import { programTypes, feeStatus, programsObj } from '../../dummy/status';
import { ConvertService } from './../../services/convert.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from '../../auth/auth.service';
import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { FireValidatorsService } from '../../services/fire-validators.service';
import { ITuition } from '../../interfaces/tuition';
import { MappingService } from '../../services/mapping.service';
import { programs } from 'src/app/dummy/status';
import { Academic } from 'src/app/stores/academic.store';

@Component({
  selector: 'app-edit-academic-fee',
  templateUrl: './edit-academic-fee.component.html',
  styleUrls: ['./edit-academic-fee.component.scss']
})
export class EditAcademicFeeComponent implements OnInit {
  @ViewChild("focusInput") inputEl: ElementRef;

  form: FormGroup;
  code: AbstractControl;
  name: AbstractControl;
  price: AbstractControl;
  program: AbstractControl;
  program_type: AbstractControl;
  description: AbstractControl;
  status: AbstractControl;
  foreigner: AbstractControl;
  campus: AbstractControl;

  programs = programs;
  programTypes = programTypes;
  feeStatus = feeStatus
  campusList = [];

  constructor(
    public dialogRef: MatDialogRef<EditAcademicFeeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public auth: AuthService,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore,
    public store: Academic
  ) { }

  buildForm(): void {
    this.form = this.fb.group({
      code: [null, Validators.required],
      description: [null],
      name: [null, Validators.required],
      price: [null, Validators.required],
      campus: [null, Validators.required],
      foreigner: [null],
      program: [this.programs[0], Validators.required],
      program_type: [this.programTypes[0], Validators.required],
      status: [feeStatus[0], Validators.required]
    })
    this.name = this.form.controls['name'];
    this.campus = this.form.controls['campus'];
    this.description = this.form.controls['description'];
    this.name = this.form.controls['name'];
    this.price = this.form.controls['price'];
    this.program = this.form.controls['program'];
    this.program_type = this.form.controls['program_type'];
    this.status = this.form.controls['status'];

    let { code, description, name, price, foreigner, program, program_type, status } = this.data;

    const freeType = this.programTypes.filter(m => m.key === program_type.key)[0];
    const statusDoc = this.feeStatus.filter(m => m.key === status.key)[0];
    this.form.patchValue({
      code: code,
      description: description,
      name: name,
      price: price,
      foreigner: ConvertService.toNull(foreigner),
      program: program,
      program_type: freeType,
      status: statusDoc
    })
  }

  compareObjects(o1: any, o2: any): boolean {
    if (o2) return o1.key === o2.key;
  }

  async ngOnInit() {
    this.buildForm();

    this.campusList = await this.store.fetchCampus();
    this.form.patchValue({
      campus: this.data.campus ? this.data.campus : this.campusList[0]
    })
  }

  create(f: any) {
    if (this.form.valid) {
      const formData: ITuition = {
        key: this.data.key,
        code: f.code,
        name: f.name,
        price: f.price,
        campus: f.campus,
        foreigner: f.foreigner,
        priority: null,
        program: f.program,
        program_type: f.program_type,
        description: f.description,
        status: f.status,
        modifiedDate: new Date(),
        modifiedBy: this.auth.getUser(),
        is_academic: true,
      }
      this.store.update(formData, (success, error) => {
        if (success) {
          const { program_type, program, status, campus } = f;
          this.dialogRef.close();
          this.snackBar.open('Academic Tuition Fee has been created.', 'done', { duration: 2000 });
          this.form.reset();
          this.form.patchValue({
            program: program,
            program_type: program_type,
            status: status,
            campus: campus,
          })
          this.inputEl.nativeElement.focus();
        }
        else {
          this.snackBar.open(error, 'Error')
        }
      })
    }
  }

}