import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'app-header-tabs',
  templateUrl: './header-tabs.component.html',
  styleUrls: ['./header-tabs.component.scss']
})
export class HeaderTabsComponent implements OnInit {
  @Input() name:string;
  @Input() buttonText:string;
  @Input() tabs:Array<any>;
  @Input() disabled:boolean;
  @Output() onPress=new EventEmitter();
  
    constructor() { }
  
    ngOnInit() {
    }
    
    create(){
      this.onPress.emit();
    }
  
  }
  
