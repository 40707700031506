import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { MappingService } from 'src/app/services/mapping.service';
import { Tuition } from 'src/app/stores/tuition.store';
import { Environment } from 'src/app/stores/environment.store';
import { Student } from 'src/app/stores/student.store';
import { MatSnackBar, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Payment } from 'src/app/stores/payment.store';

@Component({
  selector: 'app-student-resolve-fee',
  templateUrl: './student-resolve-fee.component.html',
  styleUrls: ['./student-resolve-fee.component.scss']
})
export class StudentResolveFeeComponent implements OnInit {

  form: FormGroup;
  nationality: AbstractControl;
  check: AbstractControl;

  filteredForeignerStudent: any;

  constructor(
    public dialogRef: MatDialogRef<StudentResolveFeeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public fee: Tuition,
    public env: Environment,
    public studentStore: Student,
    private snackBar: MatSnackBar,
    public store: Payment,
  ) { }

  ngOnInit() {
    this.buildForm();
    this.fee.fetchNationality(list => {
      this.filteredForeignerStudent = MappingService.autoComplete(
        this.nationality,
        list,
        "name"
      );

      this.env.fetchConfigToArray(docs => {
        const isForeigner = list.filter(m => m.key === this.studentStore.student.nationality.key).length > 0;
        const isLocal = list.filter(m => m.key === this.store.student.nationality.key).length > 0;

        let nationalityStu = null;
        if(isForeigner) nationalityStu = this.env.config.foreigner_student;
        if(isLocal) nationalityStu = this.env.config.local_student;
        this.form.patchValue({ nationality: nationalityStu })
      })
    });

  }

  displayItem(item: any): string {
    return item ? item.name : item;
  }

  buildForm(): void {
    this.form = this.fb.group({
      nationality: [null,
        Validators.compose([
          MappingService.validSelected.bind(this)
        ])],
    });
    this.nationality = this.form.controls["nationality"];
  }

  create(f: any) {
    if (this.form.valid) {
      this.form.disable();
      this.store.resolveStudentFee(this.studentStore.student, f.nationality, this.env.config, (success, error) => {
        if (success) {
          this.dialogRef.close();
          this.snackBar.open("Resolve student fee have been successful.", "done", {
            duration: 2000
          });
          this.form.enable();
          this.dialogRef.close("yes");
        } else {
          this.form.enable();
          this.snackBar.open(error, "Error");
        }
      });
    }
  }




}
