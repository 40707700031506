import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Environment } from 'src/app/stores/environment.store';
import { ClearPaymentStore } from 'src/app/stores/clearPayment.store';
import { ConvertService } from 'src/app/services/convert.service';
import { toNumber } from 'src/app/services/mapping.service';
import { DeleteComponent } from 'src/app/components/delete/delete.component';

@Component({
  selector: 'app-clear-payment-fee',
  templateUrl: './clear-payment-fee.component.html',
  styleUrls: ['./clear-payment-fee.component.scss']
})
export class ClearPaymentFeeComponent implements OnInit {
  form: FormGroup;
  cash_in: AbstractControl;
  note: AbstractControl;
  received_date: AbstractControl;
  clear_date: AbstractControl;

  process = true;
  TITLE = ""
  change: number = 0;
  amountPay: number = 0;
  exchangeFee: number;
  exchange_rate;

  constructor(
    public dialogRef: MatDialogRef<ClearPaymentFeeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    public payment: ClearPaymentStore,
    public auth: Environment,
    public dialog: MatDialog,
  ) { }

  buildForm(): void {
    this.change = 0;
    this.form = this.fb.group({
      cash_in: [null, [Validators.compose([Validators.required, this.validCashIn.bind(this)])]],
      received_date: [null, Validators.required],
      clear_date: [null, Validators.required],
      note: [this.data.note, Validators.required],
    });
    this.cash_in = this.form.controls['cash_in'];
    this.received_date = this.form.controls['received_date'];
    this.clear_date = this.form.controls['clear_date'];
    this.note = this.form.controls['note'];
  }

  public validCashIn(control: AbstractControl): { [s: string]: boolean } {
    const value = control.value;
    if (value !== undefined && value !== null && value !== "") {
      this.change = ConvertService.getNumber(value, 0) - this.amountPay;

      if (this.change === 0) {
        return { validEqual: true };
      }

      if (this.change < 0) {
        return { validEqualS: true };
      }

      if (this.change > 0) {
        return { validEqualB: true };
      }

      if (value < 0) {
        return { validAmount: true }
      }

    } else {
      this.change = 0 - this.amountPay;
    }
  }

  async ngOnInit() {
    this.buildForm();
    const { clearWith, INVOICE, IMPORT_PAYMENT } = this.data;

    const { student, selectedAdmission } = this.payment;
    this.TITLE = `Clear Payment With ${clearWith}`;

    await this.auth.fetchConfigDoc()
    const { config } = this.auth
    const { dollar_exchange } = config
    this.exchange_rate = config.dollar_exchange || 4000;

    this.payment.fetchReceivePayment(student.key, selectedAdmission.key, selectedAdmission, INVOICE.key, docs => {

      this.amountPay = this.payment.grandTotal;
      this.exchangeFee = toNumber(dollar_exchange) * toNumber(this.amountPay)
      this.change = 0 - this.amountPay;

      if (IMPORT_PAYMENT) {
        const { amount, excel_payment_date } = IMPORT_PAYMENT;
        this.form.patchValue({
          cash_in: amount,
          received_date: excel_payment_date ? excel_payment_date.toDate() : null,
          clear_date: new Date()
        })
      }

      this.process = false;
    })

  }

  async _onRemoveItem(item) {
    if (!this.payment.paymentHeader) return;
    let dialogRef = this.dialog.open(DeleteComponent, {
      data: { title: 'Remove', memo: 'Please check this information again before remove from invoice.', name: item.description, button: 'OK' },
      width: '35vw',
      disableClose: true,
      role: 'dialog',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'yes') {
        this.payment.removeMiscellaneous(item, this.payment.student, this.payment.paymentHeader, (success, error) => {
          if (success) {
            this.snackBar.open(item.description + ' has been removed.', 'done', { duration: 2000 });
          }
          else {
            this.snackBar.open(error, 'Error')
          }
        })
      }
    });
  }

  create(f: any) {
    const { received_date, cash_in } = f

    if (received_date && cash_in > 0) {
      const { student, selectedAdmission } = this.payment
      const { IMPORT_PAYMENT } = this.data

      this.payment.clearStudentUnpaid(f, IMPORT_PAYMENT, student, selectedAdmission, this.payment.paymentHeader, this.auth.user, this.data.clearWith, (success, error) => {
        if (success) {
          this.snackBar.open('Clear payment is successfully.', 'done', { duration: 2000 });
          this.form.enable();
          this.dialogRef.close("yes")
        } else {
          this.snackBar.open(error, 'Error');
          this.form.enable();
        }
      });
    }
  }

}
