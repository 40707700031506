import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AbstractControl } from '@angular/forms';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { tabs } from 'src/app/dummy/tabs';
import { PrintService } from 'src/app/services/print.service';
import { Environment } from 'src/app/stores/environment.store';
import { Report } from 'src/app/stores/report.store';
import { MappingService } from 'src/app/services/mapping.service';
import { MatDialog } from '@angular/material';
import { InvoiceDialogComponent } from 'src/app/components/invoice-dialog/invoice-dialog.component';
import { REQUEST_STATUS_ARRAY } from 'src/app/dummy/status';
import * as XLSX from 'xlsx';
import { SCHOOL } from 'src/app/dummy/config';

@Component({
  selector: 'app-request-void-receipt-listing',
  templateUrl: './request-void-receipt-listing.component.html',
  styleUrls: ['./request-void-receipt-listing.component.scss']
})
export class RequestVoidReceiptListingComponent implements OnInit {
  @ViewChild('table') table: ElementRef;
  school=SCHOOL

  tabs = tabs.reportFilterBy;
  form: FormGroup;
  fromDate: AbstractControl;
  toDate: AbstractControl;
  status: AbstractControl;

  type: string;
  statusList: any;
  constructor(
    private fb: FormBuilder,
    private ps: PrintService,
    public env: Environment,
    private route: ActivatedRoute,
    public store: Report,
    public dialog: MatDialog
  ) { }

  print() {
    this.ps.print("#print", "a4");
  }

  fireEvent() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, 'SheetJS.xlsx');
  }

  async ngOnInit() {
    this.buildForm();
    await this.env.fetchUserDoc()
    this.statusList = REQUEST_STATUS_ARRAY;
    this.route.params.forEach(param => {
      this.type = param["id"];
      const fromDate = MappingService.fromDateFilter(this.type);
      const toDate = MappingService.toDateFilter(this.type);

      const statusDoc = this.statusList[0]
      this.form.patchValue({
        fromDate: fromDate,
        toDate: toDate,
      });
      this.getList(fromDate, toDate, null);
      if (this.type === "custom") {
        this.fromDate.enable();
        this.toDate.enable();
      } else {
        this.fromDate.disable();
        this.toDate.disable();
      }
    });
  }

  getList(fromDate, toDate, status) {
    const { selectedSchool, selectedCampus, user } = this.env;
    this.store.fetchRequestVoidListing(selectedSchool.key, selectedCampus.key, fromDate, toDate, status, user.key);
  }

  buildForm(): void {
    this.form = this.fb.group({
      fromDate: [null, Validators.required],
      toDate: [null, Validators.required],
      status: [null],
    });

    this.fromDate = this.form.controls["fromDate"];
    this.toDate = this.form.controls["toDate"];
    this.status = this.form.controls["status"];
  }

  displayItem(item: any): string {
    return item ? item.text : item;
  }

  _onPress(f: any) {
    if (this.form.valid) {
      const { fromDate, toDate, status } = f;
      this.getList(fromDate, toDate, status);
    }
  }

  _onPrint(item) {
    let dialogRef = this.dialog.open(InvoiceDialogComponent, {
      data: { studentKey: item.student.key, headerKey: item.received_invoiceRef },
      width: '90vw',
      height: '80vh',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

}
