import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { tabs } from 'src/app/dummy/tabs';
import { Environment } from 'src/app/stores/environment.store';
import { Institute } from 'src/app/stores/institute.store';
import { Report } from 'src/app/stores/report.store';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-unpaid-listing',
  templateUrl: './unpaid-listing.component.html',
  styleUrls: ['./unpaid-listing.component.scss']
})
export class UnpaidListingComponent implements OnInit {

  @ViewChild('table') table: ElementRef;

  tabs = tabs.schedule;
  campusControl = new FormControl('', [Validators.required]);
  yearControl = new FormControl('', [Validators.required]);
  shiftControl = new FormControl('', [Validators.required]);
  programControl = new FormControl('', [Validators.required]);
  campusData: Array<any> = [];
  yearData: Array<any> = [];
  shiftData: Array<any> = [];
  programData: Array<any> = [];

  constructor(
    public institute: Institute
    , public store: Report
    , public env: Environment
  ) { }

  async ngOnInit() {
    await this.env.fetchUserDoc()
    this.store.fetchData(this.env.user, (level) => {
      this.campusControl.patchValue(this.store.selectedCampus)
      this.yearControl.patchValue(this.store.selectedAcademicYear);
      this.programControl.patchValue(this.store.selectedProgram);
      // this.store.resolveBatchSchedule();
      this.store.fetchUnpaidStudent()
    })
  }

  _onSelectedCampus(event) {
    const { value } = event;
    this.store.selectedCampus = value;
    this.store.fetchUnpaidStudent()

  }
  _onSelectedProgram(event) {
    const { value } = event;
    this.store.selectedProgram = value;
    this.store.fetchUnpaidStudent()

  }
  _onSelectedYear(item) {
    const { value } = item;
    this.store.selectedAcademicYear = value;
    this.store.fetchUnpaidStudent()

  }


  _onSelectedLevel(item) {
    this.store.selectedLevel = item;
    this.store.fetchUnpaidStudent();
  }

  compareObjects(o1: any, o2: any): boolean {
    if (o2) return o1.key === o2.key;
  }

  exportExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'unpaidlist.xlsx');
  }
}
