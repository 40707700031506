import { MappingService } from 'src/app/services/mapping.service';
import { DataService } from "./../services/data.service";
import { observable, computed, action, autorun, toJS } from "mobx";
import { Injectable } from "@angular/core";
import { ConvertService } from '../services/convert.service';
import * as _ from 'lodash';
import { batchStatusObj, invoiceTypesObj, memberOfObj, REQUEST_STATUS, PROGRAM_OPTION_OBJ, SCHOOL_FEE_PAYMENT, paymentType } from '../dummy/status';

@Injectable()
export class Report {
  @observable public groupTerm = [];
  @observable public groupProgram = [];
  @observable public groupOtherFee = [];

  @observable public tuitionFeeWithCash = [];
  @observable public tuitionFeeWithOther = [];
  @observable public groupTuitionFeeWithOther = [];

  @observable public data = [];
  @observable public dataHeader = [];
  @observable public dataSource = [];
  @observable public installmentData = [];
  @observable public group = [];
  @observable public penalty = [];
  @observable public scholarship = [];
  @observable public prepaid = [];
  @observable public pettyCash = [];
  @observable public drawer = [];
  @observable public programTypes = null;
  @observable public programGroup = null;
  @observable public cashIn = null;
  @observable public dailyShiftPayment = null;
  @observable public installmentPayment = null;
  @observable public expensePayment = null;
  @observable public requestPenalty = null;
  @observable public requestVoid = null;
  @observable public studentFeeWithPeriod = null;
  @observable public matchTerm = null;
  @observable public receiptPayment = null;
  @observable public totalAmount = null;
  // @observable public totalAmountFix1 = null;
  @observable public moneyRiels = null;
  @observable public moneyDollars = null;

  @observable public loading = false;
  @observable public process = false;
  @observable public empty = false;
  @observable public totalLine = 0;
  @observable public balance = 0;
  @observable public balanceClose = 0;
  @observable public reportName: string;

  @observable public selectedCampus = null;
  @observable public selectedShift = null;
  @observable public selectedProgram = null;
  @observable public selectedBatch = null;

  @observable public selectedLevel = null;
  @observable public selectedSession = null;
  @observable public selectedInstitute = null;
  @observable public selectedTerm = null;
  @observable public selectedAcademicYear = null;

  @observable public configData = null;
  @observable public institutes = [];
  @observable public campus = [];
  @observable public shifts = [];
  @observable public programs = [];
  @observable public level = [];
  @observable public batches = [];
  @observable public students = [];
  @observable public batchMembers = [];
  @observable public sessions = [];
  @observable public comingStudent = [];
  @observable public academicYears = [];
  @observable public studentAdmission = [];

  @observable public sessionGroupItems: any = [];
  @observable public sessionItems: any = [];
  @observable public instructorItems: any = [];
  @observable public teacherClassItems: any = [];
  @observable public gradeSheetStudents: any = [];
  @observable public selectedGradeSheet: any = null;
  @observable public selectedSchedule: any = null;
  @observable public unpaidStudents = [];

  constructor(private ds: DataService) {

  }

  @action
  async fetchData(user: any, callback) {
    this.loading = true;
    this.batches = [];
    this.batchMembers = [];
    this.data = [];
    this.level = [];
    this.selectedLevel = null;
    this.selectedBatch = null;
    this.selectedSession = null;
    this.selectedAcademicYear = null;

    const { campus, memberOf, schoolKey } = user;

    const configDoc = await this.ds.storeDocRef(schoolKey).collection("academic_environment").doc("academic_environment").get().toPromise();
    this.configData = MappingService.pushToObject(configDoc);

    const shiftDoc = await this.ds.shiftRef().get().toPromise()
    this.shifts = MappingService.pushToArray(shiftDoc);

    const campusDoc = await this.ds.storeDocRef(schoolKey).collection("campus", ref => ref.orderBy("order")).get().toPromise()
    this.campus = MappingService.pushToArray(campusDoc);

    if (!memberOf || memberOf.key !== memberOfObj.administrator.key) {
      this.campus = this.campus.filter(m => m.key === campus.key)
    }

    this.selectedCampus = this.campus[0];
    this.selectedShift = this.shifts[0];

    const programDoc = await this.ds.campusRef().doc(this.selectedCampus.key).collection("training_programs").get().toPromise()
    this.programs = MappingService.pushToArray(programDoc);
    this.selectedProgram = this.programs[0] || null;

    const { year, term } = this.configData;
    if (this.selectedProgram && this.selectedProgram.programOption && this.selectedProgram.programOption.key === PROGRAM_OPTION_OBJ.yearBase.key) {
      this.selectedAcademicYear = year
    } else {
      this.selectedAcademicYear = term
    }
    const yearDoc = await this.ds.storeDocRef(schoolKey).collection("academic_year", ref => ref.where('termType.key', '==', this.selectedProgram.programOption
      .key)).get().toPromise()
    const yearData = MappingService.pushToArray(yearDoc);
    this.academicYears = yearData;

    const levelDoc = await this.ds.campusRef().doc(this.selectedCampus.key).collection("training_levels", ref => ref.where("program.key", "==", this.selectedProgram.key)).get().toPromise()
    this.level = MappingService.pushToArray(levelDoc);
    this.level = MappingService.orderBy(this.level, "grade.order")
    this.selectedLevel = this.level[0];

    this.loading = false;
    callback(this.level)
  }

  @action
  async fetchUnpaidStudent() {
    this.loading = true;
    this.unpaidStudents = [];
    const docs = MappingService.pushToArray(await this.ds.academicYearRef().doc(this.selectedAcademicYear.key).collection("campus").doc(this.selectedCampus.key).collection('enrollments', ref => ref.where('program.program.key', '==', this.selectedProgram.key)
      .where('program.key', '==', this.selectedLevel.key)
      .where('isPaid', '==', false)).get().toPromise())
    this.unpaidStudents = docs || [];
    this.loading = false;
  }

  @action
  async fetchCampus(item, callback) {
    this.loading = true;
    this.batches = [];
    this.batchMembers = [];
    this.data = [];
    this.sessions = [];
    this.selectedProgram = null;
    this.selectedLevel = null;
    this.selectedBatch = null;
    this.selectedSession = null;
    this.selectedCampus = item;
    this.selectedShift = null;
    this.selectedAcademicYear = null;

    const programDoc = await this.ds.campusRef().doc(this.selectedCampus.key).collection("training_programs").get().toPromise()
    this.programs = MappingService.pushToArray(programDoc);

    this.selectedProgram = this.programs[0] || null;

    const { year, term } = this.configData;
    if (this.selectedProgram && this.selectedProgram.programOption && this.selectedProgram.programOption.key === PROGRAM_OPTION_OBJ.yearBase.key) {
      this.selectedAcademicYear = year
    } else {
      this.selectedAcademicYear = term
    }

    // const yearDoc = await this.ds.storeDocRef(schoolKey).collection("academic_year").get().toPromise()
    // const yearData = MappingService.pushToArray(yearDoc);
    // this.academicYears = yearData;

    const levelDoc = await this.ds.campusRef().doc(this.selectedCampus.key).collection("training_levels", ref => ref.where("program.key", "==", this.selectedProgram.key)).get().toPromise()
    this.level = MappingService.pushToArray(levelDoc);
    this.level = MappingService.orderBy(this.level, "grade.order")
    this.selectedShift = this.shifts[0];
    this.selectedLevel = this.level[0];

    this.loading = false;
    callback(this.level)
  }

  @action
  fetchSelectedShift(item) {
    this.loading = true;
    this.batches = [];
    this.batchMembers = [];
    this.data = [];
    this.sessions = [];
    this.selectedLevel = null;
    this.selectedBatch = null;
    this.selectedShift = item;
    this.loading = false;
  }

  @action
  fetchSelectedYear(item) {
    this.loading = true;
    this.batches = [];
    this.batchMembers = [];
    this.data = [];
    this.sessions = [];
    this.selectedProgram = null;
    this.selectedLevel = null;
    this.selectedBatch = null;
    this.selectedSession = null;
    this.selectedCampus = null;
    this.selectedShift = null;
    this.selectedAcademicYear = item;
    this.loading = false;
  }

  @action
  fetchProgram() {
    this.loading = true;
    this.batches = [];
    this.sessions = [];
    this.batchMembers = [];
    this.data = [];
    this.selectedProgram = null;
    this.selectedLevel = null;
    this.selectedBatch = null;
    this.selectedSession = null;
    this.selectedAcademicYear = null;
    this.loading = false;
  }
  @action
  async fetchTrainingLevel(item, callback) {
    this.loading = true;
    this.batches = [];
    this.level = [];
    this.batchMembers = [];
    this.data = [];
    this.selectedLevel = null;
    this.selectedBatch = null;
    this.selectedSession = null;
    this.selectedSession = null;

    const { year, term } = this.configData;
    if (this.selectedProgram && this.selectedProgram.programOption && this.selectedProgram.programOption.key === PROGRAM_OPTION_OBJ.yearBase.key) {
      this.selectedAcademicYear = year
    } else {
      this.selectedAcademicYear = term
    }

    // const yearDoc = await this.ds.academicYearRef().get().toPromise()
    // const yearData = MappingService.pushToArray(yearDoc);
    // this.academicYears = yearData;

    const levelDoc = await this.ds.campusRef().doc(this.selectedCampus.key).collection("training_levels", ref => ref.where("program.key", "==", this.selectedProgram.key)).get().toPromise()
    this.level = MappingService.pushToArray(levelDoc);
    this.level = MappingService.orderBy(this.level, "grade.order")
    this.selectedShift = this.shifts[0];

    this.loading = false;
    callback(this.level)
  }

  @action
  fetchBatch() {
    this.loading = true;
    this.batches = [];
    this.batchMembers = [];
    this.data = [];
    this.ds.batchLevelKeyRef(this.selectedLevel.key, this.selectedAcademicYear.key, this.selectedCampus.key)
      .valueChanges()
      .subscribe(docs => {
        if (docs && docs.length > 0) {
          this.batches = docs.filter((m: any) => m.status.key == batchStatusObj.active.key && m.shift.key === this.selectedShift.key);
        }
        this.loading = false;
      })
  }
  @action
  fetchSummeryByShift(shiftKey, uid) {
    this.loading = true;
    this.ds
      .invoiceShiftRef(shiftKey, uid)
      .valueChanges()
      .subscribe(docs => {
        const rows = docs.filter(m => m.isHeader === false);
        this.data = rows;
        this.group = MappingService.groupBy(rows, "invoice_type.key", "invoice_type.key");
        this.totalLine = this.group.length;
        this.penalty = docs.filter(m => m.penalty && m.isHeader === true);
        this.scholarship = docs.filter(m => m.scholarship && m.isHeader === true);
        this.prepaid = docs.filter(m => m.prepaid && m.isHeader === true);
        this.empty = docs.length === 0;
        this.fetchOpenBalanceShift(shiftKey, list => {
          this.fetchPettyCash(shiftKey, petty => {
            this.fetchInstallment(shiftKey, install => {
              this.loading = false;
            })
          })
        })
      });
  }
  @action
  fetchBatchMember() {
    this.loading = true;
    this.ds.batchLevelDocRef(this.selectedBatch.key).collection("students").valueChanges().subscribe(docs => {
      this.batchMembers = MappingService.orderBy(docs, "student.full_name");
      this.loading = false;
    })
  }
  @action
  fetchBatchSchedule(yearKey: string) {
    this.loading = true;
    this.ds.batchLevelDocRef(this.selectedBatch.key).collection("schedules", ref => ref.where("academicYear.key", "==", yearKey)).valueChanges().subscribe(docs => {
      this.data = docs;
      this.empty = docs.length === 0;
      this.loading = false;
    })
  }

  @action
  fetchDailyShiftListing(fromDate: number, toDate: number, uid: string) {
    this.loading = true;
    this.ds.dailyShiftPaymentRef(fromDate, toDate, uid).valueChanges().subscribe(docs => {
      this.empty = docs.length === 0;
      this.dailyShiftPayment = docs;
      this.loading = false;
    })
  }

  @action
  fetchExpenseListing(fromDate: number, toDate: number, uid: string) {
    this.loading = true;
    this.expensePayment = [];
    this.ds.expensePaymentRef(fromDate, toDate, uid).valueChanges().subscribe(docs => {
      this.empty = docs.length === 0;
      this.expensePayment = docs;
      this.loading = false;
    })
  }

  @action
  fetchRequestPenaltyListing(schoolKey: string, campusKey: string, fromDate: any, toDate: any, status: any, uid: string) {
    this.loading = true;
    this.requestPenalty = [];
    this.ds.requestPenaltyRef(schoolKey, campusKey, fromDate, toDate).valueChanges().subscribe(docs => {
      this.empty = docs.length === 0;
      this.requestPenalty = docs.filter(m => m.update_by.key === uid);
      if (status) {
        this.requestPenalty = docs.filter(m => m.request_status.key === status.key);
      }
      this.loading = false;
    })
  }

  @action
  fetchRequestVoidListing(schoolKey: string, campusKey: string, fromDate: any, toDate: any, status: any, uid: string) {
    this.loading = true;
    this.requestVoid = [];
    this.ds.requestVoidRef(schoolKey, campusKey, fromDate, toDate).valueChanges().subscribe(docs => {
      this.empty = docs.length === 0;
      this.requestVoid = docs.filter(m => m.update_by.key === uid);
      if (status) {
        this.requestVoid = docs.filter(m => m.request_status.key === status.key);
      }
      this.loading = false;
    })
  }

  @action
  fetchStudentFeeWithPeriodListing(schoolKey: string, campusKey: string, fromDate: any, toDate: any, status: any, uid: string) {
    this.loading = true;
    this.studentFeeWithPeriod = [];
    this.ds.studentFeeWithPeriodRef(schoolKey, campusKey, fromDate, toDate).valueChanges().subscribe(docs => {
      this.empty = docs.length === 0;
      this.studentFeeWithPeriod = docs;
      this.loading = false;
    })
  }

  @action
  fetchMatchTermListing(fromDate: number, toDate: number, uid: string) {
    this.loading = true;
    this.matchTerm = [];
    this.ds.matchTermListingRef(fromDate, toDate, uid).valueChanges().subscribe(docs => {
      this.empty = docs.length === 0;
      this.matchTerm = docs.filter((m: any) => m.payment_status.key === SCHOOL_FEE_PAYMENT.lessPayment.key);
      this.loading = false;
    })
  }

  @action
  fetchInstallmentPaymentListing(fromDate: number, toDate: number, uid: string) {
    this.loading = true;
    this.matchTerm = [];
    this.ds.matchTermListingRef(fromDate, toDate, uid).valueChanges().subscribe(docs => {
      this.empty = docs.length === 0;
      this.matchTerm = docs.filter((m: any) => m.payment_status.key === SCHOOL_FEE_PAYMENT.installment.key);
      this.loading = false;
    })
  }

  @action
  fetchInstallmentNextPayment(fromDate: number, toDate: number, uid: string) {
    this.loading = true;
    this.matchTerm = [];
    this.ds.installmentNextPaymentRef(fromDate, toDate, uid).valueChanges().subscribe(docs => {
      this.empty = docs.length === 0;
      this.matchTerm = docs //.filter((m: any) => m.payment_status.key === SCHOOL_FEE_PAYMENT.installment.key);
      this.loading = false;
    })
  }

  @action
  fetchPaymentAR(fromDate: number, toDate: number, uid: string) {
    this.loading = true;
    this.matchTerm = [];
    this.ds.paymentARRef(fromDate, toDate, uid).valueChanges().subscribe(docs => {
      this.empty = docs.length === 0;
      this.matchTerm = docs //.filter((m: any) => m.payment_status.key === SCHOOL_FEE_PAYMENT.installment.key);
      this.loading = false;
    })
  }

  @action
  fetchReceiptReportListing(fromDate: number, toDate: number, uid: string) {
    this.loading = true;
    this.receiptPayment = [];
    this.ds.receiptReportRef(fromDate, toDate, uid).valueChanges().subscribe(docs => {
      this.empty = docs.length === 0;
      this.receiptPayment = MappingService.orderByDesc(docs, "invoice_no");
      this.loading = false;
    })
  }

  @action
  fetchInstallmentListing(fromDate: number, toDate: number, uid: string) {
    this.loading = true;
    this.installmentPayment = null;
    this.ds.installmentPaymentRef(fromDate, toDate, uid).valueChanges().subscribe(docs => {
      this.empty = docs.length === 0;
      this.installmentPayment = docs;
      this.loading = false;
    })
  }

  @action
  fetchOpenBalanceShift(shiftKey: any, callback) {
    this.process = true;
    this.ds.dailyShiftByUserShiftRef(shiftKey).valueChanges().subscribe(docs => {
      this.drawer = docs;
      this.process = false;
      callback(docs);
    })
  }

  @action
  fetchSummary(shift: any) {
    this.loading = true;
    this.balance = null;
    this.ds
      .invoiceAllSummeryByShiftRef(shift.key)
      .valueChanges()
      .subscribe(docs => {
        const rowHeader = docs.filter(m => m.isHeader === true);
        const rows = docs.filter(m => m.isHeader === false);
        this.dataHeader = rowHeader;
        this.data = rows;
        this.group = MappingService.groupBy(rows, "invoice_type.key", "invoice_type.key");

        this.totalLine = this.group.length;
        this.penalty = docs.filter(m => m.penalty && m.isHeader === true);
        this.scholarship = docs.filter(m => m.scholarshipRef && m.isHeader === true);
        this.prepaid = docs.filter(m => m.prepaid && m.isHeader === true);
        this.cashIn = shift.cash_in;
        this.empty = docs.length === 0;
        const tuitionFee = MappingService.sum(rowHeader, "amount");
        this.fetchPettyCash(shift.key, petty => {
          this.fetchInstallment(shift.key, install => {
            this.loading = false;
            this.balance = tuitionFee;
            this.balanceClose = tuitionFee + ConvertService.toNumber(shift.cash_in) + MappingService.sum(this.installmentData, "price") - MappingService.sum(this.dataSource, "price");
          })
        })
      });
  }

  @action
  fetchSummeryType(shift: any, invoiceTypeKey: number, type: any) {
    this.loading = true;
    this.balance = null;
    this.ds
      .invoiceSummeryByShiftRef(shift.key, invoiceTypeKey, type)
      .valueChanges()
      .subscribe(docs => {
        const rows = docs.filter(m => m.isHeader === false);
        const rowHeader = docs.filter(m => m.isHeader === true);
        this.data = rows;
        this.group = MappingService.groupBy(rows, "invoice_type.key", "invoice_type.key");
        this.totalLine = this.group.length;
        this.penalty = docs.filter(m => m.penalty && m.isHeader === true);
        this.scholarship = docs.filter(m => m.scholarshipRef && m.isHeader === true);
        this.prepaid = docs.filter(m => m.prepaid && m.isHeader === true);
        this.empty = docs.length === 0;
        const tuitionFee = MappingService.sum(rows, "amount");
        this.fetchPettyCash(shift.key, petty => {
          this.fetchInstallment(shift.key, install => {
            this.loading = false;
            this.balance = tuitionFee + ConvertService.toNumber(this.cashIn) + MappingService.sum(this.installmentData, "price") - MappingService.sum(this.dataSource, "price");
          })
        })
      });
  }

  @action
  fetchPettyCash(shiftKey: string, callback) {
    this.process = true;
    this.dataSource = []
    this.ds.pettyCashByShiftRef(shiftKey).valueChanges().subscribe(docs => {
      if (docs && docs.length > 0) {
        this.dataSource = docs.filter((m: any) => m.pettyCash_status.key === REQUEST_STATUS.approved.key)
      }
      this.process = false;
      callback(this.dataSource);
    })
  }

  @action
  fetchInstallment(shiftKey: string, callback) {
    this.process = true;
    this.installmentData = [];
    this.ds.installmentByShiftRef(shiftKey).valueChanges().subscribe(docs => {
      this.installmentData = docs;
      this.process = false;
      callback(docs);
    })
  }

  @action
  fetchReceiptByTerm(shiftKey: string) {
    this.loading = true;
    this.data = null;
    this.groupTerm = [];
    this.programGroup = [];
    this.pettyCash = [];
    this.totalAmount = null;
    this.ds.invoiceByDetailShiftRef(shiftKey).valueChanges().subscribe(docs => {
      this.tuitionFeeWithCash = docs.filter(m => m.payment_type.key === paymentType.cash.key)
      this.tuitionFeeWithOther = docs.filter(m => m.payment_type.key !== paymentType.cash.key)

      this.data = docs;
      this.empty = docs.length === 0;

      const tuitionFee = this.tuitionFeeWithCash.filter(m => m.invoice_type.key === invoiceTypesObj.tuitionFee.key);
      this.groupTerm = MappingService.groupByDesc(tuitionFee, "issue_year.key", "issue_year.page_key");

      const programFee = this.tuitionFeeWithCash.filter(m => m.invoice_type.key !== invoiceTypesObj.tuitionFee.key && m.invoice_type.key !== invoiceTypesObj.otherFee.key);
      this.groupProgram = MappingService.groupByDesc(programFee, "invoice_type.key", "invoice_type.key");

      const otherFees = this.tuitionFeeWithCash.filter(m => m.invoice_type.key === invoiceTypesObj.otherFee.key);
      this.groupOtherFee = MappingService.groupByDesc(otherFees, "course.key", "course.key");

      let totalFee = MappingService.sum(this.tuitionFeeWithCash, "amount");

      this.groupTuitionFeeWithOther = MappingService.groupByDesc(this.tuitionFeeWithOther, "payment_type.key", "payment_type.order");

      this.fetchPettyCash(shiftKey, petty => {
        this.pettyCash = petty;
        this.fetchInstallment(shiftKey, install => {
          this.installmentData = install;
          const totalPayment = totalFee + MappingService.sum(this.installmentData, "price") - MappingService.sum(this.pettyCash, "price");
          this.totalAmount = ConvertService.toFloatFixed2(totalPayment);
          this.loading = false;
        })
      })

    })
  }

  @action
  fetchReceiptListing(shiftKey: string) {
    this.loading = true;
    this.ds.invoiceByShiftRef(shiftKey).valueChanges().subscribe(docs => {
      this.data = MappingService.orderBy(docs, "invoice_no");
      this.programTypes = MappingService.groupBy(
        this.data,
        "program.key",
        "program.key"
      );
      this.empty = docs.length === 0;
      this.fetchPettyCash(shiftKey, petty => {
        this.fetchInstallment(shiftKey, install => {
          this.loading = false;
        })
      })
    })
  }

  @action
  fetchReceiptTuitionFeeListing(shiftKey: string, type: string) {
    this.loading = true;
    this.ds.invoiceTypeListingByShiftRef(shiftKey, type).valueChanges().subscribe(docs => {
      this.data = MappingService.orderBy(docs, "invoice_no");
      this.programTypes = MappingService.groupBy(
        docs,
        "display_program",
        "display_program"
      );
      this.empty = docs.length === 0;
      this.loading = false;
    })
  }

  @action
  fetchReceiptTypeListing(shiftKey: string, type: string) {
    this.loading = true;
    this.ds.invoiceTypeListingByShiftRef(shiftKey, type).valueChanges().subscribe(docs => {
      this.data = MappingService.orderBy(docs, "invoice_no");
      this.empty = docs.length === 0;
      this.loading = false;
    })
  }

  @action
  saveReceiptAllCash(shiftKey: string, grandTotalDollar, add_no_amount, exchange_rate, riels: Array<any>, dollars: Array<any>, callback) {
    this.loading = true;
    const batch = this.ds.batch();
    const shiftRef = this.ds.shiftMovementFireRef().doc(shiftKey);

    riels.forEach(riel => {
      batch.set(shiftRef.collection("riels").doc(riel.key), riel);
    });

    dollars.forEach(dollar => {
      batch.set(shiftRef.collection("dollars").doc(dollar.key), dollar);
    });

    const haveCheck = dollars.find(m => m.key === "checkQty" && m.total > 0) ? true : false

    batch.update(shiftRef, {
      confirmCash: true,
      exchange_rate: exchange_rate,
      grand_total_dollar: grandTotalDollar,
      add_no_amount: add_no_amount,

      pay_with_check: haveCheck,
    })

    batch.commit().then(() => {
      this.loading = false;
      callback(true, null);
    }).catch(error => {
      this.loading = false;
      callback(false, error);
    })
  }

  @action
  fetchDialShiftCashReceiveRiels(shiftKey: string, callback) {
    this.loading = true;
    this.ds.shiftMovementDocsRef().doc(shiftKey).collection("riels").valueChanges().subscribe(docs => {
      this.moneyRiels = MappingService.orderByDesc(docs, "operant");
      this.loading = false;
      callback(this.moneyRiels);
    })
  }

  @action
  fetchDialShiftCashReceiveDollars(shiftKey: string, callback) {
    this.loading = true;
    this.moneyDollars = null;
    this.ds.shiftMovementDocsRef().doc(shiftKey).collection("dollars").valueChanges().subscribe(docs => {
      const data = MappingService.orderByDesc(docs, "operant");
      if (data.length > 0) {
        let checkArray = data.filter(m => m.key === "checkQty");
        let dollarArray = data.filter(m => m.key !== "checkQty");
        this.moneyDollars = dollarArray.concat(checkArray);
      }
      this.loading = false;
      callback(this.moneyDollars);
    })
  }

}
