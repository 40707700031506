import { DataService } from "../services/data.service";
import { observable, computed, action, autorun } from "mobx";
import { Injectable } from "@angular/core";
import { AuthService } from "../auth/auth.service";
import { DailyShift, ShiftSummary } from "../interfaces/dailyShift";
import { ConvertService } from "../services/convert.service";
import { Environment } from "./environment.store";


@Injectable()
export class DailyShiftStore {
  @observable user = null;
  @observable config = null;
  @observable loading = false;
  @observable academic = null;
  @observable process = false;

  constructor(
    private ds: DataService,
    private auth: AuthService,
  ) { }

  @action
  fetchCashierInvoice(user: any, callback) {
    this.process = true;
    this.ds.invoiceCashierRef(user).valueChanges().subscribe(docs => {
      this.process = false;
      callback(docs)
    });
  }

  @action
  shiftOpen(item: DailyShift, user, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const shiftRef = this.ds.dailyShiftFireRef().doc(item.key)
    batch.set(shiftRef, item)
    const userRef = this.ds.userFireRef().doc(user.key)
    batch.update(userRef, {
      shift: item,
      isShiftOpen: true,
      balance: ConvertService.toNumber(user.balance) + ConvertService.toNumber(item.cash_in),
      petty_cash: 0,
      installment: 0,
    })
    batch.commit()
      .then(() => {
        callback(true, item);
        this.process = false;
      })
      .catch(error => {
        callback(false, error);
        this.process = false;
      });
  }

  @action
  shiftClose(dailyShift: DailyShift, shiftSummary: ShiftSummary, user: any, balance: number, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const dailyShiftRef = this.ds.dailyShiftFireRef().doc(dailyShift.key);
    const shiftSummaryRef = this.ds.shiftSummaryFireRef().doc(shiftSummary.key);
    const userRef = this.ds.userFireRef().doc(user.key);

    batch.update(dailyShiftRef, dailyShift);
    batch.set(shiftSummaryRef, shiftSummary);
    batch.update(userRef, {
      shift: null,
      isShiftOpen: false,
      balance: 0,
      petty_cash: 0,
      installment: 0,
    })
    batch.commit()
      .then(() => {
        this.process = false;
        callback(true, dailyShift);
      })
      .catch(error => {
        this.process = false;
        callback(false, error);
      });
  }

}
