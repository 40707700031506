import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatDialog } from '@angular/material';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { BaseStore } from 'src/app/stores/base.store';
import { MappingService, academicYearObj, userObj, campusObj, academicTrainingFeeObj, shiftObj, academicTrainingProgramObj } from 'src/app/services/mapping.service';
import { recordStatus, SCHOOL_TYPE } from 'src/app/dummy/status';
import { Environment } from 'src/app/stores/environment.store';
import { Registration } from 'src/app/stores/registration.store';
import { ConvertService } from 'src/app/services/convert.service';
import { DataService } from 'src/app/services/data.service';
import { IRegistrationResult } from 'src/app/interfaces/testing';
import { Router } from '@angular/router';

@Component({
  selector: 'app-set-admission',
  templateUrl: './set-admission.component.html',
  styleUrls: ['./set-admission.component.scss']
})
export class SetAdmissionComponent implements OnInit {
  @ViewChild("focusInput") inputEl: ElementRef;
  form: FormGroup;
  academicYear: AbstractControl;
  trainingGrade: AbstractControl;
  trainingProgram: AbstractControl;
  campus: AbstractControl;
  shift: AbstractControl;
  test_english: AbstractControl;
  test_math: AbstractControl;
  test_khmer: AbstractControl;
  test_other: AbstractControl;
  note: AbstractControl;
  memo: AbstractControl;

  filteredProgramStates: any;
  filteredGradeStates: any;
  filteredYearStates: any;
  filteredCampusStates: any;
  filteredShiftStates: any;

  ITEM: any;
  gradeList: any;
  selectedGrade: any;
  process: boolean = true;
  constructor(
    public dialogRef: MatDialogRef<SetAdmissionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public env: Environment,
    private snackBar: MatSnackBar,
    public base: BaseStore,
    private afs: AngularFirestore,
    public store: Registration,
    public dialog: MatDialog,
    private ds: DataService,
    private router: Router
  ) { }

  buildForm(): void {
    this.form = this.fb.group({
      trainingProgram: [null, Validators.compose([Validators.required, MappingService.validSelected.bind(this)])],
      trainingGrade: [null, Validators.compose([Validators.required, MappingService.validSelected.bind(this)])],
      academicYear: [null, Validators.compose([Validators.required, MappingService.validSelected.bind(this)])],
      campus: [null],
      shift: [null, Validators.compose([Validators.required, MappingService.validSelected.bind(this)])],
      test_english: [null,],
      test_math: [null,],
      test_khmer: [null,],
      test_other: [null,],
      note: [null,],
      memo: [null,],
    })
    this.trainingProgram = this.form.controls['trainingProgram'];
    this.trainingGrade = this.form.controls['trainingGrade'];
    this.academicYear = this.form.controls['academicYear'];
    this.campus = this.form.controls['campus'];
    this.shift = this.form.controls['shift'];
    this.test_english = this.form.controls['test_english'];
    this.test_math = this.form.controls['test_math'];
    this.test_khmer = this.form.controls['test_khmer'];
    this.test_other = this.form.controls['test_other'];
    this.note = this.form.controls['note'];
    this.memo = this.form.controls['memo'];
  }

  async ngOnInit() {
    this.buildForm();

    let campusData: any = await this.base.fetchCampus();
    campusData = MappingService.orderBy(campusData, "order")
    const shiftData: any = await this.base.fetchShift();
    let yearData: any = await this.base.fetchAcademicYear();
    yearData = MappingService.orderByDesc(yearData, "startDate")
    // const { TESTING } = this.data;
    // const { program, campus, testing_result, shift } = TESTING;
    yearData = yearData.filter((m: any) => m.termType.key === 1);
    this.filteredShiftStates = MappingService.autoComplete(this.shift, shiftData, "name");
    this.filteredCampusStates = MappingService.autoComplete(this.campus, campusData, "name");
    this.filteredYearStates = MappingService.autoComplete(this.academicYear, yearData, "name");
    // let programData: any = await this.base.fetchTrainingProgram(campusData[0].key);
    // programData = programData.filter((m: any) => m.key === programData[0].key);
    // this.filteredProgramStates = MappingService.autoComplete(this.trainingProgram, programData, "name");
    // const gradeData: any = await this.base.fetchTrainingLevel(campusData[0].key, programData[0].key);
    // this.filteredGradeStates = MappingService.autoComplete(this.trainingGrade, gradeData, "grade.name");

    this.form.patchValue({
      academicYear: yearData[0],
      shift: shiftData[0],
    })
    this.process = false;
  }

  compareObjects(o1: any, o2: any): boolean {
    if (o2) return o1.key === o2.key;
  }

  displayItem(item: any): string {
    return item ? item.name : item;
  }

  displayItemGrade(item: any): string {
    return item ? item.grade.name : item;
  }

  displayItemText(item: any): string {
    return item ? item.text : item;
  }

  async onSelectCampus(event) {
    const { value } = event.option;
    this.trainingProgram.patchValue(null);
    if (value) {
      const programData = await this.base.fetchTrainingProgram(value.key);
      this.filteredProgramStates = MappingService.autoComplete(this.trainingProgram, programData, "name");
    }
  }

  async onSelectProgram(event) {
    const { value } = event.option;
    const { campus } = this.form.value;
    this.trainingGrade.patchValue(null);

    if (value && campus) {

      // let gradeData = [];
      // gradeData = this.gradeList.filter(m => m.grade.type.key === value.key);
      // gradeData = MappingService.orderBy(gradeData, "grade.order")
      // this.filteredGradeStates = MappingService.autoComplete(this.trainingGrade, gradeData, "grade.name");

      const gradeData = await this.base.fetchTrainingLevel(campus.key, value.key);
      this.filteredGradeStates = MappingService.autoComplete(this.trainingGrade, gradeData, "grade.name");
    }
  }

  onSelectGrade(event) {
    const { value } = event.option;
    this.selectedGrade = null;
    if (value) {

      this.selectedGrade = value;
    }
  }

  create(f: any) {
    if (this.form.valid) {
      this.form.disable();
      const { trainingGrade, academicYear, shift, test_english, test_math, test_khmer, test_other, note, memo } = f;

      const { STUDENT, TESTING } = this.data;
      const { campus, program } = TESTING;
      const key = this.afs.createId();
      const item: IRegistrationResult = {
        key: key,
        status: recordStatus.active,
        create_date: new Date(),
        create_date_key: ConvertService.toDateKey(new Date()),
        create_by: userObj(this.env.user),
        update_date: new Date(),
        update_date_key: ConvertService.toDateKey(new Date()),
        update_by: userObj(this.env.user),
        pageKey: ConvertService.pageKey(),

        trainingProgram: academicTrainingProgramObj(program),
        trainingGrade: academicTrainingFeeObj(trainingGrade),
        academicYear: academicYearObj(academicYear),
        campus: campusObj(campus),
        shift: shiftObj(shift),

        test_english: test_english,
        test_math: test_math,
        test_khmer: test_khmer,
        test_other: test_other,
        note: note,
        memo: memo,
        registrationKey: null,
        registrationRef: null,
        studentKey: STUDENT.key,
        studentRef: this.ds.studentDocument(STUDENT.key).ref,
        testingKey: TESTING.key,
        testingRef: this.ds.testingDBRef().doc(TESTING.key).ref,
      };

      this.store.setAdmission(item, STUDENT, TESTING, (success, res) => {
        if (success) {
          this.dialogRef.close();
          this.snackBar.open('Registration have been set result successful.', 'done', { duration: 2500 });
          this.form.enable();
          this.form.reset();
          // this.router.navigate(["/student/" + key + '/' + res + "/receive-payment"]);
        }
        else {
          alert(res)
        }
      })
    }
  }



}
