import { AbstractControl } from '@angular/forms';
import { Injectable } from '@angular/core';
import * as moment from 'moment'
import * as firebase from 'firebase/app';
import { PROGRAM_TERMS_OBJ } from '../dummy/status';

@Injectable({
  providedIn: 'root'
})
export class ConvertService {

  constructor() { }

  get timestamp() {
    return firebase.database.ServerValue.TIMESTAMP;
  }


  static getKhmerNumber(s) {
    let array = []
    for (var i = 0; i < s.length; i++) {
      array.push(s.charAt(i))
    }
    let result = '';
    array.forEach(item => {
      if (item === '1')
        result = result + '១'
      else if (item.toLowerCase() === '2')
        result = result + '២'
      else if (item.toLowerCase() === '3')
        result = result + '៣'
      else if (item.toLowerCase() === '4')
        result = result + '៤'
      else if (item.toLowerCase() === '5')
        result = result + '៥'
      else if (item.toLowerCase() === '6')
        result = result + '៦'
      else if (item.toLowerCase() === '7')
        result = result + '៧'
      else if (item.toLowerCase() === '8')
        result = result + '៨'
      else if (item.toLowerCase() === '9')
        result = result + '៩'
      else if (item.toLowerCase() === '0')
        result = result + '០'
      else
        result = result + item
    });

    return result;
  }

  static getMonthSchedule(item) {
    let result = '';
    if (item === 'Jan')
      result = result + 'មករា'
    else if (item.toLowerCase() === 'feb')
      result = result + 'កុម្ភៈ'
    else if (item.toLowerCase() === 'mar')
      result = result + 'មីនា'
    else if (item.toLowerCase() === 'apr')
      result = result + 'មេសា'
    else if (item.toLowerCase() === 'may')
      result = result + 'ឧសភា'
    else if (item.toLowerCase() === 'jun')
      result = result + 'មិថុនា'
    else if (item.toLowerCase() === 'jul')
      result = result + 'កក្កដា'
    else if (item.toLowerCase() === 'aug')
      result = result + 'សីហា'
    else if (item.toLowerCase() === 'sep')
      result = result + 'កញ្ញា'
    else if (item.toLowerCase() === 'oct')
      result = result + 'តុលា'
    else if (item.toLowerCase() === 'nov')
      result = result + 'វិច្ឆិកា'
    else if (item.toLowerCase() === 'dec')
      result = result + 'ធ្នូ'
    else
      result = result + 'មករា / '
    return result;
  }
  
  static toYearMonthKey(date: Date) {
    return Number(moment(date).format('YYYYMM'))
  }

  static calcBusinessDays(a, b) {
    const d1 = moment(a)
    const d2 = moment(b)

    // Calc all days used including last day ( the +1 )
    const days = d2.diff(d1, 'days') + 1;

    // how many full weekends occured in this time span
    const weekends = Math.floor(days / 7);

    // Subtract all the weekend days
    let businessDays = days;

    const cur = d1.clone();
    for (let i = 0; i < days; i++) {
      if (cur.day() === 0 || cur.day() === 6) {
        businessDays--;
      }
      cur.add(1, 'days')
    }

    return businessDays;
  }

  static numberStartFromDate(date: number) {
    return moment(date, 'YYYYMMDD').startOf('days').toDate();
  }
  static numberEndToDate(date: number) {
    return moment(date, 'YYYYMMDD').endOf("days").toDate();
  }

  static startFromDate(date: any) {
    return moment(date).startOf('days').toDate();
  }

  static startMonthFromDate(date: any) {
    return moment(date).startOf('month').toDate();
  }
  static endMonthFromDate(date: any) {
    return moment(date).endOf('month').toDate();
  }
  static endToDate(date: any) {
    return moment(date).endOf("days").toDate();
  }

  static dateFormat(date: Date) {
    return moment(date).format('D/MM/YYYY');
  }

  static getPaymentTerm(programTerm: any, paymentOption: any) {
    let termKey = 0
    if (programTerm.key === 1) {
      switch (paymentOption.period) {
        case 3:
          termKey = 1
          break;
        case 6:
          termKey = 2
          break;
        case 12:
          termKey = 4
          break;
        default:
          break;
      }
    }
    else if (programTerm.key === 2) {
      switch (paymentOption.period) {
        case 3:
          termKey = 2
          break;
        case 6:
          termKey = 3
          break;
        case 12:
          termKey = 4
          break;
        default:
          break;
      }
    }
    else if (programTerm.key === 3) {
      switch (paymentOption.period) {
        case 3:
          termKey = 3
          break;
        case 6:
          termKey = 4
          break;
        default:
          break;
      }
    } else if (programTerm.key === 4) {
      switch (paymentOption.period) {
        case 3:
          termKey = 4
          break;
        default:
          break;
      }
    }

    return PROGRAM_TERMS_OBJ[`term${termKey}`];
  }

  static convertAdminFeeAmount(term, adminFee) {
    const { amount } = adminFee
    let value = 0
    switch (term.key) {
      case 1:
        value = amount
        break;
      case 2:
        value = (amount / 4) * 3
        break;
      case 3:
        value = (amount / 4) * 2
        break;
      case 4:
        value = (amount / 4) * 1
        break;
      default:
        value = amount
        break;
    }
    return value;
  }

  static getDaySchedule(days) {
    var daysInput = Object.keys(days).map(function (key) {
      return { name: key, value: days[key] };
    });

    daysInput = daysInput.filter(m => m.value === true)
    daysInput.sort((a: any, b: any) => {
      var day1 = a.name
      var day2 = b.name
      if (daysOfWeek[day1] < daysOfWeek[day2]) {
        return -1;
      } else if (daysOfWeek[day1] > daysOfWeek[day2]) {
        return 1;
      } else {
        return 0;
      }
    });
    let result = '';
    daysInput.forEach(item => {
      if (item.name === 'monday')
        result = result + 'Monday / '
      else if (item.name.toLowerCase() === 'tuesday')
        result = result + 'Tuesday / '
      else if (item.name.toLowerCase() === 'friday')
        result = result + 'Friday / '
      else if (item.name.toLowerCase() === 'saturday')
        result = result + 'Saturday / '
      else if (item.name.toLowerCase() === 'sunday')
        result = result + 'Sunday / '
      else if (item.name.toLowerCase() === 'thursday')
        result = result + 'Thursday / '
      else
        result = result + 'Wednesday / '
    })
    if (result.length > 3)
      result = result.substring(0, result.length - 3)
    return result;
  }

  static getScholarshipOther(data: Array<any>) {
    let value = null
    if (data && data.length) {
      data.map(m => {
        if (value)
          value = m.amount > value.amount ? m : value
        else
          value = m
      })
    }
    return value;
  }

  static toNumber(value) {
    if (value === null || value === "" || value === undefined) {
      return 0;
    }
    if (Number(value) === NaN) return 0;
    return Number(value);
  }

  static toFloatFixed(value, fix) {
    if (value === null || value === "" || value === undefined) {
      return 0;
    }
    if (Number(value) === NaN) return 0;
    return Number(value.toFixed(fix));
  }

  static toFloatFixedOne(value: any) {
    if (value === null || value === "" || value === undefined) {
      return 0;
    }
    if (Number(value) === NaN) return 0;
    const amount = this.toFloatFixed(value, 2);
    const totalAmount = amount.toString().slice(0, -1);
    return Number(totalAmount);
  }

  static toFloatFixed2(value) {
    if (value === null || value === "" || value === undefined) {
      return 0;
    }
    if (Number(value) === NaN) return 0;
    return Number(value.toFixed(2));
  }

  static roundUp2(num) {
    const p = Math.pow(10, 1);
    const m = (num * p) * (1 + Number.EPSILON);
    const value = Math.round(m) / p
    return Number(value.toFixed(2));
  }

  static toRoundUpDown(value) {
    if (value === null || value === "" || value === undefined) {
      return 0;
    }
    if (Number(value) === NaN) return 0;

    const valueFixed1 = Number(value.toFixed(1))
    const numString = valueFixed1.toString()
    const numSplit = numString.split(".")

    let num1 = this.toNumber(numSplit[0]) || 0
    let num2 = 0;
    if (numSplit.length === 2) {
      num2 = this.toNumber(numSplit[1])
      if (num2 >= 5) {
        num1 += 1
      }
    }
    return Number(num1);
  }

  static numRoundUp(value) {
    if (value === null || value === "" || value === undefined) {
      return 0;
    }
    if (Number(value) === NaN) return 0;
    return Number(Math.ceil(value));
  }

  static numRoundDown(value) {
    if (value === null || value === "" || value === undefined) {
      return 0;
    }
    if (Number(value) === NaN) return 0;
    return Number(Math.floor(value));
  }

  static getNumber(value: any, getval: any): number {
    if (value === NaN || value === null || value === undefined || value === '') {
      return getval;
    }
    return Number(value);
  }

  static toNull(value) {
    if (value === null || value === "" || value === undefined) {
      return null;
    }
    return value;
  }

  static toNullInputValue(input: AbstractControl) {
    if (input === undefined || undefined === null) return null;
    const value = input.value;
    if (value === null || value === "" || value === undefined) {
      return null;
    }
    return value;
  }

  static toNullInputKey(input: AbstractControl) {
    if (input === undefined || undefined === null) return null;
    const value = input.value;
    if (value === null || value === "" || value === undefined) {
      return null;
    }
    return value.key;
  }

  static yearSuffix() {
    return moment().format('YY').toString();
  }
  static addWeek(interval: number) {
    return moment().add(interval, 'week').toDate()
  }

  static addMonth(interval: number) {
    return moment().add(interval, 'month').toDate()
  }

  static addDateMonth(date: any, interval: number) {
    return moment(date).add(interval, 'month').toDate()
  }
  static addMonthDateRemove1Day(date: any, interval: number) {
    return moment(date).add(interval, 'month').subtract(1, 'days').toDate()
  }
  static addDateDay(date: any, interval: number) {
    return moment(date).add(interval, 'days').toDate()
  }

  static removeDateDay(date: any, interval: number) {
    return moment(date).subtract(interval, 'days').toDate()
  }
  static addDay(interval: number) {
    return moment().add(interval, 'days').toDate()
  }

  static subtractDay(interval: number) {
    return moment().subtract(interval, 'days').toDate()
  }

  static countDay(endDate, currentData) {
    return Number(moment(endDate).diff(moment(currentData), 'days')) + 1
  }

  static pageKey() {
    return Number(moment().format('YYYYMMDDHHmmss'))
  }

  static toDateKey(date: any) {
    return Number(moment(date).format('YYYYMMDD'))
  }

  static dateKey() {
    return Number(moment().format('YYYYMMDD'))
  }

  static hourToNumber(time) {
    return Number(moment(time, 'hh:mm a').format('HH').toString() + '.' + moment(time, 'hh:mm a').format('mm').toString());
  }

  static toHour(time) {
    return moment(time, 'hh:mm a').format('hh:mm a')
  }

  static generate_testing_invoiceNo(config) {
    var number = moment().format('YYMMDD').toString() + (config.invoice_shufit + 1).toString();
    return Number(number);
  }

  static generate_puc_id(config) {
    const value = `0${config.puc_id + 1}`;
    // const value = moment().format('YY').toString() + (config.puc_id + 1).toString();
    return value;
  }

  static getAge(DOB) {
    var today = new Date();
    var birthDate = new Date(DOB);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age = age - 1;
    }
    return age;
  }
}


export function toUpperCase(value: string) {
  if (value === "" || value === undefined) {
    return null;
  }
  return value.toUpperCase();
}

export const daysOfWeek = {
  "monday": 1,
  "tuesday": 2,
  "wednesday": 3,
  "thursday": 4,
  "friday": 5,
  "saturday": 6,
  "sunday": 7
}

export function toNull(value) {
  if (value === null || value === "" || value === undefined) {
    return null;
  }
  return value;
}
