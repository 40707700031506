import { AddUnpaidAcademicComponent } from './add-unpaid-academic/add-unpaid-academic.component';
import { AddUnpaidPaymentComponent } from './add-unpaid-payment/add-unpaid-payment.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AddPettyCashComponent } from './add-petty-cash/add-petty-cash.component';
import { AddTrainingFeeComponent } from './add-training-fee/add-training-fee.component';
import { AddProgramFeeComponent } from './add-program-fee/add-program-fee.component';
import { AddInstiuteFeeComponent } from "./add-instiute-fee/add-instiute-fee.component";
import { CashierShiftClosedComponent } from "./cashier-shift-closed/cashier-shift-closed.component";
import { ConfirmSuccessComponent } from './confirm-success/confirm-success.component';
import { SetAdmissionComponent } from '../pages/student-admission/set-admission/set-admission.component';
import { AddStudentCutPaymentComponent } from '../pages/student/student-cut-payment/add-student-cut-payment/add-student-cut-payment.component';
import { ConfirmDeleteComponent } from './confirm-delete/confirm-delete.component';
import { CashierVoidReceiptComponent } from './cashier-void-receipt/cashier-void-receipt.component';
import { AddPeriodFeeComponent } from '../pages/student/add-period-fee/add-period-fee.component';
import { AddPaymentCrossYearComponent } from '../pages/student/student-cut-payment/add-payment-cross-year/add-payment-cross-year.component';

export const APP_ENTRY = [
  AddInstiuteFeeComponent,
  CashierShiftClosedComponent,
  AddProgramFeeComponent,
  AddTrainingFeeComponent,
  AddPettyCashComponent,
  ForgotPasswordComponent,
  AddUnpaidPaymentComponent,
  AddUnpaidAcademicComponent,
  ConfirmSuccessComponent,
  SetAdmissionComponent,
  AddStudentCutPaymentComponent,
  ConfirmDeleteComponent,
  CashierVoidReceiptComponent,
  AddPeriodFeeComponent,
  AddPaymentCrossYearComponent,
]