import { DataService } from "../services/data.service";
import { observable, action } from "mobx";
import { Injectable } from "@angular/core";
import { MappingService } from "../services/mapping.service";

@Injectable()
export class OtherService {
  @observable public data = null;
  @observable public trainingFee = null;
  @observable public priceList = [];
  @observable public otherFees = [];
  @observable public trainings = null;
  @observable public selectedOtherFee = null;
  @observable public selectedTraining = null;

  @observable public loading = false;
  @observable public empty = false;
  @observable public process = false;

  constructor(private ds: DataService) { }

  @action
  fetchOtherServiceFee(schoolKey: string) {
    this.loading = true;
    this.ds.otherServiceFeeRef(schoolKey).valueChanges().subscribe(docs => {
      this.otherFees = MappingService.orderBy(docs, "order");
      this.loading = false;
    })
  }

  @action
  fetchSingleOtherFee(schoolKey: string, key: string) {
    this.loading = true;
    this.ds.otherServiceFeeDocRef(schoolKey, key).valueChanges().subscribe(doc => {
      this.selectedOtherFee = doc;
      this.loading = false;
    })
  }

  @action
  fetchOtherFeeDetail(schoolKey: string, key: string) {
    this.loading = true;
    this.ds.otherServiceFeeDocRef(schoolKey, key).collection("admission_service_other_detail").valueChanges().subscribe(docs => {
      this.data = MappingService.orderBy(docs, "order");;
      this.empty = docs.length === 0;
      this.loading = false;
    })
  }

}
