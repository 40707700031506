import { Environment } from './../../../stores/environment.store';
import { Testing } from './../../../stores/testing.store';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Payment } from 'src/app/stores/payment.store';
import { MatSnackBar, MatDialog } from '@angular/material';
import { FormBuilder, AbstractControl, FormGroup, Validators } from '@angular/forms';
import { PrintService } from 'src/app/services/print.service';
import { Miscellaneous } from 'src/app/stores/miscellaneous.store';
import { AddReceivePaymentMiscellaneousComponent } from 'src/app/components/add-receive-payment-miscellaneous/add-receive-payment-miscellaneous.component';
import { Student } from 'src/app/stores/student.store';

@Component({
  selector: 'app-miscellaneous-fee-puc-student',
  templateUrl: './miscellaneous-fee-puc-student.component.html',
  styleUrls: ['./miscellaneous-fee-puc-student.component.scss']
})
export class MiscellaneousFeePucStudentComponent implements OnInit {
  instituteKey:string;
  @ViewChild("focusInput")
  inputEl: ElementRef;

  form: FormGroup;
  search: AbstractControl;
  formFocus: boolean = false;
  id: string;

  instituteLists: any;
  testData: any;
  isClickTest: boolean =  false;

  constructor(
    private fb: FormBuilder,
    public store: Miscellaneous,
    private ps: PrintService,
    private snackBar: MatSnackBar,
    public testing: Testing,
    private router: Router,
    private route: ActivatedRoute,
    public test:Testing,
    public env:Environment,
    public dialog: MatDialog,
    public payment : Payment,
    public studentStore: Student
  ) {}

  ngOnInit() {
    this.store.fees = null;
    this.form = this.fb.group({
      search: [null, Validators.required]
    });
    this.search = this.form.controls["search"];
  }

  _goBack() {
    this.router.navigate(["/"]);
  }
  _onFocus(yes) {
    this.formFocus = yes;
  }

  _onSearch(f: any) {
    if (this.form.valid) {
      this.id = f.search;
      if (this.id) {
        this.store.fetchStudentByID(this.id);
      }
      this.form.patchValue({
        search: this.id
      });
    }
  }

  compareByKey(f1: any, f2: any) {
    return f1 && f2 && f1.key === f2.key;
  }

  _onReceivePayment(item: any) {
    let dialogRef = this.dialog.open(AddReceivePaymentMiscellaneousComponent, {
      data: {program: item, student: this.studentStore.student},
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: true,
      role: "dialog"
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => {
      if (result === "print") {
        this.print();
      }
    });
  }

  print() {
    this.ps.print("#print", "a5l");
  }

  isAddTest(status: boolean){
    this.isClickTest = status;
  }


  // create(f: any) {
  //   if (this.testingForm.valid) {
  //     const formData: ITesting = {
  //       key: null,
  //       first_name: f.first_name,
  //       last_name: f.last_name,
  //       dob: f.dob,
  //       isAssignProgram: false,
  //       mobile_phone: f.mobile_phone,
  //       isPaid: TestFeeStatus.unpaid,
  //       institute: f.institute,
  //       isPaidTest: false,
  //       status: TestingStatus.active,
  //       create_date: new Date(),
  //       create_date_key: ConvertService.dateKey(),
  //       create_by: this.env.user,
  //       page_key: ConvertService.pageKey()
  //     };
  //     this.testing.addTest(
  //       formData,
  //       this.env.config,
  //       this.env.user,
  //       (success, res) => {
  //         if (success) {
  //           this.isClickTest = false;
  //           this.store.fetchInvoice(res.serial_id, this.status);
  //           this.snackBar.open("Testing has been created.", "done", {
  //             duration: 2000
  //           });
  //           this.form.reset();
  //         } else {
  //           this.snackBar.open(res, "Error");
  //         }
  //       }
  //     );
  //   }
  // }
}
