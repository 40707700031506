import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { ConvertService } from 'src/app/services/convert.service';

@Component({
  selector: 'app-testing-card',
  templateUrl: './testing-card.component.html',
  styleUrls: ['./testing-card.component.scss']
})
export class TestingCardComponent implements OnInit {
  @Output() onPress = new EventEmitter();
  @Input() data: any = null;
  @Input() process: boolean;

  currentDate: number = ConvertService.toDateKey(new Date());
  constructor() { }

  ngOnInit() {
  }

  _onPress(data) {
    this.onPress.emit(data);
  }

}
