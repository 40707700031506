import { Testing } from "./../../stores/testing.store";
import { Environment } from "src/app/stores/environment.store";
import { Router, ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { slideInOutAnimation } from "../../services/slide-io.animation";
import { Miscellaneous } from "src/app/stores/miscellaneous.store";
import {
  FormGroup,
  AbstractControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Observable } from "rxjs";
import { ITuition } from "src/app/interfaces/tuition";
import { MappingService } from "src/app/services/mapping.service";

@Component({
  animations: [slideInOutAnimation],
  selector: "app-miscellaneous-fee",
  templateUrl: "./miscellaneous-fee.component.html",
  styleUrls: ["./miscellaneous-fee.component.scss"],
  host: { "[@slideInOutAnimation]": "" }
})
export class MiscellaneousFeeComponent implements OnInit {
  form: FormGroup;
  payType: AbstractControl;

  filteredStates: Observable<ITuition[]>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public store: Miscellaneous,
    public env: Environment,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      payType: [null]
    });

    this.payType = this.form.controls["payType"];

    this.store.fetchAllMis(list => {
      this.filteredStates = MappingService.autoComplete(
        this.payType,
        list,
        "name"
      );
    });
  }

  _onChange(event){
    if(event){
      const {key} = event.option.value
      this.router.navigate(["receive-miscellaneous/fee/new/" + key]);
    }
  }
  
  _goBack() {
    this.router.navigate(["/"]);
  }

  displayItem(item: any): string {
    return item ? item.name : item;
  }
}
