import { Component, OnInit } from '@angular/core';
import { Report } from '../stores/report.store';
import { reportFilterBy } from '../dummy/status';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  tabs=reportFilterBy
  constructor(public store:Report) { }

  ngOnInit() {
  }

}
