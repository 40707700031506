import { AddTrainingFeeComponent } from './../../../components/add-training-fee/add-training-fee.component';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ActivatedRoute } from "@angular/router";
import { Institute } from "./../../../stores/institute.store";
import { Component, OnInit } from "@angular/core";
import { YearTraining } from 'src/app/stores/yearTraining.store';
import { AddTrainingGradeComponent } from '../add-training-grade/add-training-grade.component';
import { DeleteComponent } from 'src/app/components/delete/delete.component';
import { EditTrainingGradeComponent } from '../edit-training-grade/edit-training-grade.component';
import { TrainingGradeFeeComponent } from '../training-grade-fee/training-grade-fee.component';
import { TrainingGradePaymentOptionComponent } from '../training-grade-payment-option/training-grade-payment-option.component';

@Component({
  selector: 'app-training-grade',
  templateUrl: './training-grade.component.html',
  styleUrls: ['./training-grade.component.scss']
})
export class TrainingGradeComponent implements OnInit {
  id: string;
  constructor(
    public store: YearTraining,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.store.fetchAcademicYear();
    this.route.params.forEach(params => {
      this.id = params["id"];
      this.store.fetchSingleAcademicYear(this.id);
      // this.store.fetchAcademicYearTraining(this.id);
      this.store.fetchCampus();
    })
  }

  _onSetupFee(item) {
    let dialogRef = this.dialog.open(TrainingGradeFeeComponent, {
      data: item,
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: true,
      role: "dialog"
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => { });
  }

  _onSetupPaymentOption(item) {
    let dialogRef = this.dialog.open(TrainingGradePaymentOptionComponent, {
      data: item,
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: true,
      role: "dialog"
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => { });
  }

  create() {
    let dialogRef = this.dialog.open(AddTrainingGradeComponent, {
      data: null,
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: true,
      role: "dialog"
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => { });
  }

  edit(item: any) {
    // if (item.fee) return;
    let dialogRef = this.dialog.open(EditTrainingGradeComponent, {
      data: item,
      width: '35vw',
      height: '100vh',
      role: 'dialog',
    });
    dialogRef.updatePosition({ top: '0', right: '0', bottom: '0' });
  }

  delete(item: any) {
    if (item.fee) return;
    let dialogRef = this.dialog.open(DeleteComponent, {
      data: { title: 'Delete Training Grade', memo: 'If Training Grade is using by other function in system you cannot delete it.', name: item.grade.name },
      width: '35vw',
      disableClose: true,
      role: 'dialog',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'yes') {
        this.store.deleteTrainingGrade(item, (success, error) => {
          if (success) {
            this.snackBar.open('Training Grade has been deleted.', 'done', { duration: 2000 });
          }
          else {
            this.snackBar.open(error, 'Error')
          }
        })
      }
    });
  }

}
