import { Pipe, PipeTransform } from '@angular/core';
import { MappingService } from '../services/mapping.service';

@Pipe({
  name: 'sumTotalInvoice'
})
export class SumTotalInvoicePipe implements PipeTransform {

  transform(docs: Array<any>, args?: any): any {
    const totalScholarship = MappingService.sum(docs, "scholarship")+MappingService.sum(docs, "loan");
    const totalPenalty = MappingService.sum(docs, "penalty");
    const totalPrepaid = MappingService.sum(docs, "prepaid");
    const totalFee = MappingService.sum(docs, "amount") + totalPenalty - totalScholarship - totalPrepaid;
    return MappingService.sum(docs,args);
  }

}
