import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit,Inject } from '@angular/core';

@Component({
  selector: 'app-cashier-shift-closed',
  templateUrl: './cashier-shift-closed.component.html',
  styleUrls: ['./cashier-shift-closed.component.scss']
})
export class CashierShiftClosedComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<CashierShiftClosedComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
  }
  onClose(){
    this.dialogRef.close('no');
  }
  onOpen(){
    this.dialogRef.close('yes');
  }

}
