import { DataService } from 'src/app/services/data.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { tabs } from './../../dummy/tabs';
import { Component, OnInit } from '@angular/core';
import { BaseStore } from 'src/app/stores/base.store';
import { ConfirmSuccessComponent } from 'src/app/components/confirm-success/confirm-success.component';
import { Environment } from 'src/app/stores/environment.store';

@Component({
  selector: 'app-academic-year',
  templateUrl: './academic-year.component.html',
  styleUrls: ['./academic-year.component.scss']
})
export class AcademicYearComponent implements OnInit {
  tabs = tabs.academicYear;
  constructor(
    public store: BaseStore,
    private ds: DataService,
    public dialog: MatDialog,
    public env: Environment,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.store.fetchData(this.ds.academicYearRef());
  }

  _onActive(item: any) {
    let dialogRef = this.dialog.open(ConfirmSuccessComponent, {
      data: { title: "Active Academic Year", warning: "Before you set " + item.name + " active please verify your information.", note: "Are you sure you want to set Active this year?" },
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      disableClose: false,
      role: "dialog"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'yes') {
        // this.env.setActiveYear(item, (success, error) => {
        //   if (success) {
        //     this.snackBar.open("This academic year have been Active", 'done', { duration: 2000 });
        //   }
        //   else {
        //     this.snackBar.open(error, 'Error')
        //   }
        // })
      }
    });
  }

}
