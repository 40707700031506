import { ConvertService } from "./../../services/convert.service";
import { Environment } from "./../../stores/environment.store";
import { AngularFirestore } from "@angular/fire/firestore";
import { ILevelFee } from "./../../interfaces/tuition";
import { Tuition } from "./../../stores/tuition.store";
import { AuthService } from "../../auth/auth.service";
import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef
} from "@angular/core";
import {
  FormGroup,
  AbstractControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from "@angular/material";

@Component({
  selector: "app-add-instiute-fee",
  templateUrl: "./add-instiute-fee.component.html",
  styleUrls: ["./add-instiute-fee.component.scss"]
})
export class AddInstiuteFeeComponent implements OnInit {
  @ViewChild("focusInput")
  inputEl: ElementRef;

  form: FormGroup;
  price: AbstractControl;

  constructor(
    public dialogRef: MatDialogRef<AddInstiuteFeeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public auth: AuthService,
    private snackBar: MatSnackBar,
    public store: Tuition,
    private db: AngularFirestore,
    private env: Environment
  ) {}

  buildForm(): void {
    this.form = this.fb.group({
      price: [null, Validators.required]
    });
    this.price = this.form.controls["price"];
  }

  ngOnInit() {
    this.buildForm();
    this.store.fetchData(this.data);
  }

  create(f: any, isNew) {
    if (this.form.valid) {
      const item: ILevelFee = {
        key: this.db.createId(),
        createDate: new Date(),
        createBy: this.env.user,
        page_key: ConvertService.pageKey(),
        price: f.price,
        level: this.data,
      };
      this.store.save(item, (success, error) => {
        if (success) {
          if (!isNew) this.dialogRef.close();
          this.snackBar.open("Academic Tuition Fee has been created.", "done", {
            duration: 2000
          });
          this.form.reset();
          this.inputEl.nativeElement.focus();
        } else {
          this.snackBar.open(error, "Error");
        }
      });
    }
  }
}
