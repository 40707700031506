import { Directive, ElementRef, Renderer, Input } from "@angular/core";

@Directive({
  selector: "[appFocusSearch]",
  host: {
    "(focus)": "_onFocus()",
    "(blur)": "_onBlur()",
    "[class.mat-input-focus]": "inputFocusClass"
  }
})
export class FocusSearchDirective {
  constructor() {}
  inputFocusClass: boolean = false;

  _onFocus() {
    this.inputFocusClass = true;
  }

  _onBlur() {
    this.inputFocusClass = false;
  }
}
