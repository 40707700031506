import { ConvertService } from 'src/app/services/convert.service';
import { DataService } from "../services/data.service";
import { observable, computed, action, autorun } from "mobx";
import { Injectable } from "@angular/core";
import { academicYearObj, MappingService, pushObjArray, studentObj, toNumber, userObj } from '../services/mapping.service';
import { DISCOUNT_POLICY_STUDENT_OBJ, invoiceTypesObj, paymentStatus, paymentType, PREPAID_STATUS, PROGRAM_TERMS_OBJ, recordStatus, REQUEST_STATUS } from '../dummy/status';
import { ISPrepaid, IStudentPrepaid } from '../interfaces/invoice';
import { IStudentAccount } from '../interfaces/student';
import * as firebase from 'firebase/app';

@Injectable({ providedIn: 'root' })
export class ClearPaymentStore {
  @observable public data = [];
  @observable public process = false;
  @observable public loading = false;
  @observable public empty = false;
  @observable public fetching = false;
  @observable public done = false;

  @observable public studentPayment = null;
  @observable public invoices = [];
  @observable public studentImportPayment = null;

  @observable public student = null;
  @observable public selectedAdmission = null;
  @observable public selectedAcademicYear = null;

  @observable public admissions = []
  @observable public prepaid = null;
  @observable public installment = null;

  @observable public header = null;
  @observable public detail = null;
  @observable public totalPrice = null;
  @observable public totalMiscellaneous = null;
  @observable public totalOtherFee = null;
  @observable public totalPOSFee = null;
  @observable public totalScholarshipPenalty = null;
  @observable public gradePrice = null;
  @observable public subtotal = null;
  @observable public enroll = null;
  @observable public enrollIncluded = null;
  @observable public enrollExcluded = null;

  @observable public studentScholarship = null;
  @observable public totalScholarship = null;
  @observable public scholarship = null;
  @observable public totalLoan = null;
  @observable public totalPaymentDiscount = null;
  @observable public paymentDiscountType = null;
  @observable public totalPrepaid = null;
  @observable public prepaidRef = null;
  @observable public totalInstallment = null;
  @observable public remainingInstallment = null;
  @observable public totalPenalty = null;
  @observable public totalDiscount = null;
  @observable public penaltyRef = null;
  @observable public grandTotal = null;
  @observable public totalLate = null
  @observable public draft = null;
  @observable public scholarshipPenalty = null;
  @observable public miscellaneousFee = null;
  @observable public otherFees = null;
  @observable public activeTerm = null;
  @observable public scholarshipRef = null;
  @observable public installmentRef = null;
  @observable public totalDraft = [];
  @observable public totalScholarShipPenalty = [];
  @observable public paymentEmpty = false;
  @observable.shallow public paymentHeader = null;
  @observable public paymentDetail = null;
  @observable public paymentTesting = null;
  @observable public paymentTuitionFee = null;
  @observable public paymentScholarshipPenalty = null;
  @observable public paymentMiscellaneous = null;
  @observable public paymentOtherFee = null;
  @observable public paymentPOSFee = null;
  @observable public globalDiscount = null;
  @observable public scholarshipDiscount = null;
  @observable public isShowScholarship = true;
  @observable public isHavePenalty = false;
  @observable.shallow public testing = null;

  @observable public invoice = null;
  @observable public invoiceHeader = null;
  @observable public invoiceKey = null;

  private lastVisible = null;
  constructor(private ds: DataService) { }

  @action
  async fetchImportPayment(item) {
    const { schoolKey, campusKey, key } = item
    const docs: any = await await this.ds.storeDocRef(schoolKey).collection("campus").doc(campusKey).collection("import_student_payment").doc(key).get().toPromise();
    return MappingService.pushToObject(docs);
  }

  @action
  async fetchData(schoolKey: string, campusKey: string, fromDate: any, toDate: any) {
    this.loading = true;
    this.studentPayment = []
    if (campusKey) {
      const paymentDoc = await this.ds.storeDocRef(schoolKey).collection("campus").doc(campusKey).collection("import_student_payment", ref => ref
        .where("excel_payment_date", ">=", fromDate)
        .where("excel_payment_date", "<=", toDate)
        .orderBy("excel_payment_date")
      ).get().toPromise();
      const paymentData = MappingService.pushToArray(paymentDoc)

      if (paymentData && paymentData.length > 0) {
        Promise.resolve(
          paymentData.map(async m => {
            const { student } = m

            const studentDoc = await this.ds.studentDocument(student.key).get().toPromise()
            const studentData = MappingService.pushToObject(studentDoc)

            const data = {
              ...m,
              program_academic: studentData.program_academic || null,
              valid: studentData.program_academic ? true : false
            }

            this.studentPayment.push(data);

          })
        )
      }

    }

    this.loading = false;
  }

  @observable fetchUnpaidInvoiceDataRef: any = null;
  @action
  fetchUnpaidInvoice(key) {
    this.process = true;
    this.fetchUnpaidInvoiceDataRef = this.ds.studentInvoiceRef(key).valueChanges().subscribe(docs => {
      if (docs.length > 0) {
        this.invoices = docs.filter(m => !m.isVoid && m.isPaid.key === paymentStatus.unpaid.key);
      }
      this.process = false;
    });
  }

  @observable fetchImportStudentPaymentDataRef: any = null;
  @action
  fetchImportStudentPayment(schoolKey, key) {
    this.process = true;
    this.fetchImportStudentPaymentDataRef = this.ds.studentImportPaymentRef(schoolKey, key).valueChanges().subscribe(doc => {
      this.studentImportPayment = doc;
      this.process = false;
    });
  }

  @action
  async fetchAcademicYear(schoolKey) {
    let selectedAcademicYear = null;
    const envDoc: any = await this.ds.academicFirebaseRef(schoolKey).get().toPromise();
    const envData = MappingService.pushToObject(envDoc);
    if (this.selectedAdmission) {
      const { program_academic } = this.selectedAdmission;
      if (program_academic && program_academic.program.programOption.key === 1)
        selectedAcademicYear = envData.year;
      else
        selectedAcademicYear = envData.term;
    }
    return selectedAcademicYear;
  }

  @action
  clearStudentStore() {
    this.student = null;
    this.selectedAdmission = null;
    this.admissions = [];
    this.selectedAcademicYear = null;
  }

  @action
  async fetchStudent(studentKey: string, admissionKey: string, callback?: any) {
    const studentDoc = await this.ds.studentDocument(studentKey).get().toPromise();
    const admissionDoc = await this.ds.admissionRef().doc(admissionKey).get().toPromise();
    const admissionList = await this.ds.studentAllAdmissionRef(studentKey).get().toPromise();
    const studentData = MappingService.pushToObject(studentDoc);
    this.admissions = MappingService.orderByDesc(MappingService.pushToArray(admissionList), "academicYear.startDate");
    this.selectedAdmission = MappingService.pushToObject(admissionDoc)
    this.student = studentData;
    if (this.student && this.student.schoolKey) {
      this.selectedAcademicYear = await this.fetchAcademicYear(this.student.schoolKey);
    }
    const { prepaid, installment } = studentData;
    this.installment = ConvertService.toNumber(installment);
    this.prepaid = ConvertService.toNumber(prepaid);

    if (callback) callback(studentData)
  }

  @action
  clearPayment() {
    this.totalScholarship = null;
    this.totalLoan = null;
    this.totalPaymentDiscount = null;
    this.paymentDiscountType = null;
    this.totalPrepaid = null;
    this.prepaidRef = null;
    this.totalPenalty = null;
    this.totalDiscount = null;
    this.penaltyRef = null;
    this.totalInstallment = null;
    this.totalPrice = null;
    this.gradePrice = 0;
    this.grandTotal = 0;
    this.totalLate = null;
    this.invoice = null;
    this.invoiceHeader = null;
    this.scholarshipRef = null;
    this.totalScholarShipPenalty = null;
    this.paymentTuitionFee = null;
    this.paymentScholarshipPenalty = null;
    this.paymentMiscellaneous = null;
    this.paymentOtherFee = null;
    this.paymentPOSFee = null;
    this.totalScholarshipPenalty = null;
    this.paymentEmpty = true;
    this.totalMiscellaneous = null;
    this.totalScholarshipPenalty = null;
    this.scholarship = null;

    this.globalDiscount = null;
    this.scholarshipDiscount = null;
    this.isShowScholarship = true;
    this.isHavePenalty = false;

    this.paymentHeader = null;
    this.paymentDetail = null;
  }

  @action
  fetchStudentPayment(studentKey: string, admissionKey: string, admission: any, invoiceKey: string, callback: any) {
    this.paymentHeader = null;
    this.paymentDetail = null;
    this.paymentEmpty = true;
    let invoices = [];
    this.ds.studentInvoiceByAdmission(admissionKey, admission, studentKey, invoiceKey).valueChanges().subscribe(allInv => {
      if (allInv && allInv.length > 0) {
        this.paymentHeader = allInv.filter(m => !m.isVoid && m.isHeader)[0];
        invoices = this.paymentHeader ? allInv.filter(m => !m.isVoid && m.headerRef === this.paymentHeader.key) : [];
        this.paymentDetail = this.paymentHeader ? invoices.filter(m => !m.isHeader) : []
      } else {
        this.paymentHeader = null;
        this.paymentDetail = null;
        this.paymentEmpty = true;
        invoices = [];
      }

      this.paymentEmpty = invoices.length === 0;
      callback(invoices);
    })
  }

  @action
  async fetchReceivePayment(studentKey: string, admissionKey: string, admission: any, invoiceKey: string, callback: any) {
    this.process = true;
    this.clearPayment();
    this.fetchStudentPayment(studentKey, admissionKey, admission, invoiceKey, async (docs) => {

      const admissionDoc = await this.ds.admissionRef().doc(admissionKey).get().toPromise();
      const studentDoc = await this.ds.studentDocument(studentKey).get().toPromise();

      const admission = MappingService.pushToObject(admissionDoc);
      const student = MappingService.pushToObject(studentDoc);
      this.scholarship = null
      this.scholarship = await this.fetchStudentScholarship(admission);

      this.paymentTuitionFee = docs.filter(m => m.isHeader === false && m.invoice_type.key === invoiceTypesObj.tuitionFee.key);
      this.paymentMiscellaneous = docs.filter(m => m.isHeader === false && (m.invoice_type.key === invoiceTypesObj.miscellaneous.key));
      this.paymentOtherFee = docs.filter(m => m.isHeader === false && (m.invoice_type.key === invoiceTypesObj.otherFee.key));
      this.paymentPOSFee = docs.filter(m => m.isHeader === false && (m.invoice_type.key === invoiceTypesObj.posFee.key));

      const tuitionFee = this.paymentTuitionFee && this.paymentTuitionFee.length > 0 ? this.paymentTuitionFee[0] : null;

      this.globalDiscount = null;
      this.scholarshipDiscount = null;
      if (tuitionFee) {
        this.isHavePenalty = await this.checkIsHavePenalty(tuitionFee)
        this.globalDiscount = await this.getGlobalDiscount(tuitionFee, student);
        this.scholarshipDiscount = await this.getStudentScholarship(tuitionFee, this.scholarship, this.paymentHeader ? this.paymentHeader.issue_year : null);
      }

      let totalScholarshipDeduct = this.scholarshipDiscount ? (ConvertService.toNumber(this.scholarshipDiscount.amount) + ConvertService.toNumber(this.scholarshipDiscount.amountOther)) : 0;
      if ((this.globalDiscount && ConvertService.toNumber(this.globalDiscount.amount)) > (this.scholarshipDiscount && (ConvertService.toNumber(this.scholarshipDiscount.amount) + ConvertService.toNumber(this.scholarshipDiscount.amountOther)))) {
        totalScholarshipDeduct = ConvertService.toNumber(this.globalDiscount.amount)
        this.isShowScholarship = false;
      }

      let totalDeduction = 0;
      if (student && ConvertService.toNumber(student.prepaid) > 0) {
        this.totalPrepaid = ConvertService.toNumber(student.prepaid);
        this.prepaidRef = student.prepaidRef
      }

      totalDeduction = ConvertService.toNumber(this.totalPrepaid);
      //=====HAS DISCOUNT FEE
      this.totalDiscount = null
      if (this.paymentHeader && this.paymentHeader.discount && this.paymentHeader.discount > 0) {
        let { discount } = this.paymentHeader;
        this.totalDiscount = discount;

        if (this.totalDiscount > totalScholarshipDeduct) {
          totalScholarshipDeduct = 0
          this.globalDiscount = null;
          this.scholarshipDiscount = null;
        }
        else this.totalDiscount = null

      }

      //=====HAS PENALTY FEE
      this.totalPenalty = null
      if (this.paymentHeader && this.paymentHeader.penalty && this.paymentHeader.penalty > 0) {
        let { penalty, penaltyRef } = this.paymentHeader;
        this.totalPenalty = penalty;
        this.penaltyRef = penaltyRef;
      }

      //=====HAS MISCELLANEOUS FEE
      if (this.paymentMiscellaneous) {
        this.totalMiscellaneous = MappingService.sum(this.paymentMiscellaneous, 'amount');
      }

      //=====HAS OTHER FEE
      if (this.paymentOtherFee) {
        this.totalOtherFee = MappingService.sum(this.paymentOtherFee, 'amount');
      }

      //=====HAS POS FEE
      if (this.paymentPOSFee) {
        this.totalPOSFee = MappingService.sum(this.paymentPOSFee, 'amount');
      }

      //=====HAS TUITIONS FEE
      if (this.paymentTuitionFee) {
        this.totalPrice = MappingService.sum(this.paymentTuitionFee, 'amount');
      }

      // let deductionAmount = 0;
      // if (totalDeduction > this.totalPaymentDiscount) {
      //   deductionAmount = totalDeduction;
      //   this.totalPaymentDiscount = 0;
      //   this.paymentDiscountType = null
      // }

      // if (totalDeduction < this.totalPaymentDiscount) {
      //   deductionAmount = this.totalPaymentDiscount;
      //   this.scholarshipRef = null;
      //   this.totalScholarship = null;
      //   this.totalLoan = null;
      // }

      this.gradePrice = ConvertService.toNumber(this.totalPrice) - ConvertService.toNumber(totalDeduction) - ConvertService.toNumber(totalScholarshipDeduct) - ConvertService.toNumber(this.totalDiscount);
      this.grandTotal = this.gradePrice + ConvertService.toNumber(this.totalOtherFee) + ConvertService.toNumber(this.totalPOSFee) + ConvertService.toNumber(this.totalMiscellaneous) + ConvertService.toNumber(this.totalPenalty);

      this.process = false;
      callback(this.paymentHeader);
    })
  }

  @action
  removeMiscellaneous(item: any, student: any, oldInvoice: any, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const studentInvoiceRef = this.ds.studentFireDocument(student.key).collection("invoices");
    if (this.paymentDetail.length === 1) {
      batch.delete(studentInvoiceRef.doc(oldInvoice.key));
    }
    else {
      batch.update(studentInvoiceRef.doc(oldInvoice.key), {
        price: oldInvoice.price - item.price,
        amount: oldInvoice.amount - item.amount,
      })
    }

    batch.delete(studentInvoiceRef.doc(item.key))
    batch.commit().then(() => {
      this.process = false;
      callback(true, null);
    }).catch(error => {
      this.process = false;
      callback(false, error);
    })
  }

  @action
  async fetchCalPublicHoliday(schoolKey,fromDate, toDate) {
    const data = MappingService.pushToArray(await this.ds.publicHolidayActive(schoolKey, fromDate, toDate).get().toPromise());
    let day = 0;
    if (data && data.length > 0) {
      data.map(m => {
        const { from_date_key, to_date_key } = m;
        day += (to_date_key - from_date_key) + 1
      })
    }
    return day;
  }

  @action
  async checkIsHavePenalty(tuitionFee: any) {
    const { issue_year } = tuitionFee
    const academicYearDoc = await this.ds.academicYearRef().doc(issue_year.key).get().toPromise();
    const academicYearData = MappingService.pushToObject(academicYearDoc);
    const { endPaymentKey } = academicYearData
    const currentDateKey = ConvertService.toDateKey(new Date())
    if (endPaymentKey < currentDateKey)
      return true;
    else
      return false
  }

  @action
  async calStudentPenalty(schoolKey,tuitionFee: any) {
    const { issue_year } = tuitionFee
    let penaltyData = null
    const sysData = await this.ds.sysSetting().get().toPromise();
    const sysDoc = MappingService.pushToObject(sysData);

    const academicYearDoc = await this.ds.academicYearRef().doc(issue_year.key).get().toPromise();
    const academicYearData = MappingService.pushToObject(academicYearDoc);

    const { endPaymentKey, endPayment } = academicYearData
    const currentDateKey = ConvertService.toDateKey(new Date())
    if (endPaymentKey < currentDateKey) {
      const { LATE_PAYMENT_FEE } = sysDoc;
      const calcBusinessDays = ConvertService.calcBusinessDays(endPayment.toDate(), new Date())
      const holidayDays = await this.fetchCalPublicHoliday(schoolKey,endPayment.toDate(), new Date())
      const totalDays = ConvertService.toNumber(calcBusinessDays) - ConvertService.toNumber(holidayDays)
      if (totalDays > 0) {
        return penaltyData = {
          total: totalDays,
          amount: totalDays * LATE_PAYMENT_FEE,
          academicYearData: academicYearData,
        }
      }
    }
    return penaltyData;
  }

  @action
  async fetchStudentScholarship(admission: any) {
    let scholarship = null;
    if (admission && admission.program_academic && admission.program_academic.scholarshipKey) {
      const { program_academic, student } = admission;
      const scholarshipDoc = await this.ds.studentDocument(student.key).collection("scholarships").doc(program_academic.scholarshipKey).get().toPromise();
      scholarship = MappingService.pushToObject(scholarshipDoc);
      if (scholarship && scholarship.scholar_status.key === 1)
        scholarship = scholarship;
      else
        scholarship = null;
    }
    return scholarship;
  }

  @action
  getDiscountVoucher(tuitionFee: any, discountVoucherData: any) {
    let discountType = null;
    let discountAmount = null;
    let discountPercentage = null;
    if (tuitionFee) {
      const { amount } = tuitionFee;
      const { discount_type } = discountVoucherData.discount_voucher
      const discount = discountVoucherData.discount_voucher.amount
      discountPercentage = discount;
      discountType = discount_type;
      discountAmount = discount_type.key === 1 ? (amount * toNumber(discount)) / 100 : discount;
    }
    return {
      type: discountType,
      percentage: discountPercentage,
      amount: ConvertService.toFloatFixed2(discountAmount),
    };
  }

  @action
  async getGlobalDiscount(tuitionFee: any, student: any) {
    const isNewStudent = tuitionFee && tuitionFee.student_register === "Old" ? DISCOUNT_POLICY_STUDENT_OBJ.old.key : DISCOUNT_POLICY_STUDENT_OBJ.new.key;
    const { fee, amount, program } = tuitionFee;

    const { schoolKey } = student
    const paymentDiscountDoc = await this.ds.storeDocRef(schoolKey).collection("payment_discount", ref => ref.where("programKey", "array-contains", program.program.key)).get().toPromise();
    const paymentDiscountData = MappingService.pushToArray(paymentDiscountDoc);
    let discountType = null;
    let discountAmount = null;
    let discountPercentage = null;
    if (tuitionFee && paymentDiscountData && paymentDiscountData.length > 0) {
      const discountData = paymentDiscountData.filter((m: any) => m.payment_option && m.payment_option.key === fee.paymentOption.key && m.status.key === 1 && m.end_date_key >= ConvertService.dateKey());
      const discountAllStudent = discountData.filter((m: any) => m.discount_student.key === DISCOUNT_POLICY_STUDENT_OBJ.all.key)
      const discountStudent = discountData.filter((m: any) => m.discount_student.key === isNewStudent)
      const discountBalanceData = discountAllStudent && discountAllStudent.length > 0 ? discountAllStudent : discountStudent

      if (discountBalanceData && discountBalanceData.length > 0) {
        const { discount_type, discount } = discountBalanceData[0];
        discountPercentage = discount;
        discountType = discount_type;
        discountAmount = discount_type.key === 1 ? (amount * toNumber(discount)) / 100 : discount;
      }
    }
    return {
      type: discountType,
      percentage: discountPercentage,
      amount: ConvertService.toFloatFixed2(discountAmount),
    };
  }

  getStartProgramTerm(endProgramTerm, paymentOption) {
    let value = 0
    switch (paymentOption.period) {
      case 3:
        value = endProgramTerm.key
        break;
      case 6:
        value = endProgramTerm.key - 1
        break;
      case 12:
        value = 1
        break;
      default:
        value = 1
        break;
    }

    return PROGRAM_TERMS_OBJ[`term${value}`]
  }

  @action
  async getStudentScholarship(tuitionFee: any, scholarship: any, academicYear: any) {

    let scholarshipType = null;
    let scholarshipAmount = null;
    let scholarshipPercentage = null;
    let scholarshipCategory = null;
    let scholarshipAcademicOneTime = null;

    let scholarshipTypeOther = null;
    let scholarshipAmountOther = null;
    let scholarshipPercentageOther = null;
    let scholarshipCategoryOther = null;
    if (academicYear && tuitionFee) {

      const { fee } = tuitionFee
      const { paymentOption } = fee

      let endProgramTerm = tuitionFee.endProgramTerm
      let endProgramYear = tuitionFee.endProgramYear

      if (!tuitionFee.endProgramTerm && !tuitionFee.endProgramYear) {
        endProgramTerm = paymentOption.period === 12 ? { key: 4, text: "Term 4" } : { key: 2, text: "Term 2" };
        endProgramYear = { key: 2020, text: "2020-2021" }
      }

      if (scholarship && scholarship.year && scholarship.program_term && scholarship.scholarshipItems && scholarship.scholarshipItems.length > 0) {

        const { year, program_term } = scholarship;

        if (endProgramYear.key === year.key && endProgramTerm.key <= program_term.key) {

          const scholarOtherList = scholarship.scholarshipItems.filter(m => m.scholarship_type.type.key === 2 && m.status.key === 1)
          const scholarOther = scholarOtherList.length > 0 ? ConvertService.getScholarshipOther(scholarOtherList) : null;
          let scholarAcademic = scholarship.scholarshipItems.filter(m => m.scholarship_type.type.key === 1 && m.status.key === 1 && !m.isPaid).length > 0 ? scholarship.scholarshipItems.filter(m => m.scholarship_type.type.key === 1 && m.status.key === 1)[0] : null;

          if (scholarOther) {
            const { discount_type, amount, scholarship_type } = scholarOther;
            const tuitionFeeAmount = tuitionFee.amount;
            scholarshipAmountOther = discount_type.key === 1 ? (tuitionFeeAmount * toNumber(amount)) / 100 : amount;
            scholarshipTypeOther = discount_type;
            scholarshipPercentageOther = amount;
            scholarshipCategoryOther = scholarship_type;
          }

          if (scholarAcademic && scholarAcademic.academic_scholar_year && scholarAcademic.academic_scholar_program_term) {
            const { discount_type, amount, scholarship_type, academic_scholar_year, academic_scholar_program_term } = scholarAcademic;
            if (endProgramYear.key === academic_scholar_year.key && endProgramTerm.key <= academic_scholar_program_term.key) {
              const tuitionFeeAmount = scholarOther ? tuitionFee.amount - scholarshipAmountOther : tuitionFee.amount;
              scholarshipAmount = discount_type.key === 1 ? (tuitionFeeAmount * toNumber(amount)) / 100 : amount;
              scholarshipType = discount_type;
              scholarshipPercentage = amount;
              scholarshipCategory = scholarship_type;
              scholarshipAcademicOneTime = scholarAcademic && scholarAcademic.oneTime ? scholarAcademic : null
            }
          }
        }

        // SPECIAL CASE
        const startProgramTerm = this.getStartProgramTerm(endProgramTerm, paymentOption)
        if (endProgramYear.key === year.key && endProgramTerm.key > program_term.key && program_term.key >= startProgramTerm.key && paymentOption.period > 3) {
          const moreTerm = endProgramTerm.key - program_term.key

          let amountNoDis = 0
          let tuitionFeeAmountNew = tuitionFee.amount
          if (paymentOption.period === 6 && moreTerm === 1) {
            amountNoDis = tuitionFeeAmountNew / 2
            tuitionFeeAmountNew = amountNoDis
          }

          if (paymentOption.period === 12) {
            amountNoDis = tuitionFeeAmountNew / (4 / moreTerm)
            tuitionFeeAmountNew = tuitionFeeAmountNew - amountNoDis
          }

          const scholarOtherList = scholarship.scholarshipItems.filter(m => m.scholarship_type.type.key === 2 && m.status.key === 1)
          const scholarOther = scholarOtherList.length > 0 ? ConvertService.getScholarshipOther(scholarOtherList) : null;
          const scholarAcademic = scholarship.scholarshipItems.filter(m => m.scholarship_type.type.key === 1 && m.status.key === 1 && !m.isPaid).length > 0 ? scholarship.scholarshipItems.filter(m => m.scholarship_type.type.key === 1 && m.status.key === 1)[0] : null;

          if (scholarOther) {
            const { discount_type, amount, scholarship_type } = scholarOther;
            const tuitionFeeAmount = tuitionFeeAmountNew;
            scholarshipAmountOther = discount_type.key === 1 ? (tuitionFeeAmount * toNumber(amount)) / 100 : amount;
            scholarshipTypeOther = discount_type;
            scholarshipPercentageOther = amount;
            scholarshipCategoryOther = scholarship_type;
          }

          if (scholarAcademic && scholarAcademic.academic_scholar_year && scholarAcademic.academic_scholar_program_term) {
            const { discount_type, amount, scholarship_type, academic_scholar_year, academic_scholar_program_term } = scholarAcademic;
            if (endProgramYear.key === academic_scholar_year.key && endProgramTerm.key <= academic_scholar_program_term.key) {
              const tuitionFeeAmount = scholarOther ? tuitionFeeAmountNew - scholarshipAmountOther : tuitionFeeAmountNew;
              scholarshipAmount = discount_type.key === 1 ? (tuitionFeeAmount * toNumber(amount)) / 100 : amount;
              scholarshipType = discount_type;
              scholarshipPercentage = amount;
              scholarshipCategory = scholarship_type;
              scholarshipAcademicOneTime = scholarAcademic && scholarAcademic.oneTime ? scholarAcademic : null
            }
          }
        }

      }
    }

    return {
      type: scholarshipType,
      percentage: scholarshipPercentage,
      amount: ConvertService.toFloatFixed2(scholarshipAmount),
      scholarshipCategory: scholarshipCategory,
      scholarshipAcademicOneTime: scholarshipAcademicOneTime,

      typeOther: scholarshipTypeOther,
      percentageOther: scholarshipPercentageOther,
      amountOther: ConvertService.toFloatFixed2(scholarshipAmountOther),
      scholarshipCategoryOther: scholarshipCategoryOther,
    };
  }

  getReceivedBy(clearWith) {
    let receivedBy = null;
    let paymentTypeData = null

    switch (clearWith) {
      case "ABA":
        receivedBy = {
          key: "aba",
          displayName: "ABA",
          name: "ABA",
        };
        paymentTypeData = paymentType.abaBilling
        break;

      case "WING":
        receivedBy = {
          key: "wing",
          displayName: "Wing",
          name: "Wing",
        };
        paymentTypeData = paymentType.wingMerchant
        break;

      case "ACLEDA":
        receivedBy = {
          key: "acleda",
          displayName: "ACLEDA",
          name: "ACLEDA",
        };
        paymentTypeData = paymentType.acledaBilling
        break;

      case "TM":
        receivedBy = {
          key: "tm",
          displayName: "True Money",
          name: "True Money",
        };
        paymentTypeData = paymentType.acledaBilling
        break;

      default:
        receivedBy = {
          key: "aba",
          displayName: "ABA",
          name: "ABA",
        };
        paymentTypeData = paymentType.abaBilling
        break;
    }

    return {
      receivedBy: receivedBy,
      paymentTypeData: paymentTypeData,
    }
  }

  @action
  async clearStudentUnpaid(form: any, IMPORT_PAYMENT: any, student: any, admission: any, header: any, user: any, clearWith, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const { note, received_date, clear_date, cash_in } = form;

    const { puc_id, campus, school } = student;
    const studentRef = this.ds.studentFireRef();
    const storeRef = this.ds.storeFireRef(school.key)

    const academicYearDoc = await this.ds.academicYearDocRef(header.issue_year.key).get().toPromise()
    const academicYearData = MappingService.pushToObject(academicYearDoc)

    if (cash_in < this.grandTotal) {

      const { program_academic } = admission
      const totalPrepaidAmount = toNumber(cash_in);

      const itemKey = this.ds.createId();
      const item: IStudentPrepaid = {
        key: itemKey,
        create_date_key: ConvertService.dateKey(),
        create_date: new Date(),
        create_by: userObj(user),
        status: recordStatus.active,

        update_date_key: ConvertService.dateKey(),
        update_date: new Date(),
        update_by: userObj(user),

        student: student,
        program_academic: program_academic,
        academicYearKey: header.issue_year.key,
        admissionKey: program_academic.admissionKey,
        programKey: program_academic.program.key,
        levelKey: program_academic.key,

        amount: totalPrepaidAmount,
        invoice_no: null,
        invoiceKey: itemKey,
        headerRef: itemKey,

        note: note,
        isTran: false,
        isPaid: false,

        request_status: REQUEST_STATUS.pending,

        campusKey: campus.key,
        campus: campus,
        schoolKey: school.key,
        school: school,

        type: PREPAID_STATUS.add_prepaid,
      }

      const totalPrepaid = totalPrepaidAmount;
      const totalPrepaidData: ISPrepaid = {
        key: item.key,
        type: PREPAID_STATUS.add_prepaid,
        headerRef: item.headerRef,
        amount: totalPrepaid,
        approve_date: new Date(),
        approve_date_key: ConvertService.dateKey(),
        approve_by: userObj(user),
        approve_note: null,
        request_note: note,
      }

      batch.set(studentRef.doc(student.key).collection("student_prepaid").doc(item.key), item);

      batch.update(studentRef.doc(student.key), {
        prepaid: totalPrepaid,
        prepaidRef: totalPrepaidData,
      })

      if (IMPORT_PAYMENT) {
        const storeRef = this.ds.storeFireRef(school.key)

        batch.update(storeRef.collection("import_student_payment").doc(IMPORT_PAYMENT.key), {
          clear_date_key: ConvertService.toDateKey(clear_date),
          clear_date: clear_date,
          clear_by: user.key,
          isPaid: true,
        })

        batch.update(storeRef.collection("campus").doc(campus.key).collection("import_student_payment").doc(IMPORT_PAYMENT.key), {
          clear_date_key: ConvertService.toDateKey(clear_date),
          clear_date: clear_date,
          clear_by: user.key,
          isPaid: true,
        })
      }

      batch.commit().then(() => {
        this.process = false;
        callback(true, null);
      })
        .catch(error => {
          this.process = false;
          callback(false, error);
        });

    } else {

      const sysData = await this.ds.sysSetting().get().toPromise();
      const sysDoc = MappingService.pushToObject(sysData);

      const receivePaymentRef = this.ds.receivedPaymentFireRef();
      const updateAdmissionStudentIDdRef = this.ds.update_admission_student_idFireRef();
      const schoolRef = this.ds.schoolFireRef();
      const studentAccountRef = this.ds.studentAccountDocFire();
      const settingRef = this.ds.settingFireStore();
      const alignPaymentRef = this.ds.studentAlignPaymentFireRef();

      const headerKey = header.key;
      const invoiceNo = ConvertService.generate_testing_invoiceNo(sysDoc);

      const receivedBy = this.getReceivedBy(clearWith).receivedBy;
      const paymentTypeData = this.getReceivedBy(clearWith).paymentTypeData;


      //===== New ID
      if (!puc_id) {
        const pucID = ConvertService.generate_puc_id(sysDoc);
        const emailStudent = pucID + "@gmail.com";
        student.puc_id = pucID;
        student.email = emailStudent;
        const studentAccount: IStudentAccount = {
          key: student.key,
          create_date: new Date(),
          create_by: userObj(user),
          status: recordStatus.active,
          puc_id: pucID,
          email: emailStudent,
          fileUrl: null,
          displayName: student.full_name,
          studentKey: student.key,
          phone: student.mobile_phone,
          token: null,
          pinCode: null,
          student: studentObj(student),
          resetAuth: true,
        };

        batch.update(studentRef.doc(student.key), {
          puc_id: pucID,
          email: emailStudent,
        })
        batch.set(studentAccountRef.doc(studentAccount.key), studentAccount);

        // NEW ID NUMBER
        batch.update(settingRef, { puc_id: firebase.firestore.FieldValue.increment(1) });
      }

      // NEW INVOICE NUMBER
      batch.update(settingRef, { invoice_shufit: firebase.firestore.FieldValue.increment(1) });

      //===== CLEAR STUDENT INVOICE
      if (this.invoiceKey === headerKey) {
        batch.update(studentRef.doc(student.key), {
          invoiceKey: null,
        })
      }

      // DISCOUNT POLICY
      let globalDiscountData = null;
      let scholarshipDiscountData = null;
      let totalScholarshipDeduct = this.scholarshipDiscount ? (ConvertService.toNumber(this.scholarshipDiscount.amount) + ConvertService.toNumber(this.scholarshipDiscount.amountOther)) : 0;
      if ((this.globalDiscount && ConvertService.toNumber(this.globalDiscount.amount)) > (this.scholarshipDiscount && (ConvertService.toNumber(this.scholarshipDiscount.amount) + ConvertService.toNumber(this.scholarshipDiscount.amountOther)))) {
        totalScholarshipDeduct = ConvertService.toNumber(this.globalDiscount.amount)
        globalDiscountData = this.globalDiscount;
      } else {
        scholarshipDiscountData = this.scholarshipDiscount
      }

      this.paymentDetail.forEach(invoice => {
        let courseAmount = invoice.amount;

        if (invoice.invoice_type.key === invoiceTypesObj.tuitionFee.key) {
          courseAmount = courseAmount - totalScholarshipDeduct;
          if (this.totalPenalty)
            courseAmount = courseAmount + this.totalPenalty

          if (this.totalPrepaid)
            courseAmount = courseAmount - this.totalPrepaid

          if (this.totalDiscount)
            courseAmount = courseAmount - this.totalDiscount

          if (!puc_id) {
            const updateStudentData = {
              key: this.ds.createId(),
              admissionKey: invoice.program.admissionKey,
              studentKey: invoice.student.key,
              ref: invoice.key,
              headerRef: invoice.headerRef,
              puc_id: student.puc_id,
            }
            batch.set(updateAdmissionStudentIDdRef.doc(updateStudentData.key), updateStudentData);
          }

        } else {
          courseAmount = invoice.amount
        };
        courseAmount = ConvertService.toFloatFixed2(courseAmount);

        let receiveDetailData: any = {
          amount: courseAmount,
          penaltyRef: ConvertService.toNull(this.penaltyRef),
          penalty: ConvertService.toNumber(this.totalPenalty),
          prepaidRef: ConvertService.toNull(this.prepaidRef),
          prepaid: ConvertService.toNull(this.totalPrepaid),
          received_by: receivedBy,
          received_date: received_date,
          received_date_key: ConvertService.toDateKey(received_date),

          clear_date_key: ConvertService.toDateKey(clear_date),
          clear_date: clear_date,
          clear_payment: true,

          received_campus: receivedBy,
          received_campusKey: invoice.campus.key,
          received_campusRef: this.ds.campusRef().doc(invoice.campus.key).ref,
          dailyShift: receivedBy,
          caseIn: courseAmount,
          note: note,
          payment_type: paymentTypeData,
          isPaid: paymentStatus.paid,
          invoice_no: invoiceNo,
          payment_year: academicYearObj(invoice.issue_year),
          student: studentObj(student),

          scholarshipRef: this.scholarship ? this.scholarship.key : null,
          scholarshipProgramRef: this.scholarship ? this.scholarship.scholarshipKey : null,
          globalDiscount: globalDiscountData,
          scholarshipDiscountData: scholarshipDiscountData,

          campusKey: campus.key,
          campus: campus,
          schoolKey: school.key,
          school: school,

        }

        const { admin_fee } = sysDoc;
        if (invoice.course.key === admin_fee.key && invoice.fee.paymentOption.period) {
          const { programTermItems, end_year_date } = academicYearData
          const getFirstTerm = programTermItems.find(m => m.key === 1)
          const fromDate = getFirstTerm.program_term_start.toDate();
          const toDate = end_year_date.toDate();

          receiveDetailData.fromDate = fromDate
          receiveDetailData.fromDateKey = ConvertService.toDateKey(fromDate)
          receiveDetailData.toDate = toDate
          receiveDetailData.toDateKey = ConvertService.toDateKey(toDate)
        }

        batch.update(studentRef.doc(student.key).collection("invoices").doc(invoice.key), {
          ...receiveDetailData
        })
      })

      const receiveHeaderData = {
        amount: ConvertService.toFloatFixed2(this.grandTotal),
        penaltyRef: ConvertService.toNull(this.penaltyRef),
        penalty: ConvertService.toNumber(this.totalPenalty),
        //
        prepaidRef: ConvertService.toNull(this.prepaidRef),
        prepaid: ConvertService.toNull(this.totalPrepaid),
        received_by: receivedBy,
        received_date: received_date,
        received_date_key: ConvertService.toDateKey(received_date),

        clear_date_key: ConvertService.toDateKey(clear_date),
        clear_date: clear_date,
        clear_payment: true,

        received_campus: receivedBy,
        received_campusKey: header.campus.key,
        received_campusRef: this.ds.campusRef().doc(header.campus.key).ref,

        dailyShift: receivedBy,
        caseIn: ConvertService.toFloatFixed2(this.grandTotal),
        note: note,
        payment_type: paymentType.cash,
        isPaid: paymentStatus.paid,
        invoice_no: invoiceNo,
        payment_year: academicYearObj(header.issue_year),
        student: studentObj(student),

        scholarshipRef: this.scholarship ? this.scholarship.key : null,
        scholarshipProgramRef: this.scholarship ? this.scholarship.scholarshipKey : null,
        globalDiscount: globalDiscountData,
        scholarshipDiscountData: scholarshipDiscountData,

        campusKey: campus.key,
        campus: campus,
        schoolKey: school.key,
        school: school,
      }

      batch.update(studentRef.doc(student.key).collection("invoices").doc(headerKey), {
        ...receiveHeaderData
      })

      // UPDATE SCHOLARSHIP ACADEMIC ONE TIME
      if (scholarshipDiscountData && scholarshipDiscountData.scholarshipAcademicOneTime) {

        const { scholarshipItems } = this.scholarship
        const scholarItem = {
          ...scholarshipDiscountData.scholarshipAcademicOneTime,
          isPaid: true,
          studentKey: student.key,
          headerRef: header.key,
        }
        const scholarshipAcademicItems = pushObjArray(scholarshipItems, scholarItem)

        const scholarshipRef = this.ds.scholarshipFireRef().doc(this.scholarship.scholarshipKey)
        const campusScholarshipRef = this.ds.campusFireRef().doc(campus.key).collection("scholarships").doc(this.scholarship.scholarshipKey);

        batch.update(studentRef.doc(student.key).collection("scholarships").doc(this.scholarship.key), {
          scholarshipItems: scholarshipAcademicItems
        })
        batch.update(scholarshipRef, { scholarshipItems: scholarshipAcademicItems })
        batch.update(campusScholarshipRef, { scholarshipItems: scholarshipAcademicItems })
      }

      // UPDATE DISCOUNT VOUCHER
      if (header && header.discount_voucher_detail_key) {
        const paidVoucherData = {
          isPaid: true,
          student: receiveHeaderData.student,
          received_date: receiveHeaderData.received_date,
          received_date_key: receiveHeaderData.received_date_key,
          received_by: receiveHeaderData.received_by,
        }
        batch.update(schoolRef.doc(receiveHeaderData.schoolKey).collection("payment_discount_voucher_detail").doc(header.discount_voucher_detail_key), { ...paidVoucherData })
      }

      if (header && header.isAlignPayment) {
        batch.update(alignPaymentRef.doc(headerKey), {
          isPaid: true,
          note: note,
          received_by: receiveHeaderData.received_by,
          received_date: receiveHeaderData.received_date,
          received_date_key: receiveHeaderData.received_date_key,
          received_campus: ConvertService.toNull(header.campus),
          received_campusKey: header.campus.key,
          received_campusRef: this.ds.campusRef().doc(header.campus.key).ref,
        })
      }

      const receivedMovement = {
        key: header.key,
        invoice: {
          key: header.key,
        },
        invoiceKey: header.key,
        studentKey: student.key,
        shift: receivedBy,
        cashier: null,
        payment_year: header.issue_year,
        payment_campus: receivedBy,
        cashIn: header.amount,
        note: note,
        change: null,
        status: paymentStatus.paid,
        isClearPayment: true,
        page_key: ConvertService.pageKey(),
        create_date: new Date(),
        create_date_key: ConvertService.dateKey(),
        create_by: MappingService.userObj(user),
      }

      batch.set(receivePaymentRef.doc(receivedMovement.key), receivedMovement);


      if (cash_in === this.grandTotal) {

        const { program_academic } = admission
        const totalPrepaidAmount = toNumber(cash_in) - this.grandTotal;

        const itemKey = this.ds.createId();
        const item: IStudentPrepaid = {
          key: itemKey,
          create_date_key: ConvertService.dateKey(),
          create_date: new Date(),
          create_by: userObj(user),
          status: recordStatus.active,

          update_date_key: ConvertService.dateKey(),
          update_date: new Date(),
          update_by: userObj(user),

          student: student,
          program_academic: program_academic,
          academicYearKey: header.issue_year.key,
          admissionKey: program_academic.admissionKey,
          programKey: program_academic.program.key,
          levelKey: program_academic.key,

          amount: totalPrepaidAmount,
          invoice_no: null,
          invoiceKey: itemKey,
          headerRef: itemKey,

          note: note,
          isTran: false,
          isPaid: true,

          request_status: REQUEST_STATUS.pending,

          campusKey: campus.key,
          campus: campus,
          schoolKey: school.key,
          school: school,
          type: PREPAID_STATUS.add_prepaid,
        }

        const itemPaidKey = this.ds.createId();
        const itemPaid: IStudentPrepaid = {
          key: itemPaidKey,
          create_date_key: ConvertService.dateKey(),
          create_date: new Date(),
          create_by: userObj(user),
          status: recordStatus.active,

          update_date_key: ConvertService.dateKey(),
          update_date: new Date(),
          update_by: userObj(user),

          student: student,
          program_academic: program_academic,
          academicYearKey: header.issue_year.key,
          admissionKey: program_academic.admissionKey,
          programKey: program_academic.program.key,
          levelKey: program_academic.key,

          amount: totalPrepaidAmount,
          invoice_no: null,
          invoiceKey: itemPaidKey,
          headerRef: itemPaidKey,

          note: note,
          isTran: false,
          isPaid: true,

          request_status: REQUEST_STATUS.pending,

          campusKey: campus.key,
          campus: campus,
          schoolKey: school.key,
          school: school,

          type: PREPAID_STATUS.clear_prepaid,

        }

        batch.set(studentRef.doc(student.key).collection("student_prepaid").doc(item.key), item);
        batch.set(studentRef.doc(student.key).collection("student_prepaid").doc(itemPaid.key), itemPaid);
      }

      if (cash_in > this.grandTotal) {

        const { program_academic } = admission
        const totalPrepaidAmount = toNumber(cash_in) - this.grandTotal;

        const itemKey = this.ds.createId();
        const item: IStudentPrepaid = {
          key: itemKey,
          create_date_key: ConvertService.dateKey(),
          create_date: new Date(),
          create_by: userObj(user),
          status: recordStatus.active,

          update_date_key: ConvertService.dateKey(),
          update_date: new Date(),
          update_by: userObj(user),

          student: student,
          program_academic: program_academic,
          academicYearKey: header.issue_year.key,
          admissionKey: program_academic.admissionKey,
          programKey: program_academic.program.key,
          levelKey: program_academic.key,

          amount: totalPrepaidAmount,
          invoice_no: null,
          invoiceKey: itemKey,
          headerRef: itemKey,

          note: note,
          isTran: false,
          isPaid: false,

          request_status: REQUEST_STATUS.pending,

          campusKey: campus.key,
          campus: campus,
          schoolKey: school.key,
          school: school,
          type: PREPAID_STATUS.add_prepaid,
        }

        const totalPrepaid = totalPrepaidAmount;
        const totalPrepaidData: ISPrepaid = {
          key: item.key,
          type: PREPAID_STATUS.add_prepaid,
          headerRef: item.headerRef,
          amount: totalPrepaid,
          approve_date: new Date(),
          approve_date_key: ConvertService.dateKey(),
          approve_by: userObj(user),
          approve_note: null,
          request_note: note,
        }

        batch.set(studentRef.doc(student.key).collection("student_prepaid").doc(item.key), item);
        batch.update(studentRef.doc(student.key), {
          prepaid: totalPrepaid,
          prepaidRef: totalPrepaidData,
        })
      }

      if (IMPORT_PAYMENT) {

        batch.update(storeRef.collection("import_student_payment").doc(IMPORT_PAYMENT.key), {
          clear_date_key: ConvertService.toDateKey(clear_date),
          clear_date: clear_date,
          clear_by: user.key,
          isPaid: true,
        })

        batch.update(storeRef.collection("campus").doc(campus.key).collection("import_student_payment").doc(IMPORT_PAYMENT.key), {
          clear_date_key: ConvertService.toDateKey(clear_date),
          clear_date: clear_date,
          clear_by: user.key,
          isPaid: true,
        })
      }

      batch.commit().then(() => {
        this.process = false;
        callback(true, null);
      })
        .catch(error => {
          this.process = false;
          callback(false, error);
        });
    }
  }

}
