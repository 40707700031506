import { AddProgramFeeComponent } from './../../components/add-program-fee/add-program-fee.component';
import { Institute } from './../../stores/institute.store';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { tabs } from './../../dummy/tabs';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-program',
  templateUrl: './program.component.html',
  styleUrls: ['./program.component.scss']
})
export class ProgramComponent implements OnInit {
  tabs = tabs.institute;

  constructor(
    public store: Institute,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
   this.store.fetchProgram();
  }

  create(item) {
    let dialogRef = this.dialog.open(AddProgramFeeComponent, {
      data: item,
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: true,
      role: "dialog"
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => {});
  }

}
