import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Student } from 'src/app/stores/student.store';
import { MatDialog, MatSnackBar } from '@angular/material';
// import { SetAdmissionComponent } from 'src/app/components/set-admission/set-admission.component';
// import { UpdateStudentAdmissionComponent } from 'src/app/components/update-student-admission/update-student-admission.component';
import { BaseStore } from 'src/app/stores/base.store';
import { DataService } from 'src/app/services/data.service';
import { AddStudentCutPaymentComponent } from './add-student-cut-payment/add-student-cut-payment.component';
import { DeleteComponent } from 'src/app/components/delete/delete.component';
import { Registration } from 'src/app/stores/registration.store';
import { ConfirmDeleteComponent } from 'src/app/components/confirm-delete/confirm-delete.component';
import { Payment } from 'src/app/stores/payment.store';
import { Environment } from 'src/app/stores/environment.store';
import { CONFIG } from 'src/app/dummy/config';
import { AddMatchTermSchoolFeeComponent } from './add-match-term-school-fee/add-match-term-school-fee.component';
import { AddPaymentCrossYearComponent } from './add-payment-cross-year/add-payment-cross-year.component';

@Component({
  selector: 'app-student-cut-payment',
  templateUrl: './student-cut-payment.component.html',
  styleUrls: ['./student-cut-payment.component.scss']
})
export class StudentCutPaymentComponent implements OnInit {
  id: string;
  programKey: any;
  testingKey: any;

  constructor(
    public store: Student,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public base: BaseStore,
    private ds: DataService,
    private snackBar: MatSnackBar,
    public registrationStore: Registration,
    public payment: Payment,
    public env: Environment,
  ) { }

  ngOnInit() {
    this.route.parent.params.forEach((params) => {
      const { id, programKey, testingKey } = params;
      this.id = id;
      this.store.programKey = programKey || null;
      this.testingKey = testingKey || null;
      this.store.fetchStudentDocID(this.id, res => {
        this.store.fetchStudentTest(this.testingKey, this.id);
      });
      this.store.fetchStudentAlignPayment(this.id);
    });
  }

  _onAddPayment() {
    if (!this.store.selectedAdmission) return;
    const isUnPaid = this.store.alignPayment.filter((m) => !m.isPaid).length > 0;
    if (isUnPaid) return

    const { selectedSchool } = this.env

    if (selectedSchool.key === CONFIG.sps) {

      let dialogRef = this.dialog.open(AddStudentCutPaymentComponent, {
        data: null,
        panelClass: "register-test-overlay-panel",
        width: "35vw",
        height: "100vh",
        disableClose: true,
        role: "dialog",
        autoFocus: false,
      });
      dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
      dialogRef.afterClosed().subscribe(result => {

      });

    } else {

      let dialogRef = this.dialog.open(AddMatchTermSchoolFeeComponent, {
        data: null,
        panelClass: "register-test-overlay-panel",
        width: "35vw",
        height: "100vh",
        disableClose: true,
        role: "dialog",
        autoFocus: false,
      });
      dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
      dialogRef.afterClosed().subscribe(result => {

      });
    }

  }

  _onAddPaymentCrossYear() {
    if (!this.store.selectedAdmission) return;
    let dialogRef = this.dialog.open(AddPaymentCrossYearComponent, {
      data: null,
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: true,
      role: "dialog",
      autoFocus: false,
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  // _onUpdateAdmission(item) {
  //   let dialogRef = this.dialog.open(UpdateStudentAdmissionComponent, {
  //     data: item,
  //     panelClass: "register-test-overlay-panel",
  //     width: "35vw",
  //     height: "100vh",
  //     disableClose: false,
  //     role: "dialog",
  //     autoFocus: false,
  //   });
  //   dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
  //   dialogRef.afterClosed().subscribe(result => {

  //   });
  // }

  delete(item: any) {
    if (item.isPaid) return
    let dialogRef = this.dialog.open(DeleteComponent, {
      data: { title: 'Remove Student Align Payment', memo: 'If student align payment will be remove from system.', name: item.student.full_name },
      width: '35vw',
      disableClose: true,
      role: 'dialog',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'yes') {
        this.registrationStore.deleteAlignPayment(item, (success, error) => {
          if (success) {
            this.snackBar.open('Student Align Payment has been removed.', 'done', { duration: 2000 });
          }
          else {
            this.snackBar.open(error, 'Error')
          }
        })
      }
    });
  }

}
