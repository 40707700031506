import { DataService } from 'src/app/services/data.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { tabs } from './../../dummy/tabs';
import { Component, OnInit } from '@angular/core';
import { BaseStore } from 'src/app/stores/base.store';
import { Environment } from 'src/app/stores/environment.store';
import { AddNewOtherPaymentFeeComponent } from './add-new-other-payment-fee/add-new-other-payment-fee.component';
import { EditOtherPaymentFeeComponent } from './edit-other-payment-fee/edit-other-payment-fee.component';
import { DeleteComponent } from 'src/app/components/delete/delete.component';
import { OtherService } from 'src/app/stores/otherService.store';

@Component({
  selector: 'app-other-payment-fee',
  templateUrl: './other-payment-fee.component.html',
  styleUrls: ['./other-payment-fee.component.scss']
})
export class OtherPaymentFeeComponent implements OnInit {
  tabs = tabs.otherPaymentFee;
  constructor(
    public store: OtherService,
    public baseStore: BaseStore,
    private ds: DataService,
    public dialog: MatDialog,
    public env: Environment,
    private snackBar: MatSnackBar,
  ) { }

  async ngOnInit() {
    await this.env.fetchUserDoc()
    const { selectedSchool } = this.env
    this.store.fetchOtherServiceFee(selectedSchool.key);
  }

  create() {
    let dialogRef = this.dialog.open(AddNewOtherPaymentFeeComponent, {
      data: null,
      width: '35vw',
      height: '100vh',
      role: 'dialog',
    });
    dialogRef.updatePosition({ top: '0', right: '0', bottom: '0' });
  }

  edit(item: any) {
    let dialogRef = this.dialog.open(EditOtherPaymentFeeComponent, {
      data: item,
      width: '35vw',
      height: '100vh',
      role: 'dialog',
    });
    dialogRef.updatePosition({ top: '0', right: '0', bottom: '0' });
  }

  delete(item: any) {
    let dialogRef = this.dialog.open(DeleteComponent, {
      data: { title: 'Delete Other Service Fees', memo: 'If other service fees is using by other function in system you cannot delete it.', name: item.name },
      width: '35vw',
      disableClose: true,
      role: 'dialog',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'yes') {
        const { selectedSchool } = this.env
        this.baseStore.delete(this.ds.otherServiceFeeRef(selectedSchool.key), item, (success, error) => {
          if (success) {
            this.snackBar.open('Other Service Fees has been deleted.', 'done', { duration: 2000 });
          }
          else {
            this.snackBar.open(error, 'Error')
          }
        })
      }
    });
  }

}
