import { ConvertService } from 'src/app/services/convert.service';
import { MatSnackBar } from '@angular/material';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { Student } from 'src/app/stores/student.store';
import { Payment } from 'src/app/stores/payment.store';
import { Environment } from 'src/app/stores/environment.store';
import { academicYearObj, campusObj, MappingService, schoolObj, toNumber } from 'src/app/services/mapping.service';
import { DataService } from 'src/app/services/data.service';
import { Invoice } from 'src/app/stores/invoice.store';
import { Registration } from 'src/app/stores/registration.store';
import { BaseStore } from 'src/app/stores/base.store';
import { DISCOUNT_TYPES, enrollPrograms, invoiceTypesObj, paymentStatus } from 'src/app/dummy/status';
import { IInvoice } from 'src/app/interfaces/invoice';

@Component({
  selector: 'app-add-capital-fee',
  templateUrl: './add-capital-fee.component.html',
  styleUrls: ['./add-capital-fee.component.scss']
})
export class AddCapitalFeeComponent implements OnInit {
  form: FormGroup;
  academic_year: AbstractControl;
  education_level: AbstractControl;
  capital_fee: AbstractControl;
  discount_type: AbstractControl;
  discount: AbstractControl;

  discountTypeList = DISCOUNT_TYPES

  totalFee: number;
  constructor(
    public dialogRef: MatDialogRef<AddCapitalFeeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    public store: Registration,
    public ds: DataService,
    public env: Environment,
    public studentStore: Student,
    public invoice: Invoice,
    public baseStore: BaseStore,
    public payment: Payment,
  ) { }

  buildForm(): void {
    this.form = this.fb.group({
      academic_year: [null, Validators.compose([Validators.required, MappingService.validSelected.bind(this)])],
      education_level: [null, Validators.compose([Validators.required, MappingService.validSelected.bind(this)])],
      capital_fee: [null, Validators.compose([Validators.required, MappingService.validSelected.bind(this)])],
      discount_type: [DISCOUNT_TYPES[0], Validators.required],
      discount: [null, [Validators.compose([this.validCashIn.bind(this)])]],
    })
    this.academic_year = this.form.controls['academic_year'];
    this.education_level = this.form.controls['education_level'];
    this.capital_fee = this.form.controls['capital_fee'];
    this.discount_type = this.form.controls["discount_type"];
    this.discount = this.form.controls["discount"];
  }

  public validCashIn(control: AbstractControl): { [s: string]: boolean } {
    const value = control.value;
    if (value !== undefined && value !== null && value !== "") {
      if (value < 0) {
        return { validAmount: true }
      }

      const { discount_type, capital_fee } = this.form.value
      this.totalFee = capital_fee ? capital_fee.price : 0

      if (value > 100 && discount_type.key === 1) {
        return { validKey: true }
      }

      if (capital_fee && discount_type && value) {
        const { price } = capital_fee
        this.totalFee = discount_type.key == 1 ? (price - ((value * price) / 100)) : price - value
      }
    }
  }

  _onDiscount(event) {
    const { capital_fee } = this.form.value
    this.totalFee = capital_fee ? capital_fee.price : 0
    this.discount.patchValue(null)
  }

  currentYear;
  selectedAcademicYear;
  process: boolean = true;

  academicYearList = []
  educationLevelList = []
  capitalFeeList = []
  async ngOnInit() {
    this.buildForm();

    const { program_academic } = this.studentStore.selectedAdmission;
    await this.env.fetchUserDoc()
    const { selectedSchool, selectedCampus } = this.env
    const envData: any = await this.env.fetchAcademicEnv(selectedSchool.key);

    let academicYearDoc = await this.baseStore.fetchList(this.ds.storeDocRef(selectedSchool.key).collection("academic_year", ref => ref.limit(50)))

    if (program_academic) {
      const { program } = program_academic;
      if (program.programOption.key === 1) {
        this.currentYear = envData.year;
        academicYearDoc = academicYearDoc.filter(m => m.termType.key === 1)

      } else {
        this.currentYear = envData.term;
        academicYearDoc = academicYearDoc.filter(m => m.termType.key === 2)
      }
    }

    this.academicYearList = MappingService.orderByDesc(academicYearDoc, "startDate")
    this.selectedAcademicYear = this.academicYearList.find(m => m.key === this.currentYear.key)
    this.academic_year.patchValue(this.selectedAcademicYear)
    const { academic_year } = this.selectedAcademicYear

    this.educationLevelList = await this.baseStore.fetchList(this.ds.storeDocRef(selectedSchool.key).collection("campus").doc(selectedCampus.key).collection("school_year").doc(`${academic_year.key}`).collection("school_education_level", ref => ref.orderBy("order")))
    this.process = false;
  }

  async selectedYear(event) {
    const { value } = event.option
    this.educationLevelList = []

    if (value) {
      const { selectedSchool, selectedCampus } = this.env
      this.selectedAcademicYear = this.academicYearList.find(m => m.key === value.key)
      const { academic_year } = this.selectedAcademicYear
      this.educationLevelList = await this.baseStore.fetchList(this.ds.storeDocRef(selectedSchool.key).collection("campus").doc(selectedCampus.key).collection("school_year").doc(`${academic_year.key}`).collection("school_education_level", ref => ref.orderBy("order")))
    }
  }

  async selectedLevel(event) {
    const { value } = event.option
    this.capitalFeeList = []

    if (value) {
      const { selectedSchool, selectedCampus } = this.env
      const { academic_year } = this.selectedAcademicYear
      this.capitalFeeList = await this.baseStore.fetchList(this.ds.storeDocRef(selectedSchool.key).collection("campus").doc(selectedCampus.key).collection("school_year").doc(`${academic_year.key}`).collection("school_education_level").doc(value.key).collection("capital_fee", ref => ref.orderBy("type.key")))
    }
  }

  selectedFee(event) {
    const { value } = event.option
    this.totalFee = 0
    if (value) {
      this.totalFee = value.price
    }
  }

  compareObjects(o1: any, o2: any): boolean {
    if (o2) return o1.key === o2.key;
  }

  displayItem(item: any): string {
    return item ? item.name : item;
  }

  displayItemType(item: any): string {
    return item ? item.type.name : item;
  }

  create(f: any) {
    if (!this.selectedAcademicYear) return;
    if (this.form.valid) {
      this.form.disable();
      const { selectedCampus, selectedSchool } = this.env
      const { academic_year, education_level, capital_fee, discount_type, discount } = f
      const { startDate, end_year_date } = academic_year
      const { program_academic } = this.studentStore.selectedAdmission;
      const date = new Date();
      const create_by = MappingService.userObj(this.env.user);
      const amount = discount ? toNumber(this.totalFee) : capital_fee.price

      const course = {
        ...MappingService.capitalFeeObj(capital_fee),
        amount,
        discount_type,
        discount,
      }

      const item: IInvoice = {
        key: this.ds.createId(),
        create_date: date,
        create_date_key: ConvertService.toDateKey(date),
        create_by: create_by,
        issue_by: create_by,
        issue_date: date,
        invoice_no: null,
        invoice_type: invoiceTypesObj.capitalFee,
        course: course,
        price: amount,
        amount: amount,

        school_fee_type: enrollPrograms.academic,
        student: MappingService.studentObj(this.studentStore.student),
        verify_by: create_by,
        verify_date: date,
        page_key: ConvertService.pageKey(),
        verify_date_key: ConvertService.toDateKey(date),
        invoice_date: date,
        invoice_date_key: ConvertService.toDateKey(date),
        isPaid: paymentStatus.unpaid,
        isVoid: false,
        program: program_academic,
        byAdmission: false,
        issue_year: academicYearObj(academic_year),
        payment_year: null,
        headerRef: null,
        isHeader: false,
        enroll_status: null,
        isEnrollVerify: true,
        schoolSession: null,
        description: course.name,

        penaltyRef: null,
        penalty: null,
        scholarshipRef: null,
        scholarship: null,
        loan: null,
        prepaidRef: null,
        prepaid: null,

        campusKey: selectedCampus.key,
        campus: campusObj(selectedCampus),
        schoolKey: selectedSchool.key,
        school: schoolObj(selectedSchool),

        fromDate: startDate.toDate(),
        fromDateKey: ConvertService.toDateKey(startDate.toDate()),
        toDate: end_year_date.toDate(),
        toDateKey: ConvertService.toDateKey(end_year_date.toDate()),
      }

      const { invoiceKey, paymentHeader } = this.payment;
      const header = invoiceKey ? this.invoice.paymentHeader : paymentHeader
      this.payment.addCapitalFee(item, header, (success, error) => {
        if (success) {
          this.dialogRef.close()
          this.snackBar.open("Capital Fee has been added to invoice.", "Done", { duration: 2000 })
          this.form.enable();
          this.form.reset();
          this.totalFee = 0
          this.form.patchValue({
            academic_year,
            education_level,
            discount_type,
          })
        }
        else {
          this.snackBar.open(error, "Error");
          this.form.enable();
        }
      })
    }
  }
}