import { ITuition } from "./../interfaces/tuition";
import { DataService } from "./../services/data.service";
import { observable, action } from "mobx";
import { Injectable } from "@angular/core";
import { MappingService } from "../services/mapping.service";
import { ITesting, TestOption } from "../interfaces/testing";
import {
  InvoiceTypesObj,
  recordStatus,
  TestFeeStatus,
  admissionPrograms,
  Status,
  studentStatus,
  invoiceTypesObj,
  enrollPrograms,
  paymentStatus,
  invoiceTypeParams
} from "../dummy/status";
import { Student_PUC_Profile, IStudentAccount, IStudent } from "../interfaces/student";
import { IInvoice } from "../interfaces/invoice";
import { ConvertService } from "../services/convert.service";
import { Environment } from "./environment.store";
import { AdministratorService } from "../services/administrator.service";

@Injectable()
export class Testing {
  @observable public data = null;
  @observable public loading = false;
  @observable public testingExist = false;
  @observable public empty = false;
  @observable public process = false;
  @observable public done = false;
  @observable public fetch = false;
  @observable public lastVisible: any = new Date();
  @observable public institutes = null;
  @observable public selectedProgram = null;
  @observable public invoiceDoc = null;

  @observable public studentInvoices = [];

  constructor(
    private ds: DataService,
    private env: Environment,
    private as: AdministratorService
  ) { }

  @action
  async fetchStudent() {

    const invDoc = await this.ds.dbRef().collection("students").doc("yuLY5VNVyk9gO6XIg36I").collection("invoices").doc("3wOAj25m2AnGgmJjx0Ml").get().toPromise()
    this.invoiceDoc = MappingService.pushToObject(invDoc)

    const studentDoc = await this.ds.dbRef().collection("students", ref => ref
      .where("schoolKey", "==", "ewis_school")
      // .where("puc_id", "==", "0062105")
      // .limit(50)
    ).get().toPromise()
    const studentData = studentDoc.docs.map(m => {
      return {
        key: m.data().key,
        full_name: m.data().full_name,
      }
    })

    this.studentInvoices = []
    await Promise.resolve(

      studentData.forEach(async m => {
        const studentInvDoc = await this.ds.dbRef().collection("students").doc(m.key).collection("invoices", ref => ref
          // .where("isHeader", "==", true)
          .where("isPaid.key", "==", 2)
        ).get().toPromise()

        const studentInvData = studentInvDoc.docs.map(m => {
          return {
            key: m.data().key,
            studentKey: m.data().student.key,
          }
        })

        if(studentInvData && studentInvData.length > 0){
          this.studentInvoices = this.studentInvoices.concat(studentInvData)
        }

      })
    )

  }

  @action
  updateInvoice() {

    if (this.studentInvoices.length === 0) return;

    const endProgramTerm = {
      key: 4,
      text: "Term 4"
    }

    const endProgramYear = {
      key: 2020,
      text: "2020-2021"
    }

    this.studentInvoices.forEach(m => {
      const batch = this.ds.batch()
      const { issue_year, issue_yearKey, issue_yearRef } = this.invoiceDoc
      const { key, studentKey } = m
      const ref = this.ds.firestore().collection("students").doc(studentKey).collection("invoices").doc(key)

      batch.update(ref, {
        endProgramTerm: endProgramTerm,
        endProgramYear: endProgramYear,

        issue_year, issue_yearKey, issue_yearRef
      })

      // batch.commit().then(() => {
      //   console.log('done');
      // })

    })
  }

  @observable
  fetchInstitutes() {
    this.process = true;
    this.ds
      .programRef()
      .valueChanges()
      .subscribe(docs => {
        const data = MappingService.groupBy(
          docs,
          "institute.key",
          "institute.name"
        );
        this.institutes = data;
        this.process = false;
      });
  }

  @observable
  fetchData(user: any, type: string, text: string) {
    this.loading = true;
    this.done = false;
    this.ds.testingCollectionRef(user, type, text).valueChanges().subscribe(docs => {
      const snapshots = MappingService.orderByDesc(docs, "page_key");
      this.data = snapshots;
      if (docs.length) {
        this.lastVisible = docs[docs.length - 1];
      } else {
        this.lastVisible = null;
        this.done = true;
      }
      this.empty = docs.length === 0;
      this.loading = false;
    });
  }

  @action
  fetchInstitute(callback) {
    this.loading = true;
    this.ds
      .programRef()
      .valueChanges()
      .subscribe(docs => {
        if (docs.length > 0) {
          this.data = MappingService.groupBy(
            docs,
            "institute.key",
            "institute.name"
          );
          callback(docs);
        }
        this.loading = false;
      });
  }

  @action
  onScroll(text: string) {
    if (this.fetch || this.done) return;
    this.fetch = true;
    this.ds
      .testingCollectionScrollRef(text, this.lastVisible)
      .valueChanges()
      .subscribe(docs => {
        if (docs.length) {
          this.lastVisible = docs[docs.length - 1];
        } else {
          this.done = true;
        }
        this.data = this.data.slice().concat(docs);
        this.fetch = false;
      });
  }

  @action
  addTest(item: ITesting, config: TestOption, user: any, callback) {
    this.process = true;
    this.as.updatePucIdAndInvoice((success, res) => {
      if (success) {
        const batch = this.ds.batch();
        const testingKey = this.ds.createId();
        const studentKey = this.ds.createId();
        const studentProfileKey = this.ds.createId();
        const invoiceKey = this.ds.createId();
        const invoiceDetailKey = this.ds.createId();
        const expiredFee = this.ds.expiredFeeDate(new Date(), config);

        const invoiceType = InvoiceTypesObj.testing;
        const serial = res.serial_id;
        const serial_id = serial.toString();
        const invoiceNo = res.invoiceNo;

        const studentData: IStudent = {
          key: studentKey,
          unique_code: null,
          first_name: item.first_name,
          last_name: item.last_name,
          khmer_first_name: null,
          khmer_last_name: null,
          gender: null,
          serial_id: serial_id,
          is_puc_student: false,
          dob: item.dob,
          dobKey: ConvertService.toDateKey(item.dob),
          page_key: ConvertService.pageKey(),
          mobile_phone: item.mobile_phone,
          email_address: null,
          pob: null,
          marital_status: null,
          nationality: null,
          home_address: null,
          work_place: null,
          position: null,
          parent_name: null,
          spouse_name: null,
          emergency_person_name: null,
          emergency_relationship: null,
          emergency_address: null,
          emergency_phone: null,

          english_school_name: null,
          english_school_province: null,
          english_school_year: null,
          high_school_name: null,
          high_school_province: null,
          high_school_year: null,
          college_school_name: null,
          college_school_degree: null,
          college_school_major: null,
          college_school_year: null,
          university_school_name: null,
          university_school_degree: null,
          university_school_major: null,
          university_school_year: null,
          graduate_school_name: null,
          graduate_school_degree: null,
          graduate_school_major: null,
          graduate_school_year: null,
          education_type_at_puc: null,
          status: recordStatus.active,
          program_general: null,
          program_academic: null,
          program_type: admissionPrograms.institutes,
          create_date: new Date(),
          create_date_key: ConvertService.dateKey(),
          create_by: user,
          puc_id: null
        };

        item.key = testingKey;
        item.serial_id = serial_id;
        item.student_id = studentKey;
        item.student = MappingService.studentObj(studentData);
        item.testing_invoice_id = invoiceKey;
        item.student_puc_profile = studentProfileKey;
        item.expired_fee = expiredFee;
        item.expired_fee_key = ConvertService.toDateKey(expiredFee);

        const student_puc_profile: Student_PUC_Profile = {
          key: studentProfileKey,
          create_date: new Date(),
          create_by: user,
          major_level: invoiceType,
          program: null,
          term: null,
          student: MappingService.studentObj(studentData)
        };

        const invoice: IInvoice = {
          key: invoiceKey,
          create_date: new Date(),
          create_date_key: ConvertService.dateKey(),
          create_by: user,
          issue_by: user,
          issue_date: new Date(),
          issue_date_key: ConvertService.dateKey(),
          invoice_no: invoiceNo,
          invoice_type: invoiceType,
          payment_type: null,
          student: MappingService.studentObj(studentData),
          verify_by: user,
          verify_date: new Date(),
          verify_date_key: ConvertService.dateKey(),
          invoice_date: new Date(),
          invoice_date_key: ConvertService.toDateKey(new Date()),
          page_key: ConvertService.pageKey(),
          course: config.admission_code,
          isPaid: TestFeeStatus.unpaid,
          isVoid: null,
          expired_date: null,
          expired_date_key: null,
          price: config.admission_code.price,
          amount: config.admission_code.price,
          received_by: null,
          received_date: null,
          received_date_key: null,
          void_by: null,
          void_date: null,
          void_date_key: null,
          issue_term: null,
          major_level: null,
          testRef: testingKey,
          tuition_fee: null,
          SPEL_fee: null,
          late_fee: null,
          ID_card_fee: null,
          enrollment_fee: null,
          admission_fee: null,
          scholarship: null,
          discountPolicy: null,
          prepaid_amount: null,
          discount: null,
          other_deduction: null,
          headerRef: invoiceKey,
          paid_for: null,
          isHeader: true,
          program: null,
          enroll_verify_by: null,
          enroll_verify_date: null,
          enroll_verify_date_key: null
        };

        const invoiceDetail: IInvoice = {
          key: invoiceDetailKey,
          create_date: new Date(),
          create_by: user,
          issue_by: user,
          issue_date: new Date(),
          invoice_no: invoiceNo,
          invoice_type: invoiceType,
          payment_type: null,
          student: MappingService.studentObj(studentData),
          verify_by: user,
          verify_date: new Date(),
          verify_date_key: ConvertService.dateKey(),
          invoice_date: new Date(),
          invoice_date_key: ConvertService.toDateKey(new Date()),
          page_key: ConvertService.pageKey(),
          course: config.admission_code,
          isPaid: TestFeeStatus.unpaid,
          isVoid: null,
          expired_date: null,
          expired_date_key: null,
          price: config.admission_code.price,
          amount: config.admission_code.price,
          received_by: null,
          received_date: null,
          received_date_key: null,
          void_by: null,
          void_date: null,
          void_date_key: null,
          issue_term: null,
          major_level: null,
          testRef: testingKey,
          tuition_fee: null,
          SPEL_fee: null,
          late_fee: null,
          ID_card_fee: null,
          enrollment_fee: null,
          admission_fee: null,
          scholarship: null,
          discountPolicy: null,
          prepaid_amount: null,
          discount: null,
          other_deduction: null,
          headerRef: invoiceKey,
          paid_for: null,
          isHeader: false,
          program: null,
          enroll_verify_by: null,
          enroll_verify_date: null,
          enroll_verify_date_key: null
        };

        const studentRef = this.ds.studentFireRef().doc(studentData.key);
        const studentPucProfileRef = this.ds
          .studentPucProfileFireRef()
          .doc(student_puc_profile.key);
        const testingRef = this.ds.testingFireRef().doc(item.key);
        const invoiceRef = this.ds.invoicesFireRef().doc(invoice.key);
        const invoiceDetailRef = this.ds
          .invoicesFireRef()
          .doc(invoiceDetail.key);

        batch.set(studentRef, studentData);
        batch.set(studentPucProfileRef, student_puc_profile);
        batch.set(testingRef, item);
        batch.set(invoiceRef, invoice);
        batch.set(invoiceDetailRef, invoiceDetail);

        batch
          .commit()
          .then(() => {
            callback(true, item);
            this.process = false;
          })
          .catch(error => {
            callback(false, error);
            this.process = false;
          });
      } else {
        callback(false, res);
        this.process = false;
      }
    });
  }

  @action
  delete(form: ITuition, callback) {
    this.ds
      .tuitionFeeRef()
      .doc(form.key)
      .delete()
      .then(() => {
        callback(true, null);
      })
      .catch(error => {
        callback(false, error);
      });
  }

  @action
  checkExistTest(form: ITesting, callback) {
    this.process = true;
    this.ds.testingExistRef(form).get().then(docs => {
      if (!docs.empty) {
        let data = null;
        docs.forEach(docs => {
          this.testingExist = true;
          data = docs.data();
        })
        this.process = false;
        callback(true, data);
      } else {
        this.testingExist = false;
        this.process = false;
        callback(false, null);
      }
    })
  }

  @action
  retakeTesting(f: ITesting, user: any, testingOption: any, callback) {
    this.process = true;
    this.as.updateSerialId((success, res) => {
      if (success) {
        const batch = this.ds.batch();
        const { student } = f
        const testingRef = this.ds.testingFireRef().doc(f.key);
        const studentInvoiceRef = this.ds.studentFireRef().doc(student.key).collection("invoices");
        const studentRef = this.ds.studentFireRef().doc(student.key);
        const serialID = res.serial_number + 1;
        const serial_id = 'S' + serialID.toString();
        f.serial_id = serial_id;
        student.serial_id = serial_id;
        const headerKey = this.ds.createId();
        const date = new Date();
        const create_by = MappingService.userObj(user);
        const campus = MappingService.campusObj(user.campus);
        const invoiceNo = null;

        const invoiceHeader: IInvoice = {
          key: headerKey,
          display_program: "Testing",
          display_level: null,
          display_shift: null,
          create_date: date,
          create_date_key: ConvertService.toDateKey(date),
          create_by: create_by,
          issue_by: create_by,
          issue_date: date,
          invoice_no: invoiceNo,
          invoice_type: invoiceTypesObj.testing,
          school_fee_type: enrollPrograms.institutes,
          student: MappingService.studentObj(student),
          verify_by: create_by,
          verify_date: date,
          page_key: ConvertService.pageKey(),
          verify_date_key: ConvertService.toDateKey(date),
          invoice_date: date,
          invoice_date_key: ConvertService.toDateKey(date),
          course: {
            key: f.test_type.key,
            text: f.test_type.shortName,
            name: f.test_type.name,
          },
          isPaid: paymentStatus.unpaid,
          isVoid: false,
          program: invoiceTypeParams.testing,
          byAdmission: false,
          price: testingOption.price,
          amount: testingOption.price,
          issue_term: null,
          payment_term: null,
          headerRef: headerKey,
          isHeader: true,
          isEnrollVerify: true,
          campus: campus,
          schoolSession: null,
          description: null,
          penalty: null,
          prepaid: null,
          scholarship: null,
          scholarshipRef: null,
          prepaidRef: null,
          penaltyRef: null,
        }

        const invoiceDetail: IInvoice = {
          key: this.ds.createId(),
          display_program: "Testing",
          display_level: null,
          display_shift: null,
          create_date: date,
          create_date_key: ConvertService.toDateKey(date),
          create_by: create_by,
          issue_by: create_by,
          issue_date: date,
          invoice_no: invoiceNo,
          invoice_type: invoiceTypesObj.testing,
          school_fee_type: enrollPrograms.institutes,
          student: MappingService.studentObj(student),
          verify_by: create_by,
          verify_date: date,
          page_key: ConvertService.pageKey(),
          verify_date_key: ConvertService.toDateKey(date),
          invoice_date: date,
          invoice_date_key: ConvertService.toDateKey(date),
          course: null,
          isPaid: paymentStatus.unpaid,
          isVoid: false,
          program: f.test_type,
          byAdmission: false,
          price: testingOption.price,
          amount: testingOption.price,
          issue_term: null,
          payment_term: null,
          headerRef: headerKey,
          isHeader: false,
          isEnrollVerify: true,
          campus: campus,
          schoolSession: null,
          description: null,
          scholarship: null,
          scholarshipRef: null,
        }

        batch.set(studentInvoiceRef.doc(invoiceHeader.key), invoiceHeader);
        batch.set(studentInvoiceRef.doc(invoiceDetail.key), invoiceDetail);
        batch.set(testingRef, f);
        batch.update(studentRef, { serial_id: serial_id });

        batch.commit().then(() => {
          callback(true, f);
          this.process = false;
        })
          .catch(error => {
            callback(false, error);
            this.process = false;
          });
      }
    });
  }

  @action
  register(f: ITesting, user: any, testingOption: any, callback) {
    this.process = true;
    this.as.updateSerialId((success, res) => {
      if (success) {
        const batch = this.ds.batch();
        const studentKey = this.ds.createId();

        const serialID = res.serial_number + 1;
        const serial_id = 'S' + serialID.toString();
        const last_name = f.last_name.trim().toUpperCase();
        const first_name = f.first_name.trim().toUpperCase();

        const programAcademic = {
          category: {
            key: ConvertService.toNull(f.institute.key),
            name: ConvertService.toNull(f.institute.name),
          },
          type: ConvertService.toNull(f.institute.instituteType),
          key: ConvertService.toNull(f.test_type.key),
          name: ConvertService.toNull(f.test_type.name),
        }

        f.serial_id = serial_id;
        f.first_name = first_name;
        f.last_name = last_name;
        f.full_name = last_name + ' ' + first_name;
        f.key = this.ds.createId();

        const studentData: IStudent = {
          key: studentKey,
          unique_code: f.unique_code,
          first_name: first_name,
          last_name: last_name,
          khmer_first_name: f.khmer_first_name,
          khmer_last_name: f.khmer_last_name,
          full_name: last_name + ' ' + first_name,
          address: f.address,
          gender: f.gender,
          serial_id: f.serial_id,
          puc_id: f.puc_id,
          recent_test_key: f.key,
          is_puc_student: false,
          dob: f.dob,
          dobKey: ConvertService.toDateKey(f.dob),
          page_key: ConvertService.pageKey(),
          mobile_phone: f.mobile_phone,
          email: null,
          email_address: f.email_address,
          pob: null,
          marital_status: null,
          nationality: null,
          home_address: null,
          work_place: null,
          position: null,
          parent_name: null,
          spouse_name: null,
          emergency_person_name: null,
          emergency_relationship: null,
          emergency_address: null,
          emergency_phone: null,

          english_school_name: null,
          english_school_province: null,
          english_school_year: null,
          high_school_name: null,
          high_school_province: null,
          high_school_year: null,
          college_school_name: null,
          college_school_degree: null,
          college_school_major: null,
          college_school_year: null,
          university_school_name: null,
          university_school_degree: null,
          university_school_major: null,
          university_school_year: null,
          graduate_school_name: null,
          graduate_school_degree: null,
          graduate_school_major: null,
          graduate_school_year: null,
          education_type_at_puc: null,
          status: recordStatus.active,
          program_general: f.institute,
          create_date: new Date(),
          create_date_key: ConvertService.dateKey(),
          create_by: MappingService.userObj(user),
          prepaid: null,
          scholarship: null,
          program_academic: programAcademic,
          is_student_PSIS: false,
          is_student_institute: true,
          is_student_academic: false,
        };

        f.student = MappingService.studentObj(studentData);
        const studentRef = this.ds.studentFireRef().doc(studentData.key);
        const testingRef = this.ds.testingFireRef().doc(f.key);
        const studentInvoiceRef = this.ds.studentFireRef().doc(studentData.key).collection("invoices");

        const headerKey = this.ds.createId();
        const date = new Date();
        const create_by = MappingService.userObj(user);
        const campus = MappingService.campusObj(user.campus);
        const invoiceNo = null;

        const invoiceHeader: IInvoice = {
          key: headerKey,
          display_program: "Testing",
          display_level: null,
          display_shift: null,
          create_date: date,
          create_date_key: ConvertService.toDateKey(date),
          create_by: create_by,
          issue_by: create_by,
          issue_date: date,
          invoice_no: invoiceNo,
          invoice_type: invoiceTypesObj.testing,
          school_fee_type: enrollPrograms.institutes,
          student: MappingService.studentObj(studentData),
          verify_by: create_by,
          verify_date: date,
          page_key: ConvertService.pageKey(),
          verify_date_key: ConvertService.toDateKey(date),
          invoice_date: date,
          invoice_date_key: ConvertService.toDateKey(date),
          course: {
            key: f.test_type.key,
            text: f.test_type.shortName,
            name: f.test_type.name,
          },
          isPaid: paymentStatus.unpaid,
          isVoid: false,
          program: invoiceTypeParams.testing,
          byAdmission: false,
          price: testingOption.price,
          amount: testingOption.price,
          issue_term: null,
          payment_term: null,
          headerRef: headerKey,
          isHeader: true,
          isEnrollVerify: true,
          campus: campus,
          schoolSession: null,
          description: null,
          penalty: null,
          prepaid: null,
          scholarship: null,
          scholarshipRef: null,
          prepaidRef: null,
          penaltyRef: null,
        }

        const invoiceDetail: IInvoice = {
          key: this.ds.createId(),
          display_program: "Testing",
          display_level: null,
          display_shift: null,
          create_date: date,
          create_date_key: ConvertService.toDateKey(date),
          create_by: create_by,
          issue_by: create_by,
          issue_date: date,
          invoice_no: invoiceNo,
          invoice_type: invoiceTypesObj.testing,
          school_fee_type: enrollPrograms.institutes,
          student: MappingService.studentObj(studentData),
          verify_by: create_by,
          verify_date: date,
          page_key: ConvertService.pageKey(),
          verify_date_key: ConvertService.toDateKey(date),
          invoice_date: date,
          invoice_date_key: ConvertService.toDateKey(date),
          course: null,
          isPaid: paymentStatus.unpaid,
          isVoid: false,
          program: f.test_type,
          byAdmission: false,
          price: testingOption.price,
          amount: testingOption.price,
          issue_term: null,
          payment_term: null,
          headerRef: headerKey,
          isHeader: false,
          isEnrollVerify: true,
          campus: campus,
          schoolSession: null,
          description: null,
          scholarship: null,
          scholarshipRef: null,
        }
        batch.set(studentInvoiceRef.doc(invoiceHeader.key), invoiceHeader);
        batch.set(studentInvoiceRef.doc(invoiceDetail.key), invoiceDetail);
        batch.set(studentRef, studentData);
        batch.set(testingRef, f);

        batch.commit().then(() => {
          callback(true, f);
          this.process = false;
        })
          .catch(error => {
            callback(false, error);
            this.process = false;
          });
      }
    })
  }

  @action
  retakeTest(f: ITesting, user: any, student: any, testingOption, callback) {
    this.process = true;
    this.as.updateSerialId((success, res) => {
      if (success) {
        const batch = this.ds.batch();
        const studentKey = this.ds.createId();
        const serialID = res.serial_number + 1;
        const serial_id = 'S' + serialID.toString();
        const last_name = f.last_name.trim().toUpperCase();
        const first_name = f.first_name.trim().toUpperCase();
        const programAcademic = {
          category: {
            key: ConvertService.toNull(f.institute.key),
            name: ConvertService.toNull(f.institute.name),
          },
          type: ConvertService.toNull(f.institute.instituteType),
          key: ConvertService.toNull(f.test_type.key),
          name: ConvertService.toNull(f.test_type.name),
        }

        f.serial_id = serial_id;
        f.puc_id = ConvertService.toNull(student.puc_id);
        f.first_name = first_name;
        f.last_name = last_name;
        f.full_name = last_name + ' ' + first_name;
        f.key = this.ds.createId();

        const studentData: IStudent = {
          key: student.key,
          unique_code: f.unique_code,
          first_name: first_name,
          last_name: last_name,
          khmer_first_name: f.khmer_first_name,
          khmer_last_name: f.khmer_last_name,
          full_name: last_name + ' ' + first_name,
          address: f.address,
          gender: f.gender,
          serial_id: f.serial_id,
          puc_id: f.puc_id,
          recent_test_key: f.key,
          is_puc_student: false,
          dob: f.dob,
          dobKey: ConvertService.toDateKey(f.dob),
          page_key: ConvertService.pageKey(),
          mobile_phone: f.mobile_phone,
          email: student.email,
          email_address: f.email_address,
          pob: null,
          marital_status: null,
          nationality: null,
          home_address: null,
          work_place: null,
          position: null,
          parent_name: null,
          spouse_name: null,
          emergency_person_name: null,
          emergency_relationship: null,
          emergency_address: null,
          emergency_phone: null,

          english_school_name: null,
          english_school_province: null,
          english_school_year: null,
          high_school_name: null,
          high_school_province: null,
          high_school_year: null,
          college_school_name: null,
          college_school_degree: null,
          college_school_major: null,
          college_school_year: null,
          university_school_name: null,
          university_school_degree: null,
          university_school_major: null,
          university_school_year: null,
          graduate_school_name: null,
          graduate_school_degree: null,
          graduate_school_major: null,
          graduate_school_year: null,
          education_type_at_puc: null,
          status: recordStatus.active,
          program_general: f.institute,
          create_date: new Date(),
          create_date_key: ConvertService.dateKey(),
          create_by: user,
          prepaid: null,
          scholarship: null,
          program_academic: programAcademic,
          is_student_PSIS: false,
          is_student_institute: true,
          is_student_academic: false,
        };

        f.student = MappingService.studentObj(studentData);
        const studentRef = this.ds.studentFireRef().doc(studentData.key);
        const testingRef = this.ds.testingFireRef().doc(f.key);
        const studentInvoiceRef = this.ds.studentFireRef().doc(studentData.key).collection("invoices");

        const headerKey = this.ds.createId();
        const date = new Date();
        const create_by = MappingService.userObj(user);
        const campus = MappingService.campusObj(user.campus);
        const invoiceNo = null;

        const invoiceHeader: IInvoice = {
          key: headerKey,
          display_program: "Testing",
          display_level: null,
          display_shift: null,
          create_date: date,
          create_date_key: ConvertService.toDateKey(date),
          create_by: create_by,
          issue_by: create_by,
          issue_date: date,
          invoice_no: invoiceNo,
          invoice_type: invoiceTypesObj.testing,
          school_fee_type: enrollPrograms.institutes,
          student: MappingService.studentObj(studentData),
          verify_by: create_by,
          verify_date: date,
          page_key: ConvertService.pageKey(),
          verify_date_key: ConvertService.toDateKey(date),
          invoice_date: date,
          invoice_date_key: ConvertService.toDateKey(date),
          course: {
            key: f.test_type.key,
            text: f.test_type.shortName,
            name: f.test_type.name,
          },
          isPaid: paymentStatus.unpaid,
          isVoid: false,
          program: invoiceTypeParams.testing,
          byAdmission: false,
          price: testingOption.price,
          amount: testingOption.price,
          issue_term: null,
          payment_term: null,
          headerRef: headerKey,
          isHeader: true,
          isEnrollVerify: true,
          campus: campus,
          schoolSession: null,
          description: null,
          penalty: null,
          prepaid: null,
          scholarship: null,
          scholarshipRef: null,
          prepaidRef: null,
          penaltyRef: null,
        }

        const invoiceDetail: IInvoice = {
          key: this.ds.createId(),
          display_program: "Testing",
          display_level: null,
          display_shift: null,
          create_date: date,
          create_date_key: ConvertService.toDateKey(date),
          create_by: create_by,
          issue_by: create_by,
          issue_date: date,
          invoice_no: invoiceNo,
          invoice_type: invoiceTypesObj.testing,
          school_fee_type: enrollPrograms.institutes,
          student: MappingService.studentObj(studentData),
          verify_by: create_by,
          verify_date: date,
          page_key: ConvertService.pageKey(),
          verify_date_key: ConvertService.toDateKey(date),
          invoice_date: date,
          invoice_date_key: ConvertService.toDateKey(date),
          course: null,
          isPaid: paymentStatus.unpaid,
          isVoid: false,
          program: f.test_type,
          byAdmission: false,
          price: testingOption.price,
          amount: testingOption.price,
          issue_term: null,
          payment_term: null,
          headerRef: headerKey,
          isHeader: false,
          isEnrollVerify: true,
          campus: campus,
          schoolSession: null,
          description: null,
          scholarship: null,
          scholarshipRef: null,
        }

        batch.set(studentInvoiceRef.doc(invoiceHeader.key), invoiceHeader);
        batch.set(studentInvoiceRef.doc(invoiceDetail.key), invoiceDetail);
        batch.update(studentRef, studentData);
        batch.set(testingRef, f);

        batch.commit().then(() => {
          callback(true, f);
          this.process = false;
        })
          .catch(error => {
            callback(false, error);
            this.process = false;
          });

      }
    })
  }

}
