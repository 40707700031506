import { AddUnpaidAcademicComponent } from './../../../components/add-unpaid-academic/add-unpaid-academic.component';
import { AddUnpaidPaymentComponent } from './../../../components/add-unpaid-payment/add-unpaid-payment.component';
import { Unpaid } from './../../../stores/unpaid.store';
import { MatDialog } from '@angular/material';
import { Environment } from './../../../stores/environment.store';
import { Student } from 'src/app/stores/student.store';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { admissionType } from 'src/app/dummy/status';
import { InvoiceDialogComponent } from 'src/app/components/invoice-dialog/invoice-dialog.component';
import { AddPrepaidStudentComponent } from 'src/app/components/add-prepaid-student/add-prepaid-student.component';

@Component({
  selector: 'app-unpaid-payment',
  templateUrl: './unpaid-payment.component.html',
  styleUrls: ['./unpaid-payment.component.scss']
})
export class UnpaidPaymentComponent implements OnInit {
  studentKey = null;
  admissionKey = null;
  disabled = true;
  isAdmin = false;
  constructor(
    private route: ActivatedRoute,
    public store: Student,
    public env: Environment,
    public dialog: MatDialog,
    public unpaid: Unpaid,
    private router: Router
  ) { }

  ngOnInit() {
    this.route.parent.params.forEach(params => {
      this.studentKey = params["id"];
      this.admissionKey = params["admissionKey"];
      this.unpaid.fetchData(this.studentKey);
      this.disabled = false;
    });

    this.env.getUser(res => {
      this.isAdmin = false;
      if (res && res.email === "sitha.b@puc.edu.kh") {
        this.isAdmin = true;
      }
    })
  }

  _onEnglishProgram() {
    if (this.unpaid.paidInstitute) return;
    if (this.store.admissions.length > 0) {
      const haveAdmissionDoc = this.store.admissions.filter(m => m.admission_type.key === admissionType.institute.key);
      if (haveAdmissionDoc.length > 0) {
        this.store.selectedAdmission = haveAdmissionDoc[0];
      }
    }
    let dialogRef = this.dialog.open(AddUnpaidPaymentComponent, {
      data: null,
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: true,
      role: "dialog"
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => { });
  }

  _onAcademicProgram() {
    // if (this.unpaid.paidAcademic) return;
    if (this.store.admissions.length > 0) {
      const haveAdmissionDoc = this.store.admissions.filter(m => m.admission_type.key === admissionType.academic.key);
      if (haveAdmissionDoc.length > 0) {
        this.store.selectedAdmission = haveAdmissionDoc[0];
      } else return;
    }
    let dialogRef = this.dialog.open(AddUnpaidAcademicComponent, {
      data: null,
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: true,
      role: "dialog"
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => { });
  }

  _onAddPrepaid() {
    // if (this.unpaid.paidAcademic) return;
    if (this.store.admissions.length > 0) {
      const haveAdmissionDoc = this.store.admissions.filter(m => m.admission_type.key === admissionType.academic.key);
      if (haveAdmissionDoc.length > 0) {
        this.store.selectedAdmission = haveAdmissionDoc[0];
      } else return;
    }
    let dialogRef = this.dialog.open(AddPrepaidStudentComponent, {
      data: null,
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: true,
      role: "dialog"
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => { });
  }

  _onPrint(item) {
    let dialogRef = this.dialog.open(InvoiceDialogComponent, {
      data: { studentKey: item.student.key, headerKey: item.headerRef },
      width: '90vw',
      height: '80vh',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  _onPay(item) {
    const { isPaid, program, student } = item;
    if (isPaid.key === 2) {
      this.router.navigate([`/student/${student.key}/${program.admissionKey}/new-invoice/invoice-no/${item.key}`]);
    }
    else {
      this._onPrint(item)
    }
  }

}
