import { AuthService } from './../../auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Environment } from 'src/app/stores/environment.store';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(
    public auth: AuthService,
    private router: Router,
    public env: Environment,
    public dialog: MatDialog,

  ) { }

  logOut() {
    this.auth.signOut().then(success => {
      this.router.navigate(['/']);
    });
  }

  async ngOnInit() {
    await this.env.fetchUserDoc();
  }

}
