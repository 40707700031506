import { observable, computed, action, autorun } from "mobx";
import { Injectable } from "@angular/core";
import { DataService } from "../services/data.service";

@Injectable()
export class Miscellaneous {
  @observable data = [];
  @observable fees = null;

  @observable loading = false;
  @observable isFirstLoad = true;

  constructor(private ds: DataService) { }

  @action
  fetchStudentByID(pucID: string) {
    // this.loading = true;
    // this.ds.studentByIDRef(pucID).valueChanges().subscribe((docs) => {
    //   if(docs.length > 0){
    //     this.student = docs[0];
    //     this.fetchMisFee();
    //   }
    //   else 
    //     this.student = null;
    //   this.loading = false;
    //   this.isFirstLoad = false;
    // });
  }

  @action
  fetchMisFee() {
    this.loading = true;
    this.ds.miscellaneousRef().valueChanges().subscribe((docs) => {
      if (docs.length > 0)
        this.fees = docs;
      this.loading = false;
    });
  }

  @action
  fetchAllMis(callback) {
    // this.loading = true;
    //   this.ds.miscellaneousRef().valueChanges().subscribe((docs) => {
    //     this.loading = false;
    //     callback(docs);
    //   });
  }

  @action
  fetchEachMis(key: string, callback) {
    // this.loading = true;
    //   this.ds.miscellaneousDoc(key).valueChanges().subscribe((docs) => {
    //     this.loading = false;
    //     callback(docs);
    //   });
  }

}
