import { ITrainingFee } from './../interfaces/tuition';
import { DataService } from "./../services/data.service";
import { observable, action } from "mobx";
import { Injectable } from "@angular/core";
import { MappingService } from "../services/mapping.service";
import { ISetupTestingFee } from "../interfaces/tuition";
import { termsModulesObj } from '../dummy/status';

@Injectable()
export class Institute {
  @observable
  public data = null;
  @observable
  public testing = null;
  @observable
  public loading = false;
  @observable
  public empty = false;

  @observable
  public selectedInstitute = null;
  @observable
  public programs = null;
  @observable
  public programLevel = null;
  @observable
  public loadingProgram = false;
  @observable
  public emptyProgram = false;
  @observable
  public program = null;
  @observable
  public selectedProgram = null;
  @observable
  public level = null;

  @observable
  public process = false;

  constructor(private ds: DataService) { }
  @action
  fetchProgramDoc(key) {
    this.process = true;
    this.ds
      .programRef().doc(key)
      .valueChanges()
      .subscribe(docs => {
        this.selectedProgram = docs;
        this.process = false;
      });
  }

  @action
  fetchTrainingLevel(key) {
    this.loading = true;
    this.ds
      .trainingLevelByProgramRef(key)
      .valueChanges()
      .subscribe(docs => {
        this.level = MappingService.orderBy(docs,"page_key");
        this.empty = docs.length === 0;
        this.loading = false;
      });
  }

  @action
  fetchProgram() {
    this.loading = true;
    this.data = null;
    this.ds
      .programRef()
      .valueChanges()
      .subscribe(docs => {
        this.empty = docs.length === 0;
        this.data = docs;
        this.loading = false;
      });
  }

  @action
  fetchProgramLevel(programKey: string) {
    this.loadingProgram = true;
    this.ds.programLevelRef(programKey).valueChanges().subscribe(docs => {
      this.programLevel = docs;
      this.emptyProgram = docs.length === 0;
      this.loadingProgram = false;
    })
  }

  @action
  fetchData() {
    this.loading = true;
    this.ds
      .institutesRef()
      .valueChanges()
      .subscribe(docs => {
        this.data = docs;
        this.empty = docs.length === 0;
        this.loading = false;
      });
  }

  @action
  fetchSelectedInstitute(key) {
    this.ds.institutesRef().doc(key).valueChanges().subscribe(doc => {
      this.selectedInstitute = doc;
    })
  }

  @action
  fetchProgramById(instituteKey, callback) {
    this.process = true;
    this.ds
      .instituteProgramsRef(instituteKey)
      .valueChanges()
      .subscribe(docs => {
        this.programs = docs;
        this.process = false;
        callback(docs);
      });
  }
  @action
  fetchAllProgram(callback) {
    this.process = true;
    this.data = null;
    this.ds
      .programRef()
      .valueChanges()
      .subscribe(docs => {
        this.program = docs;
        this.process = false;
        callback(docs);
      });
  }
  @action
  fetchProgramLevelToArray(programKey, callback) {
    this.program = true;
    this.ds
      .trainingLevelByProgramRef(programKey)
      .valueChanges()
      .subscribe(docs => {
        this.program = false;
        callback(docs)
      });
  }

  @action
  fetchTermInstitute(instituteKey: string, callback) {
    this.ds.termInstituteActiveRef(termsModulesObj.english_term.key, instituteKey).valueChanges().subscribe(docs => {
      callback(docs)
    })
  }
  @action
  fetchShift(callback) {
    this.process = true;
    this.ds
      .shiftRef()
      .valueChanges()
      .subscribe(docs => {
        this.process = false;
        callback(docs)
      });
  }
  @action
  fetchCampus(callback) {
    this.process = true;
    this.ds
      .campusRef()
      .valueChanges()
      .subscribe(docs => {
        this.process = false;
        callback(docs)
      });
  }

  @action
  setupTestingFee(item: ISetupTestingFee, callback) {
    this.process = true;
    const batch = this.ds.batch();
    batch.set(this.ds.firestore().collection("institute_testing_movement").doc(item.key), item);
    batch.update(this.ds.firestore().collection("testType").doc(item.program.key), {
      testingFee: item.fee,
      update_date: new Date(),
      update_by: item.create_by
    })
    batch.commit()
      .then(() => {
        this.process = false;
        callback(true, null);
      })
      .catch(error => {
        this.process = false;
        callback(false, error);
      });
  }
  @action
  setupTrainingFee(item: ITrainingFee, callback) {
    this.process = true;
    const batch = this.ds.batch();

    batch.set(this.ds.firestore().collection("institute_training_movement").doc(item.key), item);
    batch.update(this.ds.firestore().collection("institute_training_level").doc(item.level.key), {
      fee: item.fee,
      update_date: new Date(),
      update_by: item.create_by
    })
    batch.commit()
      .then(() => {
        this.process = false;
        callback(true, null);
      })
      .catch(error => {
        this.process = false;
        callback(false, error);
      });
  }
}
