
import { invoiceTypesObj, enrollPrograms, paymentStatus, EnrollStatus, Status, recordStatus, scholarshipTypes } from 'src/app/dummy/status';
import { ConvertService } from 'src/app/services/convert.service';
import { MatSnackBar } from '@angular/material';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Student } from 'src/app/stores/student.store';
import { Payment } from 'src/app/stores/payment.store';
import { Environment } from 'src/app/stores/environment.store';
import { MappingService } from 'src/app/services/mapping.service';
import { DataService } from 'src/app/services/data.service';
import { Scholarship } from 'src/app/interfaces/scholarship';
import { Invoice } from 'src/app/stores/invoice.store';

@Component({
  selector: 'app-add-discount',
  templateUrl: './add-discount.component.html',
  styleUrls: ['./add-discount.component.scss']
})
export class AddDiscountComponent implements OnInit {
  form: FormGroup;
  discount: AbstractControl;

  dataLists = []
  constructor(
    public dialogRef: MatDialogRef<AddDiscountComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    public store: Payment,
    public ds: DataService,
    public env: Environment,
    public studentStore: Student,
    public invoice: Invoice
  ) { }

  buildForm(): void {
    this.form = this.fb.group({
      discount: [null, Validators.required],
    })
    this.discount = this.form.controls['discount'];
  }

  ngOnInit() {
    this.buildForm();
  }

  create(f: any) {
    if (this.form.valid) {
      this.form.disable();
      const { invoiceKey, paymentHeader } = this.store;
      const header = invoiceKey ? this.invoice.paymentHeader : paymentHeader;
      this.store.addDiscountFee(f.discount, this.studentStore.student, header, (success, error) => {
        if (success) {
          this.snackBar.open("Discount have been add to this invoices.", "Done", { duration: 2000 })
          this.dialogRef.close('yes');
          this.form.enable();
        }
        else {
          this.snackBar.open(error, "Error");
          this.form.enable();
        }
      })

    }
  }
}