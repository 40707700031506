import { DataService } from 'src/app/services/data.service';
import { Student } from 'src/app/stores/student.store';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-invoice-router',
  templateUrl: './new-invoice-router.component.html',
  styleUrls: ['./new-invoice-router.component.scss']
})
export class NewInvoiceRouterComponent implements OnInit {

  constructor(
    private store: Student,
    private ds: DataService,
    private router: Router) { }

  async ngOnInit() {
    this.store.fetchStudentProfile(this.store.student.key, (doc) => {
      const { invoiceKey, key } = doc;

      if (invoiceKey) {
        this.router.navigate([`/student/${key}/${this.store.selectedAdmission.key}/new-invoice/invoice-no/${invoiceKey}`]);
      }
      else {
        const newKey = this.ds.createId();
        this.ds.studentDocument(key).update({
          invoiceKey: newKey
        }).then(() => {
          this.router.navigate([`/student/${key}/${this.store.selectedAdmission.key}/new-invoice/invoice-no/${newKey}`]);
        }).catch(error => {
          alert(error);
        })
      }
    })
  }
}
