import { ConvertService } from './../../../services/convert.service';
import { DataService } from 'src/app/services/data.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Environment } from './../../../stores/environment.store';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { BaseStore } from 'src/app/stores/base.store';
import { MappingService, feeObj, gradeObj, academicYearObj } from 'src/app/services/mapping.service';
import { ITrainingGrade, ITrainingGradeFee } from 'src/app/interfaces/trainingGrade';
import { status, recordStatus } from 'src/app/dummy/status';
import { checkExistDoc } from 'src/app/services/fire-validators.service';
import { YearTraining } from 'src/app/stores/yearTraining.store';

@Component({
  selector: 'app-training-grade-fee',
  templateUrl: './training-grade-fee.component.html',
  styleUrls: ['./training-grade-fee.component.scss']
})
export class TrainingGradeFeeComponent implements OnInit {
  @ViewChild("focusInput") inputEl: ElementRef;
  form: FormGroup;
  fee: AbstractControl;

  filteredFeeStates: any;

  constructor(
    public dialogRef: MatDialogRef<TrainingGradeFeeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public env: Environment,
    private snackBar: MatSnackBar,
    public base: BaseStore,
    private afs: AngularFirestore,
    private ds: DataService,
    public store: YearTraining,
  ) { }

  buildForm(): void {
    this.form = this.fb.group({
      fee: [null, Validators.compose([Validators.required, MappingService.validSelected.bind(this)])],
    })
    this.fee = this.form.controls['fee'];
  }

  async ngOnInit() {
    this.buildForm();

    const list = await this.base.fetchAcademicFee();

    this.filteredFeeStates = MappingService.autoComplete(this.fee, list, "name");
  }

  compareObjects(o1: any, o2: any): boolean {
    if (o2) return o1.key === o2.key;
  }

  displayItem(item: any): string {
    return item ? item.name : item;
  }

  create(f: any) {
    if (this.form.valid) {
      this.form.disable();
      const { fee } = f;
      const key = this.afs.createId();
      const item: ITrainingGradeFee = {
        key: key,
        trainingGradeKey: this.data.key,
        grade: gradeObj(this.data.grade),
        gradeNext: gradeObj(this.data.gradeNext),
        priceList: [],
        academicYear: academicYearObj(this.data.academicYear),
        status: recordStatus.active,
        create_date: new Date(),
        create_date_key: ConvertService.toDateKey(new Date()),
        create_by: this.env.user,
        pageKey: ConvertService.pageKey(),
      };

      this.store.setFeeTrainingGrade(item, (success, error) => {
        if (success) {
          this.dialogRef.close();
          this.snackBar.open('Add Training Fee has been created.', 'done', { duration: 2500 });
          this.form.enable();
          this.form.reset();
        }
        else {
          alert(error)
        }
      })
    }
  }

}
