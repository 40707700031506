import { ShiftCloseComponent } from 'src/app/pages/shift-close/shift-close.component';
import { MatDialog } from '@angular/material';
import { Environment } from 'src/app/stores/environment.store';
import { Router } from '@angular/router';
import { tabs } from './../../dummy/tabs';
import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/stores/user.store';
import { Report } from 'src/app/stores/report.store';
import { MappingService } from 'src/app/services/mapping.service';
import { ConvertService } from 'src/app/services/convert.service';
import { ConfirmDeleteComponent } from 'src/app/components/confirm-delete/confirm-delete.component';

@Component({
  selector: 'app-shift-report',
  templateUrl: './shift-report.component.html',
  styleUrls: ['./shift-report.component.scss']
})
export class ShiftReportComponent implements OnInit {
  tabs = tabs.reportShift

  constructor(
    private router: Router,
    public store: User,
    public env: Environment,
    private dialog: MatDialog,
    public report: Report,
  ) { }

  exchange_rate;
  async ngOnInit() {
    await this.env.fetchUserDoc()
    const { shift } = this.env.user;
    const shiftData: any = await this.env.fetchShiftMovementDoc(shift.key)
    this.exchange_rate = shiftData && shiftData.exchange_rate || 4000;
  }

  _goBack() {
    this.router.navigate(['/']);
  }

  closeShift() {
    if (this.env.user === null) return;
    const { shift } = this.store.user;
    let isEqual = false;
    let isBalance = false;
    let totalRiels = 0;
    let totalDollars = 0;
    if (this.report.moneyRiels)
      totalRiels = (MappingService.sum(this.report.moneyRiels, "total") / this.exchange_rate);

    if (this.report.moneyDollars)
      totalDollars = MappingService.sum(this.report.moneyDollars, "total");

    const moneyCount = totalRiels + totalDollars;
    isEqual = ConvertService.toFloatFixedOne(this.report.totalAmount) - ConvertService.toFloatFixedOne(moneyCount) == 0;

    const { balance, installment, petty_cash } = this.store.user;
    let userBalance = ConvertService.toNumber(balance) + ConvertService.toNumber(installment) - ConvertService.toNumber(petty_cash)
    isBalance = ConvertService.toFloatFixedOne(this.report.totalAmount + MappingService.sum(this.report.tuitionFeeWithOther, "amount")) - ConvertService.toFloatFixedOne(userBalance) == 0;

    if (shift && isEqual && isBalance) {
      let dialogRef = this.dialog.open(ShiftCloseComponent, {
        data: null,
        panelClass: "register-test-overlay-panel",
        width: "35vw",
        height: "100vh",
        disableClose: true,
        role: "dialog"
      });
      dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
      dialogRef.afterClosed().subscribe(result => { });
    } else {

      this.dialog.open(ConfirmDeleteComponent, {
        data: { title: "Cannot close shift", subTitle: "The shift must in same day and have enter the banknote." },
        width: '35vw',
        disableClose: false,
        role: 'dialog',
      });

    }
  }

}