import { paymentStatus } from "../../dummy/status";
import { IReceivedPayment } from "./../../interfaces/receivedPayment";
import { ConvertService } from "./../../services/convert.service";
import { Environment } from "./../../stores/environment.store";
import { AngularFirestore } from "@angular/fire/firestore";
import { AuthService } from "../../auth/auth.service";
import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef
} from "@angular/core";
import {
  FormGroup,
  AbstractControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatCheckboxChange } from "@angular/material";
import { Payment } from "src/app/stores/payment.store";
import { Student } from "src/app/stores/student.store";
import { campusObj, MappingService, toNumber, userObj } from "src/app/services/mapping.service";
import { Router } from "@angular/router";
import { BaseStore } from "src/app/stores/base.store";
import { DataService } from "src/app/services/data.service";

@Component({
  selector: 'app-add-school-payment',
  templateUrl: './add-school-payment.component.html',
  styleUrls: ['./add-school-payment.component.scss']
})
export class AddSchoolPaymentComponent implements OnInit {
  @ViewChild("focusInput")
  inputEl: ElementRef;
  form: FormGroup;
  cash_in: AbstractControl;
  note: AbstractControl;
  receive_from: AbstractControl;
  received_date: AbstractControl;
  pay_with_check: AbstractControl;
  isPayWithBank: AbstractControl;
  bank_payment: AbstractControl;
  bank_transaction_no: AbstractControl;
  bank_transaction_date: AbstractControl;

  paymentTerm: any = null;
  change: number;
  amountPay: number;
  currentYear = null;
  exchangeFee: number;
  exchange_rate;

  constructor(
    public dialogRef: MatDialogRef<AddSchoolPaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public auth: AuthService,
    private snackBar: MatSnackBar,
    public store: Payment,
    private db: AngularFirestore,
    private env: Environment,
    public payment: Payment,
    public studentStore: Student,
    private router: Router,
    public baseStore: BaseStore,
    private ds: DataService

  ) { }

  buildForm(): void {
    this.change = 0;
    this.form = this.fb.group({
      cash_in: ["", [Validators.compose([Validators.required, this.validCashIn.bind(this)])]],
      receive_from: [null, Validators.required],
      note: [null,],
      pay_with_check: [false,],
      isPayWithBank: [false,],
      received_date: [new Date(), Validators.required],
      bank_payment: [null, Validators.compose([MappingService.validSelected.bind(this), Validators.required])],
      bank_transaction_no: [null, Validators.required],
      bank_transaction_date: [new Date(), Validators.required],


    });
    this.cash_in = this.form.controls["cash_in"];
    this.receive_from = this.form.controls["receive_from"];
    this.note = this.form.controls["note"];
    this.pay_with_check = this.form.controls["pay_with_check"];
    this.isPayWithBank = this.form.controls["isPayWithBank"];
    this.received_date = this.form.controls["received_date"];
    this.bank_payment = this.form.controls["bank_payment"];
    this.bank_transaction_no = this.form.controls["bank_transaction_no"];
    this.bank_transaction_date = this.form.controls["bank_transaction_date"];
  }

  public validCashIn(control: AbstractControl): { [s: string]: boolean } {
    const value = control.value;
    if (value !== undefined && value !== null && value !== "") {
      this.change = ConvertService.getNumber(value, 0) - this.amountPay;
      if (this.change < 0) {
        return { validKey: true };
      }
      if (value < 0) {
        return { validAmount: true }
      }
    } else {
      this.change = 0 - this.amountPay;
    }
  }

  bankPayment = []
  async ngOnInit() {
    this.buildForm();
    await this.env.fetchUserDoc()
    await this.env.fetchConfigDoc()
    const { selectedSchool } = this.env
    this.bankPayment = await this.baseStore.fetchList(this.ds.dbRef().collection("stores").doc(selectedSchool.key).collection("bank_payment", ref => ref.where("status.key", "==", 1)));

    const { config } = this.env
    const { dollar_exchange } = config
    this.exchange_rate = config.dollar_exchange || 4000;

    this.currentYear = await this.env.fetchAcademicYearDoc(this.studentStore.selectedAcademicYear.key);
    const { grandTotal } = this.data.payParam;
    this.amountPay = grandTotal;
    this.exchangeFee = toNumber(dollar_exchange) * toNumber(this.amountPay)
    this.change = 0 - this.amountPay;

    this.bank_payment.disable()
    this.bank_transaction_no.disable()
  }

  displayItem(item: any): string {
    return item ? item.name : item;
  }

  showOptions(event: MatCheckboxChange): void {
    this.bank_payment.disable()
    this.bank_transaction_no.disable()
    if (event.checked) {
      this.bank_payment.enable()
      this.bank_transaction_no.enable()
    }
  }

  _save(f: any) {
    if (f.cash_in < this.amountPay && this.env.user && this.env.dailyShift) return;
    if (this.form.valid) {
      this.form.disable();
      const { selectedCampus, selectedSchool } = this.env
      const { received_date, pay_with_check, isPayWithBank, bank_payment, bank_transaction_no, bank_transaction_date } = f
      const item: IReceivedPayment = {
        key: this.db.createId(),
        invoiceKey: null,
        studentKey: null,
        shift: this.env.dailyShift, // USING
        cashier: this.env.user, // USING
        payment_year: this.currentYear,
        payment_campus: campusObj(this.env.user.campus),
        cashIn: f.cash_in,
        note: f.note,
        receive_from: f.receive_from,
        change: this.change,
        status: paymentStatus.paid,
        page_key: ConvertService.pageKey(),
        create_date: received_date,
        create_date_key: ConvertService.toDateKey(received_date),
        create_by: userObj(this.env.user),

        campusKey: selectedCampus.key,
        campus: selectedCampus,
        schoolKey: selectedSchool.key,
        school: selectedSchool,
        pay_with_check: pay_with_check,

        isPayWithBank: isPayWithBank ? isPayWithBank : false,
        bank_payment: bank_payment || null,
        bank_transaction_no: bank_transaction_no || null,
        
        bank_transaction_date: bank_transaction_date ? bank_transaction_date : null,
        bank_transaction_date_key: bank_transaction_date ? ConvertService.toDateKey(bank_transaction_date) : null,
      };

      this.store.markSchoolPayment(
        item, this.studentStore.student, this.store.paymentHeader, (success, headerKeyOrError) => {
          if (success) {
            this.router.navigate([`/student/${this.studentStore.student.key}/${this.studentStore.selectedAdmission.key}/receive-payment/`]);
            this.dialogRef.close(headerKeyOrError);
            this.snackBar.open("Invoice have been paid successful.", "done", {
              duration: 2000
            });
          } else {
            this.form.enable();
            this.snackBar.open(headerKeyOrError, "Error");
          }
        }
      );
    }
  }
}
