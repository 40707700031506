import { DataService } from "./../services/data.service";
import { observable, computed, action, autorun } from "mobx";
import { Injectable } from "@angular/core";
import { LocalStorage } from "@ngx-pwa/local-storage";
import { MappingService } from "../services/mapping.service";
import { ConvertService } from "../services/convert.service";
import { invoiceTypesObj, enrollPrograms } from "../dummy/status";

@Injectable()
export class Invoice {
    @observable public invoice = null;
    @observable public allInvoices = null;
    @observable public invoiceHeader = null;
    @observable public receiptHeader = null;
    @observable public totalTuitionFee = null;
    @observable public totalTestingFee = null;
    @observable public totalSubject = null;
    @observable public otherFee = null;
    @observable public invoiceKey = null;

    @observable public student = null;
    @observable public filterType = null;
    @observable public data = null;

    @observable public loading = false;
    @observable public process = false;
    @observable public empty = false;

    @observable public header = null;
    @observable public detail = null;
    @observable public totalPrice = null;
    @observable public totalMiscellaneous = null;
    @observable public totalScholarshipPenalty = null;
    @observable public gradePrice = null;
    @observable public subtotal = null;
    @observable public enroll = null;
    @observable public enrollIncluded = null;
    @observable public enrollExcluded = null;

    @observable public studentScholarship = null;
    @observable public totalScholarship = null;
    @observable public totalLoan = null;
    @observable public totalPrepaid = null;
    @observable public prepaidRef = null;
    @observable public totalInstallment = null;
    @observable public remainingInstallment = null;
    @observable public totalPenalty = null;
    @observable public penaltyRef = null;
    @observable public grandTotal = null;
    @observable public totalLate = null
    @observable public draft = null;
    @observable public scholarshipPenalty = null;
    @observable public miscellaneousFee = null;
    @observable public activeTerm = null;
    @observable public scholarshipRef = null;
    @observable public installmentRef = null;
    @observable public installment = null;
    @observable public totalDraft = [];
    @observable public totalScholarShipPenalty = [];
    @observable public paymentEmpty = false;
    @observable public paymentHeader = null;
    @observable public paymentDetail = null;
    @observable public paymentTesting = null;
    @observable public paymentTuitionFee = null;
    @observable public paymentScholarshipPenalty = null;
    @observable public paymentMiscellaneous = null;

    constructor(private ds: DataService) {
    }

    @action
    fetchPayments(admissionKey: string, admission, studentKey: string, invoiceKey, callback) {
        this.loading = true;
        this.paymentEmpty = false;
        this.paymentHeader = null;
        this.paymentDetail = null;
        this.ds.studentInvoiceByAdmission(admissionKey, admission, studentKey, invoiceKey).valueChanges().subscribe(inv => {
            let headerKey = null;
            let data = [];

            const docs = inv.filter(m => m.invoice_type.key !== invoiceTypesObj.scholarshipPenalty.key);
            // if unpaid payment
            if (invoiceKey) {
                data = docs.filter(m => m.course !== null && m.course.key === "unpaid" && m.isHeader === true);
                headerKey = data.length > 0 ? data[0].key : null;
                if (data.length == 0) {
                    data = docs.filter(m => m.isHeader === true && m.headerRef == invoiceKey);
                    headerKey = invoiceKey;
                }
            } else {//if enrollment payment
                data = docs.filter(m => m.course === null && m.isHeader === true);
                headerKey = data.length > 0 ? data[0].key : null;
            }

            //end scholarship penalty
            this.paymentHeader = docs.length > 0 ? docs.filter(m => m.isHeader === true && m.headerRef == headerKey)[0] : null;
            this.paymentDetail = docs.length > 0 ? docs.filter(m => m.isHeader === false && m.headerRef == headerKey) : [];
            this.paymentEmpty = this.paymentDetail.length === 0;
            this.loading = false;
            const invoices = docs.filter(m => m.headerRef === headerKey)
            callback(invoices);
        })
    }

    clearStore() {
        this.grandTotal = null;
        this.invoice = null;
        this.invoiceHeader = null;
        this.paymentMiscellaneous = null;
        this.paymentEmpty = false;
        this.totalMiscellaneous = null;
        this.loading = true;
        this.totalScholarship = null;
        this.totalLoan = null;
        this.totalPrepaid = null;
        this.totalPenalty = null;
        this.penaltyRef = null;
        this.totalInstallment = null;
        this.totalPrice = null;
        this.gradePrice = 0;
        this.grandTotal = null;
        this.totalLate = null;
        this.invoice = null;
        this.invoiceHeader = null;
        this.scholarshipRef = null;
        this.totalScholarShipPenalty = null;
        this.paymentTuitionFee = null;
        this.paymentScholarshipPenalty = null;
        this.paymentMiscellaneous = null;
        this.totalScholarshipPenalty = null;
        this.paymentEmpty = false;
        this.totalMiscellaneous = null;
        this.totalScholarshipPenalty = null;
        this.totalPenalty = null;
        this.totalScholarship = null;
    }
    @action
    async fetchEnrollment(admissionKey, studentKey: any, invoiceKey: any) {
        this.loading = true;
        this.clearStore()
        const settingDoc = await this.ds.sysSetting().get().toPromise();
        const admissionDoc = await this.ds.admissionRef().doc(admissionKey).get().toPromise();
        const studentDoc = await this.ds.studentDocument(studentKey).get().toPromise();
        const setting = MappingService.pushToObject(settingDoc);
        const admission = MappingService.pushToObject(admissionDoc);
        const student = MappingService.pushToObject(studentDoc);
        let scholarship: any = null;
        if (admission) {
            const { scholarshipKey } = admission.program_academic;
            if (scholarshipKey) {
                const scholarshipDoc = await this.ds.studentDocument(studentKey).collection("scholarships").doc(scholarshipKey).get().toPromise();
                scholarship = MappingService.pushToObject(scholarshipDoc)
            }
        }
        this.fetchPayments(admissionKey, admission, student.key, invoiceKey, (docs) => {
            let tuitionListing = [];
            this.paymentTuitionFee = [];
            if (docs.length > 0 && !invoiceKey) {
                if (docs.length > 0 && !invoiceKey) {
                    const snapshot = docs.filter(m => m.isHeader === false && m.invoice_type.key === invoiceTypesObj.tuitionFee.key);
                    if (this.paymentHeader.invoice_type.key === invoiceTypesObj.tuitionFee.key && this.paymentHeader.school_fee_type.key === enrollPrograms.institutes.key) {
                        tuitionListing = docs.length > 0 ? docs.filter(m => m.isHeader === false && m.invoice_type.key === invoiceTypesObj.tuitionFee.key) : [];
                    } else {
                        tuitionListing = snapshot.map(m => {
                            const { course, amount } = m;
                            const { code } = course.schedule_subject.subject;
                            const { payment_type, scheduleType } = course
                            const fee = MappingService.getFeeRef(setting, code, course.fee, payment_type, scheduleType);
                            const price = MappingService.getForeignerFee(student, setting, fee);
                            const grandTotal = price * course.schedule_subject.credit;
                            const isResolve = amount !== grandTotal ? true : false;
                            const resolveFeeRef = MappingService.feeObj(fee);
                            return {
                                ...m,
                                resolve: isResolve,
                                amount: grandTotal,
                                grand_total: grandTotal,
                                price: price,
                                total: grandTotal,
                                resolveFeeRef: resolveFeeRef
                            };
                        })
                    }
                }
            }
            else {
                tuitionListing = docs.length > 0 ? docs.filter(m => m.isHeader === false && m.invoice_type.key === invoiceTypesObj.tuitionFee.key) : [];
            }
            this.paymentTuitionFee = tuitionListing.length > 0 ? tuitionListing : null;
            this.paymentScholarshipPenalty = docs.length > 0 ? docs.filter(m => m.isHeader === false && m.invoice_type.key === invoiceTypesObj.scholarshipPenalty.key) : null;
            this.paymentMiscellaneous = docs.length > 0 ? docs.filter(m => m.isHeader === false && (m.invoice_type.key === invoiceTypesObj.miscellaneous.key || m.invoice_type.key === invoiceTypesObj.abcCourse.key)) : null;
            let { prepaid, installment } = student;
            if (prepaid && prepaid > 0) {
                this.totalPrepaid = ConvertService.toNumber(prepaid);
            }
            if (installment && installment > 0) {
                this.totalInstallment = ConvertService.toNumber(installment);
            }
            if (this.paymentHeader) {
                const { invoice_type } = this.paymentHeader;
                this.scholarshipRef = null;
                this.totalScholarship = null;
                this.totalLoan = null;
                if (invoice_type.key === invoiceTypesObj.tuitionFee.key) {
                    //=====HAS SCHOLARSHIP FEE UNPAID
                    this.scholarshipRef = scholarship;
                    if (this.paymentHeader) {
                        const { scholarshipRef } = this.paymentHeader;
                        if (scholarshipRef && scholarshipRef.key === "unpaid") {
                            this.scholarshipRef = scholarshipRef;
                        }
                    }
                    if (this.scholarshipRef && this.paymentTuitionFee && this.paymentTuitionFee.length > 0) {
                        const totalPriceFee = MappingService.sum(this.paymentTuitionFee, "amount");
                        let { totalLoan, totalScholarship } = MappingService.calScholarship(totalPriceFee, this.scholarshipRef)
                        this.totalScholarship = totalScholarship;
                        this.totalLoan = totalLoan;
                    }
                }
            }

            let totalDeduction = ConvertService.toNumber(this.totalScholarship) + ConvertService.toNumber(this.totalLoan);
            //=====HAS PENALTY FEE
            if (this.paymentHeader && this.paymentHeader.penalty && this.paymentHeader.penalty > 0) {
                let { penalty, penaltyRef } = this.paymentHeader;
                this.totalPenalty = penalty;
                this.penaltyRef = penaltyRef;
            }

            //=====HAS SCHOLARSHIP PENALTY FEE
            if (this.paymentScholarshipPenalty) {
                this.totalScholarshipPenalty = MappingService.sum(this.paymentScholarshipPenalty, 'amount');
            }

            //=====HAS MISCELLANEOUS FEE
            if (this.paymentMiscellaneous) {
                this.totalMiscellaneous = MappingService.sum(this.paymentMiscellaneous, 'amount');
            }

            //=====HAS TUITIONS FEE
            if (this.paymentTuitionFee) {
                this.totalPrice = MappingService.sum(this.paymentTuitionFee, 'amount');
            }

            //===== IS SENIOR PROJECT
            let isSenior = false;
            if (this.paymentTuitionFee) {
                isSenior = this.paymentTuitionFee.filter(m => m.course && m.course.isSenior).length > 0;
                if (isSenior) {
                    totalDeduction = 0;
                    this.scholarshipRef = null;
                    this.totalScholarship = null;
                    this.totalLoan = null;
                }
            }

            this.gradePrice = ConvertService.toNumber(this.totalPrice) - totalDeduction - this.totalPrepaid;
            this.grandTotal = this.gradePrice + ConvertService.toNumber(this.totalMiscellaneous) + ConvertService.toNumber(this.totalScholarshipPenalty) + ConvertService.toNumber(this.totalPenalty);
            this.loading = false;
        })
    }

    @action
    removeMiscellaneous(item: any, student: any, oldInvoice: any, callback) {
        this.process = true;
        const batch = this.ds.batch();
        const studentInvoiceRef = this.ds.studentFireDocument(student.key).collection("invoices");
        if (this.paymentDetail.length === 1) {
            batch.delete(studentInvoiceRef.doc(oldInvoice.key));
        }
        else {
            batch.update(studentInvoiceRef.doc(oldInvoice.key), {
                price: oldInvoice.price - item.price,
                amount: oldInvoice.amount - item.amount,
            })
        }

        batch.delete(studentInvoiceRef.doc(item.key))
        batch.commit().then(() => {
            this.process = false;
            callback(true, null);
        }).catch(error => {
            this.process = false;
            callback(false, error);
        })
    }


}
