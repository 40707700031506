import { ShiftOpenComponent } from 'src/app/pages/shift-open/shift-open.component';
import { CashierShiftClosedComponent } from 'src/app/components/cashier-shift-closed/cashier-shift-closed.component';
import { MatDialog } from '@angular/material';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { Router } from '@angular/router';
import { User } from 'src/app/stores/user.store';
import { Environment } from 'src/app/stores/environment.store';
import { SCHOOL } from 'src/app/dummy/config';
import { ChangePasswordComponent } from 'src/app/components/change-password/change-password.component';

@Component({
  selector: 'app-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrls: ['./nav-header.component.scss']
})
export class NavHeaderComponent implements OnInit {
school=SCHOOL
  constructor(public auth: AuthService,
    public store: User,
    public env: Environment,
    private dialog: MatDialog,
    private router: Router, ) { }

  ngOnInit() {
  }

  logOut() {
    this.auth.signOut().then(success => {
      this.router.navigate(['/auth']);
      this.store.clearStore();
      this.env.clearStore();
    }).catch(error => {
      alert(error);
    });
  }

  closeShift() {
    if (this.env.user === null) return;
    const { shift } = this.store.user;
    if (!shift) {
      let dialogRef = this.dialog.open(CashierShiftClosedComponent, {
        data: null,
        disableClose: true,
        role: "dialog"
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result === 'yes') {
          this.openShift();
        }
      });
      return;
    }
    this.router.navigate(['/report-by-shift'])
    // let dialogRef = this.dialog.open(ShiftCloseComponent, {
    //   data: null,
    //   panelClass: "register-test-overlay-panel",
    //   width: "35vw",
    //   height: "100vh",
    //   disableClose: true,
    //   role: "dialog"
    // });
    // dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    // dialogRef.afterClosed().subscribe(result => {});
  }

  openShift() {
    if (this.store.user === null) return;
    let dialogRef = this.dialog.open(ShiftOpenComponent, {
      data: null,
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: true,
      role: "dialog"
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => { });
  }

  changePassword() {
    let dialogRef = this.dialog.open(ChangePasswordComponent, {
      data: null,
      panelClass: 'item-list-panel',
      width: '35vw',
      height: '100vh',
      disableClose: true,
      role: 'dialog',
      autoFocus: false,

    });
    dialogRef.updatePosition({ top: '0', right: '0', bottom: '0' });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  
}
