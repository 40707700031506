import { Environment } from 'src/app/stores/environment.store';
import { AddPettyCashComponent } from './../../components/add-petty-cash/add-petty-cash.component';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { tabs } from './../../dummy/tabs';
import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/stores/user.store';
import { EditPettyCashComponent } from 'src/app/components/edit-petty-cash/edit-petty-cash.component';
import { PettyCash } from 'src/app/stores/pettyCash.store';

@Component({
  selector: 'app-petty-cash',
  templateUrl: './petty-cash.component.html',
  styleUrls: ['./petty-cash.component.scss']
})
export class PettyCashComponent implements OnInit {
  tabs = tabs.pettyCash;

  constructor(
    public store: PettyCash,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    public env: Environment,
    private user: User,
  ) { }

  ngOnInit() {
    this.env.getUser(user => {
      if (user && user.shift) {
        this.env.fetchUser(user.key);
        this.env.fetchDailyShift(user.key);
        this.store.fetchData(user.key, user.shift.key);
      }
    })
  }

  create() {
    if (!this.user.shift || !this.env.sameDay) return;
    let dialogRef = this.dialog.open(AddPettyCashComponent, {
      data: { pettyCash: this.store.data },
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: true,
      role: "dialog"
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => { });
  }

  update(item) {
    if (!this.user.shift || !this.env.sameDay) return;
    let dialogRef = this.dialog.open(EditPettyCashComponent, {
      data: { ITEM: item },
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: true,
      role: "dialog"
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => { });
  }

}
