import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateOnly'
})
export class DateOnlyPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value) return;
    return moment(value).format('DD MMM, YYYY');
  }

}
