import { Pages } from 'src/app/dummy/pages';
import { REQUEST_STATUS, Status } from 'src/app/dummy/status';
import { ConvertService } from "./../../services/convert.service";
import { Environment } from "./../../stores/environment.store";
import { AngularFirestore } from "@angular/fire/firestore";
import { AuthService } from "../../auth/auth.service";
import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef
} from "@angular/core";
import {
  FormGroup,
  AbstractControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from "@angular/material";
import { IPettyCash } from "src/app/interfaces/petty-cash";
import { switchMap, debounceTime, tap } from "rxjs/operators";
import { DailyShiftStore } from 'src/app/stores/dailyShift.store';
import { academicYearObj, MappingService } from 'src/app/services/mapping.service';
import { PettyCash } from 'src/app/stores/pettyCash.store';

@Component({
  selector: 'app-add-petty-cash',
  templateUrl: './add-petty-cash.component.html',
  styleUrls: ['./add-petty-cash.component.scss']
})
export class AddPettyCashComponent implements OnInit {
  @ViewChild("focusInput")
  inputEl: ElementRef;

  form: FormGroup;
  payee: AbstractControl;
  price: AbstractControl;
  refNo: AbstractControl;
  description: AbstractControl;
  receivedBy: AbstractControl;
  approvedBy: AbstractControl;
  loading = false;

  balance: number = 0;
  change: number;
  matchBalance: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<AddPettyCashComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public auth: AuthService,
    private snackBar: MatSnackBar,
    public store: PettyCash,
    private db: AngularFirestore,
    private env: Environment,
    public shiftInvoice: DailyShiftStore
  ) { }

  buildForm(): void {
    this.form = this.fb.group({
      price: [null, [Validators.compose([Validators.required, this.validCashIn.bind(this)])]],
      refNo: [null],
      payee: [null, Validators.required],
      description: [null, Validators.required],
      receivedBy: [null,],
      approvedBy: [null, Validators.required],
    });
    this.price = this.form.controls["price"];
    this.refNo = this.form.controls["refNo"];
    this.payee = this.form.controls["payee"];
    this.description = this.form.controls["description"];
    this.receivedBy = this.form.controls["receivedBy"];
    this.approvedBy = this.form.controls["approvedBy"];
  }

  async ngOnInit() {
    this.buildForm();
    await this.env.fetchUserDoc()
    this.shiftInvoice.fetchCashierInvoice(this.env.user, res => {
      this.balance = res.reduce((a, b) => a + Number(b.amount), 0)
      this.balance = parseFloat(this.balance + this.env.user.shift.cash_in);
      this.balance = this.balance + ConvertService.toNumber(this.env.user.installment) - ConvertService.toNumber(this.env.user.petty_cash);
      const pettyCashList = this.data.pettyCash
      if (pettyCashList && pettyCashList.length > 0) {
        const pettyCashData = pettyCashList.filter(m => m.pettyCash_status.key === REQUEST_STATUS.pending.key)
        this.balance -= MappingService.sum(pettyCashData, 'price')
      }
      this.change = this.balance;
    })
    this.store.fetchUser();
    this.approvedBy.valueChanges
      .pipe(
        debounceTime(Pages.debounceTime),
        tap(() => (this.loading = true)),
        switchMap(value => this.store.searchUser(value))
      )
      .subscribe(results => {
        this.store.employees = results;
        this.loading = false;
      });

    // this.receivedBy.valueChanges
    //   .pipe(
    //     debounceTime(Pages.debounceTime),
    //     tap(() => (this.loading = true)),
    //     switchMap(value => this.store.searchEmployee(value))
    //   )
    //   .subscribe(results => {
    //     this.store.received = results;
    //     this.loading = false;
    //   });
  }

  public validCashIn(control: AbstractControl): { [s: string]: boolean } {
    const value = control.value;
    if (value !== undefined && value !== null && value !== '') {
      this.change = ConvertService.getNumber(value, 0) - this.balance;
      if (this.change > 0) {
        return { validKey: true }
      }
      if (value < 0) {
        return { validAmount: true }
      }
    } else {
      this.change = this.balance
    }
    if (value !== this.balance)
      this.matchBalance = false
    else
      this.matchBalance = true
  }

  displayFullName(item: any): string {
    return item ? item.displayName : item;
  }

  create(f: any, isNew) {
    if (this.form.valid) {
      this.form.disable();
      const { price, payee, refNo, approvedBy, description } = f;
      const { selectedCampus, selectedSchool } = this.env
      const item: IPettyCash = {
        key: this.db.createId(),
        createDate: new Date(),
        createBy: MappingService.userObj(this.env.user),
        page_key: ConvertService.pageKey(),
        status: Status[1],
        payee: payee,
        price: price,
        refNo: refNo,
        approvedBy: MappingService.userObj(approvedBy),
        datePayment: new Date(),
        datePaymentKey: ConvertService.toDateKey(new Date),
        term: academicYearObj(this.env.term),
        shift: MappingService.dailyShiftObj(this.env.dailyShift),
        description: description,
        pettyCash_status: REQUEST_STATUS.pending,

        campusKey: selectedCampus.key,
        campus: selectedCampus,
        schoolKey: selectedSchool.key,
        school: selectedSchool,
      };
      this.store.addPettyCash(item, this.env.user, this.env.dailyShift, (success, error) => {
        if (success) {
          if (!isNew) this.dialogRef.close();
          this.snackBar.open("Petty cash has been created.", "done", {
            duration: 2000
          });
          this.form.reset();
          this.form.enable();
          this.inputEl.nativeElement.focus();
        } else {
          this.form.enable();
          this.snackBar.open(error, "Error");
        }
      });
    }
  }
}
