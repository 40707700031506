import { Tuition } from './../../stores/tuition.store';
import { ConvertService } from './../../services/convert.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from '../../auth/auth.service';
import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { academicYearObj, MappingService } from '../../services/mapping.service';
import { Institute } from 'src/app/stores/institute.store';
import { ITrainingFee } from 'src/app/interfaces/tuition';
import { Terms } from 'src/app/stores/term.store';
import { Environment } from 'src/app/stores/environment.store';
import { Unpaid } from 'src/app/stores/unpaid.store';
import { DataService } from 'src/app/services/data.service';
import { Student } from 'src/app/stores/student.store';
import { invoiceTypesObj, enrollPrograms, paymentStatus, enrollmentTypes } from 'src/app/dummy/status';
import { IInvoice } from 'src/app/interfaces/invoice';

@Component({
  selector: 'app-add-unpaid-academic',
  templateUrl: './add-unpaid-academic.component.html',
  styleUrls: ['./add-unpaid-academic.component.scss']
})
export class AddUnpaidAcademicComponent implements OnInit {

  @ViewChild("focusInput") inputEl: ElementRef;

  form: FormGroup;
  amount: AbstractControl;
  scholarship: AbstractControl;
  loan: AbstractControl;
  penalty: AbstractControl;
  term: AbstractControl;
  shift: AbstractControl;
  note: AbstractControl;
  
  filteredStates:any;
  filteredShiftStates:any;
  
  constructor(
    public dialogRef: MatDialogRef<AddUnpaidAcademicComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public env: Environment,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore,
    public fee: Tuition,
    public store: Institute,
    public termStore:Terms,
    public unpaid: Unpaid,
    private ds: DataService,
    public studentStore: Student
  ) { }

  buildForm(): void {
    this.form = this.fb.group({
      term: [null,Validators.compose([Validators.required,MappingService.validSelected.bind(this)])],
      amount:[null,Validators.compose([Validators.required])],
      note:[null],
      scholarship:[null],
      loan:[null],
      penalty:[null],
      shift:[null],
    })

    this.term = this.form.controls['term'];
    this.shift = this.form.controls['shift'];
    this.amount = this.form.controls['amount'];
    this.note = this.form.controls['note'];
    this.scholarship = this.form.controls['scholarship'];
    this.loan = this.form.controls['loan'];
    this.penalty = this.form.controls['penalty'];
    
    this.termStore.fetchTermToArray(list => {
      this.filteredStates = MappingService.autoComplete(
        this.term,
        list,
        "name"
      );
    });

    this.termStore.fetchShiftToArray(list => {
      this.filteredShiftStates = list;
      this.shift.patchValue(this.filteredShiftStates[0]);
    });

    let { creditRef } = this.studentStore.student;
    if (creditRef) {
      let { amount, scholarship, loan, penalty, note } = creditRef
      this.form.patchValue({
        amount: amount,
        scholarship: scholarship,
        loan: loan,
        penalty: penalty,
        note: ConvertService.toNull(note)
      })
    }
  }
  
  compareObjects(o1: any, o2: any): boolean {
    if (o2) return o1.key === o2.key;
  }
  
  displayItem(item: any): string {
    return item ? item.name : item;
  }

  ngOnInit() {
    this.buildForm();
  }

  create(f: any) {
    if (this.form.valid) {
      this.form.disable();
      let { amount, penalty, scholarship, term, shift,loan } = f;

      const create_by = MappingService.userObj(this.env.user);
      const campus = this.env.user
        ? MappingService.campusObj(this.env.user.campus)
        : null;
      const currentTerm = academicYearObj(term);
      const studentDoc = MappingService.studentObj(this.studentStore.student);

      let programAcademic = this.studentStore.selectedAdmission?this.studentStore.selectedAdmission.program_academic:null;

      const headerKey = this.ds.createId();
      const invoiceNo = null;

      const date = new Date();
      const courseProgram = programAcademic;
      const invoiceType = invoiceTypesObj.tuitionFee;
      const schoolFeeType = enrollPrograms.academic;
      const displayProgram = courseProgram?courseProgram.type.text: null;
      const displayLevel = courseProgram?courseProgram.name:null;
      const displayShift = shift.name;
      const grandTotal = amount;
      const scholarshipRef={ key:"unpaid",
      loan:loan,
      percentage:scholarship,}
      const invoiceHeader: IInvoice = {
        key: headerKey,
        display_program: displayProgram,
        display_level: displayLevel,
        display_shift: displayShift,
        create_date: date,
        create_date_key: ConvertService.dateKey(),
        create_by: create_by,
        issue_by: create_by,
        issue_date: date,
        invoice_no: invoiceNo,
        invoice_type: invoiceType,
        school_fee_type: schoolFeeType,
        student: studentDoc,
        verify_by: create_by,
        verify_date: date,
        page_key: ConvertService.pageKey(),
        verify_date_key: ConvertService.dateKey(),
        invoice_date: date,
        invoice_date_key: ConvertService.dateKey(),
        course: scholarshipRef,
        isPaid: paymentStatus.unpaid,
        isVoid: false,
        program: courseProgram,
        byAdmission: false,
        issue_term: currentTerm,
        payment_term: null,
        headerRef: headerKey,
        isHeader: true,
        enroll_status: null,
        isEnrollVerify: true,
        campus: campus,
        schoolSession: null,
        description: null,
        penaltyRef: null,
        penalty: penalty,
        scholarshipRef: scholarshipRef,
        scholarship: null,
        loan: null,
        prepaidRef: null,
        prepaid: null,
        price: grandTotal,
        amount: grandTotal,
        set_unpaid: true,
        enrollment_type: enrollmentTypes.academic,
        note: f.note,
      };

      const invoiceDetail: IInvoice = {
        key: this.ds.createId(),
        create_date: date,
        create_date_key: ConvertService.toDateKey(date),
        create_by: create_by,
        issue_by: create_by,
        issue_date: date,
        invoice_no: invoiceNo,
        invoice_type: invoiceType,
        school_fee_type: schoolFeeType,
        student: studentDoc,
        verify_by: create_by,
        verify_date: date,
        page_key: ConvertService.pageKey(),
        verify_date_key: ConvertService.toDateKey(date),
        invoice_date: date,
        invoice_date_key: ConvertService.toDateKey(date),
        course: scholarshipRef,
        isPaid: paymentStatus.unpaid,
        isVoid: false,
        program: courseProgram,
        byAdmission: false,
        issue_term: currentTerm,
        payment_term: null,
        headerRef: headerKey,
        isHeader: false,
        enroll_status: null,
        isEnrollVerify: true,
        campus: campus,
        schoolSession: null,
        description: displayLevel,
        penaltyRef: null,
        penalty: penalty,
        scholarshipRef:scholarshipRef,
        scholarship: null,
        loan: null,
        prepaidRef: null,
        prepaid: null,
        price: grandTotal,
        amount: grandTotal,
        enrollment_type: enrollmentTypes.academic,
        note: f.note,
      };

      let admissionDoc = null;
      this.unpaid.enrollEnglishPayment(admissionDoc, invoiceHeader, invoiceDetail, this.studentStore.student, (success, error) => {
          if (success) {
            this.dialogRef.close();
            this.snackBar.open("Training fee has been saved.", "done", {
              duration: 2000
            });
            this.form.enable();
            this.dialogRef.close("yes");
          } else {
            this.form.enable();
            this.snackBar.open(error, "Error");
          }
        }
      );
    }
  }

}