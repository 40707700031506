import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Student } from 'src/app/stores/student.store';
import { MatDialog, MatSnackBar } from '@angular/material';
// import { SetAdmissionComponent } from 'src/app/components/set-admission/set-admission.component';
// import { UpdateStudentAdmissionComponent } from 'src/app/components/update-student-admission/update-student-admission.component';
import { BaseStore } from 'src/app/stores/base.store';
import { DataService } from 'src/app/services/data.service';
import { SetAdmissionComponent } from './set-admission/set-admission.component';
// import { DeleteComponent } from 'src/app/components/delete/delete.component';

@Component({
  selector: 'app-student-admission',
  templateUrl: './student-admission.component.html',
  styleUrls: ['./student-admission.component.scss']
})
export class StudentAdmissionComponent implements OnInit {
  id: string;
  programKey: any;
  testingKey: any;

  constructor(
    public store: Student,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public base: BaseStore,
    private ds: DataService,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.route.parent.params.forEach((params) => {
      const { id, programKey, testingKey } = params;
      this.id = id;
      this.store.programKey = programKey || null;
      this.testingKey = testingKey || null;
      this.store.fetchStudentDocID(this.id, res => {
        this.store.fetchStudentTest(this.testingKey, this.id);
      });
      this.store.fetchStudentAdmission(this.id);
    });
  }

  _onAddAdmission() {
    let dialogRef = this.dialog.open(SetAdmissionComponent, {
      data: { STUDENT: this.store.student, TESTING: this.store.studentTesting },
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: false,
      role: "dialog",
      autoFocus: false,
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  // _onUpdateAdmission(item) {
  //   let dialogRef = this.dialog.open(UpdateStudentAdmissionComponent, {
  //     data: item,
  //     panelClass: "register-test-overlay-panel",
  //     width: "35vw",
  //     height: "100vh",
  //     disableClose: false,
  //     role: "dialog",
  //     autoFocus: false,
  //   });
  //   dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
  //   dialogRef.afterClosed().subscribe(result => {

  //   });
  // }

  // delete(item: any) {
  //   let dialogRef = this.dialog.open(DeleteComponent, {
  //     data: { title: 'Delete Student Academic', memo: 'If student academic grade is using by other function in system you cannot delete it.', name: item.student.full_name },
  //     width: '35vw',
  //     disableClose: true,
  //     role: 'dialog',
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result === 'yes') {
  //       this.base.delete(this.ds.studentAdmissionRef(), item, (success, error) => {
  //         if (success) {
  //           this.snackBar.open('Student academic has been deleted.', 'done', { duration: 2000 });
  //         }
  //         else {
  //           this.snackBar.open(error, 'Error')
  //         }
  //       })
  //     }
  //   });
  // }

}
