import { invoiceTypesObj, enrollPrograms, paymentStatus, EnrollStatus, Status, recordStatus, scholarshipTypes } from 'src/app/dummy/status';
import { ConvertService } from 'src/app/services/convert.service';
import { MatSnackBar } from '@angular/material';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { Student } from 'src/app/stores/student.store';
import { Payment } from 'src/app/stores/payment.store';
import { Environment } from 'src/app/stores/environment.store';
import { MappingService, academicYearObj } from 'src/app/services/mapping.service';
import { DataService } from 'src/app/services/data.service';
import { Invoice } from 'src/app/stores/invoice.store';
import { IInvoice } from 'src/app/interfaces/invoice';

@Component({
  selector: 'app-add-other-fee',
  templateUrl: './add-other-fee.component.html',
  styleUrls: ['./add-other-fee.component.scss']
})
export class AddOtherFeeComponent implements OnInit {
  form: FormGroup;
  fee: AbstractControl;

  dataLists = [];
  constructor(
    public dialogRef: MatDialogRef<AddOtherFeeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    public store: Payment,
    public ds: DataService,
    public env: Environment,
    public studentStore: Student,
    public invoice: Invoice
  ) { }

  buildForm(): void {
    this.form = this.fb.group({
      fee: [null, Validators.required],
    })
    this.fee = this.form.controls['fee'];
  }

  currentYear: any;
  async ngOnInit() {
    this.buildForm();
    await this.env.fetchUserDoc()
    const { selectedSchool } = this.env
    this.store.fetchOtherFeeDetail(selectedSchool.key, this.data.key);
    const envData: any = await this.env.fetchAcademicEnv(selectedSchool.key);
    this.currentYear = envData.year;
  }

  create(f: any) {
    if (this.form.valid) {
      if (f.fee.length === 0) return;
      this.form.disable();
      const date = new Date();
      const create_by = MappingService.userObj(this.env.user);
      let array = [];
      const { selectedCampus, selectedSchool } = this.env

      f.fee.forEach(doc => {
        let { program_academic } = this.studentStore.student;
        const item: IInvoice = {
          key: this.ds.createId(),
          create_date: date,
          create_date_key: ConvertService.toDateKey(date),
          create_by: create_by,
          issue_by: create_by,
          issue_date: date,
          invoice_no: null,
          invoice_type: invoiceTypesObj.otherFee,
          school_fee_type: enrollPrograms.academic,
          student: MappingService.studentObj(this.studentStore.student),
          verify_by: create_by,
          verify_date: date,
          page_key: ConvertService.pageKey(),
          verify_date_key: ConvertService.toDateKey(date),
          invoice_date: date,
          invoice_date_key: ConvertService.toDateKey(date),
          course: MappingService.otherFeeObj(doc),
          isPaid: paymentStatus.unpaid,
          isVoid: false,
          program: program_academic,
          byAdmission: false,
          issue_year: academicYearObj(this.currentYear),
          payment_year: null,
          headerRef: null,
          isHeader: false,
          enroll_status: null,
          isEnrollVerify: true,
          schoolSession: null,
          description: doc.name,

          penaltyRef: null,
          penalty: null,
          scholarshipRef: null,
          scholarship: null,
          loan: null,
          prepaidRef: null,
          prepaid: null,
          price: doc.price,
          amount: doc.price,

          campusKey: selectedCampus.key,
          campus: selectedCampus,
          schoolKey: selectedSchool.key,
          school: selectedSchool,
        }
        array.push(item);
      })

      const { invoiceKey, paymentHeader } = this.store;
      const header = invoiceKey ? this.invoice.paymentHeader : paymentHeader
      this.store.addOtherFee(selectedCampus, selectedSchool, array, this.studentStore.student, header, (success, error) => {
        if (success) {
          this.snackBar.open("Other Fee has been added to invoice.", "Done", { duration: 2000 })
          this.dialogRef.close('yes');
          this.form.enable();
        }
        else {
          this.snackBar.open(error, "Error");
          this.form.enable();
        }
      })
    }
  }
}