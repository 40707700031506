import { MappingService } from 'src/app/services/mapping.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sumArray'
})
export class SumArrayPipe implements PipeTransform {

  transform(value: Array<any>, args?: any): any {
    return MappingService.sum(value,args);
  }

}
