import { NewInvoiceRouterComponent } from './pages/student/new-invoice-router/new-invoice-router.component';
import { NewInvoiceComponent } from './pages/student/new-invoice/new-invoice.component';
import { PaymentListingComponent } from './reports/payment-listing/payment-listing.component';
import { ShiftReportListingComponent } from './pages/shift-report/shift-report-listing/shift-report-listing.component';
import { ShiftReportComponent } from './pages/shift-report/shift-report.component';
import { AllProgramSummeryComponent } from './pages/report-summery/all-program-summery/all-program-summery.component';
import { PettyCashComponent } from './pages/petty-cash/petty-cash.component';
import { AddNewTestingComponent } from './components/add-new-testing/add-new-testing.component';
import { ReceiveTestingFeeComponent } from './pages/testing-fee/receive-testing-fee/receive-testing-fee.component';
import { TestingFeeComponent } from './pages/testing-fee/testing-fee.component';
import { SummaryReportComponent } from './pages/summary-report/summary-report.component';
import { OptionsComponent } from './pages/options/options.component';
import { AcademicProgramsComponent } from './pages/academic-programs/academic-programs.component';
import { AppTabsRouteComponent } from './shared/app-tabs-route/app-tabs-route.component';
import { CashInComponent } from './pages/cash-in/cash-in.component';
import { AppLayoutComponent } from "./shared/app-layout/app-layout.component";
import { AuthLayoutComponent } from "./shared/auth-layout/auth-layout.component";
import { LayoutComponent } from "./shared/layout/layout.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./auth/auth.guard";
import { HomeComponent } from "./pages/home/home.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { SigninComponent } from "./pages/signin/signin.component";
import { AdmissionComponent } from "./pages/admission/admission.component";
import { ReceivePaymentComponent } from "./pages/receive-payment/receive-payment.component";
import { InstitutesAndCentersComponent } from './pages/institutes-and-centers/institutes-and-centers.component';
import { InstituteFeeComponent } from './pages/institutes-and-centers/institute-fee/institute-fee.component';
import { OtherFeeComponent } from './pages/other-fee/other-fee.component';
import { ListingReportDailyComponent } from './pages/listing-report-daily/listing-report-daily.component';
import { TestingComponent } from './pages/testing/testing.component';
import { MiscellaneousFeeComponent } from './pages/miscellaneous-fee/miscellaneous-fee.component';
import { MiscellaneousFeeNonePucStudentComponent } from './pages/miscellaneous-fee/miscellaneous-fee-none-puc-student/miscellaneous-fee-none-puc-student.component';
import { MiscellaneousFeePucStudentComponent } from './pages/miscellaneous-fee/miscellaneous-fee-puc-student/miscellaneous-fee-puc-student.component';
import { StudentComponent } from './pages/student/student.component';
import { StudentPaymentComponent } from './pages/student/student-payment/student-payment.component';
import { StudentAbcComponent } from './pages/student/student-abc/student-abc.component';
import { StudentPreviewComponent } from './pages/student/student-preview/student-preview.component';
import { ProgramComponent } from './pages/program/program.component';
import { StudentPayTestingComponent } from './pages/student/student-pay-testing/student-pay-testing.component';
import { StudentReceiptComponent } from './pages/student/student-receipt/student-receipt.component';
import { ReportSummeryComponent } from './pages/report-summery/report-summery.component';
import { SearchInvoiceNoComponent } from './pages/search-invoice-no/search-invoice-no.component';
import { ReceivePaymentByProgramComponent } from './pages/testing-fee/receive-payment-by-program/receive-payment-by-program.component';
import { DailyShiftReceivePaymentComponent } from './pages/reports/daily-shift-receive-payment/daily-shift-receive-payment.component';
import { DailyShiftPaymentListingComponent } from './pages/reports/daily-shift-receive-payment/daily-shift-payment-listing/daily-shift-payment-listing.component';
import { DailyShiftListingsComponent } from './pages/reports/daily-shift-listings/daily-shift-listings.component';
import { PettyCashListingsComponent } from './pages/reports/petty-cash-listings/petty-cash-listings.component';
import { InstallmentListingsComponent } from './pages/reports/installment-listings/installment-listings.component';
import { ReceiptListingsComponent } from './pages/reports/receipt-listings/receipt-listings.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ShiftGuard } from './auth/shift.guard';
import { UnpaidPaymentComponent } from './pages/student/unpaid-payment/unpaid-payment.component';
import { StudentPenaltyComponent } from './pages/student/student-penalty/student-penalty.component';
import { AcademicYearComponent } from './pages/academic-year/academic-year.component';
import { RegistrationFormComponent } from './pages/registration-form/registration-form.component';
import { TrainingGradeComponent } from './pages/academic-year/training-grade/training-grade.component';
import { TrainingGradeFeeComponent } from './pages/academic-year/training-grade-fee/training-grade-fee.component';
import { OtherPaymentFeeComponent } from './pages/other-payment-fee/other-payment-fee.component';
import { OtherPaymentFeeDetailComponent } from './pages/other-payment-fee-detail/other-payment-fee-detail.component';
import { TrainingCampusComponent } from './pages/academic-year/training-campus/training-campus.component';
import { RegistrationFormRetakeTestComponent } from './pages/registration-form-retake-test/registration-form-retake-test.component';
import { PersonalComponent } from './pages/personal/personal.component';
import { StudentAdmissionComponent } from './pages/student-admission/student-admission.component';
import { ParentContactComponent } from './pages/parent-contact/parent-contact.component';
import { StudentCutPaymentComponent } from './pages/student/student-cut-payment/student-cut-payment.component';
import { MatchTermListingComponent } from './pages/reports/match-term-listing/match-term-listing.component';
import { StudentScholarshipComponent } from './pages/student-scholarship/student-scholarship.component';
import { UnpaidListingComponent } from './pages/reports/unpaid-listing/unpaid-listing.component';
import { RequestPenaltyListingComponent } from './pages/reports/request-penalty-listing/request-penalty-listing.component';
import { RequestVoidReceiptListingComponent } from './pages/reports/request-void-receipt-listing/request-void-receipt-listing.component';
import { ClearStudentPaymentComponent } from './pages/clear-student-payment/clear-student-payment.component';
import { StudentFeeWithPeriodComponent } from './pages/reports/student-fee-with-period/student-fee-with-period.component';
import { StudentRetakeTestComponent } from './pages/student-retake-test/student-retake-test.component';
import { ReportInstallmentPaymentComponent } from './pages/reports/report-installment-payment/report-installment-payment.component';
import { ReportInstallmentNextPaymentComponent } from './pages/reports/report-installment-next-payment/report-installment-next-payment.component';
import { ReportArComponent } from './pages/reports/report-ar/report-ar.component';

const routes: Routes = [
  {
    path: "auth",
    component: AuthLayoutComponent,
    children: [{ path: "", component: SigninComponent, pathMatch: "full" }]
  },
  {
    path: "",
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", component: DashboardComponent, pathMatch: "full" },
      { path: "add-new-testing", component: AddNewTestingComponent, canActivate: [ShiftGuard] },
      { path: "new-registration-form", component: RegistrationFormComponent, canActivate: [ShiftGuard] },
      // { path: "add-testing-exist-student/:id", component: AddTestingExistStudentComponent, canActivate: [ShiftGuard] },
      { path: "registration-form-retake-test/:id", component: RegistrationFormRetakeTestComponent, canActivate: [ShiftGuard] },
      {
        path: "student/:id/:admissionKey", component: StudentComponent, children: [
          { path: "receive-payment", component: StudentPaymentComponent },
          { path: "abc", component: StudentAbcComponent },
          { path: "preview", component: StudentPreviewComponent },
          { path: "pay-testing", component: StudentPayTestingComponent },
          { path: "receipt", component: StudentReceiptComponent },
          { path: "unpaid", component: UnpaidPaymentComponent },
          { path: "new-invoice/invoice-no/:invoiceKey", component: NewInvoiceComponent },
          { path: "new-invoice/invoice-no", component: NewInvoiceRouterComponent },
          { path: "scholar-penalty", component: StudentPenaltyComponent },
          { path: "student-admission", component: StudentAdmissionComponent },
          { path: "student-cut-payment", component: StudentCutPaymentComponent },
          { path: "personal", component: PersonalComponent },
          { path: "parent-contact", component: ParentContactComponent },
          { path: "scholarship", component: StudentScholarshipComponent },
          { path: "student-retake-test", component: StudentRetakeTestComponent },
        ]
      },
      { path: "receive-payment/:id", component: ReceivePaymentComponent },
      {
        path: "payment-listing", component: TestingFeeComponent, canActivate: [ShiftGuard], children: [
          { path: "", redirectTo: "all", pathMatch: "full" },
          { path: ":id", component: ReceiveTestingFeeComponent },
          { path: ":id/:tid", component: ReceivePaymentByProgramComponent },
        ]
      },
      {
        path: "daily-shift", component: DailyShiftReceivePaymentComponent, canActivate: [ShiftGuard], children: [
          { path: "", redirectTo: "listing", pathMatch: "full" },
          { path: "listing", component: DailyShiftPaymentListingComponent },
        ]
      },
      {
        path: "payment-summery", component: ReportSummeryComponent, canActivate: [ShiftGuard], children: [
          { path: "", redirectTo: "all", pathMatch: "full" },
          { path: ":id", component: AllProgramSummeryComponent },
        ]
      },
      {
        path: "report-by-shift", component: ShiftReportComponent, children: [
          { path: "", redirectTo: "all", pathMatch: "full" },
          { path: ":id", component: ShiftReportListingComponent },
        ]
      },
      {
        path: "receive-miscellaneous-fee", component: MiscellaneousFeeComponent, children: [
          { path: "", redirectTo: "puc-student", pathMatch: "full" },
          { path: "puc-student", component: MiscellaneousFeePucStudentComponent },
          { path: "none-puc-student", component: MiscellaneousFeeNonePucStudentComponent },
        ]
      },
      { path: "receive-payments/:sid/:id", component: CashInComponent },
      { path: "search-invoice-no", component: SearchInvoiceNoComponent },
    ]
  },

  {
    path: "app",
    component: AppLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", component: HomeComponent, pathMatch: "full" },
      { path: "admission", component: AdmissionComponent },
      { path: "options", component: OptionsComponent },
      {
        path: "institutes-and-center", component: AppTabsRouteComponent, children: [
          { path: "", redirectTo: "listing", pathMatch: "full" },
          { path: "listing", component: InstitutesAndCentersComponent },
          { path: "program/:id", component: InstituteFeeComponent },
          { path: "testing", component: ProgramComponent },
        ]
      },
      { path: "other-fee", component: OtherFeeComponent },
      { path: "listing-report-daily", component: ListingReportDailyComponent },
      { path: "summary-report", component: SummaryReportComponent },
      {
        path: "academic-programs",
        component: AppTabsRouteComponent,
        children: [
          { path: "", redirectTo: "all", pathMatch: "full" },
          { path: ":id", component: AcademicProgramsComponent },
        ]
      },
      {
        path: "testing",
        component: AppTabsRouteComponent,
        children: [
          { path: "", redirectTo: "list", pathMatch: "full" },
          { path: ":id", component: TestingComponent },
        ]
      },
      {
        path: 'class-schedule', component: AppTabsRouteComponent,
        children: [
          { path: '', redirectTo: 'data', pathMatch: 'full' },
          { path: ':id', component: UnpaidListingComponent },
        ]
      },
      {
        path: "payment-listing",
        component: AppTabsRouteComponent,
        children: [
          { path: "", redirectTo: "today", pathMatch: "full" },
          { path: ":id", component: PaymentListingComponent },
        ]
      },
      {
        path: "daily-shift-listing",
        component: AppTabsRouteComponent,
        children: [
          { path: "", redirectTo: "today", pathMatch: "full" },
          { path: ":id", component: DailyShiftListingsComponent },
        ]
      },
      {
        path: "receipt-listing",
        component: AppTabsRouteComponent,
        children: [
          { path: "", redirectTo: "today", pathMatch: "full" },
          { path: ":id", component: ReceiptListingsComponent },
        ]
      },
      {
        path: "expense-listing",
        component: AppTabsRouteComponent,
        children: [
          { path: "", redirectTo: "today", pathMatch: "full" },
          { path: ":id", component: PettyCashListingsComponent },
        ]
      },
      {
        path: "request-penalty-listing",
        component: AppTabsRouteComponent,
        children: [
          { path: "", redirectTo: "today", pathMatch: "full" },
          { path: ":id", component: RequestPenaltyListingComponent },
        ]
      },
      {
        path: "request-void-receipt-listing",
        component: AppTabsRouteComponent,
        children: [
          { path: "", redirectTo: "today", pathMatch: "full" },
          { path: ":id", component: RequestVoidReceiptListingComponent },
        ]
      },
      {
        path: "student-fee-with-period-listing",
        component: AppTabsRouteComponent,
        children: [
          { path: "", redirectTo: "today", pathMatch: "full" },
          { path: ":id", component: StudentFeeWithPeriodComponent },
        ]
      },
      {
        path: "match-term-listing",
        component: AppTabsRouteComponent,
        children: [
          { path: "", redirectTo: "today", pathMatch: "full" },
          { path: ":id", component: MatchTermListingComponent },
        ]
      },
      {
        path: "installment-payment-listing",
        component: AppTabsRouteComponent,
        children: [
          { path: "", redirectTo: "today", pathMatch: "full" },
          { path: ":id", component: ReportInstallmentPaymentComponent },
        ]
      },
      {
        path: "installment-next-payment",
        component: AppTabsRouteComponent,
        children: [
          { path: "", redirectTo: "today", pathMatch: "full" },
          { path: ":id", component: ReportInstallmentNextPaymentComponent },
        ]
      },
      {
        path: "payment-ar",
        component: AppTabsRouteComponent,
        children: [
          { path: "", redirectTo: "today", pathMatch: "full" },
          { path: ":id", component: ReportArComponent },
        ]
      },
      {
        path: 'clear-student-payment', children: [
          { path: "", redirectTo: "listing", pathMatch: "full" },
          { path: "listing", component: ClearStudentPaymentComponent }
        ]
      },
      {
        path: "installment-listing",
        component: AppTabsRouteComponent,
        children: [
          { path: "", redirectTo: "today", pathMatch: "full" },
          { path: ":id", component: InstallmentListingsComponent },
        ]
      },
      {
        path: "petty-cash",
        component: AppTabsRouteComponent,
        children: [
          { path: "", redirectTo: "list", pathMatch: "full" },
          { path: ":id", component: PettyCashComponent },
        ]
      },
      {
        path: "academic-year",
        component: AppTabsRouteComponent,
        children: [
          { path: "", redirectTo: "data", pathMatch: "full" },
          { path: "data", component: AcademicYearComponent },
          { path: "training/:id", component: TrainingGradeComponent },
          { path: "training/:id/:cid", component: TrainingCampusComponent },
          { path: "year-training-fee/:id", component: TrainingGradeFeeComponent },
        ]
      },
      {
        path: "other-payment-fee",
        component: AppTabsRouteComponent,
        children: [
          { path: "", redirectTo: "data", pathMatch: "full" },
          { path: "data", component: OtherPaymentFeeComponent },
          { path: "detail/:id", component: OtherPaymentFeeDetailComponent },
        ]
      },
    ]
  },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [AuthGuard, ShiftGuard],
  exports: [RouterModule]
})
export class AppRoutingModule { }
