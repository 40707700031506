import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/auth/user';
import { Environment } from 'src/app/stores/environment.store';
import { tabs } from 'src/app/dummy/tabs';

@Component({
  selector: 'app-daily-shift-receive-payment',
  templateUrl: './daily-shift-receive-payment.component.html',
  styleUrls: ['./daily-shift-receive-payment.component.scss']
})
export class DailyShiftReceivePaymentComponent implements OnInit {
  tabs=tabs.payments
  
  constructor(
    private router: Router,
    public env:Environment
  ) { }

  ngOnInit() {
    this.env.getUser(user=>{})
  }
 
  _goBack() {
    this.router.navigate(['/']);
  }

}
