import { MappingService } from './../../services/mapping.service';
import { ActivatedRoute } from '@angular/router';
import { Environment } from './../../stores/environment.store';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AbstractControl } from '@angular/forms';
import { Report } from './../../stores/report.store';
import { Component, OnInit } from '@angular/core';
import { tabs } from 'src/app/dummy/tabs';
import { PrintService } from 'src/app/services/print.service';
import { SCHOOL } from 'src/app/dummy/config';

@Component({
  selector: 'app-payment-listing',
  templateUrl: './payment-listing.component.html',
  styleUrls: ['./payment-listing.component.scss']
})
export class PaymentListingComponent implements OnInit {
  tabs=tabs.reportFilterBy;
  school=SCHOOL

  form: FormGroup;
  program: AbstractControl;
  isAll: AbstractControl;
  institute: AbstractControl;
  term: AbstractControl;
  isTerm: AbstractControl;
  fromDate: AbstractControl;
  status: AbstractControl;
  toDate: AbstractControl;

  filteredStates: any;
  filteredProgramStates: any;
  filteredTermStates: any;
  filterStatus = tabs.panel;
  selectedTerm: any;

  process = false;
  type: string;
  isCheckTerm = false;

  constructor(
    private fb: FormBuilder,
    private ps: PrintService,
    public report: Report,
    public env: Environment,
    private route: ActivatedRoute,
    public store: Report
  ) {}

  print() {
    this.ps.print("#print", "a4");
  }

  ngOnInit() {
    this.form = this.fb.group({
      program: [
        { value: null, disabled: true },
        Validators.compose([Validators.required])
      ],
      institute: [null, Validators.compose([Validators.required])],
      term: [null, Validators.compose([Validators.required])],
      isTerm: [this.isCheckTerm, Validators.required],
      isAll: [true, Validators.required],
      fromDate: [null, Validators.required],
      toDate: [null, Validators.required],
      status: [this.filterStatus[0], Validators.required]
    });
    this.program = this.form.controls["program"];
    this.institute = this.form.controls["institute"];
    this.term = this.form.controls["term"];
    this.isTerm = this.form.controls["isTerm"];
    this.isAll = this.form.controls["isAll"];
    this.fromDate = this.form.controls["fromDate"];
    this.toDate = this.form.controls["toDate"];
    this.status = this.form.controls["status"];

    this.route.params.forEach(param => {
      this.type = param["id"];
      this.form.patchValue({
        fromDate: MappingService.fromDateFilter(this.type),
        toDate: MappingService.toDateFilter(this.type)
      });
      if (this.type === "custom") {
        this.fromDate.enable();
        this.toDate.enable();
      } else {
        this.fromDate.disable();
        this.toDate.disable();
      }

    });

    // this.store.fetchInstituteToArray(res => {
    //   this.filteredStates = res;
    //   if (res.length > 0) {
    //     this.form.patchValue({
    //       institute: res[0]
    //     });
    //     this.report.fetchTermReport(term=>{
    //       this.filteredTermStates = term;
    //       this.selectedTerm = this.filteredTermStates.filter(m=>m.institute.key ==res[0].key)[0];
    //       this.term.patchValue(this.selectedTerm);
    //       this.term.disable();
    //     })
    //     this._onPress(this.form.value);
    //     this.store.fetchProgramInstitute(res[0].key, lst => {
    //       this.filteredProgramStates = lst;
    //       this.form.patchValue({
    //         program: lst[0]
    //       });
    //     });
    //   }
    // });
  }

  _onSelectedInstitute(event) {
    const { key } = event.value;
    // this.store.fetchProgramInstitute(key, res => {
    //   this.filteredProgramStates = res;
    //   this.selectedTerm = this.filteredTermStates.filter(m=>m.institute.key == key)[0];
    //   this.term.patchValue(this.selectedTerm)
    // });
  }

  _onChange(event) {
    const { checked } = event;
    if (checked) {
      this.program.disable();
    } else {
      this.program.enable();
    }
  }

  _onCheckTerm(event) {
    const { checked } = event;
    this.isCheckTerm = checked;
    if (!this.isCheckTerm) {
      this.term.disable();
      // this.form.patchValue({
      //   fromDate: MappingService.fromDateFilter(this.type),
      //   toDate: MappingService.toDateFilter(this.type),
      // });
    } else {
      this.term.enable();
      this.form.patchValue({
        fromDate: this.selectedTerm.startterm.toDate(),
        toDate: this.selectedTerm.endterm.toDate(),
      });
    }
  }

  _onPress(f: any) {
    // if (this.form.valid) {
    //   this.process = true;
    //   const { fromDate, toDate, institute,term, program, isAll, status } = f;
    //   let filterFromDate = fromDate;
    //   let filterToDate = toDate;
    //   if(!filterFromDate && !filterToDate && !this.isCheckTerm){
    //     filterFromDate = MappingService.fromDateFilter(this.type);
    //     filterToDate = MappingService.toDateFilter(this.type);
    //   }
    //   if(this.isCheckTerm){
    //     filterFromDate = this.selectedTerm.startterm.toDate();
    //     filterToDate = this.selectedTerm.endterm.toDate();
    //   }

    //   this.form.disable();
    //   this.store.fetchTestingListing(
    //     ConvertService.toDateKey(filterFromDate),
    //     ConvertService.toDateKey(filterToDate),
    //     institute,
    //     term,
    //     program,
    //     isAll,
    //     status,
    //     res => {
    //       this.form.enable();
    //       if (isAll) {
    //         this.program.disable();
    //       } else {
    //         this.program.enable();
    //       }
    //       if (this.type === "custom") {
    //         this.fromDate.enable();
    //         this.toDate.enable();
    //       } else {
    //         this.fromDate.disable();
    //         this.toDate.disable();
    //       }
    //       this.process = false;
    //     }
    //   );
    // }
  }
}
