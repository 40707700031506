import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-miscellaneous-fee-none-puc-student',
  templateUrl: './miscellaneous-fee-none-puc-student.component.html',
  styleUrls: ['./miscellaneous-fee-none-puc-student.component.scss']
})
export class MiscellaneousFeeNonePucStudentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
