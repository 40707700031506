import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { debounceTime, tap, switchMap } from 'rxjs/operators';
import { COLLECTION_DATA, FILTER_FIELD } from 'src/app/dummy/config';
import { DISCOUNT_POLICY_STUDENT_OBJ } from 'src/app/dummy/status';
import { ConvertService } from 'src/app/services/convert.service';
import { DataService } from 'src/app/services/data.service';
import { MappingService } from 'src/app/services/mapping.service';
import { AutoCompleteStore } from 'src/app/stores/autocomplete.store';
import { BaseStore } from 'src/app/stores/base.store';
import { Environment } from 'src/app/stores/environment.store';
import { Payment } from 'src/app/stores/payment.store';
import { Student } from 'src/app/stores/student.store';

@Component({
  selector: 'app-add-student-discount-voucher',
  templateUrl: './add-student-discount-voucher.component.html',
  styleUrls: ['./add-student-discount-voucher.component.scss']
})
export class AddStudentDiscountVoucherComponent implements OnInit {
  form: FormGroup;
  voucher: AbstractControl;

  autoItem: any;
  selectedItem;
  isExist;
  totalScholarshipDeduct = 0;
  totalDiscountVoucher = 0;
  discountVoucher;

  constructor(
    public dialogRef: MatDialogRef<AddStudentDiscountVoucherComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    public env: Environment,
    public dialog: MatDialog,
    public base: BaseStore,
    private ds: DataService,
    public store: Student,
    private autoStore: AutoCompleteStore,
    public payment: Payment,
  ) { }

  buildForm(): void {
    this.form = this.fb.group({
      voucher: [null, Validators.compose([Validators.required, MappingService.validSelected.bind(this)])],
    });
    this.voucher = this.form.controls["voucher"];
  }

  async ngOnInit() {
    this.buildForm();
    await this.env.fetchUserDoc()
    const { selectedSchool } = this.env

    const { globalDiscount, scholarshipDiscount } = this.payment

    this.totalScholarshipDeduct = scholarshipDiscount ? (ConvertService.toNumber(scholarshipDiscount.amount) + ConvertService.toNumber(scholarshipDiscount.amountOther)) : 0;
    if ((globalDiscount && ConvertService.toNumber(globalDiscount.amount)) > (scholarshipDiscount && (ConvertService.toNumber(scholarshipDiscount.amount) + ConvertService.toNumber(scholarshipDiscount.amountOther)))) {
      this.totalScholarshipDeduct = ConvertService.toNumber(globalDiscount.amount)
    }

    this.autoStore.fetchAutoDataSchool(selectedSchool.key, COLLECTION_DATA.paymentDiscountVoucherDetail, FILTER_FIELD.voucher_no, docs => {
      this.autoItem = docs.filter(m => !m.isPaid);
    });

    this.voucher.valueChanges
      .pipe(
        debounceTime(300),
        tap(() => (false)),
        switchMap(value => this.autoStore.fetchAutoSearchDataSchool(selectedSchool.key, COLLECTION_DATA.paymentDiscountVoucherDetail, FILTER_FIELD.voucher_no, value, docs => {
          if (docs) {
            this.autoItem = docs.filter(m => !m.isPaid);
          }
        }))
      ).subscribe(res => { });
  }

  displayItemReceipt(item: any): string {
    return item ? item.voucher_no : item;
  }

  checkVoucher(value) {
    const { paymentHeader } = this.payment;
    let canAdd = false;

    if (value.apply_student.key === DISCOUNT_POLICY_STUDENT_OBJ.all.key) {
      canAdd = true;
    } else if (value.apply_student.key === DISCOUNT_POLICY_STUDENT_OBJ.old.key
      && paymentHeader.student_register === DISCOUNT_POLICY_STUDENT_OBJ.old.text) {
      canAdd = true;
    } else if (value.apply_student.key === DISCOUNT_POLICY_STUDENT_OBJ.new.key
      && paymentHeader.student_register === DISCOUNT_POLICY_STUDENT_OBJ.new.text) {
      canAdd = true;
    }
    return canAdd;
  }

  async onSelectVoucher(event) {
    const { value } = event.option;
    if (value) {

      this.selectedItem = value;
      const canAdd = this.checkVoucher(value);

      this.isExist = value.isPaid || value.headerRef || value.discount_voucher.expired_date_key <= ConvertService.dateKey() || !canAdd

      const { paymentTuitionFee } = this.payment
      const tuitionFee = paymentTuitionFee && paymentTuitionFee.length > 0 ? paymentTuitionFee[0] : null;
      if (!this.isExist && tuitionFee) {
        this.discountVoucher = this.payment.getDiscountVoucher(tuitionFee, value)
        this.totalDiscountVoucher = this.discountVoucher.amount
      }
      
    }
    else {
      this.isExist = false
      this.selectedItem = null;
    }
  }

  create(f: any) {
    if (this.totalDiscountVoucher < this.totalScholarshipDeduct || !this.discountVoucher) return;
    if (this.form.valid && !this.isExist) {

      const { paymentHeader } = this.payment;
      const { voucher } = f;

      this.store.addDiscountVoucher(voucher, paymentHeader, this.discountVoucher, this.env.user, (success, error) => {
        if (success) {
          this.snackBar.open("Discount voucher has been added.", "Done", { duration: 2000 })
          this.dialogRef.close('yes');
        }
        else {
          this.snackBar.open(error, "Error");
        }
      })

    }
  }

}
