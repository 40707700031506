import { ConvertService } from './../../../services/convert.service';
import { DataService } from 'src/app/services/data.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Environment } from './../../../stores/environment.store';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { BaseStore } from 'src/app/stores/base.store';
import { StatusObj, MONTHS_LIST } from 'src/app/dummy/status';
import { checkExistDoc } from 'src/app/services/fire-validators.service';
import { IOtherServiceDetail } from 'src/app/interfaces/otherService';
import { userObj, toNull } from 'src/app/services/mapping.service';

@Component({
  selector: 'app-add-new-other-payment-fee-detail',
  templateUrl: './add-new-other-payment-fee-detail.component.html',
  styleUrls: ['./add-new-other-payment-fee-detail.component.scss']
})
export class AddNewOtherPaymentFeeDetailComponent implements OnInit {
  @ViewChild("focusInput") inputEl: ElementRef;
  form: FormGroup;
  name: AbstractControl;
  order: AbstractControl;
  description: AbstractControl;
  isPeriod: AbstractControl;
  period: AbstractControl;
  price: AbstractControl;

  MONTHS_LIST = MONTHS_LIST;
  constructor(
    public dialogRef: MatDialogRef<AddNewOtherPaymentFeeDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public env: Environment,
    private snackBar: MatSnackBar,
    public store: BaseStore,
    private afs: AngularFirestore,
    private ds: DataService
  ) { }

  buildForm(): void {
    this.form = this.fb.group({
      name: [null, Validators.compose([Validators.required])],
      order: [null, Validators.required],
      description: [null,],
      isPeriod: [false,],
      period: [null,],
      price: [null, Validators.required],
    })
    this.name = this.form.controls['name'];
    this.order = this.form.controls['order'];
    this.description = this.form.controls['description'];
    this.isPeriod = this.form.controls['isPeriod'];
    this.period = this.form.controls['period'];
    this.price = this.form.controls['price'];
  }

  async ngOnInit() {
    this.buildForm();
    await this.env.fetchUserDoc()
    this.period.disable();
  }

  compareObjects(o1: any, o2: any): boolean {
    if (o2) return o1.key === o2.key;
  }

  _onCheckBox(event) {
    const { checked } = event;
    if (checked) this.period.enable();
    else this.period.disable();
  }

  create(f: any, isNew) {
    if (this.form.valid) {
      this.form.disable();
      const { name, order, isPeriod, description, period, price } = f;
      const {selectedSchool} = this.env
      const key = this.afs.createId();
      const item: IOtherServiceDetail = {
        key: key,
        status: StatusObj.ACTIVE,
        create_date: new Date(),
        create_by: userObj(this.env.user),
        update_date: new Date(),
        update_by: userObj(this.env.user),
        pageKey: ConvertService.pageKey(),
        otherFee: {
          key: this.data.parent.key,
          name: this.data.parent.name,
        },
        name,
        order,
        description,
        isPeriod,
        period: toNull(period),
        price
      };
      this.store.addNew(this.ds.otherServiceFeeRef(selectedSchool.key).doc(this.data.parent.key).collection("admission_service_other_detail"), item, (success, error) => {
        if (success) {
          if (!isNew)
            this.dialogRef.close();
          this.snackBar.open('Other Service Fee has been created.', 'done', { duration: 2500 });
          this.form.enable();
          this.form.reset();
          this.inputEl.nativeElement.focus();
        }
        else {
          alert(error)
        }
      })
    }
  }

}
