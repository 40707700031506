import { APP_ENTRY } from './components/app.entry';
import { APP_STORES } from './stores/app.store';
import { ConvertService } from './services/convert.service';
import { MaterialModule } from './module/material.module';
import { AuthService } from './auth/auth.service';
import { HomeComponent } from './pages/home/home.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './shared/header/header.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { LayoutComponent } from './shared/layout/layout.component';
import { AuthLayoutComponent } from './shared/auth-layout/auth-layout.component';
import { SigninComponent } from './pages/signin/signin.component';
import { AppLayoutComponent } from './shared/app-layout/app-layout.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { FireValidatorsService } from './services/fire-validators.service';
import { DataService } from './services/data.service';
import { MessageComponent } from './components/message/message.component';
import { NetInfoComponent } from './components/net-info/net-info.component';
import { MobxAngularModule } from 'mobx-angular';
import { AdmissionComponent } from './pages/admission/admission.component';
import { HeaderNavComponent } from './components/header-nav/header-nav.component';
import { FocusSearchDirective } from './directives/focus-search.directive';
import { ReceivePaymentComponent } from './pages/receive-payment/receive-payment.component';
import { CashInComponent } from './pages/cash-in/cash-in.component';
import { InvoiceTemplateComponent } from './components/invoice-template/invoice-template.component';
import { PrintService } from './services/print.service';
import { ShiftOpenComponent } from './pages/shift-open/shift-open.component';
import { ShiftCloseComponent } from './pages/shift-close/shift-close.component';
import { HeaderTabsComponent } from './components/header-tabs/header-tabs.component';
import { AppTabsRouteComponent } from './shared/app-tabs-route/app-tabs-route.component';
import { EmptyDataComponent } from './components/empty-data/empty-data.component';
import { InstitutesAndCentersComponent } from './pages/institutes-and-centers/institutes-and-centers.component';
import { InstituteFeeComponent } from './pages/institutes-and-centers/institute-fee/institute-fee.component';
import { AcademicProgramsComponent } from './pages/academic-programs/academic-programs.component';
import { DeleteComponent } from './components/delete/delete.component';
import { AddAcademicFeeComponent } from './components/add-academic-fee/add-academic-fee.component';
import { OptionsComponent } from './pages/options/options.component';
import { AddInstiuteFeeComponent } from './components/add-instiute-fee/add-instiute-fee.component';
import { CashierShiftComponent } from './components/cashier-shift/cashier-shift.component';
import { CashierShiftClosedComponent } from './components/cashier-shift-closed/cashier-shift-closed.component';
import { StudentComponent } from './pages/student/student.component';
import { OtherFeeComponent } from './pages/other-fee/other-fee.component';
import { AddReceivePaymentComponent } from './components/add-receive-payment/add-receive-payment.component';
import { ListingReportComponent } from './pages/listing-report/listing-report.component';
import { SummaryReportComponent } from './pages/summary-report/summary-report.component';
import { LoginShellComponent } from './components/login-shell/login-shell.component';
import { FireStoreService } from './services/utils.lib';
import { ListingReportDailyComponent } from './pages/listing-report-daily/listing-report-daily.component';
import { AddNewTestingFormComponent } from './pages/testing/add-new-testing-form/add-new-testing-form.component';
import { EditNewTestingFormComponent } from './pages/testing/edit-new-testing-form/edit-new-testing-form.component';
import { TestingComponent } from './pages/testing/testing.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PaginationLoaderComponent } from './components/pagination-loader/pagination-loader.component';
import { TestingFeeComponent } from './pages/testing-fee/testing-fee.component';
import { ReceiveTestingFeeComponent } from './pages/testing-fee/receive-testing-fee/receive-testing-fee.component';
import { RegisterTestingFormComponent } from './pages/testing-fee/register-testing-form/register-testing-form.component';
import { EmptyPageComponent } from './components/empty-page/empty-page.component';
import { MiscellaneousFeeComponent } from './pages/miscellaneous-fee/miscellaneous-fee.component';
import { MiscellaneousFeePucStudentComponent } from './pages/miscellaneous-fee/miscellaneous-fee-puc-student/miscellaneous-fee-puc-student.component';
import { MiscellaneousFeeNonePucStudentComponent } from './pages/miscellaneous-fee/miscellaneous-fee-none-puc-student/miscellaneous-fee-none-puc-student.component';
import { MiscellaneousFeeListComponent } from './components/miscellaneous-fee-list/miscellaneous-fee-list.component';
import { StudentInfoComponent } from './components/student-info/student-info.component';
import { AddReceivePaymentMiscellaneousComponent } from './components/add-receive-payment-miscellaneous/add-receive-payment-miscellaneous.component';
import { NavHeaderComponent } from './shared/nav-header/nav-header.component';
import { StudentPaymentComponent } from './pages/student/student-payment/student-payment.component';
import { StudentAbcComponent } from './pages/student/student-abc/student-abc.component';
import { StudentPreviewComponent } from './pages/student/student-preview/student-preview.component';
import { AddSchoolPaymentComponent } from './components/add-school-payment/add-school-payment.component';
import { PrimaryButtonComponent } from './components/primary-button/primary-button.component';
import { InvoicePaymentComponent } from './components/invoice-payment/invoice-payment.component';
import { AddNewTestingComponent } from './components/add-new-testing/add-new-testing.component';
import { ProgramComponent } from './pages/program/program.component';
import { AddProgramFeeComponent } from './components/add-program-fee/add-program-fee.component';
import { AddTestingPaymentComponent } from './components/add-testing-payment/add-testing-payment.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AddTrainingFeeComponent } from './components/add-training-fee/add-training-fee.component';
import { MiscellanousFeeFormComponent } from './pages/miscellaneous-fee/miscellanous-fee-form/miscellanous-fee-form.component';

import { StudentPayTestingComponent } from './pages/student/student-pay-testing/student-pay-testing.component';
import { TestingCardComponent } from './components/testing-card/testing-card.component';
import { PucButtonComponent } from './components/puc-button/puc-button.component';
import { AddDiscountInvoiceComponent } from './components/add-discount-invoice/add-discount-invoice.component';
import { AddMiscellaneousInvoiceComponent } from './components/add-miscellaneous-invoice/add-miscellaneous-invoice.component';
import { StudentReceiptComponent } from './pages/student/student-receipt/student-receipt.component';
import { PettyCashComponent } from './pages/petty-cash/petty-cash.component';
import { AddPettyCashComponent } from './components/add-petty-cash/add-petty-cash.component';
import { ReportSummeryComponent } from './pages/report-summery/report-summery.component';
import { AllProgramSummeryComponent } from './pages/report-summery/all-program-summery/all-program-summery.component';
import { SumReportPipe } from './pipes/sum-report.pipe';
import { SumArrayPipe } from './pipes/sum-array.pipe';
import { SearchInvoiceNoComponent } from './pages/search-invoice-no/search-invoice-no.component';
import { InvoiceDialogComponent } from './components/invoice-dialog/invoice-dialog.component';
import { ShiftReportComponent } from './pages/shift-report/shift-report.component';
import { ShiftReportListingComponent } from './pages/shift-report/shift-report-listing/shift-report-listing.component';
import { ReportsComponent } from './reports/reports.component';
import { PaymentListingComponent } from './reports/payment-listing/payment-listing.component';
import { FilterInvoiceProgramPipe } from './pipes/filter-invoice-program.pipe';
import { ReceivePaymentByProgramComponent } from './pages/testing-fee/receive-payment-by-program/receive-payment-by-program.component';
import { InstituteAndCenterComponent } from './pages/institute-and-center/institute-and-center.component';
import { StudentInstallmentComponent } from './pages/student/student-installment/student-installment.component';
import { SumTuitionFeeOtherPipe, SumTuitionFeePipe } from './pipes/sum-tuition-fee.pipe';
import { DailyShiftReceivePaymentComponent } from './pages/reports/daily-shift-receive-payment/daily-shift-receive-payment.component';
import { DailyShiftPaymentListingComponent } from './pages/reports/daily-shift-receive-payment/daily-shift-payment-listing/daily-shift-payment-listing.component';
import { DailyShiftListingsComponent } from './pages/reports/daily-shift-listings/daily-shift-listings.component';
import { PettyCashListingsComponent } from './pages/reports/petty-cash-listings/petty-cash-listings.component';
import { InstallmentListingsComponent } from './pages/reports/installment-listings/installment-listings.component';
import { ReceiptListingsComponent } from './pages/reports/receipt-listings/receipt-listings.component';
import { MediumDatePipe } from './pipes/medium-date.pipe';
import { ReportReceiptDialogComponent } from './components/report-receipt-dialog/report-receipt-dialog.component';
import { ReportReceiptSummaryDialogComponent } from './components/report-receipt-summary-dialog/report-receipt-summary-dialog.component';
import { ReportShiftSummaryDialogComponent } from './components/report-shift-summary-dialog/report-shift-summary-dialog.component';
import { SumTotalInvoicePipe } from './pipes/sum-total-invoice.pipe';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { UnpaidPaymentComponent } from './pages/student/unpaid-payment/unpaid-payment.component';
import { AddUnpaidPaymentComponent } from './components/add-unpaid-payment/add-unpaid-payment.component';
import { AddUnpaidAcademicComponent } from './components/add-unpaid-academic/add-unpaid-academic.component';
import { EditAcademicFeeComponent } from './components/edit-academic-fee/edit-academic-fee.component';
import { DateTimePipe } from './pipes/date-time.pipe';
import { DateOnlyPipe } from './pipes/date-only.pipe';
import { AddTestingExistStudentComponent } from './components/add-testing-exist-student/add-testing-exist-student.component';
import { StudentResolveFeeComponent } from './pages/student/student-resolve-fee/student-resolve-fee.component';
import { DaysSchedulePipe } from './pipes/days-schedule.pipe';
import { NewInvoiceComponent } from './pages/student/new-invoice/new-invoice.component';
import { ConfirmSuccessComponent } from './components/confirm-success/confirm-success.component';
import { NewInvoiceRouterComponent } from './pages/student/new-invoice-router/new-invoice-router.component';
import { StudentPenaltyComponent } from './pages/student/student-penalty/student-penalty.component';
import { AddPrepaidStudentComponent } from './components/add-prepaid-student/add-prepaid-student.component';
import { AddDiscountComponent } from './components/add-discount/add-discount.component';
import { EditEnglishScheduleComponent } from './components/edit-english-schedule/edit-english-schedule.component';
import { ProgramShiftStudentComponent } from './pages/student/program-shift-student/program-shift-student.component';
import { ResolveEnglishFeeComponent } from './components/resolve-english-fee/resolve-english-fee.component';
import { AddEnglishAdmissionComponent } from './components/add-english-admission/add-english-admission.component';
import { ResolveEnglishScholarshipComponent } from './components/resolve-english-scholarship/resolve-english-scholarship.component';
import { DateMediumPipe } from './pipes/date-medium.pipe';
import { EditEnglishPaymentComponent } from './components/edit-english-payment/edit-english-payment.component';
import { AcademicYearComponent } from './pages/academic-year/academic-year.component';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { RegistrationFormComponent } from './pages/registration-form/registration-form.component';
import { TrainingGradeComponent } from './pages/academic-year/training-grade/training-grade.component';
import { TrainingGradeFeeComponent } from './pages/academic-year/training-grade-fee/training-grade-fee.component';
import { AddTrainingGradeComponent } from './pages/academic-year/add-training-grade/add-training-grade.component';
import { EditTrainingGradeComponent } from './pages/academic-year/edit-training-grade/edit-training-grade.component';
import { TrainingGradePaymentOptionComponent } from './pages/academic-year/training-grade-payment-option/training-grade-payment-option.component';
import { AddSchoolFeeComponent } from './components/add-school-fee/add-school-fee.component';
import { OtherPaymentFeeComponent } from './pages/other-payment-fee/other-payment-fee.component';
import { AddNewOtherPaymentFeeComponent } from './pages/other-payment-fee/add-new-other-payment-fee/add-new-other-payment-fee.component';
import { EditOtherPaymentFeeComponent } from './pages/other-payment-fee/edit-other-payment-fee/edit-other-payment-fee.component';
import { OtherPaymentFeeDetailComponent } from './pages/other-payment-fee-detail/other-payment-fee-detail.component';
import { AddNewOtherPaymentFeeDetailComponent } from './pages/other-payment-fee-detail/add-new-other-payment-fee-detail/add-new-other-payment-fee-detail.component';
import { EditOtherPaymentFeeDetailComponent } from './pages/other-payment-fee-detail/edit-other-payment-fee-detail/edit-other-payment-fee-detail.component';
import { AddOtherFeeComponent } from './components/add-other-fee/add-other-fee.component';
import { SumOtherFeePipe } from './pipes/sum-other-fee.pipe';
import { TrainingCampusComponent } from './pages/academic-year/training-campus/training-campus.component';
import { GroupByProgramPipe } from './pipes/group-by-program.pipe';
import { GetCampusPipe } from './pipes/get-campus.pipe';
import { DocRefDatePipe } from './pipes/doc-ref-date.pipe';
import { DocRefPipe } from './pipes/doc-ref.pipe';
import { RegistrationFormRetakeTestComponent } from './pages/registration-form-retake-test/registration-form-retake-test.component';
import { StudentAdmissionComponent } from './pages/student-admission/student-admission.component';
import { PersonalComponent } from './pages/personal/personal.component';
import { ParentContactComponent } from './pages/parent-contact/parent-contact.component';
import { GetSchoolConfigPipe } from './pipes/get-school-config.pipe';
import { NewInvoicePosComponent } from './pages/student/new-invoice-pos/new-invoice-pos.component';
import { AddStudentPaymentComponent } from './pages/student/add-student-payment/add-student-payment.component';
import { SetAdmissionComponent } from './pages/student-admission/set-admission/set-admission.component';
import { StudentCutPaymentComponent } from './pages/student/student-cut-payment/student-cut-payment.component';
import { AddStudentCutPaymentComponent } from './pages/student/student-cut-payment/add-student-cut-payment/add-student-cut-payment.component';
import { MatchTermListingComponent } from './pages/reports/match-term-listing/match-term-listing.component';
import { AddStudentPenaltyComponent } from './components/add-student-penalty/add-student-penalty.component';
import { SumDiscountArrayPipe } from './pipes/sum-discount-array.pipe';
import { SumScholarshipArrayPipe } from './pipes/sum-scholarship-array.pipe';
import { EditPettyCashComponent } from './components/edit-petty-cash/edit-petty-cash.component';
import { ConfirmDeleteComponent } from './components/confirm-delete/confirm-delete.component';
import { StudentScholarshipComponent } from './pages/student-scholarship/student-scholarship.component';
import { StudentScholarshipCardComponent } from './components/student-scholarship-card/student-scholarship-card.component';
import { PreviewImageComponent } from './components/preview-image/preview-image.component';
import { FileSizePipe } from './pipes/file-size.pipe';
import { UnpaidListingComponent } from './pages/reports/unpaid-listing/unpaid-listing.component';
import { CashierVoidReceiptComponent } from './components/cashier-void-receipt/cashier-void-receipt.component';
import { RequestPenaltyListingComponent } from './pages/reports/request-penalty-listing/request-penalty-listing.component';
import { RequestVoidReceiptListingComponent } from './pages/reports/request-void-receipt-listing/request-void-receipt-listing.component';
import { AddStudentDiscountVoucherComponent } from './components/add-student-discount-voucher/add-student-discount-voucher.component';
import { AddStudentOldVoucherComponent } from './components/add-student-old-voucher/add-student-old-voucher.component';
import { AddCapitalFeeComponent } from './pages/student/add-capital-fee/add-capital-fee.component';
import { StudentUnpaidPaymentListingComponent } from './pages/clear-student-payment/student-unpaid-payment-listing/student-unpaid-payment-listing.component';
import { ClearStudentPaymentComponent } from './pages/clear-student-payment/clear-student-payment.component';
import { EmptyComponent } from './components/empty/empty.component';
import { ClearPaymentFeeComponent } from './pages/clear-student-payment/clear-payment-fee/clear-payment-fee.component';
import { AddPeriodFeeComponent } from './pages/student/add-period-fee/add-period-fee.component';
import { StudentFeeWithPeriodComponent } from './pages/reports/student-fee-with-period/student-fee-with-period.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';

import * as firebase from 'firebase/app';
import { AddLunchFeeComponent } from './pages/student/add-lunch-fee/add-lunch-fee.component';
import { StudentRetakeTestComponent } from './pages/student-retake-test/student-retake-test.component';
import { AddStudentRetakeTestComponent } from './pages/student-retake-test/add-student-retake-test/add-student-retake-test.component';
import { AddMatchTermSchoolFeeComponent } from './pages/student/student-cut-payment/add-match-term-school-fee/add-match-term-school-fee.component';
import { PaidWithTextRefPipe, PaidWithTextShortRefPipe } from './pipes/paid-with-text.pipe';
import { DisplayInvoiceTermRefPipe, DisplayInvoiceQtyRefPipe } from './pipes/display-invoice';
import { AddPaymentCrossYearComponent } from './pages/student/student-cut-payment/add-payment-cross-year/add-payment-cross-year.component';
import { AddDeductSchoolFeeComponent } from './components/add-deduct-school-fee/add-deduct-school-fee.component';
import { AddInstallmentSchoolFeeComponent } from './components/add-installment-school-fee/add-installment-school-fee.component';
import { RequestLessPaymentComponent } from './pages/reports/request-less-payment/request-less-payment.component';
import { RequestInstallmentPaymentComponent } from './pages/reports/request-installment-payment/request-installment-payment.component';
import { MonthsSchedulePipe, KhmerNumberPipe } from './pipes/month-schedule.pipe';
import { ReportInstallmentPaymentComponent } from './pages/reports/report-installment-payment/report-installment-payment.component';
import { ReportInstallmentNextPaymentComponent } from './pages/reports/report-installment-next-payment/report-installment-next-payment.component';
import { AddInstallmentPaymentComponent } from './components/add-installment-payment/add-installment-payment.component';
import { ReportArComponent } from './pages/reports/report-ar/report-ar.component';
firebase.initializeApp(environment.firebase);

@NgModule({
  declarations: [
    GetCampusPipe,
    AppComponent,
    DashboardComponent,
    HomeComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    LayoutComponent,
    AuthLayoutComponent,
    SigninComponent,
    AppLayoutComponent,
    SpinnerComponent,
    MessageComponent,
    NetInfoComponent,
    AdmissionComponent,
    HeaderNavComponent,
    FocusSearchDirective,
    ReceivePaymentComponent,
    CashInComponent,
    InvoiceTemplateComponent,
    ShiftOpenComponent,
    ShiftCloseComponent,
    HeaderTabsComponent,
    AppTabsRouteComponent,
    EmptyDataComponent,
    InstitutesAndCentersComponent,
    InstituteFeeComponent,
    AcademicProgramsComponent,
    DeleteComponent,
    AddAcademicFeeComponent,
    OptionsComponent,
    AddInstiuteFeeComponent,
    CashierShiftComponent,
    CashierShiftClosedComponent,
    StudentComponent,
    OtherFeeComponent,
    AddReceivePaymentComponent,
    ListingReportComponent,
    SummaryReportComponent,
    LoginShellComponent,
    ListingReportDailyComponent,
    AddNewTestingFormComponent,
    EditNewTestingFormComponent,
    TestingComponent,
    PaginationLoaderComponent,
    TestingFeeComponent,
    ReceiveTestingFeeComponent,
    RegisterTestingFormComponent,
    EmptyPageComponent,
    MiscellaneousFeeComponent,
    MiscellaneousFeePucStudentComponent,
    MiscellaneousFeeNonePucStudentComponent,
    MiscellaneousFeeListComponent,
    StudentInfoComponent,
    AddReceivePaymentMiscellaneousComponent,
    NavHeaderComponent,
    StudentPaymentComponent,
    StudentAbcComponent,
    StudentPreviewComponent,
    AddSchoolPaymentComponent,
    PrimaryButtonComponent,
    InvoicePaymentComponent,
    AddNewTestingComponent,
    ProgramComponent,
    AddProgramFeeComponent,
    AddTestingPaymentComponent,
    NotFoundComponent,
    AddTrainingFeeComponent,
    MiscellanousFeeFormComponent,
    StudentPayTestingComponent,
    TestingCardComponent,
    PucButtonComponent,
    AddDiscountInvoiceComponent,
    AddMiscellaneousInvoiceComponent,
    StudentReceiptComponent,
    PettyCashComponent,
    AddPettyCashComponent,
    ReportSummeryComponent,
    AllProgramSummeryComponent,
    SumReportPipe,
    SumArrayPipe,
    SumDiscountArrayPipe,
    SumScholarshipArrayPipe,
    SearchInvoiceNoComponent,
    InvoiceDialogComponent,
    ShiftReportComponent,
    ShiftReportListingComponent,
    ReportsComponent,
    PaymentListingComponent,
    FilterInvoiceProgramPipe,
    ReceivePaymentByProgramComponent,
    InstituteAndCenterComponent,
    StudentInstallmentComponent,
    SumTuitionFeePipe,
    DailyShiftReceivePaymentComponent,
    DailyShiftPaymentListingComponent,
    DailyShiftListingsComponent,
    PettyCashListingsComponent,
    InstallmentListingsComponent,
    ReceiptListingsComponent,
    MediumDatePipe,
    ReportReceiptDialogComponent,
    ReportReceiptSummaryDialogComponent,
    ReportShiftSummaryDialogComponent,
    SumTotalInvoicePipe,
    ForgotPasswordComponent,
    UnpaidPaymentComponent,
    AddUnpaidPaymentComponent,
    AddUnpaidAcademicComponent,
    EditAcademicFeeComponent,
    DateTimePipe,
    DateOnlyPipe,
    AddTestingExistStudentComponent,
    StudentResolveFeeComponent,
    DaysSchedulePipe,
    NewInvoiceComponent,
    ConfirmSuccessComponent,
    NewInvoiceRouterComponent,
    StudentPenaltyComponent,
    AddPrepaidStudentComponent,
    AddDiscountComponent,
    EditEnglishScheduleComponent,
    ProgramShiftStudentComponent,
    ResolveEnglishFeeComponent,
    AddEnglishAdmissionComponent,
    ResolveEnglishScholarshipComponent,
    DateMediumPipe,
    EditEnglishPaymentComponent,
    AcademicYearComponent,
    DateFormatPipe,
    RegistrationFormComponent,
    TrainingGradeComponent,
    TrainingGradeFeeComponent,
    AddTrainingGradeComponent,
    EditTrainingGradeComponent,
    TrainingGradePaymentOptionComponent,
    AddSchoolFeeComponent,
    OtherPaymentFeeComponent,
    AddNewOtherPaymentFeeComponent,
    EditOtherPaymentFeeComponent,
    OtherPaymentFeeDetailComponent,
    AddNewOtherPaymentFeeDetailComponent,
    EditOtherPaymentFeeDetailComponent,
    AddOtherFeeComponent,
    SumOtherFeePipe,
    TrainingCampusComponent,
    GroupByProgramPipe,
    DocRefDatePipe,
    DocRefPipe,
    RegistrationFormRetakeTestComponent,
    StudentAdmissionComponent,
    PersonalComponent,
    ParentContactComponent,
    GetSchoolConfigPipe,
    NewInvoicePosComponent,
    AddStudentPaymentComponent,
    SetAdmissionComponent,
    StudentCutPaymentComponent,
    AddStudentCutPaymentComponent,
    MatchTermListingComponent,
    AddStudentPenaltyComponent,
    EditPettyCashComponent,
    ConfirmDeleteComponent,
    StudentScholarshipComponent,
    StudentScholarshipCardComponent,
    PreviewImageComponent,
    FileSizePipe,
    UnpaidListingComponent,
    CashierVoidReceiptComponent,
    RequestPenaltyListingComponent,
    RequestVoidReceiptListingComponent,
    AddStudentDiscountVoucherComponent,
    AddStudentOldVoucherComponent,
    AddCapitalFeeComponent,
    ClearStudentPaymentComponent,
    StudentUnpaidPaymentListingComponent,
    EmptyComponent,
    ClearPaymentFeeComponent,
    AddPeriodFeeComponent,
    StudentFeeWithPeriodComponent,
    ChangePasswordComponent,
    AddLunchFeeComponent,
    StudentRetakeTestComponent,
    AddStudentRetakeTestComponent,
    AddMatchTermSchoolFeeComponent,
    PaidWithTextRefPipe,
    PaidWithTextShortRefPipe,
    DisplayInvoiceTermRefPipe,
    DisplayInvoiceQtyRefPipe,
    AddPaymentCrossYearComponent,
    AddDeductSchoolFeeComponent,
    AddInstallmentSchoolFeeComponent,
    RequestLessPaymentComponent,
    RequestInstallmentPaymentComponent,
    MonthsSchedulePipe,
    KhmerNumberPipe,
    ReportInstallmentPaymentComponent,
    ReportInstallmentNextPaymentComponent,
    AddInstallmentPaymentComponent,
    ReportArComponent,
    SumTuitionFeeOtherPipe,
  ],
  entryComponents: [
    ChangePasswordComponent,
    PreviewImageComponent,
    ShiftOpenComponent,
    ShiftCloseComponent,
    DeleteComponent,
    AddAcademicFeeComponent,
    AddReceivePaymentComponent,
    APP_ENTRY,
    AddNewTestingFormComponent,
    EditNewTestingFormComponent,
    AddReceivePaymentMiscellaneousComponent,
    StudentAbcComponent,
    AddSchoolPaymentComponent,
    AddTestingPaymentComponent,
    AddDiscountInvoiceComponent,
    AddMiscellaneousInvoiceComponent,
    InvoiceDialogComponent,
    StudentInstallmentComponent,
    ReportReceiptDialogComponent,
    ReportReceiptSummaryDialogComponent,
    ReportShiftSummaryDialogComponent,
    EditAcademicFeeComponent,
    StudentResolveFeeComponent,
    AddPrepaidStudentComponent,
    AddDiscountComponent,
    EditEnglishScheduleComponent,
    ProgramShiftStudentComponent,
    ResolveEnglishFeeComponent,
    AddEnglishAdmissionComponent,
    ResolveEnglishScholarshipComponent,
    EditEnglishPaymentComponent,
    AddTrainingGradeComponent,
    EditTrainingGradeComponent,
    TrainingGradeFeeComponent,
    TrainingGradePaymentOptionComponent,
    AddSchoolFeeComponent,
    AddNewOtherPaymentFeeComponent,
    EditOtherPaymentFeeComponent,
    AddNewOtherPaymentFeeDetailComponent,
    EditOtherPaymentFeeDetailComponent,
    AddOtherFeeComponent,
    NewInvoicePosComponent,
    AddStudentPaymentComponent,
    AddStudentPenaltyComponent,
    EditPettyCashComponent,
    AddStudentDiscountVoucherComponent,
    AddStudentOldVoucherComponent,
    AddCapitalFeeComponent,
    StudentUnpaidPaymentListingComponent,
    ClearPaymentFeeComponent,
    AddLunchFeeComponent,
    AddStudentRetakeTestComponent,
    AddMatchTermSchoolFeeComponent,
    AddDeductSchoolFeeComponent,
    AddInstallmentSchoolFeeComponent,
    AddInstallmentPaymentComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    MobxAngularModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    NgbModule.forRoot(),
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    MaterialModule,
    InfiniteScrollModule
  ],
  providers: [AuthService, FireValidatorsService, DataService, ConvertService, APP_STORES, PrintService, FireStoreService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
