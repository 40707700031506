import { PrintService } from "./../../services/print.service";
import { Router, ActivatedRoute } from "@angular/router";
import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  AfterViewInit
} from "@angular/core";
import { slideInOutAnimation } from "../../services/slide-io.animation";
import {
  FormGroup,
  AbstractControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Payment } from "../../stores/payment.store";
import { Environment } from "src/app/stores/environment.store";
import { MatDialog, MatSnackBar } from "@angular/material";
import { AddReceivePaymentComponent } from "src/app/components/add-receive-payment/add-receive-payment.component";
import { ITesting } from "src/app/interfaces/testing";
import { TestingStatus, TestFeeStatus } from "src/app/dummy/status";
import { ConvertService } from "src/app/services/convert.service";
import { Testing } from "src/app/stores/testing.store";

@Component({
  animations: [slideInOutAnimation],
  selector: "app-cash-in",
  templateUrl: "./cash-in.component.html",
  styleUrls: ["./cash-in.component.scss"],
  host: { "[@slideInOutAnimation]": "" }
})
export class CashInComponent implements OnInit, AfterViewInit {
  @ViewChild("focusInput")
  inputEl: ElementRef;

  form: FormGroup;
  search: AbstractControl;
  formFocus: boolean = false;
  id: string;
  status: string;
  field: string = "puc_id";
  placeholder = "Search";
  name = "Received Payment";

  testingForm: FormGroup;
  first_name: AbstractControl;
  last_name: AbstractControl;
  dob: AbstractControl;
  mobile_phone: AbstractControl;
  institute: AbstractControl;

  instituteLists: any;
  testData: any;
  isClickTest: boolean =  false;

  discount: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public store: Payment,
    public env: Environment,
    public dialog: MatDialog,
    private ps: PrintService,
    private snackBar: MatSnackBar,
    public testing: Testing
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      search: [null, Validators.required]
    });
    this.search = this.form.controls["search"];

    this.testingForm = this.fb.group({
      first_name: [null, Validators.required],
      last_name: [null, Validators.required],
      institute: [null, Validators.required],
      dob: [new Date()],
      mobile_phone: [null]
    });
    this.first_name = this.testingForm.controls["first_name"];
    this.last_name = this.testingForm.controls["last_name"];
    this.dob = this.testingForm.controls["dob"];
    this.mobile_phone = this.testingForm.controls["mobile_phone"];
    this.institute = this.testingForm.controls["institute"];

    this.testing.fetchInstitute(res=> {
      this.instituteLists = res;
      this.institute.patchValue(this.instituteLists[0]);
    })  

    this.route.params.forEach(params => {
      this.status = params["id"];
      switch (this.status) {
        case "testing-fees":
          this.placeholder = "Search Serial Number";
          this.name = "Received Testing Payment";
          break;
        case "abc-course":
          this.placeholder = "Search ID";
          this.name = "Academic Bridging Course Payment";
          break;
        case "tuition-fees":
          this.placeholder = "Search ID";
          this.name = "Received School Fee";
          break;
        default:
          // this.router.navigate(["/not-found/404"]);
          break;
      }
      const id = params["sid"];
      this.id = id === "0" ? null : id;
      if (this.id) {
        this.store.fetchInvoice(this.id, this.status);
        // this.store.getStudentScholarship(this.id, (res)=> {
        //   this.discount = res;
        // });
      } else {
        this.store.header = null;
        this.store.data = null;
      }
      this.form.patchValue({
        search: this.id
      });
    });
  }
  ngAfterViewInit() {}

  _goBack() {
    this.router.navigate(["/"]);
  }
  _onFocus(yes) {
    this.formFocus = yes;
  }

  _onSearch(f: any) {
    if (this.form.valid) {
      this.id = f.search;
      if (this.id) {
        // this.store.getStudentScholarship(this.id, (res)=> {
        //   this.discount = res;
        //   this.store.fetchInvoice(this.id, this.status);
        // });
      }
      this.form.patchValue({
        search: this.id
      });
    }
  }

  compareByKey(f1: any, f2: any) {
    return f1 && f2 && f1.key === f2.key;
  }

  _onReceivePayment() {
    if (this.store.header === null) return;
    let dialogRef = this.dialog.open(AddReceivePaymentComponent, {
      data: {
        header: this.store.header,
        discount: this.store.studentScholarship,
        status: this.status
      },
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: true,
      role: "dialog"
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => {
      if (result === "print") {
        this.print();
      }
    });
  }

  print() {
    this.ps.print("#print", "a5l");
  }

  isAddTest(status: boolean){
    this.isClickTest = status;
  }


  create(f: any) {
    if (this.testingForm.valid) {
      const formData: ITesting = {
        key: null,
        first_name: f.first_name,
        last_name: f.last_name,
        dob: f.dob,
        isAssignProgram: false,
        mobile_phone: f.mobile_phone,
        isPaid: TestFeeStatus.unpaid,
        institute: f.institute,
        isPaidTest: false,
        status: TestingStatus.active,
        create_date: new Date(),
        create_date_key: ConvertService.dateKey(),
        create_by: this.env.user,
        page_key: ConvertService.pageKey()
      };
      this.testing.addTest(
        formData,
        this.env.config,
        this.env.user,
        (success, res) => {
          if (success) {
            this.isClickTest = false;
            this.store.fetchInvoice(res.serial_id, this.status);
            this.snackBar.open("Testing has been created.", "done", {
              duration: 2000
            });
            this.form.reset();
          } else {
            this.snackBar.open(res, "Error");
          }
        }
      );
    }
  }
}
