import { Pipe, PipeTransform } from '@angular/core';
import { SCHOOL } from '../dummy/config';

@Pipe({
  name: 'getSchoolConfig'
})
export class GetSchoolConfigPipe implements PipeTransform {

  transform(value: string, field: string): any {
    return SCHOOL[value];
  }

}
