import { MappingService } from './../../services/mapping.service';
import { ActivatedRoute } from '@angular/router';
import { Environment } from './../../stores/environment.store';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AbstractControl } from '@angular/forms';
import { Report } from './../../stores/report.store';
import { Component, OnInit } from '@angular/core';
import { tabs } from 'src/app/dummy/tabs';
import { PrintService } from 'src/app/services/print.service';
import { SCHOOL } from 'src/app/dummy/config';

@Component({
  selector: 'app-listing-report-daily',
  templateUrl: './listing-report-daily.component.html',
  styleUrls: ['./listing-report-daily.component.scss']
})
export class ListingReportDailyComponent implements OnInit {
  school=SCHOOL

  tabs = tabs.reportFilterBy;
  form: FormGroup;
  program: AbstractControl;
  isAll: AbstractControl;
  institute: AbstractControl;
  term: AbstractControl;
  isTerm: AbstractControl;
  fromDate: AbstractControl;
  status: AbstractControl;
  toDate: AbstractControl;

  filteredStates: any;
  filteredProgramStates: any;
  filteredTermStates: any;
  filterStatus = tabs.panel;
  selectedTerm: any;

  process = false;
  type: string;
  isCheckTerm = false;

  constructor(
    private fb: FormBuilder,
    private ps: PrintService,
    public report: Report,
    public env: Environment,
    private route: ActivatedRoute,
    public store: Report
  ) { }

  print() {
    this.ps.print("#print", "a4");
  }

  ngOnInit() {

    this.env.getUser(user => {
      if (user && user.shift)
        this.store.fetchReceiptByTerm(user.shift.key);
    })

    this.form = this.fb.group({
      program: [
        { value: null, disabled: true },
        Validators.compose([Validators.required])
      ],
      institute: [null, Validators.compose([Validators.required])],
      term: [null, Validators.compose([Validators.required])],
      isTerm: [this.isCheckTerm, Validators.required],
      isAll: [true, Validators.required],
      fromDate: [null, Validators.required],
      toDate: [null, Validators.required],
      status: [this.filterStatus[0], Validators.required]
    });
    this.program = this.form.controls["program"];
    this.institute = this.form.controls["institute"];
    this.term = this.form.controls["term"];
    this.isTerm = this.form.controls["isTerm"];
    this.isAll = this.form.controls["isAll"];
    this.fromDate = this.form.controls["fromDate"];
    this.toDate = this.form.controls["toDate"];
    this.status = this.form.controls["status"];

    this.route.params.forEach(param => {
      this.type = param["id"];
      this.form.patchValue({
        fromDate: MappingService.fromDateFilter(this.type),
        toDate: MappingService.toDateFilter(this.type)
      });
      if (this.type === "custom") {
        this.fromDate.enable();
        this.toDate.enable();
      } else {
        this.fromDate.disable();
        this.toDate.disable();
      }

    });


  }

  _onSelectedInstitute(event) {
    const { key } = event.value;

  }

  _onChange(event) {
    const { checked } = event;
    if (checked) {
      this.program.disable();
    } else {
      this.program.enable();
    }
  }

  _onCheckTerm(event) {
    const { checked } = event;
    this.isCheckTerm = checked;
    if (!this.isCheckTerm) {
      this.term.disable();

    } else {
      this.term.enable();
      this.form.patchValue({
        fromDate: this.selectedTerm.startterm.toDate(),
        toDate: this.selectedTerm.endterm.toDate(),
      });
    }
  }

  _onPress(f: any) {

  }
}
