import { Genders, paymentStatus, recordStatus, SUBJECT_DATA } from '../../dummy/status';
import { ConvertService } from './../../services/convert.service';
import { ITesting } from './../../interfaces/testing';
import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import {
  FormGroup,
  AbstractControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { slideInOutAnimation } from "../../services/slide-io.animation";
import { Environment } from "../../stores/environment.store";
import { MappingService, academicYearObj, gradeObj, campusObj, programObj, schoolObj, ageFromDateOfBirthday, createUniqueKey } from "../../services/mapping.service";
import { MatSnackBar, MatDialog } from "@angular/material";
import { BaseStore } from 'src/app/stores/base.store';
import { Registration } from 'src/app/stores/registration.store';
import { InvoiceDialogComponent } from 'src/app/components/invoice-dialog/invoice-dialog.component';
import { AddTestingPaymentComponent } from 'src/app/components/add-testing-payment/add-testing-payment.component';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.scss'],
  host: { "[@slideInOutAnimation]": "" },
  animations: [slideInOutAnimation],
})
export class RegistrationFormComponent implements OnInit {
  @ViewChild("focusInput") inputEl: ElementRef;
  form: FormGroup;
  unique_code: AbstractControl;
  first_name: AbstractControl;
  last_name: AbstractControl;
  khmer_first_name: AbstractControl;
  khmer_last_name: AbstractControl;
  address: AbstractControl;
  gender: AbstractControl;
  dob: AbstractControl;
  mobile_phone: AbstractControl;
  email_address: AbstractControl;
  note: AbstractControl;
  admission_date: AbstractControl;
  year: AbstractControl;
  grade: AbstractControl;
  program: AbstractControl;
  campus: AbstractControl;
  shift: AbstractControl;

  genderList = Genders;
  yearList = [];
  gradeList = [];
  campusList = [];
  registrationPrice: number = 0;
  config: any = null;

  gradeAllList = [];
  programList = [];

  filteredProgramStates: any;
  filteredGradeStates: any;
  filteredYearStates: any;
  filteredShiftStates: any;

  process: boolean = true;

  subjectType = null
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    public env: Environment,
    public dialog: MatDialog,
    public base: BaseStore,
    public store: Registration,
    private ds: DataService
  ) { }

  async ngOnInit() {
    this.buildForm();

    await this.env.fetchUserDoc()
    await this.env.fetchConfigDoc()
    const { selectedCampus, selectedSchool } = this.env

    await this.env.fetchEnvDoc(selectedSchool.key)

    this.yearList = await this.base.fetchList(this.ds.storeDocRef(selectedSchool.key).collection("academic_year", ref => ref.orderBy("startDate", "desc")));

    const shiftData = await this.base.fetchShift();
    this.filteredShiftStates = MappingService.autoComplete(this.shift, shiftData, "name");
    let programData = await this.base.fetchTrainingProgram(selectedCampus.key);
    this.filteredProgramStates = MappingService.autoComplete(this.program, programData, "name");

    const { config } = this.env;
    this.registrationPrice = config.registrationFee.price;

    this.process = false;
    this.form.patchValue({
      shift: shiftData[0],
      gender: this.genderList[0]
    })

    this.grade.disable()
    this.year.disable()
  }

  compareByKey(f1: any, f2: any) {
    return f1 && f2 && f1.key === f2.key;
  }

  displayItem(item: any): string {
    return item ? item.name : item;
  }

  displayItemGrade(item: any): string {
    return item ? item.grade.name : item;
  }

  async onSelectProgram(event) {
    this.grade.disable()
    const { value } = event.option;
    const { programOption } = value
    const { config } = this.env;
    const { test_KGE, test_IEP } = config;
    const { selectedCampus } = this.env

    this.grade.patchValue(null);
    this.year.patchValue(null);

    if (value.key === test_KGE.key || value.key == test_IEP.key) {
      this.subjectType = SUBJECT_DATA.khmer;
    }

    if (value && selectedCampus) {
      const gradeData = await this.base.fetchTrainingLevel(selectedCampus.key, value.key);
      this.filteredGradeStates = MappingService.autoComplete(this.grade, gradeData, "grade.name");

      const yearData = this.yearList.filter(m => m.termType.key === value.programOption.key)
      this.filteredYearStates = MappingService.autoComplete(this.year, yearData, "name");

      const { academicEnv } = this.env
      const academicTerm = programOption.key == 1 ? academicEnv.year : academicEnv.term
      const academicTermDoc = yearData.find((m: any) => m.key === academicTerm.key)

      this.grade.enable();
      this.year.enable();
      this.year.patchValue(academicTermDoc || null)

    }
  }

  buildForm(): void {
    this.form = this.fb.group({
      unique_code: [null],
      first_name: [null, [Validators.required]],
      last_name: [null, [Validators.required]],
      khmer_first_name: [null],
      khmer_last_name: [null],
      gender: [null, [Validators.required]],
      dob: [MappingService.age18(), [Validators.required]],
      mobile_phone: [null, [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(9), Validators.maxLength(10)]],
      email_address: [null],
      note: [null],
      address: [null],
      year: [null, [Validators.required]],
      grade: [null, Validators.compose([Validators.required, MappingService.validSelected.bind(this)])],
      program: [null, [Validators.required]],
      admission_date: [new Date(), [Validators.required]],
      campus: [null],
      shift: [null, Validators.required],
    });
    this.unique_code = this.form.controls["unique_code"];
    this.first_name = this.form.controls["first_name"];
    this.last_name = this.form.controls["last_name"];
    this.khmer_first_name = this.form.controls["khmer_first_name"];
    this.khmer_last_name = this.form.controls["khmer_last_name"];
    this.gender = this.form.controls["gender"];
    this.dob = this.form.controls["dob"];
    this.mobile_phone = this.form.controls["mobile_phone"];
    this.email_address = this.form.controls["email_address"];
    this.note = this.form.controls["note"];
    this.address = this.form.controls["address"];
    this.year = this.form.controls["year"];
    this.grade = this.form.controls["grade"];
    this.program = this.form.controls["program"];
    this.admission_date = this.form.controls['admission_date'];
    this.campus = this.form.controls['campus'];
    this.shift = this.form.controls['shift'];
  }

  _goBack() {
    this.router.navigate(['/']);
  }

  create(f: any) {
    if (this.form.valid && this.env.user && this.env.config && this.registrationPrice > 0) {
      const { selectedCampus, selectedSchool } = this.env

      const birthDateKey = ConvertService.toDateKey(f.dob)
      const uniqueKey = createUniqueKey(f.first_name, f.last_name, birthDateKey, f.gender.text || "");

      const form: ITesting = {
        key: null,
        unique_code: uniqueKey,
        serial_id: null,
        first_name: f.first_name ? f.first_name.toUpperCase() : null,
        last_name: f.last_name ? f.last_name.toUpperCase() : null,
        full_name: f.first_name ? f.first_name.toUpperCase() : null + f.last_name ? f.last_name.toUpperCase() : null,
        khmer_first_name: f.khmer_first_name ? f.khmer_first_name.toUpperCase() : null,
        khmer_last_name: f.khmer_last_name ? f.khmer_last_name.toUpperCase() : null,
        address: f.address,
        gender: f.gender,
        dob: f.dob,
        dobKey: ConvertService.toDateKey(f.dob),
        age: ConvertService.getAge(f.dob),
        puc_id: null,
        page_key: ConvertService.pageKey(),
        mobile_phone: f.mobile_phone,
        email_address: f.email_address,
        admission_date: f.admission_date,
        admission_date_key: ConvertService.toDateKey(f.admission_date),
        note: f.note,
        status: recordStatus.active,
        isPaid: paymentStatus.unpaid,
        isPaidTest: false,
        create_date: new Date(),
        create_date_key: ConvertService.dateKey(),
        create_by: MappingService.userObj(this.env.user),
        branch: MappingService.campusObj(this.env.user.campus),
        admission_term: academicYearObj(f.year),
        admission: null,
        config: null,
        expired_fee: null,
        expired_fee_key: null,
        expired_test: null,
        expired_test_key: null,
        testing_result: null,

        isGrading: true,
        interview: false,

        isReTakeTest: false,
        onlineTesting: false,
        program: programObj(f.program),
        year: academicYearObj(f.year),
        grade: gradeObj(f.grade),
        amount: this.registrationPrice,
        price: this.registrationPrice,
        shift: {
          key: f.shift.key,
          name: f.shift.name,
        },

        used: false,
        isTakeTest: false,
        noTakeTest: ageFromDateOfBirthday(f.dob) < 6 ? true : false,
        programLevel: f.grade,
        subject_type: this.subjectType,
        gradeKey: f.grade.gradeKey,

        campusKey: selectedCampus.key,
        campus: campusObj(selectedCampus),
        schoolKey: selectedSchool.key,
        school: schoolObj(selectedSchool),
      }

      this.store.register(form, this.env.user, this.registrationPrice, false, null, (success, res) => {
        if (success) {
          const { year, shift, gender } = form;
          this.form.reset();
          this.form.patchValue({
            year: year,
            program: null,
            grade: null,
            admission_date: new Date(),
            dob: MappingService.age18(),
            gender: gender,
            shift: shift
          })

          const { config } = this.env;
          this.registrationPrice = config.registrationFee.price;

          this.snackBar.open(
            "Registration form has been created successfully.",
            "done",
            { duration: 2000 }
          );
          this._onReceivePayment(res);
          this.form.enable();

        } else {
          this.snackBar.open(
            res,
            "Error"
          );
          this.form.enable();
        }
      });

    }
  }

  _onPrint(item) {
    let dialogRef = this.dialog.open(InvoiceDialogComponent, {
      data: { studentKey: item.student.key, headerKey: item.headerRef },
      width: '90vw',
      height: '80vh',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  _onReceivePayment(item) {
    if (item.isPaidTest) return;
    let dialogRef = this.dialog.open(AddTestingPaymentComponent, {
      data: item,
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: true,
      role: "dialog"
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== "no") {
        this._onPrint(result)
      }
    });
  }
}
