// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBc0LAFBff2DSHvlVOGDrklcSOiyB0fIwY",
    authDomain: "ngs-preakleap.firebaseapp.com",
    projectId: "ngs-preakleap",
    databaseURL: "https://ngs-preakleap-default-rtdb.asia-southeast1.firebasedatabase.app/",
    storageBucket: "ngs-preakleap.appspot.com",
    messagingSenderId: "509897641010",
    appId: "1:509897641010:web:a557c3a847ddfdb14ac02b",
    measurementId: "G-W7KK849KYJ"

    // apiKey: "AIzaSyB6aJQ0p_fnkle1UKlK_JOiUZCseMRyxEM",
    // authDomain: "ngscloudv3.firebaseapp.com",
    // databaseURL: "https://ngscloudv3-default-rtdb.firebaseio.com",
    // projectId: "ngscloudv3",
    // storageBucket: "ngscloudv3.appspot.com",
    // messagingSenderId: "759124229487",
    // appId: "1:759124229487:web:3de8b197e919e20c43fc9a",
    // measurementId: "G-DL2F6H6DTD"
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
