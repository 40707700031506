import { AddAcademicFeeComponent } from './../../components/add-academic-fee/add-academic-fee.component';
import { DeleteComponent } from './../../components/delete/delete.component';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { Academic } from './../../stores/academic.store';
import { programTypesList } from '../../dummy/status';
import { tabs } from './../../dummy/tabs';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EditAcademicFeeComponent } from 'src/app/components/edit-academic-fee/edit-academic-fee.component';

@Component({
  selector: 'app-academic-programs',
  templateUrl: './academic-programs.component.html',
  styleUrls: ['./academic-programs.component.scss']
})
export class AcademicProgramsComponent implements OnInit {
  tabs = tabs.academics;
  type = "all";
  showInvalid = new FormControl();
  programType: any = programTypesList[0];
  programTypes = programTypesList
  constructor(
    public store: Academic,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.route.params.forEach(params => {
      this.type = params["id"];
      this.store.fetchData(this.type);
    });
  }

  create() {
    let dialogRef = this.dialog.open(AddAcademicFeeComponent, {
      data: this.type,
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: true,
      role: "dialog"
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => { });
  }

  update(item) {
    let dialogRef = this.dialog.open(EditAcademicFeeComponent, {
      data: item,
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: true,
      role: "dialog"
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => { });
  }

  delete(item: any) {
    let dialogRef = this.dialog.open(DeleteComponent, {
      data: {
        title: "Delete Academic Tuition Fee",
        memo:
          "If this academic tuition fee is using in other list you cannot delete tuition fee.",
        name: item.code
      },
      width: "35vw",
      disableClose: true,
      role: "dialog"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === "yes") {
        this.store.delete(item, (success, error) => {
          if (success) {
            this.snackBar.open("Academic Tuition fee has been deleted.", "done", {
              duration: 2000
            });
          } else {
            this.snackBar.open(error, "Error");
          }
        });
      }
    });
  }
}
