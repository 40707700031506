import { Environment } from 'src/app/stores/environment.store';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { tabs } from 'src/app/dummy/tabs';
import { User } from 'src/app/stores/user.store';

@Component({
  selector: 'app-report-summery',
  templateUrl: './report-summery.component.html',
  styleUrls: ['./report-summery.component.scss']
})
export class ReportSummeryComponent implements OnInit {
  tabs=tabs.summery
  
  constructor(
    private router: Router,
    public store:User,
    public env:Environment
  ) { }

  ngOnInit() {}
 
  _goBack() {
    this.router.navigate(['/']);
  }


}

