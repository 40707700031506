import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Student } from 'src/app/stores/student.store';
import { Payment } from 'src/app/stores/payment.store';
import { Environment } from 'src/app/stores/environment.store';
import { PrintService } from 'src/app/services/print.service';
import { ConvertService } from 'src/app/services/convert.service';

@Component({
  selector: 'app-student-preview',
  templateUrl: './student-preview.component.html',
  styleUrls: ['./student-preview.component.scss']
})
export class StudentPreviewComponent implements OnInit {
  studentKey = null;
  admissionKey = null;
  discount = null;
  constructor(private route: ActivatedRoute,
    public store: Student,
    public payment: Payment,
    public env: Environment,
    private ps: PrintService,
  ) { }

  ngOnInit() {
    this.route.parent.params.forEach(params => {
      this.studentKey = ConvertService.toNull(params["id"]);
      this.admissionKey=ConvertService.toNull(params["admissionKey"])
      const url=this.route.snapshot.url;
      this.store.payPageKey = url.length>1?url[1].path:null;
      this.store.fetchStudent(this.studentKey,this.admissionKey, (stu) => {
        this.payment.fetchInvoiceByStudentKey(this.studentKey, res => {})
      });
    });
  }

  print() {
    this.ps.print("#printD", "a5l");
  }

}
