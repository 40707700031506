import { IEnrollment } from './../interfaces/enrollment';
import { enrollPrograms, paymentStatus, EnrollStatus, enrollmentTypes, DISCOUNT_TYPES, REQUEST_STATUS, DISCOUNT_POLICY_STUDENT_OBJ, PROGRAM_TERMS_OBJ, PROGRAM_PAYMENT, INSTALLMENT_PAYMENT_STATUS } from '../dummy/status';
import { IReceivedPayment } from "./../interfaces/receivedPayment";
import { DataService } from "./../services/data.service";
import { observable, action, toJS } from "mobx";
import { Injectable } from "@angular/core";
import { ConvertService } from "../services/convert.service";
import { IFeeWithPeriod, IInvoice } from "../interfaces/invoice";
import { TestFeeStatus, paymentType, invoiceTypesObj, recordStatus, RegistrarStatus } from "../dummy/status";
import { AdministratorService } from "../services/administrator.service";
import { IRegistrar } from "../interfaces/registrar";
import { MappingService, pushToObject, userObj, studentObj, academicYearObj, toNumber, toNull, pushObjArray } from "../services/mapping.service";
import { Student_PUC_Profile, IStudentAccount } from "../interfaces/student";
import { IAbcStudent } from '../interfaces/abc-student';
import { Environment } from './environment.store';
import { IInstallment } from '../interfaces/installment';
import { IPrepaid } from '../interfaces/prepaid';
import * as firebase from 'firebase/app';
import { yearsPerPage } from '@angular/material/datepicker/typings/multi-year-view';

@Injectable()
export class Payment {
  @observable public invoice = null;
  @observable public allInvoices = null;
  @observable public unpaidInvoices = null;
  @observable public invoiceHeader = null;
  @observable public receiptHeader = null;
  @observable public totalTuitionFee = null;
  @observable public tuitionFees = [];
  @observable public totalTestingFee = null;
  @observable public totalSubject = null;
  @observable public otherFee = null;
  @observable public inventoryFee = null;
  @observable public capitalFee = null;
  @observable public feeWithPeriod = null;
  @observable public invoiceKey = null;

  @observable public student = null;
  @observable public filterType = null;
  @observable public data = null;

  @observable public loading = false;
  @observable public process = false;
  @observable public empty = false;

  @observable public header = null;
  @observable public detail = null;
  @observable public totalPrice = null;
  @observable public totalMiscellaneous = null;
  @observable public totalOtherFee = null;
  @observable public totalPOSFee = null;
  @observable public totalScholarshipPenalty = null;
  @observable public gradePrice = null;
  @observable public subtotal = null;
  @observable public enroll = null;
  @observable public enrollIncluded = null;
  @observable public enrollExcluded = null;

  @observable public studentScholarship = null;
  @observable public totalScholarship = null;
  @observable public scholarship = null;
  @observable public totalLoan = null;
  @observable public totalPaymentDiscount = null;
  @observable public paymentDiscountType = null;
  @observable public totalPrepaid = null;
  @observable public prepaidRef = null;
  @observable public totalInstallment = null;
  @observable public remainingInstallment = null;
  @observable public totalPenalty = null;
  @observable public totalDiscount = null;
  @observable public penaltyRef = null;
  @observable public grandTotal = null;
  @observable public totalLate = null
  @observable public draft = null;
  @observable public scholarshipPenalty = null;
  @observable public miscellaneousFee = null;
  @observable public otherFees = null;
  @observable public activeTerm = null;
  @observable public scholarshipRef = null;
  @observable public installmentRef = null;
  @observable public installment = null;
  @observable public totalDraft = [];
  @observable public totalScholarShipPenalty = [];
  @observable public paymentEmpty = false;
  @observable.shallow public paymentHeader = null;
  @observable public paymentDetail = null;
  @observable public paymentTesting = null;
  @observable public paymentTuitionFee = null;
  @observable public paymentScholarshipPenalty = null;
  @observable public paymentMiscellaneous = null;
  @observable public paymentOtherFee = null;
  @observable public paymentPOSFee = null;

  @observable public paymentCapitalFee = null;
  @observable public totalCapitalFee = null;

  @observable public paymentFeeWithPeriod = null;
  @observable public totalFeeWithPeriod = null;


  @observable public specialDiscount = null;
  @observable public studentTypeDiscount = null;
  @observable public globalDiscount = null;
  @observable public scholarshipDiscount = null;
  @observable public isShowScholarship = true;
  @observable public isHavePenalty = false;
  @observable.shallow public testing = null;

  @observable public studentInstallment = null;

  constructor(
    private ds: DataService,
    private as: AdministratorService,
    public env: Environment,
  ) { }

  @action
  async studentInvoiceHeader(item: any) {
    this.process = true;
    const { key, student } = item
    const studentInvoiceDoc = await this.ds.studentDocument(student.key).collection("invoices").doc(key).get().toPromise();
    this.process = false;
    return MappingService.pushToObject(studentInvoiceDoc)
  }

  @action
  removeFee(item: any, student: any, oldInvoice: any, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const studentInvoiceRef = this.ds.studentFireDocument(student.key).collection("invoices");
    if (this.paymentDetail.length === 1) {
      batch.delete(studentInvoiceRef.doc(oldInvoice.key));
    }
    else {

      batch.update(studentInvoiceRef.doc(oldInvoice.key), {
        price: oldInvoice.price - item.price,
        amount: oldInvoice.amount - item.amount,
      })
    }
    batch.delete(studentInvoiceRef.doc(item.key))
    batch.commit().then(() => {
      this.process = false;
      callback(true, null);
    }).catch(error => {
      this.process = false;
      callback(false, error);
    })
  }

  @action
  updateFee(item: any, student: any, oldInvoice: any, detail: Array<any>, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const studentInvoiceRef = this.ds.studentFireDocument(student.key).collection("invoices");

    const amount = MappingService.sum(detail, "amount")
    batch.update(studentInvoiceRef.doc(oldInvoice.key), {
      price: amount,
      amount: amount,
    })

    batch.commit().then(() => {
      this.process = false;
      callback(true, null);
    }).catch(error => {
      this.process = false;
      callback(false, error);
    })
  }

  @action
  removeMiscellaneous(item: any, student: any, oldInvoice: any, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const studentInvoiceRef = this.ds.studentFireDocument(student.key).collection("invoices");
    if (this.paymentDetail.length === 1) {
      batch.delete(studentInvoiceRef.doc(oldInvoice.key));
    }
    else {
      batch.update(studentInvoiceRef.doc(oldInvoice.key), {
        price: oldInvoice.price - item.price,
        amount: oldInvoice.amount - item.amount,
      })
    }

    batch.delete(studentInvoiceRef.doc(item.key))
    batch.commit().then(() => {
      this.process = false;
      callback(true, null);
    }).catch(error => {
      this.process = false;
      callback(false, error);
    })
  }

  @action
  resolveStudentFee(student: any, nationality: any, config: any, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const studentRef = this.ds.studentFireDocument(student.key);
    batch.update(studentRef, { nationality: nationality });

    let { uat_fee, foreigner_student } = config;
    this.paymentTuitionFee.forEach(invoice => {
      const isAESP = invoice.course.schedule_subject.subject.code.trim().startsWith("AESP");
      if (isAESP) {
        const detailAmount = uat_fee.price * invoice.course.schedule_subject.subject.credit;
        batch.update(studentRef.collection("invoices").doc(invoice.key), {
          amount: detailAmount,
          price: detailAmount,
        })
      }

      if (nationality.key === foreigner_student.key) {
        const detailAmount = invoice.course.fee.foreigner * invoice.course.schedule_subject.subject.credit;
        batch.update(studentRef.collection("invoices").doc(invoice.key), {
          amount: detailAmount,
          price: detailAmount,
        })
      }
    })

    batch.commit().then(() => {
      this.updateInvoiceHeader(student, (success, error) => {
        if (success) {
          this.process = false;
          callback(true, null);
        } else {
          this.process = false;
          callback(false, error);
        }
      })
    }).catch(error => {
      this.process = false;
      callback(false, error);
    });
  }

  @action
  updateInvoiceHeader(student: any, callback) {
    this.process = true;
    const amount = MappingService.sum(this.paymentTuitionFee, "amount");
    this.ds.studentDocument(student.key).collection("invoices").doc(this.paymentHeader.key).update({
      amount: amount,
      price: amount,
    }).then(() => {
      this.process = false;
      callback(true, null);
    }).catch(error => {
      this.process = false;
      callback(false, error);
    });

  }

  @action
  removePenaltyFee(penalty: number, student: any, oldInvoice: any, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const studentInvoiceRef = this.ds.studentFireDocument(student.key).collection("invoices");
    const penaltyAmount = ConvertService.toNumber(oldInvoice.penalty) - penalty;
    this.totalPenalty = penaltyAmount > 0 ? penaltyAmount : null

    batch.update(studentInvoiceRef.doc(oldInvoice.key), {
      penalty: this.totalPenalty,
      penaltyRef: null,
    })

    batch.commit().then(() => {
      this.process = false;
      callback(true, null);
    }).catch(error => {
      this.process = false;
      callback(false, error);
    })
  }

  @action
  fetchDraft(studentKey: string, callback) {
    this.loading = true;
    this.ds.studentDocument(studentKey).collection("draft", ref => ref
      .where("isPaid.key", "==", paymentStatus.unpaid.key))
      .valueChanges().subscribe(docs => {
        this.draft = docs;
        this.loading = false;
        callback(docs);
      })
  }

  @action
  fetchScholarShiftPenalty(studentKey: string, callback) {
    this.loading = true;
    this.ds.studentDocument(studentKey).collection("scholarship_penalty", ref => ref
      .where("isPaid", "==", false))
      .valueChanges().subscribe(docs => {
        this.scholarshipPenalty = docs;
        this.loading = false;
        callback(docs);
      })
  }

  @action
  fetchStudentInstallment(studentKey: string) {
    this.process = true;
    this.installment = null;
    this.installmentRef = null;
    this.ds.studentDocument(studentKey).valueChanges().subscribe(docs => {
      if (docs) {
        this.installmentRef = docs.installmentRef ? docs.installmentRef : null;
        this.installment = docs ? ConvertService.toNumber(docs.installment) : 0;
      }
      this.process = false;
    })
  }

  @action
  addInstallment(item: IInstallment, student: any, user: any, dailyShift: any, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const installmentRef = this.ds.installmentFireRef().doc(item.key);
    const studentRef = this.ds.studentFireRef().doc(student.key);
    const userRef = this.ds.userFireRef().doc(user.key);
    const dailyShiftRef = this.ds.dailyShiftFireRef().doc(dailyShift.key);
    let { price } = item
    batch.set(installmentRef, item);
    batch.update(studentRef, {
      installment: ConvertService.toNumber(student.installment) + price,
      installmentRef: item
    })
    batch.update(userRef, {
      installment: ConvertService.toNumber(user.installment) + price,
    })
    batch.update(dailyShiftRef, {
      installment: ConvertService.toNumber(dailyShift.installment) + price,
    })

    batch.commit().then(() => {
      this.process = false;
      callback(true, null)
    }).catch(error => {
      this.process = false;
      callback(false, error)
    })
  }

  @action
  fetchPayments(admissionKey: string, admission: any, studentKey: string, invoiceKey, callback) {
    this.loading = true;
    this.paymentEmpty = false;
    this.paymentHeader = null;
    this.paymentDetail = null;
    this.ds.studentInvoiceByAdmission(admissionKey, admission, studentKey, invoiceKey).valueChanges().subscribe(allInv => {
      let inv = [];
      if (allInv && allInv.length > 0) {
        inv = allInv.filter(m => m.isVoid === false);
      }
      let headerKey = null;
      let data = [];
      const docs = inv.filter(m => m.invoice_type.key !== invoiceTypesObj.scholarshipPenalty.key);
      // if unpaid payment
      if (invoiceKey) {
        data = docs.filter(m => m.course !== null && m.course.key === "unpaid" && m.isHeader === true);
        headerKey = data.length > 0 ? data[0].key : null;
        if (data.length == 0) {
          data = docs.filter(m => m.isHeader === true && m.headerRef == invoiceKey);
          headerKey = invoiceKey;
        }
      } else {//if enrollment payment
        data = docs.filter(m => m.course === null && m.isHeader === true);
        headerKey = data.length > 0 ? data[0].key : null;
      }
      //if invoice by scholarship penalty

      //end scholarship penalty
      this.paymentHeader = docs.length > 0 ? docs.filter(m => m.isHeader === true && m.headerRef == headerKey)[0] : null;
      this.paymentDetail = docs.length > 0 ? docs.filter(m => m.isHeader === false && m.headerRef == headerKey) : [];
      this.paymentEmpty = this.paymentDetail.length === 0;
      this.loading = false;
      const invoices = docs.filter(m => m.headerRef === headerKey)
      callback(invoices);
    })
  }

  @action
  fetchTestingInvoice(studentKey: string, callback) {
    this.process = true;
    this.paymentTesting = null;
    this.ds.studentTestingInvoice(studentKey).valueChanges().subscribe(docs => {
      this.paymentTesting = docs;
      this.process = false;
      callback(docs)
    })
  }

  @action
  fetchStudentInvoices(studentKey: string) {
    this.loading = true;
    this.ds.invoiceAllByStudentKeyRef(studentKey).valueChanges().subscribe(docs => {
      this.empty = docs.length === 0;
      this.allInvoices = MappingService.orderByDesc(docs, "received_date");
      this.loading = false;
    })
  }

  @action
  fetchUnpaidStudent(studentKey: string) {
    this.loading = true;
    this.unpaidInvoices = null;
    this.ds.invoiceStudentUnpaidRef(studentKey).valueChanges().subscribe(docs => {
      if (docs.length > 0) {
        this.unpaidInvoices = docs.filter(m => !m.isVoid);
      }
      this.empty = docs.length === 0;
      this.loading = false;
    })
  }

  @action
  async removeOldTerm(header: any, callback) {
    this.loading = true;
    const { headerRef, student } = header;
    const batch = this.ds.batch();
    const invoiceDocs = await this.ds.studentDocument(student.key).collection("invoices", ref => ref.where("headerRef", "==", headerRef)).get().toPromise();
    const invoiceData = MappingService.pushToArray(invoiceDocs);
    const invoices = invoiceData.filter(m => m.isPaid.key === paymentStatus.unpaid.key);

    // const detail = invoices.filter(m => m.isHeader === false)[0];
    // const enrollmentDoc = await this.ds.studentDocument(student.key).collection("institute_enrollment").doc(detail.key).get().toPromise();
    // const enrollmentData = MappingService.pushToObject(enrollmentDoc);

    const studentRef = this.ds.studentFireRef().doc(student.key);
    // if (enrollmentData) {
    //   batch.delete(studentRef.collection("institute_enrollment").doc(enrollmentData.key));
    // }

    if (invoices && invoices.length > 0) {
      invoices.forEach(m => {
        batch.delete(studentRef.collection("invoices").doc(m.key));
      })
    }

    batch.commit().then(() => {
      this.loading = false;
      callback(true, null);
    }).catch(error => {
      this.loading = false;
      callback(true, null);
    })
  }

  @action
  async updateInvoiceProgram(header: any, callback) {
    this.loading = true;
    const { headerRef, student } = header;
    const batch = this.ds.batch();
    const invoiceDocs = await this.ds.studentDocument(student.key).collection("invoices", ref => ref.where("headerRef", "==", headerRef)).get().toPromise();
    const invoiceData = MappingService.pushToArray(invoiceDocs);
    const invoices = invoiceData.filter(m => m.isPaid.key === paymentStatus.unpaid.key);

    const studentDoc = await this.ds.studentDocument(student.key).get().toPromise();
    const studentData = MappingService.pushToObject(studentDoc);

    const { program_academic } = studentData;
    const studentRef = this.ds.studentFireRef().doc(student.key);

    if (invoices && invoices.length > 0) {
      invoices.forEach(m => {
        batch.update(studentRef.collection("invoices").doc(m.key), {
          program: program_academic
        });
      })
    }

    batch.commit().then(() => {
      this.loading = false;
      callback(true, null);
    }).catch(error => {
      this.loading = false;
      callback(true, null);
    })
  }

  @action
  fetchOtherFeeDetail(schoolKey: string, key: string) {
    this.process = true;
    this.ds.otherServiceFeeDocRef(schoolKey, key).collection("admission_service_other_detail").valueChanges().subscribe(docs => {
      this.otherFees = MappingService.orderBy(docs, "order");;
      this.process = false;
    })
  }

  @action
  fetchMiscellaneousFee() {
    this.process = true;
    this.ds.miscellaneousFeeRef().valueChanges().subscribe(docs => {
      this.miscellaneousFee = docs;
      this.process = false;
    })
  }

  @action
  addItem(invoice: IInvoice, studentKey: string, callback) {
    this.process = true;
    this.ds.studentDocument(studentKey).collection("draft").doc(invoice.key).set(invoice).then(() => {
      callback(true, null);
      this.process = false;
    })
      .catch(error => {
        callback(false, error);
        this.process = false;
      });
  }

  @action
  addOtherFee(selectedCampus, selectedSchool, items: Array<IInvoice>, student: any, paymentHeader, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const studentInvoiceRef = this.ds.studentFireDocument(student.key).collection("invoices");
    let { program_academic } = student;
    let headerKey = this.invoiceKey ? this.invoiceKey : this.ds.createId();
    let program = program_academic;
    if (paymentHeader) program = paymentHeader.program;

    const invoiceNo = null;
    const date = new Date();
    const displayLevel = null;
    const displayShift = "Morning";
    const schoolFeeType = null;
    const grandTotal = MappingService.sum(items, "amount");
    const isNew = paymentHeader ? false : true;

    if (isNew) {
      const doc: any = items[0];
      const invoiceType = doc.invoice_type;
      const displayProgram = doc.course.otherFee.name;
      const create_by = doc.create_by;
      const currentYear = doc.issue_year;
      const campus = doc.campus;
      const invoiceHeader: IInvoice = {
        key: headerKey,
        display_program: displayProgram,
        display_level: displayLevel,
        display_shift: displayShift,
        create_date: date,
        create_date_key: ConvertService.dateKey(),
        create_by: create_by,
        issue_by: create_by,
        issue_date: date,
        invoice_no: invoiceNo,
        invoice_type: invoiceType,
        school_fee_type: schoolFeeType,
        student: MappingService.studentObj(student),
        verify_by: create_by,
        verify_date: date,
        page_key: ConvertService.pageKey(),
        verify_date_key: ConvertService.dateKey(),
        invoice_date: date,
        invoice_date_key: ConvertService.dateKey(),
        course: null,
        isPaid: paymentStatus.unpaid,
        isVoid: false,
        program: program_academic,
        byAdmission: true,
        issue_year: academicYearObj(currentYear),
        payment_year: null,
        headerRef: headerKey,
        isHeader: true,
        enroll_status: EnrollStatus.add,
        isEnrollVerify: true,
        schoolSession: null,
        description: null,
        penaltyRef: null,
        penalty: null,
        scholarshipRef: null,
        scholarship: null,
        loan: null,
        prepaidRef: null,
        prepaid: null,
        price: grandTotal,
        amount: grandTotal,

        campusKey: selectedCampus.key,
        campus: selectedCampus,
        schoolKey: selectedSchool.key,
        school: selectedSchool,
      }
      batch.set(studentInvoiceRef.doc(headerKey), invoiceHeader);
    } else {
      let { key, program, price, amount } = paymentHeader
      headerKey = key;
      program = program
      batch.update(studentInvoiceRef.doc(headerKey), {
        price: price + grandTotal,
        amount: amount + grandTotal,
      });
    }

    items.forEach(item => {
      item.headerRef = headerKey;
      item.program = program;
      batch.set(studentInvoiceRef.doc(item.key), item);
    })

    batch.commit()
      .then(() => {
        callback(true, null);
        this.process = false;
      })
      .catch(error => {
        callback(false, error);
        this.process = false;
      });
  }

  @action
  addCapitalFee(item: any, paymentHeader: any, callback) {
    this.process = true;
    const batch = this.ds.batch();
    let { program, student, amount, campus, school } = item;
    const studentInvoiceRef = this.ds.studentFireDocument(student.key).collection("invoices");
    let headerKey = this.invoiceKey ? this.invoiceKey : this.ds.createId();
    if (paymentHeader) program = paymentHeader.program;

    const invoiceNo = null;
    const date = new Date();
    const displayLevel = null;
    const displayShift = "Morning";
    const schoolFeeType = null;
    const grandTotal = amount;
    const isNew = paymentHeader ? false : true;

    if (isNew) {
      const doc: any = item;
      const invoiceType = doc.invoice_type;
      const displayProgram = invoiceTypesObj.capitalFee.text;
      const create_by = doc.create_by;
      const currentYear = doc.issue_year;
      const invoiceHeader: IInvoice = {
        key: headerKey,
        display_program: displayProgram,
        display_level: displayLevel,
        display_shift: displayShift,
        create_date: date,
        create_date_key: ConvertService.dateKey(),
        create_by: create_by,
        issue_by: create_by,
        issue_date: date,
        invoice_no: invoiceNo,
        invoice_type: invoiceType,
        school_fee_type: schoolFeeType,
        student: MappingService.studentObj(student),
        verify_by: create_by,
        verify_date: date,
        page_key: ConvertService.pageKey(),
        verify_date_key: ConvertService.dateKey(),
        invoice_date: date,
        invoice_date_key: ConvertService.dateKey(),
        course: null,
        isPaid: paymentStatus.unpaid,
        isVoid: false,
        program: program,
        byAdmission: true,
        issue_year: academicYearObj(currentYear),
        payment_year: null,
        headerRef: headerKey,
        isHeader: true,
        enroll_status: EnrollStatus.add,
        isEnrollVerify: true,
        schoolSession: null,
        description: null,
        penaltyRef: null,
        penalty: null,
        scholarshipRef: null,
        scholarship: null,
        loan: null,
        prepaidRef: null,
        prepaid: null,
        price: grandTotal,
        amount: grandTotal,

        campusKey: campus.key,
        campus: campus,
        schoolKey: school.key,
        school: school,
      }
      batch.set(studentInvoiceRef.doc(headerKey), invoiceHeader);
    } else {
      let { key, program, price, amount } = paymentHeader
      headerKey = key;
      program = program
      batch.update(studentInvoiceRef.doc(headerKey), {
        price: price + grandTotal,
        amount: amount + grandTotal,
      });
    }

    item.headerRef = headerKey;
    item.program = program;
    batch.set(studentInvoiceRef.doc(item.key), item);

    batch.commit()
      .then(() => {
        callback(true, null);
        this.process = false;
      })
      .catch(error => {
        callback(false, error);
        this.process = false;
      });
  }

  @action
  addFeeWithPeriod(item: any, paymentHeader: any, alignPayment, callback) {
    this.process = true;
    const batch = this.ds.batch();
    let { program, student, amount, campus, school } = item;
    const studentInvoiceRef = this.ds.studentFireDocument(student.key).collection("invoices");
    let headerKey = this.invoiceKey ? this.invoiceKey : this.ds.createId();
    if (paymentHeader) program = paymentHeader.program;

    const invoiceNo = null;
    const date = new Date();
    const displayLevel = null;
    const displayShift = "Morning";
    const schoolFeeType = null;
    const grandTotal = amount;
    const isNew = paymentHeader ? false : true;

    if (isNew) {
      const doc: any = item;
      const invoiceType = doc.invoice_type;
      const displayProgram = "Other Service Fees";
      const create_by = doc.create_by;
      const currentYear = doc.issue_year;
      const invoiceHeader: IInvoice = {
        key: headerKey,
        isAlignPayment: alignPayment ? true : false,
        display_program: displayProgram,
        display_level: displayLevel,
        display_shift: displayShift,
        create_date: date,
        create_date_key: ConvertService.dateKey(),
        create_by: create_by,
        issue_by: create_by,
        issue_date: date,
        invoice_no: invoiceNo,
        invoice_type: invoiceType,
        school_fee_type: schoolFeeType,
        student: MappingService.studentObj(student),
        verify_by: create_by,
        verify_date: date,
        page_key: ConvertService.pageKey(),
        verify_date_key: ConvertService.dateKey(),
        invoice_date: date,
        invoice_date_key: ConvertService.dateKey(),
        course: null,
        isPaid: paymentStatus.unpaid,
        isVoid: false,
        program: program,
        byAdmission: true,
        issue_year: academicYearObj(currentYear),
        payment_year: null,
        headerRef: headerKey,
        isHeader: true,
        enroll_status: EnrollStatus.add,
        isEnrollVerify: true,
        schoolSession: null,
        description: null,
        penaltyRef: null,
        penalty: null,
        scholarshipRef: null,
        scholarship: null,
        loan: null,
        prepaidRef: null,
        prepaid: null,
        price: grandTotal,
        amount: grandTotal,

        fromDate: doc.fromDate,
        fromDateKey: doc.fromDateKey,
        toDate: doc.toDate,
        toDateKey: doc.toDateKey,

        campusKey: campus.key,
        campus: campus,
        schoolKey: school.key,
        school: school,
      }
      batch.set(studentInvoiceRef.doc(headerKey), invoiceHeader);
    } else {
      let { key, program, price, amount } = paymentHeader
      headerKey = key;
      program = program
      batch.update(studentInvoiceRef.doc(headerKey), {
        price: price + grandTotal,
        amount: amount + grandTotal,

        isAlignPayment: alignPayment ? true : false,
      });
    }

    item.headerRef = headerKey;
    item.program = program;
    batch.set(studentInvoiceRef.doc(item.key), item);

    batch.commit()
      .then(() => {
        callback(true, null);
        this.process = false;
      })
      .catch(error => {
        callback(false, error);
        this.process = false;
      });
  }

  @action
  addMiscellaneousFee(items: Array<IInvoice>, student: any, paymentHeader, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const studentInvoiceRef = this.ds.studentFireDocument(student.key).collection("invoices");
    let { program_academic } = student;
    let headerKey = this.invoiceKey ? this.invoiceKey : this.ds.createId();
    let program = program_academic;
    if (paymentHeader) program = paymentHeader.program;

    const invoiceNo = null;
    const date = new Date();
    const displayLevel = null;
    const displayShift = null;
    const schoolFeeType = null;
    const grandTotal = MappingService.sum(items, "amount");
    const isNew = paymentHeader ? false : true;

    if (isNew) {
      const doc = items[0];
      const invoiceType = doc.invoice_type;
      const displayProgram = doc.description;
      const create_by = doc.create_by;
      const currentYear = doc.issue_year;
      const campus = doc.campus;
      const invoiceHeader: IInvoice = {
        key: headerKey,
        display_program: displayProgram,
        display_level: displayLevel,
        display_shift: displayShift,
        create_date: date,
        create_date_key: ConvertService.dateKey(),
        create_by: create_by,
        issue_by: create_by,
        issue_date: date,
        invoice_no: invoiceNo,
        invoice_type: invoiceType,
        school_fee_type: schoolFeeType,
        student: MappingService.studentObj(student),
        verify_by: create_by,
        verify_date: date,
        page_key: ConvertService.pageKey(),
        verify_date_key: ConvertService.dateKey(),
        invoice_date: date,
        invoice_date_key: ConvertService.dateKey(),
        course: null,
        isPaid: paymentStatus.unpaid,
        isVoid: false,
        program: program_academic,
        byAdmission: true,
        issue_year: academicYearObj(currentYear),
        payment_year: null,
        headerRef: headerKey,
        isHeader: true,
        enroll_status: EnrollStatus.add,
        isEnrollVerify: true,
        campus: MappingService.campusObj(campus),
        schoolSession: null,
        description: null,
        penaltyRef: null,
        penalty: null,
        scholarshipRef: null,
        scholarship: null,
        loan: null,
        prepaidRef: null,
        prepaid: null,
        price: grandTotal,
        amount: grandTotal,
      }
      batch.set(studentInvoiceRef.doc(headerKey), invoiceHeader);
    } else {
      let { key, program, price, amount } = paymentHeader
      headerKey = key;
      program = program
      batch.update(studentInvoiceRef.doc(headerKey), {
        price: price + grandTotal,
        amount: amount + grandTotal,
      });
    }

    items.forEach(item => {
      item.headerRef = headerKey;
      item.program = program;
      batch.set(studentInvoiceRef.doc(item.key), item);
    })

    batch.commit()
      .then(() => {
        callback(true, null);
        this.process = false;
      })
      .catch(error => {
        callback(false, error);
        this.process = false;
      });
  }

  @action
  addPenaltyFee(item: any, student: any, oldInvoice: IInvoice, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const studentInvoiceRef = this.ds.studentFireDocument(student.key).collection("invoices");

    const headerKey = oldInvoice.key;
    batch.update(studentInvoiceRef.doc(headerKey), {
      penalty: item.price,
      penaltyRef: item.course,
    });

    batch.commit()
      .then(() => {
        callback(true, null);
        this.process = false;
      })
      .catch(error => {
        callback(false, error);
        this.process = false;
      });
  }

  @action
  addDiscountFee(discount: number, student: any, oldInvoice: IInvoice, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const studentInvoiceRef = this.ds.studentFireDocument(student.key).collection("invoices");

    const headerKey = oldInvoice.key;
    batch.update(studentInvoiceRef.doc(headerKey), {
      discount: discount,
    });

    batch.commit().then(() => {
      callback(true, null);
      this.process = false;
    })
      .catch(error => {
        callback(false, error);
        this.process = false;
      });
  }

  @action
  createInvoice(invoice: IInvoice, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const invoiceRef = this.ds.invoiceFireRef();
    const headerKey = this.ds.createId();
    const date = new Date();
    if (this.header) {
      batch.update(invoiceRef.doc(this.header.key), {
        amount: this.header.amount + invoice.price,
        price: this.header.price + invoice.price,
        update_by: invoice.create_by,
        update_date: date
      })
      batch.set(invoiceRef.doc(invoice.key), invoice);
      batch.commit().then(() => {
        callback(true, null);
        this.process = false;
      })
        .catch(error => {
          callback(false, error);
          this.process = false;
        });
    }
    else {
      this.as.updateInvoiceNo((success, response) => {
        if (success) {
          const invoiceNo = ConvertService.generate_testing_invoiceNo(response);
          const { create_by, program, price, issue_term, schoolSession } = invoice;
          const invoiceHeader: IInvoice = {
            key: headerKey,
            create_date: date,
            create_date_key: ConvertService.toDateKey(date),
            create_by: create_by,
            issue_by: create_by,
            issue_date: date,
            invoice_no: invoiceNo,
            invoice_type: invoiceTypesObj.abcCourse,
            school_fee_type: enrollPrograms.academic,
            student: invoice.student,
            verify_by: create_by,
            verify_date: date,
            page_key: ConvertService.pageKey(),
            verify_date_key: ConvertService.toDateKey(date),
            invoice_date: date,
            invoice_date_key: ConvertService.toDateKey(date),
            course: invoice.course,
            isPaid: paymentStatus.unpaid,
            isVoid: false,
            program: program,
            byAdmission: false,
            price: price,
            amount: price,
            issue_term: issue_term,
            payment_term: null,
            headerRef: headerKey,
            isHeader: true,
            enroll_status: EnrollStatus.assign,
            isEnrollVerify: false,
            campus: ConvertService.toNull(create_by.campus),
            schoolSession: schoolSession,
            description: invoiceTypesObj.abcCourse.text
          }
          batch.set(invoiceRef.doc(headerKey), invoiceHeader)
          batch.set(invoiceRef.doc(invoice.key), invoice)
          batch.commit().then(() => {
            callback(true, null);
            this.process = false;
          })
            .catch(error => {
              callback(false, error);
              this.process = false;
            });
        }
      })
    }
  }

  @action
  fetchInvoiceItems(studentKey: string, headerKey: string, callback) {
    this.enrollExcluded = null;
    this.enrollIncluded = null;
    this.enroll = null;
    this.empty = true;
    this.tuitionFees = [];
    this.totalTuitionFee = null;
    this.tuitionFees = null;
    this.totalTestingFee = null;
    this.otherFee = null;
    this.inventoryFee = null;
    this.capitalFee = null;
    this.feeWithPeriod = null;
    this.totalScholarShipPenalty = null;
    this.totalScholarship = null;
    this.totalMiscellaneous = null;
    this.totalPrepaid = null;
    this.totalInstallment = null;
    this.ds.invoiceItemsRef(studentKey, headerKey).valueChanges().subscribe(docs => {
      this.receiptHeader = docs.filter(m => m.isHeader === true && m.isPaid.key === paymentStatus.paid.key)[0];

      //=====TESTING
      const { invoice_type } = this.receiptHeader;
      if (invoice_type && (invoice_type.key === invoiceTypesObj.registrationFee.key)) {
        this.totalTestingFee = this.receiptHeader.amount;
      } else {
        this.tuitionFees = docs.filter(m => m.isHeader === false && m.invoice_type.key === invoiceTypesObj.tuitionFee.key);
        const totalFee = MappingService.sumTuitionFees(this.tuitionFees);
        if (totalFee > 0) this.totalTuitionFee = totalFee;

        this.otherFee = docs.filter(m => m.invoice_type.key !== invoiceTypesObj.tuitionFee.key
          && m.invoice_type.key !== invoiceTypesObj.posFee.key
          && m.invoice_type.key !== invoiceTypesObj.capitalFee.key
          && m.invoice_type.key !== invoiceTypesObj.feeWithPeriod.key
          && m.isHeader == false);

        this.inventoryFee = docs.filter(m => m.invoice_type.key === invoiceTypesObj.posFee.key && m.isHeader == false);
        this.capitalFee = docs.filter(m => m.invoice_type.key === invoiceTypesObj.capitalFee.key && m.isHeader == false);
        this.feeWithPeriod = docs.filter(m => m.invoice_type.key === invoiceTypesObj.feeWithPeriod.key && m.isHeader == false);
        this.totalSubject = this.tuitionFees.length;
      }
      callback(docs);
    })
  }

  @action
  fetchInvoiceByStudentKey(studentKey: string, callback) {
    this.process = true;
    this.receiptHeader = null;
    this.ds.invoiceItemsByStudentKeyRef(studentKey).valueChanges().subscribe(docs => {
      this.empty = docs.length == 0;
      this.invoiceHeader = docs.length > 0 ? docs[0] : null;
      if (docs.length > 0) {
        this.fetchInvoiceItems(studentKey, this.invoiceHeader.headerRef, req => {
          this.process = false;
          callback(this.invoiceHeader);
        })
      }
      else {
        this.process = false;
        callback(this.invoiceHeader);
      }
    })
  }

  @action
  fetchInvoiceByInvoiceNo(invoiceNo: number, callback) {
    this.process = true;
    this.ds.invoiceItemsByInvoiceNoRef(invoiceNo).valueChanges().subscribe(docs => {
      this.process = false;
      callback(docs);
    })
  }

  @action
  fetchInvoice(Id: string, status: string) {
    this.loading = true;
    this.ds
      .invoiceRef(Id, status, ConvertService.toDateKey(new Date()))
      .valueChanges()
      .subscribe(doc => {
        this.data = doc;
        const items = doc.filter(m => m.isHeader);
        this.detail = doc.filter(m => m.isHeader === false);
        this.totalPrice = MappingService.sum(this.detail, 'amount');
        if (items.length > 0) {
          this.header = items[0];
          if (this.header.isPaid.key === 2) {
            if (
              this.studentScholarship &&
              (this.studentScholarship.scholarship.cash ||
                this.studentScholarship.scholarship.percentage) > 0
            ) {
              if (this.studentScholarship.scholarship.cash > 0) {
                this.header.amount =
                  this.header.price -
                  ConvertService.toNumber(
                    this.studentScholarship.scholarship.cash
                  );
              } else if (this.studentScholarship.scholarship.percentage > 0) {
                this.header.amount =
                  this.header.price -
                  (this.header.price *
                    ConvertService.toNumber(
                      this.studentScholarship.scholarship.percentage
                    )) /
                  100;
              }
              this.header.scholarship = this.studentScholarship.scholarship;
            } else {
              this.header.scholarship = null;
            }
          }
        } else {
          this.header = null;
        }
        this.loading = false;
      });
  }

  // @action
  // getStudentScholarship(Id: string, callback) {
  //   this.loading = true;
  //   this.ds
  //     .studentScholarshipRef(Id)
  //     .valueChanges()
  //     .subscribe(docs => {
  //       if (docs.length > 0) {
  //         this.studentScholarship = docs[0];
  //         this.loading = false;
  //         callback(this.studentScholarship);
  //       } else {
  //         this.studentScholarship = null;
  //         callback(null);
  //       }
  //     });
  // }

  @action
  receivePaymentMiscellaneous(
    payment: IReceivedPayment,
    program: any,
    user: any,
    student: any,
    term: any,
    callback
  ) {
    this.process = true;
    this.as.updateInvoiceNo((success, response) => {
      if (success) {
        const invoiceNo = ConvertService.generate_testing_invoiceNo(response);

        const batch = this.ds.batch();
        const invoice_header_key = this.ds.createId();
        const invoice_detail_key = this.ds.createId();
        const date = new Date();
        const invoiceType = invoiceTypesObj.miscellaneous;
        const studentInfo = MappingService.studentObj(student);

        const invoiceHeader: IInvoice = {
          key: invoice_header_key,
          create_date: date,
          create_date_key: ConvertService.dateKey(),
          create_by: user,
          issue_by: user,
          issue_date: date,
          invoice_no: invoiceNo,
          invoice_type: invoiceType,
          student: studentInfo,
          verify_by: user,
          verify_date: date,
          page_key: ConvertService.pageKey(),
          verify_date_key: ConvertService.dateKey(),
          invoice_date: date,
          invoice_date_key: ConvertService.dateKey(),
          course: invoiceTypesObj.tuitionFee,
          isPaid: TestFeeStatus.paid,
          isVoid: false,
          program: program,
          price: program.price,
          amount: program.price,
          headerRef: invoice_header_key,
          isHeader: true,
          campus: user.campus,
          schoolSession: student.shift,
          received_by: payment.cashier,
          received_date: new Date(),
          received_date_key: ConvertService.dateKey(),
          dailyShift: payment.shift,
          caseIn: payment.cashIn,
          payment_type: paymentType.cash,
          payment_term: payment.payment_term,
        };

        const invoiceDetail: IInvoice = {
          key: invoice_detail_key,
          create_date: date,
          create_date_key: ConvertService.dateKey(),
          create_by: user,
          issue_by: user,
          issue_date: date,
          invoice_no: invoiceNo,
          invoice_type: invoiceType,
          student: studentInfo,
          verify_by: user,
          verify_date: date,
          page_key: ConvertService.pageKey(),
          verify_date_key: ConvertService.dateKey(),
          invoice_date: date,
          invoice_date_key: ConvertService.dateKey(),
          course: invoiceTypesObj.tuitionFee,
          isPaid: TestFeeStatus.paid,
          isVoid: false,
          program: program,
          price: program.price,
          amount: program.price,
          headerRef: invoice_header_key,
          isHeader: false,
          campus: user.campus,
          schoolSession: student.shift,
          received_by: payment.cashier,
          received_date: new Date(),
          received_date_key: ConvertService.dateKey(),
          dailyShift: payment.shift,
          caseIn: payment.cashIn,
          payment_type: paymentType.cash,
          payment_term: payment.payment_term,
        };

        const transcriptRe: IRegistrar = {
          key: this.ds.createId(),
          term: term,
          campus: user.campus,
          puc_id: studentInfo.puc_id,
          page_key: ConvertService.pageKey(),
          invoiceRef: invoiceHeader,
          student: studentInfo,
          admission_date: new Date(),
          admission_date_key: ConvertService.dateKey(),
          isPaid: TestFeeStatus.paid,
          status: RegistrarStatus.pending,
          create_date: new Date(),
          create_date_key: ConvertService.dateKey(),
          create_by: user,
        }

        const student_puc_profile: Student_PUC_Profile = {
          key: this.ds.createId(),
          create_date: new Date(),
          create_by: user,
          major_level: invoiceType,
          program: program,
          term: term,
          student: studentInfo
        };

        payment.invoiceKey = invoiceHeader.key;
        payment.studentKey = student.key;
        const movementRef = this.ds.receivedPaymentFireRef().doc(payment.key);
        const invoiceHeaderRef = this.ds.invoicesFireRef().doc(invoiceHeader.key)
        const invoiceDetailRef = this.ds.invoicesFireRef().doc(invoiceDetail.key)
        const registrarRef = this.ds.registrarFireRef().doc(transcriptRe.key)
        const studentPucProfileRef = this.ds.studentPucProfileFireRef().doc(student_puc_profile.key);

        batch.set(movementRef, payment);
        batch.set(invoiceHeaderRef, invoiceHeader);
        batch.set(invoiceDetailRef, invoiceDetail);
        batch.set(registrarRef, transcriptRe);
        batch.set(studentPucProfileRef, student_puc_profile);

        batch
          .commit()
          .then(() => {
            callback(true, null);
            this.process = false;
            this.header = invoiceHeader;
          })
          .catch(error => {
            callback(false, error);
            this.process = false;
          });
      }
    });
  }

  @action
  receivePayment(
    invoices: Array<IInvoice>,
    payment: IReceivedPayment,
    status: string,
    callback
  ) {
    this.process = true;
    // const batch = this.ds.batch();
    // if (status == "testing-fees") {
    //   const testingRef = this.ds.testingFireRef().doc(invoices[0].testRef);
    //   batch.update(testingRef, {
    //     isPaidTest: true,
    //     pay_date: new Date(),
    //     pay_date_key: ConvertService.dateKey()
    //   });

    //   const movementRef = this.ds.receivedPaymentFireRef().doc(payment.key);
    //   batch.set(movementRef, payment);
    //   invoices.forEach(inv => {
    //     const item = {
    //       received_by: payment.cashier,
    //       received_date: new Date(),
    //       received_date_key: ConvertService.dateKey(),
    //       dailyShift: payment.shift,
    //       caseIn: payment.cashIn,
    //       isPaid: TestFeeStatus.paid,
    //       payment_type: paymentType.cash,
    //       payment_term: payment.payment_term,
    //       received_campus: payment.payment_campus
    //     };
    //     const invoiceDetailRef = this.ds.invoiceFireRef().doc(inv.key);
    //     batch.update(invoiceDetailRef, item);
    //   });
    //   batch
    //     .commit()
    //     .then(() => {
    //       callback(true, null);
    //       this.process = false;
    //     })
    //     .catch(error => {
    //       callback(false, error);
    //       this.process = false;
    //     });
    // } else if (status == "abc-course") {

    //   const abcStudentRef = this.ds
    //     .abcStudentFireRef()
    //     .doc(invoices[0].abcStudentRef);
    //   batch.update(abcStudentRef, {
    //     isPaidAbc: true,
    //     isPaid: TestFeeStatus.paid
    //   });

    //   const movementRef = this.ds.receivedPaymentFireRef().doc(payment.key);
    //   batch.set(movementRef, payment);
    //   invoices.forEach(inv => {
    //     const item = {

    //       received_by: payment.cashier,
    //       received_date: new Date(),
    //       received_date_key: ConvertService.dateKey(),
    //       dailyShift: payment.shift,
    //       caseIn: payment.cashIn,
    //       isPaid: TestFeeStatus.paid,
    //       payment_type: paymentType.cash,
    //       payment_term: payment.payment_term,
    //       received_campus: payment.payment_campus,
    //       scholarship: payment.invoice.scholarship,
    //       amount: payment.invoice.amount,
    //     };
    //     const invoiceDetailRef = this.ds.invoiceFireRef().doc(inv.key);
    //     batch.update(invoiceDetailRef, item);
    //   });
    //   batch
    //     .commit()
    //     .then(() => {
    //       callback(true, null);
    //       this.process = false;
    //     })
    //     .catch(error => {
    //       callback(false, error);
    //       this.process = false;
    //     });
    // }

    // //======== tuition-fees
    // else if (status == "tuition-fees") {
    //   const header = invoices.filter(m => m.isHeader === true);
    //   if (header.length > 0) {

    //     let { student, issue_term } = invoices[0];
    //     this.ds.admissionStudentFireRef(issue_term.key, student.key).get()
    //       .then(docs => {
    //         if (!docs.empty) {
    //           docs.forEach(doc => {
    //             let { key } = doc.data();
    //             const admissionStudentRef = this.ds.admissionStudentRef().doc(key);
    //             const termAdmissionRef = this.ds.academicTermAdmissionRef(issue_term.key).doc(key);
    //             batch.update(admissionStudentRef, { isPaid: true });
    //             batch.update(termAdmissionRef, { isPaid: true });
    //           });
    //         }

    //         this.ds.admissionEnrollmentRef(issue_term.key, student.key).get()
    //           .then(enrolls => {
    //             if (!enrolls.empty) {
    //               enrolls.forEach(enroll => {
    //                 let { key } = enroll.data();
    //                 const termEnrollmentRef = this.ds.academicTermEnrollmentRef(issue_term.key).doc(key);
    //                 batch.update(termEnrollmentRef, { isPaid: true });
    //               });
    //             }

    //             const movementRef = this.ds.receivedPaymentFireRef().doc(payment.key);
    //             batch.set(movementRef, payment);
    //             invoices.forEach(inv => {
    //               const item: any = {
    //                 received_by: payment.cashier,
    //                 received_date: new Date(),
    //                 received_date_key: ConvertService.dateKey(),
    //                 dailyShift: payment.shift,
    //                 caseIn: payment.cashIn,
    //                 isPaid: TestFeeStatus.paid,
    //                 payment_type: paymentType.cash,
    //                 payment_term: payment.payment_term,
    //                 received_campus: payment.payment_campus
    //               };

    //               const invoiceDetailRef = this.ds.invoiceFireRef().doc(inv.key);
    //               batch.update(invoiceDetailRef, item);
    //             });

    //             batch.commit().then(() => {
    //               callback(true, null);
    //               this.process = false;
    //             })
    //               .catch(error => {
    //                 callback(false, error);
    //                 this.process = false;
    //               });

    //           });
    //       });
    //   }
    // }
  }

  @action
  async schoolPayment(item: IReceivedPayment,
    invoiceHeader: IInvoice,
    invoiceDetail: Array<any>,
    student: any,
    admission: any,
    studentPrepaid: Array<any>,
    scholarship: any,
    payParam: any,
    callback) {
    this.process = true;
    const { puc_id, english_credit, english_creditRef, credit, creditRef } = student;

    const batch = this.ds.batch();
    const userData = await this.ds.userRef(item.cashier.key).get().toPromise();
    const userDoc = MappingService.pushToObject(userData);

    const sysData = await this.ds.sysSetting().get().toPromise();
    const sysDoc = MappingService.pushToObject(sysData);

    const userRef = this.ds.userFireRef();
    const shiftMovementRef = this.ds.shiftMovementFireRef();

    const invoiceFailRef = this.ds.invoiceFailFireRef();
    const studentRef = this.ds.studentFireRef();
    const termRef = this.ds.academicsTermFireRef();
    const studentAccountRef = this.ds.studentAccountDocFire();
    const abcStudentRef = this.ds.abcStudentRef();
    const receivedPaymentRef = this.ds.receivedPaymentFireRef().doc(item.key);
    const scheduleInProgressRef = this.ds.scheduleInProgressRef();
    const settingRef = this.ds.settingFireStore();

    const headerKey = invoiceHeader.key;
    const invoiceNo = ConvertService.generate_testing_invoiceNo(sysDoc);

    const { create_date, payment_year, shift, cashIn, note } = item;
    let shiftData = item.shift;
    if (shiftData) {
      shiftData = userDoc.shift;
      item.shift = shiftData;
    };

    const create_by = userObj(item.create_by);
    const date = create_date;

    //===== New ID
    if (!puc_id) {
      const pucID = ConvertService.generate_puc_id(sysDoc);
      const emailStudent = pucID + "@gmail.com";
      student.puc_id = pucID;
      student.email = emailStudent;
      const studentAccount: IStudentAccount = {
        key: student.key,
        create_date: new Date(),
        create_by: item.create_by,
        status: recordStatus.active,
        puc_id: pucID,
        email: emailStudent,
        fileUrl: null,
        displayName: student.full_name,
        studentKey: student.key,
        phone: student.mobile_phone,
        token: null,
        pinCode: null,
        student: studentObj(student),
      };

      batch.update(studentRef.doc(student.key), {
        puc_id: pucID,
        email: emailStudent,
      })
      batch.set(studentAccountRef.doc(studentAccount.key), studentAccount);

      // NEW ID NUMBER
      batch.update(settingRef, { puc_id: firebase.firestore.FieldValue.increment(1) });
    }

    // NEW INVOICE NUMBER
    batch.update(settingRef, { invoice_shufit: firebase.firestore.FieldValue.increment(1) });

    const { invoiceKey, totalPrepaid, totalInstallment,
      paymentTuitionFee, grandTotal, totalScholarship,
      totalLoan, penaltyRef, totalPenalty, totalPaymentDiscount } = payParam;
    //===== CLEAR STUDENT INVOICE
    if (invoiceKey === headerKey) {
      batch.update(studentRef.doc(student.key), {
        invoiceKey: null,
      })
    }
    //===== Credit Note
    // if (credit && creditRef && invoiceHeader.enrollment_type && invoiceHeader.enrollment_type.key === enrollmentTypes.academic.key) {
    //   let { key, amount } = creditRef;
    //   const movementRef = this.ds.creditNoteFireRef().doc(key);
    //   const termRef = this.ds.academicsTermFireRef().doc("mOVZMpz5C5uX1SlVyD79").collection("credit_note").doc(key);
    //   const studentRef = this.ds.studentFireRef().doc(student.key);
    //   let isPaid = false;
    //   if (grandTotal - amount >= 0) isPaid = true;
    //   batch.update(movementRef, { isPaid: isPaid, headerRef: invoiceHeader.key });
    //   batch.update(termRef, { isPaid: isPaid, headerRef: invoiceHeader.key });
    //   batch.update(studentRef, {
    //     credit: null,
    //   });
    // }
    // else if (english_credit && english_creditRef && invoiceHeader.enrollment_type && invoiceHeader.enrollment_type.key === enrollmentTypes.institute.key) {
    //   let { key, amount } = english_creditRef;
    //   const movementRef = this.ds.creditNoteFireRef().doc(key);
    //   const termRef = this.ds.academicsTermFireRef().doc("mOVZMpz5C5uX1SlVyD79").collection("credit_note").doc(key);
    //   const studentRef = this.ds.studentFireRef().doc(student.key);

    //   let isPaid = false;
    //   if (grandTotal - amount <= 0) isPaid = true;
    //   batch.update(movementRef, { isPaid: isPaid });
    //   batch.update(termRef, { isPaid: isPaid });
    //   batch.update(studentRef, {
    //     english_credit: null,
    //   });
    // }

    let prepaidRef: IPrepaid = null;
    // let installmentRef: IInstallment = null;
    // if (totalPrepaid && totalPrepaid > 0 && studentPrepaid && studentPrepaid.length > 0) {
    //   studentPrepaid.forEach(p => {
    //     let { issue_term, key } = p;
    //     length += 1;
    //     const data = {
    //       clear_prepaid: true,
    //       clear_prepaid_date: new Date(),
    //       clear_prepaid_date_key: ConvertService.dateKey(),
    //       clear_prepaid_by: userObj(create_by),
    //     }

    //     batch.update(termRef.doc(issue_term.key).collection("prepaid").doc(key), { ...data });
    //     batch.update(studentRef.doc(student.key).collection("prepaid").doc(key), { ...data });
    //   })
    // }
    // if (ConvertService.toNumber(totalInstallment) > 0 || ConvertService.toNumber(totalPrepaid) > 0) {
    //   const prepaidAmount = ConvertService.toNumber(student.prepaid) - totalPrepaid;
    //   // const installmentAmount = ConvertService.toNumber(student.installment) - this.totalInstallment;
    //   batch.update(this.ds.studentFireRef().doc(student.key), {
    //     prepaid: prepaidAmount > 0 ? prepaidAmount : null,
    //     prepaid_credit: null,
    //     prepaid_subject: null,
    //     // installment: installmentAmount > 0 ? installmentAmount : null,
    //     // modified_by: userObj(create_by),
    //     // modified_date: new Date()
    //   })
    // }


    invoiceDetail.forEach(invoice => {
      let courseAmount = invoice.amount;
      let enrollScholarship = null;
      invoice.isPaid = paymentStatus.paid;
      let enrollLoan = null;
      if (invoice.invoice_type.key === invoiceTypesObj.tuitionFee.key) {
        const enrollLength = paymentTuitionFee.length;
        enrollScholarship = ConvertService.toNumber(totalScholarship / enrollLength);
        enrollLoan = ConvertService.toNumber(totalLoan / enrollLength);
        courseAmount = courseAmount - (enrollScholarship + enrollLoan) - toNumber(totalPaymentDiscount);
      } else {
        courseAmount = invoice.amount
      };
      courseAmount = ConvertService.toFloatFixed2(courseAmount);
      const receiveDetailData = {
        amount: courseAmount,
        penaltyRef: ConvertService.toNull(penaltyRef),
        penalty: ConvertService.toNumber(totalPenalty),
        scholarshipRef: ConvertService.toNull(scholarship),
        scholarship: ConvertService.toNull(enrollScholarship),
        loan: ConvertService.toNull(enrollLoan),
        prepaidRef: ConvertService.toNull(prepaidRef),
        prepaid: ConvertService.toNull(totalPrepaid),
        received_by: create_by,
        received_date: date,
        received_date_key: ConvertService.toDateKey(date),
        received_campus: ConvertService.toNull(create_by.campus),
        received_campusKey: create_by.campus.key,
        received_campusRef: this.ds.campusRef().doc(create_by.campus.key).ref,
        dailyShift: shift,
        caseIn: cashIn,
        note: note ? note : ConvertService.toNull(invoiceHeader.note),
        payment_type: paymentType.cash,
        isPaid: paymentStatus.paid,
        invoice_no: invoiceNo,
        payment_year: payment_year,
        student: studentObj(student),

        discount: toNumber(totalPaymentDiscount),
      }

      batch.update(studentRef.doc(student.key).collection("invoices").doc(invoice.key), {
        ...receiveDetailData
      })

      //===== Pay ABC Course
      if (invoice.invoice_type.key === invoiceTypesObj.abcCourse.key) {
        const abcStudent: IAbcStudent = {
          key: this.ds.createId(),
          page_key: ConvertService.pageKey(),
          status: recordStatus.active,
          create_date: new Date(),
          create_by: userObj(create_by),
          first_name: ConvertService.toNull(student.first_name),
          last_name: ConvertService.toNull(student.last_name),
          khmer_last_name: ConvertService.toNull(student.khmer_last_name),
          khmer_first_name: ConvertService.toNull(student.khmer_first_name),
          gender: ConvertService.toNull(student.gender),
          phone: ConvertService.toNull(student.phone),
          dob: ConvertService.toNull(student.dob),
          puc_id: ConvertService.toNull(student.puc_id),
          id_card: null,
          address: null,
          email: ConvertService.toNull(student.email),
          note: null,
          admission_date: new Date(),
          admission_date_key: ConvertService.dateKey(),
          shift: null,
          student: studentObj(student),
          generation: MappingService.generationObj(this.env.puc_generation),
          enrollStatus: EnrollStatus.none,
          isPaidAbc: true,
          isPaid: paymentStatus.paid,
          isAssignSchedule: false,
          campus: ConvertService.toNull(create_by.campus),
          abc_course: this.env.abc,
          admission_term: academicYearObj(this.env.term),
        };
        batch.set(abcStudentRef.doc(abcStudent.key), abcStudent);
        batch.set(termRef.doc(item.payment_term.key).collection("abc_students").doc(abcStudent.key), abcStudent);
      }

      //===== Pay Scholarship Penalty
      if (invoice.invoice_type.key === invoiceTypesObj.scholarshipPenalty.key) {

        const { enrollment_type, issue_term } = invoice;
        if (enrollment_type.key === enrollmentTypes.academic.key) {
          const studentTranRef = this.ds.studentTranscriptFireRef().doc(student.key)
            .collection("admission").doc(admission.key).collection("courses").doc(invoice.key);
          batch.update(studentTranRef, {
            scholarship_penalty_ref: {
              isPaid: paymentStatus.paid,
              headerRef: headerKey,
              received_by: create_by,
              received_date: date,
              received_date_key: ConvertService.toDateKey(date),
              studentKey: student.key
            }
          })

        } else if (enrollment_type.key === enrollmentTypes.institute.key) {
          // const termRef = this.ds.termFireRef().doc(issue_term.key).collection("scholarship_penalty_english").doc(invoice.key);
          // batch.update(termRef, {
          //   isPaid: paymentStatus.paid,
          //   received_by: create_by,
          //   received_date: date,
          //   received_date_key: ConvertService.toDateKey(date),
          //   studentKey: student.key
          // })
        }
      }

    })

    if (invoiceHeader.invoice_type && invoiceHeader.invoice_type.key === invoiceTypesObj.scholarshipPenalty.key) {

      const { enrollment_type, issue_term } = invoiceHeader;
      if (enrollment_type.key === enrollmentTypes.academic.key) {
        batch.update(studentRef.doc(student.key), { scholarship_penalty: 0 });

      } else if (enrollment_type.key === enrollmentTypes.institute.key) {
        // const termHRef = this.ds.termFireRef().doc(issue_term.key).collection("scholarship_penalty_english").doc(invoiceHeader.key);
        // batch.update(termHRef, {
        //   isPaid: paymentStatus.paid,
        //   received_by: create_by,
        //   received_date: date,
        //   received_date_key: ConvertService.toDateKey(date),
        //   studentKey: student.key
        // })
        batch.update(studentRef.doc(student.key), { scholarship_penalty_english: 0 });
      }
    }

    const receiveHeaderData = {
      amount: ConvertService.toFloatFixed2(grandTotal),
      penaltyRef: ConvertService.toNull(penaltyRef),
      penalty: ConvertService.toNumber(totalPenalty),
      scholarshipRef: ConvertService.toNull(scholarship),
      scholarship: ConvertService.toNull(totalScholarship),
      loan: ConvertService.toNull(totalLoan),
      prepaidRef: ConvertService.toNull(prepaidRef),
      prepaid: ConvertService.toNull(totalPrepaid),
      received_by: create_by,
      received_date: date,
      received_date_key: ConvertService.toDateKey(date),
      received_campus: ConvertService.toNull(create_by.campus),
      received_campusKey: create_by.campus.key,
      received_campusRef: this.ds.campusRef().doc(create_by.campus.key).ref,

      dailyShift: shift,
      caseIn: cashIn,
      note: note ? note : ConvertService.toNull(invoiceHeader.note),
      payment_type: paymentType.cash,
      isPaid: paymentStatus.paid,
      invoice_no: invoiceNo,
      payment_year: payment_year,
      student: studentObj(student),

      discount: toNumber(totalPaymentDiscount),
    }

    item.invoiceKey = invoiceHeader.key;
    item.studentKey = student.key;
    batch.set(receivedPaymentRef, item);

    const invoiceCheck = {
      key: headerKey,
      invoiceKey: headerKey,
      studentKey: student.key,
      puc_id: student.puc_id,
      received_by: create_by,
      received_date: date,
      received_date_key: ConvertService.toDateKey(date),
    }
    batch.set(invoiceFailRef.doc(invoiceCheck.key), invoiceCheck);

    //Institute payment fee (Institutes & Center)
    const { byAdmission, school_fee_type } = invoiceHeader;
    if (school_fee_type && school_fee_type.key === 3) {
      if (byAdmission) {//from test
        this.testing.student = studentObj(student);
        this.testing.student.puc_id = puc_id;
        batch.set(scheduleInProgressRef.doc(this.testing.key), {
          ...this.testing,
          student: studentObj(student),
          isPaid: paymentStatus.paid,
        });
        const { target_term } = this.testing;
        receiveHeaderData.payment_year = payment_year
      }
    }
    batch.update(studentRef.doc(student.key).collection("invoices").doc(headerKey), {
      ...receiveHeaderData
    })

    // UPDATE USER BALANCE
    const balanceAmount = ConvertService.toNumber(userDoc.balance) + ConvertService.toNumber(receiveHeaderData.amount);
    batch.update(userRef.doc(userDoc.key), {
      balance: balanceAmount
    })
    batch.update(shiftMovementRef.doc(userDoc.shift.key), {
      balance: balanceAmount
    })

    batch.commit().then(() => {
      this.process = false;
      callback(true, invoiceHeader);
    })
      .catch(error => {
        callback(false, error);
        this.process = false;
      });
  }

  @action
  async testingPayment(item: IReceivedPayment, testingOption: any, testing: any, callback) {
    this.process = true;
    // this.as.updateInvoiceNo(async (success, response) => {
    //   if (success) {
    const batch = this.ds.batch();
    const userData = await this.ds.userRef(item.cashier.key).get().toPromise();
    const userDoc = MappingService.pushToObject(userData);

    const sysData = await this.ds.sysSetting().get().toPromise();
    const sysDoc = MappingService.pushToObject(sysData);

    const settingRef = this.ds.settingFireStore();
    const studentInvoiceRef = this.ds.studentFireRef().doc(testing.student.key).collection("invoices");
    const testingRef = this.ds.testingFireRef();
    const userRef = this.ds.userFireRef();
    const shiftMovementRef = this.ds.shiftMovementFireRef();
    const movementRef = this.ds.receivedPaymentFireRef().doc(item.key);
    const invoiceNo = ConvertService.generate_testing_invoiceNo(sysDoc);
    const expiredFee = this.ds.expiredFeeDate(new Date(), testingOption);
    const { create_date, create_by, payment_year, shift, cashIn, note } = item;
    const date = create_date;

    const invoiceData = {
      received_by: MappingService.userObj(create_by),
      received_date: date,
      received_date_key: ConvertService.toDateKey(date),
      received_campus: MappingService.campusObj(create_by.campus),
      received_campusKey: create_by.campus.key,
      received_campusRef: this.ds.campusRef().doc(create_by.campus.key).ref,
      dailyShift: shift,
      caseIn: cashIn,
      note: note,
      payment_type: paymentType.cash,
      issue_year: payment_year,
      issue_yearKey: payment_year.key,
      issue_yearRef: this.ds.academicYearRef().doc(payment_year.key).ref,
      payment_year: payment_year,
      invoice_no: invoiceNo,
      isPaid: paymentStatus.paid
    }

    let invoiceHeader = this.paymentTesting.filter(m => m.isHeader == true)[0];
    item.invoiceKey = invoiceHeader.key;
    item.studentKey = testing.student.key;
    batch.set(movementRef, item);

    this.paymentTesting.forEach(inv => {
      batch.update(studentInvoiceRef.doc(inv.key), { ...invoiceData })
    })

    batch.update(testingRef.doc(testing.key), {
      isPaidTest: true,
      pay_date: new Date(),
      pay_date_key: ConvertService.dateKey(),
      pay_by: create_by,
      expired_fee: expiredFee,
      expired_fee_key: ConvertService.toDateKey(expiredFee),
    });

    // NEW INVOICE NUMBER
    batch.update(settingRef, { invoice_shufit: firebase.firestore.FieldValue.increment(1) });

    // UPDATE USER BALANCE
    const balanceAmount = ConvertService.toNumber(userDoc.balance) + ConvertService.toNumber(invoiceHeader.amount);
    batch.update(userRef.doc(userDoc.key), {
      balance: balanceAmount
    })
    batch.update(shiftMovementRef.doc(userDoc.shift.key), {
      balance: balanceAmount
    })

    batch.commit().then(() => {
      this.process = false;
      callback(true, invoiceHeader);
    })
      .catch(error => {
        callback(false, error);
        this.process = false;
      });
    //   }
    // })
  }

  @action
  clearPayment() {
    this.totalScholarship = null;
    this.totalLoan = null;
    this.totalPaymentDiscount = null;
    this.paymentDiscountType = null;
    this.totalPrepaid = null;
    this.prepaidRef = null;
    this.totalPenalty = null;
    this.totalDiscount = null;
    this.penaltyRef = null;
    this.totalInstallment = null;
    this.totalPrice = null;
    this.gradePrice = 0;
    this.grandTotal = 0;
    this.totalLate = null;
    this.invoice = null;
    this.invoiceHeader = null;
    this.scholarshipRef = null;
    this.totalScholarShipPenalty = null;
    this.paymentTuitionFee = null;
    this.paymentScholarshipPenalty = null;
    this.paymentMiscellaneous = null;
    this.paymentOtherFee = null;
    this.paymentPOSFee = null;
    this.paymentCapitalFee = null;
    this.totalCapitalFee = null;

    this.paymentFeeWithPeriod = null;
    this.totalFeeWithPeriod = null;
    this.totalScholarshipPenalty = null;
    this.paymentEmpty = true;
    this.totalMiscellaneous = null;
    this.totalScholarshipPenalty = null;
    this.scholarship = null;

    this.specialDiscount = null;
    this.studentTypeDiscount = null;
    this.globalDiscount = null;
    this.scholarshipDiscount = null;
    this.isShowScholarship = true;
    this.isHavePenalty = false;
    this.loading = false;

    this.paymentHeader = null;
    this.paymentDetail = null;
  }

  @action
  async fetchEnrollment(admissionKey: string, studentKey: string, invoiceKey: string, callback) {
    this.clearPayment();
    this.loading = true;
    const settingDoc = await this.ds.sysSetting().get().toPromise();
    const admissionDoc = await this.ds.admissionRef().doc(admissionKey).get().toPromise();
    const studentDoc = await this.ds.studentDocument(studentKey).get().toPromise();
    const paymentDiscountDoc = await this.ds.paymentDiscountRef().get().toPromise();

    const setting = MappingService.pushToObject(settingDoc);
    const admission = MappingService.pushToObject(admissionDoc);
    const student = MappingService.pushToObject(studentDoc);
    const paymentDiscountData = MappingService.pushToArray(paymentDiscountDoc);

    if (admission) {
      const { scholarshipKey } = admission.program_academic;
      if (scholarshipKey) {
        const scholarshipDoc = await this.ds.studentDocument(studentKey).collection("scholarships").doc(scholarshipKey).get().toPromise();
        this.scholarship = MappingService.pushToObject(scholarshipDoc);
      }
    }

    this.fetchPayments(admissionKey, admission, student.key, invoiceKey, async (docs) => {
      let tuitionListing = [];
      this.paymentTuitionFee = [];
      if (docs.length > 0 && !invoiceKey) {
        const snapshot = docs.filter(m => m.isHeader === false && m.invoice_type.key === invoiceTypesObj.tuitionFee.key);
        if (this.paymentHeader.invoice_type.key === invoiceTypesObj.tuitionFee.key) {
          tuitionListing = docs.length > 0 ? docs.filter(m => m.isHeader === false && m.invoice_type.key === invoiceTypesObj.tuitionFee.key) : [];
        } else {
          tuitionListing = snapshot.map(m => {
            const { course, amount } = m;
            const { code } = course.schedule_subject.subject;
            const { payment_type, scheduleType } = course
            const fee = MappingService.getFeeRef(setting, code, course.fee, payment_type, scheduleType);
            const price = MappingService.getForeignerFee(student, setting, fee);
            const grandTotal = price * course.schedule_subject.credit;
            const isResolve = amount !== grandTotal ? true : false;
            const resolveFeeRef = MappingService.feeObj(fee);
            return {
              ...m,
              resolve: isResolve,
              amount: grandTotal,
              grand_total: grandTotal,
              price: price,
              total: grandTotal,
              resolveFeeRef: resolveFeeRef
            };
          })
        }
      }
      else {
        tuitionListing = docs.length > 0 ? docs.filter(m => m.isHeader === false && m.invoice_type.key === invoiceTypesObj.tuitionFee.key) : [];
      }
      if (this.paymentHeader) {
        const { byAdmission, school_fee_type } = this.paymentHeader;
        //pay from testing for English
        if (byAdmission && school_fee_type && school_fee_type.key === 3) {
          const testingDocs = await this.ds.studentTestingRef(studentKey).get().toPromise();
          this.testing = testingDocs.empty ? null : MappingService.pushToArray(testingDocs)[0];
        }
      }

      this.paymentTuitionFee = tuitionListing.length > 0 ? tuitionListing : null;
      const tuitionFee = this.paymentTuitionFee && this.paymentTuitionFee.length > 0 ? this.paymentTuitionFee[0] : null;

      if (tuitionFee && paymentDiscountData && paymentDiscountData.length > 0) {
        const { fee } = tuitionFee;
        const discountBalanceData = paymentDiscountData.filter(m => m.payment_option && m.payment_option.key === fee.paymentOption.key);
        if (discountBalanceData && discountBalanceData.length > 0) {
          const discountBalanceDoc = discountBalanceData[0];
          const discountBalance = discountBalanceDoc.discount;
          const totalPriceFee = MappingService.sum(this.paymentTuitionFee, "amount");
          this.paymentDiscountType = discountBalanceDoc;
          this.totalPaymentDiscount = discountBalanceDoc.discount_type.key === 1 ? (totalPriceFee * toNumber(discountBalance)) / 100 : discountBalance;
        }
      }

      this.paymentScholarshipPenalty = docs.length > 0 ? docs.filter(m => m.isHeader === false && m.invoice_type.key === invoiceTypesObj.scholarshipPenalty.key) : null;
      this.paymentMiscellaneous = docs.length > 0 ? docs.filter(m => m.isHeader === false && (m.invoice_type.key === invoiceTypesObj.miscellaneous.key || m.invoice_type.key === invoiceTypesObj.abcCourse.key)) : null;
      this.paymentOtherFee = docs.length > 0 ? docs.filter(m => m.isHeader === false && (m.invoice_type.key === invoiceTypesObj.otherFee.key)) : null;
      let { prepaid, installment } = student;
      if (prepaid && prepaid > 0) {
        this.totalPrepaid = ConvertService.toNumber(prepaid);
      }
      if (installment && installment > 0) {
        this.totalInstallment = ConvertService.toNumber(installment);
      }
      if (this.paymentHeader) {
        const { invoice_type } = this.paymentHeader;
        this.scholarshipRef = null;
        this.totalScholarship = null;
        this.totalLoan = null;
        if (invoice_type.key === invoiceTypesObj.tuitionFee.key) {
          //=====HAS SCHOLARSHIP FEE UNPAID
          this.scholarshipRef = this.scholarship;
          if (this.paymentHeader) {
            const { scholarshipRef } = this.paymentHeader;
            if (scholarshipRef && scholarshipRef.key === "unpaid") {
              this.scholarshipRef = scholarshipRef;
            }
          }
          if (this.scholarshipRef && this.paymentTuitionFee && this.paymentTuitionFee.length > 0) {
            const totalPriceFee = MappingService.sum(this.paymentTuitionFee, "amount");
            let { totalLoan, totalScholarship } = MappingService.calScholarship(totalPriceFee, this.scholarshipRef)
            this.totalScholarship = totalScholarship;
            this.totalLoan = totalLoan;
          }
        }
      }
      let totalDeduction = ConvertService.toNumber(this.totalScholarship) + ConvertService.toNumber(this.totalLoan);

      //=====HAS PENALTY FEE
      if (this.paymentHeader && this.paymentHeader.penalty && this.paymentHeader.penalty > 0) {
        let { penalty, penaltyRef } = this.paymentHeader;
        this.totalPenalty = penalty;
        this.penaltyRef = penaltyRef;
      }

      //=====HAS DISCOUNT FEE
      if (this.paymentHeader && this.paymentHeader.discount && this.paymentHeader.discount > 0) {
        let { discount } = this.paymentHeader;
        this.totalDiscount = discount;
      }

      //=====HAS SCHOLARSHIP PENALTY FEE
      if (this.paymentScholarshipPenalty) {
        this.totalScholarshipPenalty = MappingService.sum(this.paymentScholarshipPenalty, 'amount');
      }

      //=====HAS MISCELLANEOUS FEE
      if (this.paymentMiscellaneous) {
        this.totalMiscellaneous = MappingService.sum(this.paymentMiscellaneous, 'amount');
      }

      //=====HAS OTHER FEE
      if (this.paymentOtherFee) {
        this.totalOtherFee = MappingService.sum(this.paymentOtherFee, 'amount');
      }

      //=====HAS TUITIONS FEE
      if (this.paymentTuitionFee) {
        this.totalPrice = MappingService.sum(this.paymentTuitionFee, 'amount');
      }

      //===== IS SENIOR PROJECT
      let isSenior = false;
      if (this.paymentTuitionFee) {
        isSenior = this.paymentTuitionFee.filter(m => m.course && m.course.isSenior).length > 0;
        if (isSenior) {
          totalDeduction = 0;
          this.scholarshipRef = null;
          this.totalScholarship = null;
          this.totalLoan = null;
        }
      }

      let deductionAmount = 0;
      if (totalDeduction > this.totalPaymentDiscount) {
        deductionAmount = totalDeduction;
        this.totalPaymentDiscount = 0;
        this.paymentDiscountType = null
      }

      if (totalDeduction < this.totalPaymentDiscount) {
        deductionAmount = this.totalPaymentDiscount;
        this.scholarshipRef = null;
        this.totalScholarship = null;
        this.totalLoan = null;
      }

      this.gradePrice = ConvertService.toNumber(this.totalPrice) - ConvertService.toNumber(deductionAmount) - ConvertService.toNumber(this.totalPrepaid) - ConvertService.toNumber(this.totalDiscount);
      this.grandTotal = this.gradePrice + ConvertService.toNumber(this.totalOtherFee) + ConvertService.toNumber(this.totalMiscellaneous) + ConvertService.toNumber(this.totalScholarshipPenalty) + ConvertService.toNumber(this.totalPenalty);
      this.loading = false;
      callback(this.paymentHeader)
    })
  }

  @action
  async fetchCalPublicHoliday(schoolKey, fromDate, toDate) {
    const data = MappingService.pushToArray(await this.ds.publicHolidayActive(schoolKey, fromDate, toDate).get().toPromise());
    let day = 0;
    if (data && data.length > 0) {
      data.map(m => {
        const { from_date_key, to_date_key } = m;
        day += (to_date_key - from_date_key) + 1
      })
    }
    return day;
  }

  @action
  fetchRequestPenalty(studentKey, callback) {
    this.ds.studentDocument(studentKey).collection("request_penalty", ref => ref
      .where("request_status.key", "==", REQUEST_STATUS.pending.key)).valueChanges().subscribe(docs => {
        callback(docs)
      })
  }

  @action
  fetchRequestVoidReceipt(studentKey, callback) {
    this.ds.studentDocument(studentKey).collection("request_void", ref => ref
      .where("request_status.key", "==", REQUEST_STATUS.pending.key)).valueChanges().subscribe(docs => {
        callback(docs)
      })
  }

  @action
  fetchPendingInstallmentPayment(studentKey, callback) {
    this.studentInstallment = null
    this.ds.studentInstallmentPaymentRef(studentKey).valueChanges().subscribe(docs => {
      const installmentDoc = docs //.filter((m:any) => m.pay_date_key > ConvertService.dateKey())
      this.studentInstallment = installmentDoc && installmentDoc.length > 0 ? installmentDoc[0] : null;
      callback(docs)
    })
  }

  @action
  fetchStudentInstallmentPayment(studentKey, callback) {
    this.ds.studentDocument(studentKey).collection("request_void", ref => ref
      .where("request_status.key", "==", REQUEST_STATUS.pending.key)).valueChanges().subscribe(docs => {
        callback(docs)
      })
  }

  @action
  updateInvoice250(detail: any) {

    const batch = this.ds.batch()
    const studentRef = this.ds.studentFireRef()
    const { student, key, headerRef } = detail

    const data = {
      amount: 250,
      price: 250,
    }

    batch.update(studentRef.doc(student.key).collection("invoices").doc(key), { ...data })
    batch.update(studentRef.doc(student.key).collection("invoices").doc(headerRef), { ...data })
    // batch.commit().then(() => {
    // })

  }

  @action
  fetchStudentPayment(studentKey: string, admissionKey: string, admission: any, invoiceKey: string, callback: any) {
    this.paymentHeader = null;
    this.paymentDetail = null;
    this.paymentEmpty = true;
    let invoices = [];
    this.ds.studentDocument(studentKey).valueChanges().subscribe(student => {
      this.ds.studentInvoiceByAdmission(admissionKey, admission, studentKey, invoiceKey).valueChanges().subscribe(allInv => {

        if (allInv && allInv.length > 0) {
          this.paymentHeader = allInv.filter(m => !m.isVoid && m.isHeader)[0];
          invoices = this.paymentHeader ? allInv.filter(m => !m.isVoid && m.headerRef === this.paymentHeader.key) : [];
          this.paymentDetail = this.paymentHeader ? invoices.filter(m => !m.isHeader) : []
        } else {
          this.paymentHeader = null;
          this.paymentDetail = null;
          this.paymentEmpty = true;
          invoices = [];
        }

        this.paymentEmpty = invoices.length === 0;

        const data = {
          invoices: invoices,
          student: student,
        }
        callback(data);
      })

    })
  }

  @action
  async fetchReceivePayment(studentKey: string, admissionKey: string, invoiceKey: string, callback: any) {
    this.loading = true;
    this.clearPayment();

    const admissionDoc = await this.ds.admissionRef().doc(admissionKey).get().toPromise();
    const admission = MappingService.pushToObject(admissionDoc);

    this.fetchStudentPayment(studentKey, admissionKey, admission, invoiceKey, async (data) => {
      const docs = data.invoices
      const student = data.student

      this.scholarship = null
      this.scholarship = await this.fetchStudentScholarship(admission);

      this.paymentTuitionFee = docs.filter(m => m.isHeader === false && m.invoice_type.key === invoiceTypesObj.tuitionFee.key);
      this.paymentMiscellaneous = docs.filter(m => m.isHeader === false && (m.invoice_type.key === invoiceTypesObj.miscellaneous.key));
      this.paymentOtherFee = docs.filter(m => m.isHeader === false && (m.invoice_type.key === invoiceTypesObj.otherFee.key));
      this.paymentPOSFee = docs.filter(m => m.isHeader === false && (m.invoice_type.key === invoiceTypesObj.posFee.key));

      this.paymentCapitalFee = docs.filter(m => m.isHeader === false && (m.invoice_type.key === invoiceTypesObj.capitalFee.key));
      this.paymentFeeWithPeriod = docs.filter(m => m.isHeader === false && (m.invoice_type.key === invoiceTypesObj.feeWithPeriod.key));

      const tuitionFee = this.paymentTuitionFee && this.paymentTuitionFee.length > 0 ? this.paymentTuitionFee[0] : null;

      this.specialDiscount = null;
      this.studentTypeDiscount = null;
      this.globalDiscount = null;
      this.scholarshipDiscount = null;
      if (tuitionFee) {

        const { issue_year } = tuitionFee
        const academicYearDoc = await this.ds.academicYearRef().doc(issue_year.key).get().toPromise();
        const academicYearData = MappingService.pushToObject(academicYearDoc);

        this.isHavePenalty = await this.checkIsHavePenalty(academicYearData)

        //  HAVE SPECIAL DISCOUNT
        this.specialDiscount = await this.getSpecialDiscount(tuitionFee, academicYearData, student)

        let schoolFeeAmount = tuitionFee.amount

        //  HAVE STUDENT TYPE
        if (student && student.student_type) {
          this.studentTypeDiscount = await this.getStudentType(tuitionFee, schoolFeeAmount, student.student_type, academicYearData)

          if (this.studentTypeDiscount && this.studentTypeDiscount.amount > 0) {
            schoolFeeAmount = schoolFeeAmount - this.studentTypeDiscount.amount;
          }
        }

        if (this.specialDiscount && this.specialDiscount.amount > 0) {
          schoolFeeAmount = schoolFeeAmount - this.specialDiscount.amount;
        }

        this.globalDiscount = await this.getGlobalDiscount(tuitionFee, schoolFeeAmount, student);
        this.scholarshipDiscount = await this.getStudentScholarship(tuitionFee, schoolFeeAmount, this.scholarship, this.paymentHeader ? this.paymentHeader.issue_year : null, student);

      }

      let totalScholarshipDeduct = this.scholarshipDiscount ? (ConvertService.toNumber(this.scholarshipDiscount.amount) + ConvertService.toNumber(this.scholarshipDiscount.amountOther)) : 0;
      if ((this.globalDiscount && ConvertService.toNumber(this.globalDiscount.amount)) > (this.scholarshipDiscount && (ConvertService.toNumber(this.scholarshipDiscount.amount) + ConvertService.toNumber(this.scholarshipDiscount.amountOther)))) {
        totalScholarshipDeduct = ConvertService.toNumber(this.globalDiscount.amount)
        this.isShowScholarship = false;
      }

      let totalDeduction = 0;
      //=====HAS PREPAID 
      if (student && ConvertService.toNumber(student.prepaid) > 0) {
        this.totalPrepaid = ConvertService.toNumber(student.prepaid);
        this.prepaidRef = student.prepaidRef
      }

      totalDeduction = ConvertService.toNumber(this.totalPrepaid);
      //=====HAS DISCOUNT FEE
      this.totalDiscount = null
      if (this.paymentHeader && this.paymentHeader.discount && this.paymentHeader.discount > 0) {
        let { discount } = this.paymentHeader;
        this.totalDiscount = discount;

        if (this.totalDiscount > totalScholarshipDeduct) {
          totalScholarshipDeduct = 0
          this.globalDiscount = null;
          this.scholarshipDiscount = null;
        }
        else this.totalDiscount = null
      }

      //=====HAS PENALTY FEE
      this.totalPenalty = null
      if (this.paymentHeader && this.paymentHeader.penalty && this.paymentHeader.penalty > 0) {
        let { penalty, penaltyRef } = this.paymentHeader;
        this.totalPenalty = penalty;
        this.penaltyRef = penaltyRef;
      }

      //=====HAS MISCELLANEOUS FEE
      if (this.paymentMiscellaneous) {
        this.totalMiscellaneous = MappingService.sum(this.paymentMiscellaneous, 'amount');
      }

      //=====HAS OTHER FEE
      if (this.paymentOtherFee) {
        this.totalOtherFee = MappingService.sum(this.paymentOtherFee, 'amount');
      }

      //=====HAS POS FEE
      if (this.paymentPOSFee) {
        this.totalPOSFee = MappingService.sum(this.paymentPOSFee, 'amount');
      }

      //=====HAS CAPITAL FEE
      if (this.paymentCapitalFee) {
        this.totalCapitalFee = MappingService.sum(this.paymentCapitalFee, 'amount');
      }

      //=====HAS FEE WITH PERIOD
      if (this.paymentFeeWithPeriod) {
        this.totalFeeWithPeriod = MappingService.sum(this.paymentFeeWithPeriod, 'amount');
      }

      //=====HAS TUITIONS FEE
      if (this.paymentTuitionFee) {
        this.totalPrice = MappingService.sum(this.paymentTuitionFee, 'amount');
      }

      // let deductionAmount = 0;
      // if (totalDeduction > this.totalPaymentDiscount) {
      //   deductionAmount = totalDeduction;
      //   this.totalPaymentDiscount = 0;
      //   this.paymentDiscountType = null
      // }

      // if (totalDeduction < this.totalPaymentDiscount) {
      //   deductionAmount = this.totalPaymentDiscount;
      //   this.scholarshipRef = null;
      //   this.totalScholarship = null;
      //   this.totalLoan = null;
      // }

      this.gradePrice = ConvertService.toNumber(this.totalPrice) - ConvertService.toNumber(totalDeduction) - ConvertService.toNumber(totalScholarshipDeduct) - ConvertService.toNumber(this.totalDiscount) - ConvertService.toNumber(this.specialDiscount ? this.specialDiscount.amount : 0) - ConvertService.toNumber(this.studentTypeDiscount ? this.studentTypeDiscount.amount : 0);
      this.grandTotal = this.gradePrice + ConvertService.toNumber(this.totalOtherFee) + ConvertService.toNumber(this.totalPOSFee) + ConvertService.toNumber(this.totalCapitalFee) + ConvertService.toNumber(this.totalFeeWithPeriod) + ConvertService.toNumber(this.totalMiscellaneous) + ConvertService.toNumber(this.totalPenalty);

      this.loading = false;
      callback(this.paymentHeader);
    })
  }

  @action
  async checkIsHavePenalty(academicYearData: any) {
    const { endPaymentKey } = academicYearData
    const currentDateKey = ConvertService.toDateKey(new Date())
    if (endPaymentKey < currentDateKey)
      return true;
    else
      return false
  }

  @action
  async calStudentPenalty(schoolKey, tuitionFee: any) {
    const { issue_year } = tuitionFee
    let penaltyData = null
    const sysData = await this.ds.sysSetting().get().toPromise();
    const sysDoc = MappingService.pushToObject(sysData);

    const academicYearDoc = await this.ds.academicYearRef().doc(issue_year.key).get().toPromise();
    const academicYearData = MappingService.pushToObject(academicYearDoc);

    const { endPaymentKey, endPayment } = academicYearData
    const currentDateKey = ConvertService.toDateKey(new Date())
    if (endPaymentKey < currentDateKey) {
      const { LATE_PAYMENT_FEE } = sysDoc;
      const calcBusinessDays = ConvertService.calcBusinessDays(endPayment.toDate(), new Date())
      const holidayDays = await this.fetchCalPublicHoliday(schoolKey, endPayment.toDate(), new Date())
      const totalDays = ConvertService.toNumber(calcBusinessDays) - ConvertService.toNumber(holidayDays)
      if (totalDays > 0) {
        return penaltyData = {
          total: totalDays,
          amount: totalDays * LATE_PAYMENT_FEE,
          academicYearData: academicYearData,
        }
      }
    }
    return penaltyData;
  }

  @action
  async fetchStudentScholarship(admission: any) {
    let scholarship = null;
    if (admission && admission.program_academic && admission.program_academic.scholarshipKey) {
      const { program_academic, student } = admission;
      const scholarshipDoc = await this.ds.studentDocument(student.key).collection("scholarships").doc(program_academic.scholarshipKey).get().toPromise();
      scholarship = MappingService.pushToObject(scholarshipDoc);
      if (scholarship && scholarship.scholar_status.key === 1)
        scholarship = scholarship;
      else
        scholarship = null;
    }
    return scholarship;
  }

  @action
  getDiscountVoucher(tuitionFee: any, discountVoucherData: any) {
    let discountType = null;
    let discountAmount = null;
    let discountPercentage = null;
    if (tuitionFee) {
      const { amount } = tuitionFee;
      const { discount_type } = discountVoucherData.discount_voucher
      const discount = discountVoucherData.discount_voucher.amount
      discountPercentage = discount;
      discountType = discount_type;
      discountAmount = discount_type.key === 1 ? (amount * toNumber(discount)) / 100 : discount;
    }
    return {
      type: discountType,
      percentage: discountPercentage,
      amount: ConvertService.toFloatFixed2(discountAmount),
    };
  }

  checkIsEwis(student) {
    const { schoolKey } = student
    let value = false
    if (schoolKey === "ewis_school") value = true
    return value;
  }

  @action
  async getGlobalDiscount(tuitionFee: any, schoolFeeAmount: number, student: any) {
    const isNewStudent = tuitionFee && tuitionFee.student_register === "Old" ? DISCOUNT_POLICY_STUDENT_OBJ.old.key : DISCOUNT_POLICY_STUDENT_OBJ.new.key;
    const { fee, program } = tuitionFee;

    const { schoolKey } = student
    const paymentDiscountDoc = await this.ds.storeDocRef(schoolKey).collection("payment_discount", ref => ref.where("programKey", "array-contains", program.program.key)).get().toPromise();
    const paymentDiscountData = MappingService.pushToArray(paymentDiscountDoc);
    let discountType = null;
    let discountAmount = null;
    let discountPercentage = null;
    if (tuitionFee && paymentDiscountData && paymentDiscountData.length > 0) {
      const discountData = paymentDiscountData.filter((m: any) => m.payment_option && m.payment_option.key === fee.paymentOption.key && m.status.key === 1 && m.end_date_key >= ConvertService.dateKey());
      const discountAllStudent = discountData.filter((m: any) => m.discount_student.key === DISCOUNT_POLICY_STUDENT_OBJ.all.key)
      const discountStudent = discountData.filter((m: any) => m.discount_student.key === isNewStudent)
      const discountBalanceData = discountAllStudent && discountAllStudent.length > 0 ? discountAllStudent : discountStudent

      if (discountBalanceData && discountBalanceData.length > 0) {
        const { discount_type, discount } = discountBalanceData[0];
        discountPercentage = discount;
        discountType = discount_type;
        discountAmount = discount_type.key === 1 ? (schoolFeeAmount * toNumber(discount)) / 100 : discount;
      }
    }

    const isEwis = this.checkIsEwis(student)
    return {
      type: discountType,
      percentage: discountPercentage,
      amount: isEwis ? ConvertService.toRoundUpDown(discountAmount) : ConvertService.toFloatFixed2(discountAmount),
    };
  }

  @action
  async getStudentType(tuitionFee: any, schoolFeeAmount: number, student_type: any, selectedAcademicYear) {

    let programPayment = null
    const { endProgramTerm, fee } = tuitionFee
    const program_term = endProgramTerm
    const paymentOption = fee.paymentOption

    let studentDiscount = null

    if (tuitionFee && program_term && paymentOption) {

      if (paymentOption.period === 12) {
        programPayment = PROGRAM_PAYMENT.find(m => m.key === 3)
      } else {

        if (program_term.key === 1 || program_term.key === 2) {
          programPayment = PROGRAM_PAYMENT.find(m => m.key === 1)
        }

        if (program_term.key === 3 || program_term.key === 4) {
          programPayment = PROGRAM_PAYMENT.find(m => m.key === 2)
        }
      }

      const { selectedCampus, selectedSchool } = this.env
      const { academic_year } = selectedAcademicYear

      const studentDiscountDoc = await this.ds.schoolDBRef().doc(selectedSchool.key).collection("campus")
        .doc(selectedCampus.key).collection("school_year").doc(`${academic_year.key}`).collection("student_type").doc(`${student_type.key}`).collection("discount_by_year", ref => ref
          .where("type.key", "==", programPayment.key)
        ).get().toPromise()
      studentDiscount = MappingService.pushToArray(studentDiscountDoc)
    }

    let discountType = null;
    let discountAmount = null;
    let discountPercentage = null;

    if (studentDiscount && studentDiscount.length > 0) {
      const studentDiscountDoc = studentDiscount[0]
      const { discount_type, discount } = studentDiscountDoc

      discountType = discount_type
      discountPercentage = discount
      discountAmount = discount_type.key === 2 ? discount : (schoolFeeAmount * discount) / 100
    }

    return {
      type: discountType,
      percentage: discountPercentage,
      amount: discountAmount,
    };

  }

  @action
  async getSpecialDiscount(tuitionFee: any, academicYearData: any, student: any) {
    const { fee, amount } = tuitionFee;
    const { key } = student
    const { academic_year } = academicYearData

    const paymentDiscountDoc = await this.ds.studentDocument(key).collection("special_discount").get().toPromise();
    const paymentDiscountData = MappingService.pushToArray(paymentDiscountDoc);

    let discountType = null;
    let discountAmount = null;
    let discountPercentage = null;
    if (tuitionFee && paymentDiscountData && paymentDiscountData.length > 0) {
      const discountData = paymentDiscountData.find(m => m.status.key === 1 && m.paymentOption.key === fee.paymentOption.key && m.year && m.year.key === academic_year.key)
      if (discountData) {
        const { discount_type, discount } = discountData;
        discountPercentage = discount;
        discountType = discount_type;
        discountAmount = discount_type.key === 1 ? (amount * toNumber(discount)) / 100 : discount;
      }
    }

    const isEwis = this.checkIsEwis(student)
    return {
      type: discountType,
      percentage: discountPercentage,
      amount: isEwis ? ConvertService.toRoundUpDown(discountAmount) : ConvertService.toFloatFixed2(discountAmount),
    };
  }

  getStartProgramTerm(endProgramTerm, paymentOption) {
    let value = 0
    switch (paymentOption.period) {
      case 3:
        value = endProgramTerm.key
        break;
      case 6:
        value = endProgramTerm.key - 1
        break;
      case 12:
        value = 1
        break;
      default:
        value = 1
        break;
    }

    return PROGRAM_TERMS_OBJ[`term${value}`]
  }

  @action
  async getStudentScholarship(tuitionFee: any, schoolFeeAmount: number, scholarship: any, academicYear: any, student: any) {

    let scholarshipType = null;
    let scholarshipAmount = null;
    let scholarshipPercentage = null;
    let scholarshipCategory = null;
    let scholarshipAcademicOneTime = null;

    let scholarshipTypeOther = null;
    let scholarshipAmountOther = null;
    let scholarshipPercentageOther = null;
    let scholarshipCategoryOther = null;
    if (academicYear && tuitionFee) {

      const { fee } = tuitionFee
      const { paymentOption } = fee

      let endProgramTerm = tuitionFee.endProgramTerm
      let endProgramYear = tuitionFee.endProgramYear

      if (!tuitionFee.endProgramTerm && !tuitionFee.endProgramYear) {
        endProgramTerm = paymentOption.period === 12 ? { key: 4, text: "Term 4" } : { key: 2, text: "Term 2" };
        endProgramYear = { key: 2020, text: "2020-2021" }
      }

      if (scholarship && scholarship.year && scholarship.program_term && scholarship.scholarshipItems && scholarship.scholarshipItems.length > 0) {

        const { year, program_term } = scholarship;

        if (endProgramYear.key === year.key && endProgramTerm.key <= program_term.key) {

          const scholarOtherList = scholarship.scholarshipItems.filter(m => m.scholarship_type.type.key === 2 && m.status.key === 1)
          const scholarOther = scholarOtherList.length > 0 ? ConvertService.getScholarshipOther(scholarOtherList) : null;
          let scholarAcademic = scholarship.scholarshipItems.filter(m => m.scholarship_type.type.key === 1 && m.status.key === 1 && !m.isPaid).length > 0 ? scholarship.scholarshipItems.filter(m => m.scholarship_type.type.key === 1 && m.status.key === 1)[0] : null;

          if (scholarOther) {
            const { discount_type, amount, scholarship_type } = scholarOther;
            const tuitionFeeAmount = schoolFeeAmount;
            scholarshipAmountOther = discount_type.key === 1 ? (tuitionFeeAmount * toNumber(amount)) / 100 : amount;
            scholarshipTypeOther = discount_type;
            scholarshipPercentageOther = amount;
            scholarshipCategoryOther = scholarship_type;
          }

          if (scholarAcademic && scholarAcademic.academic_scholar_year && scholarAcademic.academic_scholar_program_term) {
            const { discount_type, amount, scholarship_type, academic_scholar_year } = scholarAcademic;
            if (endProgramYear.key === academic_scholar_year.key) {
              // if (endProgramYear.key === academic_scholar_year.key && endProgramTerm.key <= academic_scholar_program_term.key) {
              const tuitionFeeAmount = scholarOther ? schoolFeeAmount - scholarshipAmountOther : schoolFeeAmount;
              scholarshipAmount = discount_type.key === 1 ? (tuitionFeeAmount * toNumber(amount)) / 100 : amount;
              scholarshipType = discount_type;
              scholarshipPercentage = amount;
              scholarshipCategory = scholarship_type;
              scholarshipAcademicOneTime = scholarAcademic && scholarAcademic.oneTime ? scholarAcademic : null
            }
          }
        }

        // SPECIAL CASE
        const startProgramTerm = this.getStartProgramTerm(endProgramTerm, paymentOption)
        if (endProgramYear.key === year.key && endProgramTerm.key > program_term.key && program_term.key >= startProgramTerm.key && paymentOption.period > 3) {

          const moreTerm = endProgramTerm.key - program_term.key

          let amountNoDis = 0
          let tuitionFeeAmountNew = schoolFeeAmount

          if (paymentOption.period === 6 && moreTerm === 1) {
            amountNoDis = tuitionFeeAmountNew / 2
            tuitionFeeAmountNew = amountNoDis
          }

          if (paymentOption.period === 12) {
            amountNoDis = tuitionFeeAmountNew / (4 / moreTerm)
            tuitionFeeAmountNew = tuitionFeeAmountNew - amountNoDis
          }

          const scholarOtherList = scholarship.scholarshipItems.filter(m => m.scholarship_type.type.key === 2 && m.status.key === 1)
          const scholarOther = scholarOtherList.length > 0 ? ConvertService.getScholarshipOther(scholarOtherList) : null;
          const scholarAcademic = scholarship.scholarshipItems.filter(m => m.scholarship_type.type.key === 1 && m.status.key === 1 && !m.isPaid).length > 0 ? scholarship.scholarshipItems.filter(m => m.scholarship_type.type.key === 1 && m.status.key === 1)[0] : null;

          if (scholarOther) {
            const { discount_type, amount, scholarship_type } = scholarOther;
            const tuitionFeeAmount = tuitionFeeAmountNew;
            scholarshipAmountOther = discount_type.key === 1 ? (tuitionFeeAmount * toNumber(amount)) / 100 : amount;
            scholarshipTypeOther = discount_type;
            scholarshipPercentageOther = amount;
            scholarshipCategoryOther = scholarship_type;
          }

          if (scholarAcademic && scholarAcademic.academic_scholar_year && scholarAcademic.academic_scholar_program_term) {
            const { discount_type, amount, scholarship_type, academic_scholar_year, academic_scholar_program_term } = scholarAcademic;

            if (endProgramYear.key === academic_scholar_year.key) {

              const tuitionFeeAmount = scholarOther ? tuitionFeeAmountNew - scholarshipAmountOther : tuitionFeeAmountNew;
              scholarshipAmount = discount_type.key === 1 ? (tuitionFeeAmount * toNumber(amount)) / 100 : amount;
              scholarshipType = discount_type;
              scholarshipPercentage = amount;
              scholarshipCategory = scholarship_type;
              scholarshipAcademicOneTime = scholarAcademic && scholarAcademic.oneTime ? scholarAcademic : null
            }
          }
        }

      }
    }

    const isEwis = this.checkIsEwis(student)
    return {
      type: scholarshipType,
      percentage: scholarshipPercentage,
      amount: isEwis ? ConvertService.toRoundUpDown(scholarshipAmount) : ConvertService.toFloatFixed2(scholarshipAmount),
      scholarshipCategory: scholarshipCategory,
      scholarshipAcademicOneTime: scholarshipAcademicOneTime,

      typeOther: scholarshipTypeOther,
      percentageOther: scholarshipPercentageOther,
      amountOther: isEwis ? ConvertService.toRoundUpDown(scholarshipAmountOther) : ConvertService.toFloatFixed2(scholarshipAmountOther),
      scholarshipCategoryOther: scholarshipCategoryOther,
    };
  }

  action
  async markSchoolPayment(item: IReceivedPayment, student: any, header: any, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const userData = await this.ds.userRef(item.cashier.key).get().toPromise();
    const userDoc = MappingService.pushToObject(userData);

    const sysData = await this.ds.sysSetting().get().toPromise();
    const sysDoc = MappingService.pushToObject(sysData);

    const userRef = this.ds.userFireRef();
    const shiftMovementRef = this.ds.shiftMovementFireRef();

    const updateAdmissionStudentIDdRef = this.ds.update_admission_student_idFireRef();
    const studentRef = this.ds.studentFireRef();
    const schoolRef = this.ds.schoolFireRef();
    const studentAccountRef = this.ds.studentAccountDocFire();
    const receivedPaymentRef = this.ds.receivedPaymentFireRef().doc(item.key);
    const settingRef = this.ds.settingFireStore();
    const alignPaymentRef = this.ds.studentAlignPaymentFireRef();

    const headerKey = header.key;
    const invoiceNo = ConvertService.generate_testing_invoiceNo(sysDoc);

    const { create_date, payment_year, shift, cashIn, note, receive_from, campus, campusKey, school, schoolKey, pay_with_check, isPayWithBank, bank_payment, bank_transaction_no, bank_transaction_date, bank_transaction_date_key } = item;
    const { puc_id } = student;
    const create_by = userObj(item.create_by);
    const date = create_date;

    //===== New ID
    if (!puc_id) {
      const pucID = ConvertService.generate_puc_id(sysDoc);
      const emailStudent = pucID + "@gmail.com";
      student.puc_id = pucID;
      student.email = emailStudent;
      const studentAccount: IStudentAccount = {
        key: student.key,
        create_date: new Date(),
        create_by: item.create_by,
        status: recordStatus.active,
        puc_id: pucID,
        email: emailStudent,
        fileUrl: null,
        displayName: student.full_name,
        studentKey: student.key,
        phone: student.mobile_phone,
        token: null,
        pinCode: null,
        student: studentObj(student),
        resetAuth: true,
      };

      batch.update(studentRef.doc(student.key), {
        puc_id: pucID,
        email: emailStudent,
      })
      batch.set(studentAccountRef.doc(studentAccount.key), studentAccount);

      // NEW ID NUMBER
      batch.update(settingRef, { puc_id: firebase.firestore.FieldValue.increment(1) });
    }

    // NEW INVOICE NUMBER
    batch.update(settingRef, { invoice_shufit: firebase.firestore.FieldValue.increment(1) });

    //===== CLEAR STUDENT INVOICE
    if (this.invoiceKey === headerKey) {
      batch.update(studentRef.doc(student.key), {
        invoiceKey: null,
      })
    }

    // DISCOUNT POLICY
    let globalDiscountData = null;
    let scholarshipDiscountData = null;
    let totalScholarshipDeduct = this.scholarshipDiscount ? (ConvertService.toNumber(this.scholarshipDiscount.amount) + ConvertService.toNumber(this.scholarshipDiscount.amountOther)) : 0;
    if ((this.globalDiscount && ConvertService.toNumber(this.globalDiscount.amount)) > (this.scholarshipDiscount && (ConvertService.toNumber(this.scholarshipDiscount.amount) + ConvertService.toNumber(this.scholarshipDiscount.amountOther)))) {
      totalScholarshipDeduct = ConvertService.toNumber(this.globalDiscount.amount)
      globalDiscountData = this.globalDiscount;
    } else {
      scholarshipDiscountData = this.scholarshipDiscount
    }

    // SPECIAL DISCOUNT
    if (this.specialDiscount) {
      totalScholarshipDeduct += this.specialDiscount.amount
    }

    // STUDENT TYPE DISCOUNT
    if (this.studentTypeDiscount) {
      totalScholarshipDeduct += this.studentTypeDiscount.amount
    }

    this.paymentDetail.forEach(invoice => {
      let courseAmount = invoice.amount;
      if (invoice.invoice_type.key === invoiceTypesObj.tuitionFee.key) {
        courseAmount = courseAmount - totalScholarshipDeduct;
        if (this.totalPenalty)
          courseAmount = courseAmount + this.totalPenalty

        if (this.totalPrepaid)
          courseAmount = courseAmount - this.totalPrepaid

        if (this.totalDiscount)
          courseAmount = courseAmount - this.totalDiscount

        if (!puc_id) {
          const updateStudentData = {
            key: this.ds.createId(),
            admissionKey: invoice.program.admissionKey,
            studentKey: invoice.student.key,
            ref: invoice.key,
            headerRef: invoice.headerRef,
            puc_id: student.puc_id,
          }
          batch.set(updateAdmissionStudentIDdRef.doc(updateStudentData.key), updateStudentData);
        }

      } else {
        courseAmount = invoice.amount
      };
      courseAmount = ConvertService.toFloatFixed2(courseAmount);

      let receiveDetailData: any = {
        amount: courseAmount,
        penaltyRef: ConvertService.toNull(this.penaltyRef),
        penalty: ConvertService.toNumber(this.totalPenalty),
        prepaidRef: ConvertService.toNull(this.prepaidRef),
        prepaid: ConvertService.toNull(this.totalPrepaid),
        received_by: create_by,
        received_date: date,
        received_date_key: ConvertService.toDateKey(date),
        received_campus: ConvertService.toNull(create_by.campus),
        received_campusKey: create_by.campus.key,
        received_campusRef: this.ds.campusRef().doc(create_by.campus.key).ref,
        dailyShift: shift,
        caseIn: cashIn,
        note: note,
        receive_from: receive_from,
        isPaid: paymentStatus.paid,
        invoice_no: invoiceNo,
        payment_year: academicYearObj(payment_year),
        student: studentObj(student),

        scholarshipRef: this.scholarship ? this.scholarship.key : null,
        scholarshipProgramRef: this.scholarship ? this.scholarship.scholarshipKey : null,
        specialDiscount: this.specialDiscount,
        studentTypeDiscount: this.studentTypeDiscount,
        globalDiscount: globalDiscountData,
        scholarshipDiscountData: scholarshipDiscountData,

        campusKey: campusKey,
        campus: campus,
        schoolKey: schoolKey,
        school: school,

        pay_with_check,
        isPayWithBank,
        payment_type: bank_payment ? bank_payment : paymentType.cash,
        bank_transaction_no,
        bank_transaction_date,
        bank_transaction_date_key,
      }

      const { admin_fee } = sysDoc;
      if (invoice.course.key === admin_fee.key && invoice.fee.paymentOption.period) {
        const { programTermItems, end_year_date } = payment_year
        const getFirstTerm = programTermItems.find(m => m.key === 1)
        const fromDate = getFirstTerm.program_term_start.toDate();
        const toDate = end_year_date.toDate();

        receiveDetailData.fromDate = fromDate
        receiveDetailData.fromDateKey = ConvertService.toDateKey(fromDate)
        receiveDetailData.toDate = toDate
        receiveDetailData.toDateKey = ConvertService.toDateKey(toDate)
      }

      batch.update(studentRef.doc(student.key).collection("invoices").doc(invoice.key), {
        ...receiveDetailData
      })

      // ADD FEE WITH PERIOD
      if (invoice.invoice_type.key === invoiceTypesObj.feeWithPeriod.key) {
        const periodItem: IFeeWithPeriod = {
          key: this.ds.createId(),
          create_date: new Date(),
          create_date_key: ConvertService.dateKey(),
          create_by: create_by,
          status: recordStatus.active,
          page_key: ConvertService.pageKey(),

          student: studentObj(student),
          course: invoice.course,
          academicYearKey: invoice.issue_year.key,
          admissionKey: invoice.program.admissionKey,
          programKey: invoice.program.program.key,
          levelKey: invoice.program.key,

          price: invoice.course.price,
          amount: invoice.amount,
          invoiceKey: invoice.key,
          headerRef: invoice.headerRef,
          isPaid: true,

          fromDate: invoice.fromDate,
          fromDateKey: invoice.fromDateKey,
          toDate: invoice.toDate,
          toDateKey: invoice.toDateKey,

          campusKey: invoice.campusKey,
          schoolKey: invoice.schoolKey,

        }

        batch.set(studentRef.doc(student.key).collection("student_fee_with_period").doc(periodItem.key), periodItem);
      }

    })

    const receiveHeaderData = {
      amount: ConvertService.toFloatFixed2(this.grandTotal),
      penaltyRef: ConvertService.toNull(this.penaltyRef),
      penalty: ConvertService.toNumber(this.totalPenalty),
      //
      prepaidRef: ConvertService.toNull(this.prepaidRef),
      prepaid: ConvertService.toNull(this.totalPrepaid),
      received_by: create_by,
      received_date: date,
      received_date_key: ConvertService.toDateKey(date),
      received_campus: ConvertService.toNull(create_by.campus),
      received_campusKey: create_by.campus.key,
      received_campusRef: this.ds.campusRef().doc(create_by.campus.key).ref,

      dailyShift: shift,
      caseIn: cashIn,
      note: note,
      receive_from: receive_from,
      isPaid: paymentStatus.paid,
      invoice_no: invoiceNo,
      payment_year: academicYearObj(payment_year),
      student: studentObj(student),

      scholarshipRef: this.scholarship ? this.scholarship.key : null,
      scholarshipProgramRef: this.scholarship ? this.scholarship.scholarshipKey : null,
      specialDiscount: this.specialDiscount,
      studentTypeDiscount: this.studentTypeDiscount,
      globalDiscount: globalDiscountData,
      scholarshipDiscountData: scholarshipDiscountData,

      campusKey: campusKey,
      campus: campus,
      schoolKey: schoolKey,
      school: school,

      pay_with_check,
      isPayWithBank,
      payment_type: bank_payment ? bank_payment : paymentType.cash,
      bank_transaction_no,
      bank_transaction_date,
      bank_transaction_date_key,
    }

    item.invoiceKey = headerKey;
    item.studentKey = student.key;
    batch.set(receivedPaymentRef, item);

    batch.update(studentRef.doc(student.key).collection("invoices").doc(headerKey), {
      ...receiveHeaderData
    })

    // UPDATE SCHOLARSHIP ACADEMIC ONE TIME
    if (scholarshipDiscountData && scholarshipDiscountData.scholarshipAcademicOneTime) {

      const { scholarshipItems } = this.scholarship
      const scholarItem = {
        ...scholarshipDiscountData.scholarshipAcademicOneTime,
        isPaid: true,
        studentKey: student.key,
        headerRef: header.key,
      }
      const scholarshipAcademicItems = pushObjArray(scholarshipItems, scholarItem)

      const scholarshipRef = this.ds.scholarshipFireRef().doc(this.scholarship.scholarshipKey)
      const campusScholarshipRef = this.ds.campusFireRef().doc(campusKey).collection("scholarships").doc(this.scholarship.scholarshipKey);

      batch.update(studentRef.doc(student.key).collection("scholarships").doc(this.scholarship.key), {
        scholarshipItems: scholarshipAcademicItems
      })
      batch.update(scholarshipRef, { scholarshipItems: scholarshipAcademicItems })
      batch.update(campusScholarshipRef, { scholarshipItems: scholarshipAcademicItems })
    }

    // UPDATE DISCOUNT VOUCHER
    if (header && header.discount_voucher_detail_key) {
      const paidVoucherData = {
        isPaid: true,
        student: receiveHeaderData.student,
        received_date: receiveHeaderData.received_date,
        received_date_key: receiveHeaderData.received_date_key,
        received_by: receiveHeaderData.received_by,
      }
      batch.update(schoolRef.doc(receiveHeaderData.schoolKey).collection("payment_discount_voucher_detail").doc(header.discount_voucher_detail_key), { ...paidVoucherData })
    }

    // UPDATE USER BALANCE
    const balanceAmount = ConvertService.toNumber(userDoc.balance) + ConvertService.toNumber(receiveHeaderData.amount);
    batch.update(userRef.doc(userDoc.key), {
      balance: balanceAmount
    })
    batch.update(shiftMovementRef.doc(userDoc.shift.key), {
      balance: balanceAmount
    })

    if (header && header.isAlignPayment && header.invoice_type.key === 2) {
      batch.update(alignPaymentRef.doc(headerKey), {
        isPaid: true,
        note: note,
        received_ref: headerKey,
        received_by: receiveHeaderData.received_by,
        received_date: receiveHeaderData.received_date,
        received_date_key: receiveHeaderData.received_date_key,
        received_campus: ConvertService.toNull(header.campus),
        received_campusKey: header.campus.key,
        received_campusRef: this.ds.campusRef().doc(header.campus.key).ref,
      })
    }

    if (header && header.installmentOldRef) {
      batch.update(alignPaymentRef.doc(header.installmentOldRef), {
        installment_status: INSTALLMENT_PAYMENT_STATUS.completed,

        completed_ref: headerKey,
        completed_by: receiveHeaderData.received_by,
        completed_date: receiveHeaderData.received_date,
        completed_date_key: receiveHeaderData.received_date_key,
      })
    }

    batch.commit().then(() => {
      callback(true, header);
      this.clearPayment();
      this.process = false;
    })
      .catch(error => {
        callback(false, error);
        this.process = false;
      });
  }

  @action
  async addStudentPenalty(tuitionFee, penaltyData, callback) {
    this.process = true;
    const batch = this.ds.batch()
    const { student, headerRef, key } = tuitionFee
    const studentInvoiceRef = this.ds.studentFireRef().doc(student.key).collection("invoices")

    batch.update(studentInvoiceRef.doc(key), {
      penalty: penaltyData.amount
    })
    batch.update(studentInvoiceRef.doc(headerRef), {
      penalty: penaltyData.amount
    })
    // batch.commit().then(() => {
    //   this.process = false;
    //   callback(true, null)
    // }).catch(error => {
    //   this.process = false;
    //   callback(false, error)
    // })
  }

  @action
  async addRequestStudentPenalty(item: any, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const { student, schoolKey, campusKey } = item;

    const studentRef = this.ds.studentFireRef();
    const schoolRef = this.ds.schoolFireRef();

    batch.set(studentRef.doc(student.key).collection("request_penalty").doc(item.key), item)
    batch.set(schoolRef.doc(schoolKey).collection("request_penalty").doc(item.key), item)
    batch.set(schoolRef.doc(schoolKey).collection("campus").doc(campusKey).collection("request_penalty").doc(item.key), item)

    batch.commit().then(() => {
      this.process = false;
      callback(true, null)
    }).catch(error => {
      this.process = false;
      callback(false, error)
    })
  }

  @action
  async removeStudentPenalty(tuitionFee, callback) {
    this.process = true;
    const batch = this.ds.batch()
    const { student, headerRef, key } = tuitionFee
    const studentInvoiceRef = this.ds.studentFireRef().doc(student.key).collection("invoices")

    batch.update(studentInvoiceRef.doc(key), {
      penalty: null
    })
    batch.update(studentInvoiceRef.doc(headerRef), {
      penalty: null
    })

    batch.commit().then(() => {
      this.process = false;
      callback(true, null)
    }).catch(error => {
      this.process = false;
      callback(false, error)
    })
  }

}

