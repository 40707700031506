import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { AuthService } from '../../auth/auth.service';
import { DataService } from '../../services/data.service';
import { Environment } from '../../stores/environment.store';
import { DailyShiftStore } from '../../stores/dailyShift.store';
import { ShiftStatusObj, allocatedStatusObj, recordStatus } from '../../dummy/status';
import { ConvertService } from '../../services/convert.service';
import { DailyShift, ShiftSummary } from '../../interfaces/dailyShift';
import { academicYearObj, MappingService } from 'src/app/services/mapping.service';
import { Report } from 'src/app/stores/report.store';
import { RouterLink, Router } from '@angular/router';

@Component({
  selector: 'app-shift-close',
  templateUrl: './shift-close.component.html',
  styleUrls: ['./shift-close.component.scss']
})
export class ShiftCloseComponent implements OnInit {
  @ViewChild("focusInput") inputEl: ElementRef;

  form: FormGroup
  cash_out: AbstractControl
  cash_out_ref: AbstractControl
  cash_out_memo: AbstractControl

  change: number;
  matchBalance: boolean = true;
  date = new Date();

  receiveBalance: number = 0;
  exchange_rate;
  config;
  
  constructor(
    public dialogRef: MatDialogRef<ShiftCloseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private ds: DataService,
    public env: Environment,
    public store: DailyShiftStore,
    public report: Report,
    private router: Router,
  ) { }

  buildForm(): void {
    this.form = this.fb.group({
      cash_out: [null, [Validators.compose([Validators.required, this.validCashIn.bind(this)])]],
      cash_out_ref: [null,],
      cash_out_memo: [null,],
    })
    this.cash_out = this.form.controls['cash_out'];
    this.cash_out_ref = this.form.controls['cash_out_ref'];
    this.cash_out_memo = this.form.controls['cash_out_memo'];
  }

  public validCashIn(control: AbstractControl): { [s: string]: boolean } {
    const value = control.value;
    this.receiveBalance = ConvertService.toFloatFixed(this.report.balanceClose, 2)
    if (value !== undefined && value !== null && value !== '') {
      this.change = ConvertService.getNumber(value, 0) - this.receiveBalance;
      if (this.change < 0) {
        return { validKey: true }
      }
      if (value !== this.receiveBalance) {
        return { validAmount: true }
      }
    } else {
      this.change = this.receiveBalance;
    }

    if (value !== this.receiveBalance)
      this.matchBalance = false
    else
      this.matchBalance = true
  }

  async ngOnInit() {
    this.inputEl.nativeElement.focus();
    this.buildForm();
    await this.env.fetchUserDoc()

    this.config = await this.env.fetchGeneral()
    if (this.config && this.config.dollar_exchange)
      this.exchange_rate = this.config.dollar_exchange || 4000;

    if (this.env.dailyShift)
      this.report.fetchSummary(this.env.dailyShift);
  }

  create(f: any) {
    if (!this.env.dailyShift || (f.cash_out !== this.receiveBalance)) return;
    if (this.form.valid) {
      this.form.disable();
      const { selectedCampus, selectedSchool } = this.env

      const bankBalance = MappingService.sum(this.report.tuitionFeeWithOther, "amount")
      const dailyShiftData: DailyShift = {
        ...this.env.dailyShift,
        cash_out: f.cash_out,
        cash_out_ref: f.cash_out_ref,
        cash_out_memo: f.cash_out_memo,
        end_date: new Date(),
        end_date_key: ConvertService.dateKey(),
        status: ShiftStatusObj.closed,
        end_cashier: MappingService.userObj(this.env.user),
        isConfirm: true,
        allocated_status: allocatedStatusObj.pending,
        petty_cash: this.env.user.petty_cash,
        installment: this.env.user.installment,
        balance: this.env.user.balance,

        bank_balance: bankBalance,
        exchange_rate: this.exchange_rate,
      }

      let { displayName, email, name, role } = this.env.user
      const shiftSummaryData: ShiftSummary = {
        key: this.ds.createId(),
        displayName: displayName,
        email: email,
        name: name,
        role: role,
        shift: dailyShiftData,
        create_by: MappingService.userObj(this.env.user),
        create_date: new Date(),
        status: recordStatus.active,
        page_key: ConvertService.pageKey(),
        term: academicYearObj(this.env.term),
        cash_in: this.env.dailyShift.cash_in,
        cash_out: f.cash_out,
        petty_cash: this.env.user.petty_cash,
        installment: this.env.user.installment,
        balance: this.env.user.balance,
        received_balance: this.env.user.balance + this.env.user.installment - ConvertService.toNumber(this.env.dailyShift.cash_in) - this.env.user.petty_cash,
        allocated_status: allocatedStatusObj.pending,

        campusKey: selectedCampus.key,
        campus: selectedCampus,
        schoolKey: selectedSchool.key,
        school: selectedSchool,

        bank_balance: bankBalance,
        exchange_rate: this.exchange_rate,

      }

      this.store.shiftClose(dailyShiftData, shiftSummaryData, this.env.user, this.receiveBalance, (success, res) => {
        if (success) {
          this.env.dailyShift = null;
          this.dialogRef.close();
          this.snackBar.open('Close shift successfully', 'done', { duration: 2000 });
          this.form.reset();
          this.form.enable();
          this.router.navigate(['/'])
        } else {
          this.form.enable();
          this.snackBar.open('DailyShift Error! System cancel your transactions.', 'Error')
        }
      })
    }
  }

}
