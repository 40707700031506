import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paidWithText'
})
export class PaidWithTextRefPipe implements PipeTransform {

  transform(value: any, isCheck: boolean): any {
    let result = null

    switch (value.key) {
      case 1:
        result = `Paid with Cash`
        break;
      case 2:
        result = `Paid with Bank: Wing`
        break;
      case 3:
        result = `Paid with Bank: Wing`
        break;
      case 4:
        result = `Paid with Bank: ABA`
        break;
      case 5:
        result = `Paid with Bank: ACLEDA`
        break;
      case 6:
        result = `Paid with Bank: TrueMoney`
        break;
      default:
        result = `Paid with Cash`
        break;
    }

    if (isCheck && isCheck === true) {
      result = `Paid with Check`
    }

    return result
  }
}

@Pipe({
  name: 'paidWithTextShort'
})
export class PaidWithTextShortRefPipe implements PipeTransform {

  transform(value: any, isCheck: boolean): any {
    let result = null

    switch (value.key) {
      case 1:
        result = `Cash`
        break;
      case 2:
        result = `Wing`
        break;
      case 3:
        result = `Wing`
        break;
      case 4:
        result = `ABA`
        break;
      case 5:
        result = `ACLEDA`
        break;
      case 6:
        result = `TrueMoney`
        break;
      default:
        result = `Cash`
        break;
    }

    if (isCheck && isCheck === true) {
      result = `Check`
    }

    return result
  }
}
