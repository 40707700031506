import { DataService } from "./../services/data.service";
import { observable, computed, action, autorun } from "mobx";
import { Injectable } from "@angular/core";
import { LocalStorage } from "@ngx-pwa/local-storage";
import { MappingService } from "../services/mapping.service";
import { ConvertService } from "../services/convert.service";
import { invoiceTypesObj } from "../dummy/status";

@Injectable()
export class ScholarPenalty {
    @observable public invoice = null;
    @observable public invoiceHeader = null;
    @observable public receiptHeader = null;
    @observable public totalTuitionFee = null;
    @observable public totalTestingFee = null;
    @observable public totalSubject = null;
    @observable public otherFee = null;
    @observable public invoiceKey = null;

    @observable public student = null;
    @observable public filterType = null;
    @observable public data = null;

    @observable public loading = false;
    @observable public process = false;
    @observable public empty = false;

    @observable public header = null;
    @observable public detail = null;
    @observable public totalPrice = null;
    @observable public totalMiscellaneous = null;
    @observable public totalScholarshipPenalty = null;
    @observable public gradePrice = null;
    @observable public subtotal = null;
    @observable public enroll = null;
    @observable public enrollIncluded = null;
    @observable public enrollExcluded = null;

    @observable public studentScholarship = null;
    @observable public totalScholarship = null;
    @observable public totalLoan = null;
    @observable public totalPrepaid = null;
    @observable public prepaidRef = null;
    @observable public totalInstallment = null;
    @observable public remainingInstallment = null;
    @observable public totalPenalty = null;
    @observable public penaltyRef = null;
    @observable public grandTotal = null;
    @observable public totalLate = null
    @observable public draft = null;
    @observable public scholarshipPenalty = null;
    @observable public miscellaneousFee = null;
    @observable public activeTerm = null;
    @observable public scholarshipRef = null;
    @observable public installmentRef = null;
    @observable public installment = null;
    @observable public totalDraft = [];
    @observable public totalScholarShipPenalty = [];
    @observable public paymentEmpty = false;
    @observable public paymentHeader = null;
    @observable public paymentDetail = null;
    @observable public paymentTesting = null;
    @observable public paymentTuitionFee = null;
    @observable public paymentScholarshipPenalty = null;
    @observable public paymentMiscellaneous = null;

    constructor(private ds: DataService) {
    }

    @action
    fetchPayments(admissionKey: string, admission, student: any, invoiceKey, callback) {
        this.loading = true;
        this.paymentEmpty = true;
        this.paymentHeader = null;
        this.paymentDetail = [];
        this.ds.studentInvoiceByAdmission(admissionKey, admission, student.key, invoiceKey).valueChanges().subscribe(docs => {
            let invoices = []
            let { scholarship_penalty_ref, scholarship_penalty_english_ref } = student;
            if (scholarship_penalty_ref) {
                this.paymentHeader = docs.length > 0 ? docs.filter(m => m.isHeader === true && m.headerRef === scholarship_penalty_ref.scholarship_penalty_key)[0] : null;
                this.paymentDetail = docs.length > 0 ? docs.filter(m => m.isHeader === false && m.headerRef === scholarship_penalty_ref.scholarship_penalty_key) : [];
                this.paymentEmpty = this.paymentDetail.length === 0;
                invoices = docs.filter(m => m.headerRef === scholarship_penalty_ref.scholarship_penalty_key);
            }else if(scholarship_penalty_english_ref){
                this.paymentHeader = docs.length > 0 ? docs.filter(m => m.isHeader === true && m.headerRef === scholarship_penalty_english_ref.scholarship_penalty_key)[0] : null;
                this.paymentDetail = docs.length > 0 ? docs.filter(m => m.isHeader === false && m.headerRef === scholarship_penalty_english_ref.scholarship_penalty_key) : [];
                this.paymentEmpty = this.paymentDetail.length === 0;
                invoices = docs.filter(m => m.headerRef === scholarship_penalty_english_ref.scholarship_penalty_key);
            }
            this.loading = false;
            callback(invoices);
        })
    }

    clearStore() {
        this.grandTotal = null;
        this.invoice = null;
        this.invoiceHeader = null;
        this.paymentMiscellaneous = null;
        this.paymentEmpty = false;
        this.totalMiscellaneous = null;
        this.loading = true;
        this.totalScholarship = null;
        this.totalLoan = null;
        this.totalPrepaid = null;
        this.totalPenalty = null;
        this.penaltyRef = null;
        this.totalInstallment = null;
        this.totalPrice = null;
        this.gradePrice = 0;
        this.grandTotal = null;
        this.totalLate = null;
        this.invoice = null;
        this.invoiceHeader = null;
        this.scholarshipRef = null;
        this.totalScholarShipPenalty = null;
        this.paymentTuitionFee = null;
        this.paymentScholarshipPenalty = null;
        this.paymentMiscellaneous = null;
        this.totalScholarshipPenalty = null;
        this.paymentEmpty = false;
        this.totalMiscellaneous = null;
        this.totalScholarshipPenalty = null;
        this.totalPenalty = null;
        this.totalScholarship = null;
        this.paymentTuitionFee = [];
    }

    @action
    async fetchEnrollment(admissionKey, admission, studentKey, invoiceKey) {
        this.loading = true;
        this.clearStore()
        const studentDoc = await this.ds.studentDocument(studentKey).get().toPromise();
        const student = MappingService.pushToObject(studentDoc);
        this.fetchPayments(admissionKey, admission, student, invoiceKey, (docs) => {
            this.paymentTuitionFee = docs.length > 0 ? docs.filter(m => m.isHeader === false && m.invoice_type.key === invoiceTypesObj.scholarshipPenalty.key) : null;
            //=====HAS TUITIONS FEE
            if (this.paymentTuitionFee) {
                this.totalPrice = MappingService.sum(this.paymentTuitionFee, 'amount');
            }
            this.grandTotal = ConvertService.toNumber(this.totalPrice);
            this.loading = false;
        })
    }

    @action
    async removeScholarPenalty(item: any, student: any, oldInvoice: any, callback) {
        this.process = true;
        const batch = this.ds.batch();
        const studentInvoiceRef = this.ds.studentFireDocument(student.key);

        const studentDoc = await this.ds.studentDocument(student.key).get().toPromise();
        const studentData = MappingService.pushToObject(studentDoc);

        if (this.paymentDetail.length === 1) {
            batch.delete(studentInvoiceRef.collection("invoices").doc(oldInvoice.key));
        }
        else {
            batch.update(studentInvoiceRef.collection("invoices").doc(oldInvoice.key), {
                price: oldInvoice.price - item.price,
                amount: oldInvoice.amount - item.amount,
            })
        }

        const { scholarship_penalty } = studentData;
        
        batch.update(studentInvoiceRef, {
            scholarship_penalty: scholarship_penalty - item.amount,
        })
        batch.delete(studentInvoiceRef.collection("invoices").doc(item.key));

        batch.commit().then(() => {
            this.process = false;
            callback(true, null);
        }).catch(error => {
            this.process = false;
            callback(false, error);
        })
    }


}
