import { AngularFirestore } from "@angular/fire/firestore";
import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef
} from "@angular/core";
import {
  FormGroup,
  AbstractControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from "@angular/material";
import { Payment } from "src/app/stores/payment.store";
import { Student } from "src/app/stores/student.store";
import { Environment } from "src/app/stores/environment.store";
import { ConvertService } from "src/app/services/convert.service";
import { IInstallment } from "src/app/interfaces/installment";
import { recordStatus } from "src/app/dummy/status";
import { academicYearObj, MappingService } from "src/app/services/mapping.service";

@Component({
  selector: 'app-student-installment',
  templateUrl: './student-installment.component.html',
  styleUrls: ['./student-installment.component.scss']
})
export class StudentInstallmentComponent implements OnInit {
  @ViewChild("focusInput")
  inputEl: ElementRef;

  form: FormGroup;
  price: AbstractControl;
  note: AbstractControl;

  grandTotal: number = 0;

  constructor(
    public dialogRef: MatDialogRef<StudentInstallmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    public store: Payment,
    private db: AngularFirestore,
    private env: Environment,
    public studentStore: Student,
  ) { }

  buildForm(): void {
    this.form = this.fb.group({
      price: ["", [Validators.compose([Validators.required, this.calPrice.bind(this)])]],
      note: [null,]
    });
    this.price = this.form.controls["price"];
    this.note = this.form.controls["note"];

  }

  public calPrice(control: AbstractControl): { [s: string]: boolean } {
    const value = control.value;
    if (value !== undefined && value !== null && value !== "") {
      if (value < 0) {
        return { validAmount: true }
      } else {
        this.grandTotal = this.studentStore.installment + value;
      }
    }
  }

  ngOnInit() {
    this.buildForm();
  }

  _save(f: any) {
    if (f.price < 0) return;
    if (this.form.valid) {
      this.form.disable();
      const item: IInstallment = {
        key: this.db.createId(),
        price: f.price,
        note: f.note,
        isInstallment: true,
        status: recordStatus.active,
        page_key: ConvertService.pageKey(),
        create_date: new Date(),
        create_by: MappingService.userObj(this.env.user),
        received_by: MappingService.userObj(this.env.user),
        received_date: new Date(),
        received_date_key: ConvertService.dateKey(),
        student: MappingService.studentObj(this.studentStore.student),
        term: academicYearObj(this.env.term),
        campus: MappingService.campusObj(this.env.campus),
        shift: MappingService.dailyShiftObj(this.env.dailyShift),
      };

      this.store.addInstallment(item, this.studentStore.student, this.env.user, this.env.dailyShift, (success, res) => {
        if (success) {
          this.dialogRef.close('yes');
          this.form.enable();
          this.snackBar.open("Deposit have been set up  successful.", "done", {
            duration: 2000
          });
        } else {
          this.form.enable();
          this.snackBar.open(res, "Error");
        }
      });
    }
  }
}
