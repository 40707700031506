import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateTime'
})
export class DateTimePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if(!value) return;
    return moment(value).format('DD MMM YYYY, hh:MM:ss a');
  }

}
