import { MatCheckboxChange, MatSnackBar } from '@angular/material';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { Student } from 'src/app/stores/student.store';
import { Payment } from 'src/app/stores/payment.store';
import { Environment } from 'src/app/stores/environment.store';
import { academicYearObj, campusObj, MappingService, paymentOptionObj, pushObjArray, schoolObj, studentObj, toNumber, userObj } from 'src/app/services/mapping.service';
import { DataService } from 'src/app/services/data.service';
import { Invoice } from 'src/app/stores/invoice.store';
import { Registration } from 'src/app/stores/registration.store';
import { BaseStore } from 'src/app/stores/base.store';
import { INSTALLMENT_PAYMENT_STATUS, PROGRAM_TERMS, PROGRAM_TERMS_OBJ, recordStatus, SCHOOL_FEE_PAYMENT } from 'src/app/dummy/status';
import { ConvertService } from 'src/app/services/convert.service';
import { IAlignPayment } from 'src/app/interfaces/invoice';
import { CONFIG } from 'src/app/dummy/config';

@Component({
  selector: 'app-add-installment-payment',
  templateUrl: './add-installment-payment.component.html',
  styleUrls: ['./add-installment-payment.component.scss']
})
export class AddInstallmentPaymentComponent implements OnInit {
  form: FormGroup;
  amount: AbstractControl;
  note: AbstractControl;
  pay_date: AbstractControl;

  selectedAcademicYear;
  programTermList = PROGRAM_TERMS;
  currentTerm;
  constructor(
    public dialogRef: MatDialogRef<AddInstallmentPaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    public store: Registration,
    public ds: DataService,
    public env: Environment,
    public studentStore: Student,
    public invoice: Invoice,
    public baseStore: BaseStore,
    public payment: Payment,
  ) { }

  buildForm(): void {
    this.form = this.fb.group({
      amount: [null, [Validators.compose([Validators.required, this.validPaymentAmount.bind(this)])]],
      pay_date: [new Date(), Validators.required],
      note: [null],
    })
    this.amount = this.form.controls["amount"];
    this.pay_date = this.form.controls["pay_date"];
    this.note = this.form.controls["note"];

  }

  change1 = 0
  remainingAmount = 0
  public validPaymentAmount(control: AbstractControl): { [s: string]: boolean } {
    const value = control.value;
    if (value !== undefined && value !== null && value !== "") {
      this.change1 = ConvertService.getNumber(value, 0) - this.remainingAmount;
      if (this.change1 > 0) {
        return { validKey: true };
      }
      if (value <= 0) {
        return { validAmount: true }
      }
    } else {
      this.change1 = 0 - this.remainingAmount
    }
  }

  currentYear: any;
  paymentOptionData = [];
  paymentOptionList = [];
  paymentList = [];
  trainingLevelFee = [];
  trainingLevel = null;
  optionData = null;
  studentData = null;
  oldInvoice = null;
  isPaidComplete = false;
  process = true;
  paymentStudentData;

  haveStudentDiscount = false;

  academicYearList = []
  totalFee = 0;

  invoiceHeader = null
  async ngOnInit() {
    this.buildForm();
    const { campus, program_academic, student } = this.studentStore.selectedAdmission;

    await this.env.fetchUserDoc()
    const { ITEM } = this.data
    this.remainingAmount = ITEM.remainingAmount

    this.invoiceHeader = await this.payment.studentInvoiceHeader(ITEM)

    const { selectedSchool } = this.env
    // const envData: any = await this.env.fetchAcademicEnv(selectedSchool.key);
    // this.studentData = await this.baseStore.fetchDoc(this.ds.studentDocument(student.key));

    this.optionData = await this.baseStore.fetchDoc(this.ds.settingDBFireStore());
    this.paymentOptionData = await this.baseStore.fetchList(this.ds.paymentOptionRef());
    this.paymentOptionList = this.paymentOptionData.filter(m => m.key === CONFIG.paymentYearKey)

    this.trainingLevelFee = await this.baseStore.fetchList(this.ds.campusRef().doc(campus.key).collection("training_levels").doc(program_academic.key).collection("training_school_fee"))
    this.trainingLevel = await this.baseStore.fetchDoc(this.ds.campusRef().doc(campus.key).collection("training_levels").doc(program_academic.key));

    this.selectedAcademicYear = MappingService.pushToObject(await this.ds.academicYearRef().doc(this.invoiceHeader.issue_year.key).get().toPromise())

    this.getPaymentList(this.paymentOptionList[0])

    if (this.payment.paymentTuitionFee && this.payment.paymentTuitionFee.length > 0) {
      this.oldInvoice = this.payment.paymentTuitionFee[0];
    }
    this.process = false;
  }

  displayItemText(item: any): string {
    return item ? item.text : item;
  }

  getPaymentList(value) {
    this.paymentList = [];
    const { admin_fee, tuition_fee, payment_option_full_year } = this.optionData;

    if (this.trainingLevel && this.trainingLevel.pay_full_admin_fee) {

      const tuitionFeeData = this.trainingLevelFee.filter(m => m.paymentOption.key === value.key && m.fee.key === tuition_fee.key);
      if (tuitionFeeData && tuitionFeeData.length > 0)
        this.paymentList.push(tuitionFeeData[0]);

      const adminFeeData = this.trainingLevelFee.filter(m => m.paymentOption.key === payment_option_full_year.key && m.fee.key === admin_fee.key);
      const adminFeePeriod = this.paymentStudentData && this.paymentStudentData.adminFeePeriod ? this.paymentStudentData.adminFeePeriod : 0;
      if (adminFeeData && adminFeeData.length > 0 && toNumber(adminFeePeriod) < 12)
        this.paymentList.push(adminFeeData[0]);
    } else {

      const tuitionFeeData = this.trainingLevelFee.filter(m => m.paymentOption.key === value.key && m.fee.key === tuition_fee.key);
      if (tuitionFeeData && tuitionFeeData.length > 0)
        this.paymentList.push(tuitionFeeData[0]);

      const adminFeeData = this.trainingLevelFee.filter(m => m.paymentOption.key === value.key && m.fee.key === admin_fee.key);
      const adminFeePeriod = this.paymentStudentData && this.paymentStudentData.adminFeePeriod ? this.paymentStudentData.adminFeePeriod : 0;

      if (adminFeeData && adminFeeData.length > 0 && toNumber(adminFeePeriod) < 12)
        this.paymentList.push(adminFeeData[0]);

    }
  }

  create(f: any) {
    if (this.form.valid) {
      this.form.disable();

      const { selectedCampus, selectedSchool } = this.env
      const { amount, pay_date, note } = f
      const { issue_year, fromDate, toDate, isOneTimePayment } = this.invoiceHeader
      const { trainingGrade } = this.studentStore.selectedAdmission;
      const itemKey = this.ds.createId()

      const { ITEM } = this.data

      const item: IAlignPayment = {
        key: itemKey,
        create_date_key: ConvertService.dateKey(),
        create_date: new Date(),
        create_by: userObj(this.env.user),
        status: recordStatus.active,

        student: studentObj(this.studentStore.student),
        programLevel: trainingGrade,
        admissionKey: this.studentStore.selectedAdmission.key,
        headerRef: itemKey,
        installmentOldRef: this.invoiceHeader.key,
        isPaid: false,

        academicYear: academicYearObj(issue_year),
        paymentOption: paymentOptionObj(this.paymentOptionList[0]),
        fromDate,
        fromDateKey: ConvertService.toDateKey(fromDate.toDate()),
        toDate,
        toDateKey: ConvertService.toDateKey(toDate.toDate()),
        amount,
        amount_request: ITEM.amount_request,
        isOneTimePayment: false,
        oldAmount: MappingService.sum(this.paymentList, "amount"),

        payment_status: SCHOOL_FEE_PAYMENT.installment,
        note,

        remainingAmount: this.remainingAmount - amount,
        pay_date: pay_date,
        pay_date_key: ConvertService.toDateKey(pay_date),
        installment_status: INSTALLMENT_PAYMENT_STATUS.pending,
        isHeader: false,

        campusKey: selectedCampus.key,
        campus: campusObj(selectedCampus),
        schoolKey: selectedSchool.key,
        school: schoolObj(selectedSchool)
      }

      const { tuition_fee } = this.optionData;
      let tuitionFee = this.paymentList.find(m => m.fee.key === tuition_fee.key)
      tuitionFee.amount = isOneTimePayment ? this.remainingAmount : amount
      this.paymentList = pushObjArray(this.paymentList, tuitionFee)

      f.fromDate = fromDate.toDate()
      f.toDate = toDate.toDate()
      f.paymentOption = this.paymentOptionList[0]

      this.store.addSchoolFee(selectedCampus, selectedSchool, this.payment.invoiceKey, f, PROGRAM_TERMS[0], this.paymentList, this.selectedAcademicYear, this.studentStore.student, this.studentStore.selectedAdmission, this.env.user, this.optionData, this.oldInvoice, item, (success, error) => {
        if (success) {
          this.snackBar.open("School Fee has been added.", "Done", { duration: 2000 })
          this.dialogRef.close('yes');
          this.form.enable();
        }
        else {
          this.snackBar.open(error, "Error");
          this.form.enable();
        }
      })

    }
  }
}