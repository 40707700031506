import { AbstractControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { reportFilterBy } from '../../services/data.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { PrintService } from '../../services/print.service';
import { FireStoreService } from 'src/app/services/utils.lib';
import { Environment } from 'src/app/stores/environment.store';
import { SCHOOL } from 'src/app/dummy/config';

@Component({
  selector: 'app-listing-report',
  templateUrl: './listing-report.component.html',
  styleUrls: ['./listing-report.component.scss']
})
export class ListingReportComponent implements OnInit {

  form: FormGroup;
  fromDate: AbstractControl;
  toDate: AbstractControl;
  invoiceStatus: AbstractControl;

  listing: Array<any>;
  process = false;
  school=SCHOOL

  Filterby = reportFilterBy;
  Filterby_selected: any;
  isCustom = false;
  total: number;
  user: any;
  constructor(
    private fb: FormBuilder,
    private ps: PrintService,
    private afs: AngularFirestore,
    private FSS: FireStoreService,
    public env: Environment
  ) {

    const fdb = this.afs.firestore;
    const ref = fdb.collection('received_payment_movement')
      .where('shift.key', '==', "s44GJGeeZduKi0IC6alz")
    ref.get().then(docs => {
      const data = [];
      docs.forEach(doc => {
        data.push({ ...doc.data() });
      });
      const array = data
      this.total = array.reduce((acc, cur) => acc + Number(cur.invoice.amount), 0);
    });
    this.Filterby_selected = this.Filterby[1];
    this.form = this.fb.group({
      Filterby: [this.Filterby_selected, [Validators.required]],
      fromDate: [moment().startOf('month').toDate(), Validators.required],
      toDate: [moment().endOf('month').toDate(), Validators.required],
      // invoiceStatus: [null,],
    });

    this.fromDate = this.form.controls['fromDate'];
    this.toDate = this.form.controls['toDate'];
    // this.invoiceStatus = this.form.controls['invoiceStatus'];
  }
  currentDateString = this.FSS.covertDatetoString(new Date(), 'YYYYMMDD');

  selectFilter(event) {
    const scase = event.value.key;
    switch (scase) {
      case 1:
        this.form.patchValue({
          fromDate: moment().startOf('day').toDate(),
          toDate: moment().endOf('day').toDate(),
        });
        this.isCustom = true;
        break;
      case 2:
        this.form.patchValue({
          fromDate: moment().startOf('day').toDate(),
          toDate: moment().endOf('day').toDate(),
        });
        break;
      case 3:
        this.form.patchValue({
          fromDate: moment().startOf('month').toDate(),
          toDate: moment().endOf('month').toDate(),
        });
        break;
      case 4:
        this.form.patchValue({
          fromDate: moment().startOf('year').toDate(),
          toDate: moment().endOf('year').toDate(),
        });
        break;
      default:
        break;
    }
  }

  dateChange(event) {
    this.Filterby_selected = this.Filterby[0];
  }

  getTestReport() {
    this.process = true;
    const fdb = this.afs.firestore;
    fdb.settings({ timestampsInSnapshots: true });
    const start = moment(this.fromDate.value).format('YYYYMMDD');
    const end = moment(this.toDate.value).format('YYYYMMDD');
    const ref = fdb.collection('invoices')
      .where('isHeader', '==', true)
      // .where('dailyShift.key', '==', this.user.shift.key)
      .where('received_by.key', '==', this.user.key)
      .where('received_date_key', '>=', Number(start))
      .where('received_date_key', '<=', Number(end))
      .orderBy('received_date_key');
    ref.get().then(docs => {
      const data = [];
      docs.forEach(doc => {
        data.push({ ...doc.data() });
      });
      this.listing = data;
      this.total = this.listing.reduce((acc, cur) => acc + Number(cur.amount), 0);

      // this.total = this.listing.reduce((acc, cur) => acc + Number(cur.sub_total), 0);
      this.process = false;
    });

  }
  runReport(f: any) {
    this.getTestReport();
  }
  print() {
    this.ps.print('#print', 'a4');
  }

  ngOnInit() {
    this.env.getUser(user => {
      if (user && user.shift) {
        this.user = user;
        this.getTestReport();
      }

    })
  }
  getStatus(item) {
    // let message = '';
    // // tslint:disable-next-line:max-line-length
    // if (((item.expired_fee_key < this.currentDateString && item.resultTest == null) || item.expired_test_key < this.currentDateString) && item.isPaidTest && item.expired_fee_key !== null) {
    //   message = 'Expired';
    // } else {
    //   message = item.testingStatus.text;
    // }
    // return message;
  }

}
