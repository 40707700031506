import { ConvertService } from 'src/app/services/convert.service';
import { MatSnackBar } from '@angular/material';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { Student } from 'src/app/stores/student.store';
import { Payment } from 'src/app/stores/payment.store';
import { Environment } from 'src/app/stores/environment.store';
import { academicYearObj, campusObj, MappingService, schoolObj, toNumber } from 'src/app/services/mapping.service';
import { DataService } from 'src/app/services/data.service';
import { Invoice } from 'src/app/stores/invoice.store';
import { Registration } from 'src/app/stores/registration.store';
import { BaseStore } from 'src/app/stores/base.store';
import { DISCOUNT_TYPES, enrollPrograms, invoiceTypesObj, MONTHS_PERIOD, paymentStatus, PROGRAM_TERMS } from 'src/app/dummy/status';
import { IInvoice } from 'src/app/interfaces/invoice';
import { ClearPaymentStore } from 'src/app/stores/clearPayment.store';

@Component({
  selector: 'app-add-lunch-fee',
  templateUrl: './add-lunch-fee.component.html',
  styleUrls: ['./add-lunch-fee.component.scss']
})
export class AddLunchFeeComponent implements OnInit {
  form: FormGroup;
  academic_year: AbstractControl;
  lunch_fee: AbstractControl;
  month_period: AbstractControl;
  fromDate: AbstractControl;
  toDate: AbstractControl;
  alignPayment: AbstractControl;
  matchTermDate: AbstractControl;
  program_term: AbstractControl;
  addDay: AbstractControl;

  monthPeriodList = MONTHS_PERIOD

  totalFee: number;
  totalDay: number;

  isMatchTerm = false
  programTermList = PROGRAM_TERMS;
  allowAddDay = false
  holidayDays;
  constructor(
    public dialogRef: MatDialogRef<AddLunchFeeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    public store: Registration,
    public ds: DataService,
    public env: Environment,
    public studentStore: Student,
    public invoice: Invoice,
    public baseStore: BaseStore,
    public payment: Payment,
    public clearPaymentStore: ClearPaymentStore
  ) { }

  buildForm(): void {
    this.form = this.fb.group({
      academic_year: [null, Validators.compose([Validators.required, MappingService.validSelected.bind(this)])],
      lunch_fee: [null, Validators.compose([Validators.required, MappingService.validSelected.bind(this)])],
      month_period: [null, Validators.compose([Validators.required, MappingService.validSelected.bind(this)])],
      // discount_type: [DISCOUNT_TYPES[0]],
      // discount: [null, [Validators.compose([this.validCashIn.bind(this)])]],

      fromDate: [null, Validators.required],
      toDate: [null, Validators.required],

      alignPayment: [false,],
      matchTermDate: [null,],
      program_term: [PROGRAM_TERMS[0], Validators.compose([MappingService.validSelected.bind(this)])],

      addDay: [null, [Validators.compose([this.validCashIn.bind(this)])]],

    })
    this.addDay = this.form.controls['addDay'];
    this.academic_year = this.form.controls['academic_year'];
    this.lunch_fee = this.form.controls['lunch_fee'];
    this.month_period = this.form.controls['month_period'];

    this.fromDate = this.form.controls["fromDate"];
    this.toDate = this.form.controls["toDate"];

    this.alignPayment = this.form.controls["alignPayment"];
    this.matchTermDate = this.form.controls["matchTermDate"];

    this.program_term = this.form.controls["program_term"];

  }

  public validCashIn(control: AbstractControl): { [s: string]: boolean } {
    const value = control.value;
    if (value !== undefined && value !== null && value !== "") {
      if (value < 0) {
        return { validAmount: true }
      }

      const { lunch_fee, fromDate, toDate } = this.form.value
      if (value && lunch_fee && fromDate && toDate) {
        const calcBusinessDays = value
        this.totalDay = ConvertService.toNumber(calcBusinessDays) - ConvertService.toNumber(this.holidayDays)
        this.totalFee = lunch_fee.price * toNumber(this.totalDay)
      }
    }
  }

  currentYear;
  selectedAcademicYear;
  process: boolean = true;

  academicYearList = []
  lunchFeeList = []
  async ngOnInit() {
    this.buildForm();

    const { student } = this.studentStore
    if (student && student.add_actual_day) {
      this.allowAddDay = true
    }

    const { program_academic } = this.studentStore.selectedAdmission;
    await this.env.fetchUserDoc()
    const { selectedSchool, selectedCampus } = this.env
    const envData: any = await this.env.fetchAcademicEnv(selectedSchool.key);

    let academicYearDoc = await this.baseStore.fetchList(this.ds.storeDocRef(selectedSchool.key).collection("academic_year", ref => ref.limit(50)))

    if (program_academic) {
      const { program } = program_academic;
      if (program.programOption.key === 1) {
        this.currentYear = envData.year;
        academicYearDoc = academicYearDoc.filter(m => m.termType.key === 1)

      } else {
        this.currentYear = envData.term;
        academicYearDoc = academicYearDoc.filter(m => m.termType.key === 2)
      }
    }

    this.academicYearList = MappingService.orderByDesc(academicYearDoc, "startDate")
    this.selectedAcademicYear = this.academicYearList.find(m => m.key === this.currentYear.key)
    this.academic_year.patchValue(this.selectedAcademicYear)

    this.lunchFeeList = await this.baseStore.fetchList(this.ds.storeDocRef(selectedSchool.key).collection("campus").doc(selectedCampus.key).collection("lunch_fee", ref => ref.orderBy('order')))
    this.process = false;
  }

  async selectedYear(event) {
    const { value } = event.option
    this.lunchFeeList = []

    if (value) {
      const { selectedSchool, selectedCampus } = this.env
      this.selectedAcademicYear = this.academicYearList.find(m => m.key === value.key)
      const { academic_year } = this.selectedAcademicYear
      this.lunchFeeList = await this.baseStore.fetchList(this.ds.storeDocRef(selectedSchool.key).collection("campus").doc(selectedCampus.key).collection("lunch_fee", ref => ref.orderBy('order')))
    }
  }

  async selectedLevel(event) {
    const { value } = event.option

  }

  displayItemText(item: any): string {
    return item ? item.text : item;
  }

  async selectedAcademicTerm(event) {
    this.form.patchValue({
      lunch_fee: null,
      month_period: null,
      fromDate: null,
      toDate: null,
      matchTermDate: null,
    })
  }

  async selectedPeriod(event) {
    const { value } = event.option
    this.totalFee = 0
    this.totalDay = 0

    this.addDay.patchValue(null)

    if (value) {
      const { lunch_fee, month_period } = this.form.value

      const { period } = month_period
      const { programTermItems } = this.selectedAcademicYear
      const { program_term } = this.form.value

      let fromDateData = null
      let toDateData = null
      const programTermDate = programTermItems.find(m => m.key === program_term.key)

      if (programTermDate) {

        if (period === 1) {
          fromDateData = programTermDate.program_term_start.toDate()
          toDateData = ConvertService.endMonthFromDate(fromDateData)
        } else {

          fromDateData = programTermDate.program_term_start.toDate()

          switch (period) {
            case 12:
              toDateData = this.selectedAcademicYear.end_year_date.toDate()
              break;
            case 6:
              const endTermPeriod1 = programTermItems.find(m => m.key === (program_term.key + 1));
              toDateData = endTermPeriod1.program_term_end.toDate()
              break;
            case 3:
              const endTermPeriod2 = programTermItems.find(m => m.key === program_term.key);
              toDateData = endTermPeriod2.program_term_end.toDate()
              break;
            default:
              break;
          }
        }
      }



      const calcBusinessDays = ConvertService.calcBusinessDays(fromDateData, toDateData)
      const { selectedSchool } = this.env
      this.holidayDays = await this.clearPaymentStore.fetchCalPublicHoliday(selectedSchool.key, fromDateData, toDateData)

      this.totalDay = ConvertService.toNumber(calcBusinessDays) - ConvertService.toNumber(this.holidayDays)
      this.totalFee = lunch_fee.price * toNumber(this.totalDay)

      this.form.patchValue({
        fromDate: fromDateData,
        toDate: toDateData,
        addDay: this.totalDay
      })

    }
  }

  async toggle(event) {
    this.isMatchTerm = event.checked || false
    this.totalFee = 0
    this.totalDay = 0

    if (!this.isMatchTerm) {
      const { lunch_fee, month_period } = this.form.value

      const { period } = month_period
      const { programTermItems } = this.selectedAcademicYear
      const { program_term } = this.form.value

      let fromDateData = null
      let toDateData = null
      const programTermDate = programTermItems.find(m => m.key === program_term.key)

      if (programTermDate) {

        if (period === 1) {
          fromDateData = programTermDate.program_term_start.toDate()
          toDateData = ConvertService.endMonthFromDate(fromDateData)
        } else {

          fromDateData = programTermDate.program_term_start.toDate()

          switch (period) {
            case 12:
              toDateData = this.selectedAcademicYear.end_year_date.toDate()
              break;
            case 6:
              const endTermPeriod1 = programTermItems.find(m => m.key === (program_term.key + 1));
              toDateData = endTermPeriod1.program_term_end.toDate()
              break;
            case 3:
              const endTermPeriod2 = programTermItems.find(m => m.key === program_term.key);
              toDateData = endTermPeriod2.program_term_end.toDate()
              break;
            default:
              break;
          }

        }
      }

      this.form.patchValue({
        fromDate: fromDateData,
        toDate: toDateData,
      })


      const calcBusinessDays = ConvertService.calcBusinessDays(fromDateData, toDateData)
      const { selectedSchool } = this.env
      this.holidayDays = await this.clearPaymentStore.fetchCalPublicHoliday(selectedSchool.key, fromDateData, toDateData)

      this.totalDay = ConvertService.toNumber(calcBusinessDays) - ConvertService.toNumber(this.holidayDays)
      this.totalFee = lunch_fee.price * toNumber(this.totalDay)

    }
  }

  async changeDateMatchTerm(event) {
    const { value } = event
    if (value) {
      const { lunch_fee, toDate, matchTermDate } = this.form.value
      const calcBusinessDays = ConvertService.calcBusinessDays(matchTermDate, toDate)
      const { selectedSchool } = this.env
      this.holidayDays = await this.clearPaymentStore.fetchCalPublicHoliday(selectedSchool.key, matchTermDate, toDate)

      this.totalDay = ConvertService.toNumber(calcBusinessDays) - ConvertService.toNumber(this.holidayDays)
      this.totalFee = lunch_fee.price * toNumber(this.totalDay)
    }
  }

  onDateChange(event) {
    const { value } = event
    const { capital_fee } = this.form.value
    if (capital_fee)

      if (value && capital_fee) {
        const { period } = capital_fee.type
        this.form.patchValue({
          fromDate: value,
          toDate: ConvertService.addDateMonth(value, period),
        })
      }
  }

  compareObjects(o1: any, o2: any): boolean {
    if (o2) return o1.key === o2.key;
  }

  displayItem(item: any): string {
    return item ? item.name : item;
  }

  create(f: any) {
    if (!this.selectedAcademicYear) return;
    if (this.form.valid) {
      this.form.disable();
      const { selectedCampus, selectedSchool } = this.env
      const { academic_year, lunch_fee, month_period, fromDate, toDate, alignPayment, matchTermDate } = f


      const { program_academic } = this.studentStore.selectedAdmission;
      const date = new Date();
      const create_by = MappingService.userObj(this.env.user);
      const amount = this.totalFee

      const course = {
        price: lunch_fee.price,
        type: month_period,
        amount,
        days: this.totalDay,
        name: `${lunch_fee.name}`
      }

      const item: IInvoice = {
        key: this.ds.createId(),
        create_date: date,
        create_date_key: ConvertService.toDateKey(date),
        create_by: create_by,
        issue_by: create_by,
        issue_date: date,
        invoice_no: null,
        invoice_type: invoiceTypesObj.feeWithPeriod,
        course: course,
        price: amount,
        amount: amount,
        total_day: this.totalDay,

        school_fee_type: enrollPrograms.academic,
        student: MappingService.studentObj(this.studentStore.student),
        verify_by: create_by,
        verify_date: date,
        page_key: ConvertService.pageKey(),
        verify_date_key: ConvertService.toDateKey(date),
        invoice_date: date,
        invoice_date_key: ConvertService.toDateKey(date),
        isPaid: paymentStatus.unpaid,
        isVoid: false,
        program: program_academic,
        byAdmission: false,
        issue_year: academicYearObj(academic_year),
        payment_year: null,
        headerRef: null,
        isHeader: false,
        enroll_status: null,
        isEnrollVerify: true,
        schoolSession: null,
        description: course.name,

        penaltyRef: null,
        penalty: null,
        scholarshipRef: null,
        scholarship: null,
        loan: null,
        prepaidRef: null,
        prepaid: null,

        fromDate: alignPayment ? matchTermDate : fromDate,
        fromDateKey: alignPayment ? ConvertService.toDateKey(matchTermDate) : ConvertService.toDateKey(fromDate),
        toDate: toDate,
        toDateKey: ConvertService.toDateKey(toDate),

        campusKey: selectedCampus.key,
        campus: campusObj(selectedCampus),
        schoolKey: selectedSchool.key,
        school: schoolObj(selectedSchool),
      }

      const { invoiceKey, paymentHeader } = this.payment;
      const header = invoiceKey ? this.invoice.paymentHeader : paymentHeader

      this.payment.addFeeWithPeriod(item, header, alignPayment, (success, error) => {
        if (success) {
          this.dialogRef.close()
          this.snackBar.open("Lunch fee has been added to invoice.", "Done", { duration: 2000 })
          this.form.enable();
          this.form.reset();
          this.totalFee = 0
          this.form.patchValue({
            academic_year,
            lunch_fee,
          })
        }
        else {
          this.snackBar.open(error, "Error");
          this.form.enable();
        }
      })

    }
  }

}