import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatSnackBar
} from "@angular/material";
import {
  FormGroup,
  AbstractControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { AngularFirestore } from "@angular/fire/firestore";
import { MappingService } from "src/app/services/mapping.service";
import { Environment } from "src/app/stores/environment.store";
import { DataService } from "src/app/services/data.service";
import { InstituteSchedule } from "src/app/stores/institute_schedule.store";
import { Student } from "src/app/stores/student.store";
import { Payment } from "src/app/stores/payment.store";

@Component({
  selector: 'app-resolve-english-scholarship',
  templateUrl: './resolve-english-scholarship.component.html',
  styleUrls: ['./resolve-english-scholarship.component.scss']
})
export class ResolveEnglishScholarshipComponent implements OnInit {

  form: FormGroup;
  program: AbstractControl;
  level: AbstractControl;
  note: AbstractControl;
  memo: AbstractControl;
  target_term: AbstractControl;
  study_session: AbstractControl;
  target_campus: AbstractControl;
  target_session: AbstractControl;

  testLevelList: any;
  testLevelAll: any;
  programList: any;
  termList: any;
  shiftList: any;
  sessionsList: any;
  campusList: any;

  constructor(
    public dialogRef: MatDialogRef<ResolveEnglishScholarshipComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private db: AngularFirestore,
    public env: Environment,
    public store: InstituteSchedule,
    private ds: DataService,
    public studentStore: Student,
    public payment: Payment
  ) { }

  ngOnInit() {
    this.buildForm();

    this.store.fetchConfig(this.studentStore.student.key, (res) => {
      if (res) {
        this.testLevelAll = this.store.levels;
        this.shiftList = this.store.shifts;
        this.sessionsList = this.store.sessions;
        this.campusList = this.store.campus;
        this.programList = this.store.programs;

        const { institute } = this.programList[0];
        this.store.fetchInstituteTerm(institute.key, (t) => {
          this.termList = this.store.instituteTerms;

          let getProgram = null;
          let getShift = null;
          getProgram = this.programList[0];
          this.testLevelList = this.testLevelAll.filter(m => m.program.key === getProgram.key);

          getShift = this.shiftList[0];
          this.sessionsList = this.store.sessions.filter(m => m.shift.key === getShift.key);

          this.form.patchValue({
            program: getProgram,
            level: this.testLevelList[0],
            target_term: this.termList[0],
            study_session: this.shiftList[0],
            target_campus: this.campusList[0],
            target_session: this.sessionsList[0],
          })

        })
      }
    });
  }

  selectedProgram(event) {
    if (event && event.value) {
      let program = event.value;
      let getProgram = this.programList.filter(m => m.key === program.key)[0];
      this.testLevelList = this.testLevelAll.filter(m => m.program.key === getProgram.key);
      this.level.patchValue(this.testLevelList[0]);
    }
  }

  selectedShift(event) {
    if (event && event.value) {
      let shift = event.value;
      let getShift = this.shiftList.filter(m => m.key === shift.key)[0];
      this.sessionsList = this.store.sessions.filter(m => m.shift.key === getShift.key);
      this.target_session.patchValue(this.sessionsList[0]);
    }
  }

  buildForm(): void {
    this.form = this.fb.group({
      program: [null, [Validators.required]],
      level: [null, [Validators.required]],
      score: [null,],
      target_term: [null, [Validators.required]],
      target_campus: [null, [Validators.required]],
      study_session: [null, [Validators.required]],
      target_session: [null, [Validators.required]],
      note: [null],
      memo: [null]
    });
    this.program = this.form.controls["program"];
    this.level = this.form.controls["level"];
    this.target_term = this.form.controls["target_term"];
    this.target_campus = this.form.controls["target_campus"];
    this.study_session = this.form.controls["study_session"];
    this.target_session = this.form.controls["target_session"];
    this.note = this.form.controls["note"];
    this.memo = this.form.controls["memo"];
  }

  compareByKey(f1: any, f2: any) {
    return f1 && f2 && f1.key === f2.key;
  }

  save(f: any) {
    if (this.form.valid) {
      this.form.disable();
      this.store.resolveScholarship(f, this.studentStore.student, this.env.user, (success, error) => {
        if (success) {
          this.snackBar.open("The process have been completed.", "done", {
            duration: 2000
          });
          this.dialogRef.close();
          this.form.enable();
          this.form.reset();
        } else {
          this.snackBar.open(error, "Error");
          this.form.enable();
        }
      });
    }
  }
}
