import { IOptions } from './../../interfaces/options';
import { Tuition } from "./../../stores/tuition.store";
import { Environment } from "./../../stores/environment.store";
import { MappingService } from "./../../services/mapping.service";
import { AbstractControl, Validators } from "@angular/forms";
import { FormGroup, FormBuilder } from "@angular/forms";
import { tabs } from "./../../dummy/tabs";
import { Component, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material";
import { ConvertService } from 'src/app/services/convert.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: "app-options",
  templateUrl: "./options.component.html",
  styleUrls: ["./options.component.scss"]
})
export class OptionsComponent implements OnInit {
  tabs = tabs.options;
  form: FormGroup;

  bookAndMaterialFee: AbstractControl;
  uniformFee: AbstractControl;
  registrationFeeKid: AbstractControl;
  registrationFee: AbstractControl;
  first_penalty_fee: AbstractControl;
  second_penalty_fee: AbstractControl;

  payment_option_full_year: AbstractControl;
  admin_fee: AbstractControl;
  tuition_fee: AbstractControl;

  filteredBookAndMaterialFeeStates: any;
  filteredRegistrationFeeStates: any;
  filteredRegistrationFeeKidStates: any;
  filteredUniformFeeStates: any;
  firstPenaltyFeeStates: any;
  secondPenaltyFeeStates: any;
  paymentOptionStates: any;
  feeCategoryStates: any;
  feeTuitionStates: any;

  constructor(
    private fb: FormBuilder,
    public fee: Tuition,
    private snackBar: MatSnackBar,
    public store: Environment,
    public ds: DataService,
  ) { }

  async ngOnInit() {
    this.form = this.fb.group({
      bookAndMaterialFee: [null, Validators.compose([Validators.required, MappingService.validSelected.bind(this)])],
      registrationFee: [null, Validators.compose([Validators.required, MappingService.validSelected.bind(this)])],
      registrationFeeKid: [null, Validators.compose([Validators.required, MappingService.validSelected.bind(this)])],
      uniformFee: [null, Validators.compose([Validators.required, MappingService.validSelected.bind(this)])],
      first_penalty_fee: [null, Validators.compose([Validators.required, MappingService.validSelected.bind(this)])],
      second_penalty_fee: [null, Validators.compose([Validators.required, MappingService.validSelected.bind(this)])],
      payment_option_full_year: [null, Validators.compose([Validators.required, MappingService.validSelected.bind(this)])],
      admin_fee: [null, Validators.compose([Validators.required, MappingService.validSelected.bind(this)])],
      tuition_fee: [null, Validators.compose([Validators.required, MappingService.validSelected.bind(this)])],
    });

    this.bookAndMaterialFee = this.form.controls["bookAndMaterialFee"];
    this.registrationFee = this.form.controls["registrationFee"];
    this.first_penalty_fee = this.form.controls["first_penalty_fee"];
    this.second_penalty_fee = this.form.controls["second_penalty_fee"];
    this.registrationFeeKid = this.form.controls["registrationFeeKid"];
    this.uniformFee = this.form.controls["uniformFee"];
    this.payment_option_full_year = this.form.controls["payment_option_full_year"];
    this.admin_fee = this.form.controls["admin_fee"];
    this.tuition_fee = this.form.controls["tuition_fee"];

    const fee = await this.fee.fetchTuitionFee();
    const paymentOption = await this.fee.fetchList(this.ds.paymentOptionRef());
    const feeCategory = await this.fee.fetchList(this.ds.feeCategoryRef());

    this.filteredBookAndMaterialFeeStates = MappingService.autoComplete(this.bookAndMaterialFee, fee, "name");
    this.filteredRegistrationFeeStates = MappingService.autoComplete(this.registrationFee, fee, "name");
    this.filteredRegistrationFeeKidStates = MappingService.autoComplete(this.registrationFeeKid, fee, "name");
    this.filteredUniformFeeStates = MappingService.autoComplete(this.uniformFee, fee, "name");
    this.firstPenaltyFeeStates = MappingService.autoComplete(this.first_penalty_fee, fee, "name");
    this.secondPenaltyFeeStates = MappingService.autoComplete(this.second_penalty_fee, fee, "name");

    this.paymentOptionStates = MappingService.autoComplete(this.payment_option_full_year, paymentOption, "name");
    this.feeCategoryStates = MappingService.autoComplete(this.admin_fee, feeCategory, "name");
    this.feeTuitionStates = MappingService.autoComplete(this.tuition_fee, feeCategory, "name");

    const env: any = await this.store.fetchGeneral();
    const { bookAndMaterialFee, registrationFee, uniformFee, registrationFeeKid, first_penalty_fee, second_penalty_fee, admin_fee, tuition_fee, payment_option_full_year } = env;

    this.form.patchValue({
      bookAndMaterialFee: ConvertService.toNull(bookAndMaterialFee),
      registrationFee: ConvertService.toNull(registrationFee),
      registrationFeeKid: ConvertService.toNull(registrationFeeKid),
      uniformFee: ConvertService.toNull(uniformFee),
      first_penalty_fee: ConvertService.toNull(first_penalty_fee),
      second_penalty_fee: ConvertService.toNull(second_penalty_fee),
      payment_option_full_year: ConvertService.toNull(payment_option_full_year),
      admin_fee: ConvertService.toNull(admin_fee),
      tuition_fee: ConvertService.toNull(tuition_fee),
    })

  }

  displayItem(item: any): string {
    return item ? item.name : item;
  }

  create(f: any) {
    if (this.form.valid) {
      const { bookAndMaterialFee, registrationFee, uniformFee, registrationFeeKid, first_penalty_fee, second_penalty_fee, admin_fee, tuition_fee, payment_option_full_year } = f;
      const item: IOptions = {
        key: "general",
        bookAndMaterialFee,
        registrationFee,
        uniformFee,
        registrationFeeKid,
        first_penalty_fee,
        second_penalty_fee,
        admin_fee: MappingService.settingObj(admin_fee),
        tuition_fee: MappingService.settingObj(tuition_fee),
        payment_option_full_year: MappingService.paymentOptionObj(payment_option_full_year),
      }
      this.store.update(item, (success, error) => {
        if (success) {
          this.snackBar.open("Options has been saved.", "done", {
            duration: 2000
          });
        } else {
          this.snackBar.open(error, "Error");
        }
      });
    }
  }
}
