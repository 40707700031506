import { Pipe, PipeTransform } from '@angular/core';
import { ConvertService } from '../services/convert.service';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(value: Date, args?: any): any {
    if(!value) return null;
    return ConvertService.dateFormat(value);
  }

}
