import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatSnackBar
} from "@angular/material";
import {
  FormGroup,
  AbstractControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { AngularFirestore } from "@angular/fire/firestore";
import { Environment } from "src/app/stores/environment.store";
import { DataService } from "src/app/services/data.service";
import { InstituteSchedule } from "src/app/stores/institute_schedule.store";
import { Student } from "src/app/stores/student.store";
import { Payment } from "src/app/stores/payment.store";

@Component({
  selector: 'app-edit-english-payment',
  templateUrl: './edit-english-payment.component.html',
  styleUrls: ['./edit-english-payment.component.scss']
})
export class EditEnglishPaymentComponent implements OnInit {

  form: FormGroup;
  target_term: AbstractControl;
  study_session: AbstractControl;
  target_campus: AbstractControl;

  programList: any;
  termList: any;
  shiftList: any;
  campusList: any;

  constructor(
    public dialogRef: MatDialogRef<EditEnglishPaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    public env: Environment,
    public store: InstituteSchedule,
    public studentStore: Student,
    public payment: Payment
  ) { }

  ngOnInit() {
    this.buildForm();

    this.store.fetchConfig(this.studentStore.student.key, (res) => {
      if (res) {
        this.shiftList = this.store.shifts;
        this.campusList = this.store.campus;
        this.programList = this.store.programs;

        const { institute } = this.programList[0];
        this.store.fetchInstituteTerm(institute.key, (t) => {
          this.termList = this.store.instituteTerms;

          if (this.store.instituteAdmission) {
            const { target_term, study_session, target_campus } = this.data;

            this.form.patchValue({
              target_term: target_term,
              study_session: study_session,
              target_campus: target_campus,
            })
          } else {

            this.form.patchValue({
              target_term: this.termList[0],
              study_session: this.shiftList[0],
              target_campus: this.campusList[0],
            })
          }

        })
      }
    });
  }

  buildForm(): void {
    this.form = this.fb.group({
      target_term: [null, [Validators.required]],
      target_campus: [null, [Validators.required]],
      study_session: [null, [Validators.required]],
    });
    this.target_term = this.form.controls["target_term"];
    this.target_campus = this.form.controls["target_campus"];
    this.study_session = this.form.controls["study_session"];
  }

  compareByKey(f1: any, f2: any) {
    return f1 && f2 && f1.key === f2.key;
  }

  save(f: any) {
    if (this.form.valid) {
      this.form.disable();
      this.store.editPaymentInfo(f, this.data, this.studentStore.student, this.env.user, (success, error) => {
        if (success) {
          this.snackBar.open("The process have been completed.", "done", {
            duration: 2000
          });
          this.dialogRef.close();
          this.form.enable();
          this.form.reset();
        } else {
          this.snackBar.open(error, "Error");
          this.form.enable();
        }
      });
    }
  }
}
