import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BANK_PAYMENT } from 'src/app/dummy/status';
import { ClearPaymentStore } from 'src/app/stores/clearPayment.store';
import { ClearPaymentFeeComponent } from '../clear-payment-fee/clear-payment-fee.component';

@Component({
  selector: 'app-student-unpaid-payment-listing',
  templateUrl: './student-unpaid-payment-listing.component.html',
  styleUrls: ['./student-unpaid-payment-listing.component.scss']
})
export class StudentUnpaidPaymentListingComponent implements OnInit {

  constructor(
    public store: ClearPaymentStore,
    public dialogRef: MatDialogRef<StudentUnpaidPaymentListingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    const { student, schoolKey, key } = this.data
    this.store.fetchUnpaidInvoice(student.key);
    this.store.fetchImportStudentPayment(schoolKey, key)
  }

  ngOnDestroy() {
    const { fetchUnpaidInvoiceDataRef, fetchImportStudentPaymentDataRef } = this.store;
    fetchUnpaidInvoiceDataRef && fetchUnpaidInvoiceDataRef.unsubscribe();
    fetchImportStudentPaymentDataRef && fetchImportStudentPaymentDataRef.unsubscribe();
  }

  _clearPayment(item) {
    if (item.isPaid.key === 1 || !this.store.studentImportPayment) return;
    if (this.store.studentImportPayment && this.store.studentImportPayment.isPaid) return;

    const { student, program } = item
    const { bank_transfer } = this.data

    let clearWith = null
    let note = null
    switch (bank_transfer.key) {
      case BANK_PAYMENT.ABA.key:
        clearWith = "ABA"
        note = "PAY WITH ABA BILL PAYMENT"
        break;
      case BANK_PAYMENT.WING.key:
        clearWith = "WING"
        note = "PAY WITH WING BILL PAYMENT"
        break;
      case BANK_PAYMENT.AC.key:
        clearWith = "ACLEDA"
        note = "PAY WITH ACLEDA BILL PAYMENT"
        break;
      case "ACLEDA":
        clearWith = "ACLEDA"
        note = "PAY WITH ACLEDA BILL PAYMENT"
        break;
      case BANK_PAYMENT.TM.key:
        clearWith = "TM"
        note = "PAY WITH True Money BILL PAYMENT"
        break;
      default:
        break;
    }

    this.store.fetchStudent(student.key, program.admissionKey, doc => {
      const dialogRef = this.dialog.open(ClearPaymentFeeComponent, {
        data: { INVOICE: item, IMPORT_PAYMENT: this.store.studentImportPayment, clearWith: clearWith, note: note, },
        panelClass: "register-test-overlay-panel",
        width: "40vw",
        height: "100vh",
        disableClose: false,
        role: "dialog"
      });
      dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
      dialogRef.afterClosed().subscribe(result => {
        if (result == "yes") {
          this.dialogRef.close();
        }
      });
    });
  }

}
