import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AbstractControl } from '@angular/forms';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { tabs } from 'src/app/dummy/tabs';
import { PrintService } from 'src/app/services/print.service';
import { Environment } from 'src/app/stores/environment.store';
import { Report } from 'src/app/stores/report.store';
import { MappingService } from 'src/app/services/mapping.service';
import { ConvertService } from 'src/app/services/convert.service';
import * as XLSX from 'xlsx';
import { SCHOOL } from 'src/app/dummy/config';

@Component({
  selector: 'app-match-term-listing',
  templateUrl: './match-term-listing.component.html',
  styleUrls: ['./match-term-listing.component.scss']
})
export class MatchTermListingComponent implements OnInit {
  @ViewChild('table') table: ElementRef;
  school=SCHOOL

  tabs = tabs.reportFilterBy;
  form: FormGroup;
  fromDate: AbstractControl;
  toDate: AbstractControl;

  type: string;
  constructor(
    private fb: FormBuilder,
    private ps: PrintService,
    public env: Environment,
    private route: ActivatedRoute,
    public store: Report
  ) { }

  print() {
    this.ps.print("#print", "a4");
  }

  fireEvent() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, 'SheetJS.xlsx');
  }

  ngOnInit() {
    this.buildForm();
    this.route.params.forEach(param => {
      this.type = param["id"];
      const fromDate = MappingService.fromDateFilter(this.type);
      const toDate = MappingService.toDateFilter(this.type);
      this.form.patchValue({
        fromDate: fromDate,
        toDate: toDate
      });
      this.getList(fromDate, toDate);
    });
  }

  getList(fromDate, toDate) {
    this.env.getUser(user => {
      if (user)
        this.store.fetchMatchTermListing(ConvertService.toDateKey(fromDate), ConvertService.toDateKey(toDate), user.key);
    })
  }

  buildForm(): void {
    this.form = this.fb.group({
      fromDate: [null, Validators.required],
      toDate: [null, Validators.required],
    });

    this.fromDate = this.form.controls["fromDate"];
    this.toDate = this.form.controls["toDate"];
  }

  _onPress(f: any) {
    if (this.form.valid) {
      const { fromDate, toDate } = f;
      this.getList(fromDate, toDate);
    }
  }

}
