import { Component, OnInit, Inject } from '@angular/core';
import { Payment } from 'src/app/stores/payment.store';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { PrintService } from 'src/app/services/print.service';
import { Report } from 'src/app/stores/report.store';
import { InvoiceDialogComponent } from '../invoice-dialog/invoice-dialog.component';
import { SCHOOL } from 'src/app/dummy/config';

@Component({
  selector: 'app-report-receipt-dialog',
  templateUrl: './report-receipt-dialog.component.html',
  styleUrls: ['./report-receipt-dialog.component.scss']
})
export class ReportReceiptDialogComponent implements OnInit {
school=SCHOOL
  constructor(
    public dialogRef: MatDialogRef<ReportReceiptDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public store: Report,
    private ps: PrintService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.store.fetchReceiptListing(this.data.key);
  }

  print() {
    this.ps.print("#printD", "a4l");
  }

  _onPrint(item) {
    let dialogRef = this.dialog.open(InvoiceDialogComponent, {
      data: { studentKey: item.student.key, headerKey: item.headerRef },
      width: '90vw',
      height: '80vh',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

}
