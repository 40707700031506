import { PrintService } from "./../../services/print.service";
import { Router, ActivatedRoute } from "@angular/router";
import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  AfterViewInit
} from "@angular/core";
import { slideInOutAnimation } from "../../services/slide-io.animation";
import {
  FormGroup,
  AbstractControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Payment } from "../../stores/payment.store";
import { Environment } from "src/app/stores/environment.store";
import { MatDialog, MatSnackBar } from "@angular/material";
import { ConvertService } from "src/app/services/convert.service";

@Component({
  animations: [slideInOutAnimation],
  selector: 'app-search-invoice-no',
  templateUrl: './search-invoice-no.component.html',
  styleUrls: ['./search-invoice-no.component.scss'],
  host: { "[@slideInOutAnimation]": "" }
})
export class SearchInvoiceNoComponent implements OnInit, AfterViewInit {
  @ViewChild("focusInput")
  inputEl: ElementRef;

  form: FormGroup;
  search: AbstractControl;
  formFocus: boolean = false;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    public store: Payment,
    public env: Environment,
    public dialog: MatDialog,
    private ps: PrintService,
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      search: [null,]
    });
    this.search = this.form.controls["search"];
    this.store.receiptHeader = null
  }
  ngAfterViewInit() { }

  _goBack() {
    this.router.navigate(["/"]);
  }
  _onFocus(yes) {
    this.formFocus = yes;
  }

  _onSearch(f: any) {
    if (this.form.valid) {
      if (f.search) {
        const invoiceNo = ConvertService.toNumber(f.search);
        this.store.fetchInvoiceByInvoiceNo(invoiceNo, (res) => {
          if (res.length > 0) {
            let { student, headerRef } = res[0];
            this.store.fetchInvoiceItems(student.key, headerRef, (res) => { });
          }
        });
      } else this.store.receiptHeader = null

    }
  }

  print() {
    this.ps.print("#printD", "a5l");
  }

}
