
import { DataService } from "./../services/data.service";
import { observable, computed, action, autorun } from "mobx";
import { Injectable } from "@angular/core";

@Injectable()
export class Search {
  @observable
  public data = null;
  @observable
  public loading = false;
  @observable
  public empty = false;
  @observable
  public done = false;
  @observable
  public fetching = false;
  @observable
  public student=null;

  public lastVisible: any = new Date();
  constructor(private ds: DataService) {}

  @action
  fetchStudent(key:string){
    // this.ds.studentDocument(key).valueChanges().subscribe(doc=>{
    //   this.student=doc;
    // })
  }
  
  @action
  fetchData(field, campus) {
    this.loading = true;
    this.done = false;
    this.ds
      .studentRef(field, campus)
      .valueChanges()
      .subscribe(docs => {
        if (docs.length) {
          this.lastVisible = docs[docs.length - 1];
        } else {
          this.lastVisible = null;
          this.done = true;
        }
        this.empty = docs.length === 0;
        this.data = docs;
        this.loading = false;
      });
  }

  @action
  onScroll(field,search,campus) {
    if (this.fetching || this.done) return;
    this.fetching = true;
    this.ds
      .studentFetchRef(field,search, campus, this.lastVisible)
      .valueChanges()
      .subscribe(docs => {
        if (docs.length) {
          this.lastVisible = docs[docs.length - 1];
        } else {
          this.done = true;
        }
        this.data = this.data.slice().concat(docs);
        this.fetching = false;
      });
  }

  @action
  search(field,search, campus){
    if(search.key){
      return this.ds.studentSearchRef(field,search.puc_id, campus).valueChanges();
    }
    return this.ds.studentSearchRef(field,search, campus).valueChanges();
  }

}