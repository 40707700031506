import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'app-student-info',
  templateUrl: './student-info.component.html',
  styleUrls: ['./student-info.component.scss']
})
export class StudentInfoComponent implements OnInit {
  @Input() data:Array<any>;
  @Input() headerText:string;
  @Output() onDrop=new EventEmitter();
  @Input() group:Array<any>
  
    constructor() { }
  
    ngOnInit() {
      
    }
  
    _onClick(item){
      this.onDrop.emit(item)
    }
  }