import { Payment } from 'src/app/stores/payment.store';
import { StudentAbcComponent } from './student-abc/student-abc.component';
import { MatDialog } from '@angular/material';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Student } from 'src/app/stores/student.store';
import { Environment } from 'src/app/stores/environment.store';
import { searchFilterBy } from 'src/app/dummy/status';
import { switchMap, debounceTime, tap } from "rxjs/operators";
import { Pages } from "src/app/dummy/pages";
import { MappingService } from "src/app/services/mapping.service";
import { PrintService } from 'src/app/services/print.service';
import { AddDiscountInvoiceComponent } from 'src/app/components/add-discount-invoice/add-discount-invoice.component';
import { AddMiscellaneousInvoiceComponent } from 'src/app/components/add-miscellaneous-invoice/add-miscellaneous-invoice.component';
import { StudentInstallmentComponent } from './student-installment/student-installment.component';
import { StudentResolveFeeComponent } from './student-resolve-fee/student-resolve-fee.component';
import { AddDiscountComponent } from 'src/app/components/add-discount/add-discount.component';
import { ResolveEnglishFeeComponent } from 'src/app/components/resolve-english-fee/resolve-english-fee.component';
import { AddEnglishAdmissionComponent } from 'src/app/components/add-english-admission/add-english-admission.component';
import { ResolveEnglishScholarshipComponent } from 'src/app/components/resolve-english-scholarship/resolve-english-scholarship.component';
import { User } from 'src/app/stores/user.store';
import { AddSchoolFeeComponent } from 'src/app/components/add-school-fee/add-school-fee.component';
import { AddOtherFeeComponent } from 'src/app/components/add-other-fee/add-other-fee.component';
import { NewInvoicePosComponent } from './new-invoice-pos/new-invoice-pos.component';
import { CashierVoidReceiptComponent } from 'src/app/components/cashier-void-receipt/cashier-void-receipt.component';
import { AddStudentDiscountVoucherComponent } from 'src/app/components/add-student-discount-voucher/add-student-discount-voucher.component';
import { ConfirmDeleteComponent } from 'src/app/components/confirm-delete/confirm-delete.component';
import { AddStudentOldVoucherComponent } from 'src/app/components/add-student-old-voucher/add-student-old-voucher.component';
import { AddCapitalFeeComponent } from './add-capital-fee/add-capital-fee.component';
import { AddPeriodFeeComponent } from './add-period-fee/add-period-fee.component';
import { AddLunchFeeComponent } from './add-lunch-fee/add-lunch-fee.component';
import { AddDeductSchoolFeeComponent } from 'src/app/components/add-deduct-school-fee/add-deduct-school-fee.component';
import { AddInstallmentSchoolFeeComponent } from 'src/app/components/add-installment-school-fee/add-installment-school-fee.component';

@Component({
  selector: 'app-student',
  templateUrl: './student.component.html',
  styleUrls: ['./student.component.scss']
})
export class StudentComponent implements OnInit {
  icons = [
    "face",
    "adb",
    "cloud",
    "view_carousel",
    "favorite",
    "verified_user",
  ]
  formFocus: boolean;
  form: FormGroup;
  search: AbstractControl;
  searchType: AbstractControl;
  filterBy = searchFilterBy;
  loading: boolean = false;
  isAdmin = false;

  id: string = null;
  admissionKey: string = null;
  OTHER_FEE_LIST: any;
  STUDENT: any = null;

  constructor(
    private store: Student,
    public payment: Payment,
    private router: Router,
    public env: Environment,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private ps: PrintService,
    private user: User,
  ) { }

  async ngOnInit() {
    this.form = this.fb.group({
      searchType: [this.filterBy[0], [Validators.required]],
      search: [null]
    });
    this.search = this.form.controls["search"];
    this.searchType = this.form.controls["searchType"];
    if (this.store.filterType) {
      this.searchType.patchValue(this.store.filterType);
    }
    const { searchType } = this.form.value;

    this.env.getUser(res => {
      this.isAdmin = false;
      if (res && res.email === "sitha.b@puc.edu.kh") {
        this.isAdmin = true;
      }

      const { campus } = res;

      this.store.fetchData(searchType.key, campus);
      this.search.valueChanges
        .pipe(
          debounceTime(Pages.debounceTime),
          tap(() => (this.loading = true)),
          switchMap(value => this.store.search(this.searchType.value.key, value, campus))
        )
        .subscribe(results => {
          this.store.data = results;
          this.loading = false;
        });
    })

    await this.env.fetchUserDoc()
    const { selectedSchool } = this.env
    this.OTHER_FEE_LIST = await this.store.fetchOtherServiceFee(selectedSchool.key);

    this.initialData();

  }

  initialData() {
    this.route.params.forEach(param => {
      this.id = param["id"];
      this.admissionKey = param['admissionKey'];
      this.store.fetchStudent(this.id, this.admissionKey, (doc) => {
        this.STUDENT = doc;
      });
    })
  }

  _goBack() {
    this.router.navigate(['/']);
  }

  displayItem(item: any): string {
    if (this.searchType) {
      const { key } = this.searchType.value;
      return item ? item[key] : item;
    }
    return item ? item.puc_id : item;
  }

  _onPrint() {
    if (this.payment.invoiceHeader) {
      this.ps.print("#print", "a5l");
    }
  }

  compareObjects(o1: any, o2: any): boolean {
    if (o2) {
      return o1.text === o2.text;
    }
  }
  _onFocus(yes) {
    this.formFocus = yes;
  }

  _onSearch(item: any) {
    const { search, searchType } = item;
    if (search.key) {
      this.store.student = search;
      this._linkPage(search, this.store.payPageKey);
    }
    else {
      const students = MappingService.filter(this.store.data, searchType.key, search);
      if (students.length > 0) {
        const s = students[0];
        this.store.student = s;
        this._linkPage(s, this.store.payPageKey);
      }
    }
  }

  _selectionChange(event) {
    const { value } = event;
    this.store.filterType = value;
  }

  _optionSelected(item: any) {
    this.store.student = item;
    this._linkPage(item, this.store.payPageKey);
  }

  _linkPage(item, pageKey) {
    if (item.key) {
      const { program_academic, key } = item;
      this.store.student = item;
      const list = this.store.data.filter(m => m.key === key);
      if (list.length > 0) {
        if (program_academic) {
          const { admissionKey } = program_academic
          this.router.navigate([`/student/${item.key}/${admissionKey}/receive-payment`]);
        }
        else {
          this.router.navigate([`/student/${item.key}/draft/receive-payment`]);
        }
      }
    }
    else {
      const { searchType } = this.form.value;
      const list = this.store.data.filter(m => m[searchType.key] === item);
      if (list.length > 0) {
        const { program_academic } = list[0]
        this.store.student = list[0];
        if (program_academic) {
          const { admissionKey } = program_academic
          this.router.navigate([`/student/${item.key}/${admissionKey}/receive-payment`]);
        }
        else {
          this.router.navigate([`/student/${item.key}/draft/receive-payment`]);
        }
      }
    }
  }

  _onAddABC() {
    let dialogRef = this.dialog.open(StudentAbcComponent, {
      data: null,
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: false,
      role: "dialog"
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  _onAddInstallment() {
    let dialogRef = this.dialog.open(StudentInstallmentComponent, {
      data: null,
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: false,
      role: "dialog"
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  _onAddDiscount() {
    let dialogRef = this.dialog.open(AddDiscountInvoiceComponent, {
      data: null,
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: false,
      role: "dialog"
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  _onAddMiscellaneousFees() {
    let dialogRef = this.dialog.open(AddMiscellaneousInvoiceComponent, {
      data: null,
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: false,
      role: "dialog"
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  _onAddOtherFee(item) {
    let dialogRef = this.dialog.open(AddOtherFeeComponent, {
      data: item,
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: false,
      role: "dialog"
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  _onAddSchoolFees() {
    if (!this.store.selectedAdmission) return;
    if (this.payment.paymentHeader && this.payment.paymentHeader.discount_voucher_detail_key) {
      this.dialog.open(ConfirmDeleteComponent, {
        data: {
          title: "Already apply discount voucher",
          subTitle: "The student cannot change payment option."
        },
        width: '35vw',
        disableClose: false,
        role: 'dialog',
      });
    } else {
      let dialogRef = this.dialog.open(AddSchoolFeeComponent, {
        data: null,
        panelClass: "register-test-overlay-panel",
        width: "35vw",
        height: "100vh",
        disableClose: true,
        role: "dialog",
        autoFocus: false,
      });
      dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
      dialogRef.afterClosed().subscribe(result => {

      });
    }
  }

  _onAddLessPayment() {
    if (!this.store.selectedAdmission || this.payment.studentInstallment) return;
    let dialogRef = this.dialog.open(AddDeductSchoolFeeComponent, {
      data: null,
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: true,
      role: "dialog",
      autoFocus: false,
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  _onAddLInstallmentPayment() {
    if (!this.store.selectedAdmission || this.payment.studentInstallment) return;
    let dialogRef = this.dialog.open(AddInstallmentSchoolFeeComponent, {
      data: null,
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: true,
      role: "dialog",
      autoFocus: false,
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  _onAddCapitalFee() {
    if (!this.store.selectedAdmission) return;
    let dialogRef = this.dialog.open(AddCapitalFeeComponent, {
      data: null,
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: true,
      role: "dialog",
      autoFocus: false,
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  _onAddPeriodFee() {
    if (!this.store.selectedAdmission) return;
    let dialogRef = this.dialog.open(AddPeriodFeeComponent, {
      data: null,
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: true,
      role: "dialog",
      autoFocus: false,
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  _onAddLunchFee() {
    if (!this.store.selectedAdmission) return;
    let dialogRef = this.dialog.open(AddLunchFeeComponent, {
      data: null,
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: true,
      role: "dialog",
      autoFocus: false,
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  _onVoidReceipt() {
    if (!this.store.selectedAdmission) return;
    let dialogRef = this.dialog.open(CashierVoidReceiptComponent, {
      data: null,
      panelClass: "register-test-overlay-panel",
      width: "40vw",
      height: "100vh",
      disableClose: false,
      role: "dialog",
      autoFocus: false,
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  _onAddVoucher() {
    if (!this.store.selectedAdmission || !this.payment.paymentHeader) return;
    if (this.payment.paymentHeader && this.payment.paymentHeader.discount_voucher_detail_key) {
      this.dialog.open(ConfirmDeleteComponent, {
        data: {
          title: "Already apply discount voucher",
          subTitle: "The student payment already apply discount voucher. you cannot apply other voucher."
        },
        width: '35vw',
        disableClose: false,
        role: 'dialog',
      });
    } else {
      let dialogRef = this.dialog.open(AddStudentDiscountVoucherComponent, {
        data: null,
        panelClass: "register-test-overlay-panel",
        width: "40vw",
        height: "100vh",
        disableClose: false,
        role: "dialog",
        autoFocus: false,
      });
      dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
      dialogRef.afterClosed().subscribe(result => {

      });
    }
  }

  _onApplyOldVoucher() {
    if (!this.store.selectedAdmission || !this.payment.paymentHeader || this.payment.paymentHeader.discount_voucher_no) return;
    let dialogRef = this.dialog.open(AddStudentOldVoucherComponent, {
      data: null,
      panelClass: "register-test-overlay-panel",
      width: "40vw",
      height: "100vh",
      disableClose: false,
      role: "dialog",
      autoFocus: false,
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  _onAddSchoolPayment() {
    if (!this.store.selectedAdmission) return;
    // let dialogRef = this.dialog.open(AddStudentPaymentComponent, {
    //   data: null,
    //   panelClass: "register-test-overlay-panel",
    //   width: "35vw",
    //   height: "100vh",
    //   disableClose: false,
    //   role: "dialog",
    //   autoFocus: false,
    // });
    // dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    // dialogRef.afterClosed().subscribe(result => {

    // });
  }

  _onAddPOS() {
    if (!this.store.selectedAdmission) return;
    let dialogRef = this.dialog.open(NewInvoicePosComponent, {
      data: null,
      panelClass: "register-test-overlay-panel",
      width: "40vw",
      height: "100vh",
      disableClose: true,
      role: "dialog",
      autoFocus: false,
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  _onAddDiscountInvoice() {
    let dialogRef = this.dialog.open(AddDiscountComponent, {
      data: null,
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: false,
      role: "dialog"
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  _onRetakeTest() {
    if (this.user.user.shift || !this.env.sameDay) {
      this.router.navigate([`/student/${this.store.student.key}/${this.admissionKey}/student-retake-test`]);
    }
  }

  resolveFee() {
    let dialogRef = this.dialog.open(StudentResolveFeeComponent, {
      data: null,
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: false,
      role: "dialog"
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  _onSelectedAdmission(item) {
    this.router.navigate(["/student/" + this.id + "/" + item.key + '/receive-payment']);
  }

  _onNewInvoice() {
    const { invoiceKey } = this.store.student;
    if (invoiceKey) {
      this.router.navigate([`/student/${this.store.student.key}/${this.admissionKey}/new-invoice/invoice-no/${invoiceKey}`]);
    }
    else {
      this.router.navigate([`/student/${this.store.student.key}/${this.admissionKey}/new-invoice/invoice-no`]);
    }
  }

  _resolveEnglishFee() {
    if (!this.store.selectedAdmission || !this.store.student) return;
    let dialogRef = this.dialog.open(ResolveEnglishFeeComponent, {
      data: null,
      panelClass: 'register-test-overlay-panel',
      width: '35vw',
      height: '100vh',
      disableClose: false,
      role: 'dialog',
    });
    dialogRef.updatePosition({ top: '0', right: '0', bottom: '0' });
    dialogRef.afterClosed().subscribe(result => { });
  }

  _resolveAdmission() {
    if (this.store.selectedAdmission || !this.store.student) return;
    let dialogRef = this.dialog.open(AddEnglishAdmissionComponent, {
      data: null,
      panelClass: 'register-test-overlay-panel',
      width: '35vw',
      height: '100vh',
      disableClose: false,
      role: 'dialog',
    });
    dialogRef.updatePosition({ top: '0', right: '0', bottom: '0' });
    dialogRef.afterClosed().subscribe(result => { });
  }

  _resolveScholarship() {
    if (this.store.selectedAdmission || !this.store.student) return;
    let dialogRef = this.dialog.open(ResolveEnglishScholarshipComponent, {
      data: null,
      panelClass: 'register-test-overlay-panel',
      width: '35vw',
      height: '100vh',
      disableClose: false,
      role: 'dialog',
    });
    dialogRef.updatePosition({ top: '0', right: '0', bottom: '0' });
    dialogRef.afterClosed().subscribe(result => { });
  }

}
