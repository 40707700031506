
export const PROGRAM_PAYMENT = [
  { key: 1, text: 'Semester 1', kh_text: "ឆមាស ទី១" },
  { key: 2, text: 'Semester 2', kh_text: "ឆមាស ទី២" },
  { key: 3, text: 'Full Year', kh_text: "តម្លៃ មួយឆ្នាំ" },
]

export const PAYMENT_OPTIONS_WEEK = [
  { key: 1, text: "Full Year", order: 1, month: 12, weeks: 40 },
  { key: 2, text: "Six Months", order: 2, month: 6, weeks: 20 },
  { key: 3, text: "Three Months", order: 3, month: 3, weeks: 10 },
];

export const DISCOUNT_BY_YEAR = [
  { key: 1, text: 'Old Student', kh_text: 'សិស្សចាស់' },
  { key: 2, text: 'New Student', kh_text: 'សិស្សថ្មី' },
  { key: 3, text: 'Employee Child', kh_text: 'កូនបុគ្គលិក' },
]
export const MONTHS_PERIOD = [
  { key: 1, name: 'One Month', nameKh: "មួយខែ", period: 1 },
  { key: 2, name: 'Three Months', nameKh: "បីខែ", period: 3 },
  { key: 3, name: 'Six Months', nameKh: "ប្រាំមួយខែ", period: 6 },
  { key: 4, name: 'One Year', nameKh: "មួយឆ្នាំ", period: 12 },
];

export const paymentType = {
  cash: { key: 1, text: 'Cash', name: "Cash" },
  wingOnline: { key: 2, text: 'Wing Online', note: "PAY WITH WING ONLINE" },
  wingMerchant: { key: 3, text: 'Wing Merchant', note: "PAY WITH WING MERCHANT PAYMENT" },
  abaBilling: { key: 4, text: 'ABA Billing', note: "PAY WITH ABA BILL PAYMENT" },
  acledaBilling: { key: 5, text: 'ACLEDA Billing', note: "PAY WITH ACLEDA BILL PAYMENT" },
  tmBilling: { key: 6, text: 'True Money Billing', note: "PAY WITH True Money BILL PAYMENT" },
}

export const BANK_PAYMENT = {
  WINGO: { key: "WING_O", text: 'WING ONLINE', order: 2 },
  WING: { key: "WING", text: 'WING', order: 3 },
  ABA: { key: "ABA", text: 'ABA', order: 4 },
  AC: { key: "AC", text: 'ACLEDA', order: 5 },
  TM: { key: "TM", text: 'TM', order: 6 },
}

export const PREPAID_STATUS = {
  void: { key: 1, text: 'Void' },
  penalty: { key: 2, text: 'Penalty' },
  add_prepaid: { key: 3, text: 'Add Prepaid', factor: 1 },
  clear_prepaid: { key: 4, text: 'Clear Prepaid', factor: -1 },
}

export const SCHOOL_FEE_PAYMENT = {
  lessPayment: { key: 1, text: 'Less Payment' },
  installment: { key: 2, text: 'Installment' },
}

export const INSTALLMENT_PAYMENT_STATUS = {
  pending: { key: 1, text: 'Pending' },
  completed: { key: 2, text: 'Completed' },
  void: { key: 3, text: 'Void' },
}

export const LINE_TYPE_OBJECT = {
  SPECIAL: { key: 0, name: 'Special' },
  ITEM: { key: 1, name: 'Item' },
}

export const PROGRAM_TERMS = [
  { key: 1, text: 'Term 1' },
  { key: 2, text: 'Term 2' },
  { key: 3, text: 'Term 3' },
  { key: 4, text: 'Term 4' },
]

export const PROGRAM_TERMS_OBJ = {
  term1: { key: 1, text: 'Term 1' },
  term2: { key: 2, text: 'Term 2' },
  term3: { key: 3, text: 'Term 3' },
  term4: { key: 4, text: 'Term 4' },
}

export const PERIOD_FEE = [
  { key: 1, text: '3 Months', period: 3 },
  { key: 2, text: '6 Months', period: 6 },
  { key: 3, text: '9 Months', period: 9 },
  { key: 4, text: '1 Year', period: 12 },
]

export const ADJUSTMENT_TYPES = [
  { key: 1, IR: 'I', name: 'Opening Balance', factor: 1, short: 'OB' },
  { key: 2, IR: 'I', name: 'Increase Stock', short: 'ADJ+', factor: 1 },
  // { key: 3, IR: 'I', name: 'Transfer+', factor: 1 },
  { key: 4, IR: 'R', name: 'Reduce Stock', short: 'ADJ-', factor: -1 },
  // { key: 5, IR: 'R', name: 'Transfer-', factor: -1 },
  // { key: 6, IR: 'I', name: 'Bill', short: 'BILL', factor: 1 },
  { key: 6, IR: 'R', name: 'Sale', factor: -1 },
  { key: 7, IR: 'R', name: 'Void', factor: 1 },
]

export const ADJUSTMENT_TYPES_OBJ = {
  ob: { key: 1, IR: 'I', name: 'Opening Balance', factor: 1, short: 'OB' },
  adjI: { key: 2, IR: 'I', name: 'Increase Stock', short: 'ADJ+', factor: 1 },
  // { key: 3, IR: 'I', name: 'Transfer+', factor: 1 },
  adjR: { key: 4, IR: 'R', name: 'Reduce Stock', short: 'ADJ-', factor: -1 },
  // { key: 5, IR: 'R', name: 'Transfer-', factor: -1 },
  // { key: 6, IR: 'I', name: 'Bill', short: 'BILL', factor: 1 },
  sale: { key: 6, IR: 'R', name: 'Sale', factor: -1 },
  void: { key: 7, IR: 'I', name: 'Void', factor: 1 },
}

export const PROGRAM_OPTION_OBJ = {
  yearBase: { key: 1, text: 'Year Base' },
  termBase: { key: 2, text: 'Term Base' },
}

export const batchStatusObj = {
  active: { key: 1, text: 'Active' },
  disable: { key: 2, text: 'Disable' },
  close: { key: 3, text: 'Close' },
}

export const MOVEMENT_STATUS_OBJ = {
  HOLD: { key: 0, text: 'Hold' },
  RELEASED: { key: 1, text: 'Released' },
  VOID: { key: 2, text: 'Void' },
}

export const Genders = [
  { key: 1, text: 'Male' },
  { key: 2, text: 'Female' },
  // { key: 3, text: 'Monk' },
];

export const TermStatus = [
  { key: 1, text: 'Pending' },
  { key: 2, text: 'Active' },
  { key: 3, text: 'Close' },
];

export const Scholarships = {
  Approval: { key: 0, text: "Approval" },
  Reject: { key: 1, text: "Reject" },
  Pending: { key: 2, text: "Pending" }
};

export const ShiftStatusObj = {
  opening: { key: 1, name: 'Opening' },
  closed: { key: 2, name: 'Closed' },
}

export const studentFeeTypes = [
  { key: 1, text: 'Cambodia' },
  { key: 2, text: 'Foreigner' },
]

export const studentFeeTypeObj = {
  cambodia: { key: 1, text: 'Cambodia' },
  foreigner: { key: 2, text: 'Foreigner' },
}

export const Status = [
  { key: 1, text: 'Pending' },
  { key: 2, text: 'Active' },
  { key: 3, text: 'Close' },
]

export const REQUEST_STATUS = {
  pending: { key: 1, text: 'Pending' },
  approved: { key: 2, text: 'Approved' },
  canceled: { key: 3, text: 'Rejected' },
}

export const REQUEST_STATUS_ARRAY = [
  { key: 1, text: 'Pending' },
  { key: 2, text: 'Approved' },
  { key: 3, text: 'Rejected' },
]

export const programTypesList = [
  { key: 0, text: 'All' },
  { key: 1, text: 'Week Day' },
  { key: 2, text: 'Weekend' },
  { key: 3, text: 'WD/WK' },
]

// export const programsObj = {
//   undergraduate: 1,
//   graduate: 2,
//   postgraduate: 3,
//   association: 4,
//   short_course: 5,
//   others: 6,
//   abc_course: 7,
//   testing_fee: 8,
//   spel_fee: 9,
//   english_program: 10,
//   psis_program: 11,
//   uat_program: 12,
// }
// export const programs = [
//   { key: 1, text: 'Undergraduate' },
//   { key: 2, text: 'Graduate' },
//   { key: 3, text: 'Postgraduate' },
//   { key: 4, text: 'Association' },
//   { key: 5, text: 'Short Course' },
//   { key: 6, text: 'Miscellaneous', name: 'Miscellaneous' },
//   { key: 7, text: 'ABC Course' },
//   { key: 10, text: 'English Program' },
//   { key: 11, text: 'Japanese Program' },
//   { key: 12, text: 'UAT Program' },
// ]

export const programsObj = {
  primarySchool: 1,
  secondarySchool: 2,
  highSchool: 3,
  miscellaneous: 4,
}
export const programs = [
  { key: 1, text: 'Primary School' },
  { key: 2, text: 'Secondary School' },
  { key: 3, text: 'High School' },
  { key: 4, text: 'Miscellaneous' },
]

export const programTypes = [
  { key: 1, text: 'Week Day' },
  { key: 2, text: 'Weekend' },
  { key: 3, text: 'Executive' },
]
export const feeStatus = [
  { key: 1, text: 'Active' },
  { key: 2, text: 'Disabled' },
]

export const InvoiceTypes = [
  { key: 1, text: "Testing" },
  { key: 2, text: "Tuition Fee" },
  { key: 3, text: "Short Course" },
  { key: 4, text: "Academic Bridging Course" },
  { key: 5, text: "SPEL" },
  { key: 6, text: "Miscellaneous" },
  { key: 7, text: "Scholarship Penalty" },
  { key: 8, text: "Registration Fee" },
  { key: 9, text: "Admission Fee" },
];

export const invoiceTypesObj = {
  testing: { key: 1, text: "Testing" },
  tuitionFee: { key: 2, text: "Tuition Fee" },
  shortCourse: { key: 3, text: "Short Course" },
  abcCourse: { key: 4, text: "Academic Bridging Course" },
  spel: { key: 5, text: "SPEL" },
  miscellaneous: { key: 6, text: "Miscellaneous" },
  scholarshipPenalty: { key: 7, text: "Scholarship Penalty" },
  registrationFee: { key: 8, text: "Registration Fee" },
  admissionFee: { key: 9, text: "Admission Fee" },
  otherFee: { key: 10, text: "Other Fee" },
  posFee: { key: 11, text: "Inventory Fee" },
  capitalFee: { key: 12, text: "Capital Fee" },
  feeWithPeriod: { key: 13, text: "Fee with period" },
};

export const invoiceTypeParams = {
  testing: { key: 1, text: "Testing" },
  tuition_fee: { key: 2, text: "Tuition Fee" },
  academic_program: { key: 2, text: "Academic Tuition Fee" },
  institute_and_center: { key: 2, text: "Institute Tuition Fee" },
  short_course: { key: 3, text: "Short Course" },
  abc: { key: 4, text: "Academic Bridging Course" },
  miscellaneous: { key: 6, text: "Miscellaneous" },
  scholarshipPenalty: { key: 7, text: "Scholarship Penalty" },
  registrationFee: { key: 8, text: "Registration Fee" },
  admissionFee: { key: 9, text: "Admission Fee" },
  otherFee: { key: 10, text: "Other Fee" },
  posFee: { key: 11, text: "Inventory Fee" },
  capitalFee: { key: 12, text: "Capital Fee" },
  feeWithPeriod: { key: 13, text: "Fee with period" },
};

export const TestingStatus = {
  active: { key: 1, text: 'Active' },
  disables: { key: 2, text: 'Disabled' },
  expired: { key: 3, text: 'Expired' },
}

export const studentStatus = {
  activePsis: { key: "psis", active: true, firstPayment: false },
  activeFreshPsis: { key: "psis", active: true, firstPayment: true },
  disablePsis: { key: "psis", active: false, firstPayment: false },
  activeInstitute: { key: "institute", active: true, firstPayment: false },
  activeFreshInstitute: { key: "institute", active: true, firstPayment: true },
  disableInstitute: { key: "institute", active: false, firstPayment: false },
  activeAcademic: { key: "academic", active: true, firstPayment: false },
  activeFreshAcademic: { key: "academic", active: true, firstPayment: true },
  disableAcademic: { key: "academic", active: false, firstPayment: false },
}

export const enrollPrograms = {
  academic: { key: 1, text: 'Academic Program' },
  abc: { key: 2, text: 'Academic Bridging Course' },
  institutes: { key: 3, text: 'Institutes & Center' },
  shortCourse: { key: 4, text: 'Short Course' },
}

export const ACADEMIC_YEAR_STATUS = [
  { key: 1, text: 'Hold' },
  { key: 2, text: 'Active' },
  { key: 3, text: 'Close' },
];

export const ACADEMIC_YEAR_STATUS_OBJ = {
  hold: { key: 1, text: 'Hold' },
  active: { key: 2, text: 'Active' },
  close: { key: 3, text: 'Close' },
};

export const RegistrarStatus = {
  pending: { key: 1, text: 'Pending' },
  done: { key: 2, text: 'Done' },
  complete: { key: 3, text: 'Complete' },
  disables: { key: 4, text: 'Disabled' },
}

export const InvoiceTypesObj = {
  testing: { key: 1, text: 'Testing' },
  schoolFee: { key: 2, text: 'School Fee' },
  shortCourse: { key: 3, text: 'Short Course' },
}

export const enrollStatus = [
  { key: 1, text: "Pending" },
  { key: 2, text: "Add" },
  { key: 3, text: "Drop" }
];

export const enrollStatusObj = {
  pending: { key: 1, text: "Pending" },
  add: { key: 2, text: "Add" },
  change: { key: 3, text: "Changed" },
  drop: { key: 4, text: "Drop" },
  quit: { key: 5, text: "Quit" },
};

export const allocatedStatus = [
  { key: 0, text: "Opening" },
  { key: 1, text: "Pending" },
  { key: 2, text: "Hold" },
  { key: 3, text: "Complete" }
];

export const allocatedStatusObj = {
  opening: { key: 0, text: "Opening" },
  pending: { key: 1, text: "Pending" },
  hold: { key: 2, text: "Hold" },
  complete: { key: 3, text: "Complete" }
};

export const enrollmentTypes = {
  PSIS: { key: "PSIS", text: "PSIS Program" },
  institute: { key: "INSTITUTE", text: "Institute program" },
  academic: { key: "ACADEMIC", text: "Academic Program" }
};

export const status = [
  { key: 1, text: "Active" },
  { key: 2, text: "Pending" },
  { key: 3, text: "Disabled" },
  { key: 4, text: "Closed" }
];

export const recordStatus = {
  active: { key: 1, text: 'Active' },
  disables: { key: 2, text: 'Disabled' },
}

export const admissionPrograms = {
  academic: { key: 1, text: 'Academic Program' },
  abc: { key: 2, text: 'ABC Course' },
  institutes: { key: 3, text: 'Institutes & Center' },
  shortCourse: { key: 4, text: 'Short Course' },
}

export const TestFeeStatus = {
  paid: { key: 1, name: 'Paid' },
  unpaid: { key: 2, name: 'Unpaid' },
  prepaid: { key: 3, name: 'Prepaid' },
  void: { key: 4, name: 'Void' },
  disables: { key: 5, name: 'Disables' },
}

export const searchFilterBy = [
  { key: "puc_id", text: "Student ID" },
  { key: "serial_id", text: "Serial No" },
  { key: "full_name", text: "Full Name" },
  { key: "first_name", text: "First Name" },
  { key: "last_name", text: "Last Name" },
  { key: "mobile_phone", text: "Phone Number" }
];

export const FILTER_PRODUCT = [
  { key: "barcode", text: "Barcode" },
  { key: "name", text: "Product Name" },
];

export const SALE_TYPE = [
  { key: 1, text: "Sale" },
  { key: 2, text: "Free" },
];

export const paymentStatus = {
  paid: { key: 1, text: "Paid" },
  unpaid: { key: 2, text: "Unpaid" },
  prepaid: { key: 3, text: "Prepaid" },
  void: { key: 4, text: "Void" },
};

export const EnrollStatus = {
  none: { key: 1, text: "None" },
  add: { key: 2, text: "Add" },
  change: { key: 3, text: "Changed" },
  drop: { key: 4, text: "Dropped" },
  assign: { key: 5, text: "Assign" }
};

export const instituteNoScheduleObj = {
  fail: { key: 1, text: "Fail" },
  drop: { key: 2, text: "Drop" },
  coming_back: { key: 3, text: "Coming Back" },
  change_program: { key: 4, text: "Change Program" },
};

export const scholarshipTypes = {
  monk: { key: 0, text: 'Monk' },
  scholarship: { key: 1, text: 'Scholarship' },
  loan: { key: 2, text: 'Loan' },
  sibling: { key: 3, text: 'Sibling' },
};


export const reportFilterBy = {
  today: { key: 'today', text: 'Today' },
  this_week: { key: 'this_week', text: 'This week' },
  this_month: { key: 'this_month', text: 'This month' },
  this_term: { key: 'this_term', text: 'This term' },
  this_year: { key: 'this_year', text: 'This year' },
  custom: { key: 'custom', text: 'Custom' },
};


export const miscellaneousPayment = {
  today: { key: 'today', text: 'Today' },
  this_week: { key: 'this_week', text: 'This week' },
  this_month: { key: 'this_month', text: 'This month' },
  this_term: { key: 'this_term', text: 'This term' },
  this_year: { key: 'this_year', text: 'This year' },
  custom: { key: 'custom', text: 'Custom' },
};


export const userRolesObj = {
  administrator: { key: 1, text: "Administrator" },
  testing: { key: 2, text: "Testing" },
  cashier: { key: 3, text: "Cashier" },
  DAA: { key: 4, text: "DAA" },
  enrollment: { key: 5, text: "Enrollment" },
  registrar: { key: 6, text: "Registrar" },
  faculty_manager: { key: 7, text: "Faculty Manager" },
  scholarship: { key: 8, text: "Scholarship" },
  human_resource: { key: 9, text: "Human Resource" },
  financial: { key: 10, text: "Financial" },
};


export const appObjects = {
  cashier: { key: 'cashier', text: "Cashier" },
  enrollment: { key: 'class_manager', text: "Class Manager" },
  testing: { key: 'testing_center', text: "Testing Center" },
  scholarship: { key: 'scholarship', text: "Scholarship" },
  finance: { key: 'finance', text: "Finance" },
  reporting: { key: 'reporting', text: "Reporting" },
};

export const appArray = [
  { key: 'cashier', text: "Cashier" },
  { key: 'class_manager', text: "Class Manager" },
  { key: 'testing_center', text: "Testing Center" },
  { key: 'scholarship', text: "Scholarship" },
  { key: 'finance', text: "Finance" },
  { key: 'reporting_app', text: "Reporting App" },
]

export const memberOf = [
  { key: 0, text: "Administrator" },
  { key: 1, text: "Branch Manager" },
  { key: 2, text: "Guest" },
];

export const memberOfObj = {
  administrator: { key: 0, text: "Administrator" },
  branch_manager: { key: 1, text: "Branch Manager" },
  guest: { key: 2, text: "Guest" },
};

export const studentTypes = {
  pucStudent: { key: 1, text: "Student" },
  assign: { key: 2, text: "Assign to Academic Student" },
  transferStudent: { key: 3, text: "Transfer Student" }
};

export const admissionType = {
  psis: { key: 1, text: 'PSIS', },
  institute: { key: 2, text: 'Institute' },
  academic: { key: 3, text: 'Academic' },
}

export const termsModules = [
  { key: 'semester_term', text: 'Semester Term', order: 1, icon: 'spa', type: 'Academic' },
  { key: 'summer_term', text: 'Summer Term', order: 2, icon: 'wb_sunny', type: 'Academic' },
  { key: 'accelerated_term', text: 'Accelerated Term', order: 3, icon: 'pages', type: 'Academic' },
  { key: 'english_term', text: 'English Term', order: 4, icon: 'pages', type: 'English' },
  { key: 'japanese_term', text: 'Japanese Term', order: 5, icon: 'pages', type: 'Japanese' },
]

export const termsModulesObj = {
  semester_term: { key: 'semester_term', text: 'Semester Term', order: 1, icon: 'spa', type: 'Academic' },
  summer_term: { key: 'summer_term', text: 'Summer Term', order: 2, icon: 'wb_sunny', type: 'Academic' },
  accelerated_term: { key: 'accelerated_term', text: 'Accelerated Term', order: 3, icon: 'pages', type: 'Academic' },
  english_term: { key: 'english_term', text: 'English Term', order: 4, icon: 'pages', type: 'English' },
  japanese_term: { key: 'japanese_term', text: 'Japanese Term', order: 5, icon: 'pages', type: 'Japanese' },
}


export const academicProgramObj = {
  associate: { key: 'associate', routerId: 'associate', active: true, icon: 'supervised_user_circle', name: 'Associate', order: 0, text: 'Associate', level: 'Undergraduate', type: 'ACADEMIC' },
  bachelor: { key: 'bachelor', routerId: 'bachelor', active: true, icon: 'supervised_user_circle', name: 'Bachelor', order: 1, text: 'Bachelor', level: 'Undergraduate', type: 'ACADEMIC' },
  pre_master: { key: 'pre_master', routerId: 'pre_master', active: true, icon: 'supervised_user_circle', name: 'Pre Master', order: 2, text: 'Pre Master', level: 'Pre Master', type: 'ACADEMIC' },
  master: { key: 'master', routerId: 'master', active: true, icon: 'supervised_user_circle', name: 'Master', order: 3, text: 'Master', level: 'Graduate', type: 'ACADEMIC' },
  postgraduate: { key: 'postgraduate', routerId: 'postgraduate', active: true, icon: 'supervised_user_circle', name: 'Doctor or PhD', order: 4, text: 'Doctor or PhD', level: 'Graduate', type: 'ACADEMIC' },
}

export const StatusObj = {
  DISABLED: { key: 0, text: "Disabled" },
  ACTIVE: { key: 1, text: "Active" },
};

export const PAYMENT_OPTIONS = [
  { key: "FULL_YEAR", text: "Full Year", order: 1, month: 12 },
  { key: "SIX_MONTHS", text: "Six Months", order: 2, month: 6 },
  { key: "THREE_MONTHS", text: "Three Months", order: 3, month: 4 },
  { key: "ONE_MONTH", text: "One Month", order: 4, month: 1 },
];

export const PAYMENT_OPTIONS_OBJ = {
  FULL_YEAR: { key: "FULL_YEAR", text: "Full Year", order: 1, month: 12 },
  SIX_MONTHS: { key: "SIX_MONTHS", text: "Six Months", order: 2, month: 6 },
  THREE_MONTHS: { key: "THREE_MONTHS", text: "Three Months", order: 3, month: 4 },
  ONE_MONTH: { key: "ONE_MONTH", text: "One Month", order: 4, month: 1 },
};

export const SCHOOL_PROGRAM = [
  { key: 1, text: 'Nursery', },
  { key: 2, text: 'Kindergarten' },
  { key: 3, text: 'Primary School' },
  { key: 4, text: 'Secondary School' },
  { key: 5, text: 'High school' },
];

export const SCHOOL_PROGRAM_OBJ = {
  nursery: { key: 1, text: 'Nursery' },
  kindergarten: { key: 2, text: 'Kindergarten' },
  primarySchool: { key: 3, text: 'Primary School' },
  secondarySchool: { key: 4, text: 'Secondary School' },
  highSchool: { key: 5, text: 'High school' },
};

export const SCHOOL_TYPE = [
  { key: 1, text: 'Khmer' },
  { key: 2, text: 'English' },
  { key: 3, text: 'Khmer & English' },
];

export const SCHOOL_TYPE_OBJ = {
  khmer: { key: 1, text: 'Khmer' },
  english: { key: 2, text: 'English' },
  khmerNEnglish: { key: 3, text: 'Khmer & English' },
};

export const MONTHS_LIST = [
  { key: 1, text: '1 Month' },
  { key: 2, text: '2 Months' },
  { key: 3, text: '3 Months' },
  { key: 4, text: '4 Months' },
  { key: 5, text: '5 Months' },
  { key: 6, text: '6 Months' },
  { key: 7, text: '7 Months' },
  { key: 8, text: '8 Months' },
  { key: 9, text: '9 Months' },
  { key: 10, text: '10 Months' },
  { key: 11, text: '11 Months' },
  { key: 12, text: '12 Months' },
];

export const Nationality = [
  { key: 1, text: 'Khmer' },
  { key: 2, text: 'Foreigner' },
];

export const PARENTS_STATUS = [
  { key: 1, text: 'Living Together' },
  { key: 2, text: 'Living Apart' },
  { key: 3, text: 'Divorce' },
  { key: 4, text: 'Widow / Widower' },
];

export const STUDENT_STAY_WITH = [
  { key: 1, text: 'Parents' },
  { key: 2, text: 'Mother' },
  { key: 3, text: 'Father' },
  { key: 4, text: 'Others' },
];

export const Gender = [
  { key: 1, text: 'Male' },
  { key: 2, text: 'Female' },
  // { key: 3, text: 'Monk' },
];

export const CAN_STATUS = [
  { key: 1, text: 'Yes' },
  { key: 2, text: 'No' },
];

export const DISCOUNT_TYPES = [
  { key: 1, text: 'Percentage' },
  { key: 2, text: 'Cash' },
]

export const SUBJECT_DATA = {
  khmer: { key: 1, name: 'ភាសាខ្មែរ', enName: 'Khmer' },
  math: { key: 2, name: 'គណិតវិទ្យា', enName: 'Math' },
  english: { key: 3, name: "ភាសាអង់គ្លេស", enName: "English" }
}

export const USER_ROLE = [
  { key: 1, text: 'National' },
  { key: 2, text: 'Province' },
  { key: 3, text: 'District' },
  { key: 4, text: 'Commune' },
]

export const DISCOUNT_POLICY_STUDENT = [
  { key: 1, text: 'All' },
  { key: 2, text: 'Old' },
  { key: 3, text: 'New' },
]

export const DISCOUNT_POLICY_STUDENT_OBJ = {
  all: { key: 1, text: 'All' },
  old: { key: 2, text: 'Old' },
  new: { key: 3, text: 'New' },
}
