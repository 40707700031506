
import { DataService } from "./../services/data.service";
import { observable, action } from "mobx";
import { Injectable } from "@angular/core";
import { academicYearObj, MappingService } from '../services/mapping.service';
import { ConvertService } from "../services/convert.service";
import { enrollmentTypes, invoiceTypesObj, enrollPrograms, paymentStatus, EnrollStatus, instituteNoScheduleObj, recordStatus, enrollStatusObj, admissionType, studentTypes } from "../dummy/status";
import { IInvoice } from "../interfaces/invoice";
import { IEnrollment } from "../interfaces/enrollment";
import { IAdmission } from "../interfaces/admission";
import { Router } from "@angular/router";

@Injectable()
export class InstituteSchedule {
  @observable public data = null;
  @observable public loading = false;
  @observable public process = false;

  @observable public levels = null;
  @observable public programs = null;
  @observable public shifts = null;
  @observable public sessions = null;
  @observable public campus = null;
  @observable public instituteAdmission = null;
  @observable public instituteTerms = null;


  constructor(private ds: DataService, private router: Router) { }

  @action
  async fetchConfig(studentKey: string, callback) {
    this.process = true;
    this.instituteAdmission = null;

    const programsDoc = await this.ds.programRef().get().toPromise();
    this.programs = MappingService.pushToArray(programsDoc);
    const levelsDoc = await this.ds.levelTestTypeRef().get().toPromise();
    this.levels = MappingService.orderBy(MappingService.pushToArray(levelsDoc), "level.order");
    const shiftsDoc = await this.ds.shiftRef().get().toPromise();
    this.shifts = MappingService.pushToArray(shiftsDoc);
    const sessionDoc = await this.ds.sessionRef().get().toPromise();
    this.sessions = MappingService.pushToArray(sessionDoc);
    const campusDoc = await this.ds.campusRef().get().toPromise();
    this.campus = MappingService.pushToArray(campusDoc);

    const admissionDoc = await this.ds.studentAdmissionEnDocRef(studentKey).get().toPromise();
    const admissionArray = MappingService.pushToArray(admissionDoc);
    if (admissionArray && admissionArray.length > 0) this.instituteAdmission = admissionArray[0]

    this.process = false;
    callback(this.programs)
  }

  @action
  async fetchInstituteTerm(instituteKey: string, callback) {
    this.process = true;

    const instituteTermsDoc = await this.ds.instituteTermDocsRef(instituteKey).get().toPromise();
    const data = MappingService.pushToArray(instituteTermsDoc);
    this.instituteTerms = MappingService.orderByDesc(data, "startterm");

    this.process = false;
    callback(this.instituteTerms)
  }

  @action
  async editPaymentInfo(form: any, invoice: any, student: any, user: any, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const { target_term, study_session, target_campus } = form;
    const instituteNoScheduleRef = this.ds.studentInstituteNoScheduleFireRef();
    const testingRef = this.ds.testingFireRef();
    const studentRef = this.ds.studentFireRef().doc(student.key);

    const instituteNoScheduleDoc = await this.ds.studentInstituteNoScheduleDBRef().doc(invoice.key).get().toPromise();
    const instituteNoScheduleData = MappingService.pushToObject(instituteNoScheduleDoc);

    const testingDoc = await this.ds.testingDBRef().doc(invoice.key).get().toPromise();
    const testingData = MappingService.pushToObject(testingDoc);

    const data = {
      target_term: academicYearObj(target_term),
      study_session: study_session,
      target_campus: MappingService.campusObj(target_campus),
      update_date: new Date(),
      update_by: MappingService.userObj(user),
    }

    if (instituteNoScheduleData) {
      batch.update(instituteNoScheduleRef.doc(instituteNoScheduleData.key), { ...data });
    }

    if (testingData) {
      batch.update(testingRef.doc(testingData.key), { ...data });
    }

    batch.update(studentRef.collection("invoices").doc(invoice.key), { ...data });
    batch.update(studentRef.collection("invoices").doc(invoice.headerRef), { ...data });

    batch.commit().then(() => {
      this.process = false;
      callback(true, null);
    }).catch(error => {
      this.process = false;
      callback(false, error);
    })
  }

  @action
  async resolveEnglishFee(item: any, admission: any, student: any, user: any, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const studentRef = this.ds.studentFireRef();
    const admissionRef = this.ds.studentAdmissionFireRef();
    const instituteNoScheduleRef = this.ds.studentInstituteNoScheduleFireRef();
    const batchLevelRef = this.ds.batchLevelFireRef();
    const noScheduleRef = this.ds.studentInstituteNoScheduleFireRef();
    const termRef = this.ds.termFireRef();
    const transcriptRef = this.ds.transcriptFireRef();
    const { program, level, target_term, target_campus, study_session, target_session } = item;

    const { program_academic } = admission;
    const { category, type, admissionKey, scholarshipKey } = program_academic;

    const programAcademic = {
      category: category,
      type: type,
      key: program.key,
      name: program.name,
      admissionKey: admissionKey,
      scholarshipKey: ConvertService.toNull(scholarshipKey),
      start_level_key: level.key,
      start_level_name: level.name,
    }

    //UPDATE INVOICES
    const invoiceData = await this.ds.studentDocument(student.key).collection("invoices", ref =>
      ref.where("school_fee_type.key", "==", 3).where("enrollment_type.key", "==", enrollmentTypes.institute.key).where("issue_term.key", "==", target_term.key))
      .get().toPromise();
    const invoiceDocs = MappingService.pushToArray(invoiceData);

    const headerKey = this.ds.createId();
    const invoiceNo = null;

    const date = new Date();
    const create_by = MappingService.userObj(user);
    const campus = user ? MappingService.campusObj(user.campus) : null;
    const courseProgram = programAcademic;
    const invoiceType = invoiceTypesObj.tuitionFee;
    const displayProgram = level.program.shortName ? level.program.shortName : programAcademic.name;;
    const displayLevel = level.name;
    const displayShift = study_session.name;
    const schoolFeeType = enrollPrograms.institutes;
    const description = displayProgram + ' ' + displayLevel;
    const grandTotal = level.fee.price * 1;

    const invoiceHeader: IInvoice = {
      key: headerKey,
      display_program: displayProgram,
      display_level: displayLevel,
      display_shift: displayShift,
      shiftKey: study_session.key,
      create_date: date,
      create_date_key: ConvertService.dateKey(),
      create_by: create_by,
      issue_by: create_by,
      issue_date: date,
      invoice_no: invoiceNo,
      invoice_type: invoiceType,
      school_fee_type: schoolFeeType,
      student: MappingService.studentObj(student),
      verify_by: create_by,
      verify_date: date,
      page_key: ConvertService.pageKey(),
      verify_date_key: ConvertService.dateKey(),
      invoice_date: date,
      invoice_date_key: ConvertService.dateKey(),
      course: null,
      isPaid: paymentStatus.unpaid,
      isVoid: false,
      program: courseProgram,
      enrollment_type: enrollmentTypes.institute,
      byAdmission: false,
      issue_term: academicYearObj(target_term),
      payment_term: null,
      headerRef: headerKey,
      isHeader: true,
      enroll_status: EnrollStatus.add,
      isEnrollVerify: true,
      campus: MappingService.campusObj(campus),
      schoolSession: null,
      description: description,

      penaltyRef: null,
      penalty: null,
      scholarshipRef: null,
      scholarship: null,
      loan: null,
      prepaidRef: null,
      prepaid: null,
      price: grandTotal,
      amount: grandTotal,

      target_campus: MappingService.campusObj(target_campus),
      target_term: MappingService.campusObj(target_term),
      study_session: study_session,
    }
    const detailKey = this.ds.createId();
    const invoiceDetail: IInvoice = {
      key: detailKey,
      display_program: displayProgram,
      display_level: displayLevel,
      display_shift: displayShift,
      shiftKey: study_session.key,
      create_date: date,
      create_date_key: ConvertService.dateKey(),
      create_by: create_by,
      issue_by: create_by,
      issue_date: date,
      invoice_no: invoiceNo,
      invoice_type: invoiceTypesObj.tuitionFee,
      school_fee_type: schoolFeeType,
      student: MappingService.studentObj(student),
      verify_by: create_by,
      verify_date: date,
      page_key: ConvertService.pageKey(),
      verify_date_key: ConvertService.dateKey(),
      invoice_date: date,
      invoice_date_key: ConvertService.dateKey(),
      course: level,
      enrollmentKey: detailKey,
      testingKey: detailKey,
      enrollment_type: enrollmentTypes.institute,
      isFreshmen: false,
      isPaid: paymentStatus.unpaid,
      isVoid: false,
      program: courseProgram,
      byAdmission: false,
      issue_term: academicYearObj(target_term),
      payment_term: null,
      headerRef: headerKey,
      isHeader: false,
      enroll_status: EnrollStatus.assign,
      isEnrollVerify: true,
      campus: MappingService.campusObj(campus),
      schoolSession: null,
      description: description,

      penaltyRef: null,
      penalty: null,
      scholarshipRef: null,
      scholarship: null,
      loan: null,
      prepaidRef: null,
      prepaid: null,
      price: grandTotal,
      amount: grandTotal,
      target_campus: MappingService.campusObj(target_campus),
      target_term: MappingService.campusObj(target_term),
      study_session: study_session,
      institute_no_schedule: instituteNoScheduleObj.change_program,
      is_no_schedule: true,
    }

    const enrollment: IEnrollment = {
      key: detailKey,
      enrollmentOldKey: detailKey,
      isFreshmen: false,
      admissionKey: programAcademic.admissionKey,
      create_date: date,
      create_by: create_by,
      status: recordStatus.active,
      page_key: ConvertService.pageKey(),
      enroll_status: enrollStatusObj.add,
      enrollment_type: enrollmentTypes.institute,
      enroll_date: date,
      enroll_date_key: ConvertService.dateKey(),
      student: student,
      subject: level,
      fee: MappingService.feeObj(level.fee),
      campus: campus,
      shift: study_session,
      major: null,
      isStudentEnroll: false,
      courseCode: null,
      courseName: level.name,
      credits_attempted: null,
      credits_calculator: 0,
      credits_earned: 0,
      gradePoint: 0,
      gradeRef: null,
      isCompleted: false,
      active: true,
      isEnroll: false,
      included: true,
      isImport: false,
      isShow: true,
      labHours: null,
      lecHours: null,
      overall_grade: null,
      program: programAcademic,
      term: academicYearObj(target_term),
      schedule: null,
      isApproval: true,
      isPaid: false,
      target_campus: MappingService.campusObj(target_campus),
      target_term: academicYearObj(target_term),
      study_session: study_session,
      target_session: MappingService.sessionTimeObj(target_session),
      price: level.fee.price,
      qty: 1,
      total: level.fee.price * 1,
      scholarship: null,
      scholarshipRef: null,
      loan: null,
      prepaid: null,
      installment: null,
      grand_total: level.fee.price * 1,
      institute_no_schedule: instituteNoScheduleObj.coming_back,
      is_no_schedule: true,
    };

    if (invoiceDocs && invoiceDocs.length > 0) {
      const unPaidInvoices = invoiceDocs.filter(m => m.isPaid.key === paymentStatus.unpaid.key);

      if (unPaidInvoices && unPaidInvoices.length > 0) {
        const enrollmentData = await this.ds.studentDocument(student.key).collection("institute_enrollment").get().toPromise();
        const enrollmentDoc = MappingService.pushToArray(enrollmentData);

        if (enrollmentDoc && enrollmentDoc.length > 0) {
          enrollmentDoc.forEach(enroll => {
            const { key, admissionKey } = enroll;
            batch.delete(studentRef.doc(student.key).collection("institute_enrollment").doc(key));
            batch.delete(termRef.doc(target_term.key).collection("institute_enrollment").doc(key));
            batch.delete(transcriptRef.doc(student.key).collection("admission").doc(admissionKey).collection("courses").doc(key));
          })
        }

        unPaidInvoices.forEach(inv => {
          batch.delete(studentRef.doc(student.key).collection("invoices").doc(inv.key));
        });

        //NEW INVOICE
        batch.set(studentRef.doc(student.key).collection("invoices").doc(invoiceHeader.key), invoiceHeader);
        batch.set(studentRef.doc(student.key).collection("invoices").doc(invoiceDetail.key), invoiceDetail);
        batch.set(instituteNoScheduleRef.doc(enrollment.key), enrollment);
      }
    }

    const instituteKey = category.key;
    const studentBatch = student[instituteKey];

    //HAVE BATCH
    if (studentBatch) {
      const batchLevelData = await this.ds.batchLevelRef().doc(studentBatch.key).collection("students", ref => ref.where("student.key", "==", student.key)).get().toPromise();;
      const batchLevelDoc = MappingService.pushToArray(batchLevelData);

      if (batchLevelDoc && batchLevelDoc.length > 0) {
        const studentInBatch = batchLevelDoc[0];
        batch.delete(batchLevelRef.doc(studentBatch.key).collection("students").doc(studentInBatch.key));
      }
    }

    const noScheduleData = await this.ds.instituteNoScheduleRef(student.key).get().toPromise();;
    const noScheduleDoc = MappingService.pushToArray(noScheduleData);
    if (noScheduleDoc && noScheduleDoc.length > 0) {
      batch.delete(noScheduleRef.doc(noScheduleDoc[0].key));
    }

    const admissionData = await this.ds.admissionByStudentRef(student.key).get().toPromise();;
    const admissionDocs = MappingService.pushToArray(admissionData);
    if (admissionDocs && admissionDocs.length > 0) {
      const otherAdmission = admissionDocs.filter(m => m.key !== admission.key &&
        m.admission_type.key === admissionType.institute.key &&
        m.program_academic.category.key === admission.program_academic.category.key);

      if (otherAdmission && otherAdmission.length > 0) {
        otherAdmission.forEach(ads => {
          batch.delete(admissionRef.doc(ads.key))
        });
      }
    }

    batch.update(admissionRef.doc(admission.key), {
      program_academic: programAcademic,
    });

    batch.update(studentRef.doc(student.key), {
      [instituteKey]: null,
      program_academic: programAcademic,
      resolveEnglishFee: true,
      resolve_fee_by: MappingService.userObj(user),
      resolve_fee_date: new Date(),
    });

    batch.commit().then(() => {
      this.process = false;
      callback(true, null);
    }).catch(error => {
      this.process = false;
      callback(false, error);
    })
  }

  @action
  async resolveScholarship(item: any, student: any, user: any, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const studentRef = this.ds.studentFireRef();
    const admissionRef = this.ds.studentAdmissionFireRef();
    const { program, level, target_term, target_campus, study_session } = item;

    const admissionKey = this.ds.createId();
    const programAcademic = {
      category: {
        key: "DBrSzLY3UQ2kEgF50nfU",
        name: "English Program"
      },
      type: {
        key: 1,
        text: "Institute & Center"
      },
      key: program.key,
      name: program.name,
      admissionKey: admissionKey,
      scholarshipKey: "DBrSzLY3UQ2kEgF50nfU",
      start_level_key: level.key,
      start_level_name: level.name,
    }

    const admission: IAdmission = {
      key: admissionKey,
      create_date: new Date(),
      create_by: MappingService.userObj(user),
      status: recordStatus.active,
      date_key: ConvertService.dateKey(),
      page_key: ConvertService.pageKey(),
      term: academicYearObj(target_term),
      puc_generation: null,
      major_generation: null,
      major: null,
      shift: study_session,
      student_type: studentTypes.pucStudent,
      admission_type: admissionType.institute,
      student: MappingService.studentObj(student),
      curriculum: null,
      faculty: null,
      program: programAcademic.type,
      isPaid: false,
      isPaidAdminFee: false,
      isImport: true,
      is_student_institute: true,
      is_student_academic: false,
      program_academic: programAcademic,

      campusKey: null,
      campus: null,
      schoolKey: null,
      school: null,
    };

    batch.set(admissionRef.doc(admission.key), admission);
    batch.update(studentRef.doc(student.key), {
      program_academic: programAcademic,
      resolveAdmission: true,
      resolve_admission_by: MappingService.userObj(user),
      resolve_admission_date: new Date(),
    });

    batch.commit().then(() => {
      this.process = false;
      callback(true, null);
      this.router.navigate([`/student/${student.key}/${admission.key}/receive-payment`]);
    }).catch(error => {
      this.process = false;
      callback(false, error);
    })
  }

  @action
  async addEnglishAdmission(item: any, student: any, user: any, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const studentRef = this.ds.studentFireRef();
    const admissionRef = this.ds.studentAdmissionFireRef();
    const instituteNoScheduleRef = this.ds.studentInstituteNoScheduleFireRef();
    const termRef = this.ds.termFireRef();
    const transcriptRef = this.ds.transcriptFireRef();
    const { program, level, target_term, target_campus, study_session, target_session } = item;

    const admissionKey = this.ds.createId();
    const programAcademic = {
      category: {
        key: "DBrSzLY3UQ2kEgF50nfU",
        name: "English Program"
      },
      type: {
        key: 1,
        text: "Institute & Center"
      },
      key: program.key,
      name: program.name,
      admissionKey: admissionKey,
      scholarshipKey: "DBrSzLY3UQ2kEgF50nfU",
      start_level_key: level.key,
      start_level_name: level.name,
    }

    const headerKey = this.ds.createId();
    const invoiceNo = null;

    const date = new Date();
    const create_by = MappingService.userObj(user);
    const campus = user ? MappingService.campusObj(user.campus) : null;
    const courseProgram = programAcademic;
    const invoiceType = invoiceTypesObj.tuitionFee;
    const displayProgram = level.program.shortName ? level.program.shortName : programAcademic.name;;
    const displayLevel = level.name;
    const displayShift = study_session.name;
    const schoolFeeType = enrollPrograms.institutes;
    const description = displayProgram + ' ' + displayLevel;
    const grandTotal = level.fee.price * 1;

    const invoiceHeader: IInvoice = {
      key: headerKey,
      display_program: displayProgram,
      display_level: displayLevel,
      display_shift: displayShift,
      shiftKey: study_session.key,
      create_date: date,
      create_date_key: ConvertService.dateKey(),
      create_by: create_by,
      issue_by: create_by,
      issue_date: date,
      invoice_no: invoiceNo,
      invoice_type: invoiceType,
      school_fee_type: schoolFeeType,
      student: MappingService.studentObj(student),
      verify_by: create_by,
      verify_date: date,
      page_key: ConvertService.pageKey(),
      verify_date_key: ConvertService.dateKey(),
      invoice_date: date,
      invoice_date_key: ConvertService.dateKey(),
      course: null,
      isPaid: paymentStatus.unpaid,
      isVoid: false,
      program: courseProgram,
      enrollment_type: enrollmentTypes.institute,
      byAdmission: false,
      issue_term: academicYearObj(target_term),
      payment_term: null,
      headerRef: headerKey,
      isHeader: true,
      enroll_status: EnrollStatus.add,
      isEnrollVerify: true,
      campus: MappingService.campusObj(campus),
      schoolSession: null,
      description: description,

      penaltyRef: null,
      penalty: null,
      scholarshipRef: null,
      scholarship: null,
      loan: null,
      prepaidRef: null,
      prepaid: null,
      price: grandTotal,
      amount: grandTotal,

      target_campus: MappingService.campusObj(target_campus),
      target_term: MappingService.campusObj(target_term),
      study_session: study_session,
    }
    const detailKey = this.ds.createId();
    const invoiceDetail: IInvoice = {
      key: detailKey,
      display_program: displayProgram,
      display_level: displayLevel,
      display_shift: displayShift,
      shiftKey: study_session.key,
      create_date: date,
      create_date_key: ConvertService.dateKey(),
      create_by: create_by,
      issue_by: create_by,
      issue_date: date,
      invoice_no: invoiceNo,
      invoice_type: invoiceTypesObj.tuitionFee,
      school_fee_type: schoolFeeType,
      student: MappingService.studentObj(student),
      verify_by: create_by,
      verify_date: date,
      page_key: ConvertService.pageKey(),
      verify_date_key: ConvertService.dateKey(),
      invoice_date: date,
      invoice_date_key: ConvertService.dateKey(),
      course: level,
      enrollmentKey: detailKey,
      testingKey: detailKey,
      enrollment_type: enrollmentTypes.institute,
      isFreshmen: false,
      isPaid: paymentStatus.unpaid,
      isVoid: false,
      program: courseProgram,
      byAdmission: false,
      issue_term: academicYearObj(target_term),
      payment_term: null,
      headerRef: headerKey,
      isHeader: false,
      enroll_status: EnrollStatus.assign,
      isEnrollVerify: true,
      campus: MappingService.campusObj(campus),
      schoolSession: null,
      description: description,

      penaltyRef: null,
      penalty: null,
      scholarshipRef: null,
      scholarship: null,
      loan: null,
      prepaidRef: null,
      prepaid: null,
      price: grandTotal,
      amount: grandTotal,
      target_campus: MappingService.campusObj(target_campus),
      target_term: MappingService.campusObj(target_term),
      study_session: study_session,
      institute_no_schedule: instituteNoScheduleObj.change_program,
      is_no_schedule: true,
    }

    const admission: IAdmission = {
      key: admissionKey,
      create_date: date,
      create_by: create_by,
      status: recordStatus.active,
      date_key: ConvertService.dateKey(),
      page_key: ConvertService.pageKey(),
      term: academicYearObj(target_term),
      puc_generation: null,
      major_generation: null,
      major: null,
      shift: study_session,
      student_type: studentTypes.pucStudent,
      admission_type: admissionType.institute,
      student: MappingService.studentObj(student),
      curriculum: null,
      faculty: null,
      program: programAcademic.type,
      isPaid: false,
      isPaidAdminFee: false,
      isImport: true,
      is_student_institute: true,
      is_student_academic: false,
      program_academic: programAcademic,

      campusKey: null,
      campus: null,
      schoolKey: null,
      school: null,
    };

    const enrollment: IEnrollment = {
      key: detailKey,
      enrollmentOldKey: detailKey,
      isFreshmen: false,
      admissionKey: programAcademic.admissionKey,
      create_date: date,
      create_by: create_by,
      status: recordStatus.active,
      page_key: ConvertService.pageKey(),
      enroll_status: enrollStatusObj.add,
      enrollment_type: enrollmentTypes.institute,
      enroll_date: date,
      enroll_date_key: ConvertService.dateKey(),
      student: student,
      subject: level,
      fee: MappingService.feeObj(level.fee),
      campus: campus,
      shift: study_session,
      major: null,
      isStudentEnroll: false,
      courseCode: null,
      courseName: level.name,
      credits_attempted: null,
      credits_calculator: 0,
      credits_earned: 0,
      gradePoint: 0,
      gradeRef: null,
      isCompleted: false,
      active: true,
      isEnroll: false,
      included: true,
      isImport: false,
      isShow: true,
      labHours: null,
      lecHours: null,
      overall_grade: null,
      program: programAcademic,
      term: academicYearObj(target_term),
      schedule: null,
      isApproval: true,
      isPaid: false,
      target_campus: MappingService.campusObj(target_campus),
      target_term: academicYearObj(target_term),
      study_session: study_session,
      target_session: MappingService.sessionTimeObj(target_session),
      price: level.fee.price,
      qty: 1,
      total: level.fee.price * 1,
      scholarship: null,
      scholarshipRef: null,
      loan: null,
      prepaid: null,
      installment: null,
      grand_total: level.fee.price * 1,
      institute_no_schedule: instituteNoScheduleObj.change_program,
      is_no_schedule: true,
    };

    //NEW INVOICE
    batch.set(studentRef.doc(student.key).collection("invoices").doc(invoiceHeader.key), invoiceHeader);
    batch.set(studentRef.doc(student.key).collection("invoices").doc(invoiceDetail.key), invoiceDetail);
    batch.set(instituteNoScheduleRef.doc(enrollment.key), enrollment);
    batch.set(admissionRef.doc(admission.key), admission);
    batch.update(studentRef.doc(student.key), {
      program_academic: programAcademic,
      resolveAdmission: true,
      resolve_admission_by: MappingService.userObj(user),
      resolve_admission_date: new Date(),
    });

    batch.commit().then(() => {
      this.process = false;
      callback(true, null);
    }).catch(error => {
      this.process = false;
      callback(false, error);
    })
  }

  @action
  async updateInvoices(item: any, invoice: any, student: any, user: any, callback) {
    this.process = true;
    const studentNoScheduleDocs = await this.ds.instituteNoScheduleRef(student.key).get().toPromise();
    const studentInProgressDocs = await this.ds.scheduleInProgressAwaitRef(student.key).get().toPromise();

    const batch = this.ds.batch();
    const { program, level, target_term, target_campus, study_session, target_session } = item;
    const { header, detail } = invoice;
    const studentRef = this.ds.studentFireRef().doc(student.key);
    const data = {
      study_session: study_session,
      target_session: MappingService.sessionTimeObj(target_session),
      target_campus: MappingService.campusObj(target_campus),
      target_term: academicYearObj(target_term),
      issue_term: academicYearObj(target_term),
    }
    batch.update(studentRef.collection("invoices").doc(header.key), {
      ...data,
      update_date: new Date(),
      update_by: MappingService.userObj(user),
    })
    batch.update(studentRef.collection("invoices").doc(detail.key), {
      ...data,
      update_date: new Date(),
      update_by: MappingService.userObj(user),
    })

    // batch.commit().then(() => {
    //   this.process = false;
    //   callback(true, null);
    // }).catch(error => {
    //   this.process = false;
    //   callback(false, error);
    // })
  }

}
