import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Student } from 'src/app/stores/student.store';
import { PreviewImageComponent } from '../preview-image/preview-image.component';

@Component({
  selector: 'app-student-scholarship-card',
  templateUrl: './student-scholarship-card.component.html',
  styleUrls: ['./student-scholarship-card.component.scss']
})
export class StudentScholarshipCardComponent implements OnInit {
  @Output() onPress = new EventEmitter();
  @Input() data: any = null;
  @Input() user: any = null;
  @Input() process: boolean;
  @Input() id: any;
  @Output() onAdd = new EventEmitter();

  constructor(
    public store: Student,
    public dialog: MatDialog,
    public router: Router
  ) { }

  ngOnInit() {

  }

  onViewImage(item) {
    const { fileurl } = item;
    if (!fileurl) return;
    this.dialog.open(PreviewImageComponent, {
      data: fileurl,
      panelClass: 'register-test-overlay-panel',
      width: '50vw',
      height: '100vh',
      disableClose: false,
      role: 'dialog',
    });
  }

}
