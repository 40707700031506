import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { recordStatus, REQUEST_STATUS } from 'src/app/dummy/status';
import { IVoidReceipt } from 'src/app/interfaces/invoice';
import { ConvertService } from 'src/app/services/convert.service';
import { DataService } from 'src/app/services/data.service';
import { campusObj, MappingService, toNumber, userObj } from 'src/app/services/mapping.service';
import { BaseStore } from 'src/app/stores/base.store';
import { Environment } from 'src/app/stores/environment.store';
import { Student } from 'src/app/stores/student.store';

@Component({
  selector: 'app-cashier-void-receipt',
  templateUrl: './cashier-void-receipt.component.html',
  styleUrls: ['./cashier-void-receipt.component.scss']
})
export class CashierVoidReceiptComponent implements OnInit {
  form: FormGroup;
  receipt: AbstractControl;
  note: AbstractControl;

  filteredReceiptStates: any;
  selectedReceipt;
  isExist = false;
  constructor(
    public dialogRef: MatDialogRef<CashierVoidReceiptComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    public env: Environment,
    public dialog: MatDialog,
    public base: BaseStore,
    private ds: DataService,
    public store: Student
  ) { }

  async ngOnInit() {
    this.buildForm();
    const { student } = this.store;

    let invoiceData = await this.base.fetchList(this.ds.invoiceAllByStudentKeyRef(student.key));
    invoiceData = invoiceData.filter(m => !m.isVoid && m.received_date_key === ConvertService.dateKey());
    invoiceData = MappingService.orderByDesc(invoiceData, "received_date");
    this.filteredReceiptStates = MappingService.autoComplete(this.receipt, invoiceData, "invoice_no");
  }

  buildForm(): void {
    this.form = this.fb.group({
      receipt: [null, Validators.compose([Validators.required, MappingService.validSelected.bind(this)])],
      note: [null, Validators.required],
    });
    this.receipt = this.form.controls["receipt"];
    this.note = this.form.controls["note"];
  }

  displayItemReceipt(item: any): string {
    return item ? item.invoice_no : item;
  }

  async onSelectReceipt(event) {
    const { value } = event.option;
    if (value) {
      this.selectedReceipt = value;
      const { student } = this.store
      this.isExist = await this.store.fetchCheckVoidReceipt(student.key, value.key)
    }
    else {
      this.isExist = false
      this.selectedReceipt = null;
    }
    
  }

  create(f: any) {
    if (this.form.valid && !this.isExist) {

      const { user, selectedCampus, selectedSchool } = this.env;
      const { receipt, note } = f;
      const { student, issue_year, program, key, headerRef, amount, prepaid, invoice_no } = receipt;

      const total = amount + toNumber(prepaid)
      const item: IVoidReceipt = {
        key: this.ds.createId(),
        create_date_key: ConvertService.dateKey(),
        create_date: new Date(),
        create_by: userObj(user),
        status: recordStatus.active,

        update_date_key: ConvertService.dateKey(),
        update_date: new Date(),
        update_by: userObj(user),

        student: student,
        academicYearKey: issue_year.key,
        admissionKey: program.admissionKey,
        programKey: program.program.key,
        levelKey: program.key,

        amount: total,
        invoice_no: invoice_no,
        note: note,
        invoiceKey: key,
        headerRef: headerRef,
        isTran: false,
        isPaid: false,

        request_status: REQUEST_STATUS.pending,

        campusKey: selectedCampus.key,
        campus: campusObj(selectedCampus),
        schoolKey: selectedSchool.key,
        school: selectedSchool,
      }

      this.store.addRequestVoidStudentReceipt(item, (success, error) => {
        if (success) {
          this.snackBar.open("Void receipt has been requested.", "Done", { duration: 2000 })
          this.dialogRef.close('yes');
        }
        else {
          this.snackBar.open(error, "Error");
        }
      })
    }
  }

}
