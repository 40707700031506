import { Component, OnInit } from '@angular/core';
import { Institute } from '../../stores/institute.store';
import { tabs } from '../../dummy/tabs';
import { Campus } from '../../stores/campus.store';

@Component({
  selector: 'app-admission',
  templateUrl: './admission.component.html',
  styleUrls: ['./admission.component.scss']
})
export class AdmissionComponent implements OnInit {
  tabs=tabs.panel;
  constructor(
    private institute:Institute,
    private campus:Campus) { }

  ngOnInit() {
    // this.institute.fetchTesting()
  }

}
