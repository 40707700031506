import { TestFeeStatus } from "../../dummy/status";
import { IReceivedPayment } from "./../../interfaces/receivedPayment";
import { ConvertService } from "./../../services/convert.service";
import { Environment } from "./../../stores/environment.store";
import { AngularFirestore } from "@angular/fire/firestore";
import { ILevelFee } from "./../../interfaces/tuition";
import { Tuition } from "./../../stores/tuition.store";
import { AuthService } from "../../auth/auth.service";
import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef
} from "@angular/core";
import {
  FormGroup,
  AbstractControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from "@angular/material";
import { Payment } from "src/app/stores/payment.store";
import { Student } from "src/app/stores/student.store";
import { academicYearObj, MappingService, toNumber } from "src/app/services/mapping.service";

@Component({
  selector: 'app-add-testing-payment',
  templateUrl: './add-testing-payment.component.html',
  styleUrls: ['./add-testing-payment.component.scss']
})
export class AddTestingPaymentComponent implements OnInit {
  @ViewChild("focusInput")
  inputEl: ElementRef;

  form: FormGroup;
  cash_in: AbstractControl;
  note: AbstractControl;
  received_date: AbstractControl;

  paymentTerm: any = null;
  change: number;

  amountPay: number;
  exchangeFee: number;
  exchange_rate;

  constructor(
    public dialogRef: MatDialogRef<AddTestingPaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public auth: AuthService,
    private snackBar: MatSnackBar,
    public store: Payment,
    private db: AngularFirestore,
    private env: Environment,
    public studentStore: Student,
  ) { }

  buildForm(): void {
    this.change = 0;
    this.form = this.fb.group({
      cash_in: ["", [Validators.compose([Validators.required, this.validCashIn.bind(this)])]],
      note: [null,],
      received_date: [new Date(), Validators.required],
    });
    this.cash_in = this.form.controls["cash_in"];
    this.note = this.form.controls["note"];
    this.received_date = this.form.controls["received_date"];
  }

  public validCashIn(control: AbstractControl): { [s: string]: boolean } {
    const value = control.value;
    if (value !== undefined && value !== null && value !== "") {
      this.change = ConvertService.getNumber(value, 0) - this.amountPay;
      if (this.change < 0) {
        return { validKey: true };
      }
      if (value < 0) {
        return { validAmount: true }
      }
    } else {
      this.change = 0 - this.amountPay;
    }
  }

  async ngOnInit() {
    this.buildForm();
    await this.env.fetchUserDoc()
    await this.env.fetchConfigDoc()
    const { config } = this.env
    const { dollar_exchange } = config
    this.exchange_rate = config.dollar_exchange || 4000;

    const { student, admission_term } = this.data;
    this.paymentTerm = admission_term;

    this.store.fetchTestingInvoice(student.key, (inv) => {
      if (inv && inv.length > 0) {
        const headerInv = inv.find(m => m.isHeader)
        this.amountPay = headerInv.amount;
        this.exchangeFee = toNumber(dollar_exchange) * toNumber(this.amountPay)
        this.change = 0 - this.amountPay;
      }
    })
  }

  _save(f: any) {
    if (f.cash_in < this.amountPay) return;
    if (this.form.valid && this.store.paymentTesting) {
      this.form.disable();
      const { selectedCampus, selectedSchool } = this.env
      const { received_date } = f
      const item: IReceivedPayment = {
        key: this.db.createId(),
        invoiceKey: null,
        studentKey: null,
        shift: this.env.dailyShift,
        cashier: this.env.user,// USING
        payment_year: academicYearObj(this.paymentTerm),
        payment_campus: MappingService.campusObj(this.env.user.campus),
        cashIn: f.cash_in,
        note: f.note,
        change: this.change,
        status: TestFeeStatus.paid,
        page_key: ConvertService.pageKey(),
        create_date: received_date,
        create_date_key: ConvertService.toDateKey(received_date),
        create_by: MappingService.userObj(this.env.user),

        campusKey: selectedCampus.key,
        campus: selectedCampus,
        schoolKey: selectedSchool.key,
        school: selectedSchool,
      };
      this.store.testingPayment(
        item, this.env.config.registrationFee, this.data, (success, headerKeyOrError) => {
          if (success) {
            this.dialogRef.close(headerKeyOrError);
            this.snackBar.open("Invoice have been paid successful.", "done", {
              duration: 2000
            });
          } else {
            this.snackBar.open(headerKeyOrError, "Error");
          }
        }
      );
    }
  }
}
