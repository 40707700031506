import { enrollPrograms, paymentStatus, EnrollStatus } from '../../../dummy/status';
import { invoiceTypesObj } from 'src/app/dummy/status';
import { ConvertService } from 'src/app/services/convert.service';
import { DataService } from './../../../services/data.service';
import { MatSnackBar } from '@angular/material';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { Student } from 'src/app/stores/student.store';
import { Payment } from 'src/app/stores/payment.store';
import { Environment } from 'src/app/stores/environment.store';
import { academicYearObj, MappingService } from 'src/app/services/mapping.service';
import { Invoice } from 'src/app/stores/invoice.store';
import { IInvoice } from 'src/app/interfaces/invoice';

@Component({
  selector: 'app-student-abc',
  templateUrl: './student-abc.component.html',
  styleUrls: ['./student-abc.component.scss']
})
export class StudentAbcComponent implements OnInit {
  form: FormGroup;
  abc: AbstractControl;

  dataLists = []
  constructor(
    public dialogRef: MatDialogRef<StudentAbcComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    public store: Payment,
    public invoice: Invoice,
    public ds: DataService,
    public env: Environment,
    public studentStore: Student
  ) { }

  buildForm(): void {
    this.form = this.fb.group({
      abc: [null, Validators.required],
    })
    this.abc = this.form.controls['abc'];
  }

  ngOnInit() {
    this.buildForm();
    this.dataLists.push(this.env);
  }

  create(f: any) {
    if (this.form.valid && f.abc.length > 0) {
      this.form.disable();
      const date = new Date();
      const create_by = MappingService.userObj(this.env.user);
      const item: IInvoice = {
        key: this.ds.createId(),
        create_date: date,
        create_date_key: ConvertService.toDateKey(date),
        create_by: create_by,
        issue_by: create_by,
        issue_date: date,
        invoice_no: null,
        invoice_type: invoiceTypesObj.abcCourse,
        school_fee_type: enrollPrograms.abc,
        student: MappingService.studentObj(this.studentStore.student),
        verify_by: create_by,
        verify_date: date,
        page_key: ConvertService.pageKey(),
        verify_date_key: ConvertService.toDateKey(date),
        invoice_date: date,
        invoice_date_key: ConvertService.toDateKey(date),
        course: this.env.abc,
        isPaid: paymentStatus.unpaid,
        isVoid: false,
        program: enrollPrograms.academic,
        byAdmission: false,
        price: this.env.abcFee.price,
        amount: this.env.abcFee.price,
        issue_term: academicYearObj(this.env.term),
        payment_term: null,
        headerRef: null,
        isHeader: false,
        enroll_status: EnrollStatus.assign,
        isEnrollVerify: true,
        campus: MappingService.campusObj(this.env.campus),
        schoolSession: null,
        description: invoiceTypesObj.abcCourse.text
      }
      const items = [];
      items.push(item);
      const { invoiceKey, paymentHeader } = this.store;
      const header = invoiceKey ? this.invoice.paymentHeader : paymentHeader
      this.store.addMiscellaneousFee(items, this.studentStore.student, header, (success, error) => {
        if (success) {
          this.snackBar.open("ABC has been added to invoice.", "Done", { duration: 2000 })
          this.dialogRef.close('yes');
          this.form.enable();
        }
        else {
          this.snackBar.open(error, "Error");
          this.form.enable();
        }
      })
    }
  }
}