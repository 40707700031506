import { MatDialog ,MatSnackBar, MatSnackBarModule} from "@angular/material";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Student } from "src/app/stores/student.store";
import { Payment } from "src/app/stores/payment.store";
import { Environment } from "src/app/stores/environment.store";
import { PrintService } from "src/app/services/print.service";
import { AddTestingPaymentComponent } from 'src/app/components/add-testing-payment/add-testing-payment.component';

@Component({
  selector: 'app-student-pay-testing',
  templateUrl: './student-pay-testing.component.html',
  styleUrls: ['./student-pay-testing.component.scss']
})
export class StudentPayTestingComponent implements OnInit {
  id = null;
  disabled=true;
  process=false;
  constructor(
    private route: ActivatedRoute,
    public store: Student,
    public payment: Payment,
    public env: Environment,
    public dialog: MatDialog,
    private ps: PrintService,
    private snackBar:MatSnackBarModule,
    private router: Router
  ) {}

  ngOnInit() {
    this.route.params.forEach(params => {
      this.id = params["id"];
      this.store.payPageKey = this.route.snapshot.url[1].path;
      this.process=true;
      this.store.fetchAdmission(this.id, res=>{});
      this.store.fetchStudentToDoc(this.id, doc => {
        this.env.fetchConfigToArray(ls=>{
          this.store.fetchTesting(this.id);
          this.process=false;
        })
      });
    });
  }

  _goTesting(){
    this.router.navigate(['/add-new-testing']);
  }

  print() {
    this.ps.print("#printD", "a5l");
  }

  _onReceivePayment(item) {
    if(item.isPaidTest) return;
    let dialogRef = this.dialog.open(AddTestingPaymentComponent, {
      data: item,
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: true,
      role: "dialog"
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => {
      if (result === "print") {
        this.print();
      }
    });
  }

}
