import { IAdmission } from "./../../interfaces/admission";
import { Student } from "src/app/stores/student.store";
import { Tuition } from "./../../stores/tuition.store";
import { ConvertService } from "./../../services/convert.service";
import { AngularFirestore } from "@angular/fire/firestore";
import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef
} from "@angular/core";
import {
  FormGroup,
  AbstractControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from "@angular/material";
import { academicYearObj, MappingService } from "../../services/mapping.service";
import { Institute } from "src/app/stores/institute.store";
import { Terms } from "src/app/stores/term.store";
import { Environment } from "src/app/stores/environment.store";
import { Unpaid } from "src/app/stores/unpaid.store";
import { DataService } from "src/app/services/data.service";
import {
  invoiceTypesObj,
  studentTypes,
  admissionType,
  enrollPrograms,
  paymentStatus,
  recordStatus,
  enrollmentTypes
} from "src/app/dummy/status";
import { IInvoice } from "src/app/interfaces/invoice";

@Component({
  selector: "app-add-unpaid-payment",
  templateUrl: "./add-unpaid-payment.component.html",
  styleUrls: ["./add-unpaid-payment.component.scss"]
})
export class AddUnpaidPaymentComponent implements OnInit {
  @ViewChild("focusInput") inputEl: ElementRef;

  form: FormGroup;
  amount: AbstractControl;
  scholarship: AbstractControl;
  loan: AbstractControl;
  penalty: AbstractControl;
  program: AbstractControl;
  term: AbstractControl;
  level: AbstractControl;
  shift: AbstractControl;
  note: AbstractControl;

  filteredStates: any;
  filteredShiftStates: any;
  filteredProgramStates: any;
  filteredLevelStates: any;

  constructor(
    public dialogRef: MatDialogRef<AddUnpaidPaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public env: Environment,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore,
    public fee: Tuition,
    public store: Institute,
    public termStore: Terms,
    public unpaid: Unpaid,
    private ds: DataService,
    public studentStore: Student
  ) { }

  buildForm(): void {
    this.form = this.fb.group({
      term: [
        null,
        Validators.compose([
          Validators.required,
          MappingService.validSelected.bind(this)
        ])
      ],
      shift: [null, Validators.compose([Validators.required])],
      program: [null, Validators.compose([Validators.required])],
      level: [null, Validators.compose([Validators.required])],
      amount: [null, Validators.compose([Validators.required])],
      scholarship: [null],
      loan: [null],
      note: [null],
      penalty: [null]
    });

    this.term = this.form.controls["term"];
    this.program = this.form.controls["program"];
    this.level = this.form.controls["level"];
    this.amount = this.form.controls["amount"];
    this.scholarship = this.form.controls["scholarship"];
    this.penalty = this.form.controls["penalty"];
    this.shift = this.form.controls["shift"];
    this.loan = this.form.controls["loan"];
    this.note = this.form.controls["note"];

    this.termStore.fetchTermToArray(list => {
      this.filteredStates = MappingService.autoComplete(
        this.term,
        list,
        "name"
      );
    });

    this.termStore.fetchShiftToArray(list => {
      this.filteredShiftStates = list;
      this.shift.patchValue(this.filteredShiftStates[0]);
    });

    this.store.fetchAllProgram(list => {
      this.filteredProgramStates = list;
      if (list.length > 0) {
        this.program.patchValue(list[0]);
        this.store.fetchProgramLevelToArray(list[0].key, docs => {
          const data = MappingService.orderBy(docs, "page_key")
          if (data.length > 0) {
            this.level.patchValue(data[0]);

            let { english_creditRef } = this.studentStore.student;
            if (english_creditRef) {
              let { amount, scholarship, loan, penalty, note } = english_creditRef
              this.form.patchValue({
                amount: amount,
                scholarship: scholarship,
                loan: loan,
                penalty: penalty,
                note: ConvertService.toNull(note)
              })
            } else this.amount.patchValue(data[0].fee.price);
          }
          this.filteredLevelStates = data;
        });
      }
    });

  }

  onSelectionChange(event) {
    const { value } = event;
    this.store.fetchProgramLevelToArray(value.key, docs => {
      const data = MappingService.orderBy(docs, "page_key")
      if (data.length > 0) {
        this.form.patchValue({
          level: data[0],
          amount: data[0].fee.price
        })
      }
      this.filteredLevelStates = data;
    });
  }

  onSelectionLevel(event) {
    const { value } = event;
    this.form.patchValue({
      amount: value.fee.price
    })
  }

  compareObjects(o1: any, o2: any): boolean {
    if (o2) return o1.key === o2.key;
  }

  displayItem(item: any): string {
    return item ? item.name : item;
  }

  ngOnInit() {
    this.buildForm();
  }

  create(f: any) {
    if (this.form.valid) {
      this.form.disable();
      let { amount, level, penalty, program, scholarship, term, shift, loan } = f;

      const create_by = MappingService.userObj(this.env.user);
      const campus = this.env.user
        ? MappingService.campusObj(this.env.user.campus)
        : null;
      const currentTerm = academicYearObj(term);
      const studentDoc = MappingService.studentObj(this.studentStore.student);

      let programAcademic = {
        admissionKey: null,
        category: {
          key: ConvertService.toNull(f.program.institute.key),
          name: ConvertService.toNull(f.program.institute.name)
        },
        type: ConvertService.toNull(f.program.institute.instituteType),
        key: ConvertService.toNull(f.program.key),
        name: ConvertService.toNull(f.program.name)
      };

      let admissionKey = this.ds.createId();
      const admissionDoc: IAdmission = {
        key: admissionKey,
        create_date: new Date(),
        create_by: create_by,
        status: recordStatus.active,
        date_key: ConvertService.toDateKey(new Date()),
        page_key: ConvertService.pageKey(),
        term: currentTerm,
        puc_generation: null,
        major_generation: null,
        major: null,
        shift: shift,
        student_type: studentTypes.pucStudent,
        admission_type: admissionType.institute,
        student: MappingService.studentObj(studentDoc),
        curriculum: null,
        faculty: null,
        program: programAcademic.type,
        isPaid: false,
        isPaidAdminFee: false,
        is_student_institute: true,
        is_student_academic: false,
        program_academic: programAcademic,
        // isUpdate: true,

        campusKey: null,
        campus: null,
        schoolKey: null,
        school: null,
      };
      programAcademic.admissionKey = admissionKey;

      const headerKey = this.ds.createId();
      const invoiceNo = null;

      const date = new Date();
      const courseProgram = programAcademic;
      const invoiceType = invoiceTypesObj.tuitionFee;
      const schoolFeeType = enrollPrograms.institutes;
      const displayProgram = program.shortName;
      const displayLevel = level.name;
      const displayShift = shift.name;
      const grandTotal = amount;
      const scholarshipRef = {
        key: "unpaid",
        loan: loan,
        percentage: scholarship,
      }
      const invoiceHeader: IInvoice = {
        key: headerKey,
        display_program: displayProgram,
        display_level: displayLevel,
        display_shift: displayShift,
        create_date: date,
        create_date_key: ConvertService.dateKey(),
        create_by: create_by,
        issue_by: create_by,
        issue_date: date,
        invoice_no: invoiceNo,
        invoice_type: invoiceType,
        school_fee_type: schoolFeeType,
        student: studentDoc,
        verify_by: create_by,
        verify_date: date,
        page_key: ConvertService.pageKey(),
        verify_date_key: ConvertService.dateKey(),
        invoice_date: date,
        invoice_date_key: ConvertService.dateKey(),
        course: scholarshipRef,
        isPaid: paymentStatus.unpaid,
        isVoid: false,
        program: courseProgram,
        byAdmission: false,
        issue_term: currentTerm,
        payment_term: null,
        headerRef: headerKey,
        isHeader: true,
        enroll_status: null,
        isEnrollVerify: true,
        campus: campus,
        schoolSession: null,
        description: program.shortName + " " + level.name,
        penaltyRef: null,
        penalty: penalty,
        scholarshipRef: scholarshipRef,
        scholarship: null,
        loan: null,
        prepaidRef: null,
        prepaid: null,
        price: grandTotal,
        amount: grandTotal,
        set_unpaid: true,
        enrollment_type: enrollmentTypes.institute,
        note: f.note,
      };

      const invoiceDetail: IInvoice = {
        key: this.ds.createId(),
        create_date: date,
        create_date_key: ConvertService.toDateKey(date),
        create_by: create_by,
        issue_by: create_by,
        issue_date: date,
        invoice_no: invoiceNo,
        invoice_type: invoiceType,
        school_fee_type: schoolFeeType,
        student: studentDoc,
        verify_by: create_by,
        verify_date: date,
        page_key: ConvertService.pageKey(),
        verify_date_key: ConvertService.toDateKey(date),
        invoice_date: date,
        invoice_date_key: ConvertService.toDateKey(date),
        course: level,
        isPaid: paymentStatus.unpaid,
        isVoid: false,
        program: courseProgram,
        byAdmission: false,
        issue_term: currentTerm,
        payment_term: null,
        headerRef: headerKey,
        isHeader: false,
        enroll_status: null,
        isEnrollVerify: true,
        campus: campus,
        schoolSession: null,
        description: program.shortName + " " + level.name,
        penaltyRef: null,
        penalty: penalty,
        scholarshipRef: scholarshipRef,
        scholarship: null,
        loan: null,
        prepaidRef: null,
        prepaid: null,
        price: grandTotal,
        amount: grandTotal,
        enrollment_type: enrollmentTypes.institute,
        note: f.note,
      };

      this.unpaid.enrollEnglishPayment(admissionDoc, invoiceHeader, invoiceDetail, this.studentStore.student, (success, error) => {
        if (success) {
          this.dialogRef.close();
          this.snackBar.open("Training fee has been saved.", "done", {
            duration: 2000
          });
          this.form.enable();
          this.dialogRef.close("yes");
        } else {
          this.form.enable();
          this.snackBar.open(error, "Error");
        }
      });
    }
  }
}
