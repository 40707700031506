import { Tuition } from './tuition.store';
import { Academic } from './academic.store';
import { Payment } from './payment.store';
import { User } from './user.store';
import { Campus } from './campus.store';
import { Terms } from './term.store';
import { Institute } from './institute.store';
import { Environment } from './environment.store';
import { DailyShiftStore } from './dailyShift.store';
import { Testing } from './testing.store';
import { Search } from './search.store';
import { Miscellaneous } from './miscellaneous.store';
import { Student } from './student.store';
import { Report } from './report.store';
import { Unpaid } from './unpaid.store';
import { Invoice } from './invoice.store';
import { ScholarPenalty } from './scholarPenalty.store';
import { InstituteSchedule } from './institute_schedule.store';
import { BaseStore } from './base.store';
import { Registration } from './registration.store';
import { YearTraining } from './yearTraining.store';
import { OtherService } from './otherService.store';
import { PettyCash } from './pettyCash.store';

export const APP_STORES = [
  Terms,
  Campus,
  Institute,
  User,
  Payment,
  Environment,
  DailyShiftStore,
  Academic,
  Tuition,
  Testing,
  Search,
  Miscellaneous,
  Student,
  PettyCash,
  Report,
  Unpaid,
  Invoice,
  ScholarPenalty,
  InstituteSchedule,
  BaseStore,
  Registration,
  YearTraining,
  OtherService,
]
