import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'app-cashier-shift',
  templateUrl: './cashier-shift.component.html',
  styleUrls: ['./cashier-shift.component.scss']
})
export class CashierShiftComponent implements OnInit {
@Input() name:string;
@Output() onPress=new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  _onClick(){
    this.onPress.emit();
  }
}
