import { MappingService } from 'src/app/services/mapping.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sumReport'
})
export class SumReportPipe implements PipeTransform {

  transform(value: any, data?: Array<any>, key?: any, sumField?: any): any {
    if (!data || data.length == 0) return 0;
    const filter = data.filter(m => m.invoice_type.key === key);
    const total = MappingService.sum(filter.slice(), sumField);
    return total;
  }
}
