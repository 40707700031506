import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class AdministratorService {

  constructor(private ds: DataService) { }

  updatePucIdAndInvoice(callback) {
    this.ds.settingFireStore().get().then(config => {
      const setting = config.data();
      this.ds.sysSetting().update({
        puc_id: setting.puc_id + 1,
        invoice_shufit: setting.invoice_shufit + 1,
      }).then(() => {
        callback(true, setting)
      }).catch(error => {
        callback(false, error)
      })
    }).catch(error => {
      callback(false, error)
    })
  }

  updateSerialIdAndInvoice(callback) {
    this.ds.settingFireStore().get().then(config => {
      const setting = config.data();
      this.ds.sysSetting().update({
        serial_number: setting.serial_number + 1,
        invoice_shufit: setting.invoice_shufit + 1,
      }).then(() => {
        callback(true, setting)
      }).catch(error => {
        callback(false, error)
      })
    }).catch(error => {
      callback(false, error)
    })
  }

  updateSerialId(callback) {
    this.ds.settingFireStore().get().then(config => {
      const setting = config.data();
      this.ds.sysSetting().update({
        serial_number: setting.serial_number + 1,
      }).then(() => {
        callback(true, setting)
      }).catch(error => {
        callback(false, error)
      })
    }).catch(error => {
      callback(false, error)
    })
  }

  updatePucId(callback) {
    this.ds.settingFireStore().get().then(config => {
      const setting = config.data();
      this.ds.sysSetting().update({
        puc_id: setting.puc_id + 1,
      }).then(() => {
        callback(true, setting)
      }).catch(error => {
        callback(false, error)
      })
    }).catch(error => {
      callback(false, error)
    })
  }

  updateInvoiceNo(callback) {
    this.ds.settingFireStore().get().then(config => {
      const setting = config.data();
      this.ds.sysSetting().update({
        invoice_shufit: setting.invoice_shufit + 1,
      }).then(() => {
        callback(true, setting)
      }).catch(error => {
        callback(false, error)
      })
    }).catch(error => {
      callback(false, error)
    })
  }
  
  updateInvoiceNoAndPucID(puc_id,callback) {
    this.ds.settingFireStore().get().then(config => {
      const setting = config.data();
      if(puc_id){
        this.ds.sysSetting().update({
          invoice_shufit: setting.invoice_shufit + 1,
        }).then(() => {
          callback(true, setting)
        }).catch(error => {
          callback(false, error)
        })
      }
      else{
        this.ds.sysSetting().update({
          invoice_shufit: setting.invoice_shufit + 1,
          puc_id: setting.puc_id + 1,
        }).then(() => {
          callback(true, setting)
        }).catch(error => {
          callback(false, error)
        })
      }
    }).catch(error => {
      callback(false, error)
    })
  }

  updatePucIdAndSerialID(callback) {
    this.ds.settingFireStore().get().then(config => {
      const setting = config.data();
      this.ds.sysSetting().update({
        puc_id: setting.puc_id + 1,
        serial_number: setting.serial_number + 1,
      }).then(() => {
        callback(true, setting)
      }).catch(error => {
        callback(false, error)
      })
    }).catch(error => {
      callback(false, error)
    })
  }

}
