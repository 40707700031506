import { ConvertService } from 'src/app/services/convert.service';
import { AuthService } from "./../auth/auth.service";
import { DataService } from "./../services/data.service";
import { observable, computed, action, autorun } from "mobx";
import { Injectable } from "@angular/core";

@Injectable()
export class User {
  @observable public data = null;
  @observable public loading = false;
  @observable public process = false;
  @observable public user = null;
  @observable public shift=null;
  @observable public isOpen=false;
  @observable public role=null;
  @observable public balance=0;

  constructor(private ds: DataService, private auth: AuthService) {
    this.fetchUser();
  }

  @action
  clearStore(){
    this.data = null;
    this.loading = false;
    this.process = false;
    this.user = null;
    this.shift=null;
    this.isOpen=false;
    this.role=null;
    this.balance=0;
  }

  @action
  fetchUser() {
    this.loading = true;
    this.auth.authRef().user.subscribe(user=>{
      if(user){
        this.ds
        .userRef(user.uid)
        .valueChanges()
        .subscribe(docs => {
          if(docs){
            const {role,shift,isShiftOpen,balance,petty_cash, installment}=docs;
            this.role=role;
            this.shift=shift;
            this.isOpen=isShiftOpen;
            let cashIn = shift?ConvertService.toNumber(shift.cash_in):0;
            this.balance = ConvertService.toNumber(balance) + cashIn + ConvertService.toNumber(installment) - ConvertService.toNumber(petty_cash)
            this.user = docs;
            this.loading = false;
          }
        });
      }
    })
  }
  
}
