import { invoiceTypesObj, enrollPrograms, paymentStatus, EnrollStatus, Status, recordStatus, scholarshipTypes } from 'src/app/dummy/status';
import { ConvertService } from 'src/app/services/convert.service';
import { MatSnackBar } from '@angular/material';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { Student } from 'src/app/stores/student.store';
import { Payment } from 'src/app/stores/payment.store';
import { Environment } from 'src/app/stores/environment.store';
import { MappingService, academicYearObj, toNumber } from 'src/app/services/mapping.service';
import { DataService } from 'src/app/services/data.service';
import { Invoice } from 'src/app/stores/invoice.store';
import { IInvoice } from 'src/app/interfaces/invoice';
import { Registration } from 'src/app/stores/registration.store';
import { BaseStore } from 'src/app/stores/base.store';

@Component({
  selector: 'app-add-student-payment',
  templateUrl: './add-student-payment.component.html',
  styleUrls: ['./add-student-payment.component.scss']
})
export class AddStudentPaymentComponent implements OnInit {
  form: FormGroup;
  admission_date: AbstractControl;
  paymentOption: AbstractControl;
  fromDate: AbstractControl;
  toDate: AbstractControl;

  constructor(
    public dialogRef: MatDialogRef<AddStudentPaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    public store: Registration,
    public ds: DataService,
    public env: Environment,
    public studentStore: Student,
    public invoice: Invoice,
    public baseStore: BaseStore,
    public payment: Payment,
  ) { }

  buildForm(): void {
    this.form = this.fb.group({
      admission_date: [new Date(), Validators.required],
      paymentOption: [null, Validators.required],
      fromDate: [null, Validators.required],
      toDate: [null, Validators.required],
    })
    this.admission_date = this.form.controls['admission_date'];
    this.paymentOption = this.form.controls['paymentOption'];
    this.fromDate = this.form.controls['fromDate'];
    this.toDate = this.form.controls['toDate'];
  }

  currentYear: any;
  paymentOptionList = [];
  paymentList = [];
  trainingLevelFee = [];
  trainingLevel = null;
  optionData = null;
  oldInvoice = null;
  isPaidComplete = false;
  process = true;
  async ngOnInit() {

    this.buildForm();

    await this.env.fetchUserDoc()
    const {selectedSchool} = this.env

    const { campus, program_academic, paymentPeriod } = this.studentStore.selectedAdmission;
    const envData: any = await this.env.fetchAcademicEnv(selectedSchool.key);
    this.currentYear = envData.year;

    this.optionData = await this.baseStore.fetchDoc(this.ds.settingDBFireStore());
    this.paymentOptionList = await this.baseStore.fetchList(this.ds.paymentOptionRef());

    if (campus && program_academic) {
      const { program } = program_academic;
      if (program.programOption.key === 1) {

        this.currentYear = envData.year;
      } else {

        this.currentYear = envData.term;
      }
      this.trainingLevelFee = await this.baseStore.fetchList(this.ds.campusRef().doc(campus.key).collection("training_levels").doc(program_academic.key).collection("training_school_fee"))
      this.trainingLevel = await this.baseStore.fetchDoc(this.ds.campusRef().doc(campus.key).collection("training_levels").doc(program_academic.key));
    }

    if (this.payment.paymentTuitionFee && this.payment.paymentTuitionFee.length > 0) {
      this.oldInvoice = this.payment.paymentTuitionFee[0];
      const { fromDate, toDate, paymentOption } = this.oldInvoice;
      this.form.patchValue({
        fromDate: fromDate.toDate(),
        toDate: toDate.toDate(),
        paymentOption
      })

      this.getPaymentList(paymentOption);
    }

    this.process = false;
  }

  displayItem(item: any): string {
    return item ? item.name : item;
  }

  selectedFee(event) {
    const { value } = event.option;
    const { period } = value;
    const toDatePay = ConvertService.addMonth(period)

    this.form.patchValue({
      fromDate: new Date(),
      toDate: toDatePay
    })

    this.getPaymentList(value);
  }

  getPaymentList(value) {
    this.paymentList = [];
    const { admin_fee, tuition_fee, payment_option_full_year } = this.optionData;
    if (this.trainingLevel && this.trainingLevel.pay_full_admin_fee) {

      const tuitionFeeData = this.trainingLevelFee.filter(m => m.paymentOption.key === value.key && m.fee.key === tuition_fee.key);
      if (tuitionFeeData && tuitionFeeData.length > 0)
        this.paymentList.push(tuitionFeeData[0]);

      const adminFeeData = this.trainingLevelFee.filter(m => m.paymentOption.key === payment_option_full_year.key && m.fee.key === admin_fee.key);
      const { adminFeePeriod } = this.studentStore.selectedAdmission;
      if (adminFeeData && adminFeeData.length > 0 && toNumber(adminFeePeriod) === 0)
        this.paymentList.push(adminFeeData[0]);
    } else {

      this.paymentList = this.trainingLevelFee.filter(m => m.paymentOption.key === value.key);
    }

  }

  create(f: any) {
    if (this.form.valid && this.paymentList.length > 0) {
      this.form.disable();
      // this.store.addSchoolFee(f, this.paymentList, this.currentYear, this.studentStore.student, this.studentStore.selectedAdmission, this.env.user, this.optionData, this.oldInvoice, (success, error) => {
      //   if (success) {
      //     this.snackBar.open("School Fee has been added.", "Done", { duration: 2000 })
      //     this.dialogRef.close('yes');
      //     this.form.enable();
      //   }
      //   else {
      //     this.snackBar.open(error, "Error");
      //     this.form.enable();
      //   }
      // })
    
    }
  }
}