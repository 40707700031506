import { ConvertService } from 'src/app/services/convert.service';
import { IPettyCash } from '../interfaces/petty-cash';
import { Pages } from '../dummy/pages';
import { DataService } from "../services/data.service";
import { observable, computed, action, autorun } from "mobx";
import { Injectable } from "@angular/core";


@Injectable()
export class PettyCash {
  @observable public data = [];
  @observable public process = false;
  @observable public loading = false;
  @observable public empty = false;
  @observable public fetching = false;
  @observable public done = false;
  @observable public employees = [];
  @observable public received = [];

  private lastVisible = null;
  constructor(private ds: DataService) { }

  @action
  fetchData(userKey: string, shiftKey: string) {
    this.loading = true;
    this.data = [];
    this.done = false;
    this.fetching = false;
    this.empty = false;
    this.ds.pettyCashRef(userKey, shiftKey).valueChanges().subscribe(docs => {
      if (docs.length > 0 && docs.length >= Pages.size) {
        this.lastVisible = docs[docs.length - 1];
      } else {
        this.lastVisible = null;
        this.done = true;
      }
      this.empty = docs.length === 0;
      this.data = docs;
      this.loading = false;
    });
  }

  @action
  onScroll() {
    if (this.fetching || this.done || this.lastVisible === null || this.loading) return;
    this.fetching = true;
    this.ds
      .pettyCashScrollRef(this.lastVisible)
      .valueChanges()
      .subscribe(docs => {
        if (docs.length > 0 && docs.length >= Pages.size) {
          this.lastVisible = docs[docs.length - 1];
        } else {
          this.done = true;
        }
        this.data = this.data.slice().concat(docs);
        this.fetching = false;
      });
  }

  @action
  addPettyCash(item: IPettyCash, user: any, dailyShift: any, callback) {
    const batch = this.ds.batch();
    // const userRef = this.ds.userFireRef().doc(user.key);
    const pettyCashRef = this.ds.pettyCashFireRef().doc(item.key);
    // const dailyShiftRef = this.ds.dailyShiftFireRef().doc(dailyShift.key);

    batch.set(pettyCashRef, item);
    // batch.update(userRef, {
    //   petty_cash: ConvertService.toNumber(user.petty_cash) + item.price
    // })
    // batch.update(dailyShiftRef, {
    //   petty_cash: ConvertService.toNumber(dailyShift.petty_cash) + item.price
    // })

    batch.commit().then(() => {
      callback(true, null)
    }).catch(error => {
      callback(false, error)
    })
  }

  @action
  updatePettyCash(item, callback) {
    const batch = this.ds.batch();
    const pettyCashRef = this.ds.pettyCashFireRef().doc(item.key);
    batch.update(pettyCashRef, item);
    
    batch.commit().then(() => {
      callback(true, null)
    }).catch(error => {
      callback(false, error)
    })
  }

  @action
  fetchEmpty() {
    this.ds.employeeRef().valueChanges().subscribe(docs => {
      this.employees = docs;
      this.received = docs;
    })
  }

  @action
  fetchUser() {
    this.ds.userDBRef().valueChanges().subscribe(docs => {
      this.employees = docs;
      this.received = docs;
    })
  }

  @action
  searchUser(search) {
    if (search) {
      return this.ds.userDBSearchRef(search).valueChanges();
    }
    return this.ds.userDBRef().valueChanges();
  }

  @action
  searchEmployee(search) {
    if (search) {
      return this.ds.employeeSearchRef(search).valueChanges();
    }
    return this.ds.employeeSRef().valueChanges();
  }

}
