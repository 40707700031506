import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/take';
import { User } from '../stores/user.store';

@Injectable()
export class ShiftGuard implements CanActivate {
    constructor(private user: User, private router: Router, public authService: AuthService) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.user.shift !== null) {
            return true;
        } else {
            this.router.navigate(['/'])
            return false;
        }

    }
}
