import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { AuthService } from './../../auth/auth.service';
import { Component, OnInit,ChangeDetectionStrategy } from '@angular/core';


@Component({
  changeDetection:ChangeDetectionStrategy.OnPush,
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  user:any;

  constructor(private auth:AuthService,
    private router: Router,
    public dialog: MatDialog) {}

  logout() {
    this.auth.signOut().then(()=>{
      this.router.navigate(['/auth']);
    })
  }

  ngOnInit() {
    //this.user=this.auth.getUser();
    // this.institute.instituteTerm(()=>{})
  }

}
