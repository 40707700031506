import { MatCheckboxChange, MatSnackBar } from '@angular/material';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { Student } from 'src/app/stores/student.store';
import { Payment } from 'src/app/stores/payment.store';
import { Environment } from 'src/app/stores/environment.store';
import { academicYearObj, campusObj, MappingService, paymentOptionObj, pushObjArray, schoolObj, studentObj, toNumber, userObj } from 'src/app/services/mapping.service';
import { DataService } from 'src/app/services/data.service';
import { Invoice } from 'src/app/stores/invoice.store';
import { Registration } from 'src/app/stores/registration.store';
import { BaseStore } from 'src/app/stores/base.store';
import { INSTALLMENT_PAYMENT_STATUS, PROGRAM_TERMS, PROGRAM_TERMS_OBJ, recordStatus, SCHOOL_FEE_PAYMENT } from 'src/app/dummy/status';
import { ConvertService } from 'src/app/services/convert.service';
import { IAlignPayment } from 'src/app/interfaces/invoice';
import { CONFIG } from 'src/app/dummy/config';
import { toJS } from 'mobx';

@Component({
  selector: 'app-add-installment-school-fee',
  templateUrl: './add-installment-school-fee.component.html',
  styleUrls: ['./add-installment-school-fee.component.scss']
})
export class AddInstallmentSchoolFeeComponent implements OnInit {
  form: FormGroup;
  paymentOption: AbstractControl;
  fromDate: AbstractControl;
  toDate: AbstractControl;
  program_term: AbstractControl;
  academic_year: AbstractControl;
  amount_request: AbstractControl;
  amount: AbstractControl;
  note: AbstractControl;
  pay_date: AbstractControl;
  isOneTimePayment: AbstractControl;

  selectedAcademicYear;
  programTermList = PROGRAM_TERMS;
  currentTerm;
  constructor(
    public dialogRef: MatDialogRef<AddInstallmentSchoolFeeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    public store: Registration,
    public ds: DataService,
    public env: Environment,
    public studentStore: Student,
    public invoice: Invoice,
    public baseStore: BaseStore,
    public payment: Payment,
  ) { }

  buildForm(): void {
    this.form = this.fb.group({
      academic_year: [null, Validators.compose([Validators.required, MappingService.validSelected.bind(this)])],
      paymentOption: [null, Validators.compose([MappingService.validSelected.bind(this)])],
      fromDate: [null, Validators.required],
      toDate: [null, Validators.required],
      program_term: [PROGRAM_TERMS[0], Validators.compose([MappingService.validSelected.bind(this)])],
      amount_request: [null, [Validators.compose([Validators.required, this.validCashIn.bind(this)])]],
      amount: [null, [Validators.compose([Validators.required, this.validPaymentAmount.bind(this)])]],
      pay_date: [new Date(), Validators.required],
      note: [null],
      isOneTimePayment: [false, Validators.required],

    })
    this.academic_year = this.form.controls['academic_year'];
    this.paymentOption = this.form.controls['paymentOption'];
    this.fromDate = this.form.controls['fromDate'];
    this.toDate = this.form.controls['toDate'];
    this.program_term = this.form.controls["program_term"];
    this.amount_request = this.form.controls["amount_request"];
    this.amount = this.form.controls["amount"];
    this.pay_date = this.form.controls["pay_date"];
    this.note = this.form.controls["note"];
    this.isOneTimePayment = this.form.controls["isOneTimePayment"];

  }

  change = 0;
  public validCashIn(control: AbstractControl): { [s: string]: boolean } {
    const value = control.value;
    if (value !== undefined && value !== null && value !== "") {
      this.change = ConvertService.getNumber(value, 0) - this.totalFee;
      if (this.change < 0) {
        return { validKey: true };
      }
      if (value < 0) {
        return { validAmount: true }
      }
    } else {
      this.change = 0 - this.totalFee;
    }
  }

  change1 = 0
  public validPaymentAmount(control: AbstractControl): { [s: string]: boolean } {
    const value = control.value;
    if (value !== undefined && value !== null && value !== "") {
      this.change1 = ConvertService.getNumber(value, 0) - ConvertService.toNumber(this.amount_request && this.amount_request.value || 0);
      if (this.change1 > 0) {
        return { validKey: true };
      }
      if (value <= 0) {
        return { validAmount: true }
      }
    } else {
      this.change1 = 0 - ConvertService.toNumber(this.amount_request && this.amount_request.value || 0)
    }
  }

  showOptions(event: MatCheckboxChange): void {
    this.pay_date.enable()
    this.note.enable()
    this.amount.enable()
    if (event.checked) {
      this.pay_date.disable()
      this.note.disable()
      this.amount.disable()
    }
  }

  currentYear: any;
  paymentOptionData = [];
  paymentOptionList = [];
  paymentList = [];
  trainingLevelFee = [];
  trainingLevel = null;
  optionData = null;
  studentData = null;
  oldInvoice = null;
  isPaidComplete = false;
  process = true;
  paymentStudentData;

  haveStudentDiscount = false;

  academicYearList = []
  totalFee = 0;
  async ngOnInit() {
    this.buildForm();
    const { campus, program_academic, student } = this.studentStore.selectedAdmission;
    await this.env.fetchUserDoc()
    const { selectedSchool } = this.env
    const envData: any = await this.env.fetchAcademicEnv(selectedSchool.key);

    this.studentData = await this.baseStore.fetchDoc(this.ds.studentDocument(student.key));
    this.optionData = await this.baseStore.fetchDoc(this.ds.settingDBFireStore());
    this.paymentOptionData = await this.baseStore.fetchList(this.ds.paymentOptionRef());
    this.paymentOptionList = this.paymentOptionData.filter(m => m.key === CONFIG.paymentYearKey)

    if (campus && program_academic) {
      const { program } = program_academic;
      let academicYearDoc = await this.baseStore.fetchList(this.ds.storeDocRef(selectedSchool.key).collection("academic_year", ref => ref.limit(50)))

      // if (program.programOption.key === 1) {

      //   this.currentYear = envData.year;
      //   academicYearDoc = academicYearDoc.filter(m => m.termType.key === 1)
      // } else {

      //   this.currentYear = envData.term;
      //   academicYearDoc = academicYearDoc.filter(m => m.termType.key === 2)
      // }

      this.currentYear = envData.term_cashier

      this.academicYearList = MappingService.orderByDesc(academicYearDoc, "startDate")
      this.selectedAcademicYear = this.academicYearList.find(m => m.key === this.currentYear.key)
      this.academic_year.patchValue(this.selectedAcademicYear)

      this.trainingLevelFee = await this.baseStore.fetchList(this.ds.campusRef().doc(campus.key).collection("training_levels").doc(program_academic.key).collection("training_school_fee"))
      this.trainingLevel = await this.baseStore.fetchDoc(this.ds.campusRef().doc(campus.key).collection("training_levels").doc(program_academic.key));
    }

    this.selectedAcademicYear = MappingService.pushToObject(await this.ds.academicYearRef().doc(this.currentYear.key).get().toPromise())

    const { program_term, termType } = this.selectedAcademicYear;

    if (termType.key === 1) this.program_term.enable()
    else
      this.program_term.patchValue(program_term)

    this.paymentStudentData = this.studentData[program_academic.program.key]

    if (this.paymentStudentData && this.paymentStudentData.endProgramTerm && this.paymentStudentData.endProgramYear) {
      const { endProgramTerm, endProgramYear } = this.paymentStudentData;
      const endProgramYearData = endProgramYear || { key: 2020, text: "2020-2021" }

      this.isPaidComplete = endProgramTerm.key === PROGRAM_TERMS_OBJ.term4.key &&
        endProgramYearData.key === this.selectedAcademicYear.academic_year.key ? true : false;

      const nextProgramTerm = endProgramTerm.key >= 4 ? endProgramTerm : PROGRAM_TERMS_OBJ[`term${endProgramTerm.key + 1}`]
      this.form.patchValue({
        program_term: nextProgramTerm
      })
      this.getPaymentOption(nextProgramTerm)
    }

    if (this.payment.paymentTuitionFee && this.payment.paymentTuitionFee.length > 0) {
      this.oldInvoice = this.payment.paymentTuitionFee[0];

      this.selectedAcademicYear = this.academicYearList.find(m => m.key === this.oldInvoice.issue_year.key)
      this.academic_year.patchValue(this.selectedAcademicYear)
    }

    this.currentTerm = this.program_term.value
    this.process = false;
  }

  async selectedYear(event) {
    const { value } = event.option

    if (value) {
      this.selectedAcademicYear = this.academicYearList.find(m => m.key === value.key)
      this.currentYear = this.academicYearList.find(m => m.key === value.key)
    }
  }

  getPaymentOption(value) {
    switch (value.key) {
      case 4:
        this.paymentOptionList = this.paymentOptionData.filter(m => m.period <= 3)
        break;
      case 3:
        this.paymentOptionList = this.paymentOptionData.filter(m => m.period <= 6)
        break;
      default:
        this.paymentOptionList = this.paymentOptionData
        break;
    }
  }

  selectedAcademicTerm(event) {
    this.paymentOption.patchValue(null)
    this.paymentList = []
    const { value } = event.option
    if (value) {
      this.getPaymentOption(value)
    }
  }

  displayItem(item: any): string {
    return item ? item.name : item;
  }

  displayItemText(item: any): string {
    return item ? item.text : item;
  }

  selectedFee(event) {
    this.haveStudentDiscount = false
    const { value } = event.option;
    const { program_term } = this.form.value

    const programTerm = program_term ? program_term : this.selectedAcademicYear.program_term
    if (value && programTerm) {

      const { period } = value;
      let toDatePay = null
      const { programTermItems } = this.selectedAcademicYear;

      this.currentTerm = programTermItems.find(m => m.key === programTerm.key);

      const { program_academic } = this.studentStore.selectedAdmission;
      const paymentStudentData = this.studentData[program_academic.program.key]
      const startDate = paymentStudentData ? paymentStudentData.paymentExpireDate.toDate() : this.currentTerm.program_term_start.toDate()


      if (this.selectedAcademicYear) {

        switch (period) {
          case 12:
            toDatePay = this.selectedAcademicYear.end_year_date.toDate()
            break;
          case 6:
            const endTermPeriod1 = programTermItems.find(m => m.key === (programTerm.key + 1));
            toDatePay = endTermPeriod1.program_term_end.toDate()
            break;
          case 3:
            const endTermPeriod2 = programTermItems.find(m => m.key === programTerm.key);
            toDatePay = endTermPeriod2.program_term_end.toDate()
            break;
          default:
            break;
        }

        this.form.patchValue({
          fromDate: startDate,
          toDate: toDatePay
        })
      }

      this.getPaymentList(value);
    }
  }

  getPaymentList(value) {
    this.paymentList = [];
    const { admin_fee, tuition_fee, payment_option_full_year } = this.optionData;

    if (this.trainingLevel && this.trainingLevel.pay_full_admin_fee) {

      const tuitionFeeData = this.trainingLevelFee.filter(m => m.paymentOption.key === value.key && m.fee.key === tuition_fee.key);
      if (tuitionFeeData && tuitionFeeData.length > 0)
        this.paymentList.push(tuitionFeeData[0]);

      const adminFeeData = this.trainingLevelFee.filter(m => m.paymentOption.key === payment_option_full_year.key && m.fee.key === admin_fee.key);
      const adminFeePeriod = this.paymentStudentData && this.paymentStudentData.adminFeePeriod ? this.paymentStudentData.adminFeePeriod : 0;
      if (adminFeeData && adminFeeData.length > 0 && toNumber(adminFeePeriod) < 12)
        this.paymentList.push(adminFeeData[0]);
    } else {

      const tuitionFeeData = this.trainingLevelFee.filter(m => m.paymentOption.key === value.key && m.fee.key === tuition_fee.key);
      if (tuitionFeeData && tuitionFeeData.length > 0)
        this.paymentList.push(tuitionFeeData[0]);

      const adminFeeData = this.trainingLevelFee.filter(m => m.paymentOption.key === value.key && m.fee.key === admin_fee.key);
      const adminFeePeriod = this.paymentStudentData && this.paymentStudentData.adminFeePeriod ? this.paymentStudentData.adminFeePeriod : 0;

      if (adminFeeData && adminFeeData.length > 0 && toNumber(adminFeePeriod) < 12)
        this.paymentList.push(adminFeeData[0]);

    }

    if (this.paymentList.length > 0) {
      this.totalFee = MappingService.sum(this.paymentList, "amount")

      // this.amount.patchValue(this.totalFee)
    }
  }

  create(f: any) {
    if (!this.selectedAcademicYear || this.isPaidComplete) return;
    if (this.form.valid && this.paymentList.length > 0) {
      this.form.disable();

      const { selectedCampus, selectedSchool } = this.env
      const { academic_year, paymentOption, fromDate, toDate, amount, amount_request, isOneTimePayment, pay_date, note } = f
      const { trainingGrade } = this.studentStore.selectedAdmission;
      const itemKey = this.ds.createId()

      const item: IAlignPayment = {
        key: itemKey,
        create_date_key: ConvertService.dateKey(),
        create_date: new Date(),
        create_by: userObj(this.env.user),
        status: recordStatus.active,

        student: studentObj(this.studentStore.student),
        programLevel: trainingGrade,
        admissionKey: this.studentStore.selectedAdmission.key,
        headerRef: itemKey,
        isPaid: false,

        academicYear: academicYearObj(academic_year),
        paymentOption: paymentOptionObj(paymentOption),
        fromDate,
        fromDateKey: ConvertService.toDateKey(fromDate),
        toDate,
        toDateKey: ConvertService.toDateKey(toDate),
        amount: !isOneTimePayment ? amount : amount_request,
        amount_request,
        isOneTimePayment,
        oldAmount: MappingService.sum(this.paymentList, "amount"),

        payment_status: SCHOOL_FEE_PAYMENT.installment,
        note: note || null,

        remainingAmount: amount_request - amount,
        pay_date: pay_date || null,
        pay_date_key: ConvertService.toDateKey(pay_date),
        installment_status: INSTALLMENT_PAYMENT_STATUS.pending,
        isHeader: true,

        campusKey: selectedCampus.key,
        campus: campusObj(selectedCampus),
        schoolKey: selectedSchool.key,
        school: schoolObj(selectedSchool)
      }

      const { tuition_fee } = this.optionData;
      let tuitionFee = this.paymentList.find(m => m.fee.key === tuition_fee.key)
      tuitionFee.amount = isOneTimePayment ? amount_request : amount
      this.paymentList = pushObjArray(this.paymentList, tuitionFee)

      this.store.addSchoolFee(selectedCampus, selectedSchool, this.payment.invoiceKey, f, this.currentTerm, this.paymentList, this.selectedAcademicYear, this.studentStore.student, this.studentStore.selectedAdmission, this.env.user, this.optionData, this.oldInvoice, item, (success, error) => {
        if (success) {
          this.snackBar.open("School Fee has been added.", "Done", { duration: 2000 })
          this.dialogRef.close('yes');
          this.form.enable();
        }
        else {
          this.snackBar.open(error, "Error");
          this.form.enable();
        }
      })

    }
  }
}
