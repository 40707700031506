import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  FormGroup,
  AbstractControl,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material";
import { PARENTS_STATUS, STUDENT_STAY_WITH, CAN_STATUS } from "src/app/dummy/status";
import { Environment } from "src/app/stores/environment.store";
import { Student } from "src/app/stores/student.store";
import { ConvertService, toNull } from "src/app/services/convert.service";
import { DataService } from "src/app/services/data.service";

@Component({
  selector: 'app-parent-contact',
  templateUrl: './parent-contact.component.html',
  styleUrls: ['./parent-contact.component.scss']
})
export class ParentContactComponent implements OnInit {
  @ViewChild("focusInput") inputEl: ElementRef;
  id: string;

  form: FormGroup;
  mother_name: AbstractControl;
  mother_address: AbstractControl;
  mother_home_phone: AbstractControl;
  mother_own_phone: AbstractControl;
  mother_occupation: AbstractControl;
  mother_work_place: AbstractControl;

  father_name: AbstractControl;
  father_address: AbstractControl;
  father_home_phone: AbstractControl;
  father_own_phone: AbstractControl;
  father_occupation: AbstractControl;
  father_work_place: AbstractControl;

  parent_status: AbstractControl;
  who_take_care: AbstractControl;

  student_stay_with: AbstractControl;
  guardian_name: AbstractControl;

  current_school: AbstractControl;
  current_school_year: AbstractControl;
  current_school_address: AbstractControl;
  current_school_manager: AbstractControl;
  current_school_phone: AbstractControl;
  current_school_quit_reason: AbstractControl;

  hospital_contact: AbstractControl;
  can_play_sport: AbstractControl;
  cannot_play_sport_reason: AbstractControl;
  name_sport_can_play: AbstractControl;
  other_interested_sport: AbstractControl;

  get_discipline: AbstractControl;
  get_discipline_reason: AbstractControl;

  get_reward: AbstractControl;
  get_reward_name: AbstractControl;

  parentStatusList = PARENTS_STATUS;
  stayWithList = STUDENT_STAY_WITH;
  canList = CAN_STATUS;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    public env: Environment,
    private route: ActivatedRoute,
    public store: Student,
    public ds: DataService,
  ) { }

  buildForm(): void {
    this.form = this.fb.group({
      mother_name: [null],
      mother_address: [null],
      mother_home_phone: [null],
      mother_own_phone: [null],
      mother_occupation: [null],
      mother_work_place: [null],

      father_name: [null],
      father_address: [null],
      father_home_phone: [null],
      father_own_phone: [null],
      father_occupation: [null],
      father_work_place: [null],

      parent_status: [null],
      who_take_care: [null],

      student_stay_with: [null],
      guardian_name: [null],

      current_school: [null],
      current_school_year: [null],
      current_school_address: [null],
      current_school_manager: [null],
      current_school_phone: [null],
      current_school_quit_reason: [null],

      hospital_contact: [null],
      can_play_sport: [null],
      cannot_play_sport_reason: [null],
      name_sport_can_play: [null],
      other_interested_sport: [null],

      get_discipline: [null],
      get_discipline_reason: [null],

      get_reward: [null],
      get_reward_name: [null],
    });

    this.mother_name = this.form.controls["mother_name"];
    this.mother_address = this.form.controls["mother_address"];
    this.mother_home_phone = this.form.controls["mother_home_phone"];
    this.mother_own_phone = this.form.controls["mother_own_phone"];
    this.mother_occupation = this.form.controls["mother_occupation"];
    this.mother_work_place = this.form.controls["mother_work_place"];

    this.father_name = this.form.controls["father_name"];
    this.father_address = this.form.controls["father_address"];
    this.father_home_phone = this.form.controls["father_home_phone"];
    this.father_own_phone = this.form.controls["father_own_phone"];
    this.father_occupation = this.form.controls["father_occupation"];
    this.father_work_place = this.form.controls["father_work_place"];

    this.parent_status = this.form.controls["parent_status"];
    this.who_take_care = this.form.controls["who_take_care"];

    this.student_stay_with = this.form.controls["student_stay_with"];
    this.guardian_name = this.form.controls["guardian_name"];

    this.current_school = this.form.controls["current_school"];
    this.current_school_year = this.form.controls["current_school_year"];
    this.current_school_address = this.form.controls["current_school_address"];
    this.current_school_manager = this.form.controls["current_school_manager"];
    this.current_school_phone = this.form.controls["current_school_phone"];
    this.current_school_quit_reason = this.form.controls["current_school_quit_reason"];

    this.hospital_contact = this.form.controls["hospital_contact"];
    this.can_play_sport = this.form.controls["can_play_sport"];
    this.cannot_play_sport_reason = this.form.controls["cannot_play_sport_reason"];
    this.name_sport_can_play = this.form.controls["name_sport_can_play"];
    this.other_interested_sport = this.form.controls["other_interested_sport"];

    this.get_discipline = this.form.controls["get_discipline"];
    this.get_discipline_reason = this.form.controls["get_discipline_reason"];
    this.get_reward = this.form.controls["get_reward"];
    this.get_reward_name = this.form.controls["get_reward_name"];

  }

  compareObjects(o1: any, o2: any): boolean {
    if (o2) { return o1.key === o2.key; }
  }

  async ngOnInit() {
    this.buildForm();
    this.route.parent.params.forEach(async (param) => {
      this.id = param["id"];
      const studentData = await this.store.fetchStudentDoc(this.id);
      this.store.student = studentData;
      if (studentData) {
        const {
          mother_name, mother_address, mother_home_phone, mother_own_phone, mother_occupation, mother_work_place,
          father_name, father_address, father_home_phone, father_own_phone, father_occupation, father_work_place,
          parent_status, who_take_care, student_stay_with, guardian_name,
          current_school, current_school_year, current_school_address, current_school_manager, current_school_phone,
          current_school_quit_reason, hospital_contact, can_play_sport, cannot_play_sport_reason,
          name_sport_can_play, other_interested_sport, get_discipline, get_discipline_reason, get_reward, get_reward_name
        } = studentData;

        this.form.patchValue({
          mother_name: toNull(mother_name),
          mother_address: toNull(mother_address),
          mother_home_phone: toNull(mother_home_phone),
          mother_own_phone: toNull(mother_own_phone),
          mother_occupation: toNull(mother_occupation),
          mother_work_place: toNull(mother_work_place),

          father_name: toNull(father_name),
          father_address: toNull(father_address),
          father_home_phone: toNull(father_home_phone),
          father_own_phone: toNull(father_own_phone),
          father_occupation: toNull(father_occupation),
          father_work_place: toNull(father_work_place),

          parent_status: toNull(parent_status) ? parent_status : this.parentStatusList[0],
          who_take_care: toNull(who_take_care),

          student_stay_with: toNull(student_stay_with) ? student_stay_with : this.stayWithList[0],
          guardian_name: toNull(guardian_name),

          current_school: toNull(current_school),
          current_school_year: toNull(current_school_year),
          current_school_address: toNull(current_school_address),
          current_school_manager: toNull(current_school_manager),
          current_school_phone: toNull(current_school_phone),
          current_school_quit_reason: toNull(current_school_quit_reason),

          hospital_contact: toNull(hospital_contact),
          can_play_sport: toNull(can_play_sport) ? can_play_sport : this.canList[1],
          cannot_play_sport_reason: toNull(cannot_play_sport_reason),
          name_sport_can_play: toNull(name_sport_can_play),
          other_interested_sport: toNull(other_interested_sport),

          get_discipline: toNull(get_discipline) ? get_discipline : this.canList[1],
          get_discipline_reason: toNull(get_discipline_reason),
          get_reward: toNull(get_reward) ? get_reward : this.canList[1],
          get_reward_name: toNull(get_reward_name),
        })
      }
    })
  }

  displayItem(item: any): string {
    return item ? item.name : null;
  }

  _goBack() {
    // if (this.type) {
    //   this.router.navigate(['/app/institute/' + this.key + '/' + this.type]);
    // }
    // else if (this.id) {
    //   this.router.navigate(['/student/' + this.id + '/personal']);
    // }
    // else {
    //   this.router.navigate(['/']);
    // }
  }

  create(f: any) {
    if (this.form.valid && this.env.user) {
      this.form.disable();

      const {
        mother_name, mother_address, mother_home_phone, mother_own_phone, mother_occupation, mother_work_place,
        father_name, father_address, father_home_phone, father_own_phone, father_occupation, father_work_place,
        parent_status, who_take_care, student_stay_with, guardian_name,
        current_school, current_school_year, current_school_address, current_school_manager, current_school_phone,
        current_school_quit_reason, hospital_contact, can_play_sport, cannot_play_sport_reason,
        name_sport_can_play, other_interested_sport, get_discipline, get_discipline_reason, get_reward, get_reward_name
      } = f;

      const item = {
        key: this.store.student.key,
        mother_name: mother_name,
        mother_address: mother_address,
        mother_home_phone: mother_home_phone,
        mother_own_phone: mother_own_phone,
        mother_occupation: mother_occupation,
        mother_work_place: mother_work_place,

        father_name: father_name,
        father_address: father_address,
        father_home_phone: father_home_phone,
        father_own_phone: father_own_phone,
        father_occupation: father_occupation,
        father_work_place: father_work_place,

        parent_status: parent_status,
        who_take_care: who_take_care,

        student_stay_with: student_stay_with,
        guardian_name: guardian_name,

        current_school: current_school,
        current_school_year: current_school_year,
        current_school_address: current_school_address,
        current_school_manager: current_school_manager,
        current_school_phone: current_school_phone,
        current_school_quit_reason: current_school_quit_reason,

        hospital_contact: hospital_contact,
        can_play_sport: can_play_sport,
        cannot_play_sport_reason: cannot_play_sport_reason,
        name_sport_can_play: name_sport_can_play,
        other_interested_sport: other_interested_sport,

        get_discipline: get_discipline,
        get_discipline_reason: get_discipline_reason,
        get_reward: get_reward,
        get_reward_name: get_reward_name,

        update_by: this.env.user,
        update_date_key: ConvertService.dateKey(),
        update_date: new Date(),
      }

      this.store.updateStudentDetail(item, (success, res) => {
        if (success) {
          this.snackBar.open("Student have been updated successfully.", "done", { duration: 2000 });
          this.form.enable();
        } else {
          this.snackBar.open(res, "Error");
          this.form.enable();
        }
      });
    }
  }

}