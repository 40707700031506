import { recordStatus, PROGRAM_TERMS } from 'src/app/dummy/status';
import { ConvertService } from 'src/app/services/convert.service';
import { MatSnackBar } from '@angular/material';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { Student } from 'src/app/stores/student.store';
import { Payment } from 'src/app/stores/payment.store';
import { Environment } from 'src/app/stores/environment.store';
import { MappingService, academicYearObj, toNumber, userObj, studentObj, pushObjArray, sum } from 'src/app/services/mapping.service';
import { DataService } from 'src/app/services/data.service';
import { Invoice } from 'src/app/stores/invoice.store';
import { IAlignPayment } from 'src/app/interfaces/invoice';
import { Registration } from 'src/app/stores/registration.store';
import { BaseStore } from 'src/app/stores/base.store';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-student-cut-payment',
  templateUrl: './add-student-cut-payment.component.html',
  styleUrls: ['./add-student-cut-payment.component.scss']
})
export class AddStudentCutPaymentComponent implements OnInit {
  form: FormGroup;
  academic_year: AbstractControl;
  admission_date: AbstractControl;
  paymentOption: AbstractControl;
  fromDate: AbstractControl;
  toDate: AbstractControl;
  program_term: AbstractControl;
  addDay: AbstractControl;

  filteredAcademicYearStates: Observable<any[]>;
  programTermList = PROGRAM_TERMS;
  allowAddDay = false

  currentTerm;
  gradeTotal;
  constructor(
    public dialogRef: MatDialogRef<AddStudentCutPaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    public store: Registration,
    public ds: DataService,
    public env: Environment,
    public studentStore: Student,
    public invoice: Invoice,
    public baseStore: BaseStore,
    public payment: Payment,
    private router: Router
  ) { }

  buildForm(): void {
    this.form = this.fb.group({
      academic_year: [null, Validators.compose([Validators.required, MappingService.validSelected.bind(this)])],
      admission_date: [null, Validators.required],
      paymentOption: [null, Validators.compose([MappingService.validSelected.bind(this)])],
      fromDate: [null, Validators.required],
      toDate: [null, Validators.required],
      program_term: [PROGRAM_TERMS[0], Validators.compose([MappingService.validSelected.bind(this)])],

      addDay: [null, [Validators.compose([this.validCashIn.bind(this)])]],

    })
    this.addDay = this.form.controls['addDay'];
    this.academic_year = this.form.controls['academic_year'];
    this.admission_date = this.form.controls['admission_date'];
    this.paymentOption = this.form.controls['paymentOption'];
    this.fromDate = this.form.controls['fromDate'];
    this.toDate = this.form.controls['toDate'];
    this.program_term = this.form.controls["program_term"];
  }

  public validCashIn(control: AbstractControl): { [s: string]: boolean } {
    const value = control.value;
    if (value !== undefined && value !== null && value !== "") {
      if (value < 0) {
        return { validAmount: true }
      }

      const { fromDate, toDate, paymentOption } = this.form.value
      if (value && fromDate && toDate && paymentOption && this.paymentList && this.paymentList.length > 0) {
        this.totalDay = value

        const { tuition_fee } = this.optionData;
        const tuitionFeeData = this.paymentList.filter(m => m.paymentOption.key === paymentOption.key && m.fee.key === tuition_fee.key);
        if (tuitionFeeData && tuitionFeeData.length > 0) {
          this.tuitionFeeDoc = tuitionFeeData[0];
          const { oldAmount } = tuitionFeeData[0];

          const newAmount = ConvertService.roundUp2((oldAmount * this.totalDay) / (paymentOption.period * 30))
          this.tuitionFeeDoc.amount = newAmount
          this.paymentList = pushObjArray(this.paymentList, this.tuitionFeeDoc);
          this.gradeTotal = sum(this.paymentList, "amount")
        }

      }
    }
  }

  currentYear: any;
  selectedAcademicYear: any;
  totalDay: any = null;
  paymentOptionData = [];
  paymentOptionList = [];
  paymentList = [];
  trainingLevelFee = [];
  trainingLevel = null;
  optionData = null;
  oldInvoice = null;
  tuitionFeeDoc = null;
  isPaidComplete = false;
  studentData = null;
  process = true;
  async ngOnInit() {
    this.buildForm();
    const { campus, program_academic } = this.studentStore.selectedAdmission;
    const { program } = program_academic;
    await this.env.fetchUserDoc()
    const { selectedSchool } = this.env

    const { student } = this.studentStore
    if (student && student.add_actual_day) {
      this.allowAddDay = true
    }

    this.baseStore.fetchAcademicYearToArray(selectedSchool.key, program.programOption.key, list => {
      this.filteredAcademicYearStates = MappingService.autoComplete(this.academic_year, list, "name");
    });

    const envData: any = await this.env.fetchAcademicEnv(selectedSchool.key);
    this.currentYear = envData.year;

    this.studentData = await this.baseStore.fetchDoc(this.ds.studentDocument(student.key));
    this.optionData = await this.baseStore.fetchDoc(this.ds.settingDBFireStore());
    this.paymentOptionData = await this.baseStore.fetchList(this.ds.paymentOptionRef());

    this.paymentOptionList = this.paymentOptionData

    if (campus && program_academic) {
      const { program } = program_academic;
      if (program.programOption.key === 1) {

        this.currentYear = envData.year;
      } else {

        this.currentYear = envData.term;
      }

      this.trainingLevelFee = await this.baseStore.fetchList(this.ds.campusRef().doc(campus.key).collection("training_levels").doc(program_academic.key).collection("training_school_fee"))
      this.trainingLevel = await this.baseStore.fetchDoc(this.ds.campusRef().doc(campus.key).collection("training_levels").doc(program_academic.key));
    }

    // const academicYearData = MappingService.pushToObject(await this.ds.academicYearRef().doc(this.currentYear.key).get().toPromise())
    // this.currentYear = academicYearData;

    if (this.payment.paymentTuitionFee && this.payment.paymentTuitionFee.length > 0) {
      this.oldInvoice = this.payment.paymentTuitionFee[0];

      // const { fromDate, toDate, paymentOption } = this.oldInvoice;
      // this.form.patchValue({
      //   fromDate: fromDate.toDate(),
      //   toDate: toDate.toDate(),
      //   paymentOption
      // })

      // this.getPaymentList(paymentOption);
    }

    this.process = false;
  }

  displayItem(item: any): string {
    return item ? item.name : item;
  }

  displayItemText(item: any): string {
    return item ? item.text : item;
  }

  _onDateChange(value) {
    if (value) {
      this.totalDay = null;
      this.form.patchValue({
        fromDate: null,
        paymentOption: null,
        toDate: null,
      })
    }
  }

  async _onSelectedAcademicYear(event) {
    this.selectedAcademicYear = null;
    this.totalDay = null;
    this.paymentList = []
    this.program_term.disable()
    const { value } = event.option;
    if (value) {

      this.selectedAcademicYear = await this.env.fetchAcademicYear(value.key)

      const { program_term, termType } = this.selectedAcademicYear;
      this.form.patchValue({
        fromDate: null,
        paymentOption: null,
        toDate: null,
        program_term: program_term
      })

      if (termType.key === 1) this.program_term.enable()
      else this.program_term.patchValue(program_term)

    }
  }

  getPaymentOption(value) {
    switch (value.key) {
      case 4:
        this.paymentOptionList = this.paymentOptionData.filter(m => m.period <= 3)
        break;
      case 3:
        this.paymentOptionList = this.paymentOptionData.filter(m => m.period <= 6)
        break;
      default:
        this.paymentOptionList = this.paymentOptionData
        break;
    }
  }

  selectedAcademicTerm(event) {
    this.paymentOption.patchValue(null)
    this.totalDay = null
    this.paymentList = []
    const { value } = event.option
    if (value) {
      this.getPaymentOption(value)
    }
  }

  async selectedFee(event) {
    const { value } = event.option;
    const { period } = value;
    this.addDay.patchValue(null)

    const { admission_date, program_term } = this.form.value
    const { programTermItems, } = this.selectedAcademicYear

    const programTerm = program_term ? program_term : this.selectedAcademicYear.program_term

    let toDatePay = null
    const startDate = admission_date
    this.currentTerm = programTermItems.filter(m => m.key === programTerm.key)[0];

    switch (period) {
      case 12:
        toDatePay = ConvertService.addMonthDateRemove1Day(this.currentTerm.program_term_start.toDate(), period)
        break;
      case 6:
        toDatePay = ConvertService.addMonthDateRemove1Day(this.currentTerm.program_term_start.toDate(), period)
        break;
      case 3:
        toDatePay = this.currentTerm.program_term_end.toDate()
        break;
      default:
        break;
    }

    this.form.patchValue({
      fromDate: startDate,
      toDate: toDatePay
    })

    // if (this.currentYear && this.currentYear.programTermItems && this.currentYear.programTermItems.length > 0) {
    //   const currentTerm = program_term ? this.currentYear.programTermItems.filter(m => m.key === program_term.key)[0] : null;

    //   switch (period) {
    //     case 12:
    //       toDatePay = ConvertService.addDateDay(this.currentYear.end_year_date.toDate(), 1)
    //       break;
    //     case 6:
    //       toDatePay = ConvertService.addDateMonth(currentTerm.program_term_start.toDate(), period)
    //       break;
    //     case 3:
    //       toDatePay = currentTerm.program_term_end.toDate()
    //       break;
    //     default:
    //       toDatePay = ConvertService.addDateDay(this.currentYear.end_year_date.toDate(), 1)
    //       break;
    //   }

    //   this.form.patchValue({
    //     fromDate: startDate,
    //     toDate: toDatePay
    //   })
    // }

    if (this.form.value.academic_year && this.form.value.admission_date) {
      this.totalDay = ConvertService.countDay(toDatePay, admission_date)

      this.addDay.patchValue(this.totalDay)

      // switch (period) {
      //   case 6:
      //     this.totalDay = ConvertService.toFloatFixed(this.totalDay / 2, 0)
      //     break;
      //   case 3:
      //     this.totalDay = ConvertService.toFloatFixed(this.totalDay / 4, 0)
      //     break;
      //   default:
      //     break;
      // }
    }

    this.getPaymentList(value);
  }

  async getPaymentList(value) {
    this.tuitionFeeDoc = null;
    const { campus, program_academic } = this.studentStore.selectedAdmission;
    this.trainingLevelFee = await this.baseStore.fetchList(this.ds.campusRef().doc(campus.key).collection("training_levels").doc(program_academic.key).collection("training_school_fee"))
    this.trainingLevel = await this.baseStore.fetchDoc(this.ds.campusRef().doc(campus.key).collection("training_levels").doc(program_academic.key));

    this.paymentList = [];
    const { admin_fee, tuition_fee, payment_option_full_year } = this.optionData;
    const trainingLevelFeeData = this.trainingLevelFee;

    if (this.trainingLevel && this.trainingLevel.pay_full_admin_fee) {
      const tuitionFeeData = trainingLevelFeeData.filter(m => m.paymentOption.key === value.key && m.fee.key === tuition_fee.key);
      if (tuitionFeeData && tuitionFeeData.length > 0)
        this.paymentList.push(tuitionFeeData[0]);

      const adminFeeData = trainingLevelFeeData.filter(m => m.paymentOption.key === payment_option_full_year.key && m.fee.key === admin_fee.key);
      const { adminFeePeriod } = this.studentStore.selectedAdmission;
      if (adminFeeData && adminFeeData.length > 0 && toNumber(adminFeePeriod) === 0) {
        // const { program_term } = this.form.value
        const adminFeeDoc = adminFeeData[0]
        // adminFeeDoc.amount = ConvertService.convertAdminFeeAmount(program_term, adminFeeDoc)
        this.paymentList.push(adminFeeDoc);
      }
    } else {

      this.paymentList = trainingLevelFeeData.filter(m => m.paymentOption.key === value.key);
    }

    const tuitionFeeData = trainingLevelFeeData.filter(m => m.paymentOption.key === value.key && m.fee.key === tuition_fee.key);
    if (tuitionFeeData && tuitionFeeData.length > 0) {
      this.tuitionFeeDoc = tuitionFeeData[0];
      const { amount } = tuitionFeeData[0];
      const newAmount = ConvertService.roundUp2((amount * this.totalDay) / (value.period * 30))
      this.tuitionFeeDoc.amount = newAmount
      this.tuitionFeeDoc.oldAmount = amount

      this.paymentList = pushObjArray(this.paymentList, this.tuitionFeeDoc);
    }
  }

  create(f: any) {
    if (!this.selectedAcademicYear) return;
    if (this.form.valid && this.paymentList.length > 0) {
      this.form.disable();
      const { selectedCampus, selectedSchool } = this.env
      const { admission_date, academic_year, paymentOption, fromDate, toDate } = f
      const { trainingGrade } = this.studentStore.selectedAdmission;
      const itemKey = this.ds.createId()
      const item: IAlignPayment = {
        key: itemKey,
        create_date_key: ConvertService.dateKey(),
        create_date: new Date(),
        create_by: userObj(this.env.user),
        status: recordStatus.active,

        student: studentObj(this.studentStore.student),
        programLevel: trainingGrade,
        admissionKey: this.studentStore.selectedAdmission.key,
        headerRef: itemKey,
        isPaid: false,

        admission_date,
        admission_date_key: ConvertService.toDateKey(admission_date),
        academicYear: academicYearObj(academic_year),
        paymentOption,
        fromDate,
        fromDateKey: ConvertService.toDateKey(fromDate),
        toDate,
        toDateKey: ConvertService.toDateKey(toDate),
        amount: this.tuitionFeeDoc.amount,
        oldAmount: this.tuitionFeeDoc.oldAmount,

        campusKey: selectedCampus.key,
        campus: selectedCampus,
        schoolKey: selectedSchool.key,
        school: selectedSchool
      }
      this.store.addSchoolFee(selectedCampus, selectedSchool, this.payment.invoiceKey, f, this.currentTerm, this.paymentList, this.selectedAcademicYear, this.studentStore.student, this.studentStore.selectedAdmission, this.env.user, this.optionData, this.oldInvoice, item, (success, error) => {
        if (success) {
          this.snackBar.open("School Fee has been added.", "Done", { duration: 2000 })
          this.dialogRef.close('yes');
          this.form.enable();

          const { selectedAdmission, student } = this.studentStore
          this.router.navigate(["/student/" + student.key + '/' + selectedAdmission.key + "/receive-payment"]);
        }
        else {
          this.snackBar.open(error, "Error");
          this.form.enable();
        }
      })
    }
  }
}