import { DataService } from 'src/app/services/data.service';
import { observable, action } from "mobx";
import { Injectable } from "@angular/core";
import { pushToArray, MappingService, userObj, campusObj, studentObj, trainingFeeObj, academicTrainingFeeObj, academicYearObj, pushToObject, sum, createUniqueKey, toCapitalize, toDateCalendar, toDateKey, shiftObj, academicTrainingProgramObj, ageFromDateOfBirthday, schoolObj, getAge } from '../services/mapping.service';
import { IRegistrationResult, ITesting } from '../interfaces/testing';
import { AdministratorService } from '../services/administrator.service';
import { ConvertService, toUpperCase } from '../services/convert.service';
import { IStudent, IProgramAcademic, IStudentAccount } from '../interfaces/student';
import { recordStatus, invoiceTypesObj, paymentStatus, enrollmentTypes, admissionType, studentTypes, SUBJECT_DATA, TestFeeStatus, PROGRAM_TERMS_OBJ } from '../dummy/status';
import { IInvoice } from '../interfaces/invoice';
import * as firebase from 'firebase/app';
import { IAdmission } from '../interfaces/admission';

@Injectable()
export class Registration {
  @observable data = [];
  @observable loading = true;
  @observable empty = false;
  @observable process = false;

  constructor(
    public ds: DataService,
    private as: AdministratorService
  ) { }

  @action
  fetchFee() { }

  @action
  async fetchSchoolCampus(schoolKey) {
    const campusDoc = await this.ds.storeDocRef(schoolKey).collection("campus", ref => ref.orderBy("name")).get().toPromise()
    return pushToArray(campusDoc)
  }

  async spsProgramFromCampus(campusKey) {
    const programDoc = await this.ds.campusDocRef(campusKey).collection('training_programs', ref => ref.orderBy("name")).get().toPromise()
    return pushToArray(programDoc)
  }

  async spsProgramLevel(campusKey, programKey) {
    const levelDoc = await this.ds.campusDocRef(campusKey).collection('training_levels', ref => ref.where('program.key', '==', programKey)).get().toPromise()
    let levelData = pushToArray(levelDoc)
    levelData = MappingService.orderBy(levelData, "order")
    return levelData;
  }

  async fetchAcademicYearByProgram(storeKey, optionKey) {
    const yearDoc = await this.ds.storeDocRef(storeKey).collection('academic_year', ref => ref.where('termType.key', '==', optionKey)).get().toPromise()
    let yearData = pushToArray(yearDoc)
    yearData = MappingService.orderBy(yearData, "startDate")
    return yearData;
  }

  @action
  async registerOnlineTesting(item, user, callback) {
    this.process = true;

    const { school, student } = item;

    const studentDoc = await this.ds.studentDoc(student.key).get().toPromise()
    const studentData: any = pushToObject(studentDoc)
    const data = {
      ...studentData,
      ...item,
    }

    const batch = this.ds.batch();
    const newKey = this.ds.createId()
    const birthDateKey = ConvertService.toDateKey(data.dob.toDate())
    const uniqueKey = createUniqueKey(data.first_name, data.last_name, birthDateKey, data.gender.text || "");

    const testingOption: any = pushToObject(await this.ds.testingOptionRef().get().toPromise());

    const isKGETesting = testingOption.test_KGE.key === data.program.key;
    const isIEPTesting = testingOption.test_IEP.key === data.program.key;
    const isTestingChinese = testingOption.test_CHINESE === data.program.key;

    const academicYear = academicYearObj(data.admission_term)

    const testingData = {
      key: newKey,
      khmer_first_name: data.khmer_first_name || null,
      khmer_last_name: data.khmer_last_name || null,
      first_name: toCapitalize(data.first_name),
      last_name: toCapitalize(data.last_name),
      full_name: toCapitalize(`${data.last_name} ${data.first_name}`),
      dob: data.dob ? toDateCalendar(data.dob.toDate()) : null,
      gender: data.gender,
      mobile_phone: data.mobile_phone,
      note: data.remark,
      price: testingOption.testing_fee,
      student: studentObj(studentData), //
      isPaidTest: false,
      serial_id: data.serial_id || null, //
      admission_date_key: toDateKey(new Date()),
      create_date_key: toDateKey(new Date()),
      status: { key: 1, text: "Active" },
      payment_type: null,
      subject_type: (isIEPTesting || isKGETesting) ? SUBJECT_DATA.khmer : null,
      admission_date: firebase.firestore.FieldValue.serverTimestamp(),
      create_date: firebase.firestore.FieldValue.serverTimestamp(),
      create_by: MappingService.userObj(user),
      campus: campusObj(data.campus),
      shift: shiftObj(data.shift),
      program: academicTrainingProgramObj(data.program),
      programLevel: data.level || null,
      gradeKey: data.level && data.level.gradeKey && data.level.gradeKey || null,
      puc_id: data.puc_id || null,
      used: false,
      onlineTesting: false,
      page_key: ConvertService.pageKey(),
      isPaid: TestFeeStatus.unpaid,
      interview: false,
      unique_code: uniqueKey,
      noTakeTest: ageFromDateOfBirthday(data.dob) < 6 ? true : false,
      campusKey: data.campus.key,
      schoolKey: school.key,
      school: schoolObj(school),
      age: data.dob ? getAge(toDateCalendar(data.dob.toDate())) : null,
      admission_term: academicYear
    }

    const testingOnlineRef = this.ds.testingFireRef().doc(testingData.key)
    const studentRef = this.ds.studentFireRef().doc(student.key)

    const studentInvoiceRef = this.ds.studentFireRef().doc(studentData.key).collection("invoices");

    const headerKey = testingData.key;
    const date = new Date();
    const create_by = MappingService.userObj(user);
    const invoiceNo = null;
    const grandTotal = testingOption.registrationFee.price;

    const { level, program, campus } = data
    const programAcademic: IProgramAcademic = {
      key: level && level.key || null,
      name: level && level.name || null,
      scholarshipKey: program.key,
      program: program,
      category: academicYear,
      admissionKey: null,
      grade_exam_key: null,
    }

    const invoiceHeader: IInvoice = {
      key: headerKey,
      display_program: program.name,
      display_level: "Registration Fee",
      student_register: "New",
      display_shift: null,
      create_date: date,
      create_date_key: ConvertService.toDateKey(date),
      create_by: create_by,
      issue_by: create_by,
      issue_date: date,
      invoice_no: invoiceNo,
      invoice_type: invoiceTypesObj.registrationFee,
      student: MappingService.studentObj(studentData),
      verify_by: create_by,
      verify_date: date,
      verify_date_key: ConvertService.toDateKey(date),
      page_key: ConvertService.pageKey(),
      invoice_date: date,
      invoice_date_key: ConvertService.toDateKey(date),
      course: null,
      isPaid: paymentStatus.unpaid,
      isVoid: false,
      program: programAcademic,
      price: grandTotal,
      amount: grandTotal,
      issue_term: null,
      payment_term: null,
      headerRef: headerKey,
      isHeader: true,
      isEnrollVerify: true,
      schoolSession: null,
      description: null,
      penalty: null,
      prepaid: null,
      scholarship: null,
      scholarshipRef: null,
      prepaidRef: null,
      penaltyRef: null,

      campusGrade: campusObj(campus),

      campusKey: campus.key,
      campus: campusObj(campus),
      schoolKey: school.key,
      school: schoolObj(school),
    }

    const invoiceDetail: IInvoice = {
      key: this.ds.createId(),
      display_program: program.name,
      display_level: "Registration Fee",
      student_register: "New",
      display_shift: null,
      create_date: date,
      create_date_key: ConvertService.toDateKey(date),
      create_by: create_by,
      issue_by: create_by,
      issue_date: date,
      invoice_no: invoiceNo,
      invoice_type: invoiceTypesObj.registrationFee,
      student: MappingService.studentObj(studentData),
      verify_by: create_by,
      verify_date: date,
      page_key: ConvertService.pageKey(),
      verify_date_key: ConvertService.toDateKey(date),
      invoice_date: date,
      invoice_date_key: ConvertService.toDateKey(date),
      course: null,
      isPaid: paymentStatus.unpaid,
      isVoid: false,
      program: programAcademic,
      byAdmission: false,
      price: grandTotal,
      amount: grandTotal,
      issue_term: null,
      payment_term: null,
      headerRef: headerKey,
      isHeader: false,
      isEnrollVerify: true,
      schoolSession: null,
      description: null,
      scholarship: null,
      scholarshipRef: null,

      campusGrade: campusObj(campus),

      campusKey: campus.key,
      campus: campusObj(campus),
      schoolKey: school.key,
      school: schoolObj(school),
    }
    batch.set(studentInvoiceRef.doc(invoiceHeader.key), invoiceHeader);
    batch.set(studentInvoiceRef.doc(invoiceDetail.key), invoiceDetail);

    batch.set(testingOnlineRef, testingData);
    batch.update(studentRef, { recent_test_key: testingData.key });

    batch.commit().then(() => {
      this.process = false;
      callback(true, testingData)
    }).catch(e => {
      this.process = false
      callback(false, e)
    });

  }

  @action
  async setAdmission(item: IRegistrationResult, student: any, testing: any, callback) {

    this.process = true;
    const batch = this.ds.batch();
    const { trainingGrade, academicYear, campus, shift, trainingProgram, note } = item;
    const { grade, program } = trainingGrade;

    const studentRef = this.ds.studentFireRef().doc(student.key);
    const admissionRef = this.ds.studentAdmissionFireRef();
    const testingRef = this.ds.testingFireRef();
    // const testingResultRef = this.ds.testingResultFireRef();

    const date = new Date();
    const create_by = userObj(item.create_by);
    const admissionKey = this.ds.createId();

    const academicYearData = {
      key: academicYear.key,
      name: academicYear.name,
    }
    const programAcademic: IProgramAcademic = {
      key: trainingGrade.key,
      name: trainingGrade.name,
      scholarshipKey: program.key,
      program: program,
      category: academicYearData,
      admissionKey: admissionKey,
      grade_exam_key: trainingGrade.grade.key,
    }

    student.program_academic = programAcademic;

    const tags = [
      admissionKey,
      student.key,
      academicYearData.key,
      grade.key,
      toUpperCase(grade.name),
      program.key,
      campus.key,
    ];

    const admission: IAdmission = {
      key: admissionKey,
      create_date: date,
      create_date_key: ConvertService.dateKey(),
      create_by: userObj(create_by),
      update_date: date,
      update_date_key: ConvertService.dateKey(),
      update_by: userObj(create_by),
      status: recordStatus.active,
      date_key: ConvertService.dateKey(),
      page_key: ConvertService.pageKey(),

      academicYear: academicYearObj(academicYear),
      academicYearKey: academicYear.key,
      academicYearRef: this.ds.academicYearRef().doc(academicYear.key).ref,
      trainingProgram: trainingProgram,
      trainingProgramKey: trainingProgram.key,
      trainingProgramRef: this.ds.campusRef().doc(campus.key).collection("training_programs").doc(trainingProgram.key).ref,
      trainingGrade: academicTrainingFeeObj(trainingGrade),
      trainingGradeKey: trainingGrade.key,
      trainingGradeRef: this.ds.campusRef().doc(campus.key).collection("training_levels").doc(trainingGrade.key).ref,
      tags: tags,
      priceList: null,
      paymentPeriod: 0,
      adminFeePeriod: 0,
      note: note,
      shift: shift,

      puc_generation: null,
      major_generation: null,
      major: null,
      student_type: studentTypes.pucStudent,
      admission_type: admissionType.psis,
      student: MappingService.studentObj(student),
      studentKey: student.key,
      studentRef: this.ds.studentDocument(student.key).ref,
      curriculum: null,
      faculty: null,
      isPaid: false,
      isPaidAdminFee: false,
      isImport: false,
      is_student_PSIS: true,
      is_student_institute: false,
      is_student_academic: false,
      program_academic: programAcademic,
      isHaveBatch: false,
      isFreshman: true,

      campusKey: null,
      campus: campus,
      schoolKey: null,
      school: null,
    };

    batch.set(admissionRef.doc(admission.key), admission);

    batch.update(studentRef, {
      program_academic: programAcademic,
      campus
    });

    //TESTING RESULT
    // batch.update(testingRef.doc(testing.key), {
    //   testingResultKey: item.key,
    //   testingResultRef: this.ds.testingResultDBRef().doc(item.key).ref,
    // });
    // batch.set(testingResultRef.doc(item.key), item);

    const trainingLevelFeeDocs = await this.ds.campusRef().doc(campus.key).collection("training_levels").doc(trainingGrade.key).collection("training_school_fee").get().toPromise();
    const trainingLevelFee = pushToArray(trainingLevelFeeDocs);

    const optionDocs = await this.ds.settingDBFireStore().get().toPromise();
    const optionData: any = pushToObject(optionDocs);


    let paymentList = [];
    const trainingLevel = trainingGrade;
    const { admin_fee, tuition_fee, payment_option_full_year, payment_option_three_months } = optionData;
    const paymentOption = academicYear.termType.key === 1 ? payment_option_full_year : payment_option_three_months;

    if (trainingLevel && trainingLevel.pay_full_admin_fee) {

      const tuitionFeeData = trainingLevelFee.filter((m: any) => m.paymentOption.key === paymentOption.key && m.fee.key === tuition_fee.key);
      if (tuitionFeeData && tuitionFeeData.length > 0)
        paymentList.push(tuitionFeeData[0]);

      const adminFeeData = trainingLevelFee.filter((m: any) => m.paymentOption.key === payment_option_full_year.key && m.fee.key === admin_fee.key);
      if (adminFeeData && adminFeeData.length > 0)
        paymentList.push(adminFeeData[0]);
    } else {

      paymentList = trainingLevelFee.filter((m: any) => m.paymentOption.key === paymentOption.key);
    }

    const headerKey = this.ds.createId();
    const invoiceNo = null;

    const enrollmentType = enrollmentTypes.PSIS;
    const displayProgram = trainingProgram.name;
    const displayLevel = trainingGrade.grade.name;
    const displayShift = shift ? shift.name : "Morning";
    const schoolFeeType = null;
    const grandTotal = sum(paymentList, "amount");

    const tuitionData = paymentList.filter(m => m.fee.key === tuition_fee.key);
    let description = null;
    if (tuitionData && tuitionData.length > 0) {
      description = tuitionData[0].paymentOption.name
    }

    const fromDate = new Date;
    const toDate = ConvertService.addMonth(paymentOption.period);

    const invoiceHeader: IInvoice = {
      key: headerKey,
      description: description,
      display_program: displayProgram,
      display_level: displayLevel,
      invoice_no: invoiceNo,
      invoice_type: invoiceTypesObj.tuitionFee,
      fee_category: tuition_fee,

      student: studentObj(student),
      studentKey: student.key,
      studentRef: this.ds.studentDocument(student.key).ref,
      isPaid: paymentStatus.unpaid,
      program: programAcademic,
      enrollment_type: enrollmentType,

      invoice_date: date,
      invoice_date_key: ConvertService.dateKey(),
      course: null,
      isVoid: false,
      byAdmission: true,
      issue_year: academicYear,
      issue_yearKey: academicYear.key,
      issue_yearRef: this.ds.academicYearRef().doc(academicYear.key).ref,
      campus: campus,
      payment_year: null,
      headerRef: headerKey,
      isHeader: true,
      price: grandTotal,
      amount: grandTotal,
      isEnrollVerify: true,

      display_shift: displayShift,
      shiftKey: null,
      create_date: date,
      create_date_key: ConvertService.dateKey(),
      create_by: create_by,
      page_key: ConvertService.pageKey(),
      school_fee_type: schoolFeeType,

      schoolSession: null,
      penaltyRef: null,
      penalty: null,
      scholarshipRef: null,
      scholarship: null,
      loan: null,
      prepaidRef: null,
      prepaid: null,

      fromDate: fromDate,
      fromDateKey: ConvertService.toDateKey(fromDate),
      toDate: toDate,
      toDateKey: ConvertService.toDateKey(toDate),

      campusGrade: admission.campus,
    }

    paymentList.forEach(m => {

      const invoiceType = m.fee.key === tuition_fee.key ? invoiceTypesObj.tuitionFee : invoiceTypesObj.otherFee;
      const course = m.fee.key === tuition_fee.key ? trainingGrade : m.fee;
      const invoiceDetail: IInvoice = {
        key: this.ds.createId(),
        description: course.name,
        display_program: displayProgram,
        display_level: displayLevel,
        invoice_no: invoiceNo,
        invoice_type: invoiceType,
        fee_category: m.fee,
        paymentOption: m.paymentOption,

        student: studentObj(student),
        studentKey: student.key,
        studentRef: this.ds.studentDocument(student.key).ref,
        isPaid: paymentStatus.unpaid,
        program: programAcademic,
        enrollment_type: enrollmentType,

        invoice_date: date,
        invoice_date_key: ConvertService.dateKey(),
        course: course,
        isVoid: false,
        byAdmission: true,
        issue_year: academicYear,
        issue_yearKey: academicYear.key,
        issue_yearRef: this.ds.academicYearRef().doc(academicYear.key).ref,
        campus: campus,
        payment_year: null,
        headerRef: headerKey,
        isHeader: false,
        price: m.amount,
        amount: m.amount,
        isEnrollVerify: true,
        isFreshmen: false,

        display_shift: displayShift,
        shiftKey: null,
        create_date: date,
        create_date_key: ConvertService.dateKey(),
        create_by: create_by,
        page_key: ConvertService.pageKey(),
        school_fee_type: schoolFeeType,
        schoolSession: null,
        penaltyRef: null,
        penalty: null,
        scholarshipRef: null,
        scholarship: null,
        loan: null,
        prepaidRef: null,
        prepaid: null,

        campusGrade: admission.campus,
        trainingLevelKey: trainingGrade.key,
        trainingLevelRef: this.ds.campusRef().doc(admission.campus.key).collection("training_levels").doc(trainingGrade.key).ref,
        trainingProgramKey: trainingProgram.key,
        trainingProgramRef: this.ds.campusRef().doc(admission.campus.key).collection("training_programs").doc(trainingProgram.key).ref,

        fee: trainingFeeObj(m),
        feeKey: m.key,
        feeRef: this.ds.campusRef().doc(admission.campus.key).collection("training_levels").doc(trainingGrade.key).collection("training_school_fee").doc(m.key).ref,

        fromDate: fromDate,
        fromDateKey: ConvertService.toDateKey(fromDate),
        toDate: toDate,
        toDateKey: ConvertService.toDateKey(toDate),
      }

      batch.set(studentRef.collection("invoices").doc(invoiceDetail.key), invoiceDetail);
    })

    batch.set(studentRef.collection("invoices").doc(invoiceHeader.key), invoiceHeader);

    this.process = false;
    // batch.commit().then(() => {
    //   this.process = false;
    //   callback(true, null);
    // }).catch(error => {
    //   this.process = false;
    //   callback(false, error);
    // })
  }

  @action
  async register(f: ITesting, user: any, registrationPrice: number, isRetakeTest: boolean, student: any, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const studentKey = student ? student.key : this.ds.createId();

    const sysData = await this.ds.sysSetting().get().toPromise();
    const sysDoc = MappingService.pushToObject(sysData);

    const { serial_number } = sysDoc
    const serialID = serial_number + 1;
    const serial_id = 'S' + serialID.toString();
    const last_name = f.last_name.trim().toUpperCase();
    const first_name = f.first_name.trim().toUpperCase();

    const { grade, program, year } = f
    const programAcademic: IProgramAcademic = {
      key: grade.key,
      name: grade.name,
      scholarshipKey: program.key,
      program: program,
      category: year,
      admissionKey: null,
      grade_exam_key: null,
    }

    f.serial_id = serial_id;
    f.first_name = first_name;
    f.last_name = last_name;
    f.full_name = last_name + ' ' + first_name;
    f.key = this.ds.createId();

    const { campusKey, campus, schoolKey, school } = f
    const studentData: IStudent = {
      key: studentKey,
      unique_code: f.unique_code,
      first_name: first_name,
      last_name: last_name,
      khmer_first_name: f.khmer_first_name,
      khmer_last_name: f.khmer_last_name,
      full_name: last_name + ' ' + first_name,
      address: f.address,
      gender: f.gender,
      serial_id: f.serial_id,
      puc_id: f.puc_id,
      recent_test_key: f.key,
      is_puc_student: false,
      dob: f.dob,
      dobKey: ConvertService.toDateKey(f.dob),
      page_key: ConvertService.pageKey(),
      mobile_phone: f.mobile_phone,
      email: null,
      email_address: f.email_address,
      pob: null,
      marital_status: null,
      nationality: null,
      home_address: null,
      work_place: null,
      position: null,
      parent_name: null,
      spouse_name: null,
      emergency_person_name: null,
      emergency_relationship: null,
      emergency_address: null,
      emergency_phone: null,

      english_school_name: null,
      english_school_province: null,
      english_school_year: null,
      high_school_name: null,
      high_school_province: null,
      high_school_year: null,
      college_school_name: null,
      college_school_degree: null,
      college_school_major: null,
      college_school_year: null,
      university_school_name: null,
      university_school_degree: null,
      university_school_major: null,
      university_school_year: null,
      graduate_school_name: null,
      graduate_school_degree: null,
      graduate_school_major: null,
      graduate_school_year: null,
      education_type_at_puc: null,
      status: recordStatus.active,
      create_date: new Date(),
      create_date_key: ConvertService.dateKey(),
      create_by: MappingService.userObj(user),
      prepaid: null,
      scholarship: null,
      program_academic: programAcademic,
      is_student_PSIS: false,

      campusKey: campusKey,
      campus: campus,
      schoolKey: schoolKey,
      school: school,
    };

    const emailStudent = `${serial_id}@gmail.com`;
    const studentAccount: IStudentAccount = {
      key: studentKey,
      create_date: new Date(),
      create_by: MappingService.userObj(user),
      status: recordStatus.active,
      puc_id: null,
      email: emailStudent,
      fileUrl: null,
      displayName: f.full_name,
      studentKey: studentKey,
      phone: f.mobile_phone,
      token: null,
      pinCode: null,
      student: studentObj(studentData),

      campusKey: campusKey,
      campus: campus,
      schoolKey: schoolKey,
      school: school,
      resetAuth: student ? true : false,
    };

    f.student = MappingService.studentObj(studentData);

    const settingRef = this.ds.settingFireStore();
    const studentAccountRef = this.ds.studentAccountDocFire().doc(studentData.key);
    const studentRef = this.ds.studentFireRef().doc(studentData.key);
    const testingRef = this.ds.testingFireRef().doc(f.key);
    const studentInvoiceRef = this.ds.studentFireRef().doc(studentData.key).collection("invoices");

    const headerKey = this.ds.createId();
    const date = new Date();
    const create_by = MappingService.userObj(user);
    const invoiceNo = null;
    const grandTotal = registrationPrice;

    const invoiceHeader: IInvoice = {
      key: headerKey,
      display_program: f.program.name,
      display_level: "Registration Fee",
      student_register: "New",
      display_shift: null,
      create_date: date,
      create_date_key: ConvertService.toDateKey(date),
      create_by: create_by,
      issue_by: create_by,
      issue_date: date,
      invoice_no: invoiceNo,
      invoice_type: invoiceTypesObj.registrationFee,
      student: MappingService.studentObj(studentData),
      verify_by: create_by,
      verify_date: date,
      verify_date_key: ConvertService.toDateKey(date),
      page_key: ConvertService.pageKey(),
      invoice_date: date,
      invoice_date_key: ConvertService.toDateKey(date),
      course: null,
      isPaid: paymentStatus.unpaid,
      isVoid: false,
      program: programAcademic,
      price: grandTotal,
      amount: grandTotal,
      issue_term: null,
      payment_term: null,
      headerRef: headerKey,
      isHeader: true,
      isEnrollVerify: true,
      schoolSession: null,
      description: null,
      penalty: null,
      prepaid: null,
      scholarship: null,
      scholarshipRef: null,
      prepaidRef: null,
      penaltyRef: null,

      campusGrade: f.campus,

      campusKey: campusKey,
      campus: campus,
      schoolKey: schoolKey,
      school: school,
    }

    const invoiceDetail: IInvoice = {
      key: this.ds.createId(),
      display_program: f.program.name,
      display_level: "Registration Fee",
      student_register: "New",
      display_shift: null,
      create_date: date,
      create_date_key: ConvertService.toDateKey(date),
      create_by: create_by,
      issue_by: create_by,
      issue_date: date,
      invoice_no: invoiceNo,
      invoice_type: invoiceTypesObj.registrationFee,
      student: MappingService.studentObj(studentData),
      verify_by: create_by,
      verify_date: date,
      page_key: ConvertService.pageKey(),
      verify_date_key: ConvertService.toDateKey(date),
      invoice_date: date,
      invoice_date_key: ConvertService.toDateKey(date),
      course: null,
      isPaid: paymentStatus.unpaid,
      isVoid: false,
      program: programAcademic,
      byAdmission: false,
      price: grandTotal,
      amount: grandTotal,
      issue_term: null,
      payment_term: null,
      headerRef: headerKey,
      isHeader: false,
      isEnrollVerify: true,
      schoolSession: null,
      description: null,
      scholarship: null,
      scholarshipRef: null,

      campusGrade: f.campus,
      campusKey: campusKey,
      campus: campus,
      schoolKey: schoolKey,
      school: school,
    }
    batch.set(studentInvoiceRef.doc(invoiceHeader.key), invoiceHeader);
    batch.set(studentInvoiceRef.doc(invoiceDetail.key), invoiceDetail);
    batch.set(testingRef, f);

    batch.set(studentRef, studentData, { merge: true });
    batch.set(studentAccountRef, studentAccount, { merge: true });

    // NEW SERIAL ID
    batch.update(settingRef, { serial_number: firebase.firestore.FieldValue.increment(1) });

    batch.commit().then(() => {
      callback(true, f);
      this.process = false;
    })
      .catch(error => {
        callback(false, error);
        this.process = false;
      });
  }

  // @action
  // register(f: ITesting, user: any, registrationPrice: number, isRetakeTest: boolean, studentDocKey: string, callback) {
  //   this.process = true;
  //   this.as.updateSerialId((success, res) => {
  //     if (success) {
  //       const batch = this.ds.batch();
  //       const studentKey = studentDocKey ? studentDocKey : this.ds.createId();

  //       const serialID = res.serial_number + 1;
  //       const serial_id = 'S' + serialID.toString();
  //       const last_name = f.last_name.trim().toUpperCase();
  //       const first_name = f.first_name.trim().toUpperCase();

  //       const { grade, program, year } = f
  //       const programAcademic: IProgramAcademic = {
  //         key: grade.key,
  //         name: grade.name,
  //         scholarshipKey: program.key,
  //         program: program,
  //         category: year,
  //         admissionKey: null,
  // grade_exam_key: null,
  //       }

  //       f.serial_id = serial_id;
  //       f.first_name = first_name;
  //       f.last_name = last_name;
  //       f.full_name = last_name + ' ' + first_name;
  //       f.key = this.ds.createId();

  //       const studentData: IStudent = {
  //         key: studentKey,
  //         unique_code: f.unique_code,
  //         first_name: first_name,
  //         last_name: last_name,
  //         khmer_first_name: f.khmer_first_name,
  //         khmer_last_name: f.khmer_last_name,
  //         full_name: last_name + ' ' + first_name,
  //         address: f.address,
  //         gender: f.gender,
  //         serial_id: f.serial_id,
  //         puc_id: f.puc_id,
  //         recent_test_key: f.key,
  //         is_puc_student: false,
  //         dob: f.dob,
  //         dobKey: ConvertService.toDateKey(f.dob),
  //         page_key: ConvertService.pageKey(),
  //         mobile_phone: f.mobile_phone,
  //         email: null,
  //         email_address: f.email_address,
  //         pob: null,
  //         marital_status: null,
  //         nationality: null,
  //         home_address: null,
  //         work_place: null,
  //         position: null,
  //         parent_name: null,
  //         spouse_name: null,
  //         emergency_person_name: null,
  //         emergency_relationship: null,
  //         emergency_address: null,
  //         emergency_phone: null,

  //         english_school_name: null,
  //         english_school_province: null,
  //         english_school_year: null,
  //         high_school_name: null,
  //         high_school_province: null,
  //         high_school_year: null,
  //         college_school_name: null,
  //         college_school_degree: null,
  //         college_school_major: null,
  //         college_school_year: null,
  //         university_school_name: null,
  //         university_school_degree: null,
  //         university_school_major: null,
  //         university_school_year: null,
  //         graduate_school_name: null,
  //         graduate_school_degree: null,
  //         graduate_school_major: null,
  //         graduate_school_year: null,
  //         education_type_at_puc: null,
  //         status: recordStatus.active,
  //         create_date: new Date(),
  //         create_date_key: ConvertService.dateKey(),
  //         create_by: MappingService.userObj(user),
  //         prepaid: null,
  //         scholarship: null,
  //         program_academic: programAcademic,
  //         is_student_PSIS: false,
  //       };

  //       f.student = MappingService.studentObj(studentData);
  //       const studentRef = this.ds.studentFireRef().doc(studentData.key);
  //       const testingRef = this.ds.testingFireRef().doc(f.key);
  //       const studentInvoiceRef = this.ds.studentFireRef().doc(studentData.key).collection("invoices");

  //       const headerKey = this.ds.createId();
  //       const date = new Date();
  //       const create_by = MappingService.userObj(user);
  //       const campus = MappingService.campusObj(user.campus);
  //       const invoiceNo = null;
  //       const grandTotal = registrationPrice;

  //       const invoiceHeader: IInvoice = {
  //         key: headerKey,
  //         display_program: f.program.name,
  //         display_level: "Registration Fee",
  //         student_register: "New",
  //         display_shift: null,
  //         create_date: date,
  //         create_date_key: ConvertService.toDateKey(date),
  //         create_by: create_by,
  //         issue_by: create_by,
  //         issue_date: date,
  //         invoice_no: invoiceNo,
  //         invoice_type: invoiceTypesObj.registrationFee,
  //         student: MappingService.studentObj(studentData),
  //         verify_by: create_by,
  //         verify_date: date,
  //         verify_date_key: ConvertService.toDateKey(date),
  //         page_key: ConvertService.pageKey(),
  //         invoice_date: date,
  //         invoice_date_key: ConvertService.toDateKey(date),
  //         course: null,
  //         isPaid: paymentStatus.unpaid,
  //         isVoid: false,
  //         program: programAcademic,
  //         price: grandTotal,
  //         amount: grandTotal,
  //         issue_term: null,
  //         payment_term: null,
  //         headerRef: headerKey,
  //         isHeader: true,
  //         isEnrollVerify: true,
  //         campus: campus,
  //         schoolSession: null,
  //         description: null,
  //         penalty: null,
  //         prepaid: null,
  //         scholarship: null,
  //         scholarshipRef: null,
  //         prepaidRef: null,
  //         penaltyRef: null,

  //         campusGrade: f.campus,
  //       }

  //       const invoiceDetail: IInvoice = {
  //         key: this.ds.createId(),
  //         display_program: f.program.name,
  //         display_level: "Registration Fee",
  //         student_register: "New",
  //         display_shift: null,
  //         create_date: date,
  //         create_date_key: ConvertService.toDateKey(date),
  //         create_by: create_by,
  //         issue_by: create_by,
  //         issue_date: date,
  //         invoice_no: invoiceNo,
  //         invoice_type: invoiceTypesObj.registrationFee,
  //         student: MappingService.studentObj(studentData),
  //         verify_by: create_by,
  //         verify_date: date,
  //         page_key: ConvertService.pageKey(),
  //         verify_date_key: ConvertService.toDateKey(date),
  //         invoice_date: date,
  //         invoice_date_key: ConvertService.toDateKey(date),
  //         course: null,
  //         isPaid: paymentStatus.unpaid,
  //         isVoid: false,
  //         program: programAcademic,
  //         byAdmission: false,
  //         price: grandTotal,
  //         amount: grandTotal,
  //         issue_term: null,
  //         payment_term: null,
  //         headerRef: headerKey,
  //         isHeader: false,
  //         isEnrollVerify: true,
  //         campus: campus,
  //         schoolSession: null,
  //         description: null,
  //         scholarship: null,
  //         scholarshipRef: null,

  //         campusGrade: f.campus,
  //       }
  //       batch.set(studentInvoiceRef.doc(invoiceHeader.key), invoiceHeader);
  //       batch.set(studentInvoiceRef.doc(invoiceDetail.key), invoiceDetail);
  //       batch.set(testingRef, f);

  //       if (isRetakeTest) {
  //         batch.update(studentRef, {
  //           serial_id: f.serial_id,
  //           first_name: f.first_name,
  //           last_name: f.last_name,
  //           full_name: f.last_name + ' ' + f.first_name,
  //           khmer_first_name: f.khmer_first_name,
  //           khmer_last_name: f.khmer_last_name,
  //           gender: f.gender,
  //           dob: f.dob,
  //           dobKey: f.dobKey,
  //           mobile_phone: f.mobile_phone,
  //         });
  //       } else {
  //         batch.set(studentRef, studentData);
  //       }

  //       this.process = false;
  //       batch.commit().then(() => {
  //         callback(true, f);
  //         this.process = false;
  //       })
  //         .catch(error => {
  //           callback(false, error);
  //           this.process = false;
  //         });
  //     }
  //   })
  // }

  @action
  async addSchoolFee(selectedCampus, selectedSchool, invoiceKey: string, form: any, currentTerm, paymentList: Array<any>, academicYear: any, student: any, admission: any, user: any, optionData, oldInvoice: any, alignPayment: any, callback) {

    this.process = true;
    const batch = this.ds.batch();
    const { fromDate, toDate, paymentOption } = form;

    const endProgramTerm = ConvertService.getPaymentTerm(currentTerm, paymentOption)
    const { program_academic, trainingGrade, trainingProgram, shift } = admission;
    const schoolRef = this.ds.schoolFireRef();
    const installmentPaymentRef = this.ds.firestore().collection("student_align_payment");

    let invoiceData = [];
    let paymentHeader = null;
    if (oldInvoice) {
      const invoiceDoc = await this.ds.studentDocument(student.key).collection("invoices", ref => ref
        .where("headerRef", "==", oldInvoice.headerRef)
        .where("isPaid.key", "==", 2)
      ).get().toPromise();
      invoiceData = MappingService.pushToArray(invoiceDoc);
      invoiceData = invoiceData.filter(m => !m.is_student_installment)

      paymentHeader = invoiceData && invoiceData.length > 0 ? invoiceData.filter(m => m.isHeader)[0] : null


      const installmentPaymentDoc = await this.ds.dbRef().collection("student_align_payment", ref => ref
        .where("headerRef", "==", oldInvoice.headerRef)
        .where("isPaid", "==", false)
      ).get().toPromise();
      const installmentPaymentData = MappingService.pushToArray(installmentPaymentDoc);

      if (paymentHeader && paymentHeader.discount_voucher_detail_key) {
        batch.update(schoolRef.doc(paymentHeader.schoolKey).collection("payment_discount_voucher_detail").doc(paymentHeader.discount_voucher_detail_key), {
          invoiceKey: null,
          headerRef: null,
        })
      }

      if (installmentPaymentData && installmentPaymentData.length > 0) {

        installmentPaymentData.forEach(m => {
          batch.delete(installmentPaymentRef.doc(m.key))
        })
      }
    }

    const programAcademic = program_academic;
    const studentRef = this.ds.studentFireRef().doc(student.key);
    const alignPaymentRef = this.ds.studentAlignPaymentFireRef();

    const date = new Date();
    const create_by = userObj(user);
    const campus = campusObj(user.campus);

    const headerKey = alignPayment ? alignPayment.key : invoiceKey || this.ds.createId();
    const invoiceNo = null;

    const enrollmentType = enrollmentTypes.PSIS;
    const displayProgram = trainingProgram.name;
    const displayLevel = trainingGrade.grade.name;
    const displayShift = shift ? shift.name : "Morning";
    const schoolFeeType = null;
    const grandTotal = MappingService.sum(paymentList, "amount");
    const { tuition_fee } = optionData;

    const tuitionData = paymentList.filter(m => m.fee.key === tuition_fee.key);
    let description = null;
    if (tuitionData && tuitionData.length > 0) {
      description = tuitionData[0].paymentOption.name
    }

    const invoiceHeader: IInvoice = {
      endProgramTerm: endProgramTerm,
      endProgramYear: academicYear.academic_year,
      key: headerKey,
      isAlignPayment: alignPayment.isOneTimePayment || alignPayment.remainingAmount === 0 ? false : true,
      installmentOldRef: alignPayment.installmentOldRef || null,
      description: description,
      display_program: displayProgram,
      display_level: displayLevel,
      student_register: oldInvoice && oldInvoice.student_register ? oldInvoice.student_register : "Old",
      invoice_no: invoiceNo,
      invoice_type: invoiceTypesObj.tuitionFee,
      fee_category: tuition_fee,

      student: studentObj(student),
      studentKey: student.key,
      studentRef: this.ds.studentDocument(student.key).ref,
      isPaid: paymentStatus.unpaid,
      program: programAcademic,
      enrollment_type: enrollmentType,

      invoice_date: date,
      invoice_date_key: ConvertService.dateKey(),
      course: null,
      isVoid: false,
      byAdmission: false,

      issue_year: academicYearObj(academicYear),
      issue_yearKey: academicYear.key,
      issue_yearRef: this.ds.academicYearRef().doc(academicYear.key).ref,
      payment_year: null,
      headerRef: headerKey,
      isHeader: true,
      price: grandTotal,
      amount: grandTotal,
      isEnrollVerify: true,

      display_shift: displayShift,
      shiftKey: null,
      create_date: date,
      create_date_key: ConvertService.dateKey(),
      create_by: create_by,
      page_key: ConvertService.pageKey(),
      school_fee_type: schoolFeeType,

      schoolSession: null,
      penaltyRef: null,
      penalty: null,
      scholarshipRef: null,
      scholarship: null,
      loan: null,
      prepaidRef: null,
      prepaid: null,

      fromDate: fromDate,
      fromDateKey: ConvertService.toDateKey(fromDate),
      toDate: toDate,
      toDateKey: ConvertService.toDateKey(toDate),

      campusGrade: admission.campus,

      campusKey: selectedCampus.key,
      campus: selectedCampus,
      schoolKey: selectedSchool.key,
      school: selectedSchool,

      installmentPaymentRef: {
        key: alignPayment.key,
        amount: alignPayment.amount,
        amount_request: alignPayment.amount_request,
        isOneTimePayment: alignPayment.isOneTimePayment,
        oldAmount: alignPayment.oldAmount,
        note: alignPayment.note,
        remainingAmount: alignPayment.remainingAmount,
        pay_date: alignPayment.pay_date,
        pay_date_key: alignPayment.pay_date_key,
        isHeader: alignPayment.isHeader,
      }
    }

    paymentList.forEach(m => {

      const invoiceType = m.fee.key === tuition_fee.key ? invoiceTypesObj.tuitionFee : invoiceTypesObj.otherFee;
      const course = m.fee.key === tuition_fee.key ? trainingGrade : m.fee;

      const invoiceDetail: IInvoice = {
        endProgramTerm: invoiceType.key === invoiceTypesObj.tuitionFee.key ? endProgramTerm : null,
        endProgramYear: invoiceType.key === invoiceTypesObj.tuitionFee.key ? academicYear.academic_year : null,
        key: this.ds.createId(),
        description: course.name,
        display_program: displayProgram,
        display_level: displayLevel,
        student_register: oldInvoice && oldInvoice.student_register ? oldInvoice.student_register : "Old",
        invoice_no: invoiceNo,
        invoice_type: invoiceType,
        fee_category: m.fee,
        paymentOption: m.paymentOption,
        program_term: academicYear.program_term || null,

        student: studentObj(student),
        studentKey: student.key,
        studentRef: this.ds.studentDocument(student.key).ref,
        isPaid: paymentStatus.unpaid,
        program: programAcademic,
        enrollment_type: enrollmentType,

        invoice_date: date,
        invoice_date_key: ConvertService.dateKey(),
        course: academicTrainingFeeObj(course),
        isVoid: false,
        byAdmission: false,

        issue_year: academicYearObj(academicYear),
        issue_yearKey: academicYear.key,
        issue_yearRef: this.ds.academicYearRef().doc(academicYear.key).ref,
        payment_year: null,
        headerRef: headerKey,
        isHeader: false,
        price: m.amount,
        amount: m.amount,
        isEnrollVerify: true,
        isFreshmen: oldInvoice && oldInvoice.isFreshmen ? oldInvoice.isFreshmen : false,

        display_shift: displayShift,
        shiftKey: null,
        create_date: date,
        create_date_key: ConvertService.dateKey(),
        create_by: create_by,
        page_key: ConvertService.pageKey(),
        school_fee_type: schoolFeeType,
        schoolSession: null,
        penaltyRef: null,
        penalty: null,
        scholarshipRef: null,
        scholarship: null,
        loan: null,
        prepaidRef: null,
        prepaid: null,

        campusGrade: admission.campus,
        trainingLevelKey: trainingGrade.key,
        trainingLevelRef: this.ds.campusRef().doc(admission.campus.key).collection("training_levels").doc(trainingGrade.key).ref,
        trainingProgramKey: trainingProgram.key,
        trainingProgramRef: this.ds.campusRef().doc(admission.campus.key).collection("training_programs").doc(trainingProgram.key).ref,

        fee: trainingFeeObj(m),
        feeKey: m.key,
        feeRef: this.ds.campusRef().doc(admission.campus.key).collection("training_levels").doc(trainingGrade.key).collection("training_school_fee").doc(m.key).ref,

        fromDate: fromDate,
        fromDateKey: ConvertService.toDateKey(fromDate),
        toDate: toDate,
        toDateKey: ConvertService.toDateKey(toDate),

        campusKey: selectedCampus.key,
        campus: selectedCampus,
        schoolKey: selectedSchool.key,
        school: selectedSchool,
      }

      batch.set(studentRef.collection("invoices").doc(invoiceDetail.key), invoiceDetail);
    })

    batch.set(studentRef.collection("invoices").doc(invoiceHeader.key), invoiceHeader);

    if (invoiceData && invoiceData.length > 0) {
      invoiceData.forEach(m => {
        batch.delete(studentRef.collection("invoices").doc(m.key));
      })
    }

    if (alignPayment && alignPayment.isOneTimePayment === false && alignPayment.remainingAmount > 0) {
      batch.set(alignPaymentRef.doc(alignPayment.key), alignPayment)
    }

    batch.commit().then(() => {
      this.process = false;
      callback(true, null);
    }).catch(error => {
      this.process = false;
      callback(false, error);
    })
  }

  @action
  async addSchoolFeeCrossYear(selectedCampus, selectedSchool, invoiceKey: string, form: any, currentTerm, paymentList: Array<any>, paymentListTo: Array<any>, academicYear: any, student: any, admission: any, user: any, optionData, oldInvoice: any, alignPayment: any, callback) {

    this.process = true;
    const batch = this.ds.batch();
    const { fromDate, toDate, to_program_term, free_paymentOption, to_periodFee } = form;
    let endProgramTerm = ConvertService.getPaymentTerm(to_program_term, to_periodFee)

    if (free_paymentOption) {
      const { period } = free_paymentOption
      if (period === 6) {
        endProgramTerm = PROGRAM_TERMS_OBJ[`term${endProgramTerm.key + 2}`] || endProgramTerm;
      } else {
        endProgramTerm = PROGRAM_TERMS_OBJ[`term${endProgramTerm.key + 1}`] || endProgramTerm;
      }

    }

    const { program_academic, trainingGrade, trainingProgram, shift } = admission;
    const schoolRef = this.ds.schoolFireRef();

    let invoiceData = [];
    let paymentHeader = null;
    if (oldInvoice) {
      const invoiceDoc = await this.ds.studentDocument(student.key).collection("invoices", ref => ref
        .where("headerRef", "==", oldInvoice.headerRef)
        .where("isPaid.key", "==", 2)
      ).get().toPromise();
      invoiceData = MappingService.pushToArray(invoiceDoc);
      invoiceData = invoiceData.filter(m => !m.is_student_installment)

      paymentHeader = invoiceData && invoiceData.length > 0 ? invoiceData.filter(m => m.isHeader)[0] : null

      if (paymentHeader && paymentHeader.discount_voucher_detail_key) {
        batch.update(schoolRef.doc(paymentHeader.schoolKey).collection("payment_discount_voucher_detail").doc(paymentHeader.discount_voucher_detail_key), {
          invoiceKey: null,
          headerRef: null,
        })
      }
    }

    const programAcademic = program_academic;
    const studentRef = this.ds.studentFireRef().doc(student.key);
    const alignPaymentRef = this.ds.studentAlignPaymentFireRef();

    const date = new Date();
    const create_by = userObj(user);

    const headerKey = alignPayment ? alignPayment.key : invoiceKey || this.ds.createId();
    const invoiceNo = null;

    const enrollmentType = enrollmentTypes.PSIS;
    const displayProgram = trainingProgram.name;
    const displayLevel = trainingGrade.grade.name;
    const displayShift = shift ? shift.name : "Morning";
    const schoolFeeType = null;
    const grandTotal = MappingService.sum(paymentList, "amount") + MappingService.sum(paymentListTo, "amount");
    const { tuition_fee } = optionData;

    const tuitionData = paymentList.filter(m => m.fee.key === tuition_fee.key);

    const paymentListTuitionFee = paymentListTo.find(m => m.fee.key === tuition_fee.key)
    paymentListTo = paymentListTo.filter(m => m.fee.key !== tuition_fee.key)

    let description = null;
    if (tuitionData && tuitionData.length > 0) {
      description = tuitionData[0].paymentOption.name
    }

    const invoiceHeader: IInvoice = {
      endProgramTerm: endProgramTerm,
      endProgramYear: academicYear.academic_year,
      key: headerKey,
      isAlignPayment: alignPayment ? true : false,
      description: description,
      display_program: displayProgram,
      display_level: displayLevel,
      student_register: oldInvoice && oldInvoice.student_register ? oldInvoice.student_register : "Old",
      invoice_no: invoiceNo,
      invoice_type: invoiceTypesObj.tuitionFee,
      fee_category: tuition_fee,

      student: studentObj(student),
      studentKey: student.key,
      studentRef: this.ds.studentDocument(student.key).ref,
      isPaid: paymentStatus.unpaid,
      program: programAcademic,
      enrollment_type: enrollmentType,

      invoice_date: date,
      invoice_date_key: ConvertService.dateKey(),
      course: null,
      isVoid: false,
      byAdmission: false,

      issue_year: academicYearObj(academicYear),
      issue_yearKey: academicYear.key,
      issue_yearRef: this.ds.academicYearRef().doc(academicYear.key).ref,
      payment_year: null,
      headerRef: headerKey,
      isHeader: true,
      price: grandTotal,
      amount: grandTotal,
      isEnrollVerify: true,

      display_shift: displayShift,
      shiftKey: null,
      create_date: date,
      create_date_key: ConvertService.dateKey(),
      create_by: create_by,
      page_key: ConvertService.pageKey(),
      school_fee_type: schoolFeeType,

      schoolSession: null,
      penaltyRef: null,
      penalty: null,
      scholarshipRef: null,
      scholarship: null,
      loan: null,
      prepaidRef: null,
      prepaid: null,

      fromDate: fromDate,
      fromDateKey: ConvertService.toDateKey(fromDate),
      toDate: toDate,
      toDateKey: ConvertService.toDateKey(toDate),

      campusGrade: admission.campus,

      campusKey: selectedCampus.key,
      campus: selectedCampus,
      schoolKey: selectedSchool.key,
      school: selectedSchool,
    }

    paymentList.forEach(m => {

      const invoiceType = m.fee.key === tuition_fee.key ? invoiceTypesObj.tuitionFee : invoiceTypesObj.otherFee;
      const course = m.fee.key === tuition_fee.key ? trainingGrade : m.fee;

      let amount = m.amount
      if (invoiceType.key === 2) {
        amount += paymentListTuitionFee.amount
      }

      const invoiceDetail: IInvoice = {
        endProgramTerm: invoiceType.key === invoiceTypesObj.tuitionFee.key ? endProgramTerm : null,
        endProgramYear: invoiceType.key === invoiceTypesObj.tuitionFee.key ? academicYear.academic_year : null,
        key: this.ds.createId(),
        description: course.name,
        display_program: displayProgram,
        display_level: displayLevel,
        student_register: oldInvoice && oldInvoice.student_register ? oldInvoice.student_register : "Old",
        invoice_no: invoiceNo,
        invoice_type: invoiceType,
        fee_category: m.fee,
        paymentOption: m.paymentOption,
        program_term: academicYear.program_term || null,

        student: studentObj(student),
        studentKey: student.key,
        studentRef: this.ds.studentDocument(student.key).ref,
        isPaid: paymentStatus.unpaid,
        program: programAcademic,
        enrollment_type: enrollmentType,

        invoice_date: date,
        invoice_date_key: ConvertService.dateKey(),
        course: academicTrainingFeeObj(course),
        isVoid: false,
        byAdmission: false,

        issue_year: academicYearObj(academicYear),
        issue_yearKey: academicYear.key,
        issue_yearRef: this.ds.academicYearRef().doc(academicYear.key).ref,
        payment_year: null,
        headerRef: headerKey,
        isHeader: false,
        price: amount,
        amount: amount,
        isEnrollVerify: true,
        isFreshmen: oldInvoice && oldInvoice.isFreshmen ? oldInvoice.isFreshmen : false,

        display_shift: displayShift,
        shiftKey: null,
        create_date: date,
        create_date_key: ConvertService.dateKey(),
        create_by: create_by,
        page_key: ConvertService.pageKey(),
        school_fee_type: schoolFeeType,
        schoolSession: null,
        penaltyRef: null,
        penalty: null,
        scholarshipRef: null,
        scholarship: null,
        loan: null,
        prepaidRef: null,
        prepaid: null,

        campusGrade: admission.campus,
        trainingLevelKey: trainingGrade.key,
        trainingLevelRef: this.ds.campusRef().doc(admission.campus.key).collection("training_levels").doc(trainingGrade.key).ref,
        trainingProgramKey: trainingProgram.key,
        trainingProgramRef: this.ds.campusRef().doc(admission.campus.key).collection("training_programs").doc(trainingProgram.key).ref,

        fee: trainingFeeObj(m),
        feeKey: m.key,
        feeRef: this.ds.campusRef().doc(admission.campus.key).collection("training_levels").doc(trainingGrade.key).collection("training_school_fee").doc(m.key).ref,

        fromDate: fromDate,
        fromDateKey: ConvertService.toDateKey(fromDate),
        toDate: toDate,
        toDateKey: ConvertService.toDateKey(toDate),

        campusKey: selectedCampus.key,
        campus: selectedCampus,
        schoolKey: selectedSchool.key,
        school: selectedSchool,
      }

      batch.set(studentRef.collection("invoices").doc(invoiceDetail.key), invoiceDetail);
    })

    paymentListTo.forEach(m => {

      const invoiceType = m.fee.key === tuition_fee.key ? invoiceTypesObj.tuitionFee : invoiceTypesObj.otherFee;
      const course = m.fee.key === tuition_fee.key ? trainingGrade : m.fee;

      const invoiceDetail: IInvoice = {
        endProgramTerm: invoiceType.key === invoiceTypesObj.tuitionFee.key ? endProgramTerm : null,
        endProgramYear: invoiceType.key === invoiceTypesObj.tuitionFee.key ? academicYear.academic_year : null,
        key: this.ds.createId(),
        description: course.name,
        display_program: displayProgram,
        display_level: displayLevel,
        student_register: oldInvoice && oldInvoice.student_register ? oldInvoice.student_register : "Old",
        invoice_no: invoiceNo,
        invoice_type: invoiceType,
        fee_category: m.fee,
        paymentOption: m.paymentOption,
        program_term: academicYear.program_term || null,

        student: studentObj(student),
        studentKey: student.key,
        studentRef: this.ds.studentDocument(student.key).ref,
        isPaid: paymentStatus.unpaid,
        program: programAcademic,
        enrollment_type: enrollmentType,

        invoice_date: date,
        invoice_date_key: ConvertService.dateKey(),
        course: academicTrainingFeeObj(course),
        isVoid: false,
        byAdmission: false,

        issue_year: academicYearObj(academicYear),
        issue_yearKey: academicYear.key,
        issue_yearRef: this.ds.academicYearRef().doc(academicYear.key).ref,
        payment_year: null,
        headerRef: headerKey,
        isHeader: false,
        price: m.amount,
        amount: m.amount,
        isEnrollVerify: true,
        isFreshmen: oldInvoice && oldInvoice.isFreshmen ? oldInvoice.isFreshmen : false,

        display_shift: displayShift,
        shiftKey: null,
        create_date: date,
        create_date_key: ConvertService.dateKey(),
        create_by: create_by,
        page_key: ConvertService.pageKey(),
        school_fee_type: schoolFeeType,
        schoolSession: null,
        penaltyRef: null,
        penalty: null,
        scholarshipRef: null,
        scholarship: null,
        loan: null,
        prepaidRef: null,
        prepaid: null,

        campusGrade: admission.campus,
        trainingLevelKey: trainingGrade.key,
        trainingLevelRef: this.ds.campusRef().doc(admission.campus.key).collection("training_levels").doc(trainingGrade.key).ref,
        trainingProgramKey: trainingProgram.key,
        trainingProgramRef: this.ds.campusRef().doc(admission.campus.key).collection("training_programs").doc(trainingProgram.key).ref,

        fee: trainingFeeObj(m),
        feeKey: m.key,
        feeRef: this.ds.campusRef().doc(admission.campus.key).collection("training_levels").doc(trainingGrade.key).collection("training_school_fee").doc(m.key).ref,

        fromDate: fromDate,
        fromDateKey: ConvertService.toDateKey(fromDate),
        toDate: toDate,
        toDateKey: ConvertService.toDateKey(toDate),

        campusKey: selectedCampus.key,
        campus: selectedCampus,
        schoolKey: selectedSchool.key,
        school: selectedSchool,
      }

      batch.set(studentRef.collection("invoices").doc(invoiceDetail.key), invoiceDetail);
    })

    batch.set(studentRef.collection("invoices").doc(invoiceHeader.key), invoiceHeader);

    if (invoiceData && invoiceData.length > 0) {
      invoiceData.forEach(m => {
        batch.delete(studentRef.collection("invoices").doc(m.key));
      })
    }

    if (alignPayment) {
      batch.set(alignPaymentRef.doc(alignPayment.key), alignPayment)
    }

    batch.commit().then(() => {
      this.process = false;
      callback(true, null);
    }).catch(error => {
      this.process = false;
      callback(false, error);
    })
  }

  @action
  async deleteAlignPayment(item, callback) {
    const { student, key } = item;
    const studentRef = this.ds.studentFireRef().doc(student.key);
    const alignPaymentRef = this.ds.studentAlignPaymentFireRef().doc(key);

    const batch = this.ds.batch();
    const invoiceDocs = await this.ds.studentDocument(student.key).collection("invoices", ref => ref
      .where("headerRef", "==", key)
      .where("isPaid.key", "==", 2)
    ).get().toPromise();
    const invoiceData = MappingService.pushToArray(invoiceDocs);

    if (invoiceData && invoiceData.length > 0) {
      invoiceData.map((m) => {
        batch.delete(studentRef.collection("invoices").doc(m.key))
      })
    }

    batch.delete(alignPaymentRef)

    batch.commit().then(() => {
      this.process = false;
      callback(true, null);
    }).catch(error => {
      this.process = false;
      callback(false, error);
    })
  }

  @action
  async addPOSFee(selectedCampus, selectedSchool, invoiceKey: string, selectedProduct: any, academicYear: any, student: any, admission: any, user: any, paymentHeader: any, callback) {

    this.process = true;
    const batch = this.ds.batch();
    const { trainingGrade, trainingProgram, shift } = admission;
    const programAcademic = admission.program_academic ? admission.program_academic : null;
    const studentRef = this.ds.studentFireRef().doc(student.key);

    const date = new Date();
    const create_by = userObj(user);
    const campus = campusObj(user.campus);

    const headerKey = paymentHeader ? paymentHeader.key : invoiceKey || this.ds.createId();
    const invoiceNo = null;

    const enrollmentType = enrollmentTypes.PSIS;
    const displayProgram = trainingProgram.name;
    const displayLevel = trainingGrade.grade.name;
    const displayShift = shift ? shift.name : "Morning";
    const schoolFeeType = null;

    const grandTotal = selectedProduct.grandTotal;
    let description = null;

    if (!paymentHeader) {
      const invoiceHeader: IInvoice = {
        key: headerKey,
        description: description,
        display_program: displayProgram,
        display_level: displayLevel,
        invoice_no: invoiceNo,
        invoice_type: invoiceTypesObj.posFee,
        fee_category: null,

        student: studentObj(student),
        studentKey: student.key,
        studentRef: this.ds.studentDocument(student.key).ref,
        isPaid: paymentStatus.unpaid,
        program: programAcademic,
        enrollment_type: enrollmentType,

        invoice_date: date,
        invoice_date_key: ConvertService.dateKey(),
        course: null,
        isVoid: false,
        byAdmission: true,
        issue_year: academicYear,
        issue_yearKey: academicYear.key,
        issue_yearRef: this.ds.academicYearRef().doc(academicYear.key).ref,
        payment_year: null,
        headerRef: headerKey,
        isHeader: true,
        price: grandTotal,
        amount: grandTotal,
        isEnrollVerify: true,

        display_shift: displayShift,
        shiftKey: null,
        create_date: date,
        create_date_key: ConvertService.dateKey(),
        create_by: create_by,
        page_key: ConvertService.pageKey(),
        school_fee_type: schoolFeeType,

        schoolSession: null,
        penaltyRef: null,
        penalty: null,
        scholarshipRef: null,
        scholarship: null,
        loan: null,
        prepaidRef: null,
        prepaid: null,

        fromDate: null,
        fromDateKey: null,
        toDate: null,
        toDateKey: null,
        campusGrade: admission.campus,

        campusKey: selectedCampus.key,
        campus: selectedCampus,
        schoolKey: selectedSchool.key,
        school: selectedSchool,

      }
      batch.set(studentRef.collection("invoices").doc(invoiceHeader.key), invoiceHeader);
    } else {
      batch.update(studentRef.collection("invoices").doc(headerKey), {
        price: paymentHeader.price + grandTotal,
        amount: paymentHeader.amount + grandTotal,
      });
    }

    const invoiceType = invoiceTypesObj.posFee;
    const course = selectedProduct

    const invoiceDetail: IInvoice = {
      key: this.ds.createId(),
      description: course.name,
      display_program: displayProgram,
      display_level: displayLevel,
      invoice_no: invoiceNo,
      invoice_type: invoiceType,
      fee_category: null,
      paymentOption: null,

      student: studentObj(student),
      studentKey: student.key,
      studentRef: this.ds.studentDocument(student.key).ref,
      isPaid: paymentStatus.unpaid,
      program: programAcademic,
      enrollment_type: enrollmentType,

      invoice_date: date,
      invoice_date_key: ConvertService.dateKey(),
      course: course,
      isVoid: false,
      byAdmission: true,
      issue_year: academicYear,
      issue_yearKey: academicYear.key,
      issue_yearRef: this.ds.academicYearRef().doc(academicYear.key).ref,
      payment_year: null,
      headerRef: headerKey,
      isHeader: false,
      price: grandTotal,
      amount: grandTotal,
      isEnrollVerify: true,
      isFreshmen: false,

      display_shift: displayShift,
      shiftKey: null,
      create_date: date,
      create_date_key: ConvertService.dateKey(),
      create_by: create_by,
      page_key: ConvertService.pageKey(),
      school_fee_type: schoolFeeType,
      schoolSession: null,
      penaltyRef: null,
      penalty: null,
      scholarshipRef: null,
      scholarship: null,
      loan: null,
      prepaidRef: null,
      prepaid: null,

      campusGrade: admission.campus,
      trainingLevelKey: trainingGrade.key,
      trainingLevelRef: this.ds.campusRef().doc(admission.campus.key).collection("training_levels").doc(trainingGrade.key).ref,
      trainingProgramKey: trainingProgram.key,
      trainingProgramRef: this.ds.campusRef().doc(admission.campus.key).collection("training_programs").doc(trainingProgram.key).ref,

      fee: null,
      feeKey: null,
      feeRef: null,

      fromDate: null,
      fromDateKey: null,
      toDate: null,
      toDateKey: null,

      is_cut_stock: false,

      campusKey: selectedCampus.key,
      campus: selectedCampus,
      schoolKey: selectedSchool.key,
      school: selectedSchool,
    }

    batch.set(studentRef.collection("invoices").doc(invoiceDetail.key), invoiceDetail);
    batch.commit().then(() => {
      this.process = false;
      callback(true, null);
    }).catch(error => {
      this.process = false;
      callback(false, error);
    })
  }

}

