
import { observable, action, } from 'mobx';
import { Injectable } from '@angular/core';
import { DataService } from '../services/data.service';

@Injectable({ providedIn: 'root' })
export class AutoCompleteStore {
    @observable data: Array<any> = [];
    @observable filter: Array<any> = [];
    @observable collectionData: Array<any> = [];
    @observable singleData: any;
    @observable process: boolean = false;
    @observable deleteProcess: boolean = false;
    @observable loading: boolean = true;
    @observable empty: boolean = false;
    constructor(private ds: DataService) { }

    @action
    fetchAutoData(collection, field: string, callback) {
        this.ds.autoCollectionRef(collection, field).valueChanges().subscribe(docs => {
            callback(docs)
        })
    }

    @action
    async fetchAutoSearchData(collectionName: string, field: string, search: any, callback) {
        return this.ds.autoFilterCollectionRef(collectionName, field, search).valueChanges().subscribe(docs => {
            callback(docs)
        });
    }

    @action
    fetchAutoDataSchool(schoolKey, collection, field: string, callback) {
        this.ds.autoCollectionSchoolRef(schoolKey, collection, field).valueChanges().subscribe(docs => {
            callback(docs)
        })
    }

    @action
    async fetchAutoSearchDataSchool(schoolKey, collection: string, field: string, search: any, callback) {
        return this.ds.autoFilterCollectionSchoolRef(schoolKey, collection, field, search).valueChanges().subscribe(docs => {
            callback(docs)
        });
    }

    @action
    async fetchAutoSearchContentDataSchool(schoolKey, collection: string, field: string, search: any, callback) {
        return this.ds.autoFilterContentCollectionSchoolRef(schoolKey, collection, field, search).valueChanges().subscribe(docs => {
            callback(docs)
        });
    }

}
