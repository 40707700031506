import { Component, OnInit, Input } from "@angular/core";

import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup, AbstractControl, FormBuilder } from "@angular/forms";
import { Environment } from "src/app/stores/environment.store";
import { Testing } from "src/app/stores/testing.store";
import { MatDialog } from "@angular/material";
import { tabs } from "src/app/dummy/tabs";
import { AddNewTestingFormComponent } from "./add-new-testing-form/add-new-testing-form.component";
import { AddTestingPaymentComponent } from "src/app/components/add-testing-payment/add-testing-payment.component";
import { PrintService } from "src/app/services/print.service";
import { Payment } from "src/app/stores/payment.store";
import { User } from "src/app/stores/user.store";

@Component({
  selector: 'app-testing',
  templateUrl: './testing.component.html',
  styleUrls: ['./testing.component.scss']
})
export class TestingComponent implements OnInit {
  form: FormGroup;
  search: AbstractControl;
  key: string;
  name: string;
  status: string;
  type: string;
  tabs: any = [];

  constructor(
    public store: Testing,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private fb: FormBuilder,
    private ps: PrintService,
    public env: Environment,
    public payment: Payment,
    private user: User,

  ) { }

  buildForm(): void {
    this.form = this.fb.group({
      search: [null]
    });
    this.search = this.form.controls["search"];
  }

  ngOnInit() {
    this.buildForm();
    this.route.params.forEach(params => {
      this.type = params["id"];
      this.tabs = tabs.testing.map(m => {
        return ({ path: m.path + '/' + m.key, key: m.key, label: m.label })
      })


      this.env.getUser(user => {
        if (user && user.shift)
          this.store.fetchData(this.env.user, this.type, "");
      })

      // this.store.fetchStudent()

    });
  }

  updateInvoice(){
    // this.store.updateInvoice()
  }

  _onScroll() {
    this.store.onScroll("")
  }

  _onSearch(f: any) {
    if (this.form.valid) {
      this.store.fetchData(this.env.user, this.type, f.search);
    }
  }

  create() {
    let dialogRef = this.dialog.open(AddNewTestingFormComponent, {
      data: null,
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: true,
      role: "dialog"
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => { });
  }

  print() {
    this.ps.print("#printD", "a5l");
  }

  _onReceivePayment(item) {
    if (item.isPaidTest || !this.env.user.shift || !this.env.sameDay) return;
    // if (item.isPaidTest || !this.env.user.shift) return;
    let dialogRef = this.dialog.open(AddTestingPaymentComponent, {
      data: item,
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: true,
      role: "dialog"
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => {
      if (result === "print") {
        this.print();
      }
    });
  }

  // create() {
  //   if (!this.institute.config) {
  //     let dialogRef = this.dialog.open(ModalWarningComponent, {
  //       data: { title: 'Term', subtitle: 'Term was closed. Start a new term for getting start register testing.' },
  //       panelClass: 'register-test-overlay-panel',
  //       width: '35vw',
  //       disableClose: false,
  //       role: 'dialog',
  //     });
  //     return;
  //   }
  //   this.router.navigate([`/app/institute/${this.key}/${this.name}/${this.status}/register`])
  // }

  // update(item) {
  //   if (!this.institute.config) {
  //     let dialogRef = this.dialog.open(ModalWarningComponent, {
  //       data: { title: 'Term', subtitle: 'Term was closed. Start a new term for getting start register testing.' },
  //       panelClass: 'register-test-overlay-panel',
  //       width: '35vw',
  //       disableClose: false,
  //       role: 'dialog',
  //     });
  //     return;
  //   }
  //   this.router.navigate([`/app/institute/${this.key}/${this.name}/${this.status}/edit/${item.key}`])
  // }

}
