import { ConvertService } from 'src/app/services/convert.service';
import { Observable } from 'rxjs/Observable';
import { AbstractControl } from '@angular/forms';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { map, startWith } from 'rxjs/operators';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/switchMap';
import { firestore } from 'firebase';
import * as moment from 'moment';
import { academicProgramObj } from '../dummy/status';

@Injectable({
  providedIn: 'root'
})
export class MappingService {

  constructor() { }

  static getForeignerFee(student, setting, fee) {
    const { nationality } = student;
    const { foreigner_student } = setting;
    if (!nationality) return ConvertService.toNumber(fee.price);
    if (nationality.key === foreigner_student.key) return ConvertService.toNumber(fee.foreigner)
    return ConvertService.toNumber(fee.price)
  }

  static getFeeRef(settings, courseCode, currentFee, paymentType, scheduleType) {
    const { academic_fee, uat_fee, academic_weekend_fee } = settings;
    if (scheduleType.key === academicProgramObj.bachelor.key || scheduleType.key === academicProgramObj.associate.key) {
      let feeBa = paymentType.key === 1 ? academic_fee : academic_weekend_fee;
      const validUat = uatCourses.filter(m => m.trim().toLowerCase() === courseCode.trim().toLowerCase());
      if (validUat.length > 0) {
        feeBa = uat_fee
      }
      return feeBa;
    }
    return currentFee;
  }


  static fromDateFilter(key) {
    switch (key) {
      case "today":
        return moment().startOf('day').toDate();
      case "yesterday":
        return moment().subtract(1, 'day').startOf('day').toDate();
      case "this_week":
        return moment().startOf('week').toDate();
      case "this_month":
        return moment().startOf('month').toDate();
      case "this_year":
        return moment().startOf('year').toDate();
      default:
        return moment().toDate();
    }
  }

  static toDateFilter(key) {
    switch (key) {
      case "today":
        return moment().endOf('day').toDate();
      case "yesterday":
        return moment().subtract(1, 'day').endOf('day').toDate();
      case "this_week":
        return moment().endOf('week').toDate();
      case "this_month":
        return moment().endOf('month').toDate();
      case "this_year":
        return moment().endOf('year').toDate();
      default:
        return moment().toDate();
    }
  }

  static age18() {
    return moment().add(-18, 'year').toDate();
  }

  static sum(data: Array<any>, field: string) {
    return data.reduce((a, b) => a + ConvertService.toNumber(b[field]), 0)
  }

  static sumTuitionFees(data: Array<any>) {
    let value = 0;
    const unpaidDocs = data.filter(m => m.scholarshipRef && m.scholarshipRef.key === "unpaid")
    if (unpaidDocs.length > 0) {
      value = unpaidDocs[0].price;
    } else {
      data.forEach(doc => {
        if (!doc.scholarship100) {
          value += doc.amount + ConvertService.toNumber(doc.loan) + ConvertService.toNumber(doc.scholarship)
        } else {
          value += doc.amount;
        }
      })
    }
    return value;
  }

  static orderByDesc(data: Array<any>, field: string) {
    return _.orderBy(data, [field], ['desc'])
  }
  static orderBy(data: Array<any>, field: string) {
    return _.orderBy(data, [field], ['asc'])
  }

  static groupByDesc(data: Array<any>, field: string, filedOrderBy: string) {
    const rows = _.uniqBy(data, field)
    return _.orderBy(rows, [filedOrderBy], ['desc'])
  }

  static filter(data: Array<any>, field: string, keyword: any) {
    return _.filter(data, [field, keyword])
  }

  static search(item: Observable<string>) {
    return item.debounceTime(400).distinctUntilChanged()
  }

  static pushToArray(data: firestore.QuerySnapshot) {
    if (data.empty) return null;
    return data.docs.map(m => { return { ...m.data() } })
  }

  static pushToObject(data: firestore.DocumentSnapshot) {
    if (!data.exists) return null;
    return data.data();
  }

  static groupBy(data: Array<any>, field: string, orderBy: string) {
    const rows = _.uniqBy(data, field)
    return _.orderBy(rows, [orderBy])
  }

  static groupByYear(data: Array<any>, field) {
    const list = data.map(m => {
      return moment(m[field].toDate()).format('YYYY');
    })
    const items = _.uniqBy(list)
    return _.orderBy(items, ['desc'])
  }

  static calScholarship(amount, arg: any): any {
    let value = 0;
    let { percentage, cash, loan } = arg;
    const cashAmount = ConvertService.toNumber(cash);
    const percentAmount = ConvertService.toNumber(percentage);
    const loanAmount = ConvertService.toNumber(loan);
    let balanceDue = amount;
    let totalScholarship = 0;
    let totalLoan = 0;
    if (cashAmount !== 0) {
      balanceDue = balanceDue - cashAmount;
      totalScholarship = totalScholarship + cashAmount;
    }
    if (percentAmount !== 0) {
      const totalDisP = balanceDue * percentAmount / 100;
      totalScholarship = totalScholarship + totalDisP;
    }
    if (loanAmount !== 0) {
      const totalDisL = balanceDue * loanAmount / 100;
      totalLoan = totalDisL;
    }
    const totalDis = totalScholarship + totalLoan;
    balanceDue = balanceDue - totalDis;

    value = balanceDue;
    const result = {
      totalLoan: totalLoan,
      totalScholarship: totalScholarship,
      balanceDue: value,
      deductAmount: totalDis
    }
    return result;
  }

  static filterStates(data: any, value: any, type: any): any[] {
    if (value.key || value.id || !data) return;
    switch (type) {
      case "invoice_no":
        return data.filter(state => state.invoice_no.toString().toLowerCase().indexOf(value.toLowerCase()) > -1);
      case "code":
        return data.filter(state => state.code.toLowerCase().indexOf(value.toLowerCase()) > -1);
      case "name":
        return data.filter(state => state.name.toLowerCase().indexOf(value.toLowerCase()) > -1);
      case "full_name":
        return data.filter(state => state.full_name.toLowerCase().indexOf(value.toLowerCase()) > -1);
      case "discount_voucher_no":
        return data.filter(state => state.discount_voucher_no.toLowerCase().indexOf(value.toLowerCase()) > -1);
      case "subject.name":
        return data.filter(state => state.subject.name.toLowerCase().indexOf(value.toLowerCase()) > -1);
      case "type.name":
        return data.filter(state => state.type.name.toLowerCase().indexOf(value.toLowerCase()) > -1);
      default:
        break;
    }
  }

  static getItemByKey(list: Array<any>, key) {
    const data = list.filter(m => m.key === key);
    return data.length > 0 ? data[0] : null;
  }

  static autoComplete(stateCtrl: AbstractControl, data: any, type) {
    return stateCtrl.valueChanges.pipe(startWith(''),
      map(state => state ? this.filterStates(data, stateCtrl.value, type) : data.slice())
    );
  }

  public static validSelected(control: AbstractControl): { [s: string]: boolean } {
    const value = control.value;
    if (value !== undefined && value !== null && value !== '') {
      if (!value.key && !value.id) {
        return { validKey: true }
      }
    }
  }

  public static toNull(value) {
    if (value === "" || value === undefined) {
      return null;
    }
    return value;
  }

  static studentObj(item: any) {
    const obj = this.toNull(item)
    if (obj) {
      return {
        key: item.key,
        id: this.toNull(item.StudentId),
        puc_id: this.toNull(item.puc_id),
        serial_id: this.toNull(item.serial_id),
        PUCCODE: this.toNull(item.PUCCODE),
        first_name: this.toNull(item.first_name),
        last_name: this.toNull(item.last_name),
        full_name: this.toNull(item.full_name),
        mobile_phone: this.toNull(item.mobile_phone),
        email_address: this.toNull(item.email_address),
        email: this.toNull(item.email),
        gender: this.toNull(item.gender),
        program_academic: this.toNull(item.program_academic),
      };
    } else return null;
  }

  static dailyShiftObj(item: any) {
    const obj = this.toNull(item)
    if (obj) {
      return {
        key: item.key,
        page_key: this.toNull(item.page_key),
        start_cashier: this.userObj(item.start_cashier),
        start_date: this.toNull(item.start_date),
        start_date_key: this.toNull(item.start_date_key),
      };
    } else return null;
  }

  static instituteObj(item: any) {
    const obj = this.toNull(item)
    if (obj) {
      return {
        key: item.key,
        name: this.toNull(item.name),
        instituteType: this.toNull(item.instituteType),
        shortName: this.toNull(item.shortName),
      };
    } else return null;
  }

  static testTypeObj(item: any) {
    const obj = this.toNull(item)
    if (obj) {
      return {
        key: item.key,
        name: this.toNull(item.name),
        institute: this.instituteObj(item.institute),
        shortName: this.toNull(item.shortName),
        description: this.toNull(item.description),
        // testingFee: {
        //   key: this.toNull(item.testingFee.key),
        //   code: this.toNull(item.testingFee.code),
        //   name: this.toNull(item.testingFee.name),
        //   price: this.toNull(item.testingFee.price),
        //   program: this.toNull(item.testingFee.program),
        // }
      };
    } else return null;
  }

  static userObj(item: any) {
    return {
      key: item.key,
      name: this.toNull(item.name),
      displayName: this.toNull(item.displayName),
      email: this.toNull(item.email),
      role: this.toNull(item.role),
      campus: this.campusObj(item.campus),
      uid: this.toNull(item.uid),
    };
  }

  static employeeObj(item: any) {
    return {
      key: item.key,
      name: this.toNull(item.name),
      display_name: this.toNull(item.display_name),
      email: this.toNull(item.email),
      dob: this.toNull(item.dob),
      first_name: this.toNull(item.first_name),
      last_name: this.toNull(item.last_name),
      full_name: this.toNull(item.full_name),
    };
  }

  static installmentObj(item: any) {
    return {
      key: item.key,
      name: this.toNull(item.name),
      create_date: this.toNull(item.create_date),
      create_by: this.userObj(item.create_by),
      page_key: this.toNull(item.page_key),
      student: { key: item.student.key },
      last_installment: this.toNull(item.last_installment),
      price: this.toNull(item.price),
      amount: this.toNull(item.amount),
      received_by: this.userObj(item.received_by),
      received_date: this.toNull(item.received_date),
      received_date_key: this.toNull(item.received_date_key),
      term: {
        key: item.term.key,
        code: item.term.code,
        name: item.term.name,
      },
      campus: this.campusObj(item.campus),
      shift: this.toNull(item.shift),
      note: this.toNull(item.note),
    };
  }

  static campusObj(item: any) {
    const obj = this.toNull(item)
    if (obj) {
      return {
        key: item.key,
        name: this.toNull(item.name),
      };
    } else return null;
  }

  static settingObj(item: any) {
    const obj = this.toNull(item)
    if (obj) {
      return {
        key: item.key,
        name: this.toNull(item.name),
      };
    } else return null;
  }

  static paymentOptionObj(item: any) {
    const obj = this.toNull(item)
    if (obj) {
      return {
        key: item.key,
        name: this.toNull(item.name),
        order: this.toNull(item.order),
        period: this.toNull(item.period),
      };
    } else return null;
  }

  static otherFeeObj(item: any) {
    const obj = this.toNull(item)
    if (obj) {
      return {
        key: item.key,
        name: this.toNull(item.name),
        isPeriod: this.toNull(item.isPeriod),
        price: this.toNull(item.price),
        period: this.toNull(item.period),
        otherFee: this.toNull(item.otherFee),
      };
    } else return null;
  }

  static capitalFeeObj(item: any) {
    const obj = this.toNull(item)
    if (obj) {
      return {
        key: item.key,
        name: this.toNull(item.type.name),
        nameKh: this.toNull(item.type.nameKh),
        type: this.toNull(item.type),
        price: this.toNull(item.price),
        schoolEducationLevelKey: this.toNull(item.schoolEducationLevelKey),
        schoolYearKey: this.toNull(item.schoolYearKey),
      };
    } else return null;
  }

  static sessionTimeObj(item: any) {
    const obj = this.toNull(item)
    if (obj) {
      return {
        key: item.key,
        name: this.toNull(item.name),
        shortName: this.toNull(item.shortName),
        shift: {
          key: item.shift.key,
          name: item.shift.name,
          id: item.shift.id,
        }
      };
    } else return null;
  }

  static sessionObj(item: any) {
    const obj = this.toNull(item)
    if (obj) {
      return {
        key: this.toNull(item.key),
        days: this.toNull(item.days),
        fromHours: this.toNull(item.fromHours),
        fromHoursNumber: this.toNull(item.fromHoursNumber),
        name: this.toNull(item.name),
        toHours: this.toNull(item.toHours),
        toHoursNumber: this.toNull(item.toHoursNumber),
        shift: this.shiftObj(item.shift)
      }
    } else return null;

  }

  static shiftObj(item: any) {
    const obj = this.toNull(item)
    if (obj) {
      return {
        key: this.toNull(item.key),
        id: this.toNull(item.id),
        duration: this.toNull(item.duration),
        paymentRate: this.toNull(item.paymentRate),
        name: this.toNull(item.name),
        order: this.toNull(item.order),
      }
    } else return null;
  }

  static scholarshipObj(item: any) {
    return {
      key: item.key,
      student: this.toNull(item.student),
      percentage: this.toNull(item.percentage),
      cash: this.toNull(item.cash),
      isScholarship: this.toNull(item.isScholarship),
      isScholarshipLoan: this.toNull(item.isScholarshipLoan),
      isScholarshipSibling: this.toNull(item.isScholarshipSibling),
      approve_by: this.toNull(item.approve_by),
      approve_date: this.toNull(item.approve_date),
      program: this.toNull(item.program),
      sibling: this.toNull(item.sibling),
      scholarshipType: this.toNull(item.scholarshipType),
    };
  }

  static generationObj(item: any) {
    return {
      key: item.key,
      campus: this.toNull(item.campus),
      generation: this.toNull(item.generation),
      term: {
        key: item.term.key,
        name: item.term.name,
      },
    };
  }

  static abcCourseObj(item: any) {
    return {
      key: item.key,
      campus: this.toNull(item.campus),
      code: this.toNull(item.code),
      endTermKey: this.toNull(item.endTermKey),
      endcourse: this.toNull(item.endcourse),
      generation: {
        key: item.generation.key,
        generation: item.generation.generation,
      },
      name: this.toNull(item.name),
      page_key: this.toNull(item.page_key),
      startDateKey: this.toNull(item.startDateKey),
      startcourse: this.toNull(item.startcourse),
      term: {
        key: item.term.key,
        name: item.term.name,
      },
    };
  }

  static levelObj(item: any) {
    return {
      key: item.key,
      name: this.toNull(item.name),
      objective: this.toNull(item.objective),
      level: this.toNull(item.level),
      nextLevel: this.toNull(item.nextLevel),
      fromScored: this.toNull(item.fromScored),
      toScored: this.toNull(item.toScored),
      passScored: this.toNull(item.passScored),
      failScored: this.toNull(item.failScored),
      success: this.toNull(item.success)
    };
  }

  static feeObj(item: any) {
    const obj = this.toNull(item)
    if (obj) {
      return {
        key: item.key,
        code: this.toNull(item.code),
        is_academic: this.toNull(item.is_academic),
        name: this.toNull(item.name),
        price: this.toNull(item.price),
        foreigner: this.toNull(item.foreigner),
        program: this.toNull(item.program),
        program_type: this.toNull(item.program_type),
      }
    } else return null;
  }

}

const uatCourses = [
  "PE 100",
  "PE 101",
  "PE 102",
  "AESP 101",
  "AESP 103",
  "AESP 102",
  "AESP 104",
  "AESP 105",
  "AESP 106"
]

export function dateKeyToDate(dateKey: string | number) {
  return Number(moment(dateKey, 'YYYYMMDD').toDate())
}

export function createUniqueKey(firstName: string, lastName: string, birthDateKey: number, gender: string) {
  return ((firstName.trim() + lastName.trim() + birthDateKey + gender).substring(0).toLowerCase())
}

export function getAge(created_at: any): number {
  return moment().diff(created_at, 'years');
}
export function ageFromDateOfBirthday(dateOfBirth: any): number {
  return moment().diff(dateOfBirth, 'years');
}

export function toCapitalize(value) {
  let string = null;
  if (value) string = value.toUpperCase().toString().trim();
  return string;
}

export function toDateCalendar(date: any) {
  return moment(date, 'YYYY-MM-DD').toDate();
}

export function toDateKey(date: Date) {
  return Number(moment(date).format('YYYYMMDD'))
}
export function fileSize(size: any) {
  if (size) {
    const fSExt = new Array('Bytes', 'KB', 'MB', 'GB');
    let j = 0;
    while (size > 900) { size /= 1024; j++; };
    const exactSize = (Math.round(size * 100) / 100) + ' ' + fSExt[j];
    return exactSize
  }
  return null
}

export function pushToArray(snapshot: firestore.QuerySnapshot) {
  if (snapshot.empty) return [];
  return snapshot.docs.map(m => ({ ...m.data(), id: m.id }));
}

export function pushToObject(snapshot: firestore.DocumentSnapshot) {
  if (!snapshot.exists) return null;
  return { ...snapshot.data(), id: snapshot.id }
}

export function pushObjArray(data: Array<any>, newItem: any) {
  let value = [];
  if (data && data.length > 0) {
    value = data;
    const exist = value.filter(m => m.key === newItem.key);
    if (exist && exist.length > 0) {
      const index = value.findIndex((obj => obj.key == newItem.key));
      value[index] = newItem;
    } else {
      value.push(newItem);
    }
  } else {
    value.push(newItem);
  }

  return value;
}

export function pushStringArray(data: Array<any>, key: string) {
  let value = [];
  if (data && data.length > 0) {
    value = data;
    const exist = value.filter(m => m === key);
    if (exist && exist.length > 0) {
      const index = value.findIndex((obj => obj == key));
      value[index] = key;
    } else {
      value.push(key);
    }
  } else {
    value.push(key);
  }

  return value;
}

export function removeObjArray(data: Array<any>, key: string): any {
  let value = [];
  if (data && data.length > 0) {
    value = data.filter(m => m.key !== key);
  }
  return value;
}

export function removeStingArray(data: Array<any>, key: string): any {
  let value = [];
  if (data && data.length > 0) {
    value = data.filter(m => m !== key);
  }
  return value;
}

export function toNull(value) {
  if (value === "" || value === undefined) {
    return null;
  }
  return value;
}

export function toNumber(value) {
  if (value === null || value === "" || value === undefined) {
    return 0;
  }
  if (Number(value) === NaN) return 0;
  return Number(value);
}

export function sum(data: Array<any>, field: string) {
  return data.reduce((a, b) => a + toNumber(b[field]), 0)
}

//NEW OBJECT

export function shiftObj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      name: toNull(item.name),
    };
  } else return null;
}

export function academicTrainingProgramObj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      name: toNull(item.name),
      khName: toNull(item.khName),
      programOption: toNull(item.programOption),
    }
  } else return null;
}

export function feeObj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      code: toNull(item.code),
      is_academic: toNull(item.is_academic),
      name: toNull(item.name),
      price: toNull(item.price),
      foreigner: toNull(item.foreigner),
      program: toNull(item.program),
      program_type: toNull(item.program_type),
    }
  } else return null;
}

export function gradeObj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      name: toNull(item.name),
      displayName: toNull(item.displayName),
      order: toNull(item.order),
      program: toNull(item.program),
      type: toNull(item.type),
    }
  } else return null;
}

export function programObj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      name: toNull(item.name),
      khName: toNull(item.khName),
      programOption: toNull(item.programOption),
    }
  } else return null;
}

export function academicTrainingFeeObj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      name: toNull(item.name),
      order: toNull(item.order),
      grade: gradeObj(item.grade),
      gradeNext: gradeObj(item.gradeNext),
      program: toNull(item.program),
      period: toNull(item.period),
    }
  } else return null;
}

export function userObj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      campus: campusObj(item.campus),
      name: toNull(item.name),
      displayName: toNull(item.displayName),
      email: toNull(item.email),
      role: toNull(item.role),
    };
  } else return null;
}

export function studentObj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      id: toNull(item.StudentId),
      puc_id: toNull(item.puc_id),
      serial_id: toNull(item.serial_id),
      PUCCODE: toNull(item.PUCCODE),
      first_name: toNull(item.first_name),
      last_name: toNull(item.last_name),
      full_name: toNull(item.full_name),
      mobile_phone: toNull(item.mobile_phone),
      email_address: toNull(item.email_address),
      email: toNull(item.email),
      gender: toNull(item.gender),
    };
  } else return null;
}

export function academicYearObj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      name: toNull(item.name),
      code: toNull(item.code),
      dropClass: toNull(item.dropClass),
      dropClassKey: toNull(item.dropClassKey),
      endDate: toNull(item.endDate),
      endDateKey: toNull(item.endDateKey),
      endEnroll: toNull(item.endEnroll),
      endEnrollKey: toNull(item.endEnrollKey),
      endPayment: toNull(item.endPayment),
      endPaymentKey: toNull(item.endPaymentKey),
      startDate: toNull(item.startDate),
      startDateKey: toNull(item.startDateKey),
      startEnroll: toNull(item.startEnroll),
      startEnrollKey: toNull(item.startEnrollKey),
      termType: toNull(item.termType),
      academic_year: toNull(item.academic_year),
      program_term: toNull(item.program_term),
    }
  } else return null;
}


export function campusObj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      name: toNull(item.name),
    };
  } else return null;
}

export function schoolObj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      name: toNull(item.name),
      khName: toNull(item.khName),
    };
  } else return null;
}

export function paymentOptionObj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      name: toNull(item.name),
      order: toNull(item.order),
      period: toNull(item.period),
    }
  } else return null;
}

export function trainingFeeObj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      amount: toNull(item.amount),
      fee: toNull(item.fee),
      paymentOption: toNull(item.paymentOption),
    };
  } else return null;
}

export function productObj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      avg: toNull(item.avg),
      barcode: toNull(item.barcode),
      category: toNull(item.category),
      cost: toNull(item.cost),
      name: toNull(item.name),
      page_key: toNull(item.page_key),
      productType: toNull(item.productType),
      reorderPoint: toNull(item.reorderPoint),
      sellingPrice: toNull(item.sellingPrice),
      sku: toNull(item.sku),
      stockBalance: toNull(item.stockBalance),
      storeKey: toNull(item.storeKey),
      storeRef: toNull(item.storeRef),
      unitStock: toNull(item.unitStock),

      grandTotal: toNull(item.grandTotal),
      discountPercentage: toNull(item.discountPercentage),
      discountCash: toNull(item.discountCash),
      discountTotal: toNull(item.discountTotal),
      sale_type: toNull(item.sale_type),
      qty: toNull(item.qty),
      totalPrice: toNull(item.totalPrice),
      totalAfterDiscount: toNull(item.totalAfterDiscount),
      vat: toNull(item.vat),
      percentage: toNull(item.percentage),
      cash: toNull(item.cash),
    };
  } else return null;
}
