import { invoiceTypesObj } from 'src/app/dummy/status';
import { Payment } from 'src/app/stores/payment.store';
import { Component, OnInit, Input } from '@angular/core';
import { Environment } from 'src/app/stores/environment.store';
import { SCHOOL } from 'src/app/dummy/config';

@Component({
  selector: 'app-invoice-payment',
  templateUrl: './invoice-payment.component.html',
  styleUrls: ['./invoice-payment.component.scss']
})
export class InvoicePaymentComponent implements OnInit {
  @Input() user: any;
  @Input() show:boolean=true;
  school=SCHOOL

  // img = '../../../assets/images/ngs.png'
  img = SCHOOL.img


  paymentDate = new Date();
  paymentType = invoiceTypesObj;

  // address = "Street 207(300m East of SAR KHENG bridge), Romchek IV, Rattanak, Battambang, Cambodia";
  // address = "Romchek IV, Rattanak, Battambang, Cambodia";
  // phone = "Tel: (855)53 637 7720, (855)53 638 8820. Website: www.puc.edu.kh";

  currentDate= new Date();
  constructor(public store: Payment, public env: Environment) { }

  ngOnInit() {

  }

}
