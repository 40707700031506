import { ShiftCloseComponent } from 'src/app/pages/shift-close/shift-close.component';
import { CashierShiftClosedComponent } from 'src/app/components/cashier-shift-closed/cashier-shift-closed.component';
import { MatDialog } from '@angular/material';
import { invoiceTypeParams } from 'src/app/dummy/status';
import { ConvertService } from 'src/app/services/convert.service';
import { AuthService } from 'src/app/auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { Report } from './../../../stores/report.store';
import { Component, OnInit } from '@angular/core';
import { Environment } from 'src/app/stores/environment.store';
import { User } from 'src/app/stores/user.store';
import { PrintService } from 'src/app/services/print.service';
import { SCHOOL } from 'src/app/dummy/config';

@Component({
  selector: 'app-shift-report-listing',
  templateUrl: './shift-report-listing.component.html',
  styleUrls: ['./shift-report-listing.component.scss']
})
export class ShiftReportListingComponent implements OnInit {
  type = "all";
  reportName = "All";
  shift = null;
  exchange_rate;
  school=SCHOOL

  shiftData;
  constructor(public env: Environment,
    public store: Report,
    public auth: AuthService,
    public user: User,
    private route: ActivatedRoute,
    private ps: PrintService,
    private dialog: MatDialog
  ) { }

  async ngOnInit() {
    this.route.params.forEach(param => {
      this.type = param["id"];
      this.env.getUser(async (user) => {
        if (user && user.shift) {
          this.shift = user.shift;
          this.shiftData = await this.env.fetchShiftMovementDoc(user.shift.key)
          this.exchange_rate = this.shiftData && this.shiftData.exchange_rate || 4000;

          this.store.fetchReceiptByTerm(user.shift.key);
          this.store.fetchDialShiftCashReceiveRiels(user.shift.key, (riels) => { })
          this.store.fetchDialShiftCashReceiveDollars(user.shift.key, (dollars) => { })
        }
      })
    })
  }

  print() {
    this.ps.print('#print', 'a4');
  }

}
