import { ITuition } from './../interfaces/tuition';
import { DataService } from "./../services/data.service";
import { observable, action } from "mobx";
import { Injectable } from "@angular/core";
import { MappingService } from "../services/mapping.service";

@Injectable()
export class Academic {
  @observable
  public data = null;
  @observable
  public testing = null;
  @observable
  public loading = false;
  @observable
  public empty = false;

  @observable
  public selectedInstitute = null;
  @observable
  public programs = null;
  @observable
  public process = false;

  constructor(private ds: DataService) { }

  @action
  async fetchCampus() {
    const data = await this.ds.campusRef().get().toPromise();
    return MappingService.pushToArray(data)
  }

  @action
  fetchData(type: string) {
    this.loading = true;
    this.ds.tuitionFeeByTypeRef(type).valueChanges().subscribe(docs => {
      this.data = MappingService.orderBy(docs, "code");
      this.empty = docs.length === 0;
      this.loading = false;
    })
  }

  @action
  fetchToArray(type: string, callback) {
    this.process = true;
    this.ds.tuitionFeeByTypeRef(type).valueChanges().subscribe(docs => {
      callback(docs);
      this.process = false;
    })
  }

  @action
  save(item: ITuition, callback) {
    this.process = true;
    this.ds
      .tuitionFeeRef()
      .doc(item.key)
      .set(item)
      .then(() => {
        this.process = false;
        callback(true, null);
      })
      .catch(error => {
        this.process = false;
        callback(false, error);
      });
  }

  @action
  update(item: ITuition, callback) {
    this.process = true;
    this.ds
      .tuitionFeeRef()
      .doc(item.key)
      .update(item)
      .then(() => {
        this.process = false;
        callback(true, null);
      })
      .catch(error => {
        this.process = false;
        callback(false, error);
      });
  }

  @action
  delete(form: ITuition, callback) {
    this.ds
      .tuitionFeeRef()
      .doc(form.key)
      .delete()
      .then(() => {
        callback(true, null);
      })
      .catch(error => {
        callback(false, error);
      });
  }

}
