import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayInvoiceTerm'
})
export class DisplayInvoiceTermRefPipe implements PipeTransform {

  transform(item: any, invoiceType: any): any {
    let result = null

    switch (invoiceType.key) {
      case 2:
        result = item.fee.paymentOption.period || null
        break;
      case 10:
        result = item.fee && item.fee.paymentOption && item.fee.paymentOption.period && item.fee.paymentOption.period || null
        break;
      case 12:
        result = 1
        break;
      case 13:
        result = item.course.type.period || null
        break;
      default:
        break;
    }

    return result
  }
}

@Pipe({
  name: 'displayInvoiceQty'
})
export class DisplayInvoiceQtyRefPipe implements PipeTransform {

  transform(item: any, invoiceType: any): any {
    let result = 1

    switch (invoiceType.key) {
      case 11:
        result = item.course.qty || 1
        break;
      case 13:
        result = item.total_day || 1
        break;
      default:
        break;
    }

    return result
  }
}

