import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConvertService } from 'src/app/services/convert.service';
import { DataService } from 'src/app/services/data.service';
import { MappingService } from 'src/app/services/mapping.service';
import { BaseStore } from 'src/app/stores/base.store';
import { Environment } from 'src/app/stores/environment.store';
import { Payment } from 'src/app/stores/payment.store';
import { Student } from 'src/app/stores/student.store';

@Component({
  selector: 'app-add-student-old-voucher',
  templateUrl: './add-student-old-voucher.component.html',
  styleUrls: ['./add-student-old-voucher.component.scss']
})
export class AddStudentOldVoucherComponent implements OnInit {
  form: FormGroup;
  receipt: AbstractControl;

  filteredReceiptStates: any;

  selectedItem;
  isExist;
  totalScholarshipDeduct = 0;
  totalDiscountVoucher = 0;
  discountVoucher;

  constructor(
    public dialogRef: MatDialogRef<AddStudentOldVoucherComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    public env: Environment,
    public dialog: MatDialog,
    public base: BaseStore,
    private ds: DataService,
    public store: Student,
    public payment: Payment,
  ) { }

  async ngOnInit() {
    this.buildForm();

    const { globalDiscount, scholarshipDiscount } = this.payment

    this.totalScholarshipDeduct = scholarshipDiscount ? (ConvertService.toNumber(scholarshipDiscount.amount) + ConvertService.toNumber(scholarshipDiscount.amountOther)) : 0;
    if ((globalDiscount && ConvertService.toNumber(globalDiscount.amount)) > (scholarshipDiscount && (ConvertService.toNumber(scholarshipDiscount.amount) + ConvertService.toNumber(scholarshipDiscount.amountOther)))) {
      this.totalScholarshipDeduct = ConvertService.toNumber(globalDiscount.amount)
    }

    const { student } = this.store;

    let invoiceData = await this.base.fetchList(this.ds.invoiceAllByStudentKeyRef(student.key));
    invoiceData = invoiceData.filter(m => !m.isVoid && m.received_date_key === ConvertService.dateKey());

    invoiceData = MappingService.groupByDesc(invoiceData, "discount_voucher_no", "received_date");
    this.filteredReceiptStates = MappingService.autoComplete(this.receipt, invoiceData, "discount_voucher_no");
  }

  buildForm(): void {
    this.form = this.fb.group({
      receipt: [null, Validators.compose([Validators.required, MappingService.validSelected.bind(this)])],
    });
    this.receipt = this.form.controls["receipt"];
  }

  displayItemReceipt(item: any): string {
    return item ? item.discount_voucher_no : item;
  }

  async onSelectVoucher(event) {
    const { value } = event.option;
    if (value) {
      const { selectedSchool } = this.env
      const { selectedAdmission } = this.store;
      const { discount_voucher_detail_key, program } = value

      const admissionProgramNew = selectedAdmission.program_academic.program
      const admissionProgramOld = program.program

      this.selectedItem = await this.store.fetchStudentVoucherDoc(selectedSchool.key, discount_voucher_detail_key);

      const canAdd = this.selectedItem.apply_voucher_multi && admissionProgramNew.key !== admissionProgramOld.key;

      this.isExist = this.selectedItem.discount_voucher.expired_date_key <= ConvertService.dateKey() || !canAdd

      const { paymentTuitionFee } = this.payment
      const tuitionFee = paymentTuitionFee && paymentTuitionFee.length > 0 ? paymentTuitionFee[0] : null;

      if (!this.isExist && tuitionFee) {
        this.discountVoucher = this.payment.getDiscountVoucher(tuitionFee, this.selectedItem)
        this.totalDiscountVoucher = this.discountVoucher.amount
      }

    }
    else {
      this.isExist = false
      this.selectedItem = null;
    }
  }

  create(f: any) {
    if (this.totalDiscountVoucher < this.totalScholarshipDeduct || !this.discountVoucher) return;
    if (this.form.valid && !this.isExist) {

      const { paymentHeader } = this.payment;
      this.store.addDiscountVoucher(this.selectedItem, paymentHeader, this.discountVoucher, this.env.user, (success, error) => {
        if (success) {
          this.snackBar.open("Discount voucher has been added.", "Done", { duration: 2000 })
          this.dialogRef.close('yes');
        }
        else {
          this.snackBar.open(error, "Error");
        }
      })

    }
  }
}
