import { AbstractControl, FormBuilder, Validators } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import { User } from "./../../stores/user.store";
import { Router } from "@angular/router";
import { AuthService } from "./../../auth/auth.service";
import { Component, OnInit } from "@angular/core";
import {
  trigger,
  style,
  transition,
  animate,
  query,
  stagger
} from "@angular/animations";
import { MatDialog } from "@angular/material";
import { ShiftOpenComponent } from "../shift-open/shift-open.component";
import { ShiftCloseComponent } from "../shift-close/shift-close.component";
import { Environment } from "../../stores/environment.store";
import { CashierShiftClosedComponent } from "src/app/components/cashier-shift-closed/cashier-shift-closed.component";
import { searchFilterBy, memberOfObj } from "src/app/dummy/status";
import { Student } from "src/app/stores/student.store";
import { switchMap, debounceTime, tap } from "rxjs/operators";
import { Pages } from "src/app/dummy/pages";
import { ConvertService } from "src/app/services/convert.service";
import { MappingService } from "src/app/services/mapping.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
  animations: [
    trigger("explainerAnim", [
      transition("* => *", [
        // query(".ani-col", style({ opacity: 0, transform: "translateY(40px)" })),
        query(
          ".ani-col",
          stagger("200ms", [
            animate(
              "500ms .3s ease-out",
              style({ opacity: 1, transform: "translateY(0)" })
            )
          ])
        ),
        query(".ani-col", [animate(1000, style("*"))])
      ])
    ])
  ]
})



export class DashboardComponent implements OnInit {
  formFocus: boolean;
  form: FormGroup;
  search: AbstractControl;
  searchType: AbstractControl;
  filterBy = searchFilterBy;
  loading: boolean = false;

  isCashier = true;

  constructor(
    private auth: AuthService,
    private router: Router,
    private user: User,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public env: Environment,
    public store: Student,
  ) { }

  logout() {
    this.auth.signOut().then(() => {
      this.router.navigate(["/auth"]);
    });
  }

  _onReceivedPayment(type: string) {
    if (this.user.user === null) return;
    const { shift } = this.user.user;
    if (!shift) {
      let dialogRef = this.dialog.open(CashierShiftClosedComponent, {
        data: null,
        disableClose: true,
        role: "dialog"
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result === 'yes') {
          this.openShift();
        }
      });
      return;
    }
    this.router.navigate(["/receive-payments/0/" + type]);
  }

  _onSearchInvoiceNo() {
    this.router.navigate(["/search-invoice-no"]);
  }

  receiptList() {
    if (this.user.user.shift) {
      this.router.navigate(["/payment-listing"]);
    }
  }
  PaymentList() {
    if (this.user.user.shift) {
      this.router.navigate(["/payment-summery"]);
    }
  }
  ShiftList() {
    if (this.user.user.shift) {
      this.router.navigate(["/app/daily-shift-listing"]);
    }
  }

  ShiftSummary() {
    if (this.user.user.shift) {
      this.router.navigate(["/daily-shift/listing"]);
    }
  }

  _onPettyCash() {
    if (this.user.user.shift && this.env.sameDay) {
      this.router.navigate(["/app/petty-cash"]);
    }
  }

  NewTesting() {
    if (this.user.user.shift && this.env.sameDay) {
      this.router.navigate(["/new-registration-form"]);
    }
  }

  ngOnInit() {
    this.form = this.fb.group({
      searchType: [this.filterBy[0], [Validators.required]],
      search: [null]
    });
    this.search = this.form.controls["search"];
    this.searchType = this.form.controls["searchType"];
    if (this.store.filterType) {
      this.searchType.patchValue(this.store.filterType);
    }
    const { searchType } = this.form.value;

    this.env.fetchUserAccount(userDoc => {
      const { campus, memberOf } = userDoc;
      if (memberOf.key !== memberOfObj.guest.key) {
        this.isCashier = false
      }
      this.store.fetchData(searchType.key, campus);
      this.store.filterType = searchType;
      this.search.valueChanges
        .pipe(
          debounceTime(Pages.debounceTime),
          tap(() => (this.loading = true)),
          switchMap(value => this.store.search(this.store.filterType.key, value, campus))
        )
        .subscribe(results => {
          this.store.data = results;
          this.loading = false;
        });
    });

  }

  openShift() {
    if (this.user.user === null) return;
    let dialogRef = this.dialog.open(ShiftOpenComponent, {
      data: null,
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: true,
      role: "dialog"
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => {
      if (result === "yes") {
        this.env.fetchUserAccount((user) => { })
      }
    });
  }

  closeShift() {
    const { shift } = this.user.user;
    if (!shift) {
      let dialogRef = this.dialog.open(CashierShiftClosedComponent, {
        data: null,
        disableClose: true,
        role: "dialog"
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result === 'yes') {
          this.openShift();
        }
      });
      return;
    }
    let dialogRef = this.dialog.open(ShiftCloseComponent, {
      data: null,
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: true,
      role: "dialog"
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => { });
  }


  displayItem(item: any): string {
    if (this.searchType) {
      const { key } = this.searchType.value;
      return item ? item[key] : item;
    }
    return item ? item.puc_id : item;
  }

  compareObjects(o1: any, o2: any): boolean {
    if (o2) {
      return o1.text === o2.text;
    }
  }
  _onFocus(yes) {
    this.formFocus = yes;
  }

  _onSearch(item: any) {
    if (this.form.valid) {
      this.onRouter(item)
    }
  }

  _selectionChange(event) {
    const { value } = event;
    this.searchType = value;
    this.store.filterType = value;
  }

  _optionSelected(item: any) {
    this.onRouter(item)
  }

  _onSubmit(f: any) {
    if (this.form.valid) {
      const { search } = f;
      this.onRouter(search)
    }
  }

  onRouter(item) {
    if (item.key) {
      const { program_academic, key } = item;
      this.store.student = item;
      const list = this.store.data.filter(m => m.key === key);
      if (list.length > 0) {
        if (program_academic) {
          const { admissionKey } = program_academic;
          if (admissionKey) {
            this.router.navigate(["/student/" + this.store.student.key + "/" + admissionKey + '/receive-payment']);
          } else {
            this.store.resolveAdmission(this.store.student);
          }
        }
        else {
          this.router.navigate(["/student/" + this.store.student.key + "/draft/receive-payment"]);
        }
      }
    }
    else {
      const { searchType } = this.form.value;
      const list = this.store.data.filter(m => m[searchType.key] === item);
      if (list.length > 0) {
        const { program_academic, key } = list[0]
        this.store.student = list[0];
        if (program_academic) {
          const { admissionKey } = program_academic
          this.router.navigate(["/student/" + key + '/' + admissionKey + "/receive-payment"]);
        }
        else {
          this.router.navigate(["/student/" + this.store.student.key + "/draft/receive-payment"]);
        }
      }
    }
  }

}
