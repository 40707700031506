import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-new-testing-form',
  templateUrl: './edit-new-testing-form.component.html',
  styleUrls: ['./edit-new-testing-form.component.scss']
})
export class EditNewTestingFormComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
