import { ForgotPasswordComponent } from './../../components/forgot-password/forgot-password.component';
import { DataService } from './../../services/data.service';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { AuthService } from './../../auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, AbstractControl, Validators, FormBuilder } from '@angular/forms';
import { appObjects } from 'src/app/dummy/status';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  hide = true;
  form: FormGroup;
  email: AbstractControl;
  password: AbstractControl;
  error: any;
  process = false;
  constructor(
    private fb: FormBuilder,
    public auth: AuthService,
    private router: Router,
    private dialog: MatDialog,
    public ds: DataService
  ) {
    this.auth.isLoggedIn().then(doc => {
      if (doc) {
        this.router.navigate(['/']);
      }
    })
  }

  ngOnInit() {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
    this.email = this.form.controls['email'];
    this.password = this.form.controls['password'];
  }

  emailLogIn(f: any): void {
    this.error = null;
    this.process = true;
    if (this.form.valid) {
      this.form.disable();
      const email = f.email;
      const password = f.password;
      this.auth.emailSignin(email, password).then(user => {
        if (user) {
          this.ds.userRef(user.user.uid).valueChanges().subscribe(doc => {
            if (!doc) {
              this.error = "Invalid your email and password or access denied.";
              this.router.navigate(['/auth']);
              this.process = false;
              this.form.enable();
              return;
            }
            const { apps } = doc;
            if (!apps || apps.filter(m => m === appObjects.cashier.key).length === 0) {
              this.auth.signOut().then(() => {
                this.error = "Invalid your email and password or access denied.";
                this.router.navigate(['/auth']);
                this.process = false;
                this.form.enable();
              })
            }
            else {
              this.router.navigate(['/']);
              this.process = false;
              this.form.enable();
            }
          })
        }
      }).catch(error => {
        this.process = false;
        this.error = error.message;
        this.form.enable();
      });
    } else {
      this.process = false;
      this.form.enable();
      this.error = 'Please enter your email and password.';
    }
  }


  resetPassword() {
    const dialogRef = this.dialog.open(ForgotPasswordComponent, {
      data: null,
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: true,
      role: "dialog"
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => { });
  }

}
