import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-program-shift-student',
  templateUrl: './program-shift-student.component.html',
  styleUrls: ['./program-shift-student.component.scss']
})
export class ProgramShiftStudentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
