import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { Testing } from 'src/app/stores/testing.store';
import { FireValidatorsService } from 'src/app/services/fire-validators.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { ConvertService } from 'src/app/services/convert.service';
import { Environment } from 'src/app/stores/environment.store';
import { ITesting } from 'src/app/interfaces/testing';
import { TestingStatus, paymentStatus } from 'src/app/dummy/status';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-new-testing-form',
  templateUrl: './add-new-testing-form.component.html',
  styleUrls: ['./add-new-testing-form.component.scss']
})
export class AddNewTestingFormComponent implements OnInit {
  @ViewChild("focusInput") inputEl: ElementRef;
  isLinear = true;
  form: FormGroup;
  first_name: AbstractControl;
  last_name: AbstractControl;
  dob: AbstractControl;
  mobile_phone: AbstractControl;
  testData: any;

  constructor(
    public dialogRef: MatDialogRef<AddNewTestingFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    public store: Testing,
    private afs: AngularFirestore,
    public env: Environment,
    private router: Router,
  ) { }

  buildForm(): void {
    this.form = this.fb.group({
      // code: [null, Validators.compose([Validators.required]), FireValidatorsService.checkExist(this.afs, 'admission_services', 'code')],
      first_name: [null, Validators.required],
      last_name: [null, Validators.required],
      dob: [new Date(), Validators.required],
      mobile_phone: [null, Validators.required],
    })
    this.first_name = this.form.controls['first_name'];
    this.last_name = this.form.controls['last_name'];
    this.dob = this.form.controls['dob'];
    this.mobile_phone = this.form.controls['mobile_phone'];
   
  }
  compareObjects(o1: any, o2: any): boolean {
    if (o2) return o1.key === o2.key;
  }

  ngOnInit() {
    this.buildForm();
  }

  create(f: any, stepper) {
    if (this.form.valid) {
      const formData: ITesting = {
        key: null,
        first_name: f.first_name,
        last_name: f.last_name,
        dob: f.dob,
        isPaid: paymentStatus.unpaid,
        isPaidTest: false,
        mobile_phone: f.mobile_phone,
        status: TestingStatus.active,
        create_date: new Date(),
        create_date_key: ConvertService.dateKey(),
        create_by: this.env.user,
        page_key:ConvertService.pageKey(),
      }
      this.store.addTest(formData, this.env.config, this.env.user, (success, res) => {
        if (success) {
          this.testData = res;
          this.snackBar.open('Testing has been created.', 'done', { duration: 2000 });
          this.form.reset();
          stepper.next();
        }
        else {
          this.snackBar.open(res, 'Error')
        }
      })
    }
  }

  _goPay(item){
    this.dialogRef.close();
    this.router.navigate(['/receive-payments/'+1047+ '/testing-fees'])
  }

}