import { IOptions } from "./../interfaces/options";
import { DataService } from "./../services/data.service";
import { observable, action } from "mobx";
import { Injectable } from "@angular/core";
import { AuthService } from "../auth/auth.service";
import { MappingService, pushToArray, pushToObject } from "../services/mapping.service";
import { Router } from "@angular/router";
import { appObjects } from "../dummy/status";
import { ConvertService } from "../services/convert.service";
import { AngularFireAuth } from "@angular/fire/auth";
import * as firebase from 'firebase/app';

@Injectable()
export class Environment {
  @observable user = null;
  @observable dailyShift = null;
  @observable sameDay = false;
  @observable config = null;
  @observable loading = false;
  @observable academic = null;
  @observable process = false;
  @observable balance = null;
  @observable abc = null;
  @observable abcFee = null;
  @observable public term = null;
  @observable public campus = null;
  @observable public puc_generation = null;
  @observable public instituteTerm = null;
  @observable public selectedYear = null;
  @observable public academicEnv = null;
  @observable public sysOption = null;

  @observable selectedCampus: any = null;
  @observable selectedSchool: any = null;

  constructor(
    private ds: DataService,
    private router: Router,
    private auth: AuthService,
    private authFire: AngularFireAuth

  ) {
    this.auth.authRef().user.subscribe(user => {
      if (user) {
        this.fetchUser(user.uid);
      }
      else {
        this.router.navigate(['/auth'])
      }
    });
  }

  @action
  changePassword(oldPassword: any, newPassword: any, callback) {
    const user = firebase.auth().currentUser;
    firebase.auth().signInWithEmailAndPassword(user.email, oldPassword).then((account) => {
      user.updatePassword(newPassword).then(() => {
        callback(true, null)
      }).catch(error => {
        callback(false, error)
      })
    }).catch(error => {
      callback(false, error)
    })
  }

  @action
  async fetchAcademicYearDoc(key) {
    const academicYearDoc = await this.ds.academicYearDocRef(key).get().toPromise();
    return MappingService.pushToObject(academicYearDoc);
  }

  @action
  async fetchConfigDoc() {
    const configDoc = await this.ds.sysSetting().get().toPromise();
    this.config = MappingService.pushToObject(configDoc);
  }

  @action
  async fetchEnvDoc(schoolKey) {
    const sysConfigDoc = await this.ds.environmentRef(schoolKey).get().toPromise();
    this.academicEnv = MappingService.pushToObject(sysConfigDoc);
  }

  @action
  async fetchSysOption(schoolKey) {
    const sysOptionDoc = await this.ds.sysOptionRef(schoolKey).get().toPromise();
    this.sysOption = MappingService.pushToObject(sysOptionDoc);
  }

  @action
  async fetchUserDoc() {
    const { uid } = this.auth.authRef().auth.currentUser;
    const userDoc: any = await this.ds.userDocRef(uid).get().toPromise();
    this.user = MappingService.pushToObject(userDoc);
    this.selectedCampus = this.user.campus;
    this.selectedSchool = this.user.school;
  }

  @action
  async fetchAcademicEnv(schoolKey) {
    const data = await this.ds.environmentRef(schoolKey).get().toPromise();
    return pushToObject(data);
  }

  @action
  async fetchAcademicYear(key) {
    const data = await this.ds.academicYearDocRef(key).get().toPromise();
    return pushToObject(data);
  }

  @action
  async fetchGeneral() {
    const doc = await this.ds.generalDocRef().get().toPromise();
    return pushToObject(doc);
  }

  @action
  async fetchShiftMovementDoc(key) {
    const doc = await this.ds.shiftMovementDocRef(key).get().toPromise();
    return pushToObject(doc);
  }


  @action
  async fetchReceivePaymentMovement(shiftKey: string) {
    const doc = await this.ds.paymentMovementShiftNullRef(shiftKey).get().toPromise();
    return pushToArray(doc);
  }

  @action
  async fetchUpdateStudentInvoice(items: Array<any>) {

    Promise.resolve(
      items.map(async m => {

        const { studentKey, invoiceKey, key, cashier } = m
        const dailyShift = cashier.shift

        const batch = this.ds.batch()
        const receivedPaymentRef = this.ds.receivedPaymentFireRef()
        const invoiceRef = this.ds.studentFireRef().doc(studentKey).collection("invoices")
        const invoicesDocs = await this.ds.studentDocument(studentKey).collection("invoices", ref => ref
          .where("headerRef", "==", invoiceKey)
        ).get().toPromise()
        const invoicesData = MappingService.pushToArray(invoicesDocs)

        if (invoicesData && invoicesData.length > 0) {

          invoicesData.map(inv => {
            batch.update(invoiceRef.doc(inv.key), { dailyShift: dailyShift })
          })
        }

        batch.update(receivedPaymentRef.doc(key), { shift: dailyShift })

        batch.commit().then(() => {
          console.log('Resolve Shift');
        });
      })
    )

  }

  @action
  async fetchUser(uid) {
    this.loading = true;
    this.dailyShift = null;
    this.sameDay = false;
    const userDoc = await this.ds.userRef(uid).get().toPromise();
    this.user = MappingService.pushToObject(userDoc);
    this.selectedCampus = this.user.campus;
    this.selectedSchool = this.user.school;

    if (this.user) {
      const { campus, balance, apps, shift } = this.user;
      if (!apps || apps.filter(m => m === appObjects.cashier.key).length === 0) {
        this.auth.authRef().auth.signOut().then(() => {
          this.loading = false;
          this.router.navigate(['/auth']);
        })
      }
      else {
        this.campus = campus;
        this.balance = balance;
        if (shift) {
          const shiftDoc = await this.ds.shiftMovementDocRef(shift.key).get().toPromise();
          this.dailyShift = MappingService.pushToObject(shiftDoc);
          const { create_date_key } = this.dailyShift;
          const dayKey = ConvertService.dateKey();
          if (dayKey == create_date_key) this.sameDay = true;
        }
        const configDoc = await this.ds.sysSetting().get().toPromise();
        const sysConfigDoc = await this.ds.environmentRef(this.selectedSchool.key).get().toPromise();
        this.config = MappingService.pushToObject(configDoc);
        const configData = MappingService.pushToObject(sysConfigDoc);
        if (configData) {
          const { abc_course, abc_course_fee, term, puc_generation, year } = configData;
          this.abc = abc_course;
          this.abcFee = abc_course_fee;
          this.term = term;
          this.puc_generation = puc_generation;
          this.academic = configData;
          this.selectedYear = year;
          this.loading = false;
        }
      }
    }
    else {
      this.auth.authRef().auth.signOut().then(() => {
        this.loading = false;
        this.router.navigate(['/auth']);
      })
    }
  }

  @action
  clearStore() {
    this.user = null;
    this.dailyShift = null;
    this.config = null;
    this.loading = false;
    this.academic = null;
    this.process = false;
    this.balance = null;
    this.abc = null;
    this.abcFee = null;
    this.term = null;
    this.campus = null;
    this.puc_generation = null;
    this.instituteTerm = null;
  }

  @action
  getUser(callback) {
    this.loading = true;
    this.user = null;
    const { uid } = this.auth.authRef().auth.currentUser;
    this.ds.userRef(uid).valueChanges().subscribe(doc => {
      this.loading = false;
      this.user = doc;
      callback(doc)
    });
  }

  @action
  async fetchDailyShift(cashierKey: string) {
    this.loading = true;
    this.dailyShift = null;
    this.sameDay = false;
    const userDoc = await this.ds.userRef(cashierKey).get().toPromise();
    this.user = MappingService.pushToObject(userDoc);
    if (this.user && this.user.shift) {
      const shiftDoc = await this.ds.shiftMovementDocRef(this.user.shift.key).get().toPromise();
      this.dailyShift = MappingService.pushToObject(shiftDoc);
      const { create_date_key } = this.dailyShift;
      const dayKey = ConvertService.dateKey();
      if (dayKey == create_date_key) this.sameDay = true;
    }

    this.loading = false;
  }

  @action
  async fetchConfig() {
    const configDocs = await this.ds.sysSetting().get().toPromise();
    this.config = MappingService.pushToObject(configDocs);
  }

  @action
  async fetchConfigToArray(callback) {
    const configDocs = await this.ds.sysSetting().get().toPromise();
    this.config = MappingService.pushToObject(configDocs);
    callback(this.config)
  }


  @action
  async fetchUserAccount(callback) {
    this.loading = true;
    this.sameDay = false;
    const key = this.authFire.auth.currentUser.uid;
    const userDoc = await this.ds.userDocRef(key).get().toPromise();
    this.user = MappingService.pushToObject(userDoc);

    if (this.user && this.user.shift) {
      const shiftDoc = await this.ds.shiftMovementDocRef(this.user.shift.key).get().toPromise();
      this.dailyShift = MappingService.pushToObject(shiftDoc);
      const { create_date_key } = this.dailyShift;
      const dayKey = ConvertService.dateKey();
      if (dayKey == create_date_key) this.sameDay = true;
    }

    const settingDoc = await this.ds.settingDBFireStore().get().toPromise();
    const setting = MappingService.pushToObject(settingDoc);
    this.config = setting;

    if (!userDoc.exists) {
      await this.authFire.auth.signOut().then(() => {
        this.loading = false;
        this.router.navigate(['/auth']);
      })
      return;
    }
    const { apps } = this.user;
    if (!apps || apps.filter(m => m === appObjects.cashier.key).length === 0) {
      await this.authFire.auth.signOut().then(() => {
        this.loading = false;
        this.router.navigate(['/auth']);
      })
      return;
    }
    callback(this.user)
  }

  @action
  update(item: IOptions, callback) {
    this.process = true;
    this.ds.generalDocRef().update({ ...item }).then(() => {
      this.process = false;
      callback(true, null);
    })
      .catch(error => {
        this.process = false;
        callback(false, error);
      });
  }

  @action
  async fetchCampusByRole(user: any) {
    const { schoolKey, campusKey, memberOf, isAllCampus } = user
    // const campusDoc: any = await this.ds.campusSchoolRef(schoolKey).get().toPromise();
    const campusDoc: any = await this.ds.storeDocRef(schoolKey).collection("campus", ref => ref.where("status.key", "==", 1)).get().toPromise();
    let campusData = MappingService.pushToArray(campusDoc);
    campusData = MappingService.orderBy(campusData, "order")
    if (memberOf.key > 0 && !isAllCampus)
      campusData = campusData.filter((m: any) => m.key === campusKey)
    return campusData;
  }


  @action
  async fetchCampusByRoleSchool(user: any, schoolKey) {
    const { campusKey, memberOf, isAdmin, isAllCampus } = user
    // const campusDoc: any = await this.ds.campusSchoolRef(schoolKey).get().toPromise();
    const campusDoc: any = await this.ds.storeDocRef(schoolKey).collection("campus").get().toPromise();
    let campusData = MappingService.pushToArray(campusDoc);
    campusData = MappingService.orderBy(campusData, "order")
    if (memberOf.key > 0 && !isAllCampus && !isAdmin)
      campusData = campusData.filter((m: any) => m.key === campusKey)
    return campusData;
  }

}
