import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AbstractControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { PrintService } from 'src/app/services/print.service';
import { ConvertService } from 'src/app/services/convert.service';
import { Environment } from 'src/app/stores/environment.store';
import { Report } from 'src/app/stores/report.store';
import { MappingService } from 'src/app/services/mapping.service';
import { map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material';
import { SCHOOL } from 'src/app/dummy/config';

@Component({
  selector: 'app-daily-shift-payment-listing',
  templateUrl: './daily-shift-payment-listing.component.html',
  styleUrls: ['./daily-shift-payment-listing.component.scss']
})
export class DailyShiftPaymentListingComponent implements OnInit {

  form: FormGroup;
  realQty100000: AbstractControl;
  realQty50000: AbstractControl;
  realQty30000: AbstractControl;
  realQty20000: AbstractControl;
  realQty10000: AbstractControl;
  realQty5000: AbstractControl;
  realQty2000: AbstractControl;
  realQty1000: AbstractControl;
  realQty500: AbstractControl;
  realQty100: AbstractControl;

  dollarQty100: AbstractControl;
  dollarQty50: AbstractControl;
  dollarQty20: AbstractControl;
  dollarQty10: AbstractControl;
  dollarQty5: AbstractControl;
  dollarQty1: AbstractControl;
  checkQty: AbstractControl;
  school = SCHOOL

  add_no_amount: AbstractControl;

  totalRiel: number;
  totalRielToDollar: number;
  totalDollar: number;
  grandTotalDollar: number;

  addNoTotal: number;

  moneyRiels = [];
  moneyDollars = [];

  shiftDate;
  exchange_rate;

  config;

  SHIFT_DATA = [];
  constructor(
    private fb: FormBuilder,
    private ps: PrintService,
    public env: Environment,
    public store: Report,
    private snackBar: MatSnackBar,
  ) { }

  print() {
    this.ps.print("#print", "a4");
  }

  async ngOnInit() {
    this.buildForm();

    this.config = await this.env.fetchGeneral()
    if (this.config && this.config.dollar_exchange)
      this.exchange_rate = this.config.dollar_exchange || 4000;

    this.env.fetchConfigToArray(async config => {
      this.env.getUser(async user => {
        if (user && user.shift) {

          // UPDATE SHIFT NULL
          this.SHIFT_DATA = await this.env.fetchReceivePaymentMovement(user.shift.key)
          if (this.SHIFT_DATA && this.SHIFT_DATA.length > 0) {
            this.env.fetchUpdateStudentInvoice(this.SHIFT_DATA)
          }

          const shiftData: any = await this.env.fetchShiftMovementDoc(user.shift.key)

          this.shiftDate = user.shift.create_date.toDate()
          this.store.fetchReceiptByTerm(user.shift.key);
          this.store.fetchDialShiftCashReceiveRiels(user.shift.key, (riels) => {
            this.store.fetchDialShiftCashReceiveDollars(user.shift.key, (dollars) => {

              if (this.store.moneyRiels.length > 0 && this.store.moneyDollars.length > 0) {
                this.moneyRiels = this.store.moneyRiels;
                this.moneyDollars = this.store.moneyDollars;
              } else {
                this.moneyRiels = this.moneyRielsData;
                this.moneyDollars = this.moneyDollarsData;
              }

              this.form.patchValue({
                realQty100000: this.moneyRiels.filter(m => m.key === "realQty100000")[0].multi,
                realQty50000: this.moneyRiels.filter(m => m.key === "realQty50000")[0].multi,
                realQty30000: this.moneyRiels.filter(m => m.key === "realQty30000")[0].multi,
                realQty20000: this.moneyRiels.filter(m => m.key === "realQty20000")[0].multi,
                realQty10000: this.moneyRiels.filter(m => m.key === "realQty10000")[0].multi,
                realQty5000: this.moneyRiels.filter(m => m.key === "realQty5000")[0].multi,
                realQty2000: this.moneyRiels.filter(m => m.key === "realQty2000")[0].multi,
                realQty1000: this.moneyRiels.filter(m => m.key === "realQty1000")[0].multi,
                realQty500: this.moneyRiels.filter(m => m.key === "realQty500")[0].multi,
                realQty100: this.moneyRiels.filter(m => m.key === "realQty100")[0].multi,

                dollarQty100: this.moneyDollars.filter(m => m.key === "dollarQty100")[0].multi,
                dollarQty50: this.moneyDollars.filter(m => m.key === "dollarQty50")[0].multi,
                dollarQty20: this.moneyDollars.filter(m => m.key === "dollarQty20")[0].multi,
                dollarQty10: this.moneyDollars.filter(m => m.key === "dollarQty10")[0].multi,
                dollarQty5: this.moneyDollars.filter(m => m.key === "dollarQty5")[0].multi,
                dollarQty1: this.moneyDollars.filter(m => m.key === "dollarQty1")[0].multi,
                checkQty: this.moneyDollars.filter(m => m.key === "checkQty")[0].multi,

                add_no_amount: shiftData && shiftData.add_no_amount ? shiftData.add_no_amount : 0
              })

            })
          })
        }
      })
    })
  }

  moneyRielsData = [
    { key: 'realQty100000', operant: 100000, total: 0, multi: 0 },
    { key: 'realQty50000', operant: 50000, total: 0, multi: 0 },
    { key: 'realQty30000', operant: 30000, total: 0, multi: 0 },
    { key: 'realQty20000', operant: 20000, total: 0, multi: 0 },
    { key: 'realQty10000', operant: 10000, total: 0, multi: 0 },
    { key: 'realQty5000', operant: 5000, total: 0, multi: 0 },
    { key: 'realQty2000', operant: 2000, total: 0, multi: 0 },
    { key: 'realQty1000', operant: 1000, total: 0, multi: 0 },
    { key: 'realQty500', operant: 500, total: 0, multi: 0 },
    { key: 'realQty100', operant: 100, total: 0, multi: 0 },
  ];

  moneyDollarsData = [
    { key: 'dollarQty100', operant: 100, total: 0, multi: 0 },
    { key: 'dollarQty50', operant: 50, total: 0, multi: 0 },
    { key: 'dollarQty20', operant: 20, total: 0, multi: 0 },
    { key: 'dollarQty10', operant: 10, total: 0, multi: 0 },
    { key: 'dollarQty5', operant: 5, total: 0, multi: 0 },
    { key: 'dollarQty1', operant: 1, total: 0, multi: 0 },
    { key: 'checkQty', operant: 1, total: 0, multi: 0 },
  ];

  sumMoney() {
    this.totalRiel = MappingService.sum(this.moneyRiels, "total");
    this.totalRielToDollar = ConvertService.toFloatFixed2((this.totalRiel / this.exchange_rate));
    this.totalDollar = MappingService.sum(this.moneyDollars, "total");
    this.grandTotalDollar = ConvertService.toFloatFixed2(ConvertService.toNumber(this.totalRielToDollar) + ConvertService.toNumber(this.totalDollar)) + ConvertService.toNumber(this.addNoTotal);
  }

  buildForm(): void {
    this.form = this.fb.group({
      realQty100000: [null, Validators.compose([Validators.required]), this.totalReal("realQty100000", "riel")],
      realQty50000: [null, Validators.compose([Validators.required]), this.totalReal("realQty50000", "riel")],
      realQty30000: [null, Validators.compose([Validators.required]), this.totalReal("realQty30000", "riel")],
      realQty20000: [null, Validators.compose([Validators.required]), this.totalReal("realQty20000", "riel")],
      realQty10000: [null, Validators.compose([Validators.required]), this.totalReal("realQty10000", "riel")],
      realQty5000: [null, Validators.compose([Validators.required]), this.totalReal("realQty5000", "riel")],
      realQty2000: [null, Validators.compose([Validators.required]), this.totalReal("realQty2000", "riel")],
      realQty1000: [null, Validators.compose([Validators.required]), this.totalReal("realQty1000", "riel")],
      realQty500: [null, Validators.compose([Validators.required]), this.totalReal("realQty500", "riel")],
      realQty100: [null, Validators.compose([Validators.required]), this.totalReal("realQty100", "riel")],

      dollarQty100: [null, Validators.compose([Validators.required]), this.totalReal("dollarQty100", "dollar")],
      dollarQty50: [null, Validators.compose([Validators.required]), this.totalReal("dollarQty50", "dollar")],
      dollarQty20: [null, Validators.compose([Validators.required]), this.totalReal("dollarQty20", "dollar")],
      dollarQty10: [null, Validators.compose([Validators.required]), this.totalReal("dollarQty10", "dollar")],
      dollarQty5: [null, Validators.compose([Validators.required]), this.totalReal("dollarQty5", "dollar")],
      dollarQty1: [null, Validators.compose([Validators.required]), this.totalReal("dollarQty1", "dollar")],
      checkQty: [null, Validators.compose([Validators.required]), this.totalReal("checkQty", "dollar")],
      add_no_amount: [null, Validators.compose([Validators.required]), this.onAddNoAmount()],
    });
    this.realQty100000 = this.form.controls["realQty100000"];
    this.realQty50000 = this.form.controls["realQty50000"];
    this.realQty30000 = this.form.controls["realQty30000"];
    this.realQty20000 = this.form.controls["realQty20000"];
    this.realQty10000 = this.form.controls["realQty10000"];
    this.realQty5000 = this.form.controls["realQty5000"];
    this.realQty2000 = this.form.controls["realQty2000"];
    this.realQty1000 = this.form.controls["realQty1000"];
    this.realQty500 = this.form.controls["realQty500"];
    this.realQty100 = this.form.controls["realQty100"];

    this.dollarQty100 = this.form.controls["dollarQty100"];
    this.dollarQty50 = this.form.controls["dollarQty50"];
    this.dollarQty20 = this.form.controls["dollarQty20"];
    this.dollarQty10 = this.form.controls["dollarQty10"];
    this.dollarQty5 = this.form.controls["dollarQty5"];
    this.dollarQty1 = this.form.controls["dollarQty1"];
    this.checkQty = this.form.controls["checkQty"];

    this.add_no_amount = this.form.controls["add_no_amount"];

    this.form.patchValue({
      realQty100000: 0,
      realQty50000: 0,
      realQty30000: 0,
      realQty20000: 0,
      realQty10000: 0,
      realQty5000: 0,
      realQty2000: 0,
      realQty1000: 0,
      realQty500: 0,
      realQty100: 0,

      dollarQty100: 0,
      dollarQty50: 0,
      dollarQty20: 0,
      dollarQty10: 0,
      dollarQty5: 0,
      dollarQty1: 0,
      checkQty: 0,

      add_no_amount: 0,
    })
  }

  onAddNoAmount() {
    return (control: AbstractControl) => {
      if (control !== null && control !== undefined) {
        return control.valueChanges.pipe(
          map(arr => {
            this.addNoTotal = ConvertService.toNumber(arr)
            const addNoConfig = this.config && this.config.add_no_config || 0.5
            if (this.addNoTotal > addNoConfig) this.addNoTotal = 0;
            this.sumMoney();
            return null;
          })
        );
      }
    };
  }

  totalReal(controlName, type) {
    return (control: AbstractControl) => {
      if (control !== null && control !== undefined) {
        return control.valueChanges.pipe(
          map(arr => {
            if (type === "riel") {
              this.moneyRiels.forEach(r => {
                if (r.key === controlName) {
                  r.total = ConvertService.toNumber(arr) * r.operant;
                  r.multi = ConvertService.toNumber(arr)
                }
              })
            } else if (type === "dollar") {
              this.moneyDollars.forEach(r => {
                if (r.key === controlName) {
                  r.total = ConvertService.toNumber(arr) * r.operant;
                  r.multi = ConvertService.toNumber(arr)
                }
              })
            }
            this.sumMoney();
            return null;
          })
        );
      }
    };
  }

  _saveReport() {
    if (this.store.totalAmount === this.grandTotalDollar) {
      this.store.saveReceiptAllCash(this.env.user.shift.key, this.grandTotalDollar, this.add_no_amount.value, this.exchange_rate, this.moneyRiels, this.moneyDollars, (success, error) => {
        if (success) {
          this.snackBar.open("Receive cash have been saved", "done", {
            duration: 2000
          });
        } else {
          this.snackBar.open(error, "Error");
        }
      })
    };
  }

}
