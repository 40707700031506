import { TestingStatus, Genders, TermStatus, paymentStatus, recordStatus } from '../../dummy/status';
import { ConvertService } from './../../services/convert.service';
import { ITesting } from './../../interfaces/testing';
import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Institute } from "../../stores/institute.store";
import {
  FormGroup,
  AbstractControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { slideInOutAnimation } from "../../services/slide-io.animation";
import { Environment } from "../../stores/environment.store";
import { academicYearObj, MappingService } from "../../services/mapping.service";
import { MatSnackBar, MatDialog } from "@angular/material";
import { Testing } from 'src/app/stores/testing.store';
import { AddTestingPaymentComponent } from '../add-testing-payment/add-testing-payment.component';
import { PrintService } from 'src/app/services/print.service';
import { Payment } from 'src/app/stores/payment.store';
import { InvoiceDialogComponent } from '../invoice-dialog/invoice-dialog.component';

@Component({
  selector: 'app-add-new-testing',
  templateUrl: './add-new-testing.component.html',
  styleUrls: ['./add-new-testing.component.scss'],
  host: { "[@slideInOutAnimation]": "" },
  animations: [slideInOutAnimation],
})
export class AddNewTestingComponent implements OnInit {
  @ViewChild("focusInput") inputEl: ElementRef;
  genderList = Genders;
  form: FormGroup;
  unique_code: AbstractControl;
  first_name: AbstractControl;
  last_name: AbstractControl;
  khmer_first_name: AbstractControl;
  khmer_last_name: AbstractControl;
  address: AbstractControl;
  gender: AbstractControl;
  dob: AbstractControl;
  mobile_phone: AbstractControl;
  email_address: AbstractControl;
  note: AbstractControl;
  admission_date: AbstractControl;
  year: AbstractControl;
  grade: AbstractControl;

  program: AbstractControl;
  study_session: AbstractControl;
  target_campus: AbstractControl;
  target_term: AbstractControl;

  termList = null;
  campusList = null;
  filteredStates = null;
  sessionsList = null;
  testData = null;
  testExist = null;
  testingForm: any;
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    public env: Environment,
    public store: Institute,
    public test: Testing,
    public dialog: MatDialog,
    private ps: PrintService,
    public payment: Payment,
  ) { }

  ngOnInit() {
    this.buildForm();
  }

  compareByKey(f1: any, f2: any) {
    return f1 && f2 && f1.key === f2.key;
  }

  buildForm(): void {
    this.form = this.fb.group({
      unique_code: [null],
      first_name: [null, [Validators.required]],
      last_name: [null, [Validators.required]],
      khmer_first_name: [null],
      khmer_last_name: [null],
      gender: [this.genderList[0], [Validators.required]],
      dob: [MappingService.age18(), [Validators.required]],
      mobile_phone: [null,],
      email_address: [null],
      note: [null],
      address: [null],
      program: [null, Validators.compose([Validators.required, MappingService.validSelected.bind(this)])],
      admission_date: [new Date(), Validators.compose([Validators.required])],
      study_session: [null, Validators.required],
      target_campus: [null, Validators.required],
      target_term: [null,]
    });
    this.target_campus = this.form.controls['target_campus'];
    this.study_session = this.form.controls['study_session'];
    this.unique_code = this.form.controls["unique_code"];
    this.first_name = this.form.controls["first_name"];
    this.last_name = this.form.controls["last_name"];
    this.khmer_first_name = this.form.controls["khmer_first_name"];
    this.khmer_last_name = this.form.controls["khmer_last_name"];
    this.gender = this.form.controls["gender"];
    this.dob = this.form.controls["dob"];
    this.mobile_phone = this.form.controls["mobile_phone"];
    this.email_address = this.form.controls["email_address"];
    this.note = this.form.controls["note"];
    this.address = this.form.controls["address"];
    this.program = this.form.controls["program"];
    this.admission_date = this.form.controls['admission_date'];
    this.target_term = this.form.controls["target_term"]

    this.store.fetchAllProgram(list => {
      this.filteredStates = list;
      if (list.length > 0) {
        this.form.patchValue({
          program: list[0]
        })
        const { institute, testingFee } = list[0];
        if (!institute) return;
        this.store.fetchTermInstitute(institute.key, res => {
          this.termList = res;
          if (res.length > 0) {
            this.form.patchValue({
              target_term: res[0]
            })
          }
        })
      }
    })
    this.store.fetchShift(res => {
      this.sessionsList = res;
      if (res.length > 0) {
        this.form.patchValue({
          study_session: res[0]
        })
      }
    })
    this.store.fetchCampus(res => {
      this.campusList = res;
      if (res.length > 0) {
        this.form.patchValue({
          target_campus: res[0]
        })
      }
    })

  }

  _onSelectedProgram(event) {
    const { institute } = event.value;
    if (!institute) return;
    this.store.fetchTermInstitute(institute.key, res => {
      this.termList = res;
      if (res.length > 0) {
        this.form.patchValue({
          target_term: res[0]
        })
      }
    })
  }

  _goBack() {
    this.router.navigate(['/']);
  }

  resetForm() {
    this.testData = null;
    this.testExist = null;
    this.form.reset();
    this.buildForm();
    this.inputEl.nativeElement.focus();
    this.test.testingExist = false;
  }

  create(f: any) {
    if (this.form.valid && this.env.user && this.env.config) {
      this.testExist = null;
      const { program } = f;
      const form: ITesting = {
        key: null,
        unique_code: f.unique_code,
        serial_id: null,
        first_name: f.first_name ? f.first_name.toUpperCase() : null,
        last_name: f.last_name ? f.last_name.toUpperCase() : null,
        display_name: f.first_name ? f.first_name.toUpperCase() : null + f.last_name ? f.last_name.toUpperCase() : null,
        full_name: f.first_name ? f.first_name.toUpperCase() : null + f.last_name ? f.last_name.toUpperCase() : null,
        khmer_first_name: f.khmer_first_name ? f.khmer_first_name.toUpperCase() : null,
        khmer_last_name: f.khmer_last_name ? f.khmer_last_name.toUpperCase() : null,
        address: f.address,
        gender: f.gender,
        dob: f.dob,
        puc_id: null,
        dobKey: ConvertService.toDateKey(f.dob),
        page_key: ConvertService.pageKey(),
        mobile_phone: f.mobile_phone,
        email_address: f.email_address,
        institute: MappingService.instituteObj(program.institute),
        test_type: program,
        study_session: {
          key: MappingService.toNull(f.study_session.key),
          name: MappingService.toNull(f.study_session.name),
          paymentRate: MappingService.toNull(f.study_session.paymentRate)
        },
        target_term: academicYearObj(f.target_term),
        target_campus: MappingService.campusObj(f.target_campus),
        admission_date: f.admission_date,
        admission_date_key: ConvertService.toDateKey(f.admission_date),
        isAssignProgram: true,
        note: f.note,
        status: recordStatus.active,
        testingStatus: TestingStatus.active,
        isPaid: paymentStatus.unpaid,
        isPaidTest: false,
        create_date: new Date(),
        create_date_key: ConvertService.dateKey(),
        create_by: MappingService.userObj(this.env.user),
        branch: MappingService.campusObj(this.env.user.campus),
        admission_term: academicYearObj(f.target_term),
        student_id: null,
        student_puc_profile: null,
        admission: null,
        config: null,
        expired_fee: null,
        expired_fee_key: null,
        expired_test: null,
        expired_test_key: null,
        testing_result: null,
        isReTakeTest: false
      };
      this.testingForm = form;

      this.test.checkExistTest(this.testingForm, (success, res) => {
        if (success) {
          this.testExist = res;
        } else {
          this.registerTest();
        }
      })
    }
  }

  registerTest() {
    this.testData = null;
    if (this.testingForm === null) return;
    this.form.disable();
    this.test.register(this.testingForm, this.env.user, this.env.config.testing_fee, (success, res) => {
      if (success) {
        this.testData = res;
        this.testExist = null;
        const { test_type, target_term, target_campus, study_session } = this.testData;
        this.form.reset();
        this.form.patchValue({
          program: test_type,
          target_term: target_term,
          target_campus: target_campus,
          study_session: study_session,
          admission_date: new Date(),
          dob: MappingService.age18(),
          gender: this.genderList[0]
        })
        this.snackBar.open(
          "Testing has been created successfully.",
          "done",
          { duration: 2000 }
        );
        this._onReceivePayment(this.testData);
        this.form.enable();

      } else {
        this.snackBar.open(
          res,
          "Error"
        );
        this.form.enable();
      }
    });
  }

  retakeTest() {
    this.testData = null;
    if (this.testingForm === null) return;
    this.form.disable();
    this.test.retakeTest(this.testingForm, this.env.user, this.testExist.student, this.env.config.testing_fee, (success, res) => {
      if (success) {
        this.testData = res;
        this.testExist = null;
        const { test_type, target_term, target_campus, study_session } = this.testData;
        this.form.reset();
        this.form.patchValue({
          program: test_type,
          target_term: target_term,
          target_campus: target_campus,
          study_session: study_session,
          admission_date: new Date(),
          dob: MappingService.age18(),
          gender: this.genderList[0]
        })
        this.snackBar.open(
          "Testing has been created successfully.",
          "done",
          { duration: 2000 }
        );
        this._onReceivePayment(this.testData);
        this.form.enable();
      } else {
        this.snackBar.open(
          res,
          "Error"
        );
        this.form.enable();
      }
    });
  }

  print() {
    this.ps.print("#printD", "a5l");
  }

  _onPrint(item) {
    let dialogRef = this.dialog.open(InvoiceDialogComponent, {
      data: { studentKey: item.student.key, headerKey: item.headerRef },
      width: '90vw',
      height: '80vh',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  _onReceivePayment(item) {
    if (item.isPaidTest) return;
    let dialogRef = this.dialog.open(AddTestingPaymentComponent, {
      data: item,
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: true,
      role: "dialog"
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== "no") {
        this._onPrint(result)
      }
    });
  }

}
