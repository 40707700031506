import { ConvertService } from './../../../services/convert.service';
import { DataService } from 'src/app/services/data.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Environment } from './../../../stores/environment.store';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatDialog } from '@angular/material';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { BaseStore } from 'src/app/stores/base.store';
import { MappingService, feeObj, gradeObj, academicYearObj, pushToArray, pushObjArray } from 'src/app/services/mapping.service';
import { ITrainingGrade, ITrainingGradeFee } from 'src/app/interfaces/trainingGrade';
import { status, recordStatus, PAYMENT_OPTIONS } from 'src/app/dummy/status';
import { checkExistDoc } from 'src/app/services/fire-validators.service';
import { YearTraining } from 'src/app/stores/yearTraining.store';
import { DeleteComponent } from 'src/app/components/delete/delete.component';

@Component({
  selector: 'app-training-grade-payment-option',
  templateUrl: './training-grade-payment-option.component.html',
  styleUrls: ['./training-grade-payment-option.component.scss']
})
export class TrainingGradePaymentOptionComponent implements OnInit {
  @ViewChild("focusInput") inputEl: ElementRef;
  form: FormGroup;
  paymentOption: AbstractControl;
  price: AbstractControl;

  paymentOptionList = PAYMENT_OPTIONS;

  constructor(
    public dialogRef: MatDialogRef<TrainingGradePaymentOptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public env: Environment,
    private snackBar: MatSnackBar,
    public base: BaseStore,
    private afs: AngularFirestore,
    private ds: DataService,
    public store: YearTraining,
    private dialog: MatDialog,
  ) { }

  buildForm(): void {
    this.form = this.fb.group({
      paymentOption: [this.paymentOptionList[0], Validators.required],
      price: [null, Validators.required],
    })
    this.paymentOption = this.form.controls['paymentOption'];
    this.price = this.form.controls['price'];
  }

  async ngOnInit() {
    this.buildForm();
    await this.env.fetchUserDoc()
    const {selectedSchool} = this.env
    const envData: any = await this.env.fetchAcademicEnv(selectedSchool.key);
    const { year } = envData;
    this.store.fetchTrainingFee(year.key, this.data.key);

  }

  compareObjects(o1: any, o2: any): boolean {
    if (o2) return o1.key === o2.key;
  }

  create(f: any, isNew) {
    if (this.form.valid) {
      this.form.disable();
      const { paymentOption, price } = f;
      const data = {
        key: paymentOption.key,
        text: paymentOption.text,
        month: paymentOption.month,
        price: price,
      }
      const priceListData = pushObjArray(this.store.priceList, data)
      const key = this.afs.createId();
      const item: ITrainingGradeFee = {
        key: key,
        trainingGradeKey: this.data.key,
        grade: gradeObj(this.data.grade),
        gradeNext: gradeObj(this.data.gradeNext),
        priceList: priceListData,
        academicYear: academicYearObj(this.data.academicYear),
        status: recordStatus.active,
        create_date: new Date(),
        create_date_key: ConvertService.toDateKey(new Date()),
        create_by: this.env.user,
        pageKey: ConvertService.pageKey(),
      };

      this.store.setFeeTrainingGrade(item, (success, error) => {
        if (success) {
          if (!isNew) {
            this.dialogRef.close();
          }
          this.snackBar.open('Add Training Fee has been created.', 'done', { duration: 2500 });
          this.form.enable();
          this.form.reset();
          this.form.patchValue({
            paymentOption: paymentOption,
          })
        }
        else {
          alert(error)
        }
      })
    }
  }

  delete(item: any) {
    if (item.fee) return;
    let dialogRef = this.dialog.open(DeleteComponent, {
      data: { title: 'Delete Payment Option', memo: 'If Payment Option is using by other function in system you cannot delete it.', name: item.text },
      width: '35vw',
      disableClose: true,
      role: 'dialog',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'yes') {
        this.store.removePaymentOption(item, this.store.trainingFee, (success, error) => {
          if (success) {
            this.snackBar.open('Payment Option has been deleted.', 'done', { duration: 2000 });
          }
          else {
            this.snackBar.open(error, 'Error')
          }
        })
      }
    });
  }

}
