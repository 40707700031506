import { Component, OnInit, Input } from '@angular/core';
import { SCHOOL } from 'src/app/dummy/config';
import { invoiceTypesObj } from 'src/app/dummy/status';

@Component({
  selector: 'app-invoice-template',
  templateUrl: './invoice-template.component.html',
  styleUrls: ['./invoice-template.component.scss']
})
export class InvoiceTemplateComponent implements OnInit {
  @Input() invoice: any;
  @Input() user: any;
  paymentDate=new Date();
  paymentType = invoiceTypesObj;
  school=SCHOOL

  constructor(
  ) { }

  ngOnInit() {

  }
}
