import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  FormGroup,
  AbstractControl,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material";
import { Environment } from "src/app/stores/environment.store";
import { Student } from "src/app/stores/student.store";
import { ConvertService } from "src/app/services/convert.service";
import { MappingService } from "src/app/services/mapping.service";
import { DataService } from "src/app/services/data.service";
import { Nationality, PARENTS_STATUS, STUDENT_STAY_WITH, Gender } from "src/app/dummy/status";
import { AngularFirestore } from "@angular/fire/firestore";
import { FireValidatorsService } from "src/app/services/fire-validators.service";

@Component({
  selector: 'app-personal',
  templateUrl: './personal.component.html',
  styleUrls: ['./personal.component.scss']
})
export class PersonalComponent implements OnInit {
  @ViewChild("focusInput") inputEl: ElementRef;
  id: string;
  admissionKey: string;

  form: FormGroup;
  puc_id: AbstractControl;
  change_profile: AbstractControl;
  unique_code: AbstractControl;
  first_name: AbstractControl;
  last_name: AbstractControl;
  khmer_first_name: AbstractControl;
  khmer_last_name: AbstractControl;
  address: AbstractControl;
  gender: AbstractControl;
  dob: AbstractControl;
  pob: AbstractControl;
  marital_status: AbstractControl;
  nationality: AbstractControl;
  mobile_phone: AbstractControl;
  mobile_phone2: AbstractControl;
  email_address: AbstractControl;
  work_place: AbstractControl;
  position: AbstractControl;
  parent_name: AbstractControl;
  spouse_name: AbstractControl;
  country: AbstractControl;
  note: AbstractControl;

  nationalityList = Nationality;
  parentStatusList = PARENTS_STATUS;
  stayWithList = STUDENT_STAY_WITH;
  genderList = Gender;

  ITEM: any = null;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    public env: Environment,
    private route: ActivatedRoute,
    public store: Student,
    public ds: DataService,
    private afs: AngularFirestore,
  ) { }

  buildForm(): void {
    this.form = this.fb.group({
      puc_id: [null, Validators.compose([Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(7), Validators.maxLength(7)]), this.checkExistRef.bind(this)],
      change_profile: [true, [Validators.required]],
      unique_code: [null],
      first_name: [null, [Validators.required]],
      last_name: [null, [Validators.required]],
      khmer_first_name: [null,],
      khmer_last_name: [null,],
      dob: [MappingService.age18(),],
      pob: [null,],
      gender: [this.genderList[0], [Validators.required]],
      nationality: [this.nationalityList[0], Validators.required],
      address: [null],
      mobile_phone: [null,],
      email_address: [null],
      note: [null],
    });

    this.puc_id = this.form.controls["puc_id"];
    this.change_profile = this.form.controls["change_profile"];
    this.unique_code = this.form.controls["unique_code"];
    this.first_name = this.form.controls["first_name"];
    this.last_name = this.form.controls["last_name"];
    this.khmer_first_name = this.form.controls["khmer_first_name"];
    this.khmer_last_name = this.form.controls["khmer_last_name"];
    this.dob = this.form.controls["dob"];
    this.pob = this.form.controls["pob"];
    this.gender = this.form.controls["gender"];
    this.nationality = this.form.controls["nationality"];
    this.address = this.form.controls["address"];
    this.mobile_phone = this.form.controls["mobile_phone"];
    this.email_address = this.form.controls["email_address"];
    this.note = this.form.controls["note"];
  }

  compareObjects(o1: any, o2: any): boolean {
    if (o2) { return o1.key === o2.key; }
  }

  async ngOnInit() {
    this.buildForm();
    this.route.parent.params.forEach(async (param) => {
      this.id = param["id"];
      this.admissionKey = param["admissionKey"];
      const studentData = await this.store.fetchStudentDoc(this.id);
      this.store.student = studentData;
      this.ITEM = studentData

      if (studentData) {
        const { unique_code, first_name, last_name, khmer_first_name, khmer_last_name, dob, pob, gender, nationality,
          address, mobile_phone, email_address, note, change_profile, puc_id
        } = studentData;

        const genderData = this.genderList.filter(m => m.key === ConvertService.toNumber(gender.key))[0]
        this.form.patchValue({
          puc_id: puc_id,
          change_profile: change_profile ? change_profile : true,
          unique_code: unique_code,
          first_name: first_name,
          last_name: last_name,
          khmer_first_name: khmer_first_name,
          khmer_last_name: khmer_last_name,
          dob: dob ? dob.toDate() : null,
          pob: pob,
          gender: gender ? genderData : this.genderList[0],
          nationality: nationality ? nationality : this.nationalityList[0],
          address: address,
          mobile_phone: mobile_phone,
          email_address: email_address,
          note: note,
        })
      }
    })
  }

  async checkExistRef(control: AbstractControl) {
    if (control) {
      const { value } = control
      if (!value) return null;
      if (this.ITEM) {
        const { puc_id } = this.ITEM;
        if (puc_id !== value) {
          const doc = await this.ds.checkExistStudentRef(value).get().toPromise();
          if (doc.empty) return null;
          return { nameAvailable: true }
        }
        return null;
      }
      else {//when draft
        const doc = await this.ds.checkExistStudentRef(value).get().toPromise();
        if (doc.empty) return null;
        return { nameAvailable: true }
      }
    }
  }

  displayItem(item: any): string {
    return item ? item.name : null;
  }

  _goBack() {
    // if (this.type) {
    //   this.router.navigate(['/app/institute/' + this.key + '/' + this.type]);
    // }
    // else if (this.id) {
    //   this.router.navigate(['/student/' + this.id + '/personal']);
    // }
    // else {
    //   this.router.navigate(['/']);
    // }
  }

  create(f: any) {
    if (this.form.valid) {
      this.form.disable();

      const { unique_code, first_name, last_name, khmer_first_name, khmer_last_name, dob, pob, gender,
        nationality, address, mobile_phone, email_address, note, change_profile, puc_id } = f;

      const item = {
        puc_id,
        change_profile,
        unique_code: unique_code,
        first_name: first_name.toUpperCase(),
        last_name: last_name.toUpperCase(),
        full_name: `${last_name.toUpperCase()} ${first_name.toUpperCase()}`,
        khmer_first_name: khmer_first_name,
        khmer_last_name: khmer_last_name,
        dob: dob,
        dobKey: ConvertService.toDateKey(dob),
        pob: pob,
        gender: gender,
        nationality: nationality,
        address: address,
        mobile_phone: mobile_phone,
        email_address: email_address,
        note: ConvertService.toNull(note),
        update_by: this.env.user,
        update_date_key: ConvertService.dateKey(),
        update_date: new Date(),
      }

      this.store.updateStudentProfile(this.store.student, item, (success, res) => {
        if (success) {
          this.snackBar.open("Student have been updated successfully.", "done", { duration: 2000 });
          this.form.enable();
          this.router.navigate([`student/${this.id}/${this.admissionKey}/receive-payment`])
        } else {
          this.snackBar.open(res, "Error");
          this.form.enable();
        }
      });
    }
  }

}