import { invoiceTypesObj, enrollPrograms, paymentStatus, EnrollStatus, Status, recordStatus, scholarshipTypes } from 'src/app/dummy/status';
import { ConvertService } from 'src/app/services/convert.service';
import { MatSnackBar } from '@angular/material';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Student } from 'src/app/stores/student.store';
import { Payment } from 'src/app/stores/payment.store';
import { Environment } from 'src/app/stores/environment.store';
import { MappingService } from 'src/app/services/mapping.service';
import { DataService } from 'src/app/services/data.service';
import { Scholarship } from 'src/app/interfaces/scholarship';

@Component({
  selector: 'app-add-discount-invoice',
  templateUrl: './add-discount-invoice.component.html',
  styleUrls: ['./add-discount-invoice.component.scss']
})
export class AddDiscountInvoiceComponent implements OnInit {
  form: FormGroup;
  discount: AbstractControl;

  dataLists = []
  constructor(
    public dialogRef: MatDialogRef<AddDiscountInvoiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    public store: Payment,
    public ds: DataService,
    public env: Environment,
    public student: Student
  ) { }

  buildForm(): void {
    this.form = this.fb.group({
      discount: [null, Validators.required],
    })
    this.discount = this.form.controls['discount'];
  }

  ngOnInit() {
    this.buildForm();
    this.env.fetchConfigToArray(res=>{
      this.dataLists.push({name: "Monk Scholarship", price: res.monk_scholarship});
    })
  }

  create(f: any) {
    if(this.student.student.scholarship) return;
    if (this.form.valid && f.discount.length > 0) {
      this.form.disable();
      const item: Scholarship = {
        key: this.ds.createId(),
        create_by: MappingService.userObj(this.env.user),
        create_date: new Date(),
        student: MappingService.studentObj(this.student.student),
        percentage: this.env.config.monk_scholarship,
        cash: 0,
        date_key: ConvertService.dateKey(),
        status: recordStatus.active,
        program: null,
        sibling: null,
        scholarshipType: scholarshipTypes.monk,
        page_key: ConvertService.pageKey()
      }

      this.student.addMonkScholarship(item,(success,error)=>{
        if(success){
          this.snackBar.open("Discount has been Assigned to this student.","Done",{duration:2000})
          this.dialogRef.close('yes');
          this.form.enable();
        }
        else{
          this.snackBar.open(error, "Error");
          this.form.enable();
        }
      })
    }
  }
}