import { DataService } from 'src/app/services/data.service';
import { AngularFirestoreCollection } from '@angular/fire/firestore';
import { observable, action } from "mobx";
import { Injectable } from "@angular/core";
import { pushToArray, MappingService } from '../services/mapping.service';

@Injectable()
export class BaseStore {
  @observable data = [];
  @observable loading = true;
  @observable empty = false;
  @observable process = false;

  constructor(public ds: DataService) { }

  @action
  fetchAcademicYearToArray(schoolKey,typeKey, callback) {
    this.process = true;
    this.ds.academicYearByTypeRef(schoolKey,typeKey).valueChanges().subscribe(docs => {
      this.process = false;
      callback(MappingService.orderByDesc(docs, "startDate"))
    });
  }

  async fetchList(ref: any) {
    this.process = true;
    const docs = await ref.get().toPromise();
    const data = MappingService.pushToArray(docs)
    this.process = false;
    return data;
  }

  async fetchDoc(ref: any) {
    this.process = true;
    const doc = await ref.get().toPromise();
    const data = MappingService.pushToObject(doc)
    this.process = false;
    return data;
  }

  @action
  async fetchCampus() {
    this.process = true;
    const docs = await this.ds.campusRef().get().toPromise();
    this.process = false;
    return pushToArray(docs)
  }

  @action
  async fetchShift() {
    const docs = await this.ds.shiftDBRef().get().toPromise();
    return MappingService.orderBy(pushToArray(docs), "order");
  }

  @action
  async fetchTrainingProgram(campusKey: string) {
    const docs = await this.ds.campusRef().doc(campusKey).collection("training_programs").get().toPromise();
    let data = pushToArray(docs);
    data = MappingService.orderBy(data, "name");
    return data;
  }

  @action
  async fetchTrainingLevel(campusKey: string, programKey: string) {
    const docs = await this.ds.campusRef().doc(campusKey).collection("training_levels", ref => ref.where("program.key", "==", programKey)).get().toPromise();
    let data = pushToArray(docs);
    data = MappingService.orderBy(data, "grade.order");
    return data;
  }

  @action
  async fetchAcademicYear() {
    this.process = true;
    const docs = await this.ds.academicYearRef().get().toPromise();
    this.process = false;
    return pushToArray(docs)
  }

  @action
  async fetchAcademicGrade() {
    this.process = true;
    const docs = await this.ds.academicGradeRef().get().toPromise();
    this.process = false;
    return pushToArray(docs)
  }

  @action
  async fetchAcademicFee() {
    this.process = true;
    const docs = await this.ds.tuitionFeeRef().get().toPromise();
    this.process = false;
    return pushToArray(docs)
  }

  @action
  fetchData(ref: AngularFirestoreCollection) {
    this.loading = true;
    ref.valueChanges().subscribe(docs => {
      this.data = docs;
      this.empty = docs.length === 0;
      this.loading = false;
    });
  }

  @action
  addNew(ref: AngularFirestoreCollection, item: any, callback) {
    this.process = true;
    ref.doc(item.key).set(item).then(() => {
      this.process = false;
      callback(true, item)
    }).catch(error => {
      this.process = false;
      callback(false, error)
    });
  }

  @action
  update(ref: AngularFirestoreCollection, item: any, callback) {
    this.process = true;
    ref.doc(item.key).update(item).then(() => {
      this.process = false;
      callback(true, item)
    }).catch(error => {
      this.process = false;
      callback(false, error)
    });
  }

  @action
  delete(ref: AngularFirestoreCollection, item: any, callback) {
    this.process = true;
    ref.doc(item.key).delete().then(() => {
      this.process = false;
      callback(true, item)
    }).catch(error => {
      this.process = false;
      callback(false, error)
    });
  }

  @action
  updateFileUrl(ref, item: any, fileName, fileUrl, callback) {
    this.process = true;
    ref.doc(item.key).update({
      fileName: fileName,
      fileUrl: fileUrl
    }).then(() => {
      this.process = false;
      callback(true, item)
    }).catch(error => {
      this.process = false;
      callback(false, error)
    });
  }

  @action
  updateDocUrl(ref, item: any, docName, docUrl, callback) {
    this.process = true;
    ref.doc(item.key).update({
      docName: docName,
      docUrl: docUrl
    }).then(() => {
      this.process = false;
      callback(true, item)
    }).catch(error => {
      this.process = false;
      callback(false, error)
    });
  }

}