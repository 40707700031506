import { ILevelFee } from "./../interfaces/tuition";
import { DataService } from "./../services/data.service";
import { observable, action } from "mobx";
import { Injectable } from "@angular/core";
import { MappingService, pushToArray } from "../services/mapping.service";

@Injectable()
export class Tuition {
  @observable
  public data = null;
  @observable
  public loading = false;
  @observable
  public empty = false;

  @observable
  public process = false;

  constructor(private ds: DataService) { }

  async fetchList(ref: any) {
    this.process = true;
    const docs = await ref.get().toPromise();
    const data = MappingService.pushToArray(docs)
    this.process = false;
    return data;
  }
  
  @action
  async fetchTuitionFee() {
    this.process = true;
    const docs = await this.ds.tuitionFeeRef().get().toPromise();
    this.process = false;
    return pushToArray(docs)
  }

  @action
  fetchData(item: any) {
    this.loading = true;
    this.ds.levelPricesRef(item).valueChanges().subscribe(docs => {
      this.data = docs;
      this.empty = docs.length === 0;
      this.loading = false;
    })
  }

  @action
  fetchToArray(callback) {
    this.ds.tuitionFeeRef().valueChanges().subscribe(docs => {
      callback(docs);
    });
  }

  @action
  fetchUATCurriculum(callback) {
    this.ds.UATCurriculumRef().valueChanges().subscribe(docs => {
      callback(docs);
    });
  }

  @action
  fetchNationality(callback) {
    this.ds.nationalityRef().valueChanges().subscribe(docs => {
      callback(docs);
    });
  }

  @action
  fetchUATInstitute(callback) {
    this.ds.UATInstituteRef().valueChanges().subscribe(docs => {
      callback(docs);
    });
  }

  @action
  save(item: ILevelFee, callback) {
    this.process = true;
    const batch = this.ds.batch();
    batch.update(this.ds.programLevelFireRef().doc(item.level.key), {
      price: item.price
    });
    batch.set(this.ds.levelPriceFireRef().doc(item.key), item);
    batch
      .commit()
      .then(() => {
        this.process = false;
        callback(true, null);
      })
      .catch(error => {
        this.process = false;
        callback(false, error);
      });
  }
}
