import { invoiceTypeParams } from 'src/app/dummy/status';
import { ConvertService } from 'src/app/services/convert.service';
import { AuthService } from 'src/app/auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { Report } from './../../../stores/report.store';
import { Component, OnInit } from '@angular/core';
import { Environment } from 'src/app/stores/environment.store';
import { User } from 'src/app/stores/user.store';
import { PrintService } from 'src/app/services/print.service';
import { SCHOOL } from 'src/app/dummy/config';

@Component({
  selector: 'app-all-program-summery',
  templateUrl: './all-program-summery.component.html',
  styleUrls: ['./all-program-summery.component.scss']
})
export class AllProgramSummeryComponent implements OnInit {
  type = "all";
  reportName = "All";
  shiftDate;
  school=SCHOOL

  constructor(public env: Environment,
    public store: Report,
    public auth: AuthService,
    public user: User,
    private route: ActivatedRoute,
    private ps: PrintService,
  ) { }

  ngOnInit() {
    this.route.params.forEach(param => {
      this.type = param["id"];
      this.env.getUser(user => {
        if (user && user.shift) {
          this.shiftDate = user.shift.create_date.toDate()
          if (this.type === "all") {
            this.reportName = "All";
            this.store.fetchSummary(user.shift);
          }
          else {
            this.reportName = invoiceTypeParams[this.type].text;
            this.store.fetchSummeryType(user.shift, invoiceTypeParams[this.type].key, this.type);
          }
        }
      })
    })
  }

  print() {
    this.ps.print('#print', 'a4');
  }

}
