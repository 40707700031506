import { AngularFirestore } from '@angular/fire/firestore';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Environment } from 'src/app/stores/environment.store';
import { DataService } from 'src/app/services/data.service';
import { MappingService, schoolObj, campusObj } from 'src/app/services/mapping.service';
import { BaseStore } from 'src/app/stores/base.store';
import { Registration } from 'src/app/stores/registration.store';
import { InvoiceDialogComponent } from 'src/app/components/invoice-dialog/invoice-dialog.component';
import { AddTestingPaymentComponent } from 'src/app/components/add-testing-payment/add-testing-payment.component';
import { Payment } from 'src/app/stores/payment.store';

@Component({
  selector: 'app-add-student-retake-test',
  templateUrl: './add-student-retake-test.component.html',
  styleUrls: ['./add-student-retake-test.component.scss']
})
export class AddStudentRetakeTestComponent implements OnInit {
  @ViewChild("focusInput") inputEl: ElementRef;
  form: FormGroup;
  campus: AbstractControl;
  program: AbstractControl;
  shift: AbstractControl;
  level: AbstractControl;
  remark: AbstractControl;
  admission_term: AbstractControl;

  filteredProgramStates: any;
  filteredLevelStates: any;
  filteredCampusStates: any;
  filteredShiftStates: any;
  filteredAcademicYearStates: any;

  ITEM: any;
  TITLE = "Retake test";
  constructor(
    public dialogRef: MatDialogRef<AddStudentRetakeTestComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public env: Environment,
    private snackBar: MatSnackBar,
    public base: BaseStore,
    private afs: AngularFirestore,
    public store: Registration,
    public dialog: MatDialog,
    private ds: DataService,
    public payment: Payment,
  ) { }

  buildForm(): void {
    this.form = this.fb.group({
      program: [null, Validators.compose([Validators.required, MappingService.validSelected.bind(this)])],
      level: [null, Validators.compose([Validators.required, MappingService.validSelected.bind(this)])],
      campus: [null, Validators.compose([Validators.required, MappingService.validSelected.bind(this)])],
      shift: [null, Validators.compose([Validators.required, MappingService.validSelected.bind(this)])],
      admission_term: [null, Validators.compose([Validators.required, MappingService.validSelected.bind(this)])],
      remark: [null],
    })
    this.program = this.form.controls['program'];
    this.level = this.form.controls['level'];
    this.campus = this.form.controls['campus'];
    this.shift = this.form.controls['shift'];
    this.admission_term = this.form.controls['admission_term'];
    this.remark = this.form.controls['remark'];
  }

  async ngOnInit() {
    this.buildForm();
    await this.env.fetchUserDoc()
    const { selectedSchool, selectedCampus } = this.env
    await this.env.fetchEnvDoc(selectedSchool.key)

    const shiftData = await this.base.fetchShift();
    this.filteredShiftStates = MappingService.autoComplete(this.shift, shiftData, "name");

    let campusData: any = await this.store.fetchSchoolCampus(selectedSchool.key)
    campusData = campusData.filter(m => m.key === selectedCampus.key)
    const campusDoc = campusData.find(m => m.key === selectedCampus.key)
    this.filteredCampusStates = MappingService.autoComplete(this.campus, campusData, "name");

    if (campusDoc) {
      const programData = await this.store.spsProgramFromCampus(campusDoc.key);
      this.filteredProgramStates = MappingService.autoComplete(this.program, programData, "name");
    }
    this.form.patchValue({
      shift: shiftData[0],
      campus: campusDoc,
    })

  }

  async selectCampus(event) {
    const { key } = this.campus.value;
    const programData = await this.store.spsProgramFromCampus(key);
    this.filteredProgramStates = MappingService.autoComplete(this.program, programData, "name");
  }

  generalIsTrue;
  async selectProgram(event) {
    this.admission_term.patchValue(null)
    const { key, name, programOption } = this.program.value;
    if (name != "Full-time English classes (GEP)") {
      this.generalIsTrue = true;
      this.form.get('level').setValidators([Validators.required]);
      this.form.get('level').updateValueAndValidity();
    }
    else {
      this.generalIsTrue = false;
      this.form.patchValue({ level: null }); ///set value to make formControl validate again when condition is true;
      this.form.get('level').clearValidators();
      this.form.get('level').updateValueAndValidity();
      this.form.get('level').setErrors(null);
    }
    const levelData = await this.store.spsProgramLevel(this.campus.value.key, key);
    this.filteredLevelStates = MappingService.autoComplete(this.level, levelData, "name");

    const { selectedSchool } = this.env
    const academicYearData = await this.store.fetchAcademicYearByProgram(selectedSchool.key, programOption.key);
    this.filteredAcademicYearStates = MappingService.autoComplete(this.admission_term, academicYearData, "name");

    const { academicEnv } = this.env
    const academicTerm = programOption.key == 1 ? academicEnv.year : academicEnv.term
    const academicTermDoc = academicYearData.find((m: any) => m.key === academicTerm.key)

    this.admission_term.patchValue(academicTermDoc || null)
  }

  compareObjects(o1: any, o2: any): boolean {
    if (o2) return o1.key === o2.key;
  }

  displayItem(item: any): string {
    return item ? item.name : item;
  }

  displayItemGrade(item: any): string {
    return item ? item.grade.name : item;
  }

  displayItemText(item: any): string {
    return item ? item.text : item;
  }

  create(f: any) {
    if (this.form.valid) {

      this.form.disable();
      const { campus, shift, program, level, remark, admission_term } = f;
      const { selectedSchool } = this.env;
      const { STUDENT } = this.data

      const item = {
        school: schoolObj(selectedSchool),
        campus: campusObj(campus),
        shift, program, level, remark, admission_term,
        student: STUDENT,
      }

      this.store.registerOnlineTesting(item, this.env.user, (success, res) => {
        if (success) {
          this.dialogRef.close();
          this.snackBar.open('Registration testing have been updated successful.', 'done', { duration: 2500 });
          this.form.enable();
          this.form.reset();
          this._onReceivePayment(res);
        } else {
          this.form.enable();
          this.snackBar.open('Registration testing fail.', 'Error');
        }
      })

    }
  }

  _onPrint(item) {
    let dialogRef = this.dialog.open(InvoiceDialogComponent, {
      data: { studentKey: item.student.key, headerKey: item.headerRef },
      width: '90vw',
      height: '80vh',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  _onReceivePayment(item) {
    if (item.isPaidTest) return;
    let dialogRef = this.dialog.open(AddTestingPaymentComponent, {
      data: item,
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: true,
      role: "dialog"
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== "no") {
        this._onPrint(result)
      }
    });
  }

}
