import { ConvertService } from 'src/app/services/convert.service';
import { MatSnackBar } from '@angular/material';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { Student } from 'src/app/stores/student.store';
import { Payment } from 'src/app/stores/payment.store';
import { Environment } from 'src/app/stores/environment.store';
import { MappingService, productObj, toNumber } from 'src/app/services/mapping.service';
import { DataService } from 'src/app/services/data.service';
import { BaseStore } from 'src/app/stores/base.store';
import { ADJUSTMENT_TYPES_OBJ, LINE_TYPE_OBJECT, MOVEMENT_STATUS_OBJ, SALE_TYPE } from 'src/app/dummy/status';
import { Registration } from 'src/app/stores/registration.store';
import { AutoCompleteStore } from 'src/app/stores/autocomplete.store';
import { COLLECTION_DATA, FILTER_FIELD } from 'src/app/dummy/config';
import { debounceTime, tap, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-new-invoice-pos',
  templateUrl: './new-invoice-pos.component.html',
  styleUrls: ['./new-invoice-pos.component.scss']
})
export class NewInvoicePosComponent implements OnInit {
  form: FormGroup;
  product: AbstractControl;
  sale_type: AbstractControl;
  qty: AbstractControl;
  discount_percentage: AbstractControl;
  discount_cash: AbstractControl;
  discount_total: AbstractControl;

  discountPercentage = null;
  discountCash = null;
  discountTotal = null;

  saleTypeList = SALE_TYPE
  constructor(
    public dialogRef: MatDialogRef<NewInvoicePosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    public ds: DataService,
    public env: Environment,
    public studentStore: Student,
    public payment: Payment,
    public baseStore: BaseStore,
    public store: Registration,
    private autoStore: AutoCompleteStore,
  ) { }

  buildForm(): void {
    this.form = this.fb.group({
      product: [null, Validators.compose([Validators.required, MappingService.validSelected.bind(this)])],
      qty: [null, [Validators.compose([Validators.required, this.calStock.bind(this)])]],
      discount_percentage: [null, [Validators.compose([this.calStock.bind(this)])]],
      discount_cash: [null, [Validators.compose([this.calStock.bind(this)])]],
      discount_total: [null,],
      sale_type: [this.saleTypeList[0]],
    })
    this.product = this.form.controls['product'];
    this.qty = this.form.controls['qty'];
    this.discount_percentage = this.form.controls['discount_percentage'];
    this.discount_cash = this.form.controls['discount_cash'];
    this.discount_total = this.form.controls['discount_total'];
    this.sale_type = this.form.controls['sale_type'];
  }

  currentYear: any;
  paymentOptionList = [];
  optionData = null;
  selectedProduct = null;
  productStock = null;
  paymentList = [];
  grandTotal = 0;
  process = true;
  filteredStates: any;
  canCutStock = true;

  async ngOnInit() {

    this.buildForm();
    this.discount_total.disable();

    await this.env.fetchUserDoc()
    const { selectedSchool } = this.env

    const envData: any = await this.env.fetchAcademicEnv(selectedSchool.key);
    this.currentYear = envData.year;

    this.optionData = await this.baseStore.fetchDoc(this.ds.settingDBFireStore());
    this.paymentOptionList = await this.baseStore.fetchList(this.ds.paymentOptionRef());

    // const productList = await this.baseStore.fetchList(this.ds.storeDocRef(selectedSchool.key).collection("products"));
    // this.filteredStates = MappingService.autoComplete(this.product, productList, "name");

    if (this.studentStore.selectedAdmission && this.studentStore.selectedAdmission.program_academic) {
      const { program } = this.studentStore.selectedAdmission.program_academic;
      if (program.programOption.key === 1) {

        this.currentYear = envData.year;
      } else {

        this.currentYear = envData.term;
      }
    }

    this.autoStore.fetchAutoDataSchool(selectedSchool.key, COLLECTION_DATA.products, FILTER_FIELD.keywords, docs => {
      this.filteredStates = docs;
    });

    this.product.valueChanges
      .pipe(
        debounceTime(300),
        tap(() => (false)),
        switchMap(value => this.autoStore.fetchAutoSearchContentDataSchool(selectedSchool.key, COLLECTION_DATA.products, FILTER_FIELD.keywords, value, docs => {

          if (docs) {
            this.filteredStates = docs;
          }
        }))
      ).subscribe(res => { });

    this.process = false;
  }

  clearDiscount() {
    this.discountPercentage = null;
    this.discountCash = null;
    this.discountTotal = null;

    this.form.patchValue({
      discount_percentage: null,
      discount_cash: null,
      discount_total: null,
    })
  }

  async _onSelectedProduct(event) {
    this.clearDiscount();
    this.grandTotal = 0;
    this.selectedProduct = null;
    this.paymentList = [];
    let productStock = [];
    this.sale_type.patchValue(this.saleTypeList[0])
    const { value } = event.option;
    const { user } = this.env
    if (value) {
      this.selectedProduct = value;
      if (this.selectedProduct.productType.key === 1) {
        productStock = [value];
        productStock = productStock.map(b => {
          return ({
            ...b,
            productKey: b.key,
          })
        })
      } else {
        productStock = this.selectedProduct.bundleItems.map(b => {
          return ({
            ...b,
            name: b.product.name,
          })
        })
      }
      let qty = ConvertService.toNumber(this.form.value.qty)
      if (ConvertService.toNumber(this.form.value.qty) === 0) {
        qty = 1;
        this.qty.patchValue(qty)
      }
      this.grandTotal = qty ? this.selectedProduct.sellingPrice * qty : this.selectedProduct.sellingPrice;
      Promise.resolve(productStock.forEach(async m => {
        const productStockData = await this.baseStore.fetchDoc(this.ds.storeDocRef(this.env.selectedSchool.key).collection("branches").doc(user.campus.key)
          .collection("products").doc(m.productKey));
        this.paymentList.push({
          ...productStockData,
          stockLeft: productStockData.stockBalance - qty,
          qty: m.qty ? ConvertService.toNumber(m.qty) : 1,
        })
      }))
    }
  }

  public calStock(control: AbstractControl) {
    const value = control.value;
    if (value !== undefined && value !== null && value !== '' && this.paymentList.length > 0) {
      const qty = ConvertService.toNumber(this.qty.value);
      let totalPrice = qty ? this.selectedProduct.sellingPrice * qty : this.selectedProduct.sellingPrice;

      const discountPercentage = ConvertService.toNumber(this.discount_percentage.value);
      const discountCash = ConvertService.toNumber(this.discount_cash.value);
      if (discountPercentage > 0) {
        this.discountPercentage = ((totalPrice * discountPercentage) / 100)
      }
      if (discountCash > 0) {
        this.discountCash = discountCash;
      }

      this.discountTotal = toNumber(this.discountPercentage) + toNumber(this.discountCash);
      if (this.discountTotal > 0) {
        this.discount_total.patchValue(this.discountTotal)
      }

      totalPrice = totalPrice - this.discountTotal;
      this.grandTotal = totalPrice > 0 ? totalPrice : 0;

      this.paymentList = this.paymentList.map(m => {
        return ({
          ...m,
          stockLeft: m.stockBalance - qty
        })
      })
      this.canCutStock = this.paymentList.filter(m => m.stockLeft < 0).length === 0 ? true : false;
    }

    if (value === '' || value === 0) {
      this.clearDiscount();

      const qty = ConvertService.toNumber(this.qty.value);
      let totalPrice = qty ? this.selectedProduct.sellingPrice * qty : this.selectedProduct.sellingPrice;
      this.grandTotal = totalPrice;
    }
  }

  _onSelected(event) {
    this.clearDiscount();
    const { value } = event;

    if (value && this.qty.value > 0) {
      const qty = ConvertService.toNumber(this.qty.value);
      let totalPrice = qty ? this.selectedProduct.sellingPrice * qty : this.selectedProduct.sellingPrice;

      if (value.key === 2) {
        const discountPercentage = 100;
        const discountCash = 0;
        if (discountPercentage > 0) {
          this.discountPercentage = ((totalPrice * discountPercentage) / 100)
        }
        if (discountCash > 0) {
          this.discountCash = discountCash;
        }

        this.discountTotal = toNumber(this.discountPercentage) + toNumber(this.discountCash);
        if (this.discountTotal > 0) {
          this.discount_total.patchValue(this.discountTotal)
          this.discount_percentage.patchValue(discountPercentage)
          this.discount_percentage.disable();
          this.discount_cash.disable();
        }

        totalPrice = totalPrice - this.discountTotal;
        this.grandTotal = totalPrice > 0 ? totalPrice : 0;

        this.paymentList = this.paymentList.map(m => {
          return ({
            ...m,
            stockLeft: m.stockBalance - qty
          })
        })
      } else {
        const qty = ConvertService.toNumber(this.qty.value);
        let totalPrice = qty ? this.selectedProduct.sellingPrice * qty : this.selectedProduct.sellingPrice;

        const discountPercentage = ConvertService.toNumber(this.discount_percentage.value);
        const discountCash = ConvertService.toNumber(this.discount_cash.value);
        if (discountPercentage > 0) {
          this.discountPercentage = ((totalPrice * discountPercentage) / 100)
        }
        if (discountCash > 0) {
          this.discountCash = discountCash;
        }

        this.discountTotal = toNumber(this.discountPercentage) + toNumber(this.discountCash);
        if (this.discountTotal > 0) {
          this.discount_total.patchValue(this.discountTotal)
        }

        totalPrice = totalPrice - this.discountTotal;
        this.grandTotal = totalPrice > 0 ? totalPrice : 0;

        this.paymentList = this.paymentList.map(m => {
          return ({
            ...m,
            stockLeft: m.stockBalance - qty
          })
        })
      }

      this.canCutStock = this.paymentList.filter(m => m.stockLeft < 0).length === 0 ? true : false;
    };

  }

  displayItem(item: any): string {
    return item ? item.name : item;
  }

  compareObjects(o1: any, o2: any): boolean {
    if (o2) return o1.key === o2.key;
  }

  create(f: any) {
    if (this.form.valid && this.paymentList.length > 0 && this.canCutStock) {
      this.form.disable();
      this.paymentList = this.paymentList.map(m => {
        return ({
          ...m,
          product: m,
          productKey: m.key,
          productRef: this.ds.storeDocRef(this.env.selectedSchool.key).collection("products").doc(m.key).ref,
          key: this.ds.createId(),
        })
      })

      const totalPrice = this.selectedProduct.sellingPrice * f.qty;

      const form = {
        ...this.selectedProduct,
        totalPrice: totalPrice,
        totalAfterDiscount: totalPrice - this.discountTotal,
        vat: 0,
        grandTotal: this.grandTotal,
        discountPercentage: this.discountPercentage,
        discountCash: this.discountCash,
        discountTotal: this.discountTotal,
        sale_type: f.sale_type,
        qty: ConvertService.toNumber(f.qty),
        percentage: ConvertService.toNumber(f.discount_percentage),
        cash: ConvertService.toNumber(f.discount_cash),
      }

      const course = {
        ...productObj(form),
        items: this.paymentList,
        lineType: LINE_TYPE_OBJECT.ITEM,
        movementType: ADJUSTMENT_TYPES_OBJ.sale,
        movementStatus: MOVEMENT_STATUS_OBJ.RELEASED,
      }

      const { selectedCampus, selectedSchool } = this.env

      this.store.addPOSFee(selectedCampus, selectedSchool, this.payment.invoiceKey, course, this.currentYear, this.studentStore.student, this.studentStore.selectedAdmission, this.env.user, this.payment.paymentHeader, (success, error) => {
        if (success) {
          this.snackBar.open("Inventory has been added to your receipt.", "Done", { duration: 2000 })
          // this.dialogRef.close('yes');
          this.form.enable();
          this.form.reset();

          this.grandTotal = 0;
          this.selectedProduct = null;
          this.paymentList = [];
          this.sale_type.patchValue(this.saleTypeList[0])
        }
        else {
          this.snackBar.open(error, "Error");
          this.form.enable();
        }
      })
    }
  }
}
