import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'mediumDate'
})
export class MediumDatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return moment(value).format('DD MMM YYYY, hh:MM:ss a');
  }

}
