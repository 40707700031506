import { Pages } from 'src/app/dummy/pages';
import { ConvertService } from "./../../services/convert.service";
import { Environment } from "./../../stores/environment.store";
import { AuthService } from "../../auth/auth.service";
import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef
} from "@angular/core";
import {
  FormGroup,
  AbstractControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from "@angular/material";
import { switchMap, debounceTime, tap } from "rxjs/operators";
import { DailyShiftStore } from 'src/app/stores/dailyShift.store';
import { academicYearObj, MappingService } from 'src/app/services/mapping.service';
import { PettyCash } from 'src/app/stores/pettyCash.store';

@Component({
  selector: 'app-edit-petty-cash',
  templateUrl: './edit-petty-cash.component.html',
  styleUrls: ['./edit-petty-cash.component.scss']
})
export class EditPettyCashComponent implements OnInit {
  @ViewChild("focusInput")
  inputEl: ElementRef;

  form: FormGroup;
  payee: AbstractControl;
  price: AbstractControl;
  refNo: AbstractControl;
  description: AbstractControl;
  receivedBy: AbstractControl;
  approvedBy: AbstractControl;
  loading = false;

  balance: number = 0;
  change: number;
  matchBalance: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<EditPettyCashComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public auth: AuthService,
    private snackBar: MatSnackBar,
    public store: PettyCash,
    private env: Environment,
    public shiftInvoice: DailyShiftStore
  ) { }

  buildForm(): void {
    this.form = this.fb.group({
      price: [null, [Validators.compose([this.validCashIn.bind(this)])]],
      refNo: [null],
      payee: [null, Validators.required],
      description: [null, Validators.required],
      receivedBy: [null, Validators.required],
      approvedBy: [null, Validators.required],
    });
    this.price = this.form.controls["price"];
    this.refNo = this.form.controls["refNo"];
    this.payee = this.form.controls["payee"];
    this.description = this.form.controls["description"];
    this.receivedBy = this.form.controls["receivedBy"];
    this.approvedBy = this.form.controls["approvedBy"];
  }

  ngOnInit() {
    this.buildForm();

    this.store.fetchUser();

    this.approvedBy.valueChanges
      .pipe(
        debounceTime(Pages.debounceTime),
        tap(() => (this.loading = true)),
        switchMap(value => this.store.searchUser(value))
      )
      .subscribe(results => {
        this.store.employees = results;
        this.loading = false;
      });

    const { ITEM } = this.data
    const { payee, refNo, approvedBy, receivedBy, description } = ITEM;
    this.form.patchValue({
      payee, refNo, approvedBy, receivedBy, description
    })
  }

  public validCashIn(control: AbstractControl): { [s: string]: boolean } {
    const value = control.value;
    if (value !== undefined && value !== null && value !== '') {
      this.change = ConvertService.getNumber(value, 0) - this.balance;
      if (this.change > 0) {
        return { validKey: true }
      }
      if (value < 0) {
        return { validAmount: true }
      }
    } else {
      this.change = this.balance
    }
    if (value !== this.balance)
      this.matchBalance = false
    else
      this.matchBalance = true
  }

  displayFullName(item: any): string {
    return item ? item.displayName : item;
  }

  create(f: any, isNew) {
    if (this.form.valid) {
      this.form.disable();
      const { ITEM } = this.data
      const { payee, refNo, approvedBy, description } = f;
      const item = {
        key: ITEM.key,
        payee: payee,
        refNo: refNo,
        approvedBy: MappingService.userObj(approvedBy),
        receivedBy: null,
        datePayment: new Date(),
        datePaymentKey: ConvertService.toDateKey(new Date),
        term: academicYearObj(this.env.term),
        campus: MappingService.campusObj(this.env.campus),
        shift: MappingService.dailyShiftObj(this.env.dailyShift),
        description: description,

        modifiedDate: new Date(),
        modifiedBy: MappingService.userObj(this.env.user),
      };
      this.store.updatePettyCash(item, (success, error) => {
        if (success) {
          if (!isNew) this.dialogRef.close();
          this.snackBar.open("Petty cash has been updated.", "done", {
            duration: 2000
          });
          this.form.reset();
          this.form.enable();
          this.inputEl.nativeElement.focus();
        } else {
          this.form.enable();
          this.snackBar.open(error, "Error");
        }
      });
    }
  }
}
