import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pagination-loader',
  templateUrl: './pagination-loader.component.html',
  styleUrls: ['./pagination-loader.component.scss']
})
export class PaginationLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
