import { Component, OnInit, Input } from '@angular/core';
import { CashierShiftClosedComponent } from '../cashier-shift-closed/cashier-shift-closed.component';
import { ShiftCloseComponent } from 'src/app/pages/shift-close/shift-close.component';
import { ShiftOpenComponent } from 'src/app/pages/shift-open/shift-open.component';
import { MatDialog } from '@angular/material';
import { Environment } from 'src/app/stores/environment.store';
import { User } from 'src/app/stores/user.store';

@Component({
  selector: 'app-header-nav',
  templateUrl: './header-nav.component.html',
  styleUrls: ['./header-nav.component.scss']
})
export class HeaderNavComponent implements OnInit {
  @Input() displayName:string;
  @Input() avatar:string;
  @Input() balance:number;
  @Input() isOpen:boolean;
  constructor(
    private store: User,
    public dialog: MatDialog,
    public env: Environment
  ) { }

  ngOnInit() {
    
  }

  closeShift() {
    if (this.env.user === null) return;
    const {shift}=this.store.user;
    if(!shift){
      let dialogRef = this.dialog.open(CashierShiftClosedComponent, {
        data: null,
        disableClose: true,
        role: "dialog"
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result==='yes'){
          this.openShift();
        }
      });
      return;
    }
    let dialogRef = this.dialog.open(ShiftCloseComponent, {
      data: null,
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: true,
      role: "dialog"
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => {});
  }

  openShift() {
    if (this.store.user === null) return;
    let dialogRef = this.dialog.open(ShiftOpenComponent, {
      data: null,
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: true,
      role: "dialog"
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => {});
  }

}
