import { ConvertService } from './../../../services/convert.service';
import { DeleteComponent } from './../../../components/delete/delete.component';
import { AddSchoolPaymentComponent } from './../../../components/add-school-payment/add-school-payment.component';
import { MatDialog, MatSnackBar } from "@angular/material";
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Student } from "src/app/stores/student.store";
import { Payment } from "src/app/stores/payment.store";
import { Environment } from "src/app/stores/environment.store";
import { PrintService } from "src/app/services/print.service";
import { InvoiceDialogComponent } from 'src/app/components/invoice-dialog/invoice-dialog.component';
import { EditEnglishPaymentComponent } from 'src/app/components/edit-english-payment/edit-english-payment.component';
import { AddStudentPenaltyComponent } from 'src/app/components/add-student-penalty/add-student-penalty.component';
import { toJS } from 'mobx';
import { AddInstallmentPaymentComponent } from 'src/app/components/add-installment-payment/add-installment-payment.component';

@Component({
  selector: "app-student-payment",
  templateUrl: "./student-payment.component.html",
  styleUrls: ["./student-payment.component.scss"]
})
export class StudentPaymentComponent implements OnInit {
  studentKey = null;
  admissionKey: string = null;
  invoiceKey: string;
  batch_session: any = null;

  requestPenalty: any = null;
  requestVoid: any = null;
  studentInstallment: any = null;

  constructor(
    private route: ActivatedRoute,
    public store: Student,
    public payment: Payment,
    public env: Environment,
    public dialog: MatDialog,
    private ps: PrintService,
    private snackBar: MatSnackBar,
  ) { }

  async ngOnInit() {
    this.route.parent.params.forEach(params => {
      this.payment.invoiceKey = null
      this.invoiceKey = ConvertService.toNull(params["invoiceKey"]);
      this.studentKey = ConvertService.toNull(params["id"]);
      this.admissionKey = ConvertService.toNull(params["admissionKey"]);

      this.env.fetchUserAccount(user => {
        this.payment.fetchRequestPenalty(this.studentKey, (req) => {
          this.requestPenalty = req && req.length > 0 ? req[0] : null;
        })

        this.payment.fetchRequestVoidReceipt(this.studentKey, (req) => {
          this.requestVoid = req && req.length > 0 ? req[0] : null;
        })

        this.payment.fetchPendingInstallmentPayment(this.studentKey, (req) => { })
        this.payment.fetchReceivePayment(this.studentKey, this.admissionKey, null, docs => { })
      })
    })
  }

  _onAddPenalty(item?: any) {
    if (this.payment.totalPenalty && this.payment.totalPenalty > 0) return
    let dialogRef = this.dialog.open(AddStudentPenaltyComponent, {
      data: { ITEM: item },
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: true,
      role: "dialog"
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== "no") {
      }
    });
  }

  _onAddStudentPayment() {
    if (!this.payment.studentInstallment) return
    let dialogRef = this.dialog.open(AddInstallmentPaymentComponent, {
      data: { ITEM: this.payment.studentInstallment },
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: true,
      role: "dialog"
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== "no") {
      }
    });
  }

  print() {
    this.ps.print("#printD", "a5l");
  }

  _onPrint(item) {
    let dialogRef = this.dialog.open(InvoiceDialogComponent, {
      data: { studentKey: item.student.key, headerKey: item.headerRef },
      width: '90vw',
      height: '80vh',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  _onReceivePayment() {
    if (this.payment.grandTotal <= 0 || !this.env.dailyShift || !this.env.sameDay) return;
    const payParam = {
      invoiceKey: this.payment.invoiceKey,
      totalPrepaid: this.payment.totalPrepaid,
      totalInstallment: this.payment.totalInstallment,
      paymentTuitionFee: this.payment.paymentTuitionFee,
      grandTotal: this.payment.grandTotal,
      totalScholarship: this.payment.totalScholarship,
      totalLoan: this.payment.totalLoan,
      penaltyRef: this.payment.penaltyRef,
      totalPenalty: this.payment.totalPenalty,
      totalPaymentDiscount: this.payment.totalPaymentDiscount,
    }
    let dialogRef = this.dialog.open(AddSchoolPaymentComponent, {
      data: { paymentHeader: this.payment.paymentHeader, paymentDetail: this.payment.paymentDetail, scholarshipRef: this.payment.scholarshipRef, payParam: payParam },
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: true,
      role: "dialog"
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.student) {
        this._onPrint(result)
      }
    });
  }

  // _onUpdateInvoice(){
  //   if(this.payment.paymentDetail.length === 0) return
  //   this.payment.updateInvoice250(this.payment.paymentDetail[0])
  // }

  _removeFee(item) {
    if (!this.payment.paymentHeader) return;
    let dialogRef = this.dialog.open(DeleteComponent, {
      data: { title: 'Remove Fee', memo: 'Please check this information again before remove from invoice.', button: 'OK' },
      width: '35vw',
      disableClose: true,
      role: 'dialog',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'yes') {
        this.payment.removeFee(item, this.store.student, this.payment.paymentHeader, (success, error) => {
          if (success) {
            this.snackBar.open(item.description + ' has been removed.', 'done', { duration: 2000 });
          }
          else {
            this.snackBar.open(error, 'Error')
          }
        })
      }
    });
  }

  _updateFee(item) {
    if (!this.payment.paymentHeader) return;
    let dialogRef = this.dialog.open(DeleteComponent, {
      data: { title: 'Update Fee', memo: 'Please check this information again before remove from invoice.', button: 'OK' },
      width: '35vw',
      disableClose: true,
      role: 'dialog',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'yes') {
        this.payment.updateFee(item, this.store.student, this.payment.paymentHeader, this.payment.paymentDetail, (success, error) => {
          if (success) {
            this.snackBar.open(item.description + ' has been removed.', 'done', { duration: 2000 });
          }
          else {
            this.snackBar.open(error, 'Error')
          }
        })
      }
    });
  }

  async _onRemoveItem(item) {
    await this.env.fetchConfig();
    // const { admin_fee } = this.env.config;
    // if (item.course.key === admin_fee.key) return;
    if (!this.payment.paymentHeader) return;
    let dialogRef = this.dialog.open(DeleteComponent, {
      data: { title: 'Remove', memo: 'Please check this information again before remove from invoice.', name: item.description, button: 'OK' },
      width: '35vw',
      disableClose: true,
      role: 'dialog',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'yes') {
        this.payment.removeMiscellaneous(item, this.store.student, this.payment.paymentHeader, (success, error) => {
          if (success) {
            this.snackBar.open(item.description + ' has been removed.', 'done', { duration: 2000 });
          }
          else {
            this.snackBar.open(error, 'Error')
          }
        })
      }
    });
  }

  _removePenalty() {
    if (!this.payment.paymentHeader) return;
    let dialogRef = this.dialog.open(DeleteComponent, {
      data: { title: 'Remove', memo: 'Please check this information again before remove from invoice.', name: "Penalty Fee", button: 'OK' },
      width: '35vw',
      disableClose: true,
      role: 'dialog',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'yes') {
        const tuitionFee = this.payment.paymentTuitionFee && this.payment.paymentTuitionFee.length > 0 ? this.payment.paymentTuitionFee[0] : null;
        if (!tuitionFee) return;
        this.payment.removeStudentPenalty(tuitionFee, (success, error) => {
          if (success) {
            this.snackBar.open('Penalty has been removed.', 'done', { duration: 2000 });
          }
          else {
            this.snackBar.open(error, 'Error')
          }
        })
      }
    });
  }

  _removeDiscountVoucher() {
    if (!this.payment.paymentHeader) return;
    let dialogRef = this.dialog.open(DeleteComponent, {
      data: { title: 'Remove', memo: 'Please check this information again before remove from invoice.', name: "Penalty Fee", button: 'OK' },
      width: '35vw',
      disableClose: true,
      role: 'dialog',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'yes') {
        this.store.deleteDiscountVoucher(this.payment.paymentHeader, (success, error) => {
          if (success) {
            this.snackBar.open('Discount voucher has been removed.', 'done', { duration: 2000 });
          }
          else {
            this.snackBar.open(error, 'Error')
          }
        })
      }
    });
  }

  _editEnglishPayment(item: any) {
    // const { school_fee_type } = item;
    // if (school_fee_type.key !== 3) return;
    // let dialogRef = this.dialog.open(EditEnglishPaymentComponent, {
    //   data: item,
    //   panelClass: 'register-test-overlay-panel',
    //   width: '35vw',
    //   height: '100vh',
    //   disableClose: false,
    //   role: 'dialog',
    // });
    // dialogRef.updatePosition({ top: '0', right: '0', bottom: '0' });
    // dialogRef.afterClosed().subscribe(result => { });
  }

  _onRemoveScholarship() {
    let dialogRef = this.dialog.open(DeleteComponent, {
      data: { title: 'Remove', memo: 'This will remove scholarship from this student.', name: "", button: 'OK' },
      width: '35vw',
      disableClose: true,
      role: 'dialog',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'yes') {
        this.store.deleteMonkScholarship(this.store.student, (success, error) => {
          if (success) {
            this.snackBar.open('Scholarship has been removed.', 'done', { duration: 2000 });
          }
          else {
            this.snackBar.open(error, 'Error')
          }
        })
      }
    });
  }

}
