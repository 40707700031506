import { TestFeeStatus } from "../../dummy/status";
import { IReceivedPayment } from "./../../interfaces/receivedPayment";
import { ConvertService } from "./../../services/convert.service";
import { Environment } from "./../../stores/environment.store";
import { AngularFirestore } from "@angular/fire/firestore";
import { AuthService } from "../../auth/auth.service";
import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef
} from "@angular/core";
import {
  FormGroup,
  AbstractControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from "@angular/material";
import { Payment } from "src/app/stores/payment.store";

@Component({
  selector: 'app-add-receive-payment-miscellaneous',
  templateUrl: './add-receive-payment-miscellaneous.component.html',
  styleUrls: ['./add-receive-payment-miscellaneous.component.scss']
})
export class AddReceivePaymentMiscellaneousComponent implements OnInit {
  @ViewChild("focusInput")
  inputEl: ElementRef;

  form: FormGroup;
  cash_in: AbstractControl;

  paymentTerm: any = null;
  change: number;

  amountPay: number;

  constructor(
    public dialogRef: MatDialogRef<AddReceivePaymentMiscellaneousComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public auth: AuthService,
    private snackBar: MatSnackBar,
    public store: Payment,
    private db: AngularFirestore,
    private env: Environment
  ) {

  }

  buildForm(): void {
    this.change = 0;
    this.amountPay = this.data.program.price;
    this.form = this.fb.group({
      cash_in: [
        this.amountPay,
        [Validators.compose([Validators.required, this.validCashIn.bind(this)])]
      ]
    });
    this.cash_in = this.form.controls["cash_in"];

    this.change = 0 - this.amountPay;
  }

  public validCashIn(control: AbstractControl): { [s: string]: boolean } {
    const value = control.value;
    if (value !== undefined && value !== null && value !== "") {
      this.change = ConvertService.getNumber(value, 0) - this.amountPay;
      if (this.change < 0) {
        return { validKey: true };
      }
    } else {
      this.change = 0 - this.amountPay;
    }
  }

  ngOnInit() {
    this.buildForm();
  }

  _save(f: any) {
    // if (this.form.valid) {
    //   const item: IReceivedPayment = {
    //     key: this.db.createId(),
    //     invoiceKey: null,
    //     studentKey: null,
    //     shift: this.env.user.shift,
    //     cashier: this.env.user,
    //     payment_term: this.env.academic.term,
    //     payment_campus: this.env.user.campus,
    //     cashIn:f.cash_in,
    //     change:this.change,
    //     status: TestFeeStatus.paid,
    //     page_key: ConvertService.pageKey(),
    //     create_date: new Date(),
    //     create_date_key: ConvertService.toDateKey(new Date())
    //   };

    //   this.store.receivePaymentMiscellaneous(
    //     item, this.data.program, this.env.user, this.data.student, this.env.academic.term,
    //     (success,error) => {
    //       if (success) {
    //         this.dialogRef.close('print');
    //         this.snackBar.open("Invoice have been paid successful.", "done", {
    //           duration: 2000
    //         });
    //       } else {
    //         this.snackBar.open(error, "Error");
    //       }
    //     }
    //   );
    // }
  }
}
