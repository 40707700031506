import { MappingService, pushToArray, pushToObject } from 'src/app/services/mapping.service';
import { Pipe, PipeTransform } from '@angular/core';
import { DataService } from '../services/data.service';

@Pipe({
  name: 'getCampus'
})
export class GetCampusPipe implements PipeTransform {
  constructor(private ds:DataService){

  }
  async transform(campusKey:any, field?: any): Promise<any> {
    if(campusKey){
      const data = pushToObject(await this.ds.campusRef().doc(campusKey).get().toPromise())
      return data[field]
    }
  }

}
