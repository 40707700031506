import { Pipe, PipeTransform } from '@angular/core';
import { ConvertService } from '../services/convert.service';

@Pipe({
  name: 'monthSchedule'
})
export class MonthsSchedulePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value) return null;
    return ConvertService.getMonthSchedule(value);
  }

}

@Pipe({
  name: 'khmerNumber'
})
export class KhmerNumberPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value) return null;
    return ConvertService.getKhmerNumber(value);
  }

}

