import { ITesting } from '../../../interfaces/testing';
import { ConvertService } from './../../../services/convert.service';
import { Search } from './../../../stores/search.store';
import { Pages } from './../../../dummy/pages';
import { Genders,searchFilterBy,TestingStatus, TestFeeStatus } from "../../../dummy/status";
import { Environment } from "./../../../stores/environment.store";
import { Testing } from "./../../../stores/testing.store";
import { Router, ActivatedRoute } from "@angular/router";
import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import {
  FormGroup,
  AbstractControl,
  Validators,
  FormBuilder
} from "@angular/forms";
import { MatSnackBar } from "@angular/material";
import { switchMap, debounceTime, tap } from "rxjs/operators";

@Component({
  selector: "app-register-testing-form",
  templateUrl: "./register-testing-form.component.html",
  styleUrls: ["./register-testing-form.component.scss"]
})
export class RegisterTestingFormComponent implements OnInit {
  //search
  formFocus: boolean;
  user: any;
  formSearch: FormGroup;
  search: AbstractControl;
  searchType: AbstractControl;

  filterBy = searchFilterBy;
  loading: boolean = false;


  @ViewChild("focusInput")
  inputEl: ElementRef;

  form: FormGroup;
  first_name: AbstractControl;
  last_name: AbstractControl;
  gender: AbstractControl;
  cash_in: AbstractControl;

  genderList = Genders;
  testData: any;
  change: number;
  amountPay: number = 0;
  instituteKey: string;
  testingOption: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public store: Testing,
    public env: Environment,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    public searchStore:Search
  ) {}

  ngOnInit() {
    this.route.params.forEach(params => {
      this.instituteKey = params["id"];
     
    });

  }


 
}
