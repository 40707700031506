import { ConfirmSuccessComponent } from './../../../components/confirm-success/confirm-success.component';
import { ConvertService } from './../../../services/convert.service';
import { DeleteComponent } from './../../../components/delete/delete.component';
import { AddSchoolPaymentComponent } from './../../../components/add-school-payment/add-school-payment.component';
import { MatDialog, MatSnackBar } from "@angular/material";
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Student } from "src/app/stores/student.store";
import { Environment } from "src/app/stores/environment.store";
import { PrintService } from "src/app/services/print.service";
import { InvoiceDialogComponent } from 'src/app/components/invoice-dialog/invoice-dialog.component';
import { DataService } from 'src/app/services/data.service';
import { Invoice } from 'src/app/stores/invoice.store';
import { Payment } from 'src/app/stores/payment.store';

@Component({
  selector: 'app-new-invoice',
  templateUrl: './new-invoice.component.html',
  styleUrls: ['./new-invoice.component.scss']
})
export class NewInvoiceComponent implements OnInit {
  studentKey = null;
  admissionKey: string = null;
  invoiceKey: string;
  constructor(
    private route: ActivatedRoute,
    public store: Student,
    public payment: Payment,
    public env: Environment,
    public dialog: MatDialog,
    private ps: PrintService,
    private snackBar: MatSnackBar,
    private router: Router,
    private ds: DataService,
  ) { }

  ngOnInit() {
    this.route.params.forEach(items => {
      this.route.parent.params.forEach(params => {

        this.invoiceKey = ConvertService.toNull(items["invoiceKey"]);
        this.studentKey = ConvertService.toNull(params["id"]);
        this.admissionKey = ConvertService.toNull(params["admissionKey"]);

        this.payment.invoiceKey = this.invoiceKey;
        this.payment.invoiceKey = ConvertService.toNull(this.invoiceKey)
        // this.payment.fetchEnrollment(this.admissionKey, this.studentKey, this.invoiceKey);

        this.env.fetchUserAccount(user => {
          this.payment.fetchReceivePayment(this.studentKey, this.admissionKey, this.payment.invoiceKey, docs => { })
        })

      });
    })

  }

  print() {
    this.ps.print("#printD", "a5l");
  }

  _onPrint(item) {
    let dialogRef = this.dialog.open(InvoiceDialogComponent, {
      data: { studentKey: item.student.key, headerKey: item.headerRef },
      width: '90vw',
      height: '80vh',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  _onReceivePayment() {
    if (this.payment.grandTotal < 0 || !this.env.dailyShift || !this.env.sameDay) return;
    let scholarshipRef = null;
    if (this.payment.paymentHeader.course && this.payment.paymentHeader.course.key === "unpaid") scholarshipRef = this.payment.scholarshipRef;
    const payParam = {
      invoiceKey: this.payment.invoiceKey,
      totalPrepaid: this.payment.totalPrepaid,
      totalInstallment: this.payment.totalInstallment,
      paymentTuitionFee: this.payment.paymentTuitionFee,
      grandTotal: this.payment.grandTotal,
      totalScholarship: this.payment.totalScholarship,
      totalLoan: this.payment.totalLoan,
      penaltyRef: this.payment.penaltyRef,
      totalPenalty: this.payment.totalPenalty,
    }
    let dialogRef = this.dialog.open(AddSchoolPaymentComponent, {
      data: { paymentHeader: this.payment.paymentHeader, paymentDetail: this.payment.paymentDetail, scholarshipRef: scholarshipRef, payParam: payParam },
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: true,
      role: "dialog"
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== "no") {
        this._onPrint(result)
      }
    });
  }


  _onRemoveItem(item) {
    if (!this.payment.paymentHeader) return;
    let dialogRef = this.dialog.open(DeleteComponent, {
      data: { title: 'Remove', memo: 'Please check this information again before remove from invoice.', name: item.description, button: 'OK' },
      width: '35vw',
      disableClose: true,
      role: 'dialog',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'yes') {
        this.payment.removeMiscellaneous(item, this.store.student, this.payment.paymentHeader, (success, error) => {
          if (success) {
            this.snackBar.open(item.description + ' has been removed.', 'done', { duration: 2000 });
          }
          else {
            this.snackBar.open(error, 'Error')
          }
        })
      }
    });
  }


  _onCancel() {
    let dialogRef = this.dialog.open(ConfirmSuccessComponent, {
      data: { title: "Cancel Invoice", warning: "All Miscellaneous and other fee will include with tuition fee.", note: "Are you sure you want to cancel invoice?" },
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      disableClose: false,
      role: "dialog"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'yes') {
        this.ds.studentDocument(this.store.student.key).update({
          invoiceKey: null
        }).then(() => {
          this.router.navigate([`/student/${this.studentKey}/${this.admissionKey}/receive-payment/`]);
        }).catch(error => {
          alert(error);
        })
      }
    });
  }
}
