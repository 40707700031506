import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { DataService } from '../../services/data.service';
import { DailyShift } from '../../interfaces/dailyShift';
import { ConvertService } from '../../services/convert.service';
import { ShiftStatusObj, allocatedStatusObj } from '../../dummy/status';
import { Environment } from '../../stores/environment.store';
import { DailyShiftStore } from '../../stores/dailyShift.store';
import { Router } from '@angular/router';
import { MappingService } from 'src/app/services/mapping.service';

@Component({
  selector: 'app-shift-open',
  templateUrl: './shift-open.component.html',
  styleUrls: ['./shift-open.component.scss']
})
export class ShiftOpenComponent implements OnInit {
  @ViewChild("focusInput") inputEl: ElementRef;

  form: FormGroup
  cash_in: AbstractControl
  cash_in_ref: AbstractControl

  constructor(
    public dialogRef: MatDialogRef<ShiftOpenComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private ds: DataService,
    public env: Environment,
    public store: DailyShiftStore,
    private router: Router,
  ) { }

  buildForm(): void {
    this.form = this.fb.group({
      cash_in: [{ value: 0, disabled: true }, [Validators.compose([Validators.required, this.validCashIn.bind(this)])]],
      cash_in_ref: [null],
    })
    this.cash_in = this.form.controls['cash_in'];
    this.cash_in_ref = this.form.controls['cash_in_ref'];
  }

  public validCashIn(control: AbstractControl): { [s: string]: boolean } {
    const value = control.value;
    if (value !== undefined && value !== null && value !== '') {
      if (value < 0) {
        return { validAmount: true }
      }
    }
  }

  async ngOnInit() {
    this.inputEl.nativeElement.focus()
    this.buildForm();
    await this.env.fetchUserDoc()
  }

  create(f: any) {
    if (this.form.valid) {
      this.form.disable();
      const { selectedCampus, selectedSchool } = this.env
      const formData: DailyShift = {
        key: this.ds.createId(),
        cash_in: 0,
        create_by: MappingService.userObj(this.env.user),
        create_date: new Date(),
        create_date_key: ConvertService.dateKey(),
        start_date: new Date(),
        start_date_key: ConvertService.dateKey(),
        start_cashier: MappingService.userObj(this.env.user),
        status: ShiftStatusObj.opening,
        cash_in_ref: f.cash_in_ref,
        page_key: ConvertService.pageKey(),
        isConfirm: false,
        allocated_status: allocatedStatusObj.opening,
        petty_cash: 0,
        installment: 0,
        balance: 0,

        campusKey: selectedCampus.key,
        campus: selectedCampus,
        schoolKey: selectedSchool.key,
        school: selectedSchool
      }
      this.store.shiftOpen(formData, this.env.user, (success, res) => {
        if (success) {
          this.env.dailyShift = res;
          this.dialogRef.close('yes');
          this.snackBar.open('Start shift successfully', 'done', { duration: 2000 });
          this.form.reset();
          this.form.enable();
          this.router.navigate(['/'])
        } else {
          this.form.enable();
          this.snackBar.open('DailyShift Error! System cancel your transactions.', 'Error')
        }
      })
    }
  }

}
