import { MatSnackBar } from '@angular/material';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { Student } from 'src/app/stores/student.store';
import { Payment } from 'src/app/stores/payment.store';
import { Environment } from 'src/app/stores/environment.store';
import { DataService } from 'src/app/services/data.service';
import { Invoice } from 'src/app/stores/invoice.store';
import { BaseStore } from 'src/app/stores/base.store';
import { ConvertService } from 'src/app/services/convert.service';
import { IAddPenalty } from 'src/app/interfaces/invoice';
import { campusObj, userObj } from 'src/app/services/mapping.service';
import { REQUEST_STATUS, recordStatus } from 'src/app/dummy/status';

@Component({
  selector: 'app-add-student-penalty',
  templateUrl: './add-student-penalty.component.html',
  styleUrls: ['./add-student-penalty.component.scss']
})
export class AddStudentPenaltyComponent implements OnInit {

  selectedAcademicYear;
  penaltyData;
  tuitionFee = null

  form: FormGroup;
  penalty: AbstractControl;
  note: AbstractControl;

  constructor(
    public dialogRef: MatDialogRef<AddStudentPenaltyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    public ds: DataService,
    public env: Environment,
    public studentStore: Student,
    public invoice: Invoice,
    public baseStore: BaseStore,
    public payment: Payment,
  ) { }

  process = true;
  async ngOnInit() {
    this.buildForm();

    await this.env.fetchUserDoc()
    this.tuitionFee = this.payment.paymentTuitionFee && this.payment.paymentTuitionFee.length > 0 ? this.payment.paymentTuitionFee[0] : null;
    if (this.tuitionFee) {
      const {selectedSchool} = this.env
      this.penaltyData = await this.payment.calStudentPenalty(selectedSchool.key,this.tuitionFee)
      this.selectedAcademicYear = this.penaltyData.academicYearData || null

      this.form.patchValue({
        penalty: this.penaltyData && this.penaltyData.total ? this.penaltyData.total : null
      })
    }
    const { ITEM } = this.data
    if (ITEM) {
      const { amount, note } = ITEM
      this.form.patchValue({
        penalty: amount,
        note
      })
    }

    this.process = false;
  }

  buildForm(): void {
    this.form = this.fb.group({
      penalty: [null, [Validators.compose([Validators.required, this.validCashIn.bind(this)])]],
      note: [null, Validators.required],
    })
    this.penalty = this.form.controls['penalty'];
    this.note = this.form.controls['note'];
  }

  public validCashIn(control: AbstractControl): { [s: string]: boolean } {
    const value = control.value;
    if (value !== undefined && value !== null && value !== '') {
      if (value < 0) {
        return { validKey: true }
      }
    }
  }

  displayItem(item: any): string {
    return item ? item.name : item;
  }

  create(f: any) {
    if (this.penaltyData && this.form.valid) {

      const { user, selectedCampus, selectedSchool } = this.env;
      const { student, issue_year, program, key, headerRef } = this.tuitionFee;
      const { penalty, note } = f;

      const { ITEM } = this.data

      const item: IAddPenalty = {
        key: ITEM ? ITEM.key : this.ds.createId(),
        create_date_key: ITEM ? ITEM.create_date_key : ConvertService.dateKey(),
        create_date: ITEM ? ITEM.create_date : new Date(),
        create_by: ITEM ? ITEM.create_by : userObj(user),
        status: ITEM ? ITEM.status : recordStatus.active,

        update_date_key: ConvertService.dateKey(),
        update_date: new Date(),
        update_by: userObj(user),

        student: student,
        academicYearKey: issue_year.key,
        admissionKey: program.admissionKey,
        programKey: program.program.key,
        levelKey: program.key,

        total_penalty: this.penaltyData.total,
        amount: penalty,
        note: note,
        invoiceKey: key,
        headerRef: headerRef,
        isTran: false,
        isPaid: false,

        request_status: REQUEST_STATUS.pending,

        campusKey: selectedCampus.key,
        campus: campusObj(selectedCampus),
        schoolKey: selectedSchool.key,
        school: selectedSchool,
      }

      this.payment.addRequestStudentPenalty(item, (success, error) => {
        if (success) {
          this.snackBar.open("Penalty has been requested.", "Done", { duration: 2000 })
          this.dialogRef.close('yes');
        }
        else {
          this.snackBar.open(error, "Error");
        }
      })
    }
  }
}