import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Student } from 'src/app/stores/student.store';
import { MatDialog, MatSnackBar } from '@angular/material';
import { BaseStore } from 'src/app/stores/base.store';
import { DataService } from 'src/app/services/data.service';
import { ConvertService } from 'src/app/services/convert.service';

@Component({
  selector: 'app-student-scholarship',
  templateUrl: './student-scholarship.component.html',
  styleUrls: ['./student-scholarship.component.scss']
})
export class StudentScholarshipComponent implements OnInit {
  studentKey: string;

  constructor(
    public store: Student,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public base: BaseStore,
    private ds: DataService,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.route.parent.params.forEach((params) => {
      this.studentKey = ConvertService.toNull(params["id"]);
      this.store.fetchScholarshipData(this.studentKey);
      // this.id = id;
      // this.store.programKey = programKey || null;
      // this.testingKey = testingKey || null;
      // this.store.fetchStudentDocID(this.id, res => {
      //   this.store.fetchStudentTest(this.testingKey, this.id);
      // });
      // this.store.fetchStudentAdmission(this.id);
    });
  }

}
