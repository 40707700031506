import { Institute } from './../../stores/institute.store';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { tabs } from './../../dummy/tabs';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-institutes-and-centers',
  templateUrl: './institutes-and-centers.component.html',
  styleUrls: ['./institutes-and-centers.component.scss']
})
export class InstitutesAndCentersComponent implements OnInit {
  tabs = tabs.institute;

  constructor(
    public store: Institute,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
   this.store.fetchProgram();
  }


}
