import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import * as XLSX from 'xlsx';
import { StudentUnpaidPaymentListingComponent } from './student-unpaid-payment-listing/student-unpaid-payment-listing.component';
import { tabs } from 'src/app/dummy/tabs';
import { PrintService } from 'src/app/services/print.service';
import { Environment } from 'src/app/stores/environment.store';
import { MappingService } from 'src/app/services/mapping.service';
import { ClearPaymentStore } from 'src/app/stores/clearPayment.store';
import { SCHOOL } from 'src/app/dummy/config';
import { ConvertService } from 'src/app/services/convert.service';

@Component({
  selector: 'app-clear-student-payment',
  templateUrl: './clear-student-payment.component.html',
  styleUrls: ['./clear-student-payment.component.scss']
})
export class ClearStudentPaymentComponent implements OnInit {
  @ViewChild('table') table: ElementRef;
  school = SCHOOL
  // FINANCE_FUNCTION = FINANCE_FUNCTION.cashier_report;

  tabs = tabs.clearStudentPayment;
  form: FormGroup;
  fromDate: AbstractControl;
  toDate: AbstractControl;
  campus: AbstractControl;

  filteredCampusStates: Observable<any>;
  type: string;
  process: boolean = false;
  constructor(
    private fb: FormBuilder,
    private ps: PrintService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public auth: Environment,
    public store: ClearPaymentStore,
  ) { }

  print() {
    this.ps.print("#print", "a4");
  }
  fireEvent() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, 'SheetJS.xlsx');

  }
  async ngOnInit() {
    this.buildForm();
    await this.auth.fetchUserDoc()
    const { user } = this.auth;
    const campusList = await this.auth.fetchCampusByRole(user);
    this.filteredCampusStates = MappingService.autoComplete(this.campus, campusList, "name");

    const fromDateData = ConvertService.startMonthFromDate(new Date)
    const toDateData = ConvertService.endMonthFromDate(new Date)

    this.form.patchValue({
      campus: campusList[0],
      fromDate: fromDateData,
      toDate: toDateData,
    })

    this.getList(fromDateData, toDateData, campusList[0].key, user)
  }

  async getList(fromDate, toDate, campusKey, user) {
    const { selectedSchool } = this.auth
    await this.store.fetchData(selectedSchool.key, campusKey, fromDate, toDate);
  }

  buildForm(): void {
    this.form = this.fb.group({
      fromDate: [null, Validators.required],
      toDate: [null, Validators.required],
      campus: [null, Validators.compose([MappingService.validSelected.bind(this)])],
    });

    this.fromDate = this.form.controls["fromDate"];
    this.toDate = this.form.controls["fromDate"];
    this.campus = this.form.controls["toDate"];
  }

  displayItemCampus(item: any): string {
    return item ? item.name : item;
  }

  _onPress(f: any) {
    if (this.form.valid) {
      const { fromDate, toDate, term, cashier, campus } = f;
      if (f && f.campus) {
        this.getList(fromDate, toDate, campus.key, this.auth.user);
      } else {
        this.getList(fromDate, toDate, null, this.auth.user);
      }
    }
  }

  async _showStudentPayment(item) {
    const importData = await this.store.fetchImportPayment(item)
    if (importData && importData.isPaid) return;
    let dialogRef = this.dialog.open(StudentUnpaidPaymentListingComponent, {
      data: item,
      panelClass: "register-test-overlay-panel",
      width: "45vw",
      height: "100vh",
      disableClose: false,
      role: "dialog"
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => { });
  }

}
