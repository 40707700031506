import { Genders, TestFeeStatus } from "../../../dummy/status";
import { Environment } from "./../../../stores/environment.store";
import { Testing } from "./../../../stores/testing.store";
import { Router, ActivatedRoute } from "@angular/router";
import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import {
  FormGroup,
  AbstractControl,
  Validators,
  FormBuilder
} from "@angular/forms";
import { MatSnackBar } from "@angular/material";
import { ITesting } from "src/app/interfaces/testing";
import { TestingStatus } from "src/app/dummy/status";
import { ConvertService } from "src/app/services/convert.service";
import { Miscellaneous } from "src/app/stores/miscellaneous.store";

@Component({
  selector: 'app-miscellanous-fee-form',
  templateUrl: './miscellanous-fee-form.component.html',
  styleUrls: ['./miscellanous-fee-form.component.scss']
})
export class MiscellanousFeeFormComponent implements OnInit {
  @ViewChild("focusInput")
  inputEl: ElementRef;

  form: FormGroup;
  first_name: AbstractControl;
  last_name: AbstractControl;
  gender: AbstractControl;
  cash_in: AbstractControl;

  genderList = Genders;
  testData: any;
  change: number;
  amountPay: number = 0;
  misKey: string;
  testingOption: any;
  admissionFee: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public store: Miscellaneous,
    public env: Environment,
    private fb: FormBuilder,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.buildForm();
    this.route.params.forEach(params => {
      this.misKey = params["id"];
      this.store.fetchEachMis(this.misKey, res=> {
        this.admissionFee = res;
        const {price}=res;
        this.amountPay=price;
        this.change = price - this.amountPay;
        this.form.patchValue({
          cash_in: price
        });
      });
      // this.store.selectedInstitute(this.instituteKey);
      // this.env.fetchConfigToArray(doc => {
      //   const {price}=doc.testing_fee;
      //   this.amountPay=price;
      //   this.change = price - this.amountPay;
      //   this.form.patchValue({
      //     cash_in: price
      //   });
      // });
    });
  }

  compareObjects(o1: any, o2: any): boolean {
    if (o2) {
      return o1.text === o2.text;
    }
  }

  buildForm(): void {
    this.change = 0;
    this.form = this.fb.group({
      first_name: [null, Validators.required],
      last_name: [null, Validators.required],
      gender: [this.genderList[0], Validators.required],
      cash_in: [null, this.validCashIn.bind(this)]
    });
    this.first_name = this.form.controls["first_name"];
    this.last_name = this.form.controls["last_name"];
    this.cash_in = this.form.controls["cash_in"];
    this.gender = this.form.controls["gender"];
    this.change = 0 - this.amountPay;
  }

  public validCashIn(control: AbstractControl): { [s: string]: boolean } {
    const value = control.value;
    if (value !== undefined && value !== null && value !== "") {
      this.change = ConvertService.getNumber(value, 0) - this.amountPay;
      if (this.change < 0) {
        return { validKey: true };
      }
    } else {
      this.change = 0 - this.amountPay;
    }
  }

  create(f: any) {
    if (this.form.valid) {
      const formData: ITesting = {
        key: null,
        first_name: f.first_name,
        last_name: f.last_name,
        dob: f.dob,
        mobile_phone: f.mobile_phone,
        status: TestingStatus.active,
        create_date: new Date(),
        create_date_key: ConvertService.dateKey(),
        create_by: this.env.user,
        page_key: ConvertService.pageKey(),
        isPaid: TestFeeStatus.unpaid,
        isPaidTest: false,
        testingStatus: TestingStatus.active,
      };

      // this.store.addTest(
      //   formData,
      //   this.env.config,
      //   this.env.user,
      //   (success, res) => {
      //     if (success) {
      //       this.testData = res;
      //       this.snackBar.open("Testing has been created.", "done", {
      //         duration: 2000
      //       });
      //       this.form.reset();
      //       stepper.next();
      //     } else {
      //       this.snackBar.open(res, "Error");
      //     }
      //   }
      // );
    }
  }
}
