import { Environment } from 'src/app/stores/environment.store';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/stores/user.store';
import { tabs } from 'src/app/dummy/tabs';

@Component({
  selector: 'app-testing-fee',
  templateUrl: './testing-fee.component.html',
  styleUrls: ['./testing-fee.component.scss'],
})

export class TestingFeeComponent implements OnInit {
  tabs=tabs.payments
  
  constructor(
    private router: Router,
    public store:User,
    public env:Environment
  ) { }

  ngOnInit() {}
 
  _goBack() {
    this.router.navigate(['/']);
  }


}