import { MatDialog, MatSnackBar } from '@angular/material';
import { ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { DeleteComponent } from 'src/app/components/delete/delete.component';
import { AddNewOtherPaymentFeeDetailComponent } from './add-new-other-payment-fee-detail/add-new-other-payment-fee-detail.component';
import { EditOtherPaymentFeeDetailComponent } from './edit-other-payment-fee-detail/edit-other-payment-fee-detail.component';
import { OtherService } from 'src/app/stores/otherService.store';
import { BaseStore } from 'src/app/stores/base.store';
import { DataService } from 'src/app/services/data.service';
import { Environment } from 'src/app/stores/environment.store';

@Component({
  selector: 'app-other-payment-fee-detail',
  templateUrl: './other-payment-fee-detail.component.html',
  styleUrls: ['./other-payment-fee-detail.component.scss']
})
export class OtherPaymentFeeDetailComponent implements OnInit {
  id: string;
  constructor(
    public store: OtherService,
    public baseStore: BaseStore,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private ds: DataService,
    private env: Environment
  ) { }

  async ngOnInit() {
    await this.env.fetchUserDoc();
    const {selectedSchool} = this.env
    this.store.fetchOtherServiceFee(selectedSchool.key);
    this.route.params.forEach(params => {
      this.id = params["id"];
      this.store.fetchSingleOtherFee(selectedSchool.key,this.id);
      this.store.fetchOtherFeeDetail(selectedSchool.key,this.id);
    })
  }

  create() {
    let dialogRef = this.dialog.open(AddNewOtherPaymentFeeDetailComponent, {
      data: { parent: this.store.selectedOtherFee },
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: true,
      role: "dialog"
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => { });
  }

  edit(item: any) {
    let dialogRef = this.dialog.open(EditOtherPaymentFeeDetailComponent, {
      data: { parent: this.store.selectedOtherFee, item: item },
      width: '35vw',
      height: '100vh',
      role: 'dialog',
    });
    dialogRef.updatePosition({ top: '0', right: '0', bottom: '0' });
  }

  delete(item: any) {
    let dialogRef = this.dialog.open(DeleteComponent, {
      data: { title: 'Delete Other Fee Detail', memo: 'If Other Fee Detail is using by other function in system you cannot delete it.', name: item.name },
      width: '35vw',
      disableClose: true,
      role: 'dialog',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'yes') {
        const {selectedSchool} = this.env
        this.baseStore.delete(this.ds.otherServiceFeeDocRef(selectedSchool.key,this.id).collection("admission_service_other_detail"), item, (success, error) => {
          if (success) {
            this.snackBar.open('Other Fee Detail has been deleted.', 'done', { duration: 2000 });
          }
          else {
            this.snackBar.open(error, 'Error')
          }
        })
      }
    });
  }

}
