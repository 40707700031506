
import { DataService } from "./../services/data.service";
import { observable, computed, action, autorun } from "mobx";
import { Injectable } from "@angular/core";
import { MappingService, pushToArray, pushToObject, studentObj, userObj } from "../services/mapping.service";
import { admissionType } from "../dummy/status";
import { Router } from "@angular/router";
import { AuthService } from "../auth/auth.service";

@Injectable()
export class Student {
  @observable public loading = false;
  @observable public empty = false;
  @observable public done = false;
  @observable public fetching = false;
  @observable public process = false;

  @observable public data = null;
  @observable public payPageKey = null;
  @observable public testing = null;
  @observable public student = null;
  @observable public filterType = null;
  @observable public admissions = null;
  @observable public alignPayment = null;
  @observable public selectedAdmission = null;
  @observable public scholarship = null;
  @observable public scholarshipList = [];
  @observable public prepaid = null;
  @observable public studentAdmission = null;
  @observable public installment = null;
  @observable public admissionKey = null;
  @observable public studentPrepaid = [];

  @observable programKey: any = null;
  @observable public studentTesting = null;
  @observable public studentTestings = [];
  @observable public selectedAcademicYear = null;

  public lastVisible: any = new Date();
  constructor(
    private ds: DataService,
    private router: Router,
    private auth: AuthService,
  ) { }

  @observable fetchScholarshipDataRef: any = null;
  @action
  fetchScholarshipData(studentKey: string) {
    this.loading = true;
    this.fetchScholarshipDataRef = this.ds.scholarshipDBByStudentRef(studentKey)
      .valueChanges()
      .subscribe(doc => {
        this.scholarshipList = MappingService.orderByDesc(doc, "page_key");
        this.empty = doc.length === 0;
        this.loading = false;
      });
  }

  @action
  async fetchOtherServiceFee(schoolKey: string) {
    const docs = await this.ds.otherServiceFeeRef(schoolKey).get().toPromise();
    return MappingService.orderBy(pushToArray(docs), "order");
  }

  @action
  fetchStudentByAdmission(key, callback) {
    this.loading = true;
    this.ds.studentByAdmissionRef(key).valueChanges().subscribe(docs => {
      this.studentAdmission = docs.length > 0 ? docs[0] : null;
      this.selectedAdmission = this.studentAdmission;
      this.loading = false;
      callback(this.studentAdmission)
    })
  }

  @action
  async fetchStudentAdmissionDoc(yearKey: string, key: string) {
    const docs = await this.ds.academicYearDocRef(yearKey).collection("admission").doc(key).get().toPromise();
    const data: any = pushToObject(docs)
    return data;
  }

  @action
  async fetchStudentProfile(studentKey, callback) {
    this.loading = true;
    const studentDoc = await this.ds.studentDocument(studentKey).get().toPromise();
    const studentData = MappingService.pushToObject(studentDoc);
    callback(studentData)
  }

  @action
  async resolveAdmission(student: any) {
    const batch = this.ds.batch();
    const { key, program_academic } = student;

    const admissionDoc = await this.ds.admissionByStudentRef(key).get().toPromise();
    const admissionData = MappingService.pushToArray(admissionDoc);

    let programAcademic = program_academic;

    if (admissionData && admissionData.length === 1) {
      programAcademic = admissionData[0].program_academic;
    } else if (admissionData && admissionData.length > 1) {
      const academicAdmission = admissionData.filter(m => m.admission_type.key === admissionType.academic.key);
      const academicInstitute = admissionData.filter(m => m.admission_type.key === admissionType.institute.key);

      if (academicInstitute && academicInstitute.length > 0) {
        const institute = academicInstitute.filter(m => m.program_academic.category === "DBrSzLY3UQ2kEgF50nfU");
        if (institute) {
          programAcademic = institute[0].program_academic;
        } else {
          programAcademic = academicInstitute[0].program_academic;
        }
      } else if (academicAdmission && academicAdmission.length > 0) {
        programAcademic = academicAdmission[0].program_academic;
      }
    }

    const studentRef = this.ds.studentFireRef().doc(key);
    batch.update(studentRef, {
      program_academic: programAcademic
    })

    batch.commit().then(() => {
      this.router.navigate(["/student/" + student.key + "/" + programAcademic.admissionKey + '/receive-payment']);
    })
  }

  @action
  async fetchAcademicYear() {
    let selectedAcademicYear = null;
    const { uid } = this.auth.authRef().auth.currentUser;
    const userDoc: any = await this.ds.userDocRef(uid).get().toPromise();
    const userData = MappingService.pushToObject(userDoc);
    const { schoolKey } = userData
    const envDoc: any = await this.ds.environmentRef(schoolKey).get().toPromise();
    const envData = MappingService.pushToObject(envDoc);
    if (this.selectedAdmission) {
      const { program_academic } = this.selectedAdmission;
      if (program_academic && program_academic.program.programOption.key === 1)
        selectedAcademicYear = envData.year;
      else
        selectedAcademicYear = envData.term;
    }
    return selectedAcademicYear;
  }

  @action
  clearStudentStore() {
    this.student = null;
    this.selectedAdmission = null;
    this.admissions = [];
    this.selectedAcademicYear = null;
  }

  @action
  async fetchStudent(studentKey: string, admissionKey: string, callback) {
    this.clearStudentStore();
    this.loading = true;
    this.ds.studentDocument(studentKey).valueChanges().subscribe(async doc => {

      const admissionDoc = await this.ds.admissionRef().doc(admissionKey).get().toPromise();
      const admissionList = await this.ds.studentAllAdmissionRef(studentKey).get().toPromise();

      const studentAdmissions = MappingService.orderByDesc(MappingService.pushToArray(admissionList), "academicYear.startDate");
      this.admissions = MappingService.groupByDesc(studentAdmissions, "program_academic.program.key", "academicYear.startDate")

      this.selectedAdmission = MappingService.pushToObject(admissionDoc)
      this.student = doc;

      this.selectedAcademicYear = await this.fetchAcademicYear();
      this.loading = false;
      callback(this.student)
    })
  }

  @action
  async fetchStudentPrepaid(student: any, callback) {
    this.studentPrepaid = [];
    this.process = true;
    const prepaidDoc = await this.ds.studentDocument(student.key).collection("prepaid", ref => ref.where("clear_prepaid", "==", false)).get().toPromise();
    const data = MappingService.pushToArray(prepaidDoc);
    this.studentPrepaid = data;
    this.process = false;
    callback(this.studentPrepaid);
  }

  @action
  fetchScholarshipByProgram(studentKey: string, programKey: string, callback) {
    this.scholarship = null;
    if (!programKey) {
      this.scholarship = null;
      this.loading = false;
      callback(null)
      return;
    }
    this.ds.studentScholarshipByProgram(studentKey, programKey).get().then(docs => {
      const dataArray = docs.empty ? null : docs.docs.map(m => ({ ...m.data() }));
      this.scholarship = !docs.empty ? dataArray[0] : null;
      callback(this.scholarship)
    })
  }

  @action
  fetchStudentToDoc(key: string, callback) {
    this.ds.studentDocument(key).valueChanges().subscribe(doc => {
      // this.student = doc;
      // const { prepaid } = doc;
      // this.prepaid = prepaid
      callback(doc)
    })
  }

  @action
  fetchTesting(studentKey: string) {
    this.loading = true;
    this.ds.testingByStudentRef(studentKey).valueChanges().subscribe(docs => {
      this.empty = docs.length === 0;
      this.testing = docs;
      this.loading = false;
    })
  }

  @action
  fetchSelectedAdmission(admissionKey, callback) {
    this.loading = true;
    this.ds.admissionRef().doc(admissionKey).valueChanges().subscribe(doc => {
      this.selectedAdmission = doc
      this.loading = false;
      callback(doc);
    })
  }

  @action
  async fetchStudentDocID(studentKey, callback) {
    this.ds.studentDocument(studentKey).valueChanges().subscribe(async doc => {
      // const { program_academic } = doc;
      // this.student = doc;
      // this.selectedAdmission = null;
      // if (program_academic && program_academic.admissionKey) {
      //   const admissionData = await this.ds.studentAdmissionDBRef(program_academic.admissionKey).get().toPromise();
      //   const admissionDoc = pushToObject(admissionData);
      //   this.selectedAdmission = admissionDoc;
      // }
      callback(doc);
    });
  }

  @action
  async fetchStudentDoc(studentKey: string) {
    this.process = true;
    const data = await this.ds.studentDocument(studentKey).get().toPromise();
    this.process = false;
    return MappingService.pushToObject(data);
  }

  @action
  async updateStudentAndTesting(item: any, student: any, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const studentRef = this.ds.studentFireRef().doc(student.key);
    const testingRef = this.ds.testingStudentRef();
    const academicYearRef = this.ds.academicYearFireRef();
    const levelBatchRef = this.ds.levelBatchFireRef();
    const admissionRef = this.ds.studentAdmissionFireRef();

    const testingDoc = await this.ds.studentTestingRef(student.key).get().toPromise();
    const testingData = MappingService.pushToArray(testingDoc);

    const envDoc = await this.ds.environmentRef(student.schoolKey).get().toPromise();
    const envData = MappingService.pushToObject(envDoc);

    const { year } = envData;

    const admissionDoc = await this.ds.academicYearRef().doc(year.key).collection("admission", ref => ref
      .where("student.key", "==", student.key)).get().toPromise();
    const admissionData = MappingService.pushToArray(admissionDoc);

    const studentDoc = await this.ds.studentDocument(student.key).get().toPromise();
    const studentProfile = MappingService.pushToObject(studentDoc);

    const studentData = {
      key: student.key,
      ...item,
      ...studentProfile,
    }

    const { program_academic } = studentProfile;
    if (program_academic && program_academic.key) {
      const batchData = studentProfile[program_academic.key];
      if (batchData) {

        const enrollDoc = await this.ds.levelBatchDBFireRef().doc(batchData.key).collection("students", ref => ref
          .where("student.key", "==", student.key)).get().toPromise();
        const enrollData = MappingService.pushToArray(enrollDoc);

        if (enrollData && enrollData.length > 0) {
          enrollData.forEach(e => {
            batch.update(levelBatchRef.doc(batchData.key).collection("students").doc(e.key), { student: MappingService.studentObj(studentData) })
          })
        }
      }
    }

    if (admissionData && admissionData.length > 0) {
      admissionData.forEach(m => {
        batch.update(admissionRef.doc(m.key), { student: MappingService.studentObj(studentData) });
        batch.update(academicYearRef.doc(year.key).collection("admission").doc(m.key), { student: MappingService.studentObj(studentData) });
      })
    }

    batch.update(studentRef, { ...item })

    if (testingData && testingData.length > 0) {
      testingData.forEach(m => {
        batch.update(testingRef.doc(m.key), {
          ...item,
          student: MappingService.studentObj(studentData),
        })
      })
    }

    batch.commit().then(() => {
      this.process = false;
      callback(true, null);
    })
      .catch(error => {
        this.process = false;
        alert(error)
        callback(false, error);
      });
  }

  @action
  async updateStudentProfile(student, item: any, callback) {
    this.process = true;

    const { update_by, update_date_key, update_date, puc_id } = item
    const { key, program_academic } = student
    const { admissionKey } = program_academic

    const studentDoc = await this.ds.studentDocument(key).get().toPromise()
    const studentData: any = pushToObject(studentDoc)

    const studentAccDoc = await this.ds.studentAccountDocRef(key).get().toPromise()
    const studentAccData = pushToObject(studentAccDoc)

    const email = `${puc_id}@gmail.com`;
    const studentItem = {
      ...studentData,
      ...item,
      email: email,
      key: key,
    }

    const studentAccItem = {
      ...studentAccData,
      email: email,
      puc_id: puc_id,
      student: studentObj(studentItem),
      key: key,
      resetAuth: true,
      update_by,
      update_date_key,
      update_date
    }

    const batch = this.ds.batch();
    const studentRef = this.ds.studentFireRef()
    const studentAccRef = this.ds.studentAccountFireRef()
    const admissionRef = this.ds.studentAdmissionFireRef()

    batch.update(admissionRef.doc(admissionKey), { isPaid: true })
    batch.update(studentRef.doc(studentItem.key), studentItem)

    // UPDATE AUTH
    if (puc_id !== studentData.puc_id) {
      batch.update(studentAccRef.doc(studentAccItem.key), studentAccItem)
    }

    batch.commit().then(() => {
      this.process = false;
      callback(true, null);
    }).catch(error => {
      this.process = false;
      alert(error)
      callback(false, error);
    });
  }

  @action
  fetchStudentTest(testingKey, studentKey) {
    this.loading = true;
    if (testingKey) {
      this.ds.testingStudent(testingKey).valueChanges().subscribe(doc => {
        this.studentTesting = doc;
        this.loading = false;
      })
    }
    else {
      this.ds.studentTestRef(studentKey).valueChanges().subscribe((docs: any) => {
        this.studentTesting = docs.length > 0 ? docs[0] : null;
        this.loading = false;
      })
    }
  }

  @action
  fetchStudentTestings(studentKey) {
    this.loading = true;
    this.ds.studentTestRef(studentKey).valueChanges().subscribe((docs: any) => {
      this.studentTestings = docs
      this.loading = false;
    })
  }

  @action
  fetchStudentAlignPayment(studentKey) {
    this.loading = true;
    this.ds.studentAlignPaymentRef(studentKey).valueChanges().subscribe(docs => {
      this.alignPayment = MappingService.orderByDesc(docs, "create_date");
      this.loading = false;
    })
  }

  @action
  fetchStudentAdmission(studentKey) {
    this.loading = true;
    this.ds.studentAllAdmissionRef(studentKey).valueChanges().subscribe(docs => {
      this.admissions = MappingService.orderByDesc(docs, "academicYear.startDate");
      this.loading = false;
    })
  }

  @action
  fetchAdmissionDocument(admissionKey, callback) {
    this.admissionKey = admissionKey;
    this.selectedAdmission = null;
    this.ds.studentAdmissionFireRef().doc(admissionKey).get().then(doc => {
      if (doc.exists) {
        this.selectedAdmission = doc.data();
      }
      callback(this.selectedAdmission);
    })
  }

  @action
  fetchAdmission(studentKey, callback) {
    this.loading = true;
    this.selectedAdmission = null
    this.ds.studentAdmissionRef(studentKey).valueChanges().subscribe(docs => {
      this.admissions = docs
      this.selectedAdmission = docs.length > 0 ? docs[0] : null;
      this.studentAdmission = this.selectedAdmission;
      this.loading = false;
      callback(this.admissions);
    })
  }

  @action
  fetchData(field, campus) {
    this.loading = true;
    this.ds.studentRef(field, campus).valueChanges().subscribe(docs => {
      this.empty = docs.length === 0;
      this.data = docs;
      this.loading = false;
    });
  }

  @action
  search(field, search, campus) {
    if (search.key) {
      return this.ds.studentSearchRef(field, search.puc_id, campus).valueChanges();
    }
    return this.ds.studentSearchRef(field, search, campus).valueChanges();
  }

  @action
  addMonkScholarship(item, callback) {
    const batch = this.ds.batch();
    const studentRef = this.ds.studentFireRef().doc(item.student.key);
    const scholarshipsRef = this.ds.scholarshipsFireRef().doc(item.key);

    batch.update(studentRef, {
      scholarship: MappingService.scholarshipObj(item)
    });
    batch.set(scholarshipsRef, item);

    batch.commit().then(() => {
      callback(true, null);
      this.process = false;
    })
      .catch(error => {
        callback(false, error);
        this.process = false;
      });
  }

  @action
  deleteMonkScholarship(item, callback) {
    const batch = this.ds.batch();
    const studentRef = this.ds.studentFireRef().doc(item.key);
    const scholarshipsRef = this.ds.scholarshipsFireRef().doc(item.scholarship.key);

    batch.update(studentRef, {
      scholarship: null
    });
    batch.delete(scholarshipsRef);

    batch.commit().then(() => {
      callback(true, null);
      this.process = false;
    })
      .catch(error => {
        callback(false, error);
        this.process = false;
      });
  }

  @action
  updateStudentDetail(item: any, callback) {
    this.process = true;
    this.ds.studentDocument(item.key).update({ ...item }).then(() => {
      this.process = false;
      callback(true, null);
    })
      .catch(error => {
        this.process = false;
        alert(error)
        callback(false, error);
      });
  }

  @action
  async fetchCheckVoidReceipt(studentKey, invoiceKey) {
    this.process = true;
    const invoiceDoc = await this.ds.studentDocument(studentKey).collection("request_void", ref => ref
      .where("invoiceKey", "==", invoiceKey)
    ).get().toPromise();
    const invoiceData = MappingService.pushToArray(invoiceDoc)
    this.process = false;
    return invoiceData && invoiceData.length > 0 ? true : false;
  }

  @action
  async fetchStudentVoucherDoc(storeKey, key) {
    this.process = true;
    const voucherDoc = await this.ds.storeDocRef(storeKey).collection("payment_discount_voucher_detail").doc(key).get().toPromise();
    const voucherData = MappingService.pushToObject(voucherDoc)
    this.process = false;
    return voucherData;
  }

  @action
  async addRequestVoidStudentReceipt(item: any, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const { student, schoolKey, campusKey } = item;

    const studentRef = this.ds.studentFireRef();
    const schoolRef = this.ds.schoolFireRef();

    batch.set(studentRef.doc(student.key).collection("request_void").doc(item.key), item)
    batch.set(schoolRef.doc(schoolKey).collection("request_void").doc(item.key), item)
    batch.set(schoolRef.doc(schoolKey).collection("campus").doc(campusKey).collection("request_void").doc(item.key), item)

    batch.commit().then(() => {
      this.process = false;
      callback(true, null)
    }).catch(error => {
      this.process = false;
      callback(false, error)
    })
  }

  @action
  async addDiscountVoucher(voucher: any, header: any, discountVoucher: any, user: any, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const { student, schoolKey } = header;

    const studentRef = this.ds.studentFireRef();
    const schoolRef = this.ds.schoolFireRef();

    batch.update(studentRef.doc(student.key).collection("invoices").doc(header.key), {
      discount: discountVoucher.amount,
      discount_voucher: discountVoucher,
      discount_voucher_date: new Date(),
      discount_voucher_by: userObj(user),
      discount_voucher_no: voucher.voucher_no,
      discount_voucher_data: voucher.discount_voucher,
      discount_voucher_detail_key: voucher.key,
      discount_voucher_apply_student: voucher.apply_student,
      discount_voucher_apply_voucher_multi: voucher.apply_voucher_multi,
    })

    batch.update(schoolRef.doc(schoolKey).collection("payment_discount_voucher_detail").doc(voucher.key), {
      invoiceKey: header.key,
      headerRef: header.key,
    })

    batch.commit().then(() => {
      this.process = false;
      callback(true, null)
    }).catch(error => {
      this.process = false;
      callback(false, error)
    })
  }

  @action
  async deleteDiscountVoucher(header: any, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const { student, schoolKey, discount_voucher_detail_key } = header;

    const studentRef = this.ds.studentFireRef();
    const schoolRef = this.ds.schoolFireRef();

    batch.update(studentRef.doc(student.key).collection("invoices").doc(header.key), {
      discount: null,
      discount_voucher: null,
      discount_voucher_date: null,
      discount_voucher_by: null,
      discount_voucher_no: null,
      discount_voucher_data: null,
      discount_voucher_detail_key: null,
      discount_voucher_apply_student: null,
      discount_voucher_apply_voucher_multi: null,
    })

    batch.update(schoolRef.doc(schoolKey).collection("payment_discount_voucher_detail").doc(discount_voucher_detail_key), {
      invoiceKey: null,
      headerRef: null,
    })

    batch.commit().then(() => {
      this.process = false;
      callback(true, null)
    }).catch(error => {
      this.process = false;
      callback(false, error)
    })
  }

}
