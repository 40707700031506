import { Pipe, PipeTransform } from '@angular/core';
import { MappingService } from '../services/mapping.service';

@Pipe({
  name: 'sumOtherFee'
})
export class SumOtherFeePipe implements PipeTransform {

  transform(value: any, data?: Array<any>, key?: any, sumField?: any): any {
    if (!data || data.length == 0) return 0;
    const filter = data.filter(m => m.course && m.course.key === key);
    const total = MappingService.sum(filter.slice(), sumField);
    return total;
  }

}
