import { DataService } from "./../services/data.service";
import { observable, action } from "mobx";
import { Injectable } from "@angular/core";
import { MappingService } from "../services/mapping.service";
import { enrollmentTypes } from "../dummy/status";
import { ConvertService } from "../services/convert.service";

@Injectable()
export class Unpaid {
  @observable
  public data = null;
  @observable
  public loading = false;
  @observable
  public empty = false;
  @observable
  public process = false;
  @observable
  public paidInstitute = false;
  @observable
  public paidAcademic = false;

  constructor(private ds: DataService) { }

  @action
  fetchData(studentKey: string) {
    this.loading = true;
    this.paidInstitute = false;
    this.paidAcademic = false;
    this.data = null
    this.ds.studentDocument(studentKey).collection("invoices", ref => ref.where("course.key", "==", "unpaid")).valueChanges().subscribe(docs => {
      if (docs.length > 0) {
        this.data = docs.filter(m => m.isEnrollVerify === true && m.isHeader === true);
        const invoiceInstitute = this.data.filter(m => m.enrollment_type.key == enrollmentTypes.institute.key)
        this.paidInstitute = invoiceInstitute.length > 0;
        const invoiceAcademic = this.data.filter(m => m.enrollment_type.key == enrollmentTypes.academic.key)
        this.paidAcademic = invoiceAcademic.length > 0;
        this.empty = docs.length === 0;
      }
      this.loading = false;
    })
  }

  @action
  enrollEnglishPayment(admission, invoiceHeader, invoiceDetail, student: any, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const studentRef = this.ds.studentFireRef().doc(student.key);
    const admissionRef = this.ds.studentAdmissionFireRef();

    if (admission)
      batch.set(admissionRef.doc(admission.key), admission);

    batch.set(studentRef.collection("invoices").doc(invoiceHeader.key), invoiceHeader);
    batch.set(studentRef.collection("invoices").doc(invoiceDetail.key), invoiceDetail);
    batch.commit().then(() => {
      this.process = false;
      callback(true, null);
    }).catch(error => {
      this.process = false;
      callback(false, error);
    })
  }

  @action
  addStudentPrepaid(form: any, invoiceDetail, student: any, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const { issue_term, key, create_by } = invoiceDetail;
    let { amount, credit, qty} = form;
    const studentRef = this.ds.studentFireRef().doc(student.key);
    const prepaidRef = this.ds.academicsTermFireRef().doc(issue_term.key).collection("prepaid").doc(key);

    const data = {
      use_fee_by: MappingService.userObj(create_by),
      use_fee_date: new Date(),
      use_fee_date_key: ConvertService.toDateKey(new Date()),
      clear_prepaid: false,
      is_use_fee: true,
    }

    batch.update(studentRef, { 
      prepaid: ConvertService.toNumber(amount),
      prepaid_credit: ConvertService.toNumber(credit),
      prepaid_subject: ConvertService.toNumber(qty), 
    });
    batch.set(studentRef.collection("prepaid").doc(key), { ...invoiceDetail, ...data });
    batch.set(prepaidRef, { ...invoiceDetail, ...data });
    batch.commit().then(() => {
      this.process = false;
      callback(true, null);
    }).catch(error => {
      this.process = false;
      callback(false, error);
    })
  }

}
