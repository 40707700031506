import { ILevelFee } from "../interfaces/tuition";
import { DataService } from "../services/data.service";
import { observable, action } from "mobx";
import { Injectable } from "@angular/core";
import { MappingService, pushToArray, removeObjArray } from "../services/mapping.service";
import { ITrainingGradeFee, ITrainingGrade } from "../interfaces/trainingGrade";

@Injectable()
export class YearTraining {
  @observable public data = null;
  @observable public trainingFee = null;
  @observable public priceList = [];
  @observable public years = null;
  @observable public campus = null;
  @observable public trainings = null;
  @observable public groups = null;
  @observable public selectedYear = null;
  @observable public selectedCampus = null;
  @observable public selectedTraining = null;

  @observable public loading = false;
  @observable public empty = false;
  @observable public process = false;

  constructor(private ds: DataService) { }

  @action
  fetchTrainingFee(key: string, trainingKey: string) {
    this.process = true;
    this.ds.academicYearRef().doc(key).collection("academic_training_grade").doc(trainingKey).valueChanges().subscribe((doc: any) => {
      this.trainingFee = doc;
      this.priceList = doc.priceList || [];
      this.process = false;
    })
  }

  @action
  fetchAcademicYear() {
    this.loading = true;
    this.ds.academicYearRef().valueChanges().subscribe(docs => {
      this.years = docs;
      this.loading = false;
    })
  }

  @action
  fetchSingleAcademicYear(key: string) {
    this.loading = true;
    this.ds.academicYearDocRef(key).valueChanges().subscribe(doc => {
      this.selectedYear = doc;
      this.loading = false;
    })
  }

  @action
  fetchCampus() {
    this.loading = true;
    this.ds.campusRef().valueChanges().subscribe(docs => {
      this.campus = MappingService.orderBy(docs, "order");
      this.loading = false;
    })
  }

  @action
  fetchSingleCampus(key: string) {
    this.loading = true;
    this.ds.campusRef().doc(key).valueChanges().subscribe(doc => {
      this.selectedCampus = doc;
      this.loading = false;
    })
  }

  @action
  fetchAcademicYearTraining(key: string, campusKey: string) {
    this.loading = true;
    this.ds.academicYearDocRef(key).collection("academic_training_grade", ref => ref.where("campus.key", "==", campusKey)).valueChanges().subscribe(docs => {
      this.trainings = MappingService.orderBy(docs, "grade.order");
      this.groups = MappingService.groupBy(docs, "grade.type.key", "grade.type.key")
      this.empty = docs.length === 0;
      this.loading = false;
    })
  }

  @action
  async checkExistTrainingGrade(yearKey: string, gradeKey: string) {
    this.process = true;
    let isExist = false;
    const trainingData = await this.ds.academicYearDocRef(yearKey)
      .collection("academic_training_grade", ref => ref.where("grade.key", "==", gradeKey)).get().toPromise();
    const trainingDoc = MappingService.pushToArray(trainingData);
    if (trainingDoc && trainingDoc.length > 0) isExist = true;
    this.process = false;
    return isExist;
  }

  @action
  addTrainingGrade(item: ITrainingGrade, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const { academicYear } = item;
    const trainingGradeMovementRef = this.ds.academicTrainingYearMovementFireRef().doc(item.key);
    const trainingGradeRef = this.ds.academicYearFireRef().doc(academicYear.key).collection("academic_training_grade").doc(item.key);

    batch.set(trainingGradeMovementRef, item);
    batch.set(trainingGradeRef, item);
    batch.commit().then(() => {
      this.process = false;
      callback(true, null);
    }).catch(error => {
      this.process = false;
      callback(true, error);
    })
  }

  @action
  setFeeTrainingGrade(item: ITrainingGradeFee, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const { academicYear, trainingGradeKey, priceList } = item;
    const trainingGradeMovementRef = this.ds.academicTrainingYearMovementFireRef().doc(trainingGradeKey);
    const trainingGradeRef = this.ds.academicYearFireRef().doc(academicYear.key).collection("academic_training_grade").doc(trainingGradeKey);
    const trainingGradeFeeMovementRef = this.ds.academicTrainingFeeMovementFireRef().doc(item.key);

    batch.update(trainingGradeMovementRef, { priceList: priceList });
    batch.update(trainingGradeRef, { priceList: priceList });

    batch.set(trainingGradeFeeMovementRef, item);

    batch.commit().then(() => {
      this.process = false;
      callback(true, null);
    }).catch(error => {
      this.process = false;
      callback(true, error);
    })
  }

  @action
  removePaymentOption(paymentOption: any, item: ITrainingGrade, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const { academicYear, priceList } = item;
    const priceListData = removeObjArray(priceList, paymentOption.key);

    const trainingGradeMovementRef = this.ds.academicTrainingYearMovementFireRef().doc(item.key);
    const trainingGradeRef = this.ds.academicYearFireRef().doc(academicYear.key).collection("academic_training_grade").doc(item.key);

    batch.update(trainingGradeMovementRef, { priceList: priceListData });
    batch.update(trainingGradeRef, { priceList: priceListData });

    batch.commit().then(() => {
      this.process = false;
      callback(true, null);
    }).catch(error => {
      this.process = false;
      callback(true, error);
    })
  }

  @action
  updateTrainingGrade(item: ITrainingGrade, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const { academicYear } = item;
    const trainingGradeMovementRef = this.ds.academicTrainingYearMovementFireRef().doc(item.key);
    const trainingGradeRef = this.ds.academicYearFireRef().doc(academicYear.key).collection("academic_training_grade").doc(item.key);

    batch.update(trainingGradeMovementRef, item);
    batch.update(trainingGradeRef, item);
    batch.commit().then(() => {
      this.process = false;
      callback(true, null);
    }).catch(error => {
      this.process = false;
      callback(true, error);
    })
  }

  @action
  deleteTrainingGrade(item: ITrainingGrade, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const { academicYear } = item;
    const trainingGradeMovementRef = this.ds.academicTrainingYearMovementFireRef().doc(item.key);
    const trainingGradeRef = this.ds.academicYearFireRef().doc(academicYear.key).collection("academic_training_grade").doc(item.key);

    batch.delete(trainingGradeMovementRef);
    batch.delete(trainingGradeRef);
    batch.commit().then(() => {
      this.process = false;
      callback(true, null);
    }).catch(error => {
      this.process = false;
      callback(true, error);
    })
  }

}
