import { DataService } from "./../services/data.service";
import { observable, computed, action, autorun } from "mobx";
import { Injectable } from "@angular/core";


@Injectable()
export class Terms {
  @observable data=[];
  @observable loading = false;
  @observable process = false;
  @observable academic=null;

  constructor(private ds: DataService) {
    this.academicTermActive()
  }

  @action
  fetchTerm() {
    this.loading = true;
    this.ds
      .termRef()
      .valueChanges()
      .subscribe(docs => {
        this.data = docs;
        this.loading = false;
      });
  }

  @action
  fetchTermToArray(callback) {
    this.process = true;
    this.ds
      .termDocRef()
      .valueChanges()
      .subscribe(docs => {
        this.process = false;
        callback(docs);
      });
  }

  @action
  fetchShiftToArray(callback) {
    this.process = true;
    this.ds
      .shiftRef()
      .valueChanges()
      .subscribe(docs => {
        this.process = false;
        callback(docs);
      });
  }

  @action
  academicTermActive() {
    if(this.academic) return;
    this.loading = true;
    this.ds
      .termAcademicActive()
      .valueChanges()
      .subscribe(docs => {
        this.loading = false;
        if (docs.length > 0) this.academic = docs[0];
        else this.academic = null;
      });
  }

  
}
