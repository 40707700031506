import { ConvertService } from 'src/app/services/convert.service';
import { AuthService } from 'src/app/auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { Report } from './../../../stores/report.store';
import { Component, OnInit } from '@angular/core';
import { Environment } from 'src/app/stores/environment.store';
import { User } from 'src/app/stores/user.store';
import { PrintService } from 'src/app/services/print.service';
import { invoiceTypeParams } from 'src/app/dummy/status';
import { MatDialog } from '@angular/material';
import { InvoiceDialogComponent } from 'src/app/components/invoice-dialog/invoice-dialog.component';
import { SCHOOL } from 'src/app/dummy/config';

@Component({
  selector: 'app-receive-payment-by-program',
  templateUrl: './receive-payment-by-program.component.html',
  styleUrls: ['./receive-payment-by-program.component.scss']
})
export class ReceivePaymentByProgramComponent implements OnInit {
  type = "tuition_fee";
  reportName = "Tuition Fee";
  date = new Date();
  school=SCHOOL
  constructor(public env: Environment,
    public store: Report,
    public auth: AuthService,
    public user: User,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private ps: PrintService,
  ) { }

  ngOnInit() {
    this.route.params.forEach(param => {
      this.type = param["tid"];
      this.env.getUser(user => {
        this.reportName = invoiceTypeParams[this.type].text;
        if (user && user.shift)
          this.store.fetchReceiptTuitionFeeListing(user.shift.key, this.type);
      })
    });

  }

  _onPrint(item) {
    let dialogRef = this.dialog.open(InvoiceDialogComponent, {
      data: { studentKey: item.student.key, headerKey: item.headerRef },
      width: '90vw',
      height: '80vh',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  print() {
    this.ps.print('#print', 'a4');
  }

}
