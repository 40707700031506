import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'app-miscellaneous-fee-list',
  templateUrl: './miscellaneous-fee-list.component.html',
  styleUrls: ['./miscellaneous-fee-list.component.scss']
})
export class MiscellaneousFeeListComponent implements OnInit {
  @Input() data:Array<any>;
  @Input() headerText:string;
  @Output() onPress=new EventEmitter();
  @Input() group:Array<any>
  
    constructor() { }
  
    ngOnInit() {
      
    }
  
    _onClick(item){
      this.onPress.emit(item)
    }
  }