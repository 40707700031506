export const tabs = {
  panel: [
    { path: '/app/admission', label: 'All' },
    { path: '/app/loan', label: 'Pending' },
    { path: '/app/sibling', label: 'Prospective' },
    { path: '/app/paid-write-off', label: 'Admission' },
    { path: '/app/paid-write-off', label: 'Expired' },
  ],
  schedule: [
    { path: '/app/class-schedule/data', label: 'General' },
  ],
  academicYear: [
    { path: '/app/academic-year/data', label: "Data" },
  ],
  otherPaymentFee: [
    { path: '/app/other-payment-fee/data', label: "Data" },
  ],
  academicGrade: [
    { path: '/app/academic-grade/data', label: "Data" },
  ],
  academicTraining: [
    { path: '/app/academic-training/data', label: "Data" },
  ],
  academics: [
    { path: '/app/academic-programs/all', label: 'All' },
    { path: '/app/academic-programs/primarySchool', label: 'Primary School' },
    { path: '/app/academic-programs/secondarySchool', label: 'Secondary School' },
    { path: '/app/academic-programs/highSchool', label: 'High School' },
    { path: '/app/academic-programs/miscellaneous', label: 'Miscellaneous' },
  ],
  options: [
    { path: '/app/options', label: 'All' },
  ],
  clearStudentPayment: [
    { path: '//app/clear-student-payment', label: 'Data' },
  ],
  institute: [
    { path: '/app/institutes-and-center/listing', label: 'Training fee' },
    // { path: '/app/institutes-and-center/testing', label: 'Testing fee' },
  ],
  otherFee: [
    { path: '/app/other-fee', label: 'All' },
  ],
  testing: [
    { path: '/app/testing', label: 'All', key: 'all' },
    // { path: '/app/testing', label: 'Unpaid', 'key': 'unpaid' },
    // { path: '/app/testing', label: 'Paid', 'key': 'paid' },
    // { path: '/app/testing', label: 'Own', 'key': 'own' },
  ],
  pettyCash: [
    { path: '/app/petty-cash/list', label: 'Data' },
  ],

  payments: [
    // { path: '/payment-listing/all', label: 'All',icon:"ballot" },
    // { path: '/payment-listing/tuition_fee', label: 'Tuition Fee',icon:"how_to_reg" },
    // { path: '/payment-listing/academic_program', label: 'Academic',icon:"school" },
    // { path: '/payment-listing/institute_and_center', label: 'Institute',icon:"local_library" },
    { path: '/payment-listing/testing', label: 'Testing', icon: "playlist_add_check" },
    { path: '/payment-listing/abc', label: 'ABC', icon: "location_on" },
    { path: '/payment-listing/miscellaneous', label: 'Miscellaneous', icon: "local_offer" },
    { path: '/payment-listing/short_course', label: 'Short Course', icon: "phone_iphone" },
  ],
  summery: [
    { path: '/payment-summery/all', label: 'All', icon: "ballot" },
    // { path: '/payment-summery/tuition_fee', label: 'Tuition Fee', icon: "how_to_reg" },
    // { path: '/payment-summery/academic_program', label: 'Academic', icon: "school" },
    // { path: '/payment-summery/institute_and_center', label: 'Institute', icon: "local_library" },
    // { path: '/payment-summery/testing', label: 'Testing', icon: "playlist_add_check" },
    // { path: '/payment-summery/abc', label: 'ABC', icon: "location_on" },
    // { path: '/payment-summery/miscellaneous', label: 'Miscellaneous', icon: "local_offer" },
    // { path: '/payment-summery/short_course', label: 'Short Course', icon: "phone_iphone" },
  ],
  reportShift: [
    { path: '/report-by-shift/all', label: 'All', icon: "ballot" },
    { path: '/report-by-shift/close', label: 'Closing', icon: "cloud_off" },
  ],
  reportFilterBy: [
    { key: 'today', text: 'Today' },
    { key: 'yesterday', text: 'Yesterday' },
    { key: 'this_week', text: 'This week' },
    { key: 'this_month', text: 'This month' },
    { key: 'this_term', text: 'This term' },
    { key: 'this_year', text: 'This year' },
    { key: 'custom', text: 'Custom' },
  ],
  dailyShiftPanel: [
    { path: 'daily-shift-listing', label: 'All' },
  ],
  expensePanel: [
    { path: 'expense-listing', label: 'All' },
  ],
  installmentPanel: [
    { path: 'installment-listing', label: 'All' },
  ],
  paymentAR: [
    { path: '/app/payment-ar/today', label: 'All' },
  ],
}

