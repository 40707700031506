import { DataService } from "./../services/data.service";
import { observable, computed, action, autorun } from "mobx";
import { Injectable } from "@angular/core";
import { LocalStorage } from "@ngx-pwa/local-storage";

@Injectable()
export class Campus {
  @observable data = [];
  @observable loading = false;
  @observable selectedCampus = null;

  constructor
    (
      private ds: DataService,
      private localStorages: LocalStorage
    ) {
    // this.getLocal(() => {})
  }

  getLocal(callback) {
    this.localStorages.getItem<any>("puc_cloud_campus").subscribe(expansion => {
      if (expansion)
        this.selectedCampus = expansion;
      callback(true)
    },
      error => {
        callback(false)
      });
  }

  @action
  fetchCampus() {
    this.loading = true;
    this.ds
      .campusRef()
      .valueChanges()
      .subscribe(docs => {
        this.data = docs;
        if (docs.length > 0) {
          if (!this.selectedCampus) this.selectedCampus = docs[0];
        }
        this.loading = false;
      });
  }

  @action
  selecteCampus(campus: any) {
    this.localStorages.setItem("puc_cloud_campus", {
      id: campus.id,
      name: campus.name,
    }).subscribe(() => {
      this.selectedCampus = campus;
    }, error => { alert(error) });
  }

}
