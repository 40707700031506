import { AddTrainingFeeComponent } from './../../../components/add-training-fee/add-training-fee.component';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from "@angular/router";
import { Institute } from "./../../../stores/institute.store";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-institute-fee",
  templateUrl: "./institute-fee.component.html",
  styleUrls: ["./institute-fee.component.scss"]
})
export class InstituteFeeComponent implements OnInit {
  programKey = null;
  constructor(
    public store: Institute,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.store.fetchProgram();
    this.route.params.forEach(params => {
      this.programKey = params["id"];
      this.store.fetchProgramDoc(this.programKey);
      this.store.fetchTrainingLevel(this.programKey);
    })
  }
  
  _onSetupFee(item) {
    let dialogRef = this.dialog.open(AddTrainingFeeComponent, {
      data: item,
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: true,
      role: "dialog"
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => { });
  }
}
