import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Student } from 'src/app/stores/student.store';
import { MatDialog, MatSnackBar } from '@angular/material';
import { BaseStore } from 'src/app/stores/base.store';
import { DataService } from 'src/app/services/data.service';
import { AddStudentRetakeTestComponent } from './add-student-retake-test/add-student-retake-test.component';
import { AddTestingPaymentComponent } from 'src/app/components/add-testing-payment/add-testing-payment.component';
import { Environment } from 'src/app/stores/environment.store';
import { PrintService } from 'src/app/services/print.service';
import { Payment } from 'src/app/stores/payment.store';

@Component({
  selector: 'app-student-retake-test',
  templateUrl: './student-retake-test.component.html',
  styleUrls: ['./student-retake-test.component.scss']
})
export class StudentRetakeTestComponent implements OnInit {
  id: string;
  programKey: any;
  testingKey: any;

  constructor(
    public store: Student,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public base: BaseStore,
    private ds: DataService,
    private snackBar: MatSnackBar,
    public env: Environment,
    private ps: PrintService,
    public payment: Payment,
  ) { }

  ngOnInit() {
    this.route.parent.params.forEach((params) => {
      const { id, programKey } = params;
      this.id = id;
      this.store.programKey = programKey || null;
      this.store.fetchStudentDocID(this.id, res => {
        this.store.fetchStudentTestings(this.id);
      });
    });
  }

  _addNewTesting() {
    if (!this.store.student) return
    let dialogRef = this.dialog.open(AddStudentRetakeTestComponent, {
      data: { STUDENT: this.store.student },
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: true,
      role: "dialog",
      autoFocus: false,
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  _onReceivePayment(item) {
    if (item.isPaidTest || !this.env.user.shift || !this.env.sameDay) return;
    // if (item.isPaidTest || !this.env.user.shift) return;
    let dialogRef = this.dialog.open(AddTestingPaymentComponent, {
      data: item,
      panelClass: "register-test-overlay-panel",
      width: "35vw",
      height: "100vh",
      disableClose: true,
      role: "dialog"
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => {
      if (result === "print") {
        this.print();
      }
    });
  }

  print() {
    this.ps.print("#printD", "a5l");
  }

}
