export const SCHOOL = {
  // name: "វិទ្យាល័យព្រះស៊ីសុវត្ថ សាលារៀនជំនាន់ថ្មី", //Preah Sisowath High School (New Generation School)
  // name_kh: "វិទ្យាល័យព្រះស៊ីសុវត្ថ សាលារៀនជំនាន់ថ្មី",
  // email: "info@preahsisowath.edu.kh",
  // website: "https://preahsisowath.edu.kh/",
  // short_name: "NGS",
  // img: '/assets/images/ngs.png',
  // key:'ngs'

  // ngs-preakleap
  key: 'ngs-preakleap',
  name: "Prek Leap High School (New Generation School)",
  name_kh: "សាលារៀនជំនាន់ថ្មី វិទ្យាល័យ ព្រែកលៀប",
  email: "prekleaphighschool@gmail.com",
  website: "www.facebook.com/prekleaphighschool",
  short_name: "NGS-Preakleap",
  img: '/assets/images/preakleap.png',
};


export const CONFIG = {
  sps: "sovannaphumi_school",
  paymentYearKey: "zyZvPPEr4bJqHgaqFguw"
}

export const COLLECTION_DATA = {
  paymentDiscountVoucherDetail: "payment_discount_voucher_detail",
  products: "products",

};

export const FILTER_FIELD = {
  name: "name",
  keywords: "keyword",
  full_name: "full_name",
  voucher_no: "voucher_no",

};
