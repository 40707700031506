import { MatSnackBar } from '@angular/material';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { Student } from 'src/app/stores/student.store';
import { Payment } from 'src/app/stores/payment.store';
import { Environment } from 'src/app/stores/environment.store';
import { MappingService, toNumber } from 'src/app/services/mapping.service';
import { DataService } from 'src/app/services/data.service';
import { Invoice } from 'src/app/stores/invoice.store';
import { Registration } from 'src/app/stores/registration.store';
import { BaseStore } from 'src/app/stores/base.store';
import { PERIOD_FEE, PROGRAM_TERMS, PROGRAM_TERMS_OBJ } from 'src/app/dummy/status';
import { toJS } from 'mobx';
import { Router } from '@angular/router';
import { ConvertService } from 'src/app/services/convert.service';

@Component({
  selector: 'app-add-payment-cross-year',
  templateUrl: './add-payment-cross-year.component.html',
  styleUrls: ['./add-payment-cross-year.component.scss']
})
export class AddPaymentCrossYearComponent implements OnInit {
  form: FormGroup;
  fromDate: AbstractControl;
  toDate: AbstractControl;
  program_term: AbstractControl;
  academic_year: AbstractControl;
  paymentOption: AbstractControl;

  periodFee: AbstractControl;
  to_periodFee: AbstractControl;

  to_program_term: AbstractControl;
  to_academic_year: AbstractControl;
  to_paymentOption: AbstractControl;

  free_paymentOption: AbstractControl;

  selectedAcademicYear;
  selectedAcademicYearTo;
  programTermList = PROGRAM_TERMS;
  programTermListTo = PROGRAM_TERMS.filter(m => m.key === 1);

  periodFeeList = PERIOD_FEE;
  currentTerm;

  constructor(
    public dialogRef: MatDialogRef<AddPaymentCrossYearComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    public store: Registration,
    public ds: DataService,
    public env: Environment,
    public studentStore: Student,
    public invoice: Invoice,
    public baseStore: BaseStore,
    public payment: Payment,
    private router: Router

  ) { }

  buildForm(): void {
    this.form = this.fb.group({
      academic_year: [null, Validators.compose([Validators.required, MappingService.validSelected.bind(this)])],
      program_term: [PROGRAM_TERMS[0], Validators.compose([MappingService.validSelected.bind(this)])],
      paymentOption: [null, Validators.compose([MappingService.validSelected.bind(this)])],
      fromDate: [null, Validators.required],
      toDate: [null, Validators.required],

      to_academic_year: [null, Validators.compose([Validators.required, MappingService.validSelected.bind(this)])],
      to_program_term: [this.programTermListTo[0], Validators.compose([MappingService.validSelected.bind(this)])],
      to_paymentOption: [null, Validators.compose([MappingService.validSelected.bind(this)])],
      free_paymentOption: [null, Validators.compose([MappingService.validSelected.bind(this)])],

      periodFee: [null, Validators.compose([MappingService.validSelected.bind(this)])],
      to_periodFee: [null, Validators.compose([MappingService.validSelected.bind(this)])],

    })
    this.academic_year = this.form.controls['academic_year'];
    this.program_term = this.form.controls["program_term"];
    this.paymentOption = this.form.controls['paymentOption'];
    this.fromDate = this.form.controls['fromDate'];
    this.toDate = this.form.controls['toDate'];
    this.to_academic_year = this.form.controls['to_academic_year'];
    this.to_program_term = this.form.controls["to_program_term"];
    this.to_paymentOption = this.form.controls['to_paymentOption'];
    this.free_paymentOption = this.form.controls['free_paymentOption'];
    this.periodFee = this.form.controls['periodFee'];
    this.to_periodFee = this.form.controls['to_periodFee'];

  }

  currentYear: any;
  paymentOptionData = [];
  paymentOptionList = [];
  paymentOptionListTo = [];
  paymentOptionListFree = [];
  paymentList = [];
  paymentListTo = [];
  trainingLevelFee = [];
  trainingLevel = null;

  trainingLevelFeeTo = [];
  trainingLevelTo = null;

  paymentPeriodYear = null;

  optionData = null;
  studentData = null;
  oldInvoice = null;
  isPaidComplete = false;
  process = true;
  paymentStudentData;

  haveStudentDiscount = false;
  academicYearList = []
  allowFreePeriod = false

  async ngOnInit() {
    this.buildForm();
    await this.env.fetchUserDoc()
    const { selectedAdmission, student } = this.studentStore;
    const { campus, program_academic } = selectedAdmission;
    const { selectedSchool, selectedCampus } = this.env

    //ALLOW FREE PERIOD
    let freePeriod = await this.baseStore.fetchList(this.ds.storeDocRef(selectedSchool.key).collection("payment_free_period", ref => ref
      .where("status.key", "==", 1)
      .where("campusDataKey", "array-contains", selectedCampus.key)
    ));

    if (freePeriod && freePeriod.length > 0) {
      this.allowFreePeriod = freePeriod.filter(m => m.end_date_key && m.end_date_key >= ConvertService.dateKey()).length > 0 ? true : false
    }

    if (student && student.add_free_period) {
      this.allowFreePeriod = true
    }

    const envData: any = await this.env.fetchAcademicEnv(selectedSchool.key);

    this.studentData = await this.baseStore.fetchDoc(this.ds.studentDocument(student.key));
    this.optionData = await this.baseStore.fetchDoc(this.ds.settingDBFireStore());
    this.paymentOptionData = await this.baseStore.fetchList(this.ds.paymentOptionRef());
    this.paymentOptionList = this.paymentOptionData

    this.paymentOptionListFree = this.paymentOptionData.filter(m => m.period <= 6)
    this.paymentPeriodYear = this.paymentOptionData.find(m => m.period == 12)

    if (campus && program_academic) {
      const { program } = program_academic;
      let academicYearDoc = await this.baseStore.fetchList(this.ds.storeDocRef(selectedSchool.key).collection("academic_year", ref => ref.limit(50)))

      if (program.programOption.key === 1) {

        this.currentYear = envData.year;
        academicYearDoc = academicYearDoc.filter(m => m.termType.key === 1)
      } else {

        this.currentYear = envData.term;
        academicYearDoc = academicYearDoc.filter(m => m.termType.key === 2)
      }

      this.academicYearList = MappingService.orderByDesc(academicYearDoc, "startDate")
      this.selectedAcademicYear = this.academicYearList.find(m => m.key === this.currentYear.key)
      this.academic_year.patchValue(this.selectedAcademicYear)

      this.trainingLevelFee = await this.baseStore.fetchList(this.ds.campusRef().doc(campus.key).collection("training_levels").doc(program_academic.key).collection("training_school_fee"))
      this.trainingLevel = await this.baseStore.fetchDoc(this.ds.campusRef().doc(campus.key).collection("training_levels").doc(program_academic.key));

      if (this.trainingLevel) {
        const levelOrder = this.trainingLevel.order + 1
        const trainingLevelToData = await this.baseStore.fetchList(this.ds.campusRef().doc(campus.key).collection("training_levels", ref => ref
          .where("order", "==", levelOrder)
          .where("programKey", "==", program.key)
        ));

        if (trainingLevelToData && trainingLevelToData.length > 0) {
          this.trainingLevelTo = trainingLevelToData[0]
          this.trainingLevelFeeTo = await this.baseStore.fetchList(this.ds.campusRef().doc(campus.key).collection("training_levels").doc(this.trainingLevelTo.key).collection("training_school_fee"))
        }
      }
    }

    this.selectedAcademicYear = MappingService.pushToObject(await this.ds.academicYearRef().doc(this.currentYear.key).get().toPromise())

    const { program_term, termType } = this.selectedAcademicYear;

    if (termType.key === 1) this.program_term.enable()
    else
      this.program_term.patchValue(program_term)

    this.paymentStudentData = this.studentData[program_academic.program.key]

    if (this.paymentStudentData && this.paymentStudentData.endProgramTerm && this.paymentStudentData.endProgramYear) {
      const { endProgramTerm, endProgramYear } = this.paymentStudentData;
      const endProgramYearData = endProgramYear || { key: 2020, text: "2020-2021" }

      this.isPaidComplete = endProgramTerm.key === PROGRAM_TERMS_OBJ.term4.key &&
        endProgramYearData.key === this.selectedAcademicYear.academic_year.key ? true : false;

      const nextProgramTerm = endProgramTerm.key >= 4 ? endProgramTerm : PROGRAM_TERMS_OBJ[`term${endProgramTerm.key + 1}`]
      this.form.patchValue({
        program_term: nextProgramTerm
      })
      this.getPaymentOption(nextProgramTerm)
    }

    if (this.payment.paymentTuitionFee && this.payment.paymentTuitionFee.length > 0) {
      this.oldInvoice = this.payment.paymentTuitionFee[0];

      this.selectedAcademicYear = this.academicYearList.find(m => m.key === this.oldInvoice.issue_year.key)
      this.academic_year.patchValue(this.selectedAcademicYear)
    }

    this.currentTerm = this.program_term.value
    this.process = false;
  }

  async selectedYear(event) {
    const { value } = event.option
    if (value) {
      this.selectedAcademicYear = this.academicYearList.find(m => m.key === value.key)
      this.currentYear = this.academicYearList.find(m => m.key === value.key)
    }
  }

  getPaymentOption(value) {
    switch (value.key) {
      case 4:
        this.paymentOptionList = this.paymentOptionData.filter(m => m.period <= 3)
        break;
      case 3:
        this.paymentOptionList = this.paymentOptionData.filter(m => m.period <= 6)
        break;
      default:
        this.paymentOptionList = this.paymentOptionData
        break;
    }
  }

  selectedAcademicTerm(event) {
    this.paymentOption.patchValue(null)
    this.paymentList = []
    const { value } = event.option
    if (value) {
      this.getPaymentOption(value)
    }
  }

  getPaymentOptionTo(value) {
    switch (value.key) {
      case 4:
        this.paymentOptionListTo = this.paymentOptionData.filter(m => m.period <= 3)
        break;
      case 3:
        this.paymentOptionListTo = this.paymentOptionData.filter(m => m.period <= 6)
        break;
      default:
        this.paymentOptionListTo = this.paymentOptionData
        break;
    }
  }

  selectedAcademicTermTo(event) {
    this.to_paymentOption.patchValue(null)
    this.paymentListTo = []
    const { value } = event.option
    if (value) {
      this.getPaymentOptionTo(value)
    }
  }

  displayItem(item: any): string {
    return item ? item.name : item;
  }

  displayItemText(item: any): string {
    return item ? item.text : item;
  }

  // selectedFee(event) {
  //   this.haveStudentDiscount = false
  //   const { value } = event.option;
  //   const { program_term } = this.form.value

  //   const programTerm = program_term ? program_term : this.selectedAcademicYear.program_term

  //   if (value && programTerm) {

  //     const { period } = value;
  //     let toDatePay = null
  //     const { programTermItems } = this.selectedAcademicYear;
  //     this.currentTerm = programTermItems.find(m => m.key === programTerm.key);

  //     const { program_academic } = this.studentStore.selectedAdmission;
  //     const paymentStudentData = this.studentData[program_academic.program.key]
  //     const startDate = paymentStudentData ? paymentStudentData.paymentExpireDate.toDate() : this.currentTerm.program_term_start.toDate()
  //     if (this.selectedAcademicYear) {

  //       switch (period) {
  //         case 12:
  //           toDatePay = this.selectedAcademicYear.end_year_date.toDate()
  //           break;
  //         case 6:
  //           const endTermPeriod1 = programTermItems.find(m => m.key === (programTerm.key + 1));
  //           toDatePay = endTermPeriod1.program_term_end.toDate()
  //           break;
  //         case 3:
  //           const endTermPeriod2 = programTermItems.find(m => m.key === programTerm.key);
  //           toDatePay = endTermPeriod2.program_term_end.toDate()
  //           break;
  //         default:
  //           break;
  //       }

  //       this.form.patchValue({
  //         fromDate: startDate,
  //         toDate: toDatePay
  //       })
  //     }

  //     this.getPaymentList(value);
  //   }
  // }

  startDate = null;
  toDatePay = null;
  selectedFee(event) {
    const { value } = event.option;
    if (value) {
      const { program_term } = this.form.value
      const programTerm = program_term ? program_term : this.selectedAcademicYear.program_term
      const { programTermItems } = this.selectedAcademicYear;
      this.currentTerm = programTermItems.find(m => m.key === programTerm.key);
      const { program_academic } = this.studentStore.selectedAdmission;
      const paymentStudentData = this.studentData[program_academic.program.key]
      this.startDate = paymentStudentData ? paymentStudentData.paymentExpireDate.toDate() : this.currentTerm.program_term_start.toDate()

      this.getPaymentList(this.paymentPeriodYear, value)
    }
  }

  getPaymentList(value, periodFee) {

    this.paymentList = [];
    const { admin_fee, tuition_fee, payment_option_full_year } = this.optionData;

    if (this.trainingLevel && this.trainingLevel.pay_full_admin_fee) {

      const tuitionFeeData = this.trainingLevelFee.filter(m => m.paymentOption.key === value.key && m.fee.key === tuition_fee.key);
      if (tuitionFeeData && tuitionFeeData.length > 0) {

        const tuitionFeeByPeriod = tuitionFeeData[0]
        const amount = (tuitionFeeByPeriod.amount / 4) * periodFee.key
        let tuitionFeeDoc = {
          ...tuitionFeeByPeriod,
          amount: amount,
          price: amount,
        }
        this.paymentList.push(tuitionFeeDoc);

      }

      const adminFeeData = this.trainingLevelFee.filter(m => m.paymentOption.key === payment_option_full_year.key && m.fee.key === admin_fee.key);
      const adminFeePeriod = this.paymentStudentData && this.paymentStudentData.adminFeePeriod ? this.paymentStudentData.adminFeePeriod : 0;
      if (adminFeeData && adminFeeData.length > 0 && toNumber(adminFeePeriod) < 12)
        this.paymentList.push(adminFeeData[0]);
    } else {

      const tuitionFeeData = this.trainingLevelFee.filter(m => m.paymentOption.key === value.key && m.fee.key === tuition_fee.key);
      if (tuitionFeeData && tuitionFeeData.length > 0) {

        const tuitionFeeByPeriod = tuitionFeeData[0]
        const amount = (tuitionFeeByPeriod.amount / 4) * periodFee.key
        let tuitionFeeDoc = {
          ...tuitionFeeByPeriod,
          amount: amount,
          price: amount,
        }
        this.paymentList.push(tuitionFeeDoc);
      }

      const adminFeeData = this.trainingLevelFee.filter(m => m.paymentOption.key === value.key && m.fee.key === admin_fee.key);
      const adminFeePeriod = this.paymentStudentData && this.paymentStudentData.adminFeePeriod ? this.paymentStudentData.adminFeePeriod : 0;

      if (adminFeeData && adminFeeData.length > 0 && toNumber(adminFeePeriod) < 12)
        this.paymentList.push(adminFeeData[0]);

    }

  }

  async selectedYearTo(event) {
    const { value } = event.option
    if (value) {
      this.selectedAcademicYearTo = this.academicYearList.find(m => m.key === value.key)
    }
  }

  // selectedFeeTo(event) {
  //   this.haveStudentDiscount = false
  //   const { value } = event.option;
  //   const { to_program_term } = this.form.value

  //   const programTerm = to_program_term ? to_program_term : this.selectedAcademicYearTo.program_term
  //   if (value && programTerm) {

  //     const { period } = value;
  //     let toDatePay = null
  //     const { programTermItems } = this.selectedAcademicYearTo;
  //     if (this.selectedAcademicYearTo) {

  //       switch (period) {
  //         case 12:
  //           toDatePay = this.selectedAcademicYearTo.end_year_date.toDate()
  //           break;
  //         case 6:
  //           const endTermPeriod1 = programTermItems.find(m => m.key === (programTerm.key + 1));
  //           toDatePay = endTermPeriod1.program_term_end.toDate()
  //           break;
  //         case 3:
  //           const endTermPeriod2 = programTermItems.find(m => m.key === programTerm.key);
  //           toDatePay = endTermPeriod2.program_term_end.toDate()
  //           break;
  //         default:
  //           break;
  //       }

  //       this.form.patchValue({
  //         // fromDate: startDate,
  //         toDate: toDatePay
  //       })
  //     }

  //     this.getPaymentListTo(value);
  //   }
  // }

  selectedFeeTo(event) {
    this.free_paymentOption.patchValue(null)
    const { value } = event.option;
    if (value) {
      const { to_program_term } = this.form.value
      const programTerm = to_program_term ? to_program_term : this.selectedAcademicYearTo.program_term
      const { programTermItems } = this.selectedAcademicYearTo;
      this.currentTerm = programTermItems.find(m => m.key === programTerm.key);
      const { program_academic } = this.studentStore.selectedAdmission;
      const paymentStudentData = this.studentData[program_academic.program.key]
      this.startDate = paymentStudentData ? paymentStudentData.paymentExpireDate.toDate() : this.currentTerm.program_term_start.toDate()

      switch (value.period) {
        case 12:
          this.toDatePay = this.selectedAcademicYearTo.end_year_date.toDate()
          break;
        case 6:
          const endTermPeriod1 = programTermItems.find(m => m.key === (programTerm.key + 1));
          this.toDatePay = endTermPeriod1.program_term_end.toDate()
          break;
        case 3:
          const endTermPeriod2 = programTermItems.find(m => m.key === programTerm.key);
          this.toDatePay = endTermPeriod2.program_term_end.toDate()
          break;
        default:
          const endTermPeriod3 = programTermItems.find(m => m.key === programTerm.key);
          this.toDatePay = endTermPeriod3.program_term_end.toDate()
          break;
      }

      this.form.patchValue({
        fromDate: this.startDate,
        toDate: this.toDatePay
      })

      this.getPaymentListTo(this.paymentPeriodYear, value)

    }
  }

  getPaymentListTo(value, periodFee) {
    this.paymentListTo = [];
    const { admin_fee, tuition_fee, payment_option_full_year } = this.optionData;

    if (this.trainingLevelTo && this.trainingLevelTo.pay_full_admin_fee) {

      const tuitionFeeData = this.trainingLevelFeeTo.filter(m => m.paymentOption.key === value.key && m.fee.key === tuition_fee.key);
      if (tuitionFeeData && tuitionFeeData.length > 0) {
        const tuitionFeeByPeriod = tuitionFeeData[0]
        const amount = (tuitionFeeByPeriod.amount / 4) * periodFee.key
        let tuitionFeeDoc = {
          ...tuitionFeeByPeriod,
          amount: amount,
          price: amount,
        }
        this.paymentListTo.push(tuitionFeeDoc);
      }

      const adminFeeData = this.trainingLevelFeeTo.filter(m => m.paymentOption.key === payment_option_full_year.key && m.fee.key === admin_fee.key);
      const adminFeePeriod = this.paymentStudentData && this.paymentStudentData.adminFeePeriod ? this.paymentStudentData.adminFeePeriod : 0;
      if (adminFeeData && adminFeeData.length > 0 && toNumber(adminFeePeriod) < 12)
        this.paymentListTo.push(adminFeeData[0]);
    } else {

      const tuitionFeeData = this.trainingLevelFeeTo.filter(m => m.paymentOption.key === value.key && m.fee.key === tuition_fee.key);
      if (tuitionFeeData && tuitionFeeData.length > 0) {
        const tuitionFeeByPeriod = tuitionFeeData[0]
        const amount = (tuitionFeeByPeriod.amount / 4) * periodFee.key
        let tuitionFeeDoc = {
          ...tuitionFeeByPeriod,
          amount: amount,
          price: amount,
        }
        this.paymentListTo.push(tuitionFeeDoc);
      }

      const adminFeeData = this.trainingLevelFeeTo.filter(m => m.paymentOption.key === value.key && m.fee.key === admin_fee.key);
      const adminFeePeriod = this.paymentStudentData && this.paymentStudentData.adminFeePeriod ? this.paymentStudentData.adminFeePeriod : 0;

      if (adminFeeData && adminFeeData.length > 0 && toNumber(adminFeePeriod) < 12)
        this.paymentListTo.push(adminFeeData[0]);

    }
  }

  selectedFeeFree(event) {
    const { value } = event.option;
    if (value) {
      const { period } = value;
      let toDatePay = null
      const { to_program_term } = this.form.value
      const { programTermItems } = this.selectedAcademicYearTo;
      let programTerm = to_program_term ? to_program_term : this.selectedAcademicYearTo.program_term

      if (this.selectedAcademicYearTo) {
        switch (period) {
          case 12:
            toDatePay = this.selectedAcademicYearTo.end_year_date.toDate()
            break;
          case 6:
            const endTermPeriod1 = programTermItems.find(m => m.key === (programTerm.key + 3));
            toDatePay = endTermPeriod1.program_term_end.toDate()
            break;
          case 3:
            const endTermPeriod2 = programTermItems.find(m => m.key === programTerm.key + 2);
            toDatePay = endTermPeriod2.program_term_end.toDate()
            break;
          default:
            break;
        }

        this.form.patchValue({
          // fromDate: startDate,
          toDate: toDatePay
        })
      }
    }
  }

  create(f: any) {
    if (!this.selectedAcademicYear || this.isPaidComplete) return;
    if (this.form.valid && this.paymentList.length > 0) {
      this.form.disable();
      const { selectedCampus, selectedSchool } = this.env
      this.store.addSchoolFeeCrossYear(selectedCampus, selectedSchool, this.payment.invoiceKey, f, this.currentTerm, this.paymentList, this.paymentListTo, this.selectedAcademicYear, this.studentStore.student, this.studentStore.selectedAdmission, this.env.user, this.optionData, this.oldInvoice, null, (success, error) => {
        if (success) {
          this.snackBar.open("School Fee has been added.", "Done", { duration: 2000 })
          this.dialogRef.close('yes');
          this.form.enable();

          const { selectedAdmission, student } = this.studentStore
          this.router.navigate(["/student/" + student.key + '/' + selectedAdmission.key + "/receive-payment"]);
        }
        else {
          this.snackBar.open(error, "Error");
          this.form.enable();
        }
      })
    }
  }
}