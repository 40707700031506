import { FormGroup, AbstractControl, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { slideInOutAnimation } from '../../services/slide-io.animation';

@Component({
  animations: [slideInOutAnimation],
  selector: 'app-receive-payment',
  templateUrl: './receive-payment.component.html',
  styleUrls: ['./receive-payment.component.scss'],
  host: { "[@slideInOutAnimation]": "" }
})
export class ReceivePaymentComponent implements OnInit {
  form:FormGroup;
  search:AbstractControl;
  formFocus:boolean;
  student_id:string;
  constructor(
    private router:Router,
    private route:ActivatedRoute,
    private fb:FormBuilder
  ) { }

  ngOnInit() {
    this.form=this.fb.group({
      search:[null]
    })
    this.search=this.form.controls["search"];
    this.route.params.forEach(params => {
      this.student_id = params["id"];
      this.form.patchValue({
        search:this.student_id
      })
    })
  }
  _goBack(){
    this.router.navigate(['/']);
  }

 _onFocus(yes){
  this.formFocus=yes;
 }
  _onSearch(f:any){
    if(this.form.valid){
      this.router.navigate(['/receive-payment/'+f.search])
    }
  }

}
