import { ITuition } from "./../interfaces/tuition";
import { programsObj, invoiceTypesObj, enrollPrograms, enrollStatus, enrollStatusObj, ShiftStatusObj, paymentStatus, INSTALLMENT_PAYMENT_STATUS } from "../dummy/status";
import { Pages } from "./../dummy/pages";
import { AngularFirestore } from "@angular/fire/firestore";
import { Injectable } from "@angular/core";
import { ConvertService, toUpperCase } from "./convert.service";
import { ITesting, TestOption } from "../interfaces/testing";
import * as moment from "moment";
import { firestore } from 'firebase/app';

@Injectable({
  providedIn: "root"
})
export class DataService {
  constructor(private db: AngularFirestore) { }

  autoCollectionRef(collection, field: string) {
    return this.db.collection(collection, ref => ref
      .orderBy(field)
      .limit(Pages.size));
  }

  autoFilterCollectionRef(collection: string, field: string, text: any) {
    if (text && !text.key) {
      const search = text.toUpperCase();
      const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
      return this.db.collection(collection, ref =>
        ref
          .where(field, ">=", search)
          .where(field, '<', end)
          .orderBy(field)
          .limit(Pages.size)
      );
    }
    return this.db.collection(collection, ref =>
      ref
        .orderBy(field)
        .limit(Pages.size)
    );
  }

  autoCollectionSchoolRef(schoolKey, collection, field: string) {
    return this.db.collection("stores").doc(schoolKey).collection(collection, ref => ref
      .orderBy(field)
      .limit(Pages.size));
  }

  autoFilterCollectionSchoolRef(schoolKey, collection: string, field: string, text: any) {
    if (text && !text.key) {
      const search = text.toUpperCase();
      const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
      return this.db.collection("stores").doc(schoolKey).collection(collection, ref =>
        ref
          .where(field, ">=", search)
          .where(field, '<', end)
          .orderBy(field)
          .limit(Pages.size)
      );
    }
    return this.db.collection("stores").doc(schoolKey).collection(collection, ref =>
      ref
        .orderBy(field)
        .limit(Pages.size)
    );
  }

  autoFilterContentCollectionSchoolRef(schoolKey, collection: string, field: string, text: any) {
    if (text && !text.key) {
      const search = text.toUpperCase();
      return this.db.collection("stores").doc(schoolKey).collection(collection, ref =>
        ref
          .where('keyword', 'array-contains', search)
          .orderBy(field)
          .limit(Pages.size)
      );
    }
    return this.db.collection("stores").doc(schoolKey).collection(collection, ref =>
      ref
        .orderBy(field)
        .limit(Pages.size)
    );
  }

  scholarshipDBByStudentRef(studentKey: string) {
    return this.db.collection('scholarships', ref => ref
      .where("student.key", "==", studentKey)
    );
  }
  batchLevelKeyRef(levelKey, academicYearKey, campusKey) {
    return this.db.collection("institute_training_level_batch", ref => ref
      .where("level.key", "==", levelKey)
      .where("academicYear.key", "==", academicYearKey)
      .where("campus.key", "==", campusKey)
    )
  }
  batchLevelDocRef(key) {
    return this.db.collection("institute_training_level_batch").doc(key);
  }
  serverTimestamp() {
    return firestore.FieldValue.serverTimestamp()
  }

  academicYearRef() {
    return this.db.collection("academic_year", ref => ref.orderBy("startDate", "desc"));
  }

  academicYearByTypeRef(schoolKey, typeKey) {
    return this.db.collection("stores").doc(schoolKey).collection("academic_year", ref => ref.where("termType.key", "==", typeKey));
  }

  otherServiceFeeRef(schoolKey: string) {
    return this.db.collection("stores").doc(schoolKey).collection("admission_service_other");
  }

  otherServiceFeeDocRef(schoolKey: string, key: string) {
    return this.db.collection("stores").doc(schoolKey).collection("admission_service_other").doc(key);
  }

  academicYearDocRef(key: string) {
    return this.db.collection("academic_year").doc(key);
  }

  academicYearFireRef() {
    return this.firestore().collection("academic_year");
  }

  academicTrainingYearMovementFireRef() {
    return this.firestore().collection("academic_training_grade_movement");
  }

  academicTrainingFeeMovementFireRef() {
    return this.firestore().collection("academic_training_grade_fee_movement");
  }

  academicGradeRef() {
    return this.db.collection("academic_grade", ref => ref.orderBy("order"));
  }

  academicTrainingRef() {
    return this.db.collection("academic_training_grade", ref => ref.orderBy("grade.order"));
  }

  userDocRef(uid: string) {
    return this.db.collection("users").doc<any>(uid);
  }

  settingDBFireStore() {
    return this.db.collection("testing_options").doc("general");
  }

  pettyCashRef(userKey: string, shiftKey: string) {
    return this.db.collection("petty_cash", ref =>
      ref
        .where("createBy.key", "==", userKey)
        .where("shift.key", "==", shiftKey)
        .orderBy("page_key", "desc").limit(Pages.size)
    );
  }

  pettyCashByShiftRef(shiftKey: string) {
    return this.db.collection("petty_cash", ref => ref
      .where("shift.key", "==", shiftKey)
      .orderBy("page_key", "desc")
    );
  }

  installmentByShiftRef(shiftKey: string) {
    return this.db.collection("installment_movement", ref => ref
      .where("shift.key", "==", shiftKey)
      .where("isInstallment", "==", true)
      .orderBy("page_key", "desc")
    );
  }

  dailyShiftPaymentRef(fromDate: number, toDate: number, uid: string) {
    return this.db.collection("shift_movements", ref => ref
      .where("create_by.key", "==", uid)
      .where("create_date_key", ">=", fromDate)
      .where("create_date_key", "<=", toDate)
      .orderBy("create_date_key", "desc")
    );
  }

  expensePaymentRef(fromDate: number, toDate: number, uid: string) {
    return this.db.collection("petty_cash", ref => ref
      .where("createBy.key", "==", uid)
      .where("datePaymentKey", ">=", fromDate)
      .where("datePaymentKey", "<=", toDate)
      .orderBy("datePaymentKey", "desc")
    );
  }

  matchTermListingRef(fromDate: number, toDate: number, uid: string) {
    return this.db.collection("student_align_payment", ref => ref
      .where("create_by.key", "==", uid)
      .where("create_date_key", ">=", fromDate)
      .where("create_date_key", "<=", toDate)
      .orderBy("create_date_key", "desc")
    );
  }

  installmentNextPaymentRef(fromDate: number, toDate: number, uid: string) {
    return this.db.collection("student_align_payment", ref => ref
      .where("installment_status.key", "==", INSTALLMENT_PAYMENT_STATUS.pending.key)
      .where("pay_date_key", "<=", fromDate)
      // .where("pay_date_key", "<=", toDate)
      .orderBy("pay_date_key", "desc")
    );
  }

  paymentARRef(fromDate: number, toDate: number, uid: string) {
    return this.db.collection("student_align_payment", ref => ref
      .where("installment_status.key", "==", INSTALLMENT_PAYMENT_STATUS.pending.key)
    );
  }

  requestPenaltyRef(schoolKey: string, campusKey: string, fromDate: any, toDate: any) {
    return this.db.collection("stores").doc(schoolKey).collection("campus").doc(campusKey).collection("request_penalty", ref => ref
      .where("update_date", ">=", fromDate)
      .where("update_date", "<=", toDate)
      .orderBy("update_date", "desc")
    );
  }

  requestVoidRef(schoolKey: string, campusKey: string, fromDate: any, toDate: any) {
    return this.db.collection("stores").doc(schoolKey).collection("campus").doc(campusKey).collection("request_void", ref => ref
      .where("update_date", ">=", fromDate)
      .where("update_date", "<=", toDate)
      .orderBy("update_date", "desc")
    );
  }

  studentFeeWithPeriodRef(schoolKey: string, campusKey: string, fromDate: any, toDate: any) {
    return this.db.collection("stores").doc(schoolKey).collection("campus").doc(campusKey).collection("student_fee_with_period", ref => ref
      .where("create_date", ">=", fromDate)
      .where("create_date", "<=", toDate)
      .orderBy("create_date", "desc")
    );
  }

  receiptReportRef(fromDate: number, toDate: number, uid: string) {
    return this.db.collection("invoices", ref => {
      let condition = ref
        .where("isHeader", "==", true)
        .where("isPaid.key", "==", 1)
        .where("received_date_key", ">=", fromDate)
        .where("received_date_key", "<=", toDate)
        .orderBy("received_date_key", "desc")

      if (uid) {
        condition = condition.where("received_by.key", "==", uid)
      }

      return condition
    })
  }

  installmentPaymentRef(fromDate: number, toDate: number, uid: string) {
    return this.db.collection("installment_movement", ref => ref
      .where("received_by.key", "==", uid)
      .where("received_date_key", ">=", fromDate)
      .where("received_date_key", "<=", toDate)
      .orderBy("received_date_key", "desc")
    );
  }

  pettyCashScrollRef(lastVisible: any) {
    return this.db.collection("petty_cash", ref =>
      ref
        .orderBy("page_key", "desc")
        .startAfter(lastVisible.page_key)
        .limit(Pages.size)
    );
  }

  studentByAdmissionRef(key) {
    return this.db.collection("academics_major_admission", ref =>
      ref
        .where("student.key", "==", key)
        .orderBy("program.key", "desc")
        .limit(1)
    );
  }

  studentAllAdmissionRef(key) {
    return this.db.collection("academics_major_admission", ref =>
      ref.where("student.key", "==", key)
    );
  }

  studentAlignPaymentRef(key) {
    return this.db.collection("student_align_payment", ref =>
      ref.where("student.key", "==", key)
    );
  }

  studentInstallmentPaymentRef(key) {
    return this.db.collection("student_align_payment", ref => ref
      .where("isPaid", "==", true)
      .where("student.key", "==", key)
      .where("installment_status.key", "==", INSTALLMENT_PAYMENT_STATUS.pending.key)
    );
  }

  studentAdmissionRef(studentKey) {
    return this.db.collection<any>("academic_student_transcript")
      .doc(studentKey)
      .collection("admission", ref => ref
        .where("status.key", "==", 1)
        // .orderBy("term.startDateKey", "desc")
      );
  }

  studentSearchRef(field: string, search: string, campus) {
    if (!search) {
      return this.db.collection("students", ref =>
        ref
          .where("campus.key", "==", campus.key)
          .orderBy(field,)
          .limit(Pages.size)
      );
    }
    return this.db.collection("students", ref =>
      ref
        .where("campus.key", "==", campus.key)
        .where(field, ">=", toUpperCase(search))
        .orderBy(field)
        .limit(Pages.size)
    );
  }

  studentFetchRef(field: string, search: string, campus, lastVisible: any) {
    if (search) {
      return this.db.collection("students", ref =>
        ref
          .where("campus.key", "==", campus.key)
          .where(field, ">=", toUpperCase(search))
          .orderBy("page_key", "desc")
          .orderBy(field)
          .startAfter(lastVisible.page_key)
          .limit(Pages.size)
      );
    } else {
      return this.db.collection("students", ref =>
        ref
          // .where("campus.key", "==", campus.key)
          .orderBy(field)
          .startAfter(lastVisible.page_key)
          .limit(Pages.size)
      );
    }
  }

  studentRef(field: string, campus: any) {
    return this.db.collection("students", ref =>
      ref
        // .where("campus.key", "==", campus.key)
        .orderBy(field)
        .limit(Pages.size)
    );
  }

  testingByStudentRef(studentKey: string) {
    return this.db.collection("testing", ref =>
      ref
        .where("student.key", "==", studentKey)
        .orderBy("page_key", "desc")
        .limit(1)
    );
  }

  paymentDiscountRef() {
    return this.db.collection("payment_discount", ref => ref.where("status.key", "==", 1));
  }

  studentAdmissionDBRef(key) {
    return this.db.collection("academics_major_admission").doc(key);
  }

  studentTestRef(studentKey) {
    return this.db.collection("testing", ref =>
      ref
        .where("student.key", "==", studentKey)
        .orderBy("create_date", "desc")
    );
  }

  testingStudent(key: string) {
    return this.db.collection("testing").doc(key);
  }

  academicYearDBRef() {
    return this.db.collection("academic_year", ref => ref.orderBy("startDate", "desc"));
  }

  storeFireRef(schoolKey) {
    return this.firestore().collection('stores').doc(schoolKey)
  }

  academicFirebaseRef(schoolKey) {
    return this.storeDocRef(schoolKey).collection("academic_environment").doc("academic_environment");
  }

  studentDocument(key: string) {
    return this.db.collection("students").doc<any>(key);
  }

  studentAccountDocRef(key: string) {
    return this.db.collection("student_accounts").doc<any>(key);
  }

  studentInvoiceRef(studentKey) {
    return this.db.collection("students").doc(studentKey).collection("invoices", ref => ref.where("isHeader", "==", true));
  }

  studentImportPaymentRef(schoolKey, key) {
    return this.db.collection("stores").doc(schoolKey).collection("import_student_payment").doc(key);
  }

  studentFireS(key: string) {
    return this.firestore().collection("students").doc(key);
  }

  studentTestingInvoice(studentKey: string) {
    return this.db.collection("students").doc(studentKey).collection("invoices", ref => ref
      .where("invoice_type.key", "==", invoiceTypesObj.registrationFee.key)
      .where("isPaid.key", "==", paymentStatus.unpaid.key)
    );
  }

  publicHolidayActive(schoolKey: string, fromDate, toDate) {
    const fromDateTime = ConvertService.startFromDate(fromDate)
    const toDateTime = ConvertService.endToDate(toDate)
    return this.db.collection("stores").doc(schoolKey).collection("public_holiday", ref => ref
      .where("from_date", ">=", fromDateTime)
      .where("from_date", "<=", toDateTime)
      .orderBy("from_date")
    );
  }

  studentInvoiceByAdmission(admissionKey: string, admission: any, studentKey: string, invoiceKey) {
    if (invoiceKey) {
      return this.db.collection("students")
        .doc(studentKey)
        .collection("invoices", ref => ref
          .where("headerRef", "==", invoiceKey)
          .where("isPaid.key", "==", paymentStatus.unpaid.key)
        );
    } else if (admissionKey && admissionKey !== "draft") {
      const { program_academic } = admission
      return this.db.collection("students").doc(studentKey).collection("invoices", ref => ref
        .where("isEnrollVerify", "==", true)
        .where("isPaid.key", "==", paymentStatus.unpaid.key)
        .where("program.program.key", "==", program_academic.program.key)
        .orderBy("page_key")
      );
    } else {
      return this.db.collection("students").doc(studentKey).collection("invoices", ref => ref
        .where("isEnrollVerify", "==", true)
        .where("isPaid.key", "==", paymentStatus.unpaid.key)
        // .where("program.admissionKey", "==", admissionKey)
        .orderBy("page_key")
      );
    }
  }

  installmentFireRef() {
    return this.firestore().collection("installment_movement");
  }

  prepaidFireRef() {
    return this.firestore().collection("prepaid_movement");
  }

  shiftMovementFireRef() {
    return this.firestore().collection("shift_movements");
  }

  shiftMovementDocsRef() {
    return this.db.collection("shift_movements");
  }

  studentScholarshipByProgram(studentKey: string, programKey: string) {
    return this.firestore().collection("students").doc(studentKey).collection("scholarships").where("program.key", "==", programKey);
  }

  miscellaneousFeeRef() {
    return this.db.collection("admission_services", ref => ref.where("program.key", "==", programsObj.miscellaneous));
  }

  studentFireDocument(key: string) {
    return this.firestore().collection("students").doc(key);
  }

  abcStudentRef() {
    return this.firestore().collection("abc_students");
  }

  receivedPaymentFireRef() {
    return this.firestore().collection("received_payment_movement");
  }

  levelPricesRef(level: any) {
    return this.db.collection("institutes_price_level", ref =>
      ref
        .where("level.key", "==", level.key)
        .orderBy("page_key", "desc")
        .limit(Pages.size)
    );
  }

  programLevelRef(programKey) {
    return this.db.collection("testLevel", ref =>
      ref.where("program.key", "==", programKey).orderBy("program_level.key")
    );
  }

  testingCollectionRef(user: any, status: string, text) {
    if (text) {
      return this.db.collection("testing", ref =>
        ref
          .where("serial_id", "==", text)
          .where("create_by.key", "==", user.key)
      );
    }
    let ref = this.db.collection("testing", ref =>
      ref.orderBy("page_key", "desc").limit(Pages.size)
    );
    switch (status) {
      case "all":
        ref = this.db.collection("testing", ref =>
          ref
            // .orderBy("page_key", "desc")
            .where("create_by.key", "==", user.key)
            .limit(Pages.size)
        );
        break;
      case "unpaid":
        ref = this.db.collection("testing", ref =>
          ref
            .where("isPaidTest", "==", false)
            // .orderBy("page_key", "desc")
            .limit(Pages.size)
        );
        break;
      case "paid":
        ref = this.db.collection("testing", ref =>
          ref
            .where("isPaidTest", "==", true)
            // .orderBy("page_key", "desc")
            .limit(Pages.size)
        );
        break;
      case "own":
        ref = this.db.collection("testing", ref =>
          ref
            .where("create_by.key", "==", user.key)
            // .orderBy("page_key", "desc")
            .limit(Pages.size)
        );
        break;
      default:
        break;
    }
    return ref;
  }

  testingCollectionScrollRef(textSearch: string, lastVisible: any) {
    if (textSearch !== "") {
      return this.db.collection("testing", ref =>
        ref
          .where("serial_id", "==", textSearch)
          .orderBy("page_key", "desc")
          .startAfter(lastVisible.page_key)
          .limit(Pages.size)
      );
    } else {
      return this.db.collection("testing", ref =>
        ref
          .orderBy("page_key", "desc")
          .startAfter(lastVisible.page_key)
          .limit(Pages.size)
      );
    }
  }

  testingDocRef() {
    return this.db.collection<ITesting>("testing");
  }

  paymentOptionRef() {
    return this.db.collection("payment_options", ref => ref.orderBy("order"));
  }

  feeCategoryRef() {
    return this.db.collection("fee_categories", ref => ref.orderBy("name"));
  }

  tuitionFeeRef() {
    return this.db.collection<ITuition>("admission_services", ref => ref.orderBy("name"));
  }

  environmentRef(schoolKey) {
    return this.db.collection("stores").doc(schoolKey).collection("academic_environment").doc<any>("academic_environment");
  }

  sysOptionRef(schoolKey) {
    return this.db.collection("stores").doc(schoolKey).collection("sys_option").doc("general");
  }

  generalDocRef() {
    return this.db.collection("testing_options").doc("general");
  }

  nationalityRef() {
    return this.db.collection("nationality");
  }

  UATCurriculumRef() {
    return this.db.collection("curriculum", ref =>
      ref.where("key", "==", "ixgIV4Dib7gI6ylX4q2F")
    );
  }

  UATInstituteRef() {
    return this.db.collection("testType", ref =>
      ref.where("key", "==", "hubewNfOglOzd3u82Sx3")
    );
  }

  programLevelFireRef() {
    return this.firestore().collection("testLevel");
  }

  levelPriceFireRef() {
    return this.firestore().collection("institutes_price_level");
  }

  tuitionFeeByTypeRef(type: string) {
    if (type === "all")
      return this.db.collection<any>("admission_services", ref =>
        ref.where("is_academic", "==", true));
    else
      return this.db.collection<any>("admission_services", ref =>
        ref
          .where("is_academic", "==", true)
          .where("program.key", "==", programsObj[type]));
  }

  testingExistRef(form: ITesting) {
    return this
      .firestore()
      .collection("testing")
      .where("first_name", "==", form.first_name.trim().toUpperCase())
      .where("last_name", "==", form.last_name.trim().toUpperCase())
      .where("gender.key", "==", form.gender.key)
      .where("dobKey", "==", form.dobKey)
      .orderBy("page_key", "desc")
      .limit(1);
  }

  instituteProgramsRef(instituteKey) {
    return this.db.collection<any>("testType", ref =>
      ref.where("institute.key", "==", instituteKey).orderBy("code")
    );
  }

  institutesRef() {
    return this.db.collection("institutes", ref =>
      ref.where("instituteType.key", "==", 1).orderBy("code")
    );
  }

  configRef() {
    return this.firestore().collection("testing_options").doc("general");
  }

  // dailyShiftRef(cashierKey: string) {
  //   return this.db.collection("shift_movements", ref => ref
  //     .where("start_cashier.key", "==", cashierKey)
  //     .where("status.key", "==", ShiftStatusObj.opening.key)
  //     .orderBy("page_key", "desc").limit(1))
  // }

  studentAlignPaymentFireRef() {
    return this.firestore().collection("student_align_payment");
  }
  studentFireRef() {
    return this.firestore().collection("students");
  }
  studentAccountFireRef() {
    return this.firestore().collection("student_accounts");
  }
  scholarshipFireRef() {
    return this.firestore().collection('scholarships');
  }
  update_admission_student_idFireRef() {
    return this.firestore().collection("update_admission_student_id");
  }

  testingStudentRef() {
    return this.firestore().collection("testing")
  }

  levelBatchFireRef() {
    return this.firestore().collection("institute_training_level_batch");
  }

  levelBatchDBFireRef() {
    return this.db.collection("institute_training_level_batch");
  }

  instituteNoScheduleRef(studentKey) {
    return this.db.collection("institute_no_schedule", ref => ref.where("student.key", "==", studentKey));
  }

  scheduleInProgressAwaitRef(studentKey) {
    return this.db.collection("schedule_in_progress", ref => ref.where("student.key", "==", studentKey));
  }

  invoiceFailFireRef() {
    return this.firestore().collection("invoice_fail");
  }

  creditNoteFireRef() {
    return this.firestore().collection("credit_note")
  }

  studentTranscriptFireRef() {
    return this.firestore().collection("academic_student_transcript");
  }

  scholarshipsFireRef() {
    return this.firestore().collection("scholarships");
  }

  studentAccountDocFire() {
    return this.firestore().collection("student_accounts");
  }

  studentInstituteNoScheduleFireRef() {
    return this.firestore().collection("institute_no_schedule");
  }

  studentInstituteNoScheduleDBRef() {
    return this.db.collection("institute_no_schedule");
  }

  batchLevelFireRef() {
    return this.firestore().collection("institute_training_level_batch")
  }

  batchLevelRef() {
    return this.db.collection("institute_training_level_batch")
  }

  termFireRef() {
    return this.firestore().collection("academics_term")
  }

  transcriptFireRef() {
    return this.firestore().collection("academic_student_transcript");
  }

  admissionDocRef(key: string) {
    return this.db.collection("academics_major_admission").doc(key);
  }

  admissionRef() {
    return this.db.collection("academics_major_admission");
  }

  admissionByStudentRef(studentKey: string) {
    return this.db.collection("academics_major_admission", ref => ref.where("student.key", "==", studentKey));
  }

  studentAdmissionFireRef() {
    return this.firestore().collection("academics_major_admission");
  }

  studentAdmissionTranscriptFireRef() {
    return this.firestore().collection("academic_student_transcript");
  }

  studentPucProfileFireRef() {
    return this.firestore().collection("student_puc_profile");
  }

  testingFireRef() {
    return this.firestore().collection("testing");
  }

  testingDBRef() {
    return this.db.collection("testing");
  }

  scheduleInProgressRef() {
    return this.firestore().collection("schedule_in_progress")
  }

  invoicesFireRef() {
    return this.firestore().collection("invoices");
  }



  registrarFireRef() {
    return this.firestore().collection("registrar_payment");
  }

  configFireRef() {
    return this
      .firestore()
      .collection("testing_options")
      .doc("general");
  }

  schoolFireRef() {
    return this.firestore().collection("stores");
  }

  schoolDBRef() {
    return this.db.collection("stores");
  }

  storeDocRef(key) {
    return this.db.collection("stores").doc(key);
  }

  campusDocRef(doc) {
    return this.db.collection('campus').doc(doc);
  }

  studentDoc(key) {
    return this.db.collection('students').doc<any>(key)
  }

  testingOptionRef() {
    return this.db.collection("testing_options").doc('general');
  }

  academicFireRef(schoolKey) {
    return this.firestore().collection("stores").doc(schoolKey).collection("academic_environment").doc("academic_environment");
  }

  settingFireStore() {
    return this
      .firestore()
      .collection("testing_options")
      .doc("general");
  }

  studentTestingRef(studentKey) {
    return this.db.collection("testing", ref => ref
      .where("student.key", "==", studentKey));
  }

  sysSetting() {
    return this.db.collection("testing_options").doc("general");
  }

  dailyShiftFireRef() {
    return this.firestore().collection("shift_movements");
  }

  shiftSummaryFireRef() {
    return this.firestore().collection("shift_summary");
  }

  dailyShiftByUserRef(dateKey: number, uid: string) {
    return this.db.collection("shift_movements", ref => ref
      .where("create_date_key", "==", dateKey)
      .where("start_cashier.key", "==", uid)
    );
  }

  dailyShiftByUserShiftRef(shiftKey: any) {
    return this.db.collection("shift_movements", ref => ref
      .where("key", "==", shiftKey)
    );
  }

  shiftMovementsRef() {
    return this.db.collection("shift_movements");
  }

  shiftMovementDocRef(key) {
    return this.db.collection("shift_movements").doc(key);
  }

  userFireRef() {
    return this.firestore().collection("users");
  }

  pettyCashFireRef() {
    return this.firestore().collection("petty_cash");
  }

  invoiceFireRef() {
    return this.firestore().collection("invoices");
  }

  academicEnrollmentFireRef() {
    return this.firestore().collection("academic_enrollment");
  }

  instituteEnrollmentFireRef() {
    return this.firestore().collection("institute_enrollment");
  }

  academicsTermFireRef() {
    return this.firestore().collection("academics_term")
  }

  academicTermFireRef(termKey, batchKey) {
    return this
      .firestore()
      .collection("academics_term")
      .doc(termKey)
      .collection("schedules")
      .doc(batchKey)
      .collection("students");
  }

  academicTermScheduleRef(termKey) {
    return this
      .firestore()
      .collection("academics_term")
      .doc(termKey);
  }

  academicTermEnrollmentFireRef(termKey) {
    return this
      .firestore()
      .collection("academics_term")
      .doc(termKey)
      .collection("enrollment");
  }

  abcStudentFireRef() {
    return this.firestore().collection("abc_students");
  }

  admissionStudentRef() {
    return this.firestore().collection("academics_major_admission");
  }

  academicTermAdmissionRef(termKey) {
    return this
      .firestore()
      .collection("academics_term")
      .doc(termKey)
      .collection("admission");
  }

  academicTermEnrollmentRef(termKey) {
    return this
      .firestore()
      .collection("academics_term")
      .doc(termKey)
      .collection("enrollment");
  }

  studentEnrollmentRef(studentKey) {
    return this.db
      .collection("students").doc(studentKey)
      .collection("enrollment", ref =>
        ref.where("isPaid", "==", false)
          .where("enroll_status.key", "==", enrollStatusObj.add.key)
          .orderBy("page_key")
      );
  }

  academicEnrollmentRef(studentKey: string) {
    return this
      .firestore()
      .collection("academic_enrollment")
      .where("student.puc_id", "==", studentKey);
  }

  admissionStudentFireRef(termKey: string, studentKey: string) {
    return this
      .firestore()
      .collection("academics_term")
      .doc(termKey)
      .collection("admission")
      .where("student.key", "==", studentKey)
      .where("status.key", "==", 1)
      .limit(1)
      .orderBy("create_date");
  }

  admissionEnrollmentRef(termKey: string, studentKey: string) {
    return this
      .firestore()
      .collection("academics_term")
      .doc(termKey)
      .collection("enrollment")
      .where("student.key", "==", studentKey)
      .where("status.key", "==", 1)
      .where("enroll_status.key", "==", 2)
      .where("isPaid", "==", false)
      .limit(1)
      .orderBy("create_date");
  }

  userRef(uid: string) {
    return this.db.collection("users").doc<any>(uid);
  }

  userFRef(uid: string) {
    return this.firestore().collection("users").doc(uid);
  }

  invoiceCashierRef(user: any) {
    return this.db.collection("invoices", ref =>
      ref
        .where("isHeader", "==", true)
        .where("isPaid.key", "==", 1)
        .where("received_by.key", "==", user.key)
        .where("dailyShift.key", "==", user.shift.key)
        .orderBy("received_date_key")
    );
  }

  invoiceAllSummeryByShiftRef(shiftKey: string) {
    return this.db.collection("shift_movements").doc(shiftKey).collection("invoices", ref =>
      ref
        .where("isPaid.key", "==", 1)
        .where("dailyShift.key", "==", shiftKey)
    );
  }

  invoiceShiftRef(shiftKey: any, uid: any) {
    return this.db.collection<any>("invoices", ref =>
      ref
        .where("isPaid.key", "==", 1)
        .where("dailyShift.key", "==", shiftKey)
        .where("received_by.key", "==", uid)
    );
  }

  invoiceSummeryByShiftRef(shiftKey: string, invoiceTypeKey: number, type) {
    let ref = this.db.collection("shift_movements").doc(shiftKey).collection("invoices", ref =>
      ref
        .where("isPaid.key", "==", 4)
    );
    switch (type) {
      case "academic_program":
        ref = this.db.collection("shift_movements").doc(shiftKey).collection<any>("invoices", ref =>
          ref
            .where("isPaid.key", "==", 1)
            .where("dailyShift.key", "==", shiftKey)
            .where("invoice_type.key", "==", invoiceTypeKey)
            .where("school_fee_type.key", "==", enrollPrograms.academic.key)
            .orderBy("page_key", "desc")
        );
        break;
      case "institute_and_center":
        ref = this.db.collection("shift_movements").doc(shiftKey).collection<any>("invoices", ref =>
          ref
            .where("isPaid.key", "==", 1)
            .where("dailyShift.key", "==", shiftKey)
            .where("invoice_type.key", "==", invoiceTypeKey)
            .where("school_fee_type.key", "==", enrollPrograms.institutes.key)
            .orderBy("page_key", "desc")
        );
        break;
      default:
        ref = this.db.collection("shift_movements").doc(shiftKey).collection<any>("invoices", ref =>
          ref
            .where("isPaid.key", "==", 1)
            .where("invoice_type.key", "==", invoiceTypeKey)
            .where("dailyShift.key", "==", shiftKey)
            .orderBy("page_key", "desc")
        );
        break;
    }
    return ref;
  }

  invoiceByShiftRef(shiftKey: string) {
    return this.db.collection("shift_movements").doc(shiftKey).collection("invoices", ref =>
      ref
        .where("isPaid.key", "==", 1)
        .where("isHeader", "==", true)
        .orderBy("page_key", "desc")
    );
  }

  invoiceByDetailShiftRef(shiftKey: string) {
    return this.db.collection("shift_movements").doc(shiftKey).collection("invoices", ref =>
      ref
        .where("isPaid.key", "==", 1)
        .where("isHeader", "==", false)
        .orderBy("page_key", "desc")
    );
  }

  paymentMovementRef(shiftKey: string) {
    return this.db.collection("received_payment_movement", ref =>
      ref
        .where("shift.key", "==", shiftKey)
    );
  }

  paymentMovementShiftNullRef(shiftKey: string) {
    return this.db.collection("received_payment_movement", ref =>
      ref
        .where("cashier.shift.key", "==", shiftKey)
        .where("shift", "==", null)
    );
  }

  invoiceTypeListingByShiftRef(shiftKey: string, type: string) {
    let ref = this.db.collection("shift_movements").doc(shiftKey).collection("invoices", ref =>
      ref
        .where("isPaid.key", "==", 4)
    );
    switch (type) {
      case "tuition_fee":
        ref = this.db.collection("shift_movements").doc(shiftKey).collection("invoices", ref =>
          ref
            .where("isHeader", "==", true)
            .where("isPaid.key", "==", 1)
            .where("dailyShift.key", "==", shiftKey)
            .where("invoice_type.key", "==", invoiceTypesObj.tuitionFee.key)
            .orderBy("page_key", "desc")
        );
        break;
      case "academic_program":
        ref = this.db.collection("shift_movements").doc(shiftKey).collection("invoices", ref =>
          ref
            .where("isHeader", "==", true)
            .where("isPaid.key", "==", 1)
            .where("dailyShift.key", "==", shiftKey)
            .where("invoice_type.key", "==", invoiceTypesObj.tuitionFee.key)
            .where("school_fee_type.key", "==", enrollPrograms.academic.key)
            .orderBy("page_key", "desc")
        );
        break;
      case "institute_and_center":
        ref = this.db.collection("shift_movements").doc(shiftKey).collection("invoices", ref =>
          ref
            .where("isHeader", "==", true)
            .where("isPaid.key", "==", 1)
            .where("dailyShift.key", "==", shiftKey)
            .where("invoice_type.key", "==", invoiceTypesObj.tuitionFee.key)
            .where("school_fee_type.key", "==", enrollPrograms.institutes.key)
            .orderBy("page_key", "desc")
        );
        break;
      case "testing":
        ref = this.db.collection("shift_movements").doc(shiftKey).collection("invoices", ref =>
          ref
            .where("isHeader", "==", false)
            .where("isPaid.key", "==", 1)
            .where("dailyShift.key", "==", shiftKey)
            .where("invoice_type.key", "==", invoiceTypesObj.testing.key)
            .orderBy("page_key", "desc")
        );
        break;
      case "abc":
        ref = this.db.collection("shift_movements").doc(shiftKey).collection("invoices", ref =>
          ref
            .where("isHeader", "==", false)
            .where("isPaid.key", "==", 1)
            .where("dailyShift.key", "==", shiftKey)
            .where("invoice_type.key", "==", invoiceTypesObj.abcCourse.key)
            .orderBy("page_key", "desc")
        );
        break;
      case "miscellaneous":
        ref = this.db.collection("shift_movements").doc(shiftKey).collection("invoices", ref =>
          ref
            .where("isHeader", "==", false)
            .where("isPaid.key", "==", 1)
            .where("dailyShift.key", "==", shiftKey)
            .where("invoice_type.key", "==", invoiceTypesObj.miscellaneous.key)
            .orderBy("page_key", "desc")
        );
        break;
      default:
        break;
    }
    return ref;
  }

  invoiceRef(Id: string, status: string, currentDate: number) {
    if (status === "testing-fees") {
      return this.db.collection<any>("invoices", ref =>
        ref.where("student.serial_id", "==", Id).where("isVoid", "==", false)
      );
    } else if (status === "abc-course") {
      return this.db.collection<any>("invoices", ref =>
        ref
          .where("student.puc_id", "==", Id)
          .where("isVoid", "==", false)
          .where("invoice_type.key", "==", invoiceTypesObj.abcCourse.key)
      );
    } else if (status === "tuition-fees") {
      return this.db.collection<any>("invoices", ref =>
        ref
          .where("student.puc_id", "==", Id)
          .where("isVoid", "==", false)
          // .where('invoice_type.key', '==', invoiceTypesObj.tuitionFee.key)
          .orderBy("page_key", "desc")
      );
    }
  }

  invoiceItemsRef(studentKey: string, headerRef: string) {
    return this.db.collection("students").doc(studentKey).collection("invoices", ref =>
      ref.where("headerRef", "==", headerRef)
    );
  }

  invoiceItemsByStudentKeyRef(studentKey: string) {
    return this.db.collection("students").doc(studentKey).collection("invoices", ref =>
      ref
        .where("isHeader", "==", true)
        .where("isPaid.key", "==", paymentStatus.paid.key)
        .orderBy("received_date", "desc")
        .limit(1)
    );
  }

  invoiceItemsByInvoiceNoRef(invoiceNo: number) {
    return this.db.collection<any>("invoices", ref =>
      ref
        .where("invoice_no", "==", invoiceNo)
        .where("isHeader", "==", true)
        // .orderBy("page_key", "desc")
        .limit(1)
    );
  }

  invoiceAllByStudentKeyRef(studentKey: string) {
    return this.db.collection("students").doc(studentKey).collection("invoices", ref => ref.where("isHeader", "==", true).where("isPaid.key", "==", paymentStatus.paid.key));
  }

  invoiceStudentUnpaidRef(studentKey: string) {
    return this.db.collection("students").doc(studentKey).collection("invoices", ref => ref.where("isHeader", "==", true).where("isPaid.key", "==", paymentStatus.unpaid.key));
  }

  getInvoiceHeaderRef(studentKey: string, admissionKey: string) {
    return this.db.collection("students").doc(studentKey).collection("invoices", ref => ref
      .where("isHeader", "==", true)
      .where("program.admissionKey", "==", admissionKey)
      .where("isPaid.key", "==", paymentStatus.unpaid.key)
      .orderBy("create_date", "desc")
      .limit(1));
  }

  invoiceTestingRef(Id: string) {
    return this.db.collection<any>('invoices', ref => ref.where('student.serial_id', '==', Id))
  }

  studentScholarshipRef(Id: string) {
    return this.db.collection<any>("students", ref =>
      ref.where("puc_id", "==", Id).limit(1)
    );
  }

  testingRef(campus: any, term: any) {
    return this.db.collection<any>("testing", ref =>
      ref
        .where("term.key", "==", term.key)
        .where("campus.id", "==", campus.key)
        .orderBy("createdate", "desc")
    );
  }

  programRef() {
    return this.db.collection<any>("testType", ref => ref.orderBy("order"));
  }

  levelTestTypeRef() {
    return this.db.collection("institute_training_level", ref => ref.orderBy("name"));
  }

  trainingLevelByProgramRef(key) {
    return this.db.collection("institute_training_level", ref =>
      ref.where("program.key", "==", key)
    );
  }

  termInstituteActiveRef(termKey, instituteKey) {
    return this.db.collection("academics_term", ref =>
      ref
        .where("term_type.key", "==", termKey)
        .where("termstatus.key", "<", 3)
        .where("institute.key", "==", instituteKey)
        .orderBy("termstatus.key")
        .orderBy("page_key", "desc")
    );
  }

  sessionRef() {
    return this.db.collection("institute_training_level_session", ref => ref.orderBy("name"));
  }

  shiftRef() {
    return this.db.collection("academics_shift", ref => ref.orderBy("order"));
  }

  campusRef() {
    return this.db.collection("campus", ref =>
      ref.where("status.key", "==", 1)
    );
  }

  shiftDBRef() {
    return this.db.collection("academics_shift");
  }

  campusRefFire() {
    return this.db.firestore.collection("campus")
  }

  campusFireRef() {
    return this.db.firestore.collection("campus")
  }

  studentAdmissionEnDocRef(studentKey: string) {
    return this.db.collection("academics_major_admission", ref => ref
      .where("student.key", "==", studentKey)
      .where("admission_type.key", "==", 2)
    )
  }

  instituteTermDocsRef(instituteKey) {
    return this.db.collection("academics_term", ref => ref
      .where("institute.key", "==", instituteKey))
  }

  termRef() {
    return this.db.collection("academics_term", ref => ref.orderBy("page_key", "desc").limit(Pages.size)
    );
  }

  termDocRef() {
    return this.db.collection("academics_term", ref => ref.orderBy("page_key", "desc"));
  }

  allocatedPayment() {
    return this.db.collection("allocated_payments");
  }

  studentByIDRef(pucID: string) {
    return this.db.collection("students", ref =>
      ref.where("puc_id", "==", pucID).limit(1)
    );
  }

  miscellaneousRef() {
    return this.db.collection<any>("admission_services", ref =>
      ref
        .where("program.key", "==", programsObj.miscellaneous)
        .orderBy("code")
    );
  }

  miscellaneousDoc(key) {
    return this.db.collection<any>("admission_services").doc(key);
  }

  termAcademicActive() {
    return this.db.collection("term", ref =>
      ref
        .where("termstatus.key", "==", 2)
        .where("institute.instituteType.key", "==", 2)
        .orderBy("startDateKey")
        .limit(1)
    );
  }

  termInstituteActive(key: string) {
    return this.db.collection("term", ref =>
      ref
        .where("termstatus.key", "==", 2)
        .where("institute.key", "==", key)
        .orderBy("startDateKey")
        .limit(1)
    );
  }

  studentDocRef(key) {
    return this.db.collection("students", ref =>
      ref.where("puc_id", "==", key)
    );
  }

  checkExistStudentRef(value) {
    return this.db.collection('students', ref => ref
      .where("puc_id", "==", value.toString().trim())
      .limit(1)
    )
  }

  employeeDocRef(key) {
    return this.db.collection("employees", ref => ref.where("code", "==", key));
  }

  employeeRef() {
    return this.db.collection("employees", ref => ref.limit(Pages.size));
  }

  employeeSearchRef(search: any) {
    if (search.key) {
      return this.db.collection("employees", ref => ref
        .where("key", "==", search.key).limit(1)
      );
    }
    return this.db.collection("employees", ref =>
      ref
        .where("full_name", ">=", search)
        .orderBy("full_name")
        .limit(Pages.size)
    );
  }

  userDBRef() {
    return this.db.collection("users", ref => ref.limit(Pages.size));
  }

  cashierRef() {
    return this.db.collection("users", ref => ref.where("isCashier", "==", true).where("status.key", "==", 1));
  }

  userDBSearchRef(search: any) {
    if (search.key) {
      return this.db.collection("users", ref => ref
        .where("key", "==", search.key).limit(1)
      );
    }
    return this.db.collection("users", ref =>
      ref
        .where("full_name", ">=", search)
        .orderBy("full_name")
        .limit(Pages.size)
    );
  }

  employeeSRef() {
    return this.db.collection("employees", ref => ref.limit(Pages.size));
  }


  studentInfo(student) {
    return {
      name: ConvertService.toNull(student.nameEng),
      khmer: ConvertService.toNull(student.nameKh),
      phone: ConvertService.toNull(student.mobile_phone),
      code: ConvertService.toNull(student.puc_id),
      gender: ConvertService.toNull(student.gender),
      key: ConvertService.toNull(student.key),
      email: ConvertService.toNull(student.email)
    };
  }

  employeeInfo(employee) {
    return {
      firstName: ConvertService.toNull(employee.firstname),
      lastName: ConvertService.toNull(employee.lastname),
      phone: ConvertService.toNull(employee.mobile),
      code: ConvertService.toNull(employee.code),
      gender: ConvertService.toNull(employee.gender),
      key: ConvertService.toNull(employee.key),
      email: ConvertService.toNull(employee.email)
    };
  }

  expiredFeeDate(admisionDate: Date, config: TestOption) {
    return moment(admisionDate)
      .add(config.expired_fee_peroid, "days")
      .toDate();
  }

  expiredTestDate(admisionDate: Date, config: TestOption) {
    return moment(admisionDate)
      .add(config.expired_fee_peroid, "months")
      .toDate();
  }

  batch() {
    return this.db.firestore.batch();
  }

  firestore() {
    return this.db.firestore;
  }

  dbRef() {
    return this.db;
  }

  createId() {
    return this.db.createId();
  }
}

export const reportFilterBy = [
  { key: 1, text: "Custom" },
  { key: 2, text: "Today" },
  { key: 3, text: "This Month" },
  { key: 4, text: "This Year" }
];
