import { Tuition } from './../../stores/tuition.store';
import { ConvertService } from './../../services/convert.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from '../../auth/auth.service';
import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { academicYearObj, MappingService } from '../../services/mapping.service';
import { Institute } from 'src/app/stores/institute.store';
import { ITrainingFee } from 'src/app/interfaces/tuition';
import { Terms } from 'src/app/stores/term.store';
import { Environment } from 'src/app/stores/environment.store';
import { Unpaid } from 'src/app/stores/unpaid.store';
import { DataService } from 'src/app/services/data.service';
import { Student } from 'src/app/stores/student.store';
import { invoiceTypesObj, enrollPrograms, paymentStatus, enrollmentTypes } from 'src/app/dummy/status';
import { IInvoice } from 'src/app/interfaces/invoice';

@Component({
  selector: 'app-add-prepaid-student',
  templateUrl: './add-prepaid-student.component.html',
  styleUrls: ['./add-prepaid-student.component.scss']
})
export class AddPrepaidStudentComponent implements OnInit {

  @ViewChild("focusInput") inputEl: ElementRef;

  form: FormGroup;
  amount: AbstractControl;
  credit: AbstractControl;
  qty: AbstractControl;
  term: AbstractControl;
  shift: AbstractControl;
  note: AbstractControl;
  
  filteredStates:any;
  filteredShiftStates:any;
  
  constructor(
    public dialogRef: MatDialogRef<AddPrepaidStudentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public env: Environment,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore,
    public fee: Tuition,
    public store: Institute,
    public termStore:Terms,
    public unpaid: Unpaid,
    private ds: DataService,
    public studentStore: Student
  ) { }

  buildForm(): void {
    this.form = this.fb.group({
      term: [null,Validators.compose([Validators.required,MappingService.validSelected.bind(this)])],
      amount:[null,Validators.compose([Validators.required])],
      note:[null],
      credit:[null],
      qty:[null],
      shift:[null],
    })

    this.term = this.form.controls['term'];
    this.shift = this.form.controls['shift'];
    this.amount = this.form.controls['amount'];
    this.note = this.form.controls['note'];
    this.credit = this.form.controls['credit'];
    this.qty = this.form.controls['qty'];
    
    this.termStore.fetchTermToArray(list => {
      this.filteredStates = MappingService.autoComplete(
        this.term,
        list,
        "name"
      );
      const currentTerm = list.filter(m=>m.key === "mOVZMpz5C5uX1SlVyD79")[0];
      this.term.patchValue(currentTerm)
    });

    this.termStore.fetchShiftToArray(list => {
      this.filteredShiftStates = list;
      this.shift.patchValue(this.filteredShiftStates[0]);
    });

  }
  
  compareObjects(o1: any, o2: any): boolean {
    if (o2) return o1.key === o2.key;
  }
  
  displayItem(item: any): string {
    return item ? item.name : item;
  }

  ngOnInit() {
    this.buildForm();
  }

  create(f: any) {
    if (this.form.valid) {
      this.form.disable();
      let { amount, credit, qty, term, shift } = f;

      const create_by = MappingService.userObj(this.env.user);
      const campus = this.env.user
        ? MappingService.campusObj(this.env.user.campus)
        : null;
      const currentTerm = academicYearObj(term);
      const studentDoc = MappingService.studentObj(this.studentStore.student);

      let programAcademic = this.studentStore.selectedAdmission?this.studentStore.selectedAdmission.program_academic:null;

      const headerKey = this.ds.createId();
      const invoiceNo = null;

      const date = new Date();
      const courseProgram = programAcademic;
      const invoiceType = invoiceTypesObj.tuitionFee;
      const schoolFeeType = enrollPrograms.academic;
      const displayProgram = courseProgram?courseProgram.type.text: null;
      const displayLevel = courseProgram?courseProgram.name:null;
      const displayShift = shift.name;
      const grandTotal = amount;

      const invoiceDetail: IInvoice = {
        key: this.ds.createId(),
        create_date: date,
        create_date_key: ConvertService.toDateKey(date),
        create_by: create_by,
        issue_by: create_by,
        issue_date: date,
        invoice_no: invoiceNo,
        invoice_type: invoiceType,
        school_fee_type: schoolFeeType,
        student: studentDoc,
        verify_by: create_by,
        verify_date: date,
        page_key: ConvertService.pageKey(),
        verify_date_key: ConvertService.toDateKey(date),
        invoice_date: date,
        invoice_date_key: ConvertService.toDateKey(date),
        course: null,
        isPaid: paymentStatus.unpaid,
        isVoid: false,
        program: courseProgram,
        byAdmission: false,
        issue_term: currentTerm,
        payment_term: currentTerm,
        headerRef: headerKey,
        isHeader: false,
        enroll_status: null,
        isEnrollVerify: true,
        campus: campus,
        schoolSession: null,
        description: displayLevel,
        penaltyRef: null,
        penalty: null,
        scholarshipRef:null,
        scholarship: null,
        loan: null,
        prepaidRef: null,
        prepaid: null,
        price: grandTotal,
        amount: grandTotal,
        enrollment_type: enrollmentTypes.academic,
        note: f.note,
      };

      this.unpaid.addStudentPrepaid(f, invoiceDetail, this.studentStore.student, (success, error) => {
          if (success) {
            this.dialogRef.close();
            this.snackBar.open("Prepaid have been add to this student", "done", {
              duration: 2000
            });
            this.form.enable();
            this.dialogRef.close("yes");
          } else {
            this.form.enable();
            this.snackBar.open(error, "Error");
          }
        }
      );
    }
  }

}