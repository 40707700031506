import { ConvertService } from 'src/app/services/convert.service';
import { AuthService } from 'src/app/auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { Report } from './../../../stores/report.store';
import { Component, OnInit } from '@angular/core';
import { Environment } from 'src/app/stores/environment.store';
import { User } from 'src/app/stores/user.store';
import { PrintService } from 'src/app/services/print.service';
import { invoiceTypeParams } from 'src/app/dummy/status';
import { MatDialog } from '@angular/material';
import { InvoiceDialogComponent } from 'src/app/components/invoice-dialog/invoice-dialog.component';
import { SCHOOL } from 'src/app/dummy/config';

@Component({
  selector: 'app-receive-testing-fee',
  templateUrl: './receive-testing-fee.component.html',
  styleUrls: ['./receive-testing-fee.component.scss']
})
export class ReceiveTestingFeeComponent implements OnInit {
  type = "all";
  reportName = "All";
  shiftDate;
  SHIFT_DATA = [];
  school = SCHOOL

  constructor(
    public store: Report,
    public auth: AuthService,
    public user: User,
    private ps: PrintService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public env: Environment
  ) { }

  async ngOnInit() {
    this.route.params.forEach(async param => {
      this.type = param["id"];
      this.env.getUser(async user => {
        if (user && user.shift) {

          // UPDATE SHIFT NULL
          this.SHIFT_DATA = await this.env.fetchReceivePaymentMovement(user.shift.key)
          if (this.SHIFT_DATA && this.SHIFT_DATA.length > 0) {
            this.env.fetchUpdateStudentInvoice(this.SHIFT_DATA)
          }

          this.shiftDate = user.shift.create_date.toDate()
          if (this.type === "all") {
            this.reportName = "All";
            this.store.fetchReceiptListing(user.shift.key);
          }
          else {
            this.reportName = invoiceTypeParams[this.type].text;
            this.store.fetchReceiptTypeListing(user.shift.key, this.type);
          }
        }
      })
    })
  }

  _onPrint(item) {
    let dialogRef = this.dialog.open(InvoiceDialogComponent, {
      data: { studentKey: item.student.key, headerKey: item.headerRef },
      width: '90vw',
      height: '80vh',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }


  print() {
    this.ps.print('#print', 'a4l');
  }


}
