import { ConvertService, toUpperCase } from './../../../services/convert.service';
import { DataService } from 'src/app/services/data.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Environment } from './../../../stores/environment.store';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { BaseStore } from 'src/app/stores/base.store';
import { MappingService, gradeObj, academicYearObj, campusObj } from 'src/app/services/mapping.service';
import { ITrainingGrade } from 'src/app/interfaces/trainingGrade';
import { status, recordStatus } from 'src/app/dummy/status';
import { checkExistDoc } from 'src/app/services/fire-validators.service';
import { YearTraining } from 'src/app/stores/yearTraining.store';

@Component({
  selector: 'app-edit-training-grade',
  templateUrl: './edit-training-grade.component.html',
  styleUrls: ['./edit-training-grade.component.scss']
})
export class EditTrainingGradeComponent implements OnInit {
  @ViewChild("focusInput") inputEl: ElementRef;
  form: FormGroup;
  academicYear: AbstractControl;
  grade: AbstractControl;
  gradeNext: AbstractControl;
  description: AbstractControl;

  filteredGradeStates: any;
  filteredNextGradeStates: any;

  isExistGrade = false;

  constructor(
    public dialogRef: MatDialogRef<EditTrainingGradeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public env: Environment,
    private snackBar: MatSnackBar,
    public base: BaseStore,
    private afs: AngularFirestore,
    private ds: DataService,
    public store: YearTraining,
  ) { }

  buildForm(): void {
    this.form = this.fb.group({
      grade: [null, Validators.compose([Validators.required, MappingService.validSelected.bind(this)])],
      gradeNext: [null, Validators.compose([Validators.required, MappingService.validSelected.bind(this)])],
      description: [null,],
    })
    this.grade = this.form.controls['grade'];
    this.gradeNext = this.form.controls['gradeNext'];
    this.description = this.form.controls['description'];
  }

  async ngOnInit() {
    this.buildForm();

    const list = await this.base.fetchAcademicGrade();

    this.filteredGradeStates = MappingService.autoComplete(this.grade, list, "name");
    this.filteredNextGradeStates = MappingService.autoComplete(this.gradeNext, list, "name");

    const { grade, gradeNext } = this.data;
    this.form.patchValue({
      grade: grade,
      gradeNext: gradeNext,
    })
  }

  async checkExistGrade(control: AbstractControl) {
    this.isExistGrade = false;
    if (control.value) {
      const { selectedYear } = this.store;
      this.isExistGrade = await this.store.checkExistTrainingGrade(selectedYear.key, control.value.key);
    }
  }

  compareObjects(o1: any, o2: any): boolean {
    if (o2) return o1.key === o2.key;
  }

  displayItem(item: any): string {
    return item ? item.name : item;
  }

  create(f: any) {
    if (this.form.valid) {
      this.form.disable();
      const { grade, gradeNext, description } = f;

      const tags = [
        grade.key,
        `TYPE_${grade.type.key}`,
        `PROGRAM_${grade.program.key}`,
        toUpperCase(grade.name),
        gradeNext.key,
        this.store.selectedCampus.key,
        this.store.selectedYear.key,
      ]

      const item: ITrainingGrade = {
        key: this.data.key,
        tags: tags,
        name: grade.name,
        campus: campusObj(this.store.selectedCampus),
        grade: gradeObj(grade),
        gradeNext: gradeObj(gradeNext),
        description: description,
        fee: this.data.fee,
        priceList: this.data.priceList ? this.data.priceList : null,
        academicYear: academicYearObj(this.data.academicYear),
        update_date: new Date(),
        update_date_key: ConvertService.toDateKey(new Date()),
        update_by: this.env.user,
      };

      this.store.updateTrainingGrade(item, (success, error) => {
        if (success) {
          this.dialogRef.close();
          this.snackBar.open('Academic Training has been updated.', 'done', { duration: 2500 });
          this.form.enable();
          this.form.reset();
        }
        else {
          alert(error)
        }
      })
    }
  }

}
