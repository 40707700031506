import { ISetupTestingFee } from './../../interfaces/tuition';
import { Tuition } from './../../stores/tuition.store';
import { ConvertService } from './../../services/convert.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from '../../auth/auth.service';
import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { MappingService } from '../../services/mapping.service';
import { Institute } from 'src/app/stores/institute.store';

@Component({
  selector: 'app-add-program-fee',
  templateUrl: './add-program-fee.component.html',
  styleUrls: ['./add-program-fee.component.scss']
})
export class AddProgramFeeComponent implements OnInit {
  @ViewChild("focusInput") inputEl: ElementRef;

  form: FormGroup;
  testingFee: AbstractControl;
  
  filteredStates:any;
  constructor(
    public dialogRef: MatDialogRef<AddProgramFeeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public auth: AuthService,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore,
    public fee: Tuition,
    public store: Institute
  ) { }

  buildForm(): void {
    this.form = this.fb.group({
      testingFee: [null,Validators.compose([Validators.required,MappingService.validSelected.bind(this)])
      ],
    })
    this.testingFee = this.form.controls['testingFee'];
    this.fee.fetchToArray(list => {
      this.filteredStates = MappingService.autoComplete(
        this.testingFee,
        list,
        "name"
      );
    });
  }
  
  displayItem(item: any): string {
    return item ? item.name : item;
  }

  ngOnInit() {
    this.buildForm();
  }

  create(f: any) {
    if (this.form.valid) {
      this.form.disable()
      const formData: ISetupTestingFee = {
        key:this.afs.createId(),
        create_date: new Date(),
        create_by: this.auth.getUser(),
        page_key:ConvertService.pageKey(),
        fee:f.testingFee,
        program:this.data
      }
      this.store.setupTestingFee(formData, (success, error) => {
        if (success) {
            this.dialogRef.close();
          this.snackBar.open('Testing fee has been saved.', 'done', { duration: 2000 });
          this.form.enable();
          this.dialogRef.close("yes")
        }
        else {
          this.form.enable();
          this.snackBar.open(error, 'Error')
        }
      })
    }
  }

}