import { MatDialog, MatSnackBar } from "@angular/material";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Student } from "src/app/stores/student.store";
import { Payment } from "src/app/stores/payment.store";
import { Environment } from "src/app/stores/environment.store";
import { PrintService } from "src/app/services/print.service";
import { InvoiceDialogComponent } from "src/app/components/invoice-dialog/invoice-dialog.component";
import { DeleteComponent } from "src/app/components/delete/delete.component";

@Component({
  selector: 'app-student-receipt',
  templateUrl: './student-receipt.component.html',
  styleUrls: ['./student-receipt.component.scss']
})
export class StudentReceiptComponent implements OnInit {
  studentKey = null;
  admissionKey = null;
  disabled = true;
  isAdmin = false;

  constructor(
    private route: ActivatedRoute,
    public store: Student,
    public payment: Payment,
    public env: Environment,
    public dialog: MatDialog,
    private ps: PrintService,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.env.getUser(res => {
      this.isAdmin = false;
      if (res && res.email === "frontdesk@gmail.com") {
        this.isAdmin = true;
      }
    })
    this.route.parent.params.forEach(params => {
      this.studentKey = params["id"];
      this.admissionKey = params["admissionKey"];
      const url = this.route.snapshot.url;
      this.store.payPageKey = url.length > 1 ? url[1].path : null;
      // this.store.fetchAdmission(this.studentKey, res => { });
      // this.store.fetchStudentToDoc(this.studentKey, doc => {
      this.env.fetchConfigToArray(ls => {
        this.payment.fetchStudentInvoices(this.studentKey);
        this.payment.fetchUnpaidStudent(this.studentKey);
        this.disabled = false;
      })
      // });
    });
  }

  _onPrint(item) {
    // if(item.scholarship100) return;
    let dialogRef = this.dialog.open(InvoiceDialogComponent, {
      data: { studentKey: item.student.key, headerKey: item.headerRef },
      width: '90vw',
      height: '80vh',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  removeOldTerm(item) {
    // if (this.isAdmin) {
    let dialogRef = this.dialog.open(DeleteComponent, {
      data: { title: 'Remove invoices', memo: 'Please check this information again before remove from invoice.', name: item.description, button: 'OK' },
      width: '35vw',
      disableClose: true,
      role: 'dialog',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'yes') {
        this.payment.removeOldTerm(item, (success, error) => {
          if (success) {
            this.snackBar.open(item.description + ' has been removed.', 'done', { duration: 2000 });
          }
          else {
            this.snackBar.open(error, 'Error')
          }
        })
      }
    });
    // } 

    // else {
    //   if (item.enrollment_type.key !== "INSTITUTE" || item.issue_term.key === "gHVzKLEyiMJFHx5tZZuK" || item.issue_term.key === "f1XyZmFRGjlUbtc8KQ84") return;
    //   let dialogRef = this.dialog.open(DeleteComponent, {
    //     data: { title: 'Remove invoices', memo: 'Please check this information again before remove from invoice.', name: item.description, button: 'OK' },
    //     width: '35vw',
    //     disableClose: true,
    //     role: 'dialog',
    //   });
    //   dialogRef.afterClosed().subscribe(result => {
    //     if (result === 'yes') {
    //       this.payment.removeOldTerm(item, (success, error) => {
    //         if (success) {
    //           this.snackBar.open(item.description + ' has been removed.', 'done', { duration: 2000 });
    //         }
    //         else {
    //           this.snackBar.open(error, 'Error')
    //         }
    //       })
    //     }
    //   });
    // }
  }

  updateInvoiceProgram(item) {
    // if (item.enrollment_type.key !== "INSTITUTE" || item.issue_term.key === "gHVzKLEyiMJFHx5tZZuK" || item.issue_term.key === "f1XyZmFRGjlUbtc8KQ84") return;
    // let dialogRef = this.dialog.open(DeleteComponent, {
    //   data: { title: 'Update invoices', memo: 'Please check this information again before remove from invoice.', name: item.description, button: 'OK' },
    //   width: '35vw',
    //   disableClose: true,
    //   role: 'dialog',
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   if (result === 'yes') {
    //     this.payment.updateInvoiceProgram(item, (success, error) => {
    //       if (success) {
    //         this.snackBar.open(item.description + ' has been changed.', 'done', { duration: 2000 });
    //       }
    //       else {
    //         this.snackBar.open(error, 'Error')
    //       }
    //     })
    //   }
    // });
  }

}
